import { Filter } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import ReactPaginate from "react-paginate";

const AdminOutStation = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [showDelete, setshowDelete] = useState(false);
  const handleDelete = () => setshowDelete(true);
  const closeDelete = () => setshowDelete(false);

  const [AllCategory, setAllCategory] = useState([]);
  const getAllCategory = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllVehicleCategory"
      );
      if (res.status == 200) {
        setAllCategory(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllVehicleModel, setAllVehicleModel] = useState([]);
  const getAllVehicleM = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllvehicleModel"
      );
      if (res.status == 200) {
        setAllVehicleModel(
          res.data.carList?.filter((ele) => ele?.vehicleType == "Taxi")
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
//   const [vehicle, setvehicle] = useState([]);

//   const getVehicle = async () => {
//     const config = {
//       url: "/getAllVehicle",
//       method: "get",
//       baseURL: "https://dhanyatours.me/api/admin",
//       headers: { "content-type": "application/json" },
//     };
//     try {
//       const result = await axios(config);
//       if (result.status === 200) {
//         setvehicle(result.data.success);
//         // console.log("suceesss");
//       } else {
//         alert("Something went wrong");
//       }
//     } catch (error) {
//         console.log(error);
//     }
//   };

const [AllCity, setAllcity] = useState([]);
const getCity = async () => {
  try {
    const config = {
      url: "/GetCity",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "multipart/form-data" },
    };
    let res = await axios(config);
    if (res.status === 200) {
      setAllcity(res.data.success);
    } else {
      alert("Something Wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

const [AllOutStation,setAllOutStation]=useState([]);
const [nochangedata,setnochangedata]=useState([]);
const getOutStationData=async()=>{
    try {
        let res = await axios.get(
            "https://dhanyatours.me/api/admin/getAllOUTSATIONByAdmin"
          );
          if (res.status == 200) {
            setAllOutStation(res.data.success);
            setnochangedata(res.data.success);
          }
    } catch (error) {
        console.log(error);
    }
}

const [pageNumber, setPageNumber] = useState(0);

const usersPerPage = 10;
const pagesVisited = pageNumber * usersPerPage;
const pageCount = Math.ceil(AllOutStation.length / usersPerPage);
const changePage = ({ selected }) => {
  setPageNumber(selected);
};

const [search, setSearch] = useState("");
const [tableFilter, settableFilter] = useState([]);
const handleFilter = (e) => {
  if (e.target.value != "") {
    const filterTable = nochangedata.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    setAllOutStation([...filterTable]);
  } else {

    setAllOutStation([...nochangedata]);
  }
};
const [AllGst,setAllGst]=useState([]);

const getAllGst=async()=>{
    try {
        let res=await axios.get("https://dhanyatours.me/api/admin/getGst");
        if(res.status==200){
            setAllGst(res.data.success)
        }
    } catch (error) {
        console.log(error);
    }
}
  useEffect(() => {
    getOutStationData();
    getAllCategory();
    getAllVehicleM();
    getCity()
    getAllGst()
  }, []);

  const [from, setfrom] = useState("");
  const [to, setto] = useState("");
  const [category, setcategory] = useState("");
  const [TripType, setTripType] = useState("");
  const [VehicleModel, setVehicleModel] = useState("");
  const [baseKm, setbaseKm] = useState(250);
  const [PerKmPrice, setPerKmPrice] = useState(0);
  const [VehicleType, setVehicleType] = useState("");
  const [FuelType, setFuelType] = useState("");
  const [CGST, setCGST] = useState(0);
  const [SGST, setSGST] = useState(0);
  const [IGST, setIGST] = useState(0);
  const [NightDriverAllowance, setNightDriverAllowance] = useState(0);
  const [TollCharge, setTollCharge] = useState(0);
  const [ParkingCharge, setParkingCharge] = useState(0);
  const [driverAllowances, setdriverAllowances] = useState(0);
  const [WaitingTimeLimit, setWaitingTimeLimit] = useState(0);
  const [WaitingCharge, setWaitingCharge] = useState(0);
   const [TotalKm,setTotalKm]=useState(0);

  const AddOutStation=async()=>{
    try {
        if(!category|!VehicleModel|!VehicleType|!FuelType|!TotalKm|!baseKm|!PerKmPrice|!driverAllowances) return alert("Please fill the form")
        const config = {
            url: "/addOUTSATION",
            method: "post",
            baseURL: "https://dhanyatours.me/api/admin",
            headers: { "content-type": "application/json" },
            data: {
              from: from,
              to: to,
              TotalKm: TotalKm,
              VehicleModel: VehicleModel,
              FuelType: FuelType,
              baseKm: baseKm,
              PerKmPrice: PerKmPrice,    
              VehicleType: VehicleType,
              CGST: CGST,
              SGST: SGST,
              IGST: IGST,
              category:category,
              NightDriverAllowance:NightDriverAllowance,
              TollCharge:TollCharge,
              ParkingCharge:ParkingCharge,
              driverAllowances:driverAllowances,
              WaitingTimeLimit:WaitingTimeLimit,
              WaitingCharge:WaitingCharge,
            },
          };
          let res=await axios(config);
          if(res.status==200){
            alert("Successfully Added")
            handleClose();
            getOutStationData();
            setfrom("");
            setto("");
            setTripType("");
            setcategory("");
            setVehicleModel("");
            setbaseKm(0);
            setTotalKm(0)
            setParkingCharge(0);
            setPerKmPrice(0);
            setVehicleType("")
            setFuelType("");
            setCGST(0);
            setSGST(0);
            setIGST(0);
            setNightDriverAllowance(0);
            setTollCharge(0);
            setWaitingCharge(0);
            setWaitingTimeLimit(0);
            setdriverAllowances(0)
          }
    } catch (error) {
        console.log(error);
    }
  }

  const [editdata,seteditdata]=useState({});
  const updateOutstation=async()=>{
    try {
        const config = {
            url: "/updateOUTSATION",
            method: "put",
            baseURL: "https://dhanyatours.me/api/admin",
            headers: { "content-type": "application/json" },
            data: {
            id:editdata?._id,
              from: from,
              to: to,
              TotalKm: TotalKm,
              VehicleModel: VehicleModel,
              FuelType: FuelType,
              baseKm: baseKm,
              PerKmPrice: PerKmPrice,    
              VehicleType: VehicleType,
              CGST: CGST,
              SGST: SGST,
              IGST: IGST,
              category:category,
              NightDriverAllowance:NightDriverAllowance,
              TollCharge:TollCharge,
              ParkingCharge:ParkingCharge,
              driverAllowances:driverAllowances,
              WaitingTimeLimit:WaitingTimeLimit,
              WaitingCharge:WaitingCharge,
            },
          };
          let res=await axios(config);
          if(res.status==200){
            alert("Successfully Upadated")
            handleClose1();
            getOutStationData();
            setfrom("");
            setto("");
            setTotalKm(0)
            setTripType("");
            setcategory("");
            setVehicleModel("");
            setbaseKm(0);
            setParkingCharge(0);
            setPerKmPrice(0);
            setVehicleType("")
            setFuelType("");
            setCGST(0);
            setSGST(0);
            setIGST(0);
            setNightDriverAllowance(0);
            setTollCharge(0);
            setWaitingCharge(0);
            setWaitingTimeLimit(0);
            setdriverAllowances(0)
          }
    } catch (error) {
        console.log(error);
    }
  }

  const [deleteId,setdeleteId]=useState("");
  const deleteOutStation=async()=>{
    try {
        let res=await axios.delete("https://dhanyatours.me/api/admin/deleteOUTSATION/"+deleteId);
        if(res.status==200){
            alert("Successfully Deleted");
            closeDelete();
            getOutStationData()
        }
    } catch (error) {
        console.log(error);
    }
  }
  return (
    <>
      <div className="ms-5 mt-3">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h3> OutStation Packages</h3>
          </div>

          <div
            className="logde-op-00"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleShow();
            }}
          >
            + OutStation Packages
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add OutStation Packages</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6">
                    <label className="pb-2 nd-2">From</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setfrom(e.target.value)}
                  >
                    <option>Select City </option>
                    {AllCity?.map((item) => {
                      return (
                        <option value={item?.City}>{item?.City}</option>
                      );
                    })}
                  </Form.Select> 
                    </div>
                    <div className="col-md-6">
                    <label className="pb-2 nd-2">To</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setto(e.target.value)}
                  >
                    <option>Select City </option>
                    {AllCity?.filter((ele)=>ele?.City!==from).map((item) => {
                      return (
                        <option value={item?.City}>{item?.City}</option>
                      );
                    })}
                  </Form.Select> 
                    </div>
                </div>
              <div className="">
                <form>
                  <label className="pb-2 nd-2">Vehicle Category</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setcategory(e.target.value)}
                  >
                    <option>Select Vehicle Category </option>
                    {AllCategory?.map((item) => {
                      return (
                        <option value={item?.category}>{item?.category}</option>
                      );
                    })}
                  </Form.Select>
                  <label className="pb-2 nd-2">Vehicle Model</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setVehicleModel(e.target.value)}
                  >
                     <option>Select Brand / Model</option>
                    {AllVehicleModel?.filter((ele)=>ele?.category==category)?.map((item) => {
                      return (
                        <option value={item?.vehicleModel}>{item?.vehicleModel}</option>
                      );
                    })}
                  </Form.Select>

                  <label className="pb-2 nd-2">Vehicle Type</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setVehicleType(e.target.value)}
                  >
                    <option value="">Select Vehicle Type</option>
                    <option value="Ac">Ac</option>
                    <option value="Non-Ac">Non-Ac</option>
                  </Form.Select>
                  <label className="pb-2 nd-2">Vehicle Fuel Type</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setFuelType(e.target.value)}
                  >
                    <option value="">Select Fuel Type</option>
                    <option value="Petrol">Petrol</option>
                    <option value="Diesel">Diesel</option>
                    <option value="CNG">CNG</option>
                    <option value="Electric">Electric</option>
                  </Form.Select>
                  {/* <label className="pb-2 nd-2">Trip Type</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setTripType(e.target.value)}
                  >
                    <option value="">Select Fuel Type</option>
                    <option value="One Way">One Way</option>
                    <option value="Two Way">Two Way</option>
                  
                  </Form.Select> */}
                  <label htmlFor="" className="pb-2 nd-2">
                    Base Km
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={baseKm}
                    onChange={(e)=>setbaseKm(e.target.value)}
                    placeholder="Enter Base Km..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Total Distance
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={TotalKm}
                    onChange={(e)=>setTotalKm(e.target.value)}
                    placeholder="Enter total distance Km..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Per Km Price
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    min={0}
                    value={PerKmPrice}
                    onChange={(e)=>setPerKmPrice(e.target.value)}
                    id=""
                    placeholder="Enter Per Km..."
                    className="title-90"
                  />
                  <br />
             
                  
                  <label htmlFor="" className="pb-2 nd-2">
                    Driver Allowances
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={driverAllowances}
                    onChange={(e)=>setdriverAllowances(e.target.value)}
                    placeholder="Enter Driver Allowances..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                   Night Driver Allowances (10:00 pm - 06:00 am)
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    min={0}
                    id=""
                    value={NightDriverAllowance}
                    onChange={(e)=>setNightDriverAllowance(e.target.value)}
                    placeholder="Enter Driver Allowances..."
                    className="title-90"
                  />
                  <br />
                  <div className="row">
                   
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                   CGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setCGST(e.target.value)}>
                     <option value={0}>Select</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="gst")?.map((item)=>{
                       return <option value={item?.Cgst}>{item?.Cgst}%</option>
                     })}
                   </select>
                  
                   </div>
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                   SGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setSGST(e.target.value)}>
                     <option value={0}>Select</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="gst")?.map((item)=>{
                       return <option value={item?.Sgst}>{item?.Sgst}%</option>
                     })}
                   </select>
                  
                   </div>
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                    IGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setIGST(e.target.value)}>
                     <option value={0}>Select</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="Igst")?.map((item)=>{
                       return <option value={item?.Igst}>{item?.Igst}%</option>
                     })}
                   </select>
                    
                   </div>
                 </div>
                  <label htmlFor="" className="pb-2 nd-2">
                    Toll Charges
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    min={0}
                    id=""
                    value={TollCharge}
                    onChange={(e)=>setTollCharge(e.target.value)}
                    placeholder="Enter Toll charges..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Parking Charges
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={ParkingCharge}
                    onChange={(e)=>setParkingCharge(e.target.value)}
                    placeholder="Enter Parking Charges..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Waiting Time limit (Minutes)
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={WaitingTimeLimit}
                    onChange={(e)=>setWaitingTimeLimit(e.target.value)}
                    placeholder="Enter Waiting time ..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Waiting Charges / Minutes
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={WaitingCharge}
                    onChange={(e)=>setWaitingCharge(e.target.value)}
                    placeholder="Enter Waiting Charges/minutes..."
                    className="title-90"
                  />
                  <br />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>Back</Button>
              <Button variant="primary" onClick={AddOutStation}>Submit</Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Update OutStation Packages</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="row">
                    <div className="col-md-6">
                    <label className="pb-2 nd-2">From</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setfrom(e.target.value)}
                  >
                    <option>{from? (from):("Select City")} </option>
                    {AllCity?.map((item) => {
                      return (
                        <option value={item?.City}>{item?.City}</option>
                      );
                    })}
                  </Form.Select> 
                    </div>
                    <div className="col-md-6">
                    <label className="pb-2 nd-2">To</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setto(e.target.value)}
                  >
                   <option>{editdata?.to? (editdata?.to):("Select City")} </option>
                    {AllCity?.filter((ele)=>ele?.City!==from).map((item) => {
                      return (
                        <option value={item?.City}>{item?.City}</option>
                      );
                    })}
                  </Form.Select> 
                    </div>
                </div>
              <div className="">
                <form>
                  <label className="pb-2 nd-2">Vehicle Category</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setcategory(e.target.value)}
                  >
                    <option>{category?(category):("Select Vehicle Category")} </option>
                    {AllCategory?.map((item) => {
                      return (
                        <option value={item?.category}>{item?.category}</option>
                      );
                    })}
                  </Form.Select>
                  <label className="pb-2 nd-2">Vehicle Model</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setVehicleModel(e.target.value)}
                  >
                     <option>{editdata?.VehicleModel}</option>
                    {AllVehicleModel?.filter((ele)=>ele?.category==category)?.map((item) => {
                      return (
                        <option value={item?.vehicleModel}>{item?.vehicleModel}</option>
                      );
                    })}
                  </Form.Select>

                  <label className="pb-2 nd-2">Vehicle Type</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setVehicleType(e.target.value)}
                  >
                    <option value="">{editdata?.VehicleType}</option>
                    <option value="Ac">Ac</option>
                    <option value="Non-Ac">Non-Ac</option>
                  </Form.Select>
                  <label className="pb-2 nd-2">Vehicle Fuel Type</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setFuelType(e.target.value)}
                  >
                    <option value="">{editdata?.FuelType}</option>
                    <option value="Petrol">Petrol</option>
                    <option value="Diesel">Diesel</option>
                    <option value="CNG">CNG</option>
                    <option value="Electric">Electric</option>
                  </Form.Select>
                  {/* <label className="pb-2 nd-2">Trip Type</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setTripType(e.target.value)}
                  >
                    <option value="">Select Fuel Type</option>
                    <option value="One Way">One Way</option>
                    <option value="Two Way">Two Way</option>
                  
                  </Form.Select> */}
                  <label htmlFor="" className="pb-2 nd-2">
                    Base Km
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={baseKm}
                    onChange={(e)=>setbaseKm(e.target.value)}
                    placeholder="Enter Base Km..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Total Distance
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={TotalKm}
                    onChange={(e)=>setTotalKm(e.target.value)}
                    placeholder="Enter total distance Km..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Per Km Price
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    min={0}
                    value={PerKmPrice}
                    onChange={(e)=>setPerKmPrice(e.target.value)}
                    id=""
                    placeholder="Enter Per Km..."
                    className="title-90"
                  />
                  <br />
             
                  
                  <label htmlFor="" className="pb-2 nd-2">
                    Driver Allowances
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={driverAllowances}
                    onChange={(e)=>setdriverAllowances(e.target.value)}
                    placeholder="Enter Driver Allowances..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                   Night Driver Allowances (10:00 pm - 06:00 am)
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    min={0}
                    id=""
                    value={NightDriverAllowance}
                    onChange={(e)=>setNightDriverAllowance(e.target.value)}
                    placeholder="Enter Driver Allowances..."
                    className="title-90"
                  />
                  <br />
                  <div className="row">
                   
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                   CGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setCGST(e.target.value)}>
                   <option value={CGST}>{CGST}%</option>
                   <option value={0}>0%</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="gst")?.map((item)=>{
                       return <option value={item?.Cgst}>{item?.Cgst}%</option>
                     })}
                   </select>
                  
                   </div>
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                   SGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setSGST(e.target.value)}>
                   <option value={SGST}>{SGST}%</option>
                   <option value={0}>0%</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="gst")?.map((item)=>{
                       return <option value={item?.Sgst}>{item?.Sgst}%</option>
                     })}
                   </select>
                  
                   </div>
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                    IGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setIGST(e.target.value)}>
                   <option value={IGST}>{IGST}%</option>
                     <option value={0}>0%</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="Igst")?.map((item)=>{
                       return <option value={item?.Igst}>{item?.Igst}%</option>
                     })}
                   </select>
                    
                   </div>
                 </div>
                  <label htmlFor="" className="pb-2 nd-2">
                    Toll Charges
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    min={0}
                    id=""
                    value={TollCharge}
                    onChange={(e)=>setTollCharge(e.target.value)}
                    placeholder="Enter Toll Tax..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Parking Charges
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={ParkingCharge}
                    onChange={(e)=>setParkingCharge(e.target.value)}
                    placeholder="Enter Parking Charges..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Waiting Time limit (Minutes)
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={WaitingTimeLimit}
                    onChange={(e)=>setWaitingTimeLimit(e.target.value)}
                    placeholder="Enter Waiting time ..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Waiting Charges / Minutes
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={WaitingCharge}
                    onChange={(e)=>setWaitingCharge(e.target.value)}
                    placeholder="Enter Waiting Charges/minutes..."
                    className="title-90"
                  />
                  <br />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>Close</Button>
              <Button variant="primary" onClick={updateOutstation}>Submit</Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="searchHeadFlex">
                    <div className="searchWrapper" style={{ display: "flex" }}>
                        <input
                            type="text"
                            maxlength="230"
                            style={{ outlineWidth: "0px", padding: "4px" }}
                            className="textsearch"
                            placeholder="Search by ....."
                        onChange={handleFilter}
                        />
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 16 16"
                            id="BsSearch"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                                clip-rule="evenodd"
                            ></path>
                            <path
                                fill-rule="evenodd"
                                d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </div>
                </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>From</th>
              <th>To</th>
              <th>Vehicle Category</th>
              <th>Vehicle Model</th>
              <th>Vehicle Type</th>
              <th>Fule Type</th>
              <th>Base Km</th>
              <th>Total Distance</th>
              <th>Per Km Price</th>
              <th>Driver Allowances</th>
              <th>  Night Driver Allowances (10:00 pm - 06:00 am)</th>
              <th>CGST</th>
              <th>SGST</th>
              <th>IGST</th>
              <th>Toll Charges</th>
              <th>Parking Charges</th>
              <th>Waiting Charges</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {AllOutStation  ?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                return (
                     <tr>
              <td>{i+1}.</td>
              <td>{item?.from}</td>
              <td>{item?.to}</td>
              <td>{item?.category}</td>
              <td>{item?.VehicleModel}</td>
              <td>{item?.VehicleType}</td>
              <td>{item?.FuelType}</td>
              <td>{item?.baseKm} km</td>
              <td>{item?.TotalKm} km</td>
              <td>{item?.PerKmPrice}</td>
              <td>{item?.driverAllowances}</td>
              <td>{item?.NightDriverAllowance}</td>
              <td>{item?.CGST}</td>
              <td>{item?.SGST}</td>
              <td>{item?.IGST}</td>
              <td>{item?.TollCharge}</td>
              <td>{item?.ParkingCharge}</td>
              <td>{item?.WaitingTimeLimit} /{item?.WaitingCharge}</td>
              <td style={{ display: "flex", gap: "5px" }}>
                <div
                  className=""
                  onClick={() => {
                    seteditdata(item)
                    setfrom(item?.from);
                    setcategory(item?.category);
                    setbaseKm(item?.baseKm);
                    setParkingCharge(item?.ParkingCharge);
                    setPerKmPrice(item?.PerKmPrice);
                    setCGST(item?.CGST);
                    setSGST(item?.SGST);
                    setIGST(item?.IGST);
                    setTotalKm(item?.TotalKm)
                    setNightDriverAllowance(item?.NightDriverAllowance);
                    setTollCharge(item?.TollCharge);
                    setWaitingCharge(item?.WaitingCharge);
                    setWaitingTimeLimit(item?.WaitingTimeLimit);
                    setdriverAllowances(item?.driverAllowances)
                    handleShow1();
                  }}
                >
                  <BiEdit
                    style={{
                      color: "blue",
                      fontSize: "25px",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div
                  className=""
                  onClick={() => {
                    setdeleteId(item?._id);
                    handleDelete();
                  }}
                >
                  <AiFillDelete
                    style={{
                      color: "red",
                      fontSize: "25px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </td>
            </tr> 
                )
            })}
          
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
      <Modal show={showDelete} onHide={closeDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete ??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <div style={{ float: "right" }}>
            <Button variant="danger" onClick={closeDelete}>
              Cancel
            </Button>{" "}
            <Button variant="success" onClick={deleteOutStation}>Ok</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminOutStation;
