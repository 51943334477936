import axios from "axios";
import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { ImLocation2 } from "react-icons/im";
import { useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";
function HomeStayH() {
  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  };
  const [loade, setloade] = useState([]);
  // console.log(loade);

  const lodges = async () => {
    const config = {
      url: "/getAllImage",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setloade(result.data.success);

        console.log("suceesss", result.data.success);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  const [Hotels, setLoadgess] = useState([]);
  const [nochangedata,setnochangedata]=useState([])
  const getHotel = async () => {
    const config = {
      url:"/getHotelByCategory/HomeStay",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setLoadgess(result.data.success);
        setnochangedata(result.data.success);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  useEffect(() => {
    lodges();
    getHotel();
  }, []);
  useEffect(() => {
    lodges();
    getHotel();
  }, []);

  const slectHandle=(ab)=>{
 
    if(ab=="High to Low"){
   
      let am=nochangedata.sort((a,b)=>b.StartingPrice-a.StartingPrice);
      setLoadgess([...am]);
    }
    if(ab=="Low to High"){
   
      let am=nochangedata.sort((a,b)=>a.StartingPrice-b.StartingPrice);
      setLoadgess([...am]);
    }
    if(ab==""){
      getHotel();
    }
  }

  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setLoadgess([...filterTable]);
    } else {
      setSearch(e.target.value);
      setLoadgess([...nochangedata]);
    }
  };
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [number, setnumber] = useState("");

  const [message, setmessage] = useState("");

  const onCreate = async () => {
    try {
      if (!user) {
        alert("Please enter full name!");
      } else if (!email) {
        alert("Please enter email!");
      } else if (!number) {
        alert("Please enter mobile number!");
      } else {
        const config = {
          url: "/createTrainBook",
          method: "post",
          baseURL: "https://dhanyatours.me/api/user",
          headers: { "conttent-type": "application/json" },
          data: {
            userName: user,
            email: email,
            number: number,
            message: message,
          },
        };
        const result = await axios(config);
        if (result.status === 200) {
          alert("Our team  will contact you soon");
          window.location.assign("/");
        } else {
          alert("Something went wrong");
        }
      }
    } catch (error) {
      console.log("erore", error);
      alert(error.response.data.error);
    }
  };

  const { state } = useLocation();

  const [distanation, setdistanation] = useState("");
  const [date, setdate] = useState("");

  useEffect(() => {
    if (state?.distanation) {
      setdistanation(state?.distanation);
      setdate(state?.date);
    }
  }, []);

  function areStringsEqual(str1, str2) {
    // Normalize and tokenize the strings
    const words1 = str1
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, " ")
      .trim()
      .split(" ");
    const words2 = str2
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, " ")
      .trim()
      .split(" ");

    // Check if any word from words1 exists in words2
    for (const word of words1) {
      if (words2.includes(word)) {
        return true;
      }
    }

    return false;
  }
  return (
    <>
      <div>
        <OwlCarousel
          className="owl-theme"
          loop
          margin={0}
          nav={false}
          dots={false}
          autoplay={true}
          responsive={responsive}
        >
          {loade
            ?.filter((ele) => ele?.name === "Home Stay")
            ?.map((item) => {
              return (
                <div class="item">
                  <div class="box">
                    <img
                      src={`https://dhanyatours.me/image/${item?.image}`}
                      alt="Hotel"
                      style={{ width: "100%", height: "300px" }}
                    />
                  </div>
                </div>
              );
            })}
        </OwlCarousel>

        <div className="jungal">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <div className="read-jungal">
                  <h2 className="read-op">Home Stay Hotel</h2>
                  <p style={{ textAlign: "justify" }}>
                    Find homestay from Bengaluru. Perfect for any Vacation.
                    Instant Confirmation. Superb Locations. 191 Countries. Best
                    Prices. 24/7 Customer Service. Over 6 Million Listings.
                    Types: Apartments, Rooms, Flats, Entire Homes.
                  </p>
                  <p style={{ textAlign: "justify" }}>
                    If you thought homestays in India were very modest, then
                    think again! No, you don't have to share a bathroom with the
                    host family or sleep on the floor. In fact, you can enjoy
                    some of the best accommodations that India has to offer.
                    And, on top of this, you get to feast on delicious home
                    cooked food and receive personalized attention. If you've
                    ever experienced famed homestay hospitality.
                  </p>
                  <p style={{ textAlign: "justify" }}>
                    You can find magnificent homestays through Dhanya Tours and
                    Travels, there are a few that stand out in terms of
                    facilities, location, and hosts. Villages, royal residences,
                    forts, and the tranquil backwaters of Goa and Kerala are
                    just some of the mesmerizing settings where you'll find
                    homestays and you can book one as per your convenience.
                  </p>
                </div>
                <div className="search-drive">
                  <div style={{ display: "flex" }}>
                    <div class="form-outline">
                      <input
                        id="search-input"
                        type="search"
                        placeholder="Search by City , Package , Name..."
                        style={{ outlineWidth: "0px" }}
                        class="form-control"
                        //   value={search}
                          onChange={(e) => handleFilter(e)}
                      />
                    </div>
                    <button
                      id="search-button"
                      type="button"
                      class="btn btn-primary"
                      style={{ height: "52px", borderRadius: "inherit" }}
                    >
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                  <div className="spa-item mt-2">
                  <Form.Select aria-label="Default select example" onChange={(e)=>slectHandle(e.target.value)}>
                      <option value="">Popular</option>
                      <option value="High to Low">High to Low</option>
                      <option value="Low to High">Low to High</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="listing">
                  {Hotels?.filter((check) => {
                    if (distanation) {
                      if (areStringsEqual(check?.location, distanation)) {
                        return check;
                      }
                    } else {
                      return check;
                    }
                  })
                   
                    .map((item) => {
                      return (
                        <div className="row">
                          <div className="col-md-4 col-xs-4 text-left">
                            <a href={`/singal-hotel/${item?._id}`}>
                              <img
                                class="img-responsive"
                                src={`https://dhanyatours.me/Hotel/${item?.HotelProfile}`}
                                alt="kabini jungle resort"
                              />
                            </a>
                          </div>
                          <div class="col-md-8 col-xs-8 text-left">
                            <div class="car-text">
                              <h3>
                                <a href={`/singal-hotel/${item?._id}`}>
                                  {item?.hotalName}
                                </a>
                              </h3>
                              <p>
                                <i class="icon-calendar">CheckInTime </i>
                                {item?.CheckInTime}
                                <br />
                                <i class="icon-calendar">CheckOutTime </i>
                                {item?.CheckOutTime}
                              </p>
                              <p>
                                <span class="hidden-xs">
                                  <i class="icon-location-pin"></i>
                                  <ImLocation2 /> {item?.location?.slice(0,50)}
                                </span>
                              </p>
                              <p>
                                <span class="posted_by">
                                  <i class="icon-briefcase"></i> Starts at ₹{" "}
                                  {(item?.StartingPrice+(item?.StartingPrice*((item?.gst + item?.igst)/100)))?.toFixed(2)}/-
                                </span>
                              </p>
                              <p
                             
                              >
                                <a
                                  href={`/singal-hotel/${item?._id}`}
                                  class="btn btn-primary btn-sm hidden-xs"
                                >
                                  View Details
                                </a>
                                {/* <a
                                  style={{
                                    paddingLeft: "10px",
                                    fontSize: "13px",
                                  }}
                                >
                                  Posted By-
                                  {item?.adminId ? (
                                    <span
                                      style={{ fontSize: "13px", color: "red" }}
                                    >
                                      (Admin)
                                    </span>
                                  ) : (
                                    <span
                                      style={{ fontSize: "13px", color: "red" }}
                                    >
                                      ({item?.wenderId?.name})
                                    </span>
                                  )}
                                </a> */}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="col-md-3">
                <div className="r-lg-0">
                  {/* <div
                    style={{
                      boxShadow: "0px 14px 33px -9px rgba(0, 0, 0, 0.15)",
                      padding: "15px",
                      backgroundColor: "#004aad",
                      marginTop: "20px",
                      border: "1px solid #004aad",
                      bordeRadius: "5px",
                      height: "100px",
                    }}
                  >
                    <p>
                      <span
                        style={{
                          fontWeight: "600",
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        Get assistance for Booking
                      </span>
                      <br />
                      <a href="#" style={{ color: "white" }}>
                        <i class="icon-phone"></i> +91-8884 467467
                      </a>
                      <br />
                      <a href="" style={{ color: "white" }}>
                        <i class="icon-mail"></i> info@traveltour.com
                      </a>
                    </p>
                  </div> */}

                  <div
                    style={{
                      boxShadow: " 0px 14px 33px -9px rgba(0, 0, 0, 0.15)",
                      padding: "15px",
                      backgroundColor: "#004aad",
                      marginTop: "20px",
                      border: "1px solid #004aad",
                      borderRadius: "5px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "600",
                        color: "white",
                        fontSize: "14px",
                      }}
                    >
                      Want to book a trip or inquiry?
                    </span>
                    <form>
                      <div className="form-group pt-2">
                        <input
                          type="text"
                          class="form-control"
                          name="Last Name"
                          value={user}
                          onChange={(e) => setUser(e.target.value)}
                          placeholder="Full Name*"
                          required=""
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          class="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email Id*"
                          required=""
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          class="form-control"
                          name="mobileno"
                          value={number}
                          onChange={(e) => setnumber(e.target.value)}
                          placeholder="Mobile No*"
                        />
                      </div>
                      {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Select Package <span class="text-danger">*</span>
                          </label>
              <div className="col-md-12">
              <div class="form-group">
              <Form.Select onChange={(e)=>{
                           setpackageName(e.target.value)
                           setData(JSON.parse(e.target.value))
                          }}>
                            <option>Select package</option>
                            {priceing?.map((item) => {
                              return (
                                <option value={JSON.stringify(item)}>
                                  {item?.packageName}
                                </option>
                              );
                            })}
                          </Form.Select>
            </div>
              </div>
           
             
               </div> */}
                      {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Check In Date/Time <span class="text-danger">*</span>
                          </label>
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="date"
                class="form-control"
                name="Description"
                value={checkinDate}
                onChange={(e)=>setcheckinDate(e.target.value)}
             
              
              />
            </div>
              </div>
           
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="time"
                class="form-control"
               value={checkinTime}
               onChange={(e)=>setcheckinTime(e.target.value)}              
           
              />
            </div>
              </div>
               </div> */}
                      {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Check Out Date/Time <span class="text-danger">*</span>
                          </label>
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="date"
                class="form-control"
                          value={checkOutDate}
                          onChange={(e)=>setcheckOutDate(e.target.value)}
              />
            </div>
              </div>
           
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="time"
                class="form-control"
               value={checkOutTime}
               onChange={(e)=>setcheckOutTime(e.target.value)}
              />
            </div>
              </div>
               </div> */}
                      {/* <div className="row">
               <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Number Of Guest <span class="text-danger">*</span>
                          </label>
               <div className="col-md-6">
               <div class="form-group">
              <input
                type="number"
                class="form-control"
              value={numberOfguest}
              onChange={(e)=>setnumberOfguest(e.target.value)}
                placeholder="adults"
              />
            </div>
               </div>
               <div className="col-md-6">
               <div class="form-group">
              <input
                type="number"
                class="form-control"
              value={NumberOfChild}
              onChange={(e)=>setNumberOfchild(e.target.value)}
                placeholder="childs"
              />
            </div>
               </div>
               </div> */}

                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          value={message}
                          onChange={(e) => setmessage(e.target.value)}
                          placeholder="Message"
                        />
                      </div>

                      <div class="row" style={{ marginTop: "10px" }}>
                        <div class="col-sm-6">
                          <botton
                            class="btn btn-primary btn-sm hidden-xs"
                            onClick={onCreate}
                          >
                            Inquiry Now
                          </botton>
                          {/* <input
                  class="btn btn-primary btn-sm hidden-xs"
                  type="submit"
                  value="Book Now"
                /> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeStayH;
