import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Admin/sidebar.css";
import { ChakraProvider } from "@chakra-ui/react";
import { BiMoney, BiUserCircle } from "react-icons/bi";
import { FaBed } from "react-icons/fa6";
import { IoBagCheckOutline } from "react-icons/io5";
import { BsFillBookmarkCheckFill, BsFillPersonCheckFill } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { RxDashboard } from "react-icons/rx";

function SubAdmin1(props) {
  const [acc, setacc] = useState(true);
  const [acc1, setacc1] = useState(false);
  const [acc2, setacc2] = useState(false);
  const [acc3, setacc3] = useState(false);
  const [acc4, setacc4] = useState(false);
  const [acc5, setacc5] = useState(false);
  const [acc6, setacc6] = useState(false);

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const [navigation, setnavigation] = useState("relative");
  const Logout = () => {
    sessionStorage.removeItem("vendor");
    window.location.assign("/");
  };
  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 500
        ? setnavigation("fixed top-0 left-0 z-50")
        : setnavigation("relative");
    }
  };
  const vender = JSON.parse(sessionStorage.getItem("vendor"));
  if (!vender) {
    alert("Please login");
    window.location.assign("/vender-login");
  } else
    return (
      <>
        <div className="header-0">
          <nav
            className="navigation"
            style={{
              height: "120px",
            }}
          >
            <a href="/add-vender-hotel" className="" style={{ width: "15%" }}>
              <img
                src="../dhanya travells.jpeg"
                alt="logo"
                style={{ width: "221px", height: "120px" }}
              />
            </a>
            <button
              className="hamburger"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              {/* icon from Heroicons.com */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="white"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <div
              className={
                isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
              }
            >
              <ul>
                <li
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  {" "}
                  <BiUserCircle style={{ color: "white", fontSize: "45px" }} />
                  {"  "} <a style={{ color: "white" }}>{vender?.name}</a>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn btn-outline-info"
                    onClick={Logout}
                  >
                    Logout
                  </button>
                </li>
                {/* <li>
                <a href="/booking">Mybooking</a>
              </li> */}
              </ul>
            </div>
          </nav>
        </div>
        <div>
          <Row className="me-0">
            <Col md={2}>
              <div
                class="sidebar"
                style={{ height: "100vh", overflowY: "unset" }}
              >
                <ul>
                  <div className="mt-3">
                    <Link to="/dashboard">
                      {" "}
                      <li
                        className={`a-ele ${acc ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(true);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                        }}
                      >
                        <span>
                          <RxDashboard
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Home
                        </span>

                        {/* Hotel */}
                      </li>
                    </Link>
                    <Link to="/add-vender-hotel">
                      <li
                        className={`a-ele ${acc1 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(true);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                        }}
                      >
                        {" "}
                        <span>
                          {/* Hotel Book Received */}{" "}
                          <CgProfile
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Profile
                        </span>
                      </li>
                    </Link>

                    {/* <Link to="/vendor-home-stay"> */}
                    <Link to="/vendor-book-hotel">
                      {" "}
                      <li
                        className={`a-ele ${acc2 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(true);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                        }}
                      >
                        <span>
                          <BsFillBookmarkCheckFill
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          {/* Home Stay */} New Bookings
                        </span>
                      </li>
                    </Link>
                    <Link to="/vendor-check-in">
                      {" "}
                      <li
                        className={`a-ele ${acc3 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(true);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                        }}
                      >
                        <span>
                          <BsFillPersonCheckFill
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          {/* Home Stay Book Received */} Check In
                        </span>
                      </li>
                    </Link>
                    <Link to="/vendor-check-out">
                      {" "}
                      <li
                        className={`a-ele ${acc4 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(true);
                          setacc5(false);
                          setacc6(false);
                        }}
                      >
                        <span>
                          <IoBagCheckOutline
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Check Out
                        </span>
                      </li>
                      {/* Resort */}
                    </Link>
                    <Link to="/vendor-rooms">
                      <li
                        className={`a-ele ${acc5 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(true);
                          setacc6(false);
                        }}
                      >
                        <span>
                          <FaBed
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          {/* Resort Received */} Rooms
                        </span>
                      </li>{" "}
                    </Link>
                    <Link to="/vendor-room-type">
                      {" "}
                      <li
                        className={`a-ele ${acc6 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(true);
                        }}
                      >
                        <span>
                          <FaBed
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          {/* Resort Received */} Room Category
                        </span>
                      </li>
                    </Link>
                    {/* <Link to="/vendor-resorts-recipt">
                    {" "}
                    <BiMoney style={{ width: "20px", height: "20px" }} />
                 Transaction History
                  </Link> */}
                  </div>
                </ul>
              </div>
            </Col>
            <Col md={10}>
              {" "}
              <ChakraProvider>{props.childern}</ChakraProvider>
            </Col>
          </Row>
        </div>
      </>
    );
}

export default SubAdmin1;
