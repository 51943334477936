import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  AiFillDelete,
  AiOutlineDelete,
  AiOutlineDownload,
} from "react-icons/ai";
import { FaEdit, FaEye } from "react-icons/fa";
import Table1 from "react-bootstrap/Table";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { ImCross } from "react-icons/im";

function BookHotelSub() {
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);
  const [show4, setShow4] = useState(false);
  const handleShow4 = () => setShow4(true);
  const handleClose4 = () => setShow4(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [deleteId, setDeleteId] = useState("");
  const vendor = JSON.parse(sessionStorage.getItem("vendor"));
  const [booktaxi, setbooktaxi] = useState([]);
  const [tableFilter, settableFilter] = useState([]);
  const getBooking = async () => {
    const config = {
      url: "/getHotelBookingBywenderId/" + vendor?._id,
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setbooktaxi(result.data.success);
        settableFilter(result.data.success);
        getGst();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AllRoom, setAllRoom] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getAllrooms = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/user/getAllRoomsByVendorId/" + vendor?._id
      );
      if (res.status == 200) {
        setAllRoom(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDelete = async () => {
    const config = {
      url: "/deleteHotelOrder/" + deleteId,
      method: "delete",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        getBooking();
        handleClose1();
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  const [gst, setgst] = useState({});
  const getGst = async () => {
    const config = {
      url: "/getGst",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setgst(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getBooking();
    getGst();
    if (vendor) {
      getAllrooms();
    }
  }, []);

  // console.log("AllRoom",AllRoom);
  const [bookingtaxi, setbookingtaxi] = useState({});

  let [PaymentMethod, setPaymentMethod] = useState("");
  let [GST, setGST] = useState(0);
  let [Total, setTotal] = useState(0);
  let [NumberOfchild, setNumberOfchild] = useState(0);
  let [Extramater, setExtraKm] = useState(0);
  let [numberOfguest, setnumberOfguest] = useState(0);
  let [extraDeteails, setextraDeteails] = useState("");

  const editBookingTaxi = async () => {
   
    // console.log("totel==>", subTotal, Extramater, GST, Total);
    try {
      const config = {
        url: "/editbookHotelOrder",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          bookId: bookingtaxi?._id,
          extaraMatter: Extramater,
          message:extraDeteails,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Success");
        getBooking();
        handleClose();
        setExtraKm(0);
        setextraDeteails("");
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert("error");
        alert(error.response.data.error);
      }
    }
  };

  const makeConfirm = async (id) => {
    try {
      const config = {
        url: "/makeOderStatusChangeHotelBook",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          orderId: id,
          status: "Confirm",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getBooking();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const makeCancel = async (id) => {
    try {
      const config = {
        url: "/makeOderStatusChangeHotelBook",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          orderId: id,
          status: "Cancel",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getBooking();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const makeComplete = async (id) => {
    try {
      const config = {
        url: "/makeOderStatusChangeHotelBook",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          orderId: id,
          status: "Complete",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getBooking();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  //getVehicleById
  const createPDF = async () => {
    const input = document.getElementById("pdf");
    const options = { scrollY: -window.scrollY };
    const canvas = await html2canvas(input, options);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    pdf.save("bookingDetails.pdf");
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    booktaxi.length /
      usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [search, setSearch] = useState("");
 
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = tableFilter?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setbooktaxi([...filterTable]);
    } else {
      setSearch(e.target.value);
      setbooktaxi([...tableFilter]);
    }
  };
  const [AllCategory, setAllCategory] = useState([]);
  const getAllCategory = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/user/getAllRoomcategoryByVendorId/" +
          vendor?._id
      );
      if (res.status == 200) {
        setAllCategory(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (vendor) {
      getAllCategory();
    }
  }, []);
  const [category, setCategory] = useState("");
  const [roomtype, setroomtype] = useState("");
  const [rooms, setrooms] = useState([]);
  const selRoom = (data) => {
    if (data !== "") {
      data = JSON.parse(data);
      let ab = rooms;
      let am = rooms.filter((item) => item?._id == data?._id);
      if (am.length == 0) {
        ab.push(data);
        setrooms([...ab]);
      }
    }
  };

  const delRoom = (id) => {
    let am = rooms?.filter((item) => item?._id !== id);
    setrooms([...am]);
  };
  console.log("check kar baba", category, roomtype, rooms);
  const [UploadVideo, setUploadVideo] = useState(false);


  const [stDate, setstDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [stTitme, setstTime] = useState("");
  const [endTime, setendTime] = useState("");
  const makeBlockUnbolckRoom = async () => {
    try {
        if (!stDate | !stTitme)
          return alert("Please Select Start Date And Time");
        if (!endDate | !endTime)
          return alert("Please Select End Date And Time");
      
      for (let i = 0; i < rooms.length; i++) {
         const config = {
        url: "/makeBlockAndUnblockRooms",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          stDate: stDate,
          endDate: endDate,
          stTime: stTitme,
          isBlock: true,
          endTime: endTime,
          id: rooms[i]?._id,
        },
      };
      let res = await axios(config)
    }
    alert("Successfully Blocked rooms")
    setUploadVideo(false);
    handleClose4();
    getAllrooms();
    setstDate("");
    setstTime("");
    setendDate("");
    setrooms([])
    setendTime("");
    } catch (error) {
      setUploadVideo(false);
      console.log(error);
    }
  };
const [bookid,setbookid]=useState({});
const [roomCategory,setroomCategory]=useState("");
const [roomType,setroomType]=useState("");
const [AvailableR,setAvailableR]=useState([]);

function filterRoom(bookid) {
  const stdate = bookid?.checkInDate?.split("-")?.join("");
  const sttime = bookid?.checkInTime?.split(":")?.join("");
  const boEndDate = bookid?.checkOutDate?.split("-")?.join("");
  const boEndTime = bookid?.checkOutTime?.split(":")?.join("");

  const Am = AllRoom.filter((item) => {
    const roomStDate = item?.stDate?.split("-")?.join("");
    const roomStTime = item?.stTime?.split(":")?.join("");
    const roomEndDate = item?.endDate?.split("-")?.join("");
    const roomEndTime = item?.endTime?.split(":")?.join("");

    // Convert date and time strings to integers for comparison
    const stDateInt = parseInt(stdate);
    const stTimeInt = parseInt(sttime);
    const boEndDateInt = parseInt(boEndDate);
    const boEndTimeInt = parseInt(boEndTime);
    const roomStDateInt = parseInt(roomStDate);
    const roomStTimeInt = parseInt(roomStTime);
    const roomEndDateInt = parseInt(roomEndDate);
    const roomEndTimeInt = parseInt(roomEndTime);

    // Check if the room is available within the specified time frame
    if (
      roomStDateInt <= stDateInt &&
      roomEndDateInt >= boEndDateInt &&
      (roomStDateInt !== stDateInt || roomEndDateInt !== boEndDateInt ||
      (roomStDateInt === stDateInt && roomStTimeInt >= stTimeInt) ||
      (roomEndDateInt === boEndDateInt && roomEndTimeInt <= boEndTimeInt))
    ) {
      return  // Include this room in the filtered list
    }
    return item; 
     // Exclude this room from the filtered list
  });

 return setAvailableR([...Am]);
}


  const makeRoomAlotHotel=async()=>{
    try {
      if(rooms.length==0) return alert("Please select room numbers")
      const config = {
        url: "/makeRoomAlotHotel",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          AloteRoom: rooms?.map((ele)=>ele?.roomNumber)?.join(","),
          checkStatus: "CheckIn",
          roomType: roomType,
          packageName: roomCategory,
          id: bookid?._id
        },
      };
      let res=await axios(config);
      if(res.status==200){
        alert("Successfully Aloted Rooms");
        handleClose2();
        setroomCategory("");
        setroomType("");
        setrooms([]);
        getBooking();
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
const searchDate=()=>{
  if(!fromd) return alert("Please select from date");
  if(!tod) return alert("Please select to date");

 let std=fromd?.split("-");
      let entd=tod?.split("-")
      let startD=parseInt(std?.join(""));
      let endD=parseInt(entd?.join(""))
      let abc=tableFilter?.filter((ele)=>{
        // console.log("amit check it",moment(ele?.createdAt).format("YYYY-MM-DD").split("-"))
        let abd=moment(ele?.createdAt).format("YYYY-MM-DD").split("-")
        let amd=parseInt(abd?.join(""));
        return startD<=amd && endD>=amd
      })
      setbooktaxi(abc);
      // setdata(abc)
      // setfileName(`AcountHistory_${moment(fromd).format("DD/MM/YYYY")} to ${moment(tod).format("DD/MM/YYYY")}`)
}

  return (
    <>
    <div className="">
        {UploadVideo ? (
          <div
            class="loader"
            style={{ position: "fixed", top: "50%", left: "50%" }}
          ></div>
        ) : null}
   
      <div className="de-lod">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op ms-4">
            <h3>Booking Details</h3>
          </div>
        </div>

        <Modal show={show} onHide={handleClose} id="pdf">
          <Modal.Header
            closeButton
            style={{
              backgroundColor: "#2fdbeb",
              color: "white",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            {vendor?.vendorType} Booking Details
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <form>
                <label className="pb-2 nd-2">BookId{" :"}</label>{" "}
                <span>{bookingtaxi?.bookId}</span>
                <br />
                <label className="pb-2 nd-2">Name {" :"}</label>{" "}
                <span>{bookingtaxi?.userName}</span> <br />
                <label className="pb-2 nd-2">Number{" :"}</label>{" "}
                <span> {bookingtaxi?.number}</span>
                <br />
                <label className="pb-2 nd-2">Email{" :"}</label>{" "}
                <span>{bookingtaxi?.email}</span>
                <br />
                <label className="pb-2 nd-2">Location{" :"}</label>{" "}
                {bookingtaxi?.Location}
                <br />
                <label className="pb-2 nd-2">Message{" :"}</label>{" "}
                {bookingtaxi?.message}
                <TableContainer>
                  <Table variant="striped" colorScheme="teal">
                    <TableCaption></TableCaption>

                    <Tbody>
                   
                      <Tr>
                        <Td>{vendor?.vendorType} Name</Td>
                        <Td>{bookingtaxi?.lodgeId?.hotalName}</Td>
                      </Tr>
                      <Tr>
                        <Td>{vendor?.vendorType}Id</Td>
                        <Td>{bookingtaxi?.lodgeId?._id}</Td>
                      </Tr>
                      <Tr>
                        <Td>{vendor?.vendorType} Contact Number</Td>
                        <Td>{bookingtaxi?.lodgeId?.contact}</Td>
                      </Tr>
                      <Tr>
                        <Td>{vendor?.vendorType} Email</Td>
                        <Td>{bookingtaxi?.lodgeId?.email}</Td>
                      </Tr>
                      <Tr>
                        <Td>Package Name</Td>
                        <Td>{bookingtaxi?.packageName}</Td>
                      </Tr>

                      <Tr>
                        <Td>Check In Date/Time</Td>
                        <Td>
                          {" "}
                          {moment(bookingtaxi?.checkInDate).format(
                            "DD/MM/YYYY"
                          )}
                          {"  "}
                          {moment(bookingtaxi?.checkInTime, [
                            "HH:mm:ss",
                          ]).format("hh:mm a")}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Check Out Date/time</Td>
                        <Td>
                          {moment(bookingtaxi?.checkOutDate).format(
                            "DD/MM/YYYY"
                          )}
                          {"  "}
                          {moment(bookingtaxi?.checkOutTime, [
                            "HH:mm:ss",
                          ]).format("hh:mm a")}
                        </Td>
                      </Tr>
                      {bookingtaxi?.packageName ? (<Tr>
                        <Td>Package Price/Adults ₹</Td>
                        <Td>{bookingtaxi?.packagePrice}</Td>
                      </Tr>):(<></>)}
                      
                      <Tr>
                        <Td>Number Of Adults</Td>
                        <Td>{bookingtaxi?.NumberOfGuest}</Td>
                      </Tr>
                      {/* {bookingtaxi?.status === "Confirm" ? (
                        <Tr>
                          <Td>Number Of Adults</Td>
                          <Td>
                            {" "}
                            <input
                              type="number"
                              className="title-90"
                              name="lodgename"
                              value={numberOfguest}
                              placeholder="Ex:- 3"
                              onChange={(e) => setnumberOfguest(e.target.value)}
                            />
                          </Td>
                        </Tr>
                      ) : (
                        <></>
                      )} */}
                      <Tr>
                        <Td>Number Of children</Td>
                        <Td>{bookingtaxi?.NumberOfChild?.length}</Td>
                      </Tr>
                      {/* {bookingtaxi?.status === "Confirm" ? (
                        <Tr>
                          <Td>Number Of children</Td>
                          <Td>
                            {" "}
                            <input
                              type="number"
                              className="title-90"
                              name="lodgename"
                              value={NumberOfchild}
                              placeholder="Ex:- 3"
                              onChange={(e) => setNumberOfchild(e.target.value)}
                            />
                          </Td>
                        </Tr>
                      ) : (
                        <></>
                      )} */}
                      <Tr>
                        <Td>Extra Matter Price ₹</Td>
                        <Td>{(bookingtaxi?.extaraMatter)?.toFixed(2)}</Td>
                      </Tr>
                      {bookingtaxi?.status === "Confirm" ? (<>
                        <Tr>
                          <Td>Extra Matter </Td>
                          <Td>
                            {" "}
                            <input
                              type="text"
                              className="title-90"
                              name="lodgename"
                              value={extraDeteails}
                              placeholder="Write details extra mater"
                              onChange={(e) => setextraDeteails(e.target.value)}
                            />
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Extra Matter Price ₹</Td>
                          <Td>
                            {" "}
                            <input
                              type="number"
                              className="title-90"
                              name="lodgename"
                              value={Extramater}
                              placeholder="Ex:- 1200"
                              onChange={(e) => setExtraKm(e.target.value)}
                            />
                          </Td>
                        </Tr>
                     </> ) : (
                        <></>
                      )}
                      <Tr>
                        <Td>GST ₹</Td>
                        <Td>{(bookingtaxi?.gst)?.toFixed(2)}</Td>
                      </Tr>

                      <Tr>
                        <Td>Total Price ₹</Td>
                        <Td>{(parseInt(bookingtaxi?.totalAmount)+bookingtaxi?.extaraMatter)?.toFixed(2)}</Td>
                      </Tr>
                      <Tr>
                        <Td>Status</Td>
                        <Td>{bookingtaxi?.status}</Td>
                      </Tr>
                      <Tr>
                        <Td>Payment</Td>
                        <Td>{bookingtaxi?.paymentMethod}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <AiOutlineDownload
              style={{
                color: "red",
                cursor: "pointer",
                fontSize: "36px",
                float: "right",
              }}
              onClick={() => createPDF()}
            />
            {bookingtaxi?.status === "Complete" ? (
              <></>
            ) : (
              <>
                {bookingtaxi?.status === "Confirm" ? (
                  <>
                    <Button variant="primary" onClick={editBookingTaxi}>
                      Update Booking
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        makeCancel(bookingtaxi?._id);
                        handleClose();
                      }}
                    >
                      Cancel Booking
                    </Button>
                  
                  </>
                ) : (
                  <Button
                    variant="warning"
                    onClick={() => {
                      makeConfirm(bookingtaxi?._id);
                      handleClose();
                    }}
                  >
                    Confirm Booking
                  </Button>
                )}
              </>
            )}

            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete?</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <Button variant="primary" onClick={getDelete}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Alot Rooms</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label className="pb-2 nd-2">Room Category</label>
            <select name="" id="" className="title-90" onChange={(e)=>setroomCategory(e.target.value)}>
              <option value="">{bookid?.packageName}</option>
              {AllCategory?.map((item) => {
                return <option value={item?.category}>{item?.category}</option>;
              })}
            </select>
            <label className="pb-2 nd-2">Room Type</label>
            <select
              name=""
              id=""
              className="title-90"
              onChange={(e) => setroomType(e.target.value)}
            >
              <option value="">{bookid?.roomType}</option>
              <option value="Ac">Ac</option>
              <option value="Non-Ac">Non-Ac</option>
            </select>
            <label className="pb-2 nd-2">Available Rooms</label>
            <select
              name=""
              id=""
              className="title-90"
              onChange={(e) => selRoom(e.target.value)}
            >
              <option value="">---Select Room---</option>
              {AvailableR?.filter(
                (ele) =>
                  ele?.roomCategory == roomCategory&&ele?.roomType==roomType 
                 
              )?.map((item) => (
                <option value={JSON.stringify(item)}>
                  Room No.- {item?.roomNumber}
                </option>
              ))}
            </select>

            <div className="row" style={{ marginTop: "10px" }}>
              {rooms?.map((item) => {
                return (
                  <div
                    className="col-md-6"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div>
                      <label className="pb-2 nd-2">
                        Room No: {item?.roomNumber}
                      </label>
                    </div>
                    <div>
                      {" "}
                      <ImCross
                        style={{ color: "red", marginTop: "5px" }}
                        onClick={() => delRoom(item?._id)}
                      />
                    </div>
                  </div>
                );
              })}
              </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Cancel
            </Button>
            <Button variant="primary" onClick={()=>makeRoomAlotHotel()}>Alot</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Block Rooms</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label className="pb-2 nd-2">Room Category</label>
            <select
              name=""
              id=""
              className="title-90"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">----select----</option>
              {AllCategory?.map((item) => {
                return <option value={item?.category}>{item?.category}</option>;
              })}
            </select>
            <label className="pb-2 nd-2">Room Type</label>
            <select
              name=""
              id=""
              className="title-90"
              onChange={(e) => setroomtype(e.target.value)}
            >
              <option value="">----select----</option>
              <option value="Ac">Ac</option>
              <option value="Non-Ac">Non-Ac</option>
            </select>
            <div className="row">
              <label className="pb-2 nd-2">CheckIn Date And Time</label>
              <div className="col-md-6">
                <input
                  type="Date"
                  className="title-90"
                  name="lodgetiming"
                  onChange={(e) => setstDate(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="Time"
                  className="title-90"
                  name="lodgetiming"
                  onChange={(e) => setstTime(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <label className="pb-2 nd-2">Check Out Date And Time</label>
              <div className="col-md-6">
                <input
                  type="Date"
                  className="title-90"
                  name="lodgetiming"
                  onChange={(e) => setendDate(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="Time"
                  className="title-90"
                  name="lodgetiming"
                  onChange={(e) => setendTime(e.target.value)}
                />
              </div>
            </div>

            <label className="pb-2 nd-2">Available Rooms</label>
            <select
              name=""
              id=""
              className="title-90"
              onChange={(e) => selRoom(e.target.value)}
            >
              <option value="">---Select Room---</option>
              {AllRoom?.filter(
                (ele) =>
                  ele?.isBlock == false &&
                  ele?.roomCategory == category &&
                  ele?.roomType == roomtype
              )?.map((item) => (
                <option value={JSON.stringify(item)}>
                  Room No.- {item?.roomNumber}
                </option>
              ))}
            </select>

            <div className="row" style={{ marginTop: "10px" }}>
              {rooms?.map((item) => {
                return (
                  <div
                    className="col-md-6"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div>
                      <label className="pb-2 nd-2">
                        Room No: {item?.roomNumber}
                      </label>
                    </div>
                    <div>
                      {" "}
                      <ImCross
                        style={{ color: "red", marginTop: "5px" }}
                        onClick={() => delRoom(item?._id)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Cancel
            </Button>
            <Button variant="primary" onClick={()=>{
              setUploadVideo(true)
              makeBlockUnbolckRoom()}}>Block</Button>
          </Modal.Footer>
        </Modal>
        <div className="taxi-table ms-4">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="searchHeadFlex">
                  <div className="searchWrapper" style={{ display: "flex" }}>
                    <input
                      type="text"
                      maxlength="230"
                      style={{ outlineWidth: "0px", padding: "4px" }}
                      className="textsearch"
                      placeholder="Search by ..."
                      onChange={handleFilter}
                    />
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      id="BsSearch"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                        clip-rule="evenodd"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
              <label htmlFor="">From</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => setfromd(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="">To</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => settod(e.target.value)}
              />
            </div>
            <div className="col-md-2">
         
              <button onClick={searchDate}
                style={{
                  borderRadius: "3px",
                  color: "white",
                  fontSize: "18px",
                  border: "1px solid",
                  backgroundColor: "#004aad",
                  padding: "5px",
                  marginTop: "23px",
                  borderRadius: "10px",
                  fontWeight: 500,
                }}
              >
                Search
              </button>
            </div>
              <div className="col-md-2">
                <Button onClick={handleShow4} style={{ float: "right" }}>
                  Block Rooms
                </Button>
              </div>
            </div>
          </div>
          <div className="scrolll_bar">
            <Table1 striped bordered hover>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Booking Id</th>
                  <th>{vendor?.vendorType} name</th>
                  <th>User Name</th>
                  <th>Number</th>
                  <th>CheckIn Date/Time</th>
                  <th>CheckOut Date/Time</th>
                  <th>Package Name</th>
                  <th>Occupancy</th>

                  <th>Package Price ₹</th>

                  <th>Number of Adults</th>
                  <th>Total No. of Rooms</th>
                  <th>Total Amount</th>

                  <th>Booking Status</th>
                  <th>Alot Rooms</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
               
                    {booktaxi

                      ?.slice(pagesVisited, pagesVisited + usersPerPage)
                      ?.map((item, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item?.bookId}</td>
                            <th>{item?.lodgeId?.hotalName}</th>
                            <td>{item?.userName}</td>
                            <td>{item?.number}</td>
                            <td>
                              {moment(item?.checkInDate).format("DD/MM/YYYY")}
                              {"  "}
                              {moment(item?.checkInTime, ["HH:mm:ss"]).format(
                                "hh:mm a"
                              )}{" "}
                            </td>
                            <td>
                              {moment(item?.checkOutDate).format("DD/MM/YYYY")}
                              {"  "}
                              {moment(item?.checkOutTime, ["HH:mm:ss"]).format(
                                "hh:mm a"
                              )}{" "}
                            </td>
                            <td>{item?.packageName}</td>
                            <td>{item?.Occupancy}</td>
                            <td>{item?.packagePrice}</td>
                            <td>{item?.NumberOfGuest}</td>
                            <td>{item?.AloteRoom ? (<>{item?.AloteRoom?.split(",")?.length}{" "}{"Rooms No:"}{` ${item?.AloteRoom}`}</>):""}</td>
                            <td>{(parseInt(item?.totalAmount)+item?.extaraMatter)?.toFixed(2)}</td>

                            <td>{item?.status}</td>
                            <td>
                              {item.checkStatus=="CheckOut"&&item?.status=="Complete" ?(<></>):( <>{item?.AloteRoom ? ( <Button style={{backgroundColor:"blue"}} onClick={()=>{
                                filterRoom(item)
                                setroomType(item?.roomType);
                                setroomCategory(item?.packageName);
                                setbookid(item);
                                handleShow2()}}>Edit Alot Room</Button>):( <Button onClick={()=>{
                                  filterRoom(item)
                                  setroomType(item?.roomType);
                                  setroomCategory(item?.packageName);
                                  setbookid(item);
                                  handleShow2()}}> Alot Room</Button>)}
                              </>)}
                            
                            </td>
                            {item?.status === "Pending" ? (
                              <td>
                                <div style={{ display: "flex", gap: "10px" }}>
                                  <FaEye
                                    style={{
                                      fontSize: "26px",
                                      color: " #5600ff",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleShow();
                                      setextraDeteails(item?.message)
                                      setExtraKm(item?.extaraMatter)
                                      setbookingtaxi(item);
                                    }}
                                  />
                                  <button
                                    type="button"
                                    class="btn btn-success"
                                    onClick={() => makeConfirm(item?._id)}
                                  >
                                    Confirm
                                  </button>{" "}
                                  <button
                                    type="button"
                                    class="btn btn-danger"
                                    onClick={() => makeCancel(item?._id)}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </td>
                            ) : (
                              <td>
                                <div style={{ display: "flex", gap: "10px" }}>
                                  <span>
                                    <FaEye
                                      style={{
                                        color: "#5600ff",
                                        cursor: "pointer",
                                        fontSize: "26px",
                                      }}
                                      onClick={() => {
                                        handleShow();
                                        setextraDeteails(item?.message)
                                        setExtraKm(item?.extaraMatter)
                                        setbookingtaxi(item);
                                      }}
                                    />
                                  </span>
                                  <span>
                                    <AiOutlineDelete
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                        fontSize: "26px",
                                      }}
                                      onClick={() => {
                                        setDeleteId(item?._id);
                                        handleShow1();
                                      }}
                                    />
                                  </span>
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })}
              
              </tbody>
            </Table1>
          </div>

          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </div>
      </div>
    </>
  );
}

export default BookHotelSub;
