import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { CiCircleRemove } from "react-icons/ci";
import { FaEdit } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
function LuxuryTrain() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // price Modal---
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [deleteId, setDeleteId] = useState("");
  // delete Modal---
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // Includes Modal---
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // Excludes Modal---
  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit Modal---
  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [trainname, settrainname] = useState("");
  const [trainProfile, settrainProfile] = useState("");
  // const [trainImage, settrainImage] = useState("");
  const [startingPrice, setstartingPrice] = useState("");
  const [timing, settiming] = useState("");
  const [location, setlocation] = useState("");
  const [contact, setcontact] = useState("");
  const [trainemail, settrainemail] = useState("");
  const [trainoverview, settrainoverview] = useState("");
  const [traincategory, settraincategory] = useState("");

  const [Packagename, setPackagename] = useState("");
  const [Doublyweekprice, setDoublyweekprice] = useState("");
  const [Doublyweekendprice, setDoublyweekendprice] = useState("");
  const [Singleweekprice, setSingleweekprice] = useState("");
  const [Singleweekendprice, setSingleweekendprice] = useState("");
  const [suggetion, setsuggetion] = useState("");
  const [termCondition, setTermCondition] = useState("");
  const [to, setto] = useState("");
  // Include
  const [include, setinclude] = useState([]);
  const [inclTest, setinclText] = useState("");

  const addInclude = () => {
    let inc = {};
    if (inclTest) {
      inc.text = inclTest;
    }
    include.push(inc);
    alert("Success Added");
  };

  // const [termAndCondition,settermAndCondition]=useState("");
  // Exclude
  const [Excludess, setExcludess] = useState([]);
  const [exText, setexText] = useState("");
  const addExclude = () => {
    let inc = {};
    if (exText) {
      inc.text1 = exText;
    }
    Excludess.push(inc);
    alert("Success Added");
  };

  // Price
  const [price, setprice] = useState([]);
  const addPrice = () => {
    let priceObg = {};
    if (Packagename) {
      priceObg.packageName = Packagename;
    }
    if (Doublyweekprice) {
      priceObg.doublyWeekPrice = Doublyweekprice;
    }
    if (Doublyweekendprice) {
      priceObg.doublyWeekendPrice = Doublyweekendprice;
    }
    if (Singleweekprice) {
      priceObg.singleWeekPrice = Singleweekprice;
    }
    if (Singleweekendprice) {
      priceObg.singleWeekendPrice = Singleweekendprice;
    }
    price.push(priceObg);
    alert("Success Added");
  };

  const [dayName, setdayName] = useState("");
  const [details, setdetails] = useState("");
  const [ITINERARY, setITINERARY] = useState([]);
  const handleChange6 = (e, editor) => {
    const data = editor.getData();
    setdetails(data);
  };
  const [showIN, setshowIN] = useState(false);

  const AddDataITINERARY = () => {
    let obj = {};
    if (!dayName) return alert("Please enter your day title");
    if (!details) return alert("Please enter your day details");
    obj["dayName"] = dayName;
    obj["details"] = details;
    ITINERARY.push(obj);

    alert("Successfully added");
    setdayName("");
    setdetails("");
    return setshowIN(false);
  };

  const onLuxAdd = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/Addtrain",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "multipart/form-data" },
        data: {
          trainName: trainname,
          trainProfile: trainProfile,
          // trainImage: trainImage,
          OVERVIEW: trainoverview,
          trainTiming: timing,
          location: location,
          contact: contact,
          email: trainemail,
          category: traincategory,
          StartingPrice: startingPrice,
          PRICING: price,
          Includes: include,
          Excludes: Excludess,
          to: to,
          ITINERARY: ITINERARY,
          suggetion: suggetion,
          termAndCondition: termCondition,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Added");
        getLuxuryTrain();
        handleClose();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    settrainoverview(data);
  };

  const handleChange3 = (e, editor) => {
    const data = editor.getData();
    setsuggetion(data);
  };

  const handleChange4 = (e, editor) => {
    const data = editor.getData();
    setTermCondition(data);
  };
  // // Get Tarin
  const [gettrain, setgettrain] = useState([]);
  // console.log(gettrain, "successdfg");
  const getLuxuryTrain = async () => {
    const config = {
      url: "/getAlltrain",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setgettrain(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getLuxuryTrain();
  }, []);

  // Delete
  const onDeletetain = async () => {
    try {
      const config = {
        url: "/deletetrain/" + deleteId,
        method: "delete",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Delete");
        getLuxuryTrain();
        handleClose6();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  // Edit
  const [edit, setedit] = useState({});
  const onEditTrain = async () => {
    try {
      const config = {
        url: "/edittrain",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "multipart/form-data" },
        data: {
          trainId: edit._id,
          trainName: trainname,
          trainProfile: trainProfile,
          OVERVIEW: trainoverview,
          trainTiming: timing,
          location: location,
          contact: contact,
          email: trainemail,
          category: traincategory,
          StartingPrice: startingPrice,
          to: to,
          suggetion: suggetion,
          termAndCondition: termCondition,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully updated");
        getLuxuryTrain();
        handleClose4();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const MakeBlock = async (id, isBloc) => {
    try {
      const config = {
        url: "/makeBlockUnbolckTrain",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { hotelId: id, isBloc: isBloc },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getLuxuryTrain();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const getbyLodgeId = async (id) => {
    try {
      const config = {
        url: "/getbytrainId/" + id,
        method: "get",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setedit(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AddpM, setAddpM] = useState(false);

  const [HandlePrice, setHandlePrice] = useState(false);
  const [deletePrice, setdeletePricId] = useState("");
  const [packDeleM, setPackDeletM] = useState(false);

  const [updateM, setupdateM] = useState(false);

  const addPricing = async () => {
    try {
      if (!Packagename) {
        return alert("Please enter package name!");
      }
      if (!Singleweekprice) {
        return alert("Please enter singal week price!");
      }

      if (!Doublyweekprice) {
        return alert("Please enter double week price!");
      }

      const config = {
        url: "/addPricingTrain",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          lodgeId: edit._id,
          packageName: Packagename,
          singleWeekendPrice: Singleweekendprice,
          singleWeekPrice: Singleweekprice,
          doublyWeekPrice: Doublyweekprice,
          doublyWeekendPrice: Doublyweekendprice,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfull added");
        getbyLodgeId(edit._id);
        setDoublyweekendprice("");
        setDoublyweekprice("");
        setSingleweekendprice("");
        setSingleweekprice("");
        setPackagename("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updatePricing = async () => {
    try {
      const config = {
        url: "/updatePricingHotel",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          lodgeId: imagesData._id,
          packid: deletePrice,
          packageName: Packagename,
          singleWeekendPrice: Singleweekendprice,
          singleWeekPrice: Singleweekprice,
          doublyWeekPrice: Doublyweekprice,
          doublyWeekendPrice: Doublyweekendprice,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfull updated");
        getbyLodgeId(imagesData._id);
        setDoublyweekendprice("");
        setupdateM(false);
        setDoublyweekprice("");
        setSingleweekendprice("");
        setSingleweekprice("");
        setPackagename("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deletePricing = async (id) => {
    try {
      const config = {
        url: "/RemovePricingTrain",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, priceId: id },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully deleted");
        getbyLodgeId(edit._id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addIncludeA = async () => {
    try {
      if (!inclTest) {
        return alert("Please enter includes!");
      }
      const config = {
        url: "/addIncludesTrain",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, text: inclTest },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfull added");
        getbyLodgeId(edit._id);
        setinclText("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const ITINERARYAddEdit = async () => {
    try {
      let obj = { lodgeId: edit._id };
      if (!dayName) return alert("Please enter your day title");
      if (!details) return alert("Please enter your day details");
      obj["dayName"] = dayName;
      obj["details"] = details;
      const config = {
        url: "/addITINERARYData",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: obj,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully added");
        setedit(res.data.success);
        setdayName("");
        setdetails("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteIntenery = async (id) => {
    try {
      const config = {
        url: "/RemoveITINERARYData",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, priceId: id },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully deleted");
        setedit(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteIncludes = async (id) => {
    try {
      const config = {
        url: "/RemoveIncludesTrain",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, IncludesId: id },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully deleted");
        getbyLodgeId(edit._id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addExcludesA = async () => {
    try {
      if (!exText) {
        return alert("Please enter excludes!");
      }
      const config = {
        url: "/addExcludesTrain",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, text1: exText },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfull added");
        getbyLodgeId(edit._id);
        setexText("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteExcludes = async (id) => {
    try {
      const config = {
        url: "/RemoveExcludesTrain",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, ExcludesId: id },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully deleted");
        getbyLodgeId(edit._id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(gettrain?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = gettrain?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setgettrain([...gettrain]);
    }
  };

  const [imagesData, setImagesData] = useState({});
  const [showImages, setshowImages] = useState(false);
  const hsndleImages = () => setshowImages(true);
  const closeImages = () => setshowImages(false);
  const [image3, setimage3] = useState("");

  const AddSingleImagesHotel = async () => {
    if (!image3) return alert("Please select images");
    try {
      const config = {
        url: "/addtrainImage",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "multipart/form-data" },
        data: {
          trainId: imagesData?._id,
          image: image3,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setimage3("");
        getLuxuryTrain();
        setImagesData(res.data.success);
        return alert("Successfully added");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const deleteHotelImages = async (id) => {
    try {
      const config = {
        url: "/removetrainImage",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          trainId: imagesData?._id,
          imageId: id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setimage3("");
        getLuxuryTrain();
        setImagesData(res.data.success);
        return alert("Successfully deleted");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const [showCalender, setshowcalender] = useState(false);

  const [monthD, setmonthD] = useState("");
  const [date, setdate] = useState("");
  const [day, setday] = useState("");
  const [time, settime] = useState("");
  const [sta, setst] = useState("");
  const monthsData = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dayData = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const addCalenderData = async () => {
    if (!monthD) return alert("Please select month");
    if (!date) return alert("Please select date");
    if (!day) return alert("Please select day");
    if (!time) return alert("Please select time");
    try {
      const config = {
        url: "/addCalenderData",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          lodgeId: imagesData?._id,
          monthD,
          date,
          day,
          time,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Added");
        setAddpM(false);

        getLuxuryTrain();
        setImagesData(res.data.success);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const [clendId, setclendId] = useState("");
  const updatedCalender = async () => {
    if (!monthD) return alert("Please select month");
    if (!date) return alert("Please select date");
    if (!day) return alert("Please select day");
    if (!time) return alert("Please select time");
    try {
      const config = {
        url: "/updateCalenderData",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          lodgeId: imagesData?._id,
          clendId: clendId,
          monthD,
          date,
          day,
          time,
          ClStatus: sta,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Updated");
        setupdateM(false);

        getLuxuryTrain();
        setImagesData(res.data.success);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const makeBlockUnbolckCalender = async (
    clendId,
    monthD,
    date,
    day,
    time,
    abce
  ) => {
    try {
      let stata = "";
      if (abce == true) {
        stata = "Blocked";
      } else {
        stata = "Un-Blocked";
      }
      const config = {
        url: "/updateCalenderData",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          lodgeId: imagesData?._id,
          clendId: clendId,
          monthD,
          date,
          day,
          time,
          ClStatus: abce,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(`Successfully ${stata}`);
        getLuxuryTrain();
        setImagesData(res.data.success);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const deleteCalenderData = async () => {
    try {
      const config = {
        url: "/RemoveCalenderData",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          lodgeId: imagesData?._id,
          IncludesId: clendId,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deleted");
        setPackDeletM(false);

        getLuxuryTrain();
        setImagesData(res.data.success);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  return (
    <>
      <div className="de-lod">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h3>Luxury Train List</h3>
          </div>

          <div
            className="logde-op-00"
            style={{ cursor: "pointer" }}
            onClick={() => {
              settrainname("");
              settrainProfile("");
              settraincategory("");
              setstartingPrice("");
              settiming("");
              setlocation("");
              setcontact("");
              settrainemail("");
              settrainoverview("");
              setsuggetion("");
              setTermCondition("");
              setto("");
              handleShow();
            }}
          >
            + Luxury Train
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <from>
              <div className="row">
                <div className="col-md-6">
                  <label className="pb-2 nd-2">Form</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="location"
                    required
                    placeholder="Enter start from"
                    value={location}
                    onChange={(e) => {
                      setlocation(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label className="pb-2 nd-2">To</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="location"
                    required
                    placeholder="Enter destination"
                    value={to}
                    onChange={(e) => {
                      setto(e.target.value);
                    }}
                  />
                </div>
              </div>
              <label className="pb-2 nd-2">Train Name</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="trainname"
                required
                placeholder="Enter trin name"
                value={trainname}
                onChange={(e) => {
                  settrainname(e.target.value);
                }}
              />
              <label className="pb-2 nd-2" htmlFor="upload1">
                Train Profile
              </label>
              <br />
              <input
                type="file"
                className="title-90"
                name="trainprofile"
                required
                id="upload1"
                accept="image/*"
                onChange={(e) => settrainProfile(e.target.files[0])}
              />
              <label className="pb-2 nd-2"> Category</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="price"
                required
                value={traincategory}
                placeholder="Enter category"
                onChange={(e) => {
                  settraincategory(e.target.value);
                }}
              />
              <label className="pb-2 nd-2">StartingPrice</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="price"
                required
                placeholder="Enter price"
                value={startingPrice}
                onChange={(e) => {
                  setstartingPrice(e.target.value);
                }}
              />
              <label className="pb-2 nd-2">Train Timing</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="timing"
                placeholder="Enter price"
                required
                value={timing}
                onChange={(e) => {
                  settiming(e.target.value);
                }}
              />

              <label className="pb-2 nd-2">Contact</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="contact"
                placeholder="Enter contact number"
                required
                value={contact}
                onChange={(e) => {
                  setcontact(e.target.value);
                }}
              />
              <label className="pb-2 nd-2">Email</label>
              <br />
              <input
                type="email"
                className="title-90"
                name="email"
                placeholder="Enter email id"
                required
                value={trainemail}
                onChange={(e) => {
                  settrainemail(e.target.value);
                }}
              />
              <label className="pb-2 nd-2">OVERVIEW</label>
              <br />
              <CKEditor
                editor={ClassicEditor}
                data={trainoverview}
                onChange={handleChange1}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
              <label className="pb-2 nd-2">Suggetion</label>
              <br />
              <CKEditor
                editor={ClassicEditor}
                data={suggetion}
                onChange={handleChange3}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
              <label className="pb-2 nd-2">Terms&Condition</label>
              <br />
              <CKEditor
                editor={ClassicEditor}
                data={termCondition}
                onChange={handleChange4}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
              <Button className="pb-2 nd-2 mt-3" onClick={handleShow1}>
                Pricing
              </Button>
              <Button
                className="pb-2 nd-2 mt-3 mx-2"
                onClick={() => setshowIN(true)}
              >
                Itinerary
              </Button>
              <Button className="pb-2 nd-2 mt-3 mx-2" onClick={handleShow2}>
                Includes
              </Button>
              <Button className="pb-2 nd-2 mt-3 mx-2" onClick={handleShow3}>
                Excludes
              </Button>
            </from>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={onLuxAdd}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* pricing */}

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Body>
            <from>
              <label className="pb-2 nd-2">Package Name</label>
              <br />
              <input
                type="text"
                className="title-90 mb-2"
                name="packagename"
                value={Packagename}
                required=""
                placeholder="Please enter package"
                onChange={(e) => setPackagename(e.target.value)}
              />
              <label className="pb-2 nd-2">Package Price In INR</label>
              <br />
              <input
                type="text"
                className="title-90 mb-2"
                name="weekprice"
                value={Singleweekprice}
                required=""
                placeholder=" In INR/person"
                onChange={(e) => setSingleweekprice(e.target.value)}
              />
              <label className="pb-2 nd-2"> Package Price In USD </label>
              <br />
              <input
                type="text"
                className="title-90 mb-2"
                name="weekendprice"
                value={Doublyweekprice}
                required=""
                placeholder="In USD/person"
                onChange={(e) => setDoublyweekprice(e.target.value)}
              />
            </from>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Back
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                {
                  addPrice();
                  handleClose1();
                }
              }}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ITINERAY */}

        <Modal show={showIN} onHide={() => setshowIN(false)}>
          <Modal.Body>
            <from>
              <label className="pb-2 nd-2">
                Title Day {ITINERARY.length + 1}
              </label>
              <br />
              <input
                type="text"
                className="title-90 mb-2"
                name="packagename"
                value={dayName}
                required=""
                placeholder="Please enter title "
                onChange={(e) => setdayName(e.target.value)}
              />
              <label className="pb-2 nd-2">Details</label>
              <br />
              <CKEditor
                editor={ClassicEditor}
                data={details}
                onChange={handleChange6}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </from>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setshowIN(false)}>
              Back
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                {
                  AddDataITINERARY();
                }
              }}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Includes  */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Body>
            <from>
              <label className="pb-2 nd-2">Includes</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="include"
                placeholder="Enter Includes"
                value={inclTest}
                onChange={(e) => setinclText(e.target.value)}
                required
              />
            </from>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Back
            </Button>
            <Button variant="primary" onClick={addInclude}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Excludes  */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Body>
            <from>
              <label className="pb-2 nd-2">Excludes</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="excludes"
                value={exText}
                placeholder="Enter Excludes"
                required
                onChange={(e) => setexText(e.target.value)}
              />
            </from>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Back
            </Button>
            <Button variant="primary" onClick={addExclude}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>

        {/* table */}
        <div className="">
          <div className="searchHeadFlex">
            <div className="searchWrapper" style={{ display: "flex" }}>
              <input
                type="text"
                maxlength="230"
                style={{ outlineWidth: "0px", padding: "4px" }}
                className="textsearch"
                placeholder="Search train"
                onChange={handleFilter}
              />
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 16 16"
                id="BsSearch"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                  clip-rule="evenodd"
                ></path>
                <path
                  fill-rule="evenodd"
                  d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>From</th>
                <th>To</th>
                <th>Train Name</th>
                <th>Train Profile</th>
                <th>Category</th>
                <th>Starting Price</th>
                <th>Train Timing</th>

                <th>Contact</th>
                <th>Email</th>

                <th></th>
                <th>Action</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {search?.length > 0 ? (
                tableFilter
                  ?.slice(pagesVisited, pagesVisited + usersPerPage)
                  ?.map((element, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{element?.location}</td>
                        <td>{element?.to}</td>
                        <td>{element.trainName}</td>
                        <td>
                          <img
                            src={`https://dhanyatours.me/train/${element.trainProfile}`}
                            alt="lodge-image"
                            style={{ width: "100%" }}
                          />
                        </td>
                        <td>{element.category}</td>
                        <td>{element.StartingPrice}</td>
                        <td>{element.trainTiming}</td>
                        {/* <td>{element.location}</td> */}
                        <td>{element.contact}</td>
                        <td>{element.email}</td>

                        <td style={{ display: "flex", gap: "5px" }}>
                          <div
                            className=""
                            onClick={() => {
                              setedit(element);
                              setlocation(element?.location);
                              setto(element?.to);
                              settrainname(element.trainName);
                              setstartingPrice(element?.StartingPrice);
                              settiming(element.trainTiming);
                              setcontact(element?.contact);
                              settrainemail(element?.email);
                              setTermCondition(element?.termAndCondition);
                              setsuggetion(element?.suggetion);
                              getbyLodgeId(element._id);
                              settraincategory(element?.category);
                              settrainoverview(element?.OVERVIEW);
                              handleShow4();
                            }}
                          >
                            <FaEdit
                              style={{
                                color: "#5600ff",
                                cursor: "pointer",
                                fontSize: "26px",
                              }}
                            />
                          </div>
                          <div>
                            <button
                              type="button"
                              class="btn btn-info"
                              onClick={() => {
                                setImagesData(element);
                                setshowcalender(true);
                              }}
                            >
                              {" "}
                              Calender{" "}
                            </button>
                          </div>
                          <div>
                            <button
                              type="button"
                              class="btn btn-success"
                              onClick={() => {
                                setImagesData(element);
                                hsndleImages();
                              }}
                            >
                              {" "}
                              Gallery{" "}
                            </button>
                          </div>
                        </td>
                        <td>
                          {" "}
                          {element?.isBloc === false ? (
                            <button
                              type="button"
                              onClick={() => MakeBlock(element?._id, true)}
                              class="btn btn-danger"
                            >
                              Block
                            </button>
                          ) : (
                            <button
                              type="button"
                              class="btn btn-warning"
                              onClick={() => MakeBlock(element?._id, false)}
                            >
                              Unblock
                            </button>
                          )}
                        </td>
                        <td>
                          <div
                            className=""
                            onClick={() => {
                              handleShow6();
                              setDeleteId(element?._id);
                            }}
                          >
                            <AiFillDelete
                              style={{
                                color: "red",
                                cursor: "pointer",
                                fontSize: "26px",
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <>
                  {gettrain
                    ?.slice(pagesVisited, pagesVisited + usersPerPage)
                    ?.map((element, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{element?.location}</td>
                          <td>{element?.to}</td>
                          <td>{element.trainName}</td>
                          <td>
                            <img
                              src={`https://dhanyatours.me/train/${element.trainProfile}`}
                              alt="lodge-image"
                              style={{ width: "100%" }}
                            />
                          </td>
                          <td>{element.category}</td>
                          <td>{element.StartingPrice}</td>
                          <td>{element.trainTiming}</td>
                          {/* <td>{element.location}</td> */}
                          <td>{element.contact}</td>
                          <td>{element.email}</td>

                          <td style={{ display: "flex", gap: "5px" }}>
                            <div
                              className=""
                              onClick={() => {
                                setedit(element);
                                setlocation(element?.location);
                                setto(element?.to);
                                settrainname(element.trainName);
                                setstartingPrice(element?.StartingPrice);
                                settiming(element.trainTiming);
                                setcontact(element?.contact);
                                settrainemail(element?.email);
                                setTermCondition(element?.termAndCondition);
                                setsuggetion(element?.suggetion);
                                getbyLodgeId(element._id);
                                settraincategory(element?.category);
                                settrainoverview(element?.OVERVIEW);
                                handleShow4();
                              }}
                            >
                              <FaEdit
                                style={{
                                  color: "#5600ff",
                                  cursor: "pointer",
                                  fontSize: "26px",
                                }}
                              />
                            </div>
                            <div>
                              <button
                                type="button"
                                class="btn btn-info"
                                onClick={() => {
                                  setImagesData(element);
                                  setshowcalender(true);
                                }}
                              >
                                {" "}
                                Calender{" "}
                              </button>
                            </div>
                            <div>
                              <button
                                type="button"
                                class="btn btn-success"
                                onClick={() => {
                                  setImagesData(element);
                                  hsndleImages();
                                }}
                              >
                                {" "}
                                Gallery{" "}
                              </button>
                            </div>
                          </td>
                          <td>
                            {" "}
                            {element?.isBloc === false ? (
                              <button
                                type="button"
                                onClick={() => MakeBlock(element?._id, true)}
                                class="btn btn-danger"
                              >
                                Block
                              </button>
                            ) : (
                              <button
                                type="button"
                                class="btn btn-warning"
                                onClick={() => MakeBlock(element?._id, false)}
                              >
                                Unblock
                              </button>
                            )}
                          </td>
                          <td>
                            <div
                              className=""
                              onClick={() => {
                                handleShow6();
                                setDeleteId(element?._id);
                              }}
                            >
                              <AiFillDelete
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  fontSize: "26px",
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </Table>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>

        {/* delete model */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete?</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose6}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onDeletetain}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Update Train Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="pb-2 nd-2">Form</label>
                    <br />
                    <input
                      type="text"
                      className="title-90"
                      name="location"
                      required
                      placeholder={edit?.location}
                      value={location}
                      onChange={(e) => {
                        setlocation(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="pb-2 nd-2">To</label>
                    <br />
                    <input
                      type="text"
                      className="title-90"
                      name="location"
                      required
                      placeholder={edit?.to}
                      value={to}
                      onChange={(e) => {
                        setto(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <label className="pb-2 nd-2">Train Name</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  name="trainname"
                  required
                  placeholder={edit?.trainName}
                  value={trainname}
                  onChange={(e) => {
                    settrainname(e.target.value);
                  }}
                />
                <label className="pb-2 nd-2" htmlFor="upload1">
                  Train Profile
                </label>
                <br />
                <input
                  type="file"
                  className="title-90"
                  name="trainprofile"
                  required
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => settrainProfile(e.target.files[0])}
                />
                <label className="pb-2 nd-2"> Category</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  name="price"
                  placeholder={edit?.category}
                  value={traincategory}
                  onChange={(e) => {
                    settraincategory(e.target.value);
                  }}
                />
                <label className="pb-2 nd-2">StartingPrice</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  name="price"
                  required
                  placeholder={edit?.StartingPrice}
                  value={startingPrice}
                  onChange={(e) => {
                    setstartingPrice(e.target.value);
                  }}
                />
                <label className="pb-2 nd-2">Train Timing</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  name="timing"
                  placeholder={edit?.trainTiming}
                  value={timing}
                  onChange={(e) => {
                    settiming(e.target.value);
                  }}
                />

                <label className="pb-2 nd-2">Contact</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  name="contact"
                  placeholder={edit?.contact}
                  value={contact}
                  onChange={(e) => {
                    setcontact(e.target.value);
                  }}
                />
                <label className="pb-2 nd-2">Email</label>
                <br />
                <input
                  type="email"
                  className="title-90"
                  name="email"
                  placeholder={edit?.email}
                  value={trainemail}
                  onChange={(e) => {
                    settrainemail(e.target.value);
                  }}
                />
                <div className="row">
                  <label className="pb-2 nd-2" style={{ fontWeight: "bold" }}>
                    Pricing
                  </label>

                  <div className="col-6">
                    <input
                      type="text"
                      className="title-90 mb-2"
                      name="packagename"
                      value={Packagename}
                      placeholder="Package Name"
                      onChange={(e) => setPackagename(e.target.value)}
                    />
                  </div>
                  <div className="col-6">
                    <button
                      type="button"
                      style={{
                        float: "right",
                        margin: "2px",
                        padding: "1px 7px 0px",
                      }}
                      onClick={addPricing}
                      class="btn btn-outline-info"
                    >
                      Add
                    </button>
                  </div>

                  <div className="col-6">
                    <label className="pb-2 nd-2">Package Price In INR</label>
                    <br />
                    <input
                      type="text"
                      className="title-90 mb-2"
                      name="weekprice"
                      value={Singleweekprice}
                      required=""
                      placeholder=" In INR/person"
                      onChange={(e) => setSingleweekprice(e.target.value)}
                    />
                  </div>

                  <div className="col-6">
                    <label className="pb-2 nd-2"> Package Price In USD </label>
                    <br />
                    <input
                      type="text"
                      className="title-90 mb-2"
                      name="weekendprice"
                      value={Doublyweekprice}
                      required=""
                      placeholder="In USD/person"
                      onChange={(e) => setDoublyweekprice(e.target.value)}
                    />
                  </div>

                  {edit?.PRICING?.map((item) => {
                    return (
                      <div className="row">
                        <div className="col-6">
                          {" "}
                          <label className="pb-2 nd-2">
                            {item?.packageName}
                          </label>
                        </div>
                        <div className="col-6">
                          {" "}
                          <CiCircleRemove
                            style={{
                              color: "red",
                              cursor: "pointer",
                              fontSize: "20px",
                            }}
                            onClick={() => deletePricing(item._id)}
                          />
                        </div>
                        <div className="col-6">₹ {item?.singleWeekPrice}</div>
                        <div className="col-6">$ {item?.doublyWeekPrice}</div>
                      </div>
                    );
                  })}
                </div>
                <div className="row">
                  <label className="pb-2 nd-2" style={{ fontWeight: "bold" }}>
                    Itenrary
                  </label>

                  <div className="col-md-8">
                    <input
                      type="text"
                      className="title-90 mb-2"
                      name="packagename"
                      value={dayName}
                      placeholder={`Enter Title day ${
                        edit?.ITINERARY?.length + 1
                      }`}
                      onChange={(e) => setdayName(e.target.value)}
                    />
                  </div>
                  <div className="col-4">
                    <button
                      type="button"
                      style={{
                        float: "right",
                        margin: "2px",
                        padding: "1px 7px 0px",
                      }}
                      onClick={ITINERARYAddEdit}
                      class="btn btn-outline-info"
                    >
                      Add
                    </button>
                  </div>

                  <div className="col-12">
                    <label className="pb-2 nd-2">Day Details</label>
                    <br />
                    <CKEditor
                      editor={ClassicEditor}
                      data={details}
                      onChange={handleChange6}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                    />
                  </div>

                  {edit?.ITINERARY?.map((item, i) => {
                    return (
                      <div className="row">
                        <div className="col-6">
                          {" "}
                          <label className="pb-2 nd-2">
                            Day {i + 1} : {item?.dayName}
                          </label>
                        </div>
                        <div className="col-6">
                          {" "}
                          <CiCircleRemove
                            style={{
                              color: "red",
                              cursor: "pointer",
                              fontSize: "20px",
                            }}
                            onClick={() => deleteIntenery(item._id)}
                          />
                        </div>
                        <div className="col-12">
                          {" "}
                          {item?.details ? parse(item?.details) : ""}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="row">
                  <div className="col-6">
                    <label className="pb-2 nd-2" style={{ fontWeight: "bold" }}>
                      Includes
                    </label>
                  </div>
                  <div className="col-6">
                    <button
                      type="button"
                      style={{
                        float: "right",
                        margin: "2px",
                        padding: "1px 7px 0px",
                      }}
                      class="btn btn-outline-info"
                      onClick={addIncludeA}
                    >
                      Add
                    </button>
                  </div>
                  <input
                    type="text"
                    className="title-90"
                    name="include"
                    placeholder="Write your includes one by one."
                    value={inclTest}
                    onChange={(e) => setinclText(e.target.value)}
                    required
                  />
                  <ol style={{ marginLeft: "15px" }}>
                    {edit?.Includes?.map((item) => {
                      return (
                        <li>
                          {item?.text}{" "}
                          <span>
                            {" "}
                            <CiCircleRemove
                              style={{
                                color: "red",
                                cursor: "pointer",
                                fontSize: "20px",
                              }}
                              onClick={() => deleteIncludes(item?._id)}
                            />
                          </span>
                        </li>
                      );
                    })}{" "}
                  </ol>
                </div>
                <div className="row">
                  <div className="col-6">
                    <label className="pb-2 nd-2" style={{ fontWeight: "bold" }}>
                      Excludes
                    </label>
                  </div>
                  <div className="col-6">
                    <button
                      type="button"
                      style={{
                        float: "right",
                        margin: "2px",
                        padding: "1px 7px 0px",
                      }}
                      class="btn btn-outline-info"
                      onClick={addExcludesA}
                    >
                      Add
                    </button>
                  </div>
                  <input
                    type="text"
                    className="title-90"
                    name="include"
                    placeholder="Write your excludes one by one..."
                    value={exText}
                    required
                    onChange={(e) => setexText(e.target.value)}
                  />
                  <ol style={{ marginLeft: "15px" }}>
                    {edit?.Excludes?.map((item) => {
                      return (
                        <li>
                          {item?.text1}{" "}
                          <span>
                            {" "}
                            <CiCircleRemove
                              style={{
                                color: "red",
                                cursor: "pointer",
                                fontSize: "20px",
                              }}
                              onClick={() => deleteExcludes(item?._id)}
                            />
                          </span>
                        </li>
                      );
                    })}{" "}
                  </ol>
                </div>

                <label className="pb-2 nd-2">Overview</label>
                <br />
                <CKEditor
                  editor={ClassicEditor}
                  data={trainoverview}
                  onChange={handleChange1}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
                <label className="pb-2 nd-2">Suggetion</label>
                <br />
                <CKEditor
                  editor={ClassicEditor}
                  data={suggetion}
                  onChange={handleChange3}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
                <label className="pb-2 nd-2">Terms&Condition</label>
                <br />
                <CKEditor
                  editor={ClassicEditor}
                  data={termCondition}
                  onChange={handleChange4}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />

                <br />
                <Modal show={show1} onHide={handleClose1}>
                  <Modal.Body>
                    <from>
                      <label className="pb-2 nd-2">Package Name</label>
                      <br />
                      <input
                        type="text"
                        className="title-90 mb-2"
                        name="packagename"
                        value={Packagename}
                        required=""
                        onChange={(e) => setPackagename(e.target.value)}
                      />
                      <label className="pb-2 nd-2"> Doubly Week Price</label>
                      <br />
                      <input
                        type="text"
                        className="title-90 mb-2"
                        name="weekprice"
                        value={Doublyweekprice}
                        required=""
                        onChange={(e) => setDoublyweekprice(e.target.value)}
                      />
                      <label className="pb-2 nd-2"> Doubly Weekend Price</label>
                      <br />
                      <input
                        type="text"
                        className="title-90 mb-2"
                        name="weekendprice"
                        value={Doublyweekendprice}
                        onChange={(e) => setDoublyweekendprice(e.target.value)}
                      />
                      <label className="pb-2 nd-2"> Single Week Price</label>
                      <br />
                      <input
                        type="text"
                        className="title-90 mb-2"
                        name="sigaleweekprice"
                        value={Singleweekprice}
                        required=""
                        onChange={(e) => setSingleweekprice(e.target.value)}
                      />
                      <label className="pb-2 nd-2"> Single Weekend Price</label>
                      <br />
                      <input
                        type="text"
                        className="title-90 mb-2"
                        name="singaleweekendprice"
                        value={Singleweekendprice}
                        required=""
                        onChange={(e) => setSingleweekendprice(e.target.value)}
                      />
                    </from>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleClose1}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={show2} onHide={handleClose2}>
                  <Modal.Body>
                    <from>
                      <label className="pb-2 nd-2">Includes</label>
                      <br />
                      <input
                        type="text"
                        className="title-90"
                        name="include"
                        value=""
                        required
                      />
                    </from>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                      Back
                    </Button>
                    <Button variant="primary" onClick={handleClose2}>
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={show3} onHide={handleClose3}>
                  <Modal.Body>
                    <from>
                      <label className="pb-2 nd-2">Excludes</label>
                      <br />
                      <input
                        type="text"
                        className="title-90"
                        name="excludes"
                        value=""
                        required
                      />
                    </from>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose3}>
                      Back
                    </Button>
                    <Button variant="primary" onClick={handleClose3}>
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleClose4()}>
              Back
            </Button>
            <Button variant="primary" onClick={() => onEditTrain()}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Images Model */}
        <Modal show={showImages} onHide={closeImages} size="lg">
          <Modal.Header closeButton>
            <h4
              style={{
                color: "red",
                fontWeight: "bold",
                position: "absolute",
                left: "40%",
              }}
            >
              Luxury Train Gallery{" "}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6">
                <input
                  type="file"
                  className="title-90"
                  name="lodgename"
                  accept="image/*"
                  required=""
                  onChange={(e) => setimage3(e.target.files[0])}
                />
              </div>
              <div className="col-md-6">
                <button
                  type="button"
                  class="btn btn-dark"
                  onClick={AddSingleImagesHotel}
                >
                  Add Now
                </button>
              </div>
            </div>
            <p>Note:- Minimum 4 images and maximum 10 images.</p>
            <hr></hr>

            <div className="row">
              {imagesData?.trainImage?.map((item) => {
                return (
                  <div
                    className="col-md-4"
                    style={{
                      display: "flex",
                      gap: "3px",
                      marginBottom: "10px",
                    }}
                  >
                    <div>
                      <img
                        src={"https://dhanyatours.me/train/" + item?.image}
                        style={{ width: "200px", height: "210px" }}
                      />{" "}
                    </div>
                    <div>
                      <AiFillDelete
                        style={{
                          fontSize: "25px",
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => deleteHotelImages(item?._id)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeImages}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {/* Price view model Model */}
      <Modal
        show={showCalender}
        onHide={() => setshowcalender(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <h4
            style={{
              color: "red",
              fontWeight: "bold",
              position: "absolute",
              left: "35%",
            }}
          >
            Luxury Train Calender{" "}
          </h4>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="row">
            <div className="col-md-12">
              <button
                type="button"
                class="btn btn-dark"
                onClick={() => {
                  setmonthD("");
                  setdate("");
                  setday("");
                  settime("");
                  setAddpM(true);
                }}
                style={{ float: "right" }}
              >
                Add Now
              </button>
            </div>
          </div>
          <p style={{ float: "right" }}>Note:- Add Calender One By One.</p>
          <br />
          <hr></hr>
          <hr></hr>
          <div className="">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Months</th>
                  <th>Date</th>
                  <th>Day</th>
                  <th>Time</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {imagesData?.CalenderData?.map((item, i) => {
                  return (
                    <tr>
                      <td>{item?.monthD}</td>
                      <td>{item?.date}</td>
                      <td>{item?.day}</td>
                      <td>{item?.time}</td>
                      <td>
                        {item?.ClStatus == true ? (
                          <span style={{ color: "red" }}>Blocked</span>
                        ) : (
                          <span style={{ color: "green" }}>Un-Blocked</span>
                        )}
                      </td>
                      <td>
                        <div style={{ display: "flex", gap: "3px" }}>
                          <BiEdit
                            style={{
                              color: "blue",
                              fontSize: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setmonthD(item?.monthD);
                              setdate(item?.date);
                              setday(item?.day);
                              settime(item?.time);
                              setst(item?.ClStatus);
                              setclendId(item?._id);
                              setupdateM(true);
                            }}
                          />{" "}
                          {item?.ClStatus == true ? (
                            <button
                              type="button"
                              class="btn btn-success"
                              onClick={() =>
                                makeBlockUnbolckCalender(
                                  item?._id,
                                  item?.monthD,
                                  item?.date,
                                  item?.day,
                                  item?.time,
                                  false
                                )
                              }
                            >
                              Unblock
                            </button>
                          ) : (
                            <button
                              type="button"
                              class="btn btn-danger"
                              onClick={() =>
                                makeBlockUnbolckCalender(
                                  item?._id,
                                  item?.monthD,
                                  item?.date,
                                  item?.day,
                                  item?.time,
                                  true
                                )
                              }
                            >
                              Block
                            </button>
                          )}
                          <RiDeleteBin6Line
                            style={{
                              color: "red",
                              fontSize: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setclendId(item?._id);
                              setPackDeletM(true);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshowcalender(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete model */}
      <Modal show={packDeleM} onHide={() => setPackDeletM(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setPackDeletM(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deleteCalenderData}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Package Add Model */}
      <Modal show={AddpM} onHide={() => setAddpM(false)}>
        <Modal.Header closeButton>
          <h4
            style={{
              color: "red",
              fontWeight: "bold",
              position: "absolute",
              left: "40%",
            }}
          >
            Add Calender Details{" "}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <from>
            <label className="pb-2 nd-2">Month</label>
            <br />
            <select
              className="title-90 mb-2"
              onChange={(e) => setmonthD(e.target.value)}
            >
              <option value="">Select Month</option>
              {monthsData?.map((item, i) => {
                return (
                  <option value={item}>
                    {i + 1}. {item}
                  </option>
                );
              })}
            </select>

            <label className="pb-2 nd-2">Date</label>
            <br />
            <input
              type="date"
              className="title-90 mb-2"
              name="sigaleweekprice"
              value={date}
              required=""
              onChange={(e) => setdate(e.target.value)}
            />
            <label className="pb-2 nd-2"> Day</label>
            <br />
            <select
              className="title-90 mb-2"
              onChange={(e) => setday(e.target.value)}
            >
              <option value="">Select days</option>
              {dayData?.map((item, i) => {
                return (
                  <option value={item}>
                    {i + 1}. {item}
                  </option>
                );
              })}
            </select>
            <label className="pb-2 nd-2">Time</label>
            <br />
            <input
              type="time"
              className="title-90 mb-2"
              name="weekprice"
              value={time}
              required=""
              placeholder="Please enter Double Occupancy Week Price"
              onChange={(e) => settime(e.target.value)}
            />
          </from>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setAddpM(false)}>
            Back
          </Button>
          <Button variant="primary" onClick={() => addCalenderData()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Package Update Model */}
      <Modal show={updateM} onHide={() => setupdateM(false)}>
        <Modal.Header closeButton>
          <h4
            style={{
              color: "red",
              fontWeight: "bold",
              position: "absolute",
              left: "25%",
            }}
          >
            Update Calender details{" "}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <from>
            <label className="pb-2 nd-2">Month</label>
            <br />
            <select
              className="title-90 mb-2"
              onChange={(e) => setmonthD(e.target.value)}
            >
              <option value={monthD}>{monthD}</option>
              {monthsData?.map((item, i) => {
                return (
                  <option value={item}>
                    {i + 1}. {item}
                  </option>
                );
              })}
            </select>

            <label className="pb-2 nd-2">Date</label>
            <br />
            <input
              type="date"
              className="title-90 mb-2"
              name="sigaleweekprice"
              value={date}
              required=""
              onChange={(e) => setdate(e.target.value)}
            />
            <label className="pb-2 nd-2"> Day</label>
            <br />
            <select
              className="title-90 mb-2"
              onChange={(e) => setday(e.target.value)}
            >
              <option value={day}>{day}</option>
              {dayData?.map((item, i) => {
                return (
                  <option value={item}>
                    {i + 1}. {item}
                  </option>
                );
              })}
            </select>
            <label className="pb-2 nd-2">Time</label>
            <br />
            <input
              type="time"
              className="title-90 mb-2"
              name="weekprice"
              value={time}
              required=""
              placeholder="Please enter Double Occupancy Week Price"
              onChange={(e) => settime(e.target.value)}
            />
          </from>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setupdateM(false)}>
            Back
          </Button>
          <Button variant="primary" onClick={() => updatedCalender()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LuxuryTrain;
