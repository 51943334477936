import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "./product.css";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineArrowRight, MdOutlineArrowDropDown } from "react-icons/md";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";

function AllDestination() {
  const [acc, setacc] = useState(true);
  const [AllLodge, setAllLodge] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);

  const getAllLodge = async () => {
    const config = {
      url: "/getAllLodge",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setAllLodge(
          result.data.success?.filter((data) => data?.isBloc === false)
        );
        setnochangedata(
          result.data.success?.filter((data) => data?.isBloc === false)
        );
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  const [AllCategory, setAllCategory] = useState([]);
  const getAllCategory = async () => {
    const config = {
      url: "/getAllCategory",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setAllCategory(result.data.success);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllLodge();
    getAllCategory();
  }, []);

  const [cat, setcat] = useState("");
  const history = useNavigate();

  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllLodge([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAllLodge([...nochangedata]);
    }
  };

  const slectHandle = (ab) => {
    if (ab == "High to Low") {
      let am = nochangedata.sort((a, b) => b.StartingPrice - a.StartingPrice);
      setAllLodge([...am]);
    }
    if (ab == "Low to High") {
      let am = nochangedata.sort((a, b) => a.StartingPrice - b.StartingPrice);
      setAllLodge([...am]);
    }
    if (ab == "") {
      getAllLodge();
    }
  };
  const { state } = useLocation();
  useEffect(() => {
    if (state?.cat) {
      setacc(false);
      setcat(state.cat);
    }
  }, []);
  return (
    <>
      <div>
        <section>
          <div className="bgs">
            <div
              className=""
              style={{
                backgroundImage: 'url("../Image/package-2.jpg")',
                height: "300px",
                backgroundSize: "cover",
               
              }}
            >
              <div className="fghi">
                <div
                  style={{
                    color: "#004aad",
                    fontSize: "38px",
                    fontWeight: "800",
                  }}
                >
                  {/* Packages */}
                </div>
              </div>
            </div>
          </div>
          <div className="product-spa mx-5 mt-3">
            <div className="">
              <div className="left-right">
                <div className="left-0">
                  <div className="categories">
                    <div className="de-top">
                      <h4 style={{ fontSize: "21px", fontWeight: "600" }}>
                        Category
                      </h4>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <ul className="p-0" style={{ cursor: "pointer" }}>
                      <li>
                        Jungal Loadge{" "}
                        {acc ? (
                          <>
                            <span
                              className=""
                              style={{ float: "right" }}
                              onClick={() => {
                                setacc(!acc);
                              }}
                            >
                              <MdOutlineArrowDropDown
                                style={{ fontSize: "30px", color: "white" }}
                              />
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              className=""
                              style={{ float: "right" }}
                              onClick={() => {
                                setacc(!acc);
                              }}
                            >
                              <MdOutlineArrowDropDown
                                style={{ fontSize: "30px", color: "white" }}
                              />
                            </span>
                          </>
                        )}
                      </li>
                      {acc ? (
                        <></>
                      ) : (
                        <>
                          <ul>
                            {AllCategory?.map((item) => {
                              return (
                                <li
                                  style={{
                                    fontSize: "14px",
                                    backgroundColor:
                                      cat == item?.category ? "red" : "",
                                  }}
                                  onClick={() => setcat(item?.category)}
                                >
                                  {item?.category}
                                </li>
                              );
                            })}

                            {/* <li style={{ fontSize: "14px" }}>Adventure</li>
                            <li style={{ fontSize: "14px" }}>Beach</li>
                            <li style={{ fontSize: "14px" }}>Nature</li> */}
                          </ul>
                        </>
                      )}
                      <li onClick={() => history("/taxi-details")}>
                        Hire Taxi{" "}
                        <span className="" style={{ float: "right" }}>
                          <MdOutlineArrowRight
                            style={{ fontSize: "30px", color: "white" }}
                          />
                        </span>
                      </li>
                      <li onClick={() => history("/luxury-train")}>
                        Luxuri Train{" "}
                        <span className="" style={{ float: "right" }}>
                          <MdOutlineArrowRight
                            style={{ fontSize: "30px", color: "white" }}
                          />
                        </span>
                      </li>
                      <li onClick={() => history("/hotels")}>
                        Hotel{" "}
                        <span className="" style={{ float: "right" }}>
                          <MdOutlineArrowRight
                            style={{ fontSize: "30px", color: "white" }}
                          />
                        </span>
                      </li>
                      <li onClick={() => history("/resorts")}>
                        Resort{" "}
                        <span className="" style={{ float: "right" }}>
                          <MdOutlineArrowRight
                            style={{ fontSize: "30px", color: "white" }}
                          />
                        </span>
                      </li>
                      <li onClick={() => history("/home-stay")}>
                        Home Stay{" "}
                        <span className="" style={{ float: "right" }}>
                          <MdOutlineArrowRight
                            style={{ fontSize: "30px", color: "white" }}
                          />
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="right-0">
                  <div className="search-drive">
                    <div style={{ display: "flex" }}>
                      <div class="form-outline">
                        <input
                          id="search-input"
                          type="search"
                          onChange={handleFilter}
                          placeholder="Search by City,Package,Resorts..."
                          style={{ outlineWidth: "0px" }}
                          class="form-control"
                          //   value={search}
                          //   onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                      <button
                        id="search-button"
                        type="button"
                        class="btn btn-primary"
                        style={{ height: "52px", borderRadius: "inherit" }}
                      >
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                    <div className="spa-item mt-2">
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => setcat(e.target.value)}
                      >
                        <option value="">Choose...</option>
                        {AllCategory?.map((item) => {
                          return (
                            <option value={item?.category}>
                              {item?.category}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                    <div className="spa-item mt-2">
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => slectHandle(e.target.value)}
                      >
                        <option value="">Popular</option>
                        <option value="High to Low">High to Low</option>
                        <option value="Low to High">Low to High</option>
                      </Form.Select>
                    </div>
                  </div>
                  <div className="wild-0">
                    <div className="container">
                      <div className="wild-life-0">
                        <div className="lion">
                          <p
                            style={{
                              fontSize: "30px",
                              fontWeight: "600",
                              marginTop: "20px",
                              color: "#004aad",
                            }}
                          >
                            {cat}
                          </p>
                          <div
                            style={{
                              float: "right",
                              width: "100%",
                            }}
                          >
                            <hr style={{ color: "red", opacity: "3" }} />
                          </div>
                        </div>
                        {AllLodge?.filter((ele) =>
                          cat ? ele.category == cat : ele
                        )?.map((items) => {
                          return (
                            <div className="rhlep">
                              <div className="rhlepd">
                                <div className="container">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="wild-img">
                                        <img
                                          src={`https://dhanyatours.me/lodge/${items?.lodgeProfile}`}
                                          alt=""
                                          style={{
                                            width: "275px",
                                            height: "289px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="wild-img-content">
                                        <h5 style={{ fontSize: "25px" }}>
                                          {items?.lodgeName?.slice(0,30)}
                                        </h5>
                                        <p>
                                          {items?.OVERVIEW
                                            ? parse(
                                                items?.OVERVIEW?.slice(0, 70)
                                              )
                                            : ""}
                                          ...
                                        </p>
                                        <p class="bottom-area d-flex">
                                          <span>
                                            <IoLocationOutline
                                              style={{ fontSize: "15px" }}
                                            />{" "}
                                            {items?.location?.slice(0, 70)}
                                          </span>
                                        </p>
                                        <div
                                          className="active-b"
                                          style={{
                                            position: "absolute",
                                            bottom: "15px",
                                          }}
                                        >
                                          <Link
                                            to={`/wildlife-singal/${items?._id}`}
                                          >
                                            <Button>View details</Button>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="price-wild text-end">
                                        <p>Price Starts from</p>
                                        <div
                                          className="price-0"
                                          // style={{ lineHeight: "30px" }}
                                        >
                                          ₹ {items?.StartingPrice?.toFixed(2)}(
                                          {items?.Includes}).
                                        </div>
                                        <span>Per Person on twin sharing</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        {/* <div className="rhlep">
                          <Link to="/wildlife-singal">
                            <div className="rhlepd">
                              <div className="wild-img">
                                <img
                                  src="../Image/hotel2.jpg"
                                  alt=""
                                  style={{ width: "275px", height: "289px" }}
                                />
                              </div>
                              <div className="wild-img-content">
                                <p style={{ fontSize: "25px" }}>
                                  Kabini River Lodge
                                </p>
                                <p>
                                  Named after the river Kabini, the lodge
                                  beckons with the promise of elephants, gaurs,
                                  deer and flaunts the mesmerizing tiger.
                                </p>

                                <div
                                  className="active-b"
                                  style={{
                                    position: "absolute",
                                    bottom: "15px",
                                  }}
                                >
                                  <Button>View details</Button>
                                </div>
                              </div>
                              <div className="price-wild text-end">
                                <p>Price Starts from</p>
                                <div
                                  className="price-0"
                                  style={{ lineHeight: "30px" }}
                                >
                                  ₹ 8,776 (all inclusive including Safari).
                                </div>
                                <span>Per Person on twin sharing</span>
                                <div className="active-b">
                                  <Button>Book Now</Button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AllDestination;
