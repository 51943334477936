import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

function TaxiStation() {
  const history = useNavigate();
  const location = useLocation();
  const [fname, setfname] = useState("");
  const [email, setemail] = useState("");
  const [mbnumber, setmbnumber] = useState("");
  const [address, setaddress] = useState("");
  const [Taxi, setTaxi] = useState([]);
  const taxi = async () => {
    const config = {
      url: "/alltexi",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setTaxi(result.data.success);
        // getGst();
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  }

  useEffect(() => {
    taxi()
  }, []);
  // console.log(gst, "gfuiuew");



  const gstPrice =location?.state?.gst;

  const onCreate = async () => {
    const config = {
      url: "/createOrder",
      method: "post",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "conttent-type": "application/json" },
      data: {
        userName: fname,
        email: email,
        number: mbnumber,
        picUpPlace: location?.state?.pickupplace,
        picUpTime: moment(location?.state?.picktime, ["HH:mm:ss"]).format(
          "hh:mm a"
        ),
        picUpDate: moment(location?.state?.pickdate).format("DD/MM/YYYY"),
        packagePrice: location?.state.basePrice,
        bata: location?.state?.driverBata,
        totalAmount: location?.state?.packagePrice,
        dropPlace: location?.state?.dropplace,
        FixedPrice: location?.state?.packagePrice,
        packageName: location?.state?.packageName,
        gst: gstPrice,
        dropPlace: location.state?.dropplace,
        tripType: location?.state?.tripType,
        basePrice: location?.state.basePrice,
        TripData:JSON.stringify(location?.state?.id),
        vechicleId:Taxi?.filter((item)=>item?.category==location.state?.id?.category)[0]?._id,
        totalKm:location?.state?.totalKm,
   
      },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        // setgst(result.data.success);
        alert("Successfully Booking");
        history("/");
  
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log("erore", error);
      alert(error.response.data.error);
    }
  };
  return (
    <>
      <div className="inner-content-listing-desc" style={{ padding: "80px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="over-taxi" style={{ fontSize: "41px" }}>
                {location.state?.tripType} Taxi Hire
              </h1>
              <h5>
                From {location.state.pickupplace} to {location.state.dropplace}{" "}
                on {moment(location.state.pickdate).format("DD/MM/YYYY")}{" "}
                {moment(location.state.picktime, ["HH:mm:ss"]).format(
                  "hh:mm a"
                )}
              </h5>
              <div className="mt-3">
                <div className="row">
                  <div className="col-md-5">
                    <h4 className="over-taxi">Booking Details</h4>
                    <form>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control-inner"
                          name="Last Name"
                          placeholder="Full Name"
                          value={fname}
                          required
                          onChange={(e) => {
                            setfname(e.target.value);
                          }}
                        />
                      </div>
                      <div className="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control-inner"
                              name="Email"
                              placeholder="Email ID"
                              required
                              value={email}
                              onChange={(e) => {
                                setemail(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control-inner"
                              name="Mobile"
                              placeholder="10 Digit Mobile No."
                              required
                              value={mbnumber}
                              onChange={(e) => {
                                setmbnumber(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div class="form-group">
                        <input
                          type="text"
                          class="form-control-inner"
                          name="Street"
                          id="Street"
                          placeholder="Your Pickup Address"
                          required
                          value={address}
                          onChange={(e) => {
                            setaddress(e.target.value);
                          }}
                        />
                      </div> */}

                      <div class="col-sm-6">
                        <Button
                          onClick={() => {
                            onCreate();
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-7">
                    <div class="">
                      <h4 className="over-taxi">Booking Summary</h4>
                      <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                          <tbody>
                            <tr>
                              <td>
                                <strong>Pickup Place</strong>
                              </td>
                              <td>{location?.state?.pickupplace}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Drop Place</strong>
                              </td>
                              <td>{location.state.dropplace}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Pickup Date/Time:</strong>&nbsp;
                              </td>
                              <td>
                                {moment(location.state.pickdate).format(
                                  "DD/MM/YYYY"
                                )}{" "}
                                {moment(location.state.picktime, [
                                  "HH:mm:ss",
                                ]).format("hh:mm a")}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Vehicle Type</strong>&nbsp;
                              </td>
                              <td>{location?.state.id?.category} ({location?.state.id?.VehicleType})</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Vehicle</strong>
                              </td>
                              <td>{location?.state.id?.VehicleModel}</td>
                            </tr>
                            {location?.state?.tripType=="Local Drop" ? (<>  <tr>
                              <td>
                                <strong>Package Name</strong>
                              </td>
                              <td>{location?.state?.packageName}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Package Price{" ₹ "}</strong>
                              </td>
                              <td>{location?.state?.basePrice}</td>
                            </tr></>):( <tr>
                              <td>
                                <strong>Base Price{" ₹ "}</strong>
                              </td>
                              <td>{location?.state?.basePrice}</td>
                            </tr>)}
                          
                           
                            <tr>
                              <td>
                                <strong>Driver Bata{" ₹ "}</strong>
                              </td>
                              <td>{location?.state?.driverBata}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>GST{" ₹ "}</strong>
                              </td>
                              <td>{gstPrice}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Total Price{" ₹ "} </strong>
                              </td>
                              <td>{location?.state?.packagePrice?.toFixed(2)} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TaxiStation;
