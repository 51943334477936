import React from "react";
import {
  AiFillTwitterCircle,
  AiFillYoutube,
  AiOutlineInstagram,
} from "react-icons/ai";
import { BsInstagram } from "react-icons/bs";
import { CiFacebook } from "react-icons/ci";
import { FaFacebookF } from "react-icons/fa6";
import { FiTwitter } from "react-icons/fi";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="footer-lk-o">
            <div className="">
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "800",
                  marginBottom: 10,
                }}
              >
                Copyright © Dhanya Tours & Travels, India 2024-25{" "}
              </p>
              {/* <p>
                Tourism House, #8, Papanna Lane St. Marks Road, Bangalore - 560
                001
              </p> */}
              <p style={{ marginTop: 20 }}>
                Designed & Developed By{" "}
                <span style={{ fontWeight: "600", fontSize: "20px" }}>
                  {" "}
                  <a href="https://parnetsgroup.com/">
                    <span style={{ color: "#fff" }}>Par</span>
                    <span style={{ color: "#ed3237" }}>Nets</span>
                  </a>
                </span>
              </p>
              <p style={{ marginTop: 20 }}>
                <span>
                  {" "}
                  <a href="/terms&conditions">
                    <span style={{ color: "#fff" }}>Terms & Condition</span>
                  </a>
                  <a href="/privacy&policy">
                    <span style={{ color: "#fff", paddingLeft: "20px" }}>
                      Privacy & Policy
                    </span>
                  </a>
                  <a href="/disclaimer">
                    <span style={{ color: "#fff", paddingLeft: "20px" }}>
                      Disclaimer
                    </span>
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="icons">
              <FaFacebookF /> &nbsp;
              <FiTwitter /> &nbsp;
              <AiOutlineInstagram /> &nbsp;
              <AiFillYoutube />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
