import React from "react";
import "./register.css";

function VenderRegister() {
  return (
    <>
      <section class=" bg-image">
        <div class="mask d-flex align-items-center  gradient-custom-3">
          <div class="container ">
            <div class="row d-flex justify-content-center align-items-center ">
              <div class="col-12 col-md-9 col-lg-7 col-xl-6">
                <div
                  class="card"
                  style={{
                    borderRadius: "15px",
                    boxShadow:
                      " rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                  }}
                >
                  <div class="card-body p-5">
                    <div
                      class="text-uppercase text-center mb-2"
                      style={{
                        fontWeight: "bold",
                        fontSize: "25px",
                      }}
                    >
                      Register
                    </div>
                    <form>
                      <div class="form-outline mb-2">
                        <label class="form-label" for="form3Example1cg">
                          Your Name
                        </label>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          class="form-control form-control-lg"
                        />
                      </div>

                      <div class="form-outline mb-2">
                        <label class="form-label" for="form3Example3cg">
                          Your Email
                        </label>
                        <input
                          type="email"
                          style={{ width: "100%" }}
                          class="form-control form-control-lg"
                        />
                      </div>

                      <div class="form-outline mb-2">
                        <label class="form-label" for="form3Example4cg">
                          Password
                        </label>
                        <input
                          type="password"
                          style={{ width: "100%" }}
                          class="form-control form-control-lg"
                        />
                      </div>

                      <div class="d-flex justify-content-center">
                        <button
                          type="button"
                          class="btn btn-success btn-block btn-lg gradient-custom-4 text-body"
                          style={{
                            background: "#367898",
                            color: "white",
                          }}
                        >
                          Register
                        </button>
                      </div>
                      <p class="text-center text-muted mt-2 mb-0">
                        Have already an account?{" "}
                        <a href="/vender-login" class="fw-bold text-body">
                          <u>Login</u>
                        </a>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default VenderRegister;
