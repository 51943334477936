import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

export const InvoiceJungle = () => {
  const { id } = useParams();
  const [AllotRoom,setAllotRoom]=useState([])
  const [bookData, setBookData] = useState({});
  const getInvoiceById = async () => {
    try {
      let res = await axios(
        "https://dhanyatours.me/api/admin/getInvoiceById/" + id
      );
      if (res.status == 200) {
        setBookData(res.data.success);
        setAllotRoom(res.data.success?.AllotRoom);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getInvoiceById();
    }
  }, []);
  const generatePDF = async () => {
    const input = document.getElementById("pdf-content");
    const options = { scrollY: -window.scrollY };
    const canvas = await html2canvas(input, options);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", [1000, canvas.height]);
    pdf.addImage(imgData, "PNG", 0, 0, 1000, canvas.height);
    pdf.save("Invoice.pdf");
  };
  const History=useNavigate()
  return (
    <div>
      <div
        className="container py-5"
        style={{ display: " flex", justifyContent: "center" }}
        id="pdf-content"
      >
        <div>
          <div>
            <div
              className="container border p-3 px-4 bg-light"
            
            >
              <div>
                <div className="d-flex justify-content-between ">
                  <h3>TAX INVOICE</h3>
                  <div>
                    <p>
                      <b>INVOICE NO : </b> {bookData?.InvoiceNo}
                    </p>
                    <p>
                      <b>INVOICE DATE : </b>  {moment(bookData?.date).format("ll")}
                    </p>
                  </div>
                </div>
                <div className="Invoice_0">
                  <img src="../logo_eye.jpeg" alt="logo" style={{}}/>
                  <div>
                    <h5> Dhanya Travels</h5>
                    <p>Dhanya Travel Co.Pvt.Ltd</p>
                    <p>
                      {" "}
                      II Floor, Papanna Lane, No-8, St.Mark's Road <br />{" "}
                      Bangalore-560001, India.
                    </p>
                    <p>
                      <span>Tel: </span> +91 9876543210 <span>Cell:</span> +91
                      9876543321
                    </p>
                    <p>
                      <span>Email :</span> Ganesh@gmail.com{" "}
                      <span> Website :</span> DhanyaTravel.com
                    </p>
                  </div>
                  <div className="text-end">
                    <h5>{bookData?.LodgeName}</h5>
                    <p style={
                      {width:"300px"}
                    }>
                    {bookData?.PickUpPoint}
                    </p>
                    <p>
                      <span> Cell:</span> +91
                      {bookData?.LodgeCantact}
                    </p>
                    <p>
                      <span>Email :</span> {bookData?.LodgeEmail}
                    </p>
                  </div>
                </div>

                <hr />
               
                <h6 className="fw-bold">Booking Details :</h6>
                <div className="mb-4">
                  <Table bordered striped responsive>
                    <tbody>
                      <tr>
                        <td>Booking ID : {bookData?.BookingId}</td>
                        <td>Booking Date :  {moment(bookData?.taxibookId?.createdAt).format(
                          "DD/MM/YYYY"
                        )}</td>
                        <td>Package Name : {AllotRoom[0]?.packageName}</td>
                        {/* <td>Number Of Guest : {bookData?.AllotRoom?.reduce((a,i)=>a+i?.person,0)}</td> */}
                      </tr>
                      <tr>
                        <td>Name :{bookData?.Name}</td>
                        <td>Email : {bookData?.email}</td>
                        <td>Mobile : +91 {bookData?.mobile}</td>
                       
                      </tr>
                      <tr>
                        <td>Check In date : {moment(bookData?.pickDate).format(
                            "DD/MM/YYYY"
                          )} </td>
                        <td>Check Out Date :  {moment(bookData?.dropDate).format(
                            "DD/MM/YYYY"
                          )}</td>
                          <td>Extra Matter :  {bookData?.ExtraMater}</td>
                      </tr>
                      <tr>
                      <td>Adult : {bookData?.AllotRoom?.reduce((a,i)=>a+i?.person,0)}</td>
                        <td>Children : {bookData?.AllotRoom?.reduce((a,i)=>a+Number(i?.child? i?.child:0),0)}</td>
                       
                        <td>Status :  {bookData?.status}</td>
                     
                      </tr>
                    </tbody>
                  </Table>
                </div>

                <h6 className="fw-bold">Fare Break Up :</h6>
                <div className="mb-3">
                  <Table
                    bordered
                    striped
                    responsive
                    className="invoice-table-head"
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Room</th>
                        <th>Package</th>

                        <th>Adult</th>
                        <th>Children</th>
                        <th>Days</th>
                        <th>Nights</th>
                     
                        <th>Discout%</th>
                        <th>Total Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookData?.AllotRoom?.map((item,i)=>{
                      return ( <tr>
                        <td>{i+1}.</td>
                        <td>{item?.roomNo}</td>
                        <td>{item?.packageName}</td>
                  
                        <td>{item?.person}</td>
                        <td>{item?.child}</td>
                        <td>{item?.days}</td>
                        <td>{item?.night}</td>
                        {/* <td>{bookData?.hotelbookId?.Occupancy}</td> */}
                        <td>{item?.discount}</td>
                        <td>{item?.totalP?.toFixed(2)}</td>
                      </tr>)
                      })}
                     
                  
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-end">
                  <Table style={{ width: "400px" }}>
                    <tbody>
                      <tr>
                        <td className="fw-bold">SUBTOTAL : </td>
                        <td>₹{(bookData?.AllotRoom?.reduce((a,i)=>a+i?.totalP,0))?.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">STATE TAX : </td>
                        <td>₹{bookData?.gst?.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">GST : </td>
                        <td>₹{(bookData?.stateTax)?.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">GRAND TOTAL : </td>
                        <td>₹{(parseInt(bookData?.TotalAmount))?.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
        <div>
          <button
            type="button"
            class="btn btn-warning"
            onClick={generatePDF}
            style={{ color: "white" }}
          >
            Download Pdf
          </button>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-danger"
            onClick={() => History(-1)}
          >
            Back
          </button>
        </div>
      </div>
      <br />
    </div>
  );
};
