import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'
import Table1 from "react-bootstrap/Table";
import { AiOutlineDelete } from 'react-icons/ai';
import { BiSolidEdit } from 'react-icons/bi';
const RoomType = () => {
    const [show1, setShow1] = useState(false);
    const handleShow1 = () => setShow1(true);
    const handleClose1 = () => setShow1(false)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const vendor = JSON.parse(sessionStorage.getItem("vendor"))
    const [category,setCategory]=useState("");
    const [AllCategory,setAllCategory]=useState([]);
    const getAllCategory=async()=>{
        try {
            let res=await axios.get("https://dhanyatours.me/api/user/getAllRoomcategoryByVendorId/"+vendor?._id);
            if(res.status==200){
                setAllCategory(res.data.success);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        if(vendor){
            getAllCategory();
        }
    },[])

    const addroomcategory=async()=>{
        try {
            if(!category) return alert("Please enter category");
            let am=AllCategory?.filter((ele=>ele?.category?.toLowerCase()==category?.toLowerCase()));
            if(am.length!==0)return alert(`${category} already exits`)
            const config = {
                url: "/addRoomcategory",
                method: "post",
                baseURL: "https://dhanyatours.me/api/user",
                headers: { "content-type": "application/json" },
                data:{
                    category:category,
                    vendorId:vendor?._id
                }
              };
            let res=await axios(config)
            if(res.status==200){
                alert("Successfully added");
                getAllCategory();
                setCategory("")
            }
        } catch (error) {
            console.log(error);
        }
    }
    const [cat,setcat]=useState("")
    const [edit,setEdit]=useState("");
    const handleShow = (data) => {
        setEdit(data)
        setShow(true)};

    const updateCategory=async()=>{
        try {
            if(cat){
                 let am=AllCategory?.filter((ele=>ele?.category?.toLowerCase()==cat?.toLowerCase()));
            if(am.length!==0)return alert(`${cat} already exits`)
            }
           
            const config = {
                url: "/UpdateRoomcategory",
                method: "put",
                baseURL: "https://dhanyatours.me/api/user",
                headers: { "content-type": "application/json" },
                data:{
                    category:cat,
                    id:edit?._id
                }
              };
            let res=await axios(config)
            if(res.status==200){
                alert("Successfully Updated");
                handleClose()
                getAllCategory();
                setcat("");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const [deleteId,setDeleteId]=useState("");
    const deleteCategory=async()=>{
        try {
            let res=await axios.delete("https://dhanyatours.me/api/user/deleteRoomcategory/"+deleteId);
            if(res.status==200){
                alert("Successfully deleted");
                handleClose1();
                getAllCategory()
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <div className="ms-5 mt-4">
                <div className="conatiner">
                    <div className="row">
                        <div className="col-md-6">
                            <form action="" className='title-90 pb-4'>
                                <h4 className='text-center' style={{ padding: "10px", borderBottom: "1px solid darkgray" }}>Room Category Form</h4>
                                <label htmlFor="" className="pb-2 nd-2">Category </label>
                             
                                <input type="text" className='title-90' placeholder='Please Enter Room Category' value={category} onChange={(e)=>setCategory(e.target.value)} />

                                <div className="mt-3 text-center pt-3" style={{ borderTop: "1px solid darkgrey" }}>
                                    <Button onClick={addroomcategory} >Save</Button> &nbsp;
                                    <Button variant='light' onClick={()=>setCategory("")}>Cancel</Button>
                                </div>

                            </form>
                        </div>
                        <div className="col-md-6">
                            <Table1 striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>S No.</th>
                                        <th>Category</th>
                                       
                                        <th>Action</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {AllCategory?.map((item,i)=>{
                                        return (
                                             <tr>
                                        <td>{i+1}</td>
                                         <td>{item?.category}</td>
    
                                        <td>
                                            <div style={{ display: "flex", gap: "10px" }}>
                                                <span>
                                                    <BiSolidEdit
                                                        style={{
                                                            color: "#5600ff",
                                                            cursor: "pointer",
                                                            fontSize: "26px",
                                                        }}
                                                        onClick={()=>handleShow(item)}
                                                    />
                                                </span>
                                                <span>
                                                    <AiOutlineDelete
                                                        style={{
                                                            color: "red",
                                                            cursor: "pointer",
                                                            fontSize: "26px",
                                                        }}
                                                        onClick={()=>{
                                                            setDeleteId(item?._id);
                                                            handleShow1()}}

                                                    />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                        )
                                    })}
                                   
                                </tbody>
                            </Table1>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure delete?</Modal.Title>
                </Modal.Header>
                {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={deleteCategory}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Rooms Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <label htmlFor="" className="pb-2 nd-2">Category </label>
                             
                             <input type="text" className='title-90' placeholder={edit?.category} value={cat} onChange={(e)=>setcat(e.target.value)} />




                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={updateCategory}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RoomType
