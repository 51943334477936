import axios from "axios";
import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import Enquiry from "./Enquiry";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom/dist";

function Jungal() {
  const [loade, setloade] = useState([]);
  // console.log(loade);

  // const lodges = async () => {
  //   const config = {
  //     url: "/getAllLodge",
  //     method: "get",
  //     baseURL: "https://dhanyatours.me/api/user",
  //     headers: { "content-type": "application/json" },
  //   };
  //   try {
  //     const result = await axios(config);
  //     if (result.status === 200) {
  //       setloade(result.data.success);

  //       // console.log("suceesss");
  //     } else {
  //       alert("Something went wrong");
  //     }
  //   } catch (error) {}
  // };
  const [search, setSearch] = useState("");
  const [category, setcategory] = useState([]);
  const getAllCategory = async () => {
    const config = {
      url: "/getAllCategory",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setcategory(result.data.success);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  useEffect(() => {
    lodImage();
    getAllCategory();
  }, []);

  useEffect(() => {
    lodImage();
    getAllCategory();
  }, []);

  // lodgeImage

  const [lodgeslider, setlodgeslider] = useState([]);

  const lodImage = async () => {
    const config = {
      url: "/getAllImage",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setlodgeslider(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  };
  const responsive1 = {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  };

  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [number, setnumber] = useState("");

  const [message, setmessage] = useState("");

  const onCreate = async () => {
    try {
      if (!user) {
        alert("Please enter full name!");
      } else if (!email) {
        alert("Please enter email!");
      } else if (!number) {
        alert("Please enter mobile number!");
      } else {
        const config = {
          url: "/createTrainBook",
          method: "post",
          baseURL: "https://dhanyatours.me/api/user",
          headers: { "conttent-type": "application/json" },
          data: {
            userName: user,
            email: email,
            number: number,
            message: message,
          },
        };
        const result = await axios(config);
        if (result.status === 200) {
          alert("Our team  will contact you soon");
          window.location.assign("/");
        } else {
          alert("Something went wrong");
        }
      }
    } catch (error) {
      console.log("erore", error);
      alert(error.response.data.error);
    }
  };
  const [cat, setcat] = useState("");

  const { state } = useLocation();
  useEffect(() => {
    if (state?.cat) {
      setcat(state?.cat?.toLowerCase());
    }
  }, []);

  const [distanation, setdistanation] = useState("");
  const [date, setdate] = useState("");

  useEffect(() => {
    if (state?.distanation) {
      setdistanation(state?.distanation);
      setdate(state?.date);
    }
  }, []);

  function areStringsEqual(str1, str2) {
    // Normalize and tokenize the strings
    const words1 = str1
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, " ")
      .trim()
      .split(" ");
    const words2 = str2
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, " ")
      .trim()
      .split(" ");

    // Check if any word from words1 exists in words2
    for (const word of words1) {
      if (words2.includes(word)) {
        return true;
      }
    }

    return false;
  }

  const history = useNavigate();
  return (
    <div>
      <OwlCarousel
        className="owl-theme"
        loop
        margin={0}
        nav={false}
        dots={false}
        autoplay={true}
        responsive={responsive}
      >
        {lodgeslider
          ?.filter((element) => element?.name === "Lodge")
          ?.map((slider) => {
            return (
              <div class="item">
                <div class="box">
                  <img
                    src={`https://dhanyatours.me/image/${slider?.image}`}
                    alt="Jungle Lodges and Resorts"
                    style={{ width: "100%", height: "300px" }}
                  />
                </div>
              </div>
            );
          })}
      </OwlCarousel>

      <div className="jungal">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="read-jungal mb-2">
                <h2 className="read-op">JUNGLE LODGES</h2>
                <p style={{ textAlign: "justify" }}>
                  Jungle lodges offer a unique and immersive experience for
                  nature enthusiasts and adventure seekers. The beauty of jungle
                  lodges lies in their ability to provide guests with an
                  opportunity to connect with the natural world in a way that is
                  both comfortable and sustainable.
                </p>
              </div>
              <div className="read-jungal">
                <h4>Natural Surroundings:</h4>
                <p style={{ textAlign: "justify" }}>
                  Jungle lodges are typically located in pristine natural
                  environments, such as rainforests, national parks, or wildlife
                  reserves. Guests have the chance to wake up to the sights and
                  sounds of the jungle right outside their window.
                </p>
                <h4>Wildlife Encounters:</h4>
                <p style={{ textAlign: "justify" }}>
                  Staying in a jungle lodge often means being in close proximity
                  to a variety of wildlife species. Depending on the location,
                  you might spot exotic birds, monkeys, big cats, or other
                  fascinating creatures.
                </p>
                <h4>Sustainable Accommodations: </h4>
                <p style={{ textAlign: "justify" }}>
                  Many jungle lodges are designed with eco-friendly principles
                  in mind. They use renewable energy sources, employ sustainable
                  building materials, and implement waste management systems
                  that minimize their environmental impact.
                </p>
                <h4>Guided Nature Tours:</h4>
                <p>
                  Jungle lodges usually offer guided nature walks, wildlife
                  safaris, and other activities led by knowledgeable
                  naturalists. These experts can provide valuable insights into
                  the local ecosystem and help guests observe wildlife safely.
                </p>
                <h4>Adventure and Serenity:</h4>
                <p>
                  Jungle lodges cater to both adventure seekers and those
                  looking for a tranquil retreat. You can choose to go on
                  thrilling hikes, canopy walks, or river cruises, or simply
                  relax in the midst of nature.
                </p>
              </div>
              <div class="">
                <div
                  className="carousel-r"
                  style={{
                    display: "flex",
                    overflow: category?.length > 4 ? "hidden" : "",
                    overflowX: category?.length > 4 ? "scroll" : "",
                    width: category?.length > 4 ? "957px" : "",
                  }}
                >
                  {/* <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={20}
                    nav={false}
                    dots={false}
                    autoplay={false}
                    responsive={responsive1}
                  > */}
                  {category?.map((slider) => {
                    return (
                      <div class="item">
                        <div
                          class="box"
                          style={{
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            padding: "5px",
                            margin: "6px",
                            width: "230px",
                          }}
                        >
                          <img
                            src={`https://dhanyatours.me/category/${slider?.image}`}
                            alt="Jungle Lodges and Resorts"
                            style={{ width: "100%", height: "150px" }}
                          />
                          <div
                            className="mt-3 mb-2"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              style={{
                                fontSize: "19px",
                                width: "100%",
                                backgroundColor:
                                  slider?.category?.toLowerCase() ==
                                  cat?.toLowerCase()
                                    ? "#0d6efd"
                                    : "",
                              }}
                              onClick={() =>
                                history("/our-best-packages", {
                                  state: { cat: slider?.category },
                                })
                              }
                            >
                              {slider?.category}
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* </OwlCarousel> */}
                </div>
                {/* <div className="search-drive">
                  <div style={{ display: "flex" }}>
                    <div class="form-outline">
                      <input
                        id="search-input"
                        type="search"
                        placeholder="Search by City , Package , Resorts..."
                        style={{ outlineWidth: "0px" }}
                        class="form-control"
                      />
                    </div>
                    <button
                      id="search-button"
                      type="button"
                      class="btn btn-primary"
                      style={{ height: "52px", borderRadius: "inherit" }}
                    >
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                  <div className="spa-item mt-2">
                    <Form.Select aria-label="Default select example">
                      <option>Popular</option>
                      <option value="1">High to Low</option>
                      <option value="2">Low to High</option>
                    </Form.Select>
                  </div>
                </div> */}
              </div>
              {/* {cat ? (
                <>
                  {loade
                    ?.filter((check) => {
                      if (distanation) {
                        if (areStringsEqual(check?.location, distanation)) {
                          return check;
                        }
                      } else {
                        return check;
                      }
                    })
                    ?.filter((data) => data?.isBloc === false)
                    ?.filter(
                      (ele) =>
                        ele?.category?.toLowerCase() === cat?.toLowerCase()
                    )
                    ?.map((item) => {
                      return (
                        <div className="listing">
                          <div className="row">
                            <div className="col-md-4 col-xs-4 text-left">
                              <a href={`/singal-lodge/${item._id}`}>
                                <img
                                  class="img-responsive"
                                  src={`https://dhanyatours.me/lodge/${item.lodgeProfile}`}
                                  alt="kabini jungle resort"
                                />
                              </a>
                            </div>
                            <div class="col-md-8 col-xs-8 text-left">
                              <div class="car-text">
                                <h3>
                                  <a href={`/singal-lodge/${item._id}`}>
                                    {item.lodgeName}
                                  </a>
                                </h3>
                                <p>
                                  <i class="icon-calendar"></i>{" "}
                                  {item.lodgeTiming}
                                </p>
                                <p>
                                  <span class="hidden-xs">
                                    <i class="icon-location-pin"></i>{" "}
                                    {item.location}
                                  </span>
                                </p>
                                <p>
                                  <span class="posted_by">
                                    <i class="icon-briefcase"></i> Starts at ₹{" "}
                                    {item.StartingPrice}/-
                                  </span>
                                </p>
                                <p>
                                  <a
                                    href={`/singal-lodge/${item._id}`}
                                    class="btn btn-primary btn-sm hidden-xs"
                                  >
                                    View Details
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  {" "}
                  {loade
                    ?.filter((check) => {
                      if (distanation) {
                        if (areStringsEqual(check?.location, distanation)) {
                          return check;
                        }
                      } else {
                        return check;
                      }
                    })
                    ?.map((item) => {
                      return (
                        <div className="listing">
                          <div className="row">
                            <div className="col-md-4 col-xs-4 text-left">
                              <a href={`/taxi-details/${item._id}`}>
                                <img
                                  class="img-responsive"
                                  src={`https://dhanyatours.me/lodge/${item.lodgeProfile}`}
                                  alt="kabini jungle resort"
                                />
                              </a>
                            </div>
                            <div class="col-md-8 col-xs-8 text-left">
                              <div class="car-text">
                                <h3>
                                  <a href={`/singal-lodge/${item._id}`}>
                                    {item.lodgeName}
                                  </a>
                                </h3>
                                <p>
                                  <i class="icon-calendar"></i>{" "}
                                  {item.lodgeTiming}
                                </p>
                                <p>
                                  <span class="hidden-xs">
                                    <i class="icon-location-pin"></i>{" "}
                                    {item.location}
                                  </span>
                                </p>
                                <p>
                                  <span class="posted_by">
                                    <i class="icon-briefcase"></i> Starts at ₹{" "}
                                    {item.StartingPrice}/-
                                  </span>
                                </p>
                                <p>
                                  <a
                                    href={`/singal-lodge/${item._id}`}
                                    class="btn btn-primary btn-sm hidden-xs"
                                  >
                                    View Details
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )} */}
            </div>

            <div className="col-md-3">
              <div
                className="r-lg-0"
                style={{ position: "relative", top: "unset" }}
              >
                <div>
                  <div
                    className="r-lg-0"
                    style={{ position: "relative", top: "unset" }}
                  >
                    <div
                      style={{
                        boxShadow: " 0px 14px 33px -9px rgba(0, 0, 0, 0.15)",
                        padding: "15px",
                        backgroundColor: "#004aad",
                        marginTop: "20px",
                        border: "1px solid #004aad",
                        borderRadius: "5px",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "600",
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        Want to book a trip or inquiry?
                      </span>
                      <form>
                        <div className="form-group pt-2">
                          <input
                            type="text"
                            class="form-control"
                            name="Last Name"
                            value={user}
                            onChange={(e) => setUser(e.target.value)}
                            placeholder="Full Name*"
                            required=""
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            class="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email Id*"
                            required=""
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            class="form-control"
                            name="mobileno"
                            value={number}
                            onChange={(e) => setnumber(e.target.value)}
                            placeholder="Mobile No*"
                          />
                        </div>
                        {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Select Package <span class="text-danger">*</span>
                          </label>
              <div className="col-md-12">
              <div class="form-group">
              <Form.Select onChange={(e)=>{
                           setpackageName(e.target.value)
                           setData(JSON.parse(e.target.value))
                          }}>
                            <option>Select package</option>
                            {priceing?.map((item) => {
                              return (
                                <option value={JSON.stringify(item)}>
                                  {item?.packageName}
                                </option>
                              );
                            })}
                          </Form.Select>
            </div>
              </div>
           
             
               </div> */}
                        {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Check In Date/Time <span class="text-danger">*</span>
                          </label>
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="date"
                class="form-control"
                name="Description"
                value={checkinDate}
                onChange={(e)=>setcheckinDate(e.target.value)}
             
              
              />
            </div>
              </div>
           
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="time"
                class="form-control"
               value={checkinTime}
               onChange={(e)=>setcheckinTime(e.target.value)}              
           
              />
            </div>
              </div>
               </div> */}
                        {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Check Out Date/Time <span class="text-danger">*</span>
                          </label>
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="date"
                class="form-control"
                          value={checkOutDate}
                          onChange={(e)=>setcheckOutDate(e.target.value)}
              />
            </div>
              </div>
           
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="time"
                class="form-control"
               value={checkOutTime}
               onChange={(e)=>setcheckOutTime(e.target.value)}
              />
            </div>
              </div>
               </div> */}
                        {/* <div className="row">
               <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Number Of Guest <span class="text-danger">*</span>
                          </label>
               <div className="col-md-6">
               <div class="form-group">
              <input
                type="number"
                class="form-control"
              value={numberOfguest}
              onChange={(e)=>setnumberOfguest(e.target.value)}
                placeholder="adults"
              />
            </div>
               </div>
               <div className="col-md-6">
               <div class="form-group">
              <input
                type="number"
                class="form-control"
              value={NumberOfChild}
              onChange={(e)=>setNumberOfchild(e.target.value)}
                placeholder="childs"
              />
            </div>
               </div>
               </div> */}

                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            value={message}
                            onChange={(e) => setmessage(e.target.value)}
                            placeholder="Message"
                          />
                        </div>

                        <div class="row" style={{ marginTop: "10px" }}>
                          <div class="col-sm-6">
                            <botton
                              class="btn btn-primary btn-sm hidden-xs"
                              onClick={onCreate}
                            >
                              Inquiry Now
                            </botton>
                            {/* <input
                  class="btn btn-primary btn-sm hidden-xs"
                  type="submit"
                  value="Book Now"
                /> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jungal;
