import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
function Information() {
  const [info, setinfo] = useState([]);
  const [tableFilter,settableFilter]=useState([])
  const allInformation = async () => {
    const config = {
      url: "/getallContact",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setinfo(result.data.success);
        settableFilter(result.data.success);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  useEffect(() => {
    allInformation();
  }, []);
  const [search, setSearch] = useState("");

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = tableFilter?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setinfo([...filterTable]);
    } else {
      setSearch(e.target.value);
      setinfo([...tableFilter]);
    }
  };
  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
const searchDate=()=>{
  if(!fromd) return alert("Please select from date");
  if(!tod) return alert("Please select to date");

 let std=fromd?.split("-");
      let entd=tod?.split("-")
      let startD=parseInt(std?.join(""));
      let endD=parseInt(entd?.join(""))
      let abc=tableFilter?.filter((ele)=>{
        // console.log("amit check it",moment(ele?.createdAt).format("YYYY-MM-DD").split("-"))
        let abd=moment(ele?.createdAt).format("YYYY-MM-DD").split("-")
        let amd=parseInt(abd?.join(""));
        return startD<=amd && endD>=amd
      })
      setinfo(abc);
      // setdata(abc)
      // setfileName(`AcountHistory_${moment(fromd).format("DD/MM/YYYY")} to ${moment(tod).format("DD/MM/YYYY")}`)
}
const [show1, setShow1] = useState(false);

const handleClose1 = () => setShow1(false);
const handleShow1 = () => setShow1(true);
const [deleteId,setDeleteId]=useState("")
  const onDelete = async () => {
    // e.preventDefault();
    try {
      const config = {
        url: "/deleteContact/" + deleteId,
        method: "delete",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "applicaton/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
       
        alert("Successfully deleted");
        handleClose1()
        allInformation()
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(info?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  
  return (
    <>
      <div className="ms-5">
        <div className="info">
          <div
            className="mt-2 mb-3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="logde-op">
              <h2 style={{ fontSize: "25px" }}>Contact Us</h2>
            </div>
          </div>
          <div className="row">
              <div className="col-md-4">
                <div className="searchHeadFlex">
              <div className="searchWrapper" style={{ display: "flex" }}>
                <input
                  type="text"
                  maxlength="230"
                  style={{ outlineWidth: "0px", padding: "4px" }}
                  className="textsearch"
                  placeholder="Search by ..."
                  onChange={handleFilter}
                />
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  id="BsSearch"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                    clip-rule="evenodd"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
           
              </div>
              <div className="col-md-2">
              <label htmlFor="">From</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => setfromd(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="">To</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => settod(e.target.value)}
              />
            </div>
            <div className="col-md-2">
         
              <button onClick={searchDate}
                style={{
                  borderRadius: "3px",
                  color: "white",
                  fontSize: "18px",
                  border: "1px solid",
                  backgroundColor: "#004aad",
                  padding: "5px",
                  marginTop: "23px",
                  borderRadius: "10px",
                  fontWeight: 500,
                }}
              >
                Search
              </button>
            </div>
            </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>No</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Mobile No</th>
                <th>Subject</th>
                <th>Comments</th>

                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {info   ?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((einfo, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{einfo.fullName}</td>
                    <td>{einfo.email}</td>
                    <td>{einfo.number}</td>
                    <td>{einfo.subject}</td>
                    <td>{einfo.comments}</td>
                    <td>
                      <div className="" onClick={() =>{ setDeleteId(einfo?._id)
                      handleShow1()}}>
                        <AiFillDelete style={{color:"red",fontSize:"25px",cursor:"pointer"}}/>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </div>
      <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete?</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onDelete}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
    </>
  );
}

export default Information;
