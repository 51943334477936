import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import moment from "moment";
function AdminLuxurycarenquiry() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
  const [show4, setShow4] = useState(false);
  const handleShow4 = () => setShow4(true);
  const handleClose4 = () => setShow4(false);
  const [ExpanseList, setExpansList] = useState(false);
  const [AllEnquiry, setAllEnquiry] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getAllLuxuryCarEnquiry = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllLuxuryCarEnquiryByAdmin"
      );
      if (res.status == 200) {
        setAllEnquiry(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllLuxuryCarEnquiry();
  }, []);

  const [editdata, seteditdata] = useState({});
  const [status, setstatus] = useState("");
  const [reason, setreason] = useState("");

  const makeCompleAndReject = async () => {
    try {
      const config = {
        url: "/makeCompleteOrRejectedLuxuryCars",
        method: "put",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          id: editdata?._id,
          status: status,
          reason: reason,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(`Succesfully ${res.data.success?.status}`);
        handleClose4();
        getAllLuxuryCarEnquiry();
        setreason("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllEnquiry?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleFilter = (e) => {
    if (e.target.value != "") {
   
      const filterTable = nochangedata?.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
          )
        );
        setAllEnquiry([...filterTable]);
    } else {
    
      setAllEnquiry([...nochangedata]);
    }
  };
  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
const searchDate=()=>{
  if(!fromd) return alert("Please select from date");
  if(!tod) return alert("Please select to date");

 let std=fromd?.split("-");
      let entd=tod?.split("-")
      let startD=parseInt(std?.join(""));
      let endD=parseInt(entd?.join(""))
      let abc=nochangedata?.filter((ele)=>{
        // console.log("amit check it",moment(ele?.createdAt).format("YYYY-MM-DD").split("-"))
        let abd=moment(ele?.createdAt).format("YYYY-MM-DD").split("-")
        let amd=parseInt(abd?.join(""));
        return startD<=amd && endD>=amd
      })
      setAllEnquiry(abc);
      // setdata(abc)
      // setfileName(`AcountHistory_${moment(fromd).format("DD/MM/YYYY")} to ${moment(tod).format("DD/MM/YYYY")}`)
}
  return (
    <>
      <div className="ms-5 mt-4">
        <div className="container mb-3">
          <div className="row">
            <div className="col-md-6 ">
              <div className="logde-op">
                <h2 style={{ fontSize: "25px" }}>Luxury Car Enquiry</h2>
              </div>
            </div>
            <div className="col-md-6 ">
              <div style={{ float: "right" }}>
                {/* <div className="logde-op-00" onClick={handleShow} style={{ cursor: "pointer" }}>
                                    +Luxury Car Enquiry
                                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
              <div className="col-md-4">
                <div className="searchHeadFlex">
              <div className="searchWrapper" style={{ display: "flex" }}>
                <input
                  type="text"
                  maxlength="230"
                  style={{ outlineWidth: "0px", padding: "4px" }}
                  className="textsearch"
                  placeholder="Search by ..."
                  onChange={handleFilter}
                />
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  id="BsSearch"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                    clip-rule="evenodd"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
           
              </div>
              <div className="col-md-2">
              <label htmlFor="">From</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => setfromd(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="">To</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => settod(e.target.value)}
              />
            </div>
            <div className="col-md-2">
         
              <button onClick={searchDate}
                style={{
                  borderRadius: "3px",
                  color: "white",
                  fontSize: "18px",
                  border: "1px solid",
                  backgroundColor: "#004aad",
                  padding: "5px",
                  marginTop: "26px",
                  borderRadius: "10px",
                  fontWeight: 500,
                }}
              >
                Search
              </button>
            </div>
            </div>
        <div className="container">
          <div className="scrolll_bar">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Date</th>
                  <th>Brand Name </th>
                  <th>Model Name</th>
                  <th>Pickup Place</th>
                  <th>Drop Place</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile Number</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Status</th>
                  <th>Action</th>

                  {/* <th>Delete</th> */}
                </tr>
              </thead>
              <tbody>
                {AllEnquiry?.slice(
                  pagesVisited,
                  pagesVisited + usersPerPage
                )?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}.</td>
                      <td>{item?.bookId}</td>
                      <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                      <td>{item?.CarBrand}</td>
                      <td>{item?.CarModel}</td>
                      <td>{item?.from}</td>
                      <td>{item?.to}</td>
                      <td>{item?.name}</td>
                      <td>{item?.email}</td>
                      <td>{item?.mobile}</td>
                      <td>{moment(item?.FromDate).format("DD/MM/YYYY")}</td>
                      <td>{moment(item?.ToDate).format("DD/MM/YYYY")}</td>
                      <td>
                        {item?.status} {item?.reason}
                      </td>
                      <td>
                        {" "}
                        {item?.status=='Confirm'? (<></>):( <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <button
                            className="btn btn-success"
                            onClick={() => {
                                seteditdata(item)
                              setstatus("Confirm");
                              handleShow4();
                            }}
                          >
                            Confirm
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                                seteditdata(item)
                              setstatus("Cancel");
                              handleShow4();
                            }}
                          >
                            Cancel
                          </button>
                        </div>)}
                       {" "}
                      </td>

                      {/* <td>
                                        <Button
                                            type="button"
                                            variant="danger"
                                            onClick={() => {
                                                handleShow4();
                                            }}
                                        >
                                            <span>
                                                <RiDeleteBin6Line style={{ fontSize: "25" }} />
                                            </span>
                                        </Button>
                                    </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Enquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label className="pb-2 nd-2">Brand Name</label>
            <select
              className="title-90"
              // onChange={(e) => setexpanse(e.target.value)}
            >
              <option value=""></option>
            </select>
          </div>
          <div className="do-sear mt-2">
            <label className="pb-2 nd-2">Model Name</label>
            <select
              className="title-90"
              // onChange={(e) => setexpanse(e.target.value)}
            >
              <option value=""></option>
            </select>
          </div>
          <div className="do-sear mt-2">
            <label className="pb-2 nd-2">Remarks</label>
            <input
              type="text"
              // onChange={(e) => setremark(e.target.value)}
              className="title-90"
              placeholder="Enter Your remarks"
            />
          </div>
          <div className="do-sear mt-2">
            <label className="pb-2 nd-2">Date</label>
            <input
              type="date"
              // onChange={(e) => setdate(e.target.value)}
              className="title-90"
            />
          </div>
          <div className="do-sear mt-2">
            <label className="pb-2 nd-2">Amount</label>
            <input
              type="number"
              min={1}
              // onChange={(e) => setamount(e.target.value)}
              className="title-90"
              placeholder="Enter amount"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Submit</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Expense Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="do-sear mt-2">
              <label className="pb-2 nd-2">Expense Type</label>
              <input
                type="text"
                // onChange={(e) => setexpanseT(e.target.value)}
                className="title-90"
                placeholder="Please enter expanse type"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Cancel
          </Button>
          <Button variant="primary">Submit</Button>
        </Modal.Footer>
      </Modal>
      {/* edit Model */}
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Updated Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label className="pb-2 nd-2">Expanse Type</label>
            <select
              className="title-90"
              // onChange={(e) => setexpanse(e.target.value)}
            >
              <option> </option>
            </select>
            <input
              type="text"
              className="title-90"
              placeholder="Please enter question"
            />
          </div>
          <div className="do-sear mt-2">
            <label className="pb-2 nd-2">Remarks</label>
            <input
              type="text"
              // onChange={(e) => setremark(e.target.value)}
              className="title-90"
              // placeholder={edit?.remarks}
            />
          </div>
          <div className="do-sear mt-2">
            <label className="pb-2 nd-2">Date</label>
            <input
              type="date"
              // onChange={(e) => setdate(e.target.value)}
              className="title-90"
            />
          </div>
          <div className="do-sear mt-2">
            <label className="pb-2 nd-2">Amount</label>
            <input
              type="number"
              min={1}
              // onChange={(e) => setamount(e.target.value)}
              className="title-90"
              // placeholder={edit?.amount}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary">Submit</Button>
        </Modal.Footer>
      </Modal>
      {/* deleteMode */}
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure??</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="do-sear mt-2">
              <label className="pb-2 nd-2">Comment</label>
              <input
                type="text"
                value={reason}
                onChange={(e) => setreason(e.target.value)}
                className="title-90"
                placeholder="Please enter same comment"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose4}>
            Cancel
          </Button>
          <Button variant="success" onClick={makeCompleAndReject}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default AdminLuxurycarenquiry;
