import axios from "axios";
import React, { useState } from "react";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";

function Payonline() {
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [number, setnumber] = useState("");

  const [message, setmessage] = useState("");

  const onCreate = async () => {
    try {
      if (!user) {
        alert("Please enter full name!");
      } else if (!email) {
        alert("Please enter email!");
      } else if (!number) {
        alert("Please enter mobile number!");
      } else {
        const config = {
          url: "/createTrainBook",
          method: "post",
          baseURL: "https://dhanyatours.me/api/user",
          headers: { "conttent-type": "application/json" },
          data: {
            userName: user,
            email: email,
            number: number,
            message: message,
          },
        };
        const result = await axios(config);
        if (result.status === 200) {
          alert("Our team  will contact you soon");
          window.location.assign("/");
        } else {
          alert("Something went wrong");
        }
      }
    } catch (error) {
      console.log("erore", error);
      alert(error.response.data.error);
    }
  };

  return (
    <>
      <div className="inner-content-listing-desc" style={{ padding: "30px" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <p className="listing-wro">
                Please select a button alongside the payment options below to
                proceed with the payment.
              </p>
              <div style={{ display: "flex", gap: "20px", cursor: "pointer" }}>
                <p
                  className="wr-00"
                  style={{
                    border: " 1px solid #004aad",
                    padding: " 5px 20px",
                    fontSize: " 16px",
                    fontWeight: "600",
                    color: " white",
                    background: " #004aad",
                  }}
                >
                  {" "}
                  NEFT / RTGS
                </p>
                <p
                  className="wr-00"
                  style={{
                    border: " 1px solid #004aad",
                    padding: " 5px 20px",
                    fontSize: " 16px",
                    fontWeight: "600",
                    color: " white",
                    background: " #004aad",
                  }}
                >
                  PAY ONLINE
                </p>
              </div>

              <div className="pay-f">
                <div style={{ fontSize: "23px", fontWeight: "800" }}>
                  Bank Transfer NEFT / RTGS
                </div>
                <div>
                  <b> BANK NAME</b> &nbsp;: &nbsp; ----------
                </div>
                <div>
                  <b> NAME</b> &nbsp;: &nbsp; Dhanya Tours & Travels
                </div>
                <div>
                  <b> Cash Credit A/c No</b> &nbsp;: &nbsp; 00000000000
                </div>
                <div>
                  <b> Branch</b> &nbsp;: &nbsp; -----------
                </div>
                <div>
                  <b> City</b> &nbsp;: &nbsp; -------
                </div>
                <div>
                  <b> IFSC Code</b> &nbsp;: &nbsp; ---------
                </div>
                <div>
                  <b> BIC / Swift Code</b> &nbsp;: &nbsp; ---------
                </div>
              </div>

              <p style={{ marginTop: "20px" }}>
                {" "}
                <strong style={{ color: "red", marginTop: "20px" }}>
                  NOTE &nbsp;:
                </strong>
              </p>
              <p style={{ marginTop: "20px" }}>
                The Bank Details & Postal Address can be used to transfer by
                NEFT/ RTGS. Deposit of cash or cheque / demand draft to our
                account to be intimated to executive handling. Service vouchers
                would be issued upon credit to our account only.
              </p>
            </div>
            <div className="col-md-3">
              <div className="r-lg-0">
                <div
                  style={{
                    boxShadow: " 0px 14px 33px -9px rgba(0, 0, 0, 0.15)",
                    padding: "15px",
                    backgroundColor: "#004aad",
                    marginTop: "20px",
                    border: "1px solid #004aad",
                    borderRadius: "5px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    Want to book a trip or inquiry?
                  </span>
                  <form>
                    <div className="form-group pt-2">
                      <input
                        type="text"
                        class="form-control"
                        name="Last Name"
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                        placeholder="Full Name*"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        class="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email Id*"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        class="form-control"
                        name="mobileno"
                        value={number}
                        onChange={(e) => setnumber(e.target.value)}
                        placeholder="Mobile No*"
                      />
                    </div>
                    {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Select Package <span class="text-danger">*</span>
                          </label>
              <div className="col-md-12">
              <div class="form-group">
              <Form.Select onChange={(e)=>{
                           setpackageName(e.target.value)
                           setData(JSON.parse(e.target.value))
                          }}>
                            <option>Select package</option>
                            {priceing?.map((item) => {
                              return (
                                <option value={JSON.stringify(item)}>
                                  {item?.packageName}
                                </option>
                              );
                            })}
                          </Form.Select>
            </div>
              </div>
           
             
               </div> */}
                    {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Check In Date/Time <span class="text-danger">*</span>
                          </label>
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="date"
                class="form-control"
                name="Description"
                value={checkinDate}
                onChange={(e)=>setcheckinDate(e.target.value)}
             
              
              />
            </div>
              </div>
           
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="time"
                class="form-control"
               value={checkinTime}
               onChange={(e)=>setcheckinTime(e.target.value)}              
           
              />
            </div>
              </div>
               </div> */}
                    {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Check Out Date/Time <span class="text-danger">*</span>
                          </label>
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="date"
                class="form-control"
                          value={checkOutDate}
                          onChange={(e)=>setcheckOutDate(e.target.value)}
              />
            </div>
              </div>
           
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="time"
                class="form-control"
               value={checkOutTime}
               onChange={(e)=>setcheckOutTime(e.target.value)}
              />
            </div>
              </div>
               </div> */}
                    {/* <div className="row">
               <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Number Of Guest <span class="text-danger">*</span>
                          </label>
               <div className="col-md-6">
               <div class="form-group">
              <input
                type="number"
                class="form-control"
              value={numberOfguest}
              onChange={(e)=>setnumberOfguest(e.target.value)}
                placeholder="adults"
              />
            </div>
               </div>
               <div className="col-md-6">
               <div class="form-group">
              <input
                type="number"
                class="form-control"
              value={NumberOfChild}
              onChange={(e)=>setNumberOfchild(e.target.value)}
                placeholder="childs"
              />
            </div>
               </div>
               </div> */}

                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        value={message}
                        onChange={(e) => setmessage(e.target.value)}
                        placeholder="Message"
                      />
                    </div>

                    <div class="row" style={{ marginTop: "10px" }}>
                      <div class="col-sm-6">
                        <botton
                          class="btn btn-primary btn-sm hidden-xs"
                          onClick={onCreate}
                        >
                          Inquiry Now
                        </botton>
                        {/* <input
                  class="btn btn-primary btn-sm hidden-xs"
                  type="submit"
                  value="Book Now"
                /> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* QR-Pay */}
      {/* <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "30px", fontWeight: "800" }}>
            Dhanya Tours & Travels
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pay-f text-center">
            <img
              src="../qr-code.png"
              alt="qr-scaner"
              style={{ width: "350px", height: "350px" }}
            />
          </div>
        </Modal.Body>
      </Modal> */}
      {/* Bank Transfer NEFT / RTGS */}
      {/* <Modal show={show1} onHide={handleClose1} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "30px", fontWeight: "800" }}>
            Bank Transfer NEFT / RTGS
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pay-f">
            <div>
              <b> BANK NAME</b> &nbsp;: &nbsp; ----------
            </div>
            <div>
              <b> NAME</b> &nbsp;: &nbsp; Dhanya Tours & Travels
            </div>
            <div>
              <b> Cash Credit A/c No</b> &nbsp;: &nbsp; 00000000000
            </div>
            <div>
              <b> Branch</b> &nbsp;: &nbsp; -----------
            </div>
            <div>
              <b> City</b> &nbsp;: &nbsp; -------
            </div>
            <div>
              <b> IFSC Code</b> &nbsp;: &nbsp; ---------
            </div>
            <div>
              <b> BIC / Swift Code</b> &nbsp;: &nbsp; ---------
            </div>
          </div>
          <p style={{ marginTop: "20px" }}>
            The Bank Details & Postal Address can be used to transfer by NEFT/
            RTGS. Deposit of cash or cheque / demand draft to our account to be
            intimated to executive handling. Service vouchers would be issued
            upon credit to our account only.
          </p>
        </Modal.Body>
      </Modal> */}
    </>
  );
}

export default Payonline;
