import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import ReactPaginate from "react-paginate";

function Vendor() {
  // add model ---
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
//delete model----
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
// Edit model---
const [ab, setAb] = useState(false);
  const handleClose2 = () => setAb(false);
  const HandleAbShow = () => setAb(true);
const [vendorType,setvendorType]=useState("");
  const [hotelName, sethotelname] = useState("");
  const [hotelType, sethotelType] = useState("");
  const [location, setlocation] = useState("");
  const [contactperson, setcontactperson] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [hotelLine, sethotelLine] = useState("");
  const [hotelImage, sethotelImage] = useState("");
  const [roomImage, setroomImage] = useState("");
  const [Adhar, setAdhar] = useState("");
  const [pancard, setpancard] = useState("");
  const [hotelDoc, sethotelDoc] = useState("");
  const [name, setname] = useState("");
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const onSubmit = async () => {
    if(!vendorType) return alert("Please select vendor type")
    if(!name){
      alert("Vendor name is required!")
    }else if (!hotelName){
      alert("Business name is required!")
    }else if (!hotelType){
      alert("Business type is required!")
    }else if (!location){
      alert("Business location is required!")
    }else if (!contactperson){
      alert("Contact Person Name is required!")
    }else if (!email){
      alert("Email is required!")
    }else if (!mobile){
      alert("Contact Number is required!")
    }else if (!Adhar){
      alert("Adhar card is required!")
    }else if (!pancard){
      alert("Pan card is required!")
    }else if (!hotelDoc){
      alert("Hotel document is required!")
    }else if (!password){
      alert("Password is required!")
    }  else if(password !== cpassword) {
      alert("Confirm password is not match!");
    } else
      try {
        const config = {
          url: "/AddVendor",
          method: "post",
          baseURL: "https://dhanyatours.me/api/vendor",
          headers: { "content-type": "multipart/form-data" },
          data: {
            name: name,
            mobile: mobile,
            email: email,
            HotelName: hotelName,
            HotelType: hotelType,
            ContactPersonName: contactperson,
            HotelLandLineNo: hotelLine,
            RoomImage: roomImage,
            HotelImage: hotelImage,
            AdharCard: Adhar,
            PanCard: pancard,
            HotelRelatedDocument: hotelDoc,
            password: password,
            location: location,
            vendorType:vendorType,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("successfully Added");
          getAllVendor();
          handleClose();
          sethotelname("");
          sethotelType("");
          setlocation("");
          setcontactperson("");
          setemail("");
          setmobile("");
          sethotelLine("");
          sethotelImage("");
          setroomImage("");
          setAdhar("");
          setpancard("");
          sethotelDoc("");
          setname("");
          setpassword("");
          setcpassword("");
          setvendorType("");
        } else {
          alert("Something Wrong");
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data.error);
        }
      }
  };

  const [nochangedata,setnochangedata]=useState([])
  const [AllVendor, setAllVendor] = useState([]);
  const getAllVendor = async () => {
    try {
      const config = {
        url: "/getAllVendor",
        method: "get",
        baseURL: "https://dhanyatours.me/api/vendor",
        headers: { "content-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setAllVendor(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllVendor();
  }, []);
  const handleFilter = (e) => {
    if (e.target.value != "") {
   
      const filterTable = nochangedata?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllVendor([...filterTable]);
    } else {
   
      setAllVendor([...nochangedata]);
    }
  };
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    AllVendor.length / usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  
  const makeBlock = async (id) => {
    try {
      const config = {
        url: "/makeBlcokVendor/"+id,
        method: "put",
        baseURL: "https://dhanyatours.me/api/vendor",
        headers: { "content-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
       alert("Successfully block")
       getAllVendor();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const makeUnBlock = async (id) => {
    try {
      const config = {
        url: "/makeUnBlcokVendor/"+id,
        method: "put",
        baseURL: "https://dhanyatours.me/api/vendor",
        headers: { "content-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
       alert("Successfully unblock")
       getAllVendor();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [deleteId,setdeleteId]=useState("")
  const DeleteVendor = async () => {
    try {
      const config = {
        url: "/deleteVendor/"+deleteId,
        method: "delete",
        baseURL: "https://dhanyatours.me/api/vendor",
        headers: { "content-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
       alert("Successfully deleted")
       getAllVendor();
       handleClose1();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [editData,setEditData]=useState({})
  const EditVendor = async () => {
    
    if (password && password !== cpassword ) {
      alert("Confirm password is not match!");
    } else
      try {
        const config = {
          url: "/editVendor",
          method: "put",
          baseURL: "https://dhanyatours.me/api/vendor",
          headers: { "content-type": "multipart/form-data" },
          data: {
            adminId:editData?._id,
            name: name,
            mobile: mobile,
            email: email,
            HotelName: hotelName,
            HotelType: hotelType,
            ContactPersonName: contactperson,
            HotelLandLineNo: hotelLine,
            RoomImage: roomImage,
            HotelImage: hotelImage,
            AdharCard: Adhar,
            PanCard: pancard,
            HotelRelatedDocument: hotelDoc,
            password: password,
            location: location,
            vendorType:vendorType,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("successfully updated");
          getAllVendor();
          handleClose2();
          sethotelname("");
          sethotelType("");
          setlocation("");
          setcontactperson("");
          setemail("");
          setmobile("");
          sethotelLine("");
          sethotelImage("");
          setroomImage("");
          setAdhar("");
          setpancard("");
          sethotelDoc("");
          setname("");
          setpassword("");
          setcpassword("")
          setvendorType("");
        } else {
          alert("Something Wrong");
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data.error);
        }
      }
  };

  return (
    <div className="de-lod">
      <div
        className="mt-2 mb-3"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="logde-op">
          <h3>Vendor Details</h3>
        </div>

        <div
          className="logde-op-00"
          style={{ cursor: "pointer" }}
          onClick={handleShow}
        >
          + Vendor
        </div>
    {/* --- edit Vendor Model --- */}
        <Modal show={ab} onHide={handleClose2}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="">
            <label htmlFor="" className="pb-2 nd-2">
                  Vendor Type
                </label>
                <select
                  name=""
                  id=""
                  className="title-90"
                
                  onChange={(e)=>setvendorType(e.target.value)}
                >
                   <option value="">{editData?.vendorType? (editData?.vendorType):("---Select---")}</option>
                  <option value="Hotel">Hotel</option>
                  <option value="Resort">Resort</option>
                  <option value="HomeStay">Home Stay</option>
                </select>
              <form>
                <label className="pb-2 nd-2">Vendor Name</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  required
                  placeholder={editData?.name}
                />
                <label className="pb-2 nd-2">Business Name</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  value={hotelName}
                  onChange={(e) => sethotelname(e.target.value)}
                  required
                  placeholder={editData?.HotelName}
                />
                <label className="pb-2 nd-2">Business Type</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  value={hotelType}
                  onChange={(e) => sethotelType(e.target.value)}
                  placeholder={editData?.HotelType}
                  required
                />

                <label className="pb-2 nd-2">Business Location</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  value={location}
                  onChange={(e) => setlocation(e.target.value)}
                  placeholder={editData?.location}
                  required
                />
                <label className="pb-2 nd-2">Contact Person Name</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  value={contactperson}
                  onChange={(e) => setcontactperson(e.target.value)}
                  placeholder={editData?.ContactPersonName}
                  required
                />
                <label className="pb-2 nd-2">Email Id</label>
                <br />
                <input
                  type="email"
                  className="title-90"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  placeholder={editData?.email}
                  required
                />
                <label className="pb-2 nd-2">Contact No</label>
                <br />
                <input
                  type="number"
                  className="title-90"
                  value={mobile}
                  onChange={(e) => setmobile(e.target.value)}
                  placeholder={editData?.mobile}
                  required
                />
                <label className="pb-2 nd-2">Business Land Line No</label>
                <br />
                <input
                  type="number"
                  className="title-90"
                  value={hotelLine}
                  onChange={(e) => sethotelLine(e.target.value)}
                  placeholder={editData?.HotelLandLineNo}
                  required
                />
                {/* <label className="pb-2 nd-2" htmlFor="uploade1">
                Business Image
                </label>
                <br />
                <input
                  type="file"
                  className="title-90"
                  accept="/image*"
                  id="uploade1"
                  onChange={(e) => sethotelImage(e.target.files[0])}
                  required
                /> */}
                {/* <label className="pb-2 nd-2" htmlFor="upload2">
                  Room Image
                </label>
                <br />
                <input
                  type="file"
                  className="title-90"
                  accept="/image*"
                  onChange={(e) => setroomImage(e.target.files[0])}
                  id="upload2"
                  required
                /> */}
                <label className="pb-2 nd-2">Document</label>
                <br />
                <label className="pb-2 nd-2" htmlFor="upload3">
                  Adhar card
                </label>
                <br />
                <input
                  type="file"
                  className="title-90"
                  accept="/image*"
                  onChange={(e) => setAdhar(e.target.files[0])}
                  id="upload3"
                  required
                />
                <label className="pb-2 nd-2" htmlFor="upload4">
                  Pan card
                </label>
                <br />
                <input
                  type="file"
                  className="title-90"
                  accept="/image*"
                  onChange={(e) => setpancard(e.target.files[0])}
                  id="upload4"
                  required
                />
                <label className="pb-2 nd-2" htmlFor="upload5">
                Business Related Document
                </label>
                <br />
                <input
                  type="file"
                  className="title-90"
                  accept="/image*"
                  onChange={(e) => sethotelDoc(e.target.files[0])}
                  id="upload5"
                  required
                />
                <label className="pb-2 nd-2">Password</label>
                <br />
                <input
                  type="password"
                  className="title-90"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                  placeholder="Eg:- Abcd@1234"
                  required
                />
                <label className="pb-2 nd-2">Confirm Password</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  value={cpassword}
                  onChange={(e) => setcpassword(e.target.value)}
                  placeholder="Eg:- Abcd@1234"
                  required
                />
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Back
            </Button>
            <Button variant="primary" onClick={EditVendor}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* ---Edit vendor model --- */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
           
          </Modal.Header>
          <Modal.Body>
            <div className="">
            <label htmlFor="" className="pb-2 nd-2">
                  Vendor Type
                </label>
                <select
                  name=""
                  id=""
                  className="title-90"
                
                  onChange={(e)=>setvendorType(e.target.value)}
                >
                   <option value="">---Select---</option>
                  <option value="Hotel">Hotel</option>
                  <option value="Resort">Resort</option>
                  <option value="HomeStay">Home Stay</option>
                </select>
              <form>
                <label className="pb-2 nd-2">Vendor Name</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  required
                  placeholder="Eg:- Mr.Ramesh Kumar"
                />
                <label className="pb-2 nd-2">Business Name</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  value={hotelName}
                  onChange={(e) => sethotelname(e.target.value)}
                  required
                  placeholder="Eg:- Business name"
                />
                <label className="pb-2 nd-2">Business Type</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  value={hotelType}
                  onChange={(e) => sethotelType(e.target.value)}
                  placeholder="Eg:- Boutique hotels"
                  required
                />

                <label className="pb-2 nd-2">Business Location</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  value={location}
                  onChange={(e) => setlocation(e.target.value)}
                  placeholder="Eg:- Mumbai,India"
                  required
                />
                <label className="pb-2 nd-2">Contact Person Name</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  value={contactperson}
                  onChange={(e) => setcontactperson(e.target.value)}
                  placeholder="Eg:- 9984529630"
                  required
                />
                <label className="pb-2 nd-2">Email Id</label>
                <br />
                <input
                  type="email"
                  className="title-90"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  placeholder="Eg:- hotel@gmail.com"
                  required
                />
                <label className="pb-2 nd-2">Contact No</label>
                <br />
                <input
                  type="number"
                  className="title-90"
                  value={mobile}
                  onChange={(e) => setmobile(e.target.value)}
                  placeholder="Eg:- 7376889041"
                  required
                />
                <label className="pb-2 nd-2">Business Land Line No</label>
                <br />
                <input
                  type="number"
                  className="title-90"
                  value={hotelLine}
                  onChange={(e) => sethotelLine(e.target.value)}
                  placeholder="Eg:- 1800-2022-4420"
                  required
                />
                {/* <label className="pb-2 nd-2" htmlFor="uploade1">
                  Hotel Image
                </label>
                <br />
                <input
                  type="file"
                  className="title-90"
                  accept="/image*"
                  id="uploade1"
                  onChange={(e) => sethotelImage(e.target.files[0])}
                  required
                /> */}
                {/* <label className="pb-2 nd-2" htmlFor="upload2">
                  Room Image
                </label>
                <br />
                <input
                  type="file"
                  className="title-90"
                  accept="/image*"
                  onChange={(e) => setroomImage(e.target.files[0])}
                  id="upload2"
                  required
                /> */}
                <label className="pb-2 nd-2">Document</label>
                <br />
                <label className="pb-2 nd-2" htmlFor="upload3">
                  Adhar card
                </label>
                <br />
                <input
                  type="file"
                  className="title-90"
                  accept="/image*"
                  onChange={(e) => setAdhar(e.target.files[0])}
                  id="upload3"
                  required
                />
                <label className="pb-2 nd-2" htmlFor="upload4">
                  Pan card
                </label>
                <br />
                <input
                  type="file"
                  className="title-90"
                  accept="/image*"
                  onChange={(e) => setpancard(e.target.files[0])}
                  id="upload4"
                  required
                />
                <label className="pb-2 nd-2" htmlFor="upload5">
                Business Related Document
                </label>
                <br />
                <input
                  type="file"
                  className="title-90"
                  accept="/image*"
                  onChange={(e) => sethotelDoc(e.target.files[0])}
                  id="upload5"
                  required
                />
                <label className="pb-2 nd-2">Password</label>
                <br />
                <input
                  type="password"
                  className="title-90"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                  placeholder="Eg:- Abcd@1234"
                  required
                />
                <label className="pb-2 nd-2">Confirm Password</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  value={cpassword}
                  onChange={(e) => setcpassword(e.target.value)}
                  placeholder="Eg:- Abcd@1234"
                  required
                />
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Back
            </Button>
            <Button variant="primary" onClick={onSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
          <Button variant="primary" onClick={DeleteVendor}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
      <div className="searchHeadFlex">
            <div className="searchWrapper" style={{ display: "flex" }}>
              <input
                type="text"
                maxlength="230"
                style={{ outlineWidth: "0px", padding: "4px" }}
                className="textsearch"
                placeholder="Search by ...."
              onChange={handleFilter}
              />
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 16 16"
                id="BsSearch"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                  clip-rule="evenodd"
                ></path>
                <path
                  fill-rule="evenodd"
                  d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
      <Table striped bordered hover>
        <thead>
          <tr>
          <th>S.No.</th>
            <th>Vendor Name</th>
            <th>Vendor Type</th>
            <th>Business Name</th>
            <th>Business Type</th>
            <th>Business Location</th>
            <th>Contact Person Name</th>
            <th>Email Id</th>
            <th>Contact No</th>
            <th>Business Land Line Number</th>
            {/* <th>Hotel Image</th> */}
            {/* <th>Room Image</th> */}
            <th>Adhar Card</th>
            <th>Pan Card</th>
            <th>Business Related Document</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {AllVendor?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,index) => {
            return (
              <tr>
                    <td>{index+1}</td>
                <td>{item?.name}</td>
                <td>{item?.vendorType}</td>
                <td>{item?.HotelName}</td>
                <td>{item?.HotelType}</td>
                <td>{item?.location}</td>
                <td>{item?.ContactPersonName}</td>
                <td>{item?.email}</td>
                <td>{item?.mobile}</td>
                <td>{item?.HotelLandLineNo}</td>
                {/* <td>
                  <a
                    href={`https://dhanyatours.me/Vendor/${item?.HotelImage}`}
                    target="_blank"
                  >
                    <img
                      src={`https://dhanyatours.me/Vendor/${item?.HotelImage}`}
                      alt="HotelImage"
                      style={{ width: "100%" }}
                    />
                  </a>
                </td> */}
                {/* <td>
                  <a
                    href={`https://dhanyatours.me/Vendor/${item?.RoomImage}`}
                    target="_blank"
                  >
                    <img
                      src={`https://dhanyatours.me/Vendor/${item?.RoomImage}`}
                      alt="room Image"
                      style={{ width: "100%" }}
                    />
                  </a>
                </td> */}

                <td>
                  <a
                    href={`https://dhanyatours.me/Vendor/${item?.AdharCard}`}
                    target="_blank"
                  >
                    <img
                      src={`https://dhanyatours.me/Vendor/${item?.AdharCard}`}
                      alt="images"
                      style={{ width: "100%" }}
                    />
                  </a>
                </td>
                <td>
                  <a
                    href={`https://dhanyatours.me/Vendor/${item?.PanCard}`}
                    target="_blank"
                  >
                    <img
                      src={`https://dhanyatours.me/Vendor/${item?.PanCard}`}
                      alt="images"
                      style={{ width: "100%" }}
                    />
                  </a>
                </td>
                <td>
                  <a
                    href={`https://dhanyatours.me/Vendor/${item?.HotelRelatedDocument}`}
                    target="_blank"
                  >
                    <img
                      src={`https://dhanyatours.me/Vendor/${item?.HotelRelatedDocument}`}
                      alt="images"
                      style={{ width: "100%" }}
                    />
                  </a>
                </td>
                <td>
                  <div style={{ display: "flex", gap: "2px" }}>
                    <FiEdit
                      style={{
                        color: "#0014ff",
                        cursor: "pointer",
                        fontSize: "26px",
                      }}
                      onClick={()=>{
                        HandleAbShow()
                        setEditData(item)
                      }}
                    />
                    <AiFillDelete
                      style={{
                        color: "red",
                        cursor: "pointer",
                        fontSize: "26px",
                      }}
                      onClick={()=>{
                        setdeleteId(item?._id);
                        handleShow1();
                      }}
                    />
                    {item?.isBlock === false ? (
                      <button type="button" class="btn btn-danger" onClick={()=>makeBlock(item?._id)}>
                        Block
                      </button>
                    ) : (
                      <button type="button" class="btn btn-warning"  onClick={()=>makeUnBlock(item?._id)}>
                        Unblock
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
    </div>
  );
}

export default Vendor;
