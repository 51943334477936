import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import moment from "moment/moment";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { logDOM } from "@testing-library/react";

const AdminGenerateinvoice = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [showDelete, setshowDelete] = useState(false);
  const handleDelete = () => setshowDelete(true);
  const closeDelete = () => setshowDelete(false);

  //date,Name ,BookingId,InvoiceNo,LodgeName,LodgeEmail,LodgeCantact,ExtraMater,gst,stateTax,taxiPerson,taxiAmount,TrainPerson,TrainAmount,AllotRoom,roomNo,packageName,person,child,days,night,LuxuryCarAmount,discount,PickUpPoint,DropingPoint,totalP,TotalAmount,PaymentStatus
  const [date, setdate] = useState("");
  const [Name, setName] = useState("");
  const [email, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [BookingId, setBookingId] = useState("");
  const [InvoiceNo, setInvoiceNo] = useState("");
  const [LodgeName, setLodgeName] = useState("");
  const [LodgeEmail, setLodgeEmail] = useState("");
  const [LodgeCantact, setLodgeCantact] = useState("");
  const [ExtraMater, setExtraMater] = useState("");
  const [gst, setgst] = useState("");
  const [stateTax, setstateTax] = useState("");
  const [taxiPerson, settaxiPerson] = useState("");
  const [taxiAmount, settaxiAmount] = useState("");
  const [TrainPerson, setTrainPerson] = useState("");
  const [TrainAmount, setTrainAmount] = useState("");

  const [AllotRoom, setAllotRoom] = useState([]);

  const [roomNo, setroomNo] = useState("");
  const [packageName, setpackageName] = useState("");
  const [person, setperson] = useState("");
  const [child, setchild] = useState("");
  const [days, setdays] = useState("");
  const [night, setnight] = useState("");
  const [LuxuryCarAmount, setLuxuryCarAmount] = useState("");
  const [discount, setdiscount] = useState("");
  const [PickUpPoint, setPickUpPoint] = useState("");
  const [DropingPoint, setDropingPoint] = useState("");
  const [totalP, settotalP] = useState("");
  const [TotalAmount, setTotalAmount] = useState("");
  const [PaymentStatus, setPaymentStatus] = useState("");
  const [status, setstatus] = useState("");
  const [pickDate, setpickDate] = useState("");
  const [dropDate, setdropDate] = useState("");
  const [pickUpTime, setpickUpTime] = useState("");
  const [dropTime, setdropTime] = useState("");
  const [tripType, settripType] = useState("");
  const [carHireCharge, setcarHireCharge] = useState("");
  const [driverAllowances, setdriverAllowances] = useState("");
  const [PermitCharge, setPermitCharge] = useState("");
  const [TollCharge, setTollCharge] = useState("");
  const [ParkingCharge, setParkingCharge] = useState("");
  const [serviceCharge, setserviceCharge] = useState("");

  const AddRoomAlot = () => {
    if (!roomNo) return alert("Please enter room number");
    if (!packageName) return alert("Please enter package name");
    if (!person) return alert("Please enter number of adult");
    if (!days && !night) return alert("Please enter days or night");
    if (!totalP) return alert("Please enter total price");
    AllotRoom.push({
      roomNo,
      packageName,
      person,
      days,
      night,
      totalP,
      discount,
      child,
    });
    alert("Success");
    setroomNo("");
    setperson("");
    setdays("");
    setnight("");
    setdiscount("");
    setchild("");
    settotalP("");
    return;
  };
  const [AllInvoice, setAllInvoice] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);

  const getAllInvoice = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllINVOICEByAdmin"
      );
      if (res.status == 200) {
        setAllInvoice(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllBookLoge, setAllBookLoge] = useState([]);

  const getLodgeBooking = async () => {
    const config = {
      url: "/getallLodageBookOrder",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setAllBookLoge(result.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllCarEnquiry, setAllCarEnquiry] = useState([]);
  // const [nochangedata, setnochangedata] = useState([]);
  const getAllLuxuryCarEnquiry = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllLuxuryCarEnquiryByAdmin"
      );
      if (res.status == 200) {
        setAllCarEnquiry(res.data.success);
        // setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllInvoice();
  }, []);

  const [InvoiceType, setInvoiceType] = useState("");
  const AddInvoiceGenration = async () => {
    try {
      if (!InvoiceType) return alert("Please select invoice type");
      const config = {
        url: "/addINVOICE",
        method: "post",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          date,
          Name,
          email: email,
          mobile: number,
          LodgeName,
          LodgeEmail,
          LodgeCantact,
          InvoiceType,
          gst,
          stateTax,
          taxiPerson,
          taxiAmount,
          TrainPerson,
          TrainAmount,
          AllotRoom,
          status,
          LuxuryCarAmount,
          discount,
          PickUpPoint,
          DropingPoint,
          ExtraMater,
          TotalAmount,
          PaymentStatus,
          pickDate,
          dropDate,
          pickUpTime,
          dropTime,
          tripType,
          carHireCharge,
          driverAllowances,
          PermitCharge,
          TollCharge,
          ParkingCharge,
          serviceCharge,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        handleClose();
        getAllInvoice();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [edit, setEdit] = useState({});
  const updateInvoice = async () => {
    try {
      const config = {
        url: "/updateINVOICE",
        method: "put",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          id: edit?._id,
          date,
          Name,
          InvoiceType: InvoiceType,
          LodgeName,
          LodgeEmail,
          LodgeCantact,
          email: email,
          mobile: number,
          gst,
          stateTax,
          taxiPerson,
          taxiAmount,
          TrainPerson,
          TrainAmount,
          AllotRoom,
          status,
          LuxuryCarAmount,
          ExtraMater,
          PickUpPoint,
          DropingPoint,

          TotalAmount,
          PaymentStatus,
          pickDate,
          dropDate,

          pickUpTime,
          dropTime,
          tripType,
          carHireCharge,
          driverAllowances,
          PermitCharge,
          TollCharge,
          ParkingCharge,
          serviceCharge,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully updated");
        handleClose1();
        getAllInvoice();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [deleteId, setDeleteId] = useState("");

  const deleteInvoice = async () => {
    try {
      let res = await axios.delete(
        "https://dhanyatours.me/api/admin/deleteINVOICE/" + deleteId
      );
      if (res.status == 200) {
        alert("Successfully deleted");
        closeDelete();
        getAllInvoice();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllInvoice?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const handleFilter = (e) => {
    if (e.target.value != "") {
      const filterTable = nochangedata?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllInvoice([...filterTable]);
    } else {
      setAllInvoice([...nochangedata]);
    }
  };

  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
const searchDate=()=>{
  if(!fromd) return alert("Please select from date");
  if(!tod) return alert("Please select to date");

 let std=fromd?.split("-");
      let entd=tod?.split("-")
      let startD=parseInt(std?.join(""));
      let endD=parseInt(entd?.join(""))
      let abc=nochangedata?.filter((ele)=>{
        // console.log("amit check it",moment(ele?.createdAt).format("YYYY-MM-DD").split("-"))
        let abd=moment(ele?.createdAt).format("YYYY-MM-DD").split("-")
        let amd=parseInt(abd?.join(""));
        return startD<=amd && endD>=amd
      })
      setAllInvoice(abc);
      // setdata(abc)
      // setfileName(`AcountHistory_${moment(fromd).format("DD/MM/YYYY")} to ${moment(tod).format("DD/MM/YYYY")}`)
}
  return (
    <>
      <div className="ms-5">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h3> Generate Invoice</h3>
          </div>

          <div
            className="logde-op-00"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setdate(moment().format("YYYY-MM-DD"));
              setName("");
              setLodgeName("");
              setLodgeCantact("");
              setExtraMater("");
              setgst("");
              setstateTax("");
              settaxiPerson("");
              settaxiAmount("");
              setTrainPerson("");
              setTrainAmount("");
              setAllotRoom([]);

              setpackageName("");

              setstatus("");

              setLuxuryCarAmount("");

              setPickUpPoint("");
              setDropingPoint("");

              setTotalAmount("");
              setPaymentStatus("");

              setpickUpTime("");
              setdropTime("");
              settripType("");
              setcarHireCharge("");
              setdriverAllowances("");
              setPermitCharge("");
              setTollCharge("");
              setParkingCharge("");
              setserviceCharge("");
              handleShow();
            }}
          >
            + Generate Invoice
          </div>
        </div>

        <div className="container">
          <div className="scrolll_bar">
            <div className="row">
              <div className="col-md-4">
                <div className="searchHeadFlex">
              <div className="searchWrapper" style={{ display: "flex" }}>
                <input
                  type="text"
                  maxlength="230"
                  style={{ outlineWidth: "0px", padding: "4px" }}
                  className="textsearch"
                  placeholder="Search by ..."
                  onChange={handleFilter}
                />
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  id="BsSearch"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                    clip-rule="evenodd"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
           
              </div>
              <div className="col-md-2">
              <label htmlFor="">From</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => setfromd(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="">To</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => settod(e.target.value)}
              />
            </div>
            <div className="col-md-2">
         
              <button onClick={searchDate}
                style={{
                  borderRadius: "3px",
                  color: "white",
                  fontSize: "18px",
                  border: "1px solid",
                  backgroundColor: "#004aad",
                  padding: "5px",
                  marginTop: "26px",
                  borderRadius: "10px",
                  fontWeight: 500,
                }}
              >
                Search
              </button>
            </div>
            </div>
            
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    S.No
                  </th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Date
                  </th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Name
                  </th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Booking Id{" "}
                  </th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Invoice Type{" "}
                  </th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Invoice No.
                  </th>

                  {/* <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Advanced Rescipt Voucher No.:
                  </th> */}

                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Pick Up Point
                  </th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Droping Point
                  </th>

                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Total Amount
                  </th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Payment Status
                  </th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {AllInvoice?.slice(
                  pagesVisited,
                  pagesVisited + usersPerPage
                )?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}.</td>
                      <td>{item?.date}</td>
                      <td>{item?.Name}</td>
                      <td>{item?.BookingId}</td>
                      <td>{item?.InvoiceType}</td>
                      <td>{item?.InvoiceNo}</td>

                      {/* <td>{item?.ExtraMater}</td> */}

                      <td>{item?.PickUpPoint}</td>
                      <td>{item?.DropingPoint}</td>

                      <td>{item?.TotalAmount}</td>
                      <td>{item?.PaymentStatus}</td>
                      {/* <td>{item?.InvoiceNo}</td> */}
                      <td style={{ display: "flex", gap: "5px" }}>
                        {item?.InvoiceType == "Jugle Lodge" ||
                        item?.InvoiceType == "Luxury Train" ||
                        item?.InvoiceType == "Luxury Car" ? (
                          <div
                            className=""
                            onClick={() => {
                              setEdit(item);
                              setdate(item?.date);
                              setName(item?.Name);
                              setemail(item?.email);
                              setnumber(item?.mobile);
                              setBookingId(item?.BookingId);
                              setInvoiceNo(item?.InvoiceNo);
                              setLodgeName(item?.LodgeName);
                              setLodgeCantact(item?.LodgeCantact);
                              setExtraMater(item?.ExtraMater);
                              setLodgeEmail(item?.LodgeEmail);
                              setLodgeCantact(item?.LodgeCantact);
                              setgst(item?.gst);
                              setstateTax(item?.stateTax);
                              settaxiPerson(item?.taxiPerson);
                              settaxiAmount(item?.taxiAmount);
                              setTrainPerson(item?.TrainPerson);
                              setTrainAmount(item?.TrainAmount);
                              setAllotRoom(item?.AllotRoom);

                              setLuxuryCarAmount(item?.LuxuryCarAmount);

                              setPickUpPoint(item?.PickUpPoint);
                              setDropingPoint(item?.DropingPoint);
                              setdropDate(item?.dropDate);
                              setpickDate(item?.pickDate);
                              setstatus(item?.status);
                              setTotalAmount(item?.TotalAmount);
                              setPaymentStatus(item?.PaymentStatus);
                              setInvoiceType(item?.InvoiceType);

                              setpickUpTime(item?.pickUpTime);
                              setdropTime(item?.dropTime);
                              settripType(item?.tripType);
                              setcarHireCharge(item?.carHireCharge);
                              setdriverAllowances(item?.driverAllowances);
                              setPermitCharge(item?.PermitCharge);
                              setTollCharge(item?.TollCharge);
                              setParkingCharge(item?.ParkingCharge);
                              setserviceCharge(item?.serviceCharge);
                              handleShow1();
                            }}
                          >
                            <BiEdit
                              style={{
                                color: "blue",
                                fontSize: "25px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}

                        {item?.InvoiceType == "Taxi" ? (
                          <a href={"/invoice/" + item?._id}>
                            <button type="button" class="btn btn-success">
                              Invoice
                            </button>
                          </a>
                        ) : (
                          <></>
                        )}
                        {item?.InvoiceType == "Luxury Train" ? (
                          <a href={"/Luxury-Train-Invoice/" + item?._id}>
                            <button type="button" class="btn btn-success">
                              Invoice
                            </button>
                          </a>
                        ) : (
                          <></>
                        )}
                        {item?.InvoiceType == "Luxury Car" ? (
                          <a href={"/Luxury-Invoice/" + item?._id}>
                            <button type="button" class="btn btn-success">
                              Invoice
                            </button>
                          </a>
                        ) : (
                          <></>
                        )}
                        {item?.InvoiceType == "Jugle Lodge" ? (
                          <a href={"/Jungle-Lodge-Invoice/" + item?._id}>
                            <button type="button" class="btn btn-success">
                              Invoice
                            </button>
                          </a>
                        ) : (
                          <></>
                        )}
                        {item?.InvoiceType == "Hotel" ||
                        item?.InvoiceType == "Home Stay" ||
                        item?.InvoiceType == "Resort" ? (
                          <a href={"/Hotel-Invoice/" + item?.hotelbookId}>
                            <button type="button" class="btn btn-success">
                              Invoice
                            </button>
                          </a>
                        ) : (
                          <></>
                        )}

                        <div
                          className=""
                          onClick={() => {
                            setDeleteId(item?._id);
                            handleDelete();
                          }}
                        >
                          <AiFillDelete
                            style={{
                              color: "red",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <form>
              <label className="pb-2 nd-2">Invoice Type</label>
              <br />
              <select
                className="title-90"
                name="driverBata"
                onChange={(e) => setInvoiceType(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Jugle Lodge">Jugle Lodge</option>
                <option value="Luxury Train">Luxury Train</option>
                <option value="Luxury Car">Luxury Car</option>
              </select>
              <br />
              <label className="pb-2 nd-2">Date</label>
              <br />
              <input
                type="date"
                className="title-90"
                name="driverBata"
                placeholder="Enter Invoice No...."
                value={date}
                onChange={(e) => setdate(e.target.value)}
                required
              />
              <br />
              <label className="pb-2 nd-2">Name</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="driverBata"
                placeholder="Enter User Name...."
                value={Name}
                onChange={(e) => setName(e.target.value)}
                required
              />

              <br />

              <label className="pb-2 nd-2">Email</label>
              <br />
              <input
                type="email"
                className="title-90"
                name="driverBata"
                placeholder="Enter User email...."
                value={email}
                onChange={(e) => setemail(e.target.value)}
                required
              />
              <br />
              <label className="pb-2 nd-2">Mobile Number</label>
              <br />
              <input
                type="number"
                className="title-90"
                name="driverBata"
                placeholder="Enter User mobile number...."
                value={number}
                onChange={(e) => setnumber(e.target.value)}
                required
              />
              <br />
              {InvoiceType == "Jugle Lodge" ? (
                <>
                  <label className="pb-2 nd-2">Jungle Lodege Name</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="driverBata"
                    placeholder="Enter lodge Name...."
                    value={LodgeName}
                    onChange={(e) => setLodgeName(e.target.value)}
                  />
                  <br />
                  <label className="pb-2 nd-2">Lodge Contact Number</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="driverBata"
                    placeholder="Enter lodge number...."
                    value={LodgeCantact}
                    onChange={(e) => setLodgeCantact(e.target.value)}
                  />
                  <br />
                  <label className="pb-2 nd-2">Lodge Email</label>
                  <br />
                  <input
                    type="email"
                    className="title-90"
                    name="driverBata"
                    placeholder="Enter lodge email...."
                    value={LodgeEmail}
                    onChange={(e) => setLodgeEmail(e.target.value)}
                  />
                  <br />
                  <label className="pb-2 nd-2">Lodge Address</label>
                  <br />
                  <input
                    type="email"
                    className="title-90"
                    name="driverBata"
                    placeholder="Enter lodge Name...."
                    value={PickUpPoint}
                    onChange={(e) => setPickUpPoint(e.target.value)}
                  />
                  <br />
                  <label className="pb-2 nd-2">
                    Fare Break Up Add One by one
                  </label>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Room No.</label>
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        min={0}
                        placeholder="Enter room number...."
                        value={roomNo}
                        onChange={(e) => setroomNo(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Package Name</label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter package name...."
                        value={packageName}
                        onChange={(e) => setpackageName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Number Of Adult</label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Number Of Adult...."
                        value={person}
                        min={0}
                        onChange={(e) => setperson(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Number Of Children</label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Number Of Children...."
                        value={child}
                        min={0}
                        onChange={(e) => setchild(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Days</label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Days...."
                        value={days}
                        min={0}
                        onChange={(e) => setdays(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Night</label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Night...."
                        value={night}
                        min={0}
                        onChange={(e) => setnight(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Discount</label>
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Amount...."
                        value={discount}
                        min={0}
                        onChange={(e) => setdiscount(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Total Price</label>
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Amount...."
                        value={totalP}
                        min={0}
                        onChange={(e) => settotalP(e.target.value)}
                        required
                      />
                    </div>

                    <div
                      style={{
                        textAlign: "center",
                        margin: "10px 0px 10px 0px",
                      }}
                    >
                      <button
                        type="button"
                        class="btn btn-success"
                        onClick={AddRoomAlot}
                      >
                        Add
                      </button>
                      {AllotRoom?.length != 0 ? (
                        <button
                          type="button"
                          class="btn btn-danger"
                          style={{ marginLeft: "3px" }}
                          onClick={() => {
                            setAllotRoom([]);
                          }}
                        >
                          Reset
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="row">
                      {AllotRoom?.map((item, i) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>{i + 1}.</div>
                            <div>RoomNo:-{item?.roomNo}</div>
                            <div>{item?.packageName}</div>
                            <div>{item?.person}</div>
                            <div>{item?.child}</div>
                            <div>{item?.days}</div>
                            <div>{item?.night}</div>
                            <div>{item?.discount}</div>
                            <div>{item?.totalP}</div>
                          </div>
                        );
                      })}
                    </div>
                    <br />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Check In Date</label>
                      <input
                        type="date"
                        className="title-90"
                        name="driverBata"
                        value={pickDate}
                        onChange={(e) => setpickDate(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Check Out Date</label>
                      <input
                        type="date"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Amount...."
                        value={dropDate}
                        onChange={(e) => setdropDate(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="pb-2 nd-2">Extra Mater</label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter extra mater...."
                        value={ExtraMater}
                        onChange={(e) => setExtraMater(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {InvoiceType == "Luxury Car" ? (
                <>
                  {" "}
                  <label className="pb-2 nd-2">Luxury Car</label>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Vehicle Type </label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter pickup Point...."
                        value={LodgeName}
                        onChange={(e) => setLodgeName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Vechicle Name </label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter drop point...."
                        value={LodgeEmail}
                        onChange={(e) => setLodgeEmail(e.target.value)}
                        required
                      />
                    </div>

                    <label className="pb-2 nd-2">Pick Up Point</label>
                    <br />
                    <input
                      type="text"
                      className="title-90"
                      name="driverBata"
                      placeholder="Enter Pick Up Point...."
                      value={PickUpPoint}
                      onChange={(e) => setPickUpPoint(e.target.value)}
                      required
                    />
                    <br />
                    <label className="pb-2 nd-2">Droping Point</label>
                    <br />
                    <input
                      type="text"
                      className="title-90"
                      name="driverBata"
                      placeholder="Enter Droping Point...."
                      value={DropingPoint}
                      onChange={(e) => setDropingPoint(e.target.value)}
                      required
                    />
                    <br />
                    <label className="pb-2 nd-2">Package Name</label>
                    <br />
                    <input
                      type="text"
                      className="title-90"
                      name="driverBata"
                      placeholder="Enter Package Name...."
                      value={taxiPerson}
                      onChange={(e) => settaxiPerson(e.target.value)}
                      required
                    />
                    <br />
                    <div className="col-md-3">
                      <label className="pb-2 nd-2">PickUpDate</label>
                      <br />
                      <input
                        type="date"
                        className="title-90"
                        name="driverBata"
                        value={pickDate}
                        onChange={(e) => setpickDate(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="pb-2 nd-2">PickUpTime</label>
                      <br />
                      <input
                        type="time"
                        className="title-90"
                        name="driverBata"
                        value={pickUpTime}
                        onChange={(e) => setpickUpTime(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="pb-2 nd-2">Drop Date</label>
                      <br />
                      <input
                        type="date"
                        className="title-90"
                        name="driverBata"
                        value={dropDate}
                        onChange={(e) => setdropDate(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="pb-2 nd-2">Drop Time</label>
                      <br />
                      <input
                        type="time"
                        className="title-90"
                        name="driverBata"
                        value={dropTime}
                        onChange={(e) => setdropTime(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Trip Type</label>
                      <br />
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Trip type..."
                        value={tripType}
                        onChange={(e) => settripType(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Car Hire Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter car hire charges..."
                        value={carHireCharge}
                        onChange={(e) => setcarHireCharge(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Extra Km/Hr Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Extra Km/Hr Charges..."
                        value={ExtraMater}
                        onChange={(e) => setExtraMater(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">
                        Driver Bata(Day/Night)
                      </label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Driver Bata(Day/Night)..."
                        value={driverAllowances}
                        onChange={(e) => setdriverAllowances(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Toll Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Toll Charges..."
                        value={TollCharge}
                        onChange={(e) => setTollCharge(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Parking Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Parking Charges..."
                        value={ParkingCharge}
                        onChange={(e) => setParkingCharge(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Permit Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Permit Charges..."
                        value={PermitCharge}
                        onChange={(e) => setPermitCharge(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Service charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Service Charges..."
                        value={serviceCharge}
                        onChange={(e) => setserviceCharge(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {InvoiceType == "Luxury Train" ? (
                <>
                  {" "}
                  <label className="pb-2 nd-2">Luxury Train</label>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">PNR Number </label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter pnr number...."
                        value={LodgeName}
                        onChange={(e) => setLodgeName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2"> Train Name </label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter  Train Name or number...."
                        value={LodgeEmail}
                        onChange={(e) => setLodgeEmail(e.target.value)}
                        required
                      />
                    </div>

                    <label className="pb-2 nd-2">From </label>
                    <br />
                    <input
                      type="text"
                      className="title-90"
                      name="driverBata"
                      placeholder="Enter from...."
                      value={PickUpPoint}
                      onChange={(e) => setPickUpPoint(e.target.value)}
                      required
                    />
                    <br />
                    <label className="pb-2 nd-2">To</label>
                    <br />
                    <input
                      type="text"
                      className="title-90"
                      name="driverBata"
                      placeholder="Enter to...."
                      value={DropingPoint}
                      onChange={(e) => setDropingPoint(e.target.value)}
                      required
                    />
                    <br />
                    <label className="pb-2 nd-2">Package Name</label>
                    <br />
                    <input
                      type="text"
                      className="title-90"
                      name="driverBata"
                      placeholder="Enter Package Name...."
                      value={taxiPerson}
                      onChange={(e) => settaxiPerson(e.target.value)}
                      required
                    />
                    <br />
                    <div className="col-md-3">
                      <label className="pb-2 nd-2">Start Date</label>
                      <br />
                      <input
                        type="date"
                        className="title-90"
                        name="driverBata"
                        value={pickDate}
                        onChange={(e) => setpickDate(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="pb-2 nd-2">BordingTime</label>
                      <br />
                      <input
                        type="time"
                        className="title-90"
                        name="driverBata"
                        value={pickUpTime}
                        onChange={(e) => setpickUpTime(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="pb-2 nd-2">Drop Date</label>
                      <br />
                      <input
                        type="date"
                        className="title-90"
                        name="driverBata"
                        value={dropDate}
                        onChange={(e) => setdropDate(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="pb-2 nd-2">Drop Time</label>
                      <br />
                      <input
                        type="time"
                        className="title-90"
                        name="driverBata"
                        value={dropTime}
                        onChange={(e) => setdropTime(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Seat No</label>
                      <br />
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Seat number..."
                        value={tripType}
                        onChange={(e) => settripType(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Ticket Fare </label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Ticket Faree charges..."
                        value={carHireCharge}
                        onChange={(e) => setcarHireCharge(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Convenience Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Convenience Charges..."
                        value={ExtraMater}
                        onChange={(e) => setExtraMater(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">
                        Travel Insurance Premium
                      </label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Travel Insurance Premium..."
                        value={driverAllowances}
                        onChange={(e) => setdriverAllowances(e.target.value)}
                        required
                      />
                    </div>
                    {/* <div className="col-md-6">
                      <label className="pb-2 nd-2">Toll Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Toll Charges..."
                        value={TollCharge}
                        onChange={(e) => setTollCharge(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Parking Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Parking Charges..."
                        value={ParkingCharge}
                        onChange={(e) => setParkingCharge(e.target.value)}
                        required
                      />
                    </div> */}
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Permit Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Permit Charges..."
                        value={PermitCharge}
                        onChange={(e) => setPermitCharge(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Service charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Other Service Charges..."
                        value={serviceCharge}
                        onChange={(e) => setserviceCharge(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="row">
                <div className="col-md-6">
                  <label className="pb-2 nd-2">GST</label>
                  <input
                    type="number"
                    className="title-90"
                    name="driverBata"
                    min={0}
                    placeholder="Enter No of Persons...."
                    value={gst}
                    onChange={(e) => setgst(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label className="pb-2 nd-2">State Tax(IGST)</label>
                  <input
                    type="number"
                    className="title-90"
                    name="driverBata"
                    placeholder="Enter Amount...."
                    value={stateTax}
                    min={0}
                    onChange={(e) => setstateTax(e.target.value)}
                    required
                  />
                </div>
              </div>

              <br />

              <label className="pb-2 nd-2">Total Amount</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="driverBata"
                placeholder="Enter Total Amount...."
                value={TotalAmount}
                onChange={(e) => setTotalAmount(e.target.value)}
                required
              />
              <br />
              <label className="pb-2 nd-2"> Status</label>
              <br />
              <select
                className="title-90"
                name="driverBata"
                onChange={(e) => setstatus(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Pending">Pending</option>
                <option value="Confirm">Confirm</option>
                <option value="Cancel">Cancel</option>
                <option value="Complete">Complete</option>
              </select>
              <br />
              <label className="pb-2 nd-2">Payment Status</label>
              <br />
              <select
                className="title-90"
                name="driverBata"
                onChange={(e) => setPaymentStatus(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Pending">Pending</option>
                <option value="Full Payment">Full Payment</option>
              </select>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Back
          </Button>
          <Button variant="primary" onClick={AddInvoiceGenration}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Update Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <form>
              <label className="pb-2 nd-2">Invoice Type</label>
              <br />
              <select
                className="title-90"
                name="driverBata"
                onChange={(e) => setInvoiceType(e.target.value)}
              >
                <option value={InvoiceType}>{InvoiceType}</option>
                <option value="Jugle Lodge">Jugle Lodge</option>
                <option value="Luxury Train">Luxury Train</option>
                <option value="Luxury Car">Luxury Car</option>
              </select>
              <br />
              <label className="pb-2 nd-2">Date</label>
              <br />
              <input
                type="date"
                className="title-90"
                name="driverBata"
                placeholder="Enter Invoice No...."
                value={date}
                onChange={(e) => setdate(e.target.value)}
                required
              />
              <br />
              <label className="pb-2 nd-2">Name</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="driverBata"
                placeholder="Enter User Name...."
                value={Name}
                onChange={(e) => setName(e.target.value)}
                required
              />

              <br />

              <label className="pb-2 nd-2">Email</label>
              <br />
              <input
                type="email"
                className="title-90"
                name="driverBata"
                placeholder="Enter User email...."
                value={email}
                onChange={(e) => setemail(e.target.value)}
                required
              />
              <br />
              <label className="pb-2 nd-2">Mobile Number</label>
              <br />
              <input
                type="number"
                className="title-90"
                name="driverBata"
                placeholder="Enter User mobile number...."
                value={number}
                onChange={(e) => setnumber(e.target.value)}
                required
              />
              <br />
              {InvoiceType == "Jugle Lodge" ? (
                <>
                  <label className="pb-2 nd-2">Jungle Lodege Name</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="driverBata"
                    placeholder="Enter lodge Name...."
                    value={LodgeName}
                    onChange={(e) => setLodgeName(e.target.value)}
                  />
                  <br />
                  <label className="pb-2 nd-2">Lodge Contact Number</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="driverBata"
                    placeholder="Enter lodge number...."
                    value={LodgeCantact}
                    onChange={(e) => setLodgeCantact(e.target.value)}
                  />
                  <br />
                  <label className="pb-2 nd-2">Lodge Email</label>
                  <br />
                  <input
                    type="email"
                    className="title-90"
                    name="driverBata"
                    placeholder="Enter lodge email...."
                    value={LodgeEmail}
                    onChange={(e) => setLodgeEmail(e.target.value)}
                  />
                  <br />
                  <label className="pb-2 nd-2">Lodge Address</label>
                  <br />
                  <input
                    type="email"
                    className="title-90"
                    name="driverBata"
                    placeholder="Enter lodge Name...."
                    value={PickUpPoint}
                    onChange={(e) => setPickUpPoint(e.target.value)}
                  />
                  <br />
                  <label className="pb-2 nd-2">
                    Fare Break Up Add One by one
                  </label>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Room No.</label>
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        min={0}
                        placeholder="Enter room number...."
                        value={roomNo}
                        onChange={(e) => setroomNo(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Package Name</label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter package name...."
                        value={packageName}
                        onChange={(e) => setpackageName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Number Of Adult</label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Number Of Adult...."
                        value={person}
                        min={0}
                        onChange={(e) => setperson(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Number Of Children</label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Number Of Children...."
                        value={child}
                        min={0}
                        onChange={(e) => setchild(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Days</label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Days...."
                        value={days}
                        min={0}
                        onChange={(e) => setdays(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Night</label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Night...."
                        value={night}
                        min={0}
                        onChange={(e) => setnight(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Discount</label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Amount...."
                        value={discount}
                        min={0}
                        onChange={(e) => setdiscount(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Total Price</label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Amount...."
                        value={totalP}
                        min={0}
                        onChange={(e) => settotalP(e.target.value)}
                        required
                      />
                    </div>

                    <div
                      style={{
                        textAlign: "center",
                        margin: "10px 0px 10px 0px",
                      }}
                    >
                      <button
                        type="button"
                        class="btn btn-success"
                        onClick={AddRoomAlot}
                      >
                        Add
                      </button>
                      {AllotRoom?.length != 0 ? (
                        <button
                          type="button"
                          class="btn btn-danger"
                          style={{ marginLeft: "3px" }}
                          onClick={() => {
                            setAllotRoom([]);
                          }}
                        >
                          Reset
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="row">
                      {AllotRoom?.map((item, i) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>{i + 1}.</div>
                            <div>RoomNo:-{item?.roomNo}</div>
                            <div>{item?.packageName}</div>
                            <div>{item?.person}</div>
                            <div>{item?.child}</div>
                            <div>{item?.days}</div>
                            <div>{item?.night}</div>
                            <div>{item?.discount}</div>
                            <div>{item?.totalP}</div>
                          </div>
                        );
                      })}
                    </div>
                    <br />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Check In Date</label>
                      <input
                        type="date"
                        className="title-90"
                        name="driverBata"
                        value={pickDate}
                        onChange={(e) => setpickDate(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Check Out Date</label>
                      <input
                        type="date"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Amount...."
                        value={dropDate}
                        onChange={(e) => setdropDate(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="pb-2 nd-2">Extra Mater</label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter extra mater...."
                        value={ExtraMater}
                        onChange={(e) => setExtraMater(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {InvoiceType == "Luxury Car" ? (
                <>
                  {" "}
                  <label className="pb-2 nd-2">Luxury Car</label>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Vehicle Type </label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter pickup Point...."
                        value={LodgeName}
                        onChange={(e) => setLodgeName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Vechicle Name </label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter drop point...."
                        value={LodgeEmail}
                        onChange={(e) => setLodgeEmail(e.target.value)}
                        required
                      />
                    </div>

                    <label className="pb-2 nd-2">Pick Up Point</label>
                    <br />
                    <input
                      type="text"
                      className="title-90"
                      name="driverBata"
                      placeholder="Enter Pick Up Point...."
                      value={PickUpPoint}
                      onChange={(e) => setPickUpPoint(e.target.value)}
                      required
                    />
                    <br />
                    <label className="pb-2 nd-2">Droping Point</label>
                    <br />
                    <input
                      type="text"
                      className="title-90"
                      name="driverBata"
                      placeholder="Enter Droping Point...."
                      value={DropingPoint}
                      onChange={(e) => setDropingPoint(e.target.value)}
                      required
                    />
                    <br />
                    <label className="pb-2 nd-2">Package Name</label>
                    <br />
                    <input
                      type="text"
                      className="title-90"
                      name="driverBata"
                      placeholder="Enter Package Name...."
                      value={taxiPerson}
                      onChange={(e) => settaxiPerson(e.target.value)}
                      required
                    />
                    <br />
                    <div className="col-md-3">
                      <label className="pb-2 nd-2">PickUpDate</label>
                      <br />
                      <input
                        type="date"
                        className="title-90"
                        name="driverBata"
                        value={pickDate}
                        onChange={(e) => setpickDate(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="pb-2 nd-2">PickUpTime</label>
                      <br />
                      <input
                        type="time"
                        className="title-90"
                        name="driverBata"
                        value={pickUpTime}
                        onChange={(e) => setpickUpTime(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="pb-2 nd-2">Drop Date</label>
                      <br />
                      <input
                        type="date"
                        className="title-90"
                        name="driverBata"
                        value={dropDate}
                        onChange={(e) => setdropDate(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="pb-2 nd-2">Drop Time</label>
                      <br />
                      <input
                        type="time"
                        className="title-90"
                        name="driverBata"
                        value={dropTime}
                        onChange={(e) => setdropTime(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Trip Type</label>
                      <br />
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Trip type..."
                        value={tripType}
                        onChange={(e) => settripType(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Car Hire Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter car hire charges..."
                        value={carHireCharge}
                        onChange={(e) => setcarHireCharge(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Extra Km/Hr Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Extra Km/Hr Charges..."
                        value={ExtraMater}
                        onChange={(e) => setExtraMater(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">
                        Driver Bata(Day/Night)
                      </label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Driver Bata(Day/Night)..."
                        value={driverAllowances}
                        onChange={(e) => setdriverAllowances(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Toll Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Toll Charges..."
                        value={TollCharge}
                        onChange={(e) => setTollCharge(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Parking Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Parking Charges..."
                        value={ParkingCharge}
                        onChange={(e) => setParkingCharge(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Permit Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Permit Charges..."
                        value={PermitCharge}
                        onChange={(e) => setPermitCharge(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Service charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Service Charges..."
                        value={serviceCharge}
                        onChange={(e) => setserviceCharge(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {InvoiceType == "Luxury Train" ? (
                <>
                  {" "}
                  <label className="pb-2 nd-2">Luxury Train</label>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">PNR Number </label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter pnr number...."
                        value={LodgeName}
                        onChange={(e) => setLodgeName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2"> Train Name </label>
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter  Train Name or number...."
                        value={LodgeEmail}
                        onChange={(e) => setLodgeEmail(e.target.value)}
                        required
                      />
                    </div>

                    <label className="pb-2 nd-2">From </label>
                    <br />
                    <input
                      type="text"
                      className="title-90"
                      name="driverBata"
                      placeholder="Enter from...."
                      value={PickUpPoint}
                      onChange={(e) => setPickUpPoint(e.target.value)}
                      required
                    />
                    <br />
                    <label className="pb-2 nd-2">To</label>
                    <br />
                    <input
                      type="text"
                      className="title-90"
                      name="driverBata"
                      placeholder="Enter to...."
                      value={DropingPoint}
                      onChange={(e) => setDropingPoint(e.target.value)}
                      required
                    />
                    <br />
                    <label className="pb-2 nd-2">Package Name</label>
                    <br />
                    <input
                      type="text"
                      className="title-90"
                      name="driverBata"
                      placeholder="Enter Package Name...."
                      value={taxiPerson}
                      onChange={(e) => settaxiPerson(e.target.value)}
                      required
                    />
                    <br />
                    <div className="col-md-3">
                      <label className="pb-2 nd-2">Start Date</label>
                      <br />
                      <input
                        type="date"
                        className="title-90"
                        name="driverBata"
                        value={pickDate}
                        onChange={(e) => setpickDate(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="pb-2 nd-2">BordingTime</label>
                      <br />
                      <input
                        type="time"
                        className="title-90"
                        name="driverBata"
                        value={pickUpTime}
                        onChange={(e) => setpickUpTime(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="pb-2 nd-2">Drop Date</label>
                      <br />
                      <input
                        type="date"
                        className="title-90"
                        name="driverBata"
                        value={dropDate}
                        onChange={(e) => setdropDate(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="pb-2 nd-2">Drop Time</label>
                      <br />
                      <input
                        type="time"
                        className="title-90"
                        name="driverBata"
                        value={dropTime}
                        onChange={(e) => setdropTime(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Seat No</label>
                      <br />
                      <input
                        type="text"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Seat number..."
                        value={tripType}
                        onChange={(e) => settripType(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Ticket Fare </label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Ticket Faree charges..."
                        value={carHireCharge}
                        onChange={(e) => setcarHireCharge(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Convenience Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Convenience Charges..."
                        value={ExtraMater}
                        onChange={(e) => setExtraMater(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">
                        Travel Insurance Premium
                      </label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Travel Insurance Premium..."
                        value={driverAllowances}
                        onChange={(e) => setdriverAllowances(e.target.value)}
                        required
                      />
                    </div>
                    {/* <div className="col-md-6">
                      <label className="pb-2 nd-2">Toll Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Toll Charges..."
                        value={TollCharge}
                        onChange={(e) => setTollCharge(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Parking Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Parking Charges..."
                        value={ParkingCharge}
                        onChange={(e) => setParkingCharge(e.target.value)}
                        required
                      />
                    </div> */}
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Permit Charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Permit Charges..."
                        value={PermitCharge}
                        onChange={(e) => setPermitCharge(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="pb-2 nd-2">Service charges</label>
                      <br />
                      <input
                        type="number"
                        className="title-90"
                        name="driverBata"
                        placeholder="Enter Other Service Charges..."
                        value={serviceCharge}
                        onChange={(e) => setserviceCharge(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="row">
                <div className="col-md-6">
                  <label className="pb-2 nd-2">GST</label>
                  <input
                    type="number"
                    className="title-90"
                    name="driverBata"
                    min={0}
                    placeholder="Enter No of Persons...."
                    value={gst}
                    onChange={(e) => setgst(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label className="pb-2 nd-2">State Tax</label>
                  <input
                    type="number"
                    className="title-90"
                    name="driverBata"
                    placeholder="Enter Amount...."
                    value={stateTax}
                    min={0}
                    onChange={(e) => setstateTax(e.target.value)}
                    required
                  />
                </div>
              </div>

              <br />

              <label className="pb-2 nd-2">Total Amount</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="driverBata"
                placeholder="Enter Total Amount...."
                value={TotalAmount}
                onChange={(e) => setTotalAmount(e.target.value)}
                required
              />
              <br />
              <label className="pb-2 nd-2"> Status</label>
              <br />
              <select
                className="title-90"
                name="driverBata"
                onChange={(e) => setstatus(e.target.value)}
              >
                <option value={status}>{status}</option>
                <option value="Pending">Pending</option>
                <option value="Confirm">Confirm</option>
                <option value="Cancel">Cancel</option>
                <option value="Complete">Complete</option>
              </select>
              <br />
              <label className="pb-2 nd-2">Payment Status</label>
              <br />
              <select
                className="title-90"
                name="driverBata"
                onChange={(e) => setPaymentStatus(e.target.value)}
              >
                <option value={PaymentStatus}>{PaymentStatus}</option>
                <option value="Pending">Pending</option>
                <option value="Full Payment">Full Payment</option>
              </select>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={updateInvoice}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDelete} onHide={closeDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete ??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <div style={{ float: "right" }}>
            <Button variant="danger" onClick={closeDelete}>
              Cancel
            </Button>{" "}
            <Button variant="success" onClick={deleteInvoice}>
              Ok
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminGenerateinvoice;
