import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import "../Admin/admin.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { PriceCheckOutlined } from "@mui/icons-material";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { CiCircleRemove } from "react-icons/ci";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function HotelSub() {
  const [selectedOption, setSelectedOption] = useState("Hotel");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // price Modal---
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  // delete Modal---
  const [deleteId, setDeleteId] = useState("");
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // Includes Modal---
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // Excludes Modal---
  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  // ITINERARY model ---
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  // Excludes Modal---
  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  // const handleShow4 = () => setShow4(true);

  const [lodgename, setlodgename] = useState("");
  const [Category, setCategory] = useState("");
  const [Location, setLocation] = useState("");
  const [totalRoom, settotalRoom] = useState("");
  const [Lodgeprofile, setLodgeprofile] = useState("");
  const [Lodgetiming, setlodgetiming] = useState("");
  const [Lodgeownername, setLodgeownername] = useState("");
  const [Email, setEmail] = useState("");
  const [Contact, setContact] = useState("");
  const [Overview, setOverview] = useState("");
  const [startprice, setstartprice] = useState("");

  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutTime, setcheckOutTime] = useState("");
  const [price, setprice] = useState([]);

  const [include, setinclude] = useState([]);
  const [inclTest, setinclText] = useState("");
  const vendor = JSON.parse(sessionStorage.getItem("vendor"));

  const [suggetion, setsuggetion] = useState("");
  const [termCondition, setTermCondition] = useState("");
  const [gst, setgst] = useState(0);
  const [igst, setigst] = useState(0);
  const [nochGst, setnochGst] = useState(0);

  const [city,setcity]=useState("")
  const [cgst,setcgst]=useState(0);
  const [sgst,setsgst]=useState(0);

  const [AllGst,setAllGst]=useState([]);

  const getAllGst=async()=>{
      try {
          let res=await axios.get("https://dhanyatours.me/api/admin/getGst");
          if(res.status==200){
              setAllGst(res.data.success)
          }
      } catch (error) {
          console.log(error);
      }
  }
  
  const [AllCity, setAllcity] = useState([]);
  const getCity = async () => {
    try {
      const config = {
        url: "/GetCity",
        method: "get",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setAllcity(res.data.success);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(()=>{
    getAllGst()
    getCity();
  },[])

  const [AllCategory, setAllCategory] = useState([]);
  const getAllCategory = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/user/getAllRoomcategoryByVendorId/" +
          vendor?._id
      );
      if (res.status == 200) {
        setAllCategory(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (vendor) {
      getAllCategory();
      setSelectedOption(vendor?.vendorType)
    }
  }, []);
  const handleShow4 = (data) => {
    setgst(data?.gst);
    setigst(data?.igst);
    setOverview(data?.OVERVIEW);
    settotalRoom(data?.totalRoom);
    setsuggetion(data?.suggestion);
    setTermCondition(data?.termsCondition);
    setlodgename(data?.hotalName);
    setLocation(data?.location);
    setLodgeprofile(data?.termsCondition);
    setlodgetiming(data?.lodgeTiming);
    setLodgeownername(data?.HotelOwnerName);
    setstartprice(data?.StartingPrice);
    setEmail(data?.email);
    setContact(data?.contact);
    setcgst(data?.cgst);
    setsgst(data?.sgst)
    setcity(data?.city)
    setShow4(true);
  };

  const addInclude = () => {
    let inc = {};
    if (inclTest) {
      inc.text = inclTest;
    }
    include.push(inc);
    alert("Success Added");
    setinclText("");
  };
  const [Excludes, setExcludes] = useState([]);
  const [exText, setexText] = useState("");
  const addExclude = () => {
    let inc = {};
    if (exText) {
      inc.text1 = exText;
    }
    Excludes.push(inc);
    alert("Success Added");
    setexText("");
  };

  const [packageType, setpackageType] = useState("");
  const [Packagename, setPackagename] = useState("");
  const [Doublyweekprice, setDoublyweekprice] = useState("");
  const [Doublyweekendprice, setDoublyweekendprice] = useState("");
  const [Singleweekprice, setSingleweekprice] = useState("");
  const [Singleweekendprice, setSingleweekendprice] = useState("");
  const addPrice = () => {
    let priceObg = {};
    if (Packagename) {
      priceObg.packageName = Packagename;
    }
    if (packageType) {
      priceObg.packageType = packageType;
    }
    if (Doublyweekprice) {
      priceObg.doublyWeekPrice = Doublyweekprice;
    }
    if (Doublyweekendprice) {
      priceObg.doublyWeekendPrice = Doublyweekendprice;
    }
    if (Singleweekprice) {
      priceObg.singleWeekPrice = Singleweekprice;
    }
    if (Singleweekendprice) {
      priceObg.singleWeekendPrice = Singleweekendprice;
    }
    price.push(priceObg);
    alert("Success Added");
    setPackagename("");
    setDoublyweekprice("");
    setDoublyweekendprice("");
    setSingleweekprice("");
    setSingleweekendprice("");
    setpackageType("");
  };
  const [ITINERARY, setITINERARY] = useState([]);
  const [timeTxt, settimeTxt] = useState("");
  const [deatails, setDetails] = useState("");
  const AddIntity = () => {
    let obj = {};
    if (timeTxt) {
      obj["time"] = timeTxt;
    }
    if (deatails) {
      obj["details"] = deatails;
    }
    ITINERARY.push(obj);
    alert("Success");
    settimeTxt("");
    setDetails("");
  };

  const onSubmit = async () => {
    try {
      const config = {
        url: "/AddHotelDetails",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "multipart/form-data" },
        data: {
          hotalName: lodgename,
          HotelOwnerName: Lodgeownername,
          HotelProfile: Lodgeprofile,
          OVERVIEW: Overview,
          lodgeTiming: Lodgetiming,
          location: Location,
          contact: Contact,
          category: selectedOption,
          suggestion: suggetion,
          termsCondition: termCondition,
          gst:(sgst+cgst),
          igst:igst,
          sgst:sgst,
          cgst:cgst,
          city:city,
          totalRoom: totalRoom,
          email: Email,
          PRICING: price,
          Includes: include,
          Excludes: Excludes,
          StartingPrice: startprice,
          CheckInTime: moment(checkInTime, ["HH:mm:ss"]).format("hh:mm a"),
          CheckOutTime: moment(checkOutTime, ["HH:mm:ss"]).format("hh:mm a"),
          wenderId: vendor?._id,
          ITINERARY: ITINERARY,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("successfully Added");
        handleClose();
        lodges();
      
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setOverview(data);
  };

  const handleChange3 = (e, editor) => {
    const data = editor.getData();
    setsuggetion(data);
  };

  const handleChange4 = (e, editor) => {
    const data = editor.getData();
    setTermCondition(data);
  };
  const [lodgess, setLoadgess] = useState([]);
  const lodges = async () => {
    const config = {
      url: "/getHotelByVendorId/" + vendor?._id,
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setLoadgess(result.data.success);
      }
    } catch (error) {}
  };
  useEffect(() => {
    lodges();
  
  }, []);

  const onDelete = async (id) => {
    try {
      const config = {
        url: "/deleteHotel/" + deleteId,
        method: "delete",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Delete");
        lodges();
        handleClose6();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  // Edit

  const [edit, setedit] = useState({});
  const onEdit = async () => {
  
    try {
      const config = {
        url: "/editHotel",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "multipart/form-data" },
        data: {
          lodgeId: edit?._id,
          hotalName: lodgename,
          HotelOwnerName: Lodgeownername,
          HotelProfile: Lodgeprofile,
          OVERVIEW: Overview,
          lodgeTiming: Lodgetiming,
          location: Location,
          contact: Contact,
          email: Email,
          gst:(sgst+cgst),
          igst:igst,
          sgst:sgst,
          cgst:cgst,
          city:city,
          totalRoom: totalRoom,
          suggestion: suggetion,
          termsCondition: termCondition,
          StartingPrice: startprice,
          CheckInTime: checkInTime
            ? moment(checkInTime, ["HH:mm:ss"]).format("hh:mm a")
            : "",
          CheckOutTime: checkOutTime
            ? moment(checkOutTime, ["HH:mm:ss"]).format("hh:mm a")
            : "",
          status: "Pending",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Updated");
        lodges();
        handleClose4();
      
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  // back function
  const getbyLodgeId = async (id) => {
    try {
      const config = {
        url: "/getbyHotelId/" + id,
        method: "get",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setedit(res.data.success);
        setImagesData(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AddpM, setAddpM] = useState(false);

  const [HandlePrice, setHandlePrice] = useState(false);

  const addPricing = async () => {
    try {
      if (!Packagename) {
        return alert("Please enter package name!");
      }
      if (!packageType) return alert("Please select package type");
      if (!Singleweekprice) {
        return alert("Please enter singal week price!");
      }
      if (!Singleweekendprice) {
        return alert("Please enter singal weekend price!");
      }
      if (!Doublyweekprice) {
        return alert("Please enter double week price!");
      }
      if (!Doublyweekendprice) {
        return alert("Please enter double weekend price!");
      }

      const config = {
        url: "/AddHotelPricing",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          lodgeId: imagesData._id,
          packageName: Packagename,
          singleWeekendPrice: Singleweekendprice,
          singleWeekPrice: Singleweekprice,
          doublyWeekPrice: Doublyweekprice,
          doublyWeekendPrice: Doublyweekendprice,
          packageType: packageType,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfull added");
        getbyLodgeId(imagesData._id);
        setDoublyweekendprice("");
        setDoublyweekprice("");
        setSingleweekendprice("");
        setAddpM(false);
        setSingleweekprice("");
        setPackagename("");
        setpackageType("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [deletePrice, setdeletePricId] = useState("");
  const [packDeleM, setPackDeletM] = useState(false);

  const [updateM, setupdateM] = useState(false);

  const updatePricing = async () => {
    try {
      const config = {
        url: "/updatePricingHotel",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          lodgeId: imagesData._id,
          packid: deletePrice,
          packageName: Packagename,
          singleWeekendPrice: Singleweekendprice,
          singleWeekPrice: Singleweekprice,
          doublyWeekPrice: Doublyweekprice,
          doublyWeekendPrice: Doublyweekendprice,
          packageType: packageType,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfull updated");
        getbyLodgeId(imagesData._id);
        setDoublyweekendprice("");
        setupdateM(false);
        setDoublyweekprice("");
        setSingleweekendprice("");
        setSingleweekprice("");
        setPackagename("");
        setpackageType("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deletePricing = async (id) => {
    try {
      const config = {
        url: "/removeHotelPriceing",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: imagesData._id, priceId: deletePrice },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully deleted");
        getbyLodgeId(imagesData._id);
        setPackDeletM(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addIncludeA = async () => {
    try {
      if (!inclTest) {
        return alert("Please enter includes!");
      }
      const config = {
        url: "/AddHotelIncludes",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, text: inclTest },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfull added");
        getbyLodgeId(edit._id);
        setinclText("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteIncludes = async (id) => {
    try {
      const config = {
        url: "/removeHotelIncludes",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, IncludesId: id },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully deleted");
        getbyLodgeId(edit._id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addExcludesA = async () => {
    try {
      if (!exText) {
        return alert("Please enter excludes!");
      }
      const config = {
        url: "/AddHotelExcludes",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, text1: exText },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfull added");
        getbyLodgeId(edit._id);
        setexText("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteExcludes = async (id) => {
    try {
      const config = {
        url: "/removeHotelExcludes",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, ExcludesId: id },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully deleted");
        getbyLodgeId(edit._id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addItineraryA = async () => {
    try {
      if (!timeTxt) {
        return alert("Please enter time!");
      }
      if (!deatails) {
        return alert("Please enter some details!");
      }
      const config = {
        url: "/AddHotelITINERARY",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, time: timeTxt, details: deatails },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfull added");
        getbyLodgeId(edit._id);
        settimeTxt("");
        setDetails("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteItinerary = async (id) => {
    try {
      const config = {
        url: "/removeHotelITINERARY",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, ITINERARYId: id },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully deleted");
        getbyLodgeId(edit._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    lodgess?.filter((ele) => ele?.category === selectedOption)?.length /
      usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = lodgess
        ?.filter((ele) => ele?.category === selectedOption)
        ?.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
          )
        );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setLoadgess([...lodgess]);
    }
  };
  const MakeBlock = async (id, isBloc) => {
    try {
      const config = {
        url: "/makeBlockUnbolck",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { hotelId: id, isBloc: isBloc },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        lodges();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const [imagesData, setImagesData] = useState({});
  const [showImages, setshowImages] = useState(false);
  const hsndleImages = () => setshowImages(true);
  const closeImages = () => setshowImages(false);
  const [image3, setimage3] = useState("");

  const AddSingleImagesHotel = async () => {
    if (!image3) return alert("Please select images");
    try {
      const config = {
        url: "/addSingleImagesHotel",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "multipart/form-data" },
        data: {
          id: imagesData?._id,
          image: image3,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setimage3("");
        lodges();
        setImagesData(res.data.success);
        return alert("Successfully added");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const deleteHotelImages = async (id) => {
    try {
      const config = {
        url: "/deleteSingleImagesHotel",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          id: imagesData?._id,
          removeId: id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setimage3("");
        lodges();
        setImagesData(res.data.success);
        return alert("Successfully deleted");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  return (
    <>
      <div className="de-lod ms-4">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h3>Profile</h3>
          </div>

          <div
            className="logde-op-00"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOverview("");
              setsuggetion("");
              setgst(0);
              setigst(0);
              setTermCondition("");
              setlodgename("");
              setLocation("");
              setLodgeprofile("");
              setlodgetiming("");
              setLodgeownername("");
              setstartprice("");
              setEmail("");
              settotalRoom("");
              setContact("");
              setsgst(0);
              setcgst(0)
              setcity("")
              handleShow();
            }}
          >
            + Add
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {selectedOption === "Hotel" && (
                  <label className="pb-2 nd-2">Add Hotel details</label>
                )}
                {selectedOption === "Resort" && (
                  <label className="pb-2 nd-2">Add Resort details</label>
                )}
                {selectedOption === "HomeStay" && (
                  <label className="pb-2 nd-2">Add Home Stay details</label>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
              
                <form>
                <label className="pb-2 nd-2">City</label>
                  <br />
                  <select
                       className="title-90"
                    onChange={(e) => setcity(e.target.value)}
                  >
                    <option value="">Select</option>
                    {AllCity?.map((item)=>{
                      return ( <option value={`${item?.City},${item?.State}`}>{`${item?.City},${item?.State}`}</option>)
                    })}
                  </select>
                  {selectedOption === "Hotel" && (
                    <label className="pb-2 nd-2">Hotel Name</label>
                  )}
                  {selectedOption === "Resort" && (
                    <label className="pb-2 nd-2">Resort Name</label>
                  )}
                  {selectedOption === "HomeStay" && (
                    <label className="pb-2 nd-2">Home Stay Name</label>
                  )}

                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="lodgename"
                    value={lodgename}
                    required
                    placeholder={`Please enter ${vendor?.vendorType} name`}
                    onChange={(e) => setlodgename(e.target.value)}
                  />
                  <label className="pb-2 nd-2">Location</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="location"
                    value={Location}
                    placeholder={`Please enter ${vendor?.vendorType} location`}
                    required
                    onChange={(e) => setLocation(e.target.value)}
                  />
                  <label className="pb-2 nd-2">Total Rooms</label>
                  <br />
                  <input
                    type="number"
                    className="title-90"
                    name="location"
                    value={totalRoom}
                    placeholder="Please enter total rooms"
                    required
                    onChange={(e) => settotalRoom(e.target.value)}
                  />

                  {selectedOption === "Hotel" && (
                    <label className="pb-2 nd-2" htmlFor="upload1">
                      Hotel Profile
                    </label>
                  )}
                  {selectedOption === "Resort" && (
                    <label className="pb-2 nd-2" htmlFor="upload1">
                      Resort Profile
                    </label>
                  )}
                  {selectedOption === "HomeStay" && (
                    <label className="pb-2 nd-2" htmlFor="upload1">
                      Home Stay Profile
                    </label>
                  )}

                  <br />
                  <input
                    type="file"
                    className="title-90"
                    name="lodgeprofile"
                    id="upload1"
                    accept="image/*"
                    required
                    onChange={(e) => setLodgeprofile(e.target.files[0])}
                  />
                  <div className="row">
                    {selectedOption === "Hotel" && (
                      <label className="pb-2 nd-2">
                        Hotel CheckIn/CheckOut Timing
                      </label>
                    )}
                    {selectedOption === "Resort" && (
                      <label className="pb-2 nd-2">
                        Resort CheckIn/CheckOut Timing
                      </label>
                    )}
                    {selectedOption === "HomeStay" && (
                      <label className="pb-2 nd-2">
                        Home Stay CheckIn/CheckOut Timing
                      </label>
                    )}

                    <div className="col-md-6">
                      <input
                        type="Time"
                        className="title-90"
                        name="lodgetiming"
                        value={checkInTime}
                        required
                        onChange={(e) => setCheckInTime(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="Time"
                        className="title-90"
                        name="lodgetiming"
                        value={checkOutTime}
                        required
                        onChange={(e) => setcheckOutTime(e.target.value)}
                      />
                    </div>
                  </div>
                  {selectedOption === "Hotel" && (
                    <label className="pb-2 nd-2">Hotel Owner Name</label>
                  )}
                  {selectedOption === "Resort" && (
                    <label className="pb-2 nd-2">Resort Owner Name</label>
                  )}
                  {selectedOption === "HomeStay" && (
                    <label className="pb-2 nd-2">Home Stay Owner Name</label>
                  )}
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="lodgeownername"
                    value={Lodgeownername}
                    required
                    placeholder={`Please enter ${vendor?.vendorType} owner name`}
                    onChange={(e) => setLodgeownername(e.target.value)}
                  />
                  <label className="pb-2 nd-2">Starting Price</label>
                  <br />
                  <input
                    type="Number"
                    className="title-90"
                    name="lodgeownername"
                    value={startprice}
                    required
                    placeholder="Please enter starting price"
                    onChange={(e) => setstartprice(e.target.value)}
                  />

<div className="row">
                   
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                   CGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setcgst(e.target.value)}>
                     <option value={0}>Select</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="gst")?.map((item)=>{
                       return <option value={item?.Cgst}>{item?.Cgst}%</option>
                     })}
                   </select>
                  
                   </div>
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                   SGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setsgst(e.target.value)}>
                     <option value={0}>Select</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="gst")?.map((item)=>{
                       return <option value={item?.Sgst}>{item?.Sgst}%</option>
                     })}
                   </select>
                  
                   </div>
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                    IGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setigst(e.target.value)}>
                     <option value={0}>Select</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="Igst")?.map((item)=>{
                       return <option value={item?.Igst}>{item?.Igst}%</option>
                     })}
                   </select>
                    
                   </div>
                 </div>
                  <label className="pb-2 nd-2">Email</label>
                  <br />
                  <input
                    type="email"
                    className="title-90"
                    name="email"
                    value={Email}
                    required
                    placeholder="Please enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label className="pb-2 nd-2">Contact</label>
                  <br />
                  <input
                    type="number"
                    className="title-90"
                    name="contact"
                    value={Contact}
                    placeholder="Please enter your contact number"
                    required
                    onChange={(e) => setContact(e.target.value)}
                  />
                  <label className="pb-2 nd-2">Overview</label>
                  <br />
                  <CKEditor
                    editor={ClassicEditor}
                    data={Overview}
                    onChange={handleChange1}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                  <label className="pb-2 nd-2">Suggetion</label>
                  <br />
                  <CKEditor
                    editor={ClassicEditor}
                    data={suggetion}
                    onChange={handleChange3}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                  <label className="pb-2 nd-2">Terms&Condition</label>
                  <br />
                  <CKEditor
                    editor={ClassicEditor}
                    data={termCondition}
                    onChange={handleChange4}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                  <Button className="pb-2 nd-2 mt-3" onClick={handleShow1}>
                    Pricing
                  </Button>
                  <Button className="pb-2 nd-2 mt-3 mx-2" onClick={handleShow2}>
                    Includes
                  </Button>
                  <Button className="pb-2 nd-2 mt-3 mx-2" onClick={handleShow3}>
                    Excludes
                  </Button>
                  <Button className="pb-2 nd-2 mt-3 mx-2" onClick={handleShow5}>
                    Itinerary
                  </Button>
                  <br />

                  <Modal show={show1} onHide={handleClose1}>
                    <Modal.Body>
                      <from>
                        <label className="pb-2 nd-2">Package Name</label>
                        <select
                          name=""
                          id=""
                          className="title-90"
                          onChange={(e) => setPackagename(e.target.value)}
                        >
                          <option value="">----select----</option>
                          {AllCategory?.map((item) => {
                            return (
                              <option value={item?.category}>
                                {item?.category}
                              </option>
                            );
                          })}
                        </select>
                        <label className="pb-2 nd-2">Package Type</label>
                        <select
                          name=""
                          id=""
                          className="title-90"
                          onChange={(e) => setpackageType(e.target.value)}
                        >
                          <option value="">----select----</option>
                          <option value="Ac">Ac</option>
                          <option value="Non-Ac">Non-Ac</option>
                        </select>

                        <label className="pb-2 nd-2">
                          {" "}
                          Single Occupancy Week Price
                        </label>
                        <br />
                        <input
                          type="text"
                          className="title-90 mb-2"
                          name="sigaleweekprice"
                          placeholder="Please enter Single Occupancy Week Price"
                          value={Singleweekprice}
                          required=""
                          onChange={(e) => setSingleweekprice(e.target.value)}
                        />
                        <label className="pb-2 nd-2">
                          {" "}
                          Single Occupancy Weekend Price
                        </label>
                        <br />
                        <input
                          type="text"
                          className="title-90 mb-2"
                          name="singaleweekendprice"
                          value={Singleweekendprice}
                          required=""
                          placeholder="Please enter Single Occupancy Weekend Price"
                          onChange={(e) =>
                            setSingleweekendprice(e.target.value)
                          }
                        />
                        <label className="pb-2 nd-2">
                          {" "}
                          Double Occupancy Week Price
                        </label>
                        <br />
                        <input
                          type="text"
                          className="title-90 mb-2"
                          name="weekprice"
                          value={Doublyweekprice}
                          required=""
                          placeholder="Please enter Double Occupancy Week Price"
                          onChange={(e) => setDoublyweekprice(e.target.value)}
                        />
                        <label className="pb-2 nd-2">
                          {" "}
                          Double Occupancy Weekend Price
                        </label>
                        <br />
                        <input
                          type="text"
                          className="title-90 mb-2"
                          name="weekendprice"
                          value={Doublyweekendprice}
                          required=""
                          placeholder="Please enter Double Occupancy Weekend Price"
                          onChange={(e) =>
                            setDoublyweekendprice(e.target.value)
                          }
                        />
                      </from>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose1}>
                        Back
                      </Button>
                      <Button variant="primary" onClick={() => addPrice()}>
                        Add
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal show={show2} onHide={handleClose2}>
                    <Modal.Body>
                      <from>
                        <label className="pb-2 nd-2">Includes</label>
                        <br />
                        <input
                          type="text"
                          className="title-90"
                          name="include"
                          value={inclTest}
                          onChange={(e) => setinclText(e.target.value)}
                          required
                        />
                      </from>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose2}>
                        Back
                      </Button>
                      <Button variant="primary" onClick={() => addInclude()}>
                        Add
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal show={show3} onHide={handleClose3}>
                    <Modal.Body>
                      <from>
                        <label className="pb-2 nd-2">Excludes</label>
                        <br />
                        <input
                          type="text"
                          className="title-90"
                          name="excludes"
                          value={exText}
                          required
                          onChange={(e) => setexText(e.target.value)}
                        />
                      </from>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose3}>
                        Back
                      </Button>
                      <Button variant="primary" onClick={() => addExclude()}>
                        Add
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Back
              </Button>
              <Button variant="primary" onClick={(e) => onSubmit()}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show5} onHide={handleClose5}>
            <Modal.Body>
              <from>
                <label className="pb-2 nd-2">Time/text</label>
                <br />
                <input
                  type="text"
                  className="title-90 mb-2"
                  value={timeTxt}
                  placeholder="Eg:-12:pm noon "
                  onChange={(e) => settimeTxt(e.target.value)}
                />
                <label className="pb-2 nd-2"> Details</label>
                <br />
                <input
                  type="text"
                  className="title-90 mb-2"
                  name="weekprice"
                  value={deatails}
                  required=""
                  onChange={(e) => setDetails(e.target.value)}
                />
              </from>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose5}>
                Back
              </Button>
              <Button variant="primary" onClick={() => AddIntity()}>
                Add
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="searchHeadFlex" style={{ marginRight: "10px" }}>
              <div className="searchWrapper" style={{ display: "flex" }}>
                <input
                  type="text"
                  maxlength="220"
                  style={{ outlineWidth: "0px", padding: "4px" }}
                  className="textsearch"
                  placeholder="Search by lodge"
                  onChange={handleFilter}
                />
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  id="BsSearch"
                  height="1em"
                  width="0.8em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                    clip-rule="evenodd"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          {/* <div className="col-md-3">
        <div
            className="logde-op-00"
            style={{ cursor: "pointer" ,backgroundColor:selectedOption=="Hotel"? "red":"",textAlign:"center",width:"200px"}}
            onClick={() => {
             setSelectedOption("Hotel")
            }}
          >
            Hotel
          </div>
        </div>
        <div className="col-md-3">
        <div
            className="logde-op-00"
            style={{ cursor: "pointer",backgroundColor:selectedOption=="Resort"? "red":"",textAlign:"center",width:"200px" }}
            onClick={() => {
             setSelectedOption("Resort")
            }}
          >
            Resort
          </div>
        </div>
        <div className="col-md-3">
        <div
            className="logde-op-00"
            style={{ cursor: "pointer" ,backgroundColor:selectedOption=="HomeStay"? "red":"",textAlign:"center",width:"200px"}}
            onClick={() => {
             setSelectedOption("HomeStay")
            }}
          >
            Home Stay
          </div>
        </div> */}
        </div>
        <div className="scrolll_bar">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>{selectedOption} Name</th>
                <th>Location</th>
                <th>Total Room</th>
                <th>{selectedOption} Profile</th>
                <th>{selectedOption} CheckIn Time</th>
                <th>{selectedOption} CheckOut Time</th>
                <th>{selectedOption} Owner Name</th>
                <th>Starting Price ₹</th>
                <th>Email</th>
                <th>Contact</th>

                <th>Status</th>
                <th></th>
                <th>Action</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {search?.length > 0 ? (
                tableFilter

                  ?.slice(pagesVisited, pagesVisited + usersPerPage)
                  ?.map((ele, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{ele?.hotalName}</td>
                        <td>
                          <p
                            style={{
                              overflow: "hidden",
                              overflowY: "scroll",
                              height: "100px",
                              width: "200px",
                            }}
                          >
                            {ele?.location}
                          </p>
                        </td>
                        <td>{ele?.totalRoom}</td>
                        <td>
                          <img
                            src={`https://dhanyatours.me/Hotel/${ele?.HotelProfile}`}
                            alt="Hotel-image"
                            style={{ width: "100%" }}
                          />
                        </td>
                        <td>{ele?.CheckInTime}</td>
                        <td>{ele?.CheckOutTime}</td>
                        <td>{ele.HotelOwnerName}</td>
                        <td>{ele.StartingPrice}</td>
                        <td>{ele.email}</td>
                        <td>{ele.contact}</td>

                        <td>{ele.status}</td>
                        <td style={{ display: "flex", gap: "5px" }}>
                          <div
                            onClick={() => {
                              handleShow4(ele);
                              setedit(ele);
                              getbyLodgeId(ele._id);
                            }}
                          >
                            <FaEdit
                              style={{
                                color: "#5600ff",
                                cursor: "pointer",
                                fontSize: "26px",
                              }}
                            />
                          </div>
                          <div>
                            <button
                              type="button"
                              class="btn btn-success"
                              onClick={() => {
                                setImagesData(ele);
                                hsndleImages();
                              }}
                            >
                              {" "}
                              Gallery{" "}
                            </button>
                          </div>
                          <div>
                            <button
                              type="button"
                              class="btn btn-info"
                              onClick={() => {
                                setImagesData(ele);
                                setHandlePrice(true);
                              }}
                              style={{ color: "white" }}
                            >
                              Pracing
                            </button>
                          </div>
                        </td>

                        <td>
                          {ele?.status === "Approved" ? (
                            <>
                              {ele?.isBloc === false ? (
                                <button
                                  type="button"
                                  onClick={() => MakeBlock(ele?._id, true)}
                                  class="btn btn-danger"
                                >
                                  Block
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  class="btn btn-warning"
                                  onClick={() => MakeBlock(ele?._id, false)}
                                >
                                  Unblock
                                </button>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          <div
                            className=""
                            onClick={() => {
                              handleShow6();
                              setDeleteId(ele?._id);
                            }}
                          >
                            <AiFillDelete
                              style={{
                                color: "red",
                                cursor: "pointer",
                                fontSize: "26px",
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <>
                  {lodgess
                    .filter((ele) => ele?.category === selectedOption)
                    .slice(pagesVisited, pagesVisited + usersPerPage)
                    ?.map((ele, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{ele?.hotalName}</td>
                          <td>
                            <p
                              style={{
                                overflow: "hidden",
                                overflowY: "scroll",
                                height: "100px",
                                width: "200px",
                              }}
                            >
                              {ele?.location}
                            </p>
                          </td>
                          <td>{ele?.totalRoom}</td>
                          <td>
                            <img
                              src={`https://dhanyatours.me/Hotel/${ele?.HotelProfile}`}
                              alt="Hotel-image"
                              style={{ width: "100%" }}
                            />
                          </td>
                          <td>{ele?.CheckInTime}</td>
                          <td>{ele?.CheckOutTime}</td>
                          <td>{ele.HotelOwnerName}</td>
                          <td>{ele.StartingPrice}</td>
                          <td>{ele.email}</td>
                          <td>{ele.contact}</td>

                          <td>{ele.status}</td>
                          <td style={{ display: "flex", gap: "5px" }}>
                            <div
                              onClick={() => {
                                handleShow4(ele);
                                setedit(ele);
                                getbyLodgeId(ele._id);
                              }}
                            >
                              <FaEdit
                                style={{
                                  color: "#5600ff",
                                  cursor: "pointer",
                                  fontSize: "26px",
                                }}
                              />
                            </div>
                            <div>
                              <button
                                type="button"
                                class="btn btn-success"
                                onClick={() => {
                                  setImagesData(ele);
                                  hsndleImages();
                                }}
                              >
                                {" "}
                                Gallery{" "}
                              </button>
                            </div>
                            <div>
                              <button
                                type="button"
                                class="btn btn-info"
                                onClick={() => {
                                  setImagesData(ele);
                                  setHandlePrice(true);
                                }}
                                style={{ color: "white" }}
                              >
                                Pracing
                              </button>
                            </div>
                          </td>

                          <td>
                            {ele?.status === "Approved" ? (
                              <>
                                {ele?.isBloc === false ? (
                                  <button
                                    type="button"
                                    onClick={() => MakeBlock(ele?._id, true)}
                                    class="btn btn-danger"
                                  >
                                    Block
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn btn-warning"
                                    onClick={() => MakeBlock(ele?._id, false)}
                                  >
                                    Unblock
                                  </button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td>
                            <div
                              className=""
                              onClick={() => {
                                handleShow6();
                                setDeleteId(ele?._id);
                              }}
                            >
                              <AiFillDelete
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  fontSize: "26px",
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </Table>
        </div>

        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
      {/* delete model */}
      <Modal show={show6} onHide={handleClose6}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose6}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onDelete}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edite */}
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Update {selectedOption} Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <form>
            <label className="pb-2 nd-2">City</label>
            <br />
                  <select
                       className="title-90"
                    onChange={(e) => setcity(e.target.value)}
                  >
                    <option value={city}>{city}</option>
                    {AllCity?.map((item)=>{
                      return ( <option value={`${item?.City},${item?.State}`}>{`${item?.City},${item?.State}`}</option>)
                    })}
                  </select>
              <label className="pb-2 nd-2">{vendor?.vendorType} Name</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="lodgename"
                value={lodgename}
                placeholder={edit.hotalName}
                required
                onChange={(e) => setlodgename(e.target.value)}
              />

              <label className="pb-2 nd-2">Location</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="location"
                value={Location}
                placeholder={edit.location}
                required
                onChange={(e) => setLocation(e.target.value)}
              />
              <label className="pb-2 nd-2">Total Rooms</label>
              <br />
              <input
                type="number"
                className="title-90"
                name="location"
                value={totalRoom}
                required
                onChange={(e) => settotalRoom(e.target.value)}
              />
              <label className="pb-2 nd-2" htmlFor="upload2">
              {vendor?.vendorType} Profile
              </label>
              <br />
              <input
                type="file"
                className="title-90"
                name="lodgeprofile"
                id="upload2"
                accept="image/*"
                required
                onChange={(e) => setLodgeprofile(e.target.files[0])}
              />
              <div className="row">
                <label className="pb-2 nd-2">
                {vendor?.vendorType} CheckIn/CheckOut Timing
                </label>
                <div className="col-md-6">
                  <input
                    type="Time"
                    className="title-90"
                    name="lodgetiming"
                    value={checkInTime}
                    required
                    placeholder={edit.CheckInTime}
                    onChange={(e) => setCheckInTime(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="Time"
                    className="title-90"
                    name="lodgetiming"
                    value={checkOutTime}
                    required
                    placeholder={edit.CheckOutTime}
                    onChange={(e) => setcheckOutTime(e.target.value)}
                  />
                </div>
              </div>
              <label className="pb-2 nd-2">{vendor?.vendorType} Owner Name</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="lodgeownername"
                value={Lodgeownername}
                placeholder={edit.HotelOwnerName}
                required
                onChange={(e) => setLodgeownername(e.target.value)}
              />
              <label className="pb-2 nd-2">Starting Price</label>
              <br />
              <input
                type="Number"
                className="title-90"
                name="lodgeownername"
                value={startprice}
                placeholder={edit.StartingPrice}
                required
                onChange={(e) => setstartprice(e.target.value)}
              />
               <div className="row">
                   
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                   CGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setcgst(e.target.value)}>
                       <option value={cgst}>{cgst}%</option>
                <option value={0}>{0}%</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="gst")?.map((item)=>{
                       return <option value={item?.Cgst}>{item?.Cgst}%</option>
                     })}
                   </select>
                  
                   </div>
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                   SGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setsgst(e.target.value)}>
                     <option value={sgst}>{sgst}%</option>
                <option value={0}>{0}%</option>

                     {AllGst?.filter((ele)=>ele?.gstType=="gst")?.map((item)=>{
                       return <option value={item?.Sgst}>{item?.Sgst}%</option>
                     })}
                   </select>
                  
                   </div>
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                    IGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setigst(e.target.value)}>
                        <option value={igst}>{igst}%</option>
                <option value={0}>{0}%</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="Igst")?.map((item)=>{
                       return <option value={item?.Igst}>{item?.Igst}%</option>
                     })}
                   </select>
                    
                   </div>
                 </div>
              <label className="pb-2 nd-2">Email</label>
              <br />
              <input
                type="email"
                className="title-90"
                name="email"
                value={Email}
                placeholder={edit.email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <label className="pb-2 nd-2">Contact</label>
              <br />
              <input
                type="number"
                className="title-90"
                name="contact"
                value={Contact}
                placeholder={edit.contact}
                required
                onChange={(e) => setContact(e.target.value)}
              />
              <br />

              <div className="row">
                <div className="col-6">
                  <label className="pb-2 nd-2">Includes</label>
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    style={{
                      float: "right",
                      margin: "2px",
                      padding: "1px 7px 0px",
                    }}
                    class="btn btn-outline-info"
                    onClick={addIncludeA}
                  >
                    Add
                  </button>
                </div>
                <input
                  type="text"
                  className="title-90"
                  name="include"
                  placeholder="Write your includes one by one."
                  value={inclTest}
                  onChange={(e) => setinclText(e.target.value)}
                  required
                />
                <ol style={{ marginLeft: "15px" }}>
                  {edit?.Includes?.map((item) => {
                    return (
                      <li>
                        {item?.text}{" "}
                        <span>
                          {" "}
                          <CiCircleRemove
                            style={{
                              color: "red",
                              cursor: "pointer",
                              fontSize: "20px",
                            }}
                            onClick={() => deleteIncludes(item?._id)}
                          />
                        </span>
                      </li>
                    );
                  })}{" "}
                </ol>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="pb-2 nd-2">Excludes</label>
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    style={{
                      float: "right",
                      margin: "2px",
                      padding: "1px 7px 0px",
                    }}
                    class="btn btn-outline-info"
                    onClick={addExcludesA}
                  >
                    Add
                  </button>
                </div>
                <input
                  type="text"
                  className="title-90"
                  name="include"
                  placeholder="Write your excludes one by one..."
                  value={exText}
                  required
                  onChange={(e) => setexText(e.target.value)}
                />
                <ol style={{ marginLeft: "15px" }}>
                  {edit?.Excludes?.map((item) => {
                    return (
                      <li>
                        {item?.text1}{" "}
                        <span>
                          {" "}
                          <CiCircleRemove
                            style={{
                              color: "red",
                              cursor: "pointer",
                              fontSize: "20px",
                            }}
                            onClick={() => deleteExcludes(item?._id)}
                          />
                        </span>
                      </li>
                    );
                  })}{" "}
                </ol>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="pb-2 nd-2">Itinerary</label>
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    style={{
                      float: "right",
                      margin: "2px",
                      padding: "1px 7px 0px",
                    }}
                    class="btn btn-outline-info"
                    onClick={addItineraryA}
                  >
                    Add
                  </button>
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    className="title-90"
                    name="include"
                    placeholder="Eg: 12:00 pm Noon"
                    value={timeTxt}
                    required
                    onChange={(e) => settimeTxt(e.target.value)}
                  />
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    className="title-90"
                    name="include"
                    placeholder="Write some deatails"
                    value={deatails}
                    required=""
                    onChange={(e) => setDetails(e.target.value)}
                  />
                </div>

                <ol style={{ marginLeft: "15px" }}>
                  {edit?.ITINERARY?.map((item) => {
                    return (
                      <li>
                        <span style={{ fontWeight: "bold" }}>{item?.time}</span>
                        {" : "}
                        {item?.details}{" "}
                        <span>
                          {" "}
                          <CiCircleRemove
                            style={{
                              color: "red",
                              cursor: "pointer",
                              fontSize: "20px",
                            }}
                            onClick={() => deleteItinerary(item?._id)}
                          />
                        </span>
                      </li>
                    );
                  })}{" "}
                </ol>
              </div>
              <label className="pb-2 nd-2">Overview</label>
              <br />
              <CKEditor
                editor={ClassicEditor}
                data={Overview}
                onChange={handleChange1}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
              <label className="pb-2 nd-2">Suggetion</label>
              <br />
              <CKEditor
                editor={ClassicEditor}
                data={suggetion}
                onChange={handleChange3}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
              <label className="pb-2 nd-2">Terms&Condition</label>
              <br />
              <CKEditor
                editor={ClassicEditor}
                data={termCondition}
                onChange={handleChange4}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose4()}>
            Back
          </Button>
          <Button variant="primary" onClick={(e) => onEdit()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Images Model */}
      <Modal show={showImages} onHide={closeImages} size="lg">
        <Modal.Header closeButton>
          <h4
            style={{
              color: "red",
              fontWeight: "bold",
              position: "absolute",
              left: "40%",
            }}
          >
            {vendor?.vendorType} Gallery{" "}
          </h4>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="row">
            <div className="col-md-6">
              <input
                type="file"
                className="title-90"
                name="lodgename"
                accept="image/*"
                required=""
                onChange={(e) => setimage3(e.target.files[0])}
              />
            </div>
            <div className="col-md-6">
              <button
                type="button"
                class="btn btn-dark"
                onClick={AddSingleImagesHotel}
              >
                Add Now
              </button>
            </div>
          </div>
          <p>Note:- Minimum 4 images and maximum 10 images.</p>
          <hr></hr>
          <div className="row">
            {imagesData?.HotelImages?.map((item) => {
              return (
                <div
                  className="col-md-4"
                  style={{ display: "flex", gap: "3px", marginBottom: "10px" }}
                >
                  <div>
                    <img
                      src={"https://dhanyatours.me/Hotel/" + item?.image}
                      style={{ width: "200px", height: "210px" }}
                    />{" "}
                  </div>

                  <div>
                    <AiFillDelete
                      style={{
                        fontSize: "25px",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() => deleteHotelImages(item?._id)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeImages}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Package Add Model */}
      <Modal show={AddpM} onHide={() => setAddpM(false)}>
        <Modal.Header closeButton>
          <h4
            style={{
              color: "red",
              fontWeight: "bold",
              position: "absolute",
              left: "40%",
            }}
          >
            Add Pricing{" "}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <from>
            <label className="pb-2 nd-2">Package Name</label>
            <select
              name=""
              id=""
              className="title-90"
              onChange={(e) => setPackagename(e.target.value)}
            >
              <option value="">----select----</option>
              {AllCategory?.map((item) => {
                return <option value={item?.category}>{item?.category}</option>;
              })}
            </select>
            <label className="pb-2 nd-2">Package Type</label>
            <select
              name=""
              id=""
              className="title-90"
              onChange={(e) => setpackageType(e.target.value)}
            >
              <option value="">----select----</option>
              <option value="Ac">Ac</option>
              <option value="Non-Ac">Non-Ac</option>
            </select>

            <label className="pb-2 nd-2"> Single Occupancy Week Price</label>
            <br />
            <input
              type="text"
              className="title-90 mb-2"
              name="sigaleweekprice"
              placeholder="Please enter Single Occupancy Week Price"
              value={Singleweekprice}
              required=""
              onChange={(e) => setSingleweekprice(e.target.value)}
            />
            <label className="pb-2 nd-2"> Single Occupancy Weekend Price</label>
            <br />
            <input
              type="text"
              className="title-90 mb-2"
              name="singaleweekendprice"
              value={Singleweekendprice}
              required=""
              placeholder="Please enter Single Occupancy Weekend Price"
              onChange={(e) => setSingleweekendprice(e.target.value)}
            />
            <label className="pb-2 nd-2"> Double Occupancy Week Price</label>
            <br />
            <input
              type="text"
              className="title-90 mb-2"
              name="weekprice"
              value={Doublyweekprice}
              required=""
              placeholder="Please enter Double Occupancy Week Price"
              onChange={(e) => setDoublyweekprice(e.target.value)}
            />
            <label className="pb-2 nd-2"> Double Occupancy Weekend Price</label>
            <br />
            <input
              type="text"
              className="title-90 mb-2"
              name="weekendprice"
              value={Doublyweekendprice}
              required=""
              placeholder="Please enter Double Occupancy Weekend Price"
              onChange={(e) => setDoublyweekendprice(e.target.value)}
            />
          </from>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setAddpM(false)}>
            Back
          </Button>
          <Button variant="primary" onClick={() => addPricing()}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Price view model Model */}
      <Modal show={HandlePrice} onHide={() => setHandlePrice(false)} size="lg">
        <Modal.Header closeButton>
          <h4
            style={{
              color: "red",
              fontWeight: "bold",
              position: "absolute",
              left: "40%",
            }}
          >
            Pracing{" "}
          </h4>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="row">
            <div className="col-md-12">
              <button
                type="button"
                class="btn btn-dark"
                onClick={() => {
                  setPackagename("");
                  setDoublyweekprice("");
                  setDoublyweekendprice("");
                  setSingleweekprice("");
                  setSingleweekendprice("");
                  setpackageType("");
                  setAddpM(true);
                }}
                style={{ float: "right" }}
              >
                Add Now
              </button>
            </div>
          </div>
          <p style={{ float: "right" }}>Note:- Add Pacing One By One.</p>
          <br />
          <hr></hr>
          <hr></hr>
          <div>
            {imagesData?.PRICING?.map((item) => {
              return (
                <div className="row" style={{ marginBottom: "10px" }}>
                  <div className="col-md-9">
                    <label className="pb-2 nd-2">Package Name: </label>{" "}
                    <span>{item?.packageName}</span>{" "}<span>{item?.packageType}</span>
                  </div>
                  <div className="col-md-3">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <FaEdit
                        style={{
                          color: "#5600ff",
                          cursor: "pointer",
                          fontSize: "29px",
                        }}
                        onClick={() => {
                          setPackagename(item?.packageName);
                          setDoublyweekprice(item?.doublyWeekPrice);
                          setDoublyweekendprice(item?.doublyWeekendPrice);
                          setSingleweekprice(item?.singleWeekPrice);
                          setpackageType(item?.packageType);
                          setSingleweekendprice(item?.singleWeekendPrice);
                          setdeletePricId(item?._id);

                          setupdateM(true);
                        }}
                      />{" "}
                      <AiFillDelete
                        style={{
                          fontSize: "28px",
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setdeletePricId(item?._id);
                          setPackDeletM(true);
                        }}
                      />{" "}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="pb-2 nd-2">
                      {" "}
                      Single Occupancy Week Price:{" "}
                    </label>{" "}
                    <span>₹ {item?.singleWeekPrice?.toFixed(2)}</span>
                  </div>
                  <div className="col-md-6">
                    <label className="pb-2 nd-2">
                      {" "}
                      Single Occupancy Weekend Price:
                    </label>{" "}
                    <span>₹ {item?.singleWeekendPrice?.toFixed(2)}</span>
                  </div>
                  <div className="col-md-6">
                    <label className="pb-2 nd-2">
                      {" "}
                      Double Occupancy Week Price:
                    </label>{" "}
                    <span>₹ {item?.doublyWeekPrice?.toFixed(2)}</span>
                  </div>
                  <div className="col-md-6">
                    <label className="pb-2 nd-2">
                      {" "}
                      Double Occupancy Weekend Price:
                    </label>{" "}
                    <span>₹ {item?.doublyWeekendPrice?.toFixed(2)}</span>
                  </div>
                  <hr></hr>
                  <hr></hr>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setHandlePrice(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete model */}
      <Modal show={packDeleM} onHide={() => setPackDeletM(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure package delete?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setPackDeletM(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deletePricing}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Package Add Model */}
      <Modal show={updateM} onHide={() => setupdateM(false)}>
        <Modal.Header closeButton>
          <h4
            style={{
              color: "red",
              fontWeight: "bold",
              position: "absolute",
              left: "40%",
            }}
          >
            Update Pricing{" "}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <from>
          <label className="pb-2 nd-2">Package Name</label>
                        <select
                          name=""
                          id=""
                          className="title-90"
                          onChange={(e) => setPackagename(e.target.value)}
                        >
                          <option value="">{Packagename}</option>
                          {AllCategory?.map((item) => {
                            return (
                              <option value={item?.category}>
                                {item?.category}
                              </option>
                            );
                          })}
                        </select>
                        <label className="pb-2 nd-2">Package Type</label>
                        <select
                          name=""
                          id=""
                          className="title-90"
                          onChange={(e) => setpackageType(e.target.value)}
                        >
                          <option value="">{packageType}</option>
                          <option value="Ac">Ac</option>
                          <option value="Non-Ac">Non-Ac</option>
                        </select>

            <label className="pb-2 nd-2"> Single Occupancy Week Price</label>
            <br />
            <input
              type="number"
              className="title-90 mb-2"
              name="sigaleweekprice"
              placeholder="Please enter Single Occupancy Week Price"
              value={Singleweekprice}
              required=""
              onChange={(e) => setSingleweekprice(e.target.value)}
            />
            <label className="pb-2 nd-2"> Single Occupancy Weekend Price</label>
            <br />
            <input
              type="number"
              className="title-90 mb-2"
              name="singaleweekendprice"
              value={Singleweekendprice}
              required=""
              placeholder="Please enter Single Occupancy Weekend Price"
              onChange={(e) => setSingleweekendprice(e.target.value)}
            />
            <label className="pb-2 nd-2"> Double Occupancy Week Price</label>
            <br />
            <input
              type="number"
              className="title-90 mb-2"
              name="weekprice"
              value={Doublyweekprice}
              required=""
              placeholder="Please enter Double Occupancy Week Price"
              onChange={(e) => setDoublyweekprice(e.target.value)}
            />
            <label className="pb-2 nd-2"> Double Occupancy Weekend Price</label>
            <br />
            <input
              type="number"
              className="title-90 mb-2"
              name="weekendprice"
              value={Doublyweekendprice}
              required=""
              placeholder="Please enter Double Occupancy Weekend Price"
              onChange={(e) => setDoublyweekendprice(e.target.value)}
            />
          </from>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setupdateM(false)}>
            Back
          </Button>
          <Button variant="primary" onClick={() => updatePricing()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default HotelSub;
