import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";

function LuxuryTrainImage() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [imges1, setimges1] = useState([]);
  // console.log(imges1,"success")
  const getLuxury = async () => {
    const config = {
      url: "/getAllImage",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/jason" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setimges1(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getLuxury();
  }, []);

  const [img1, setimg1] = useState("");
  const [name, setname] = useState("");
  const onLuxuryadd = async () => {
    // e.preventDefault();
    try {
      const config = {
        url: "/addImage",
        method: "post",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          image: img1,
          name: "LuxuryTrain",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Added");
        // lodges();
        window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const onLuxuryaDelete = async (id) => {
    // e.preventDefault();
    try {
      const config = {
        url: "/deleteImage/" + id,
        method: "delete",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Delete");
        // lodges();
        window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  return (
    <div className="ms-5 mt-3">
      <div
        className="mt-2 mb-3"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="logde-op">
          <h3> Luxury Train Graphic</h3>
        </div>

        <div
          className="logde-op-00"
          style={{ cursor: "pointer" }}
          onClick={handleShow}
        >
          + Luxury Train Graphic
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <form>
                <label className="pb-2 nd-2" htmlFor="uploade1">
                  Image 2
                </label>
                <br />
                <input
                  type="file"
                  className="title-90"
                  name="category"
                  id="uploade1"
                  accept="image/*"
                  required
                  onChange={(e) => setimg1(e.target.files[0])}
                />
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Back
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onLuxuryadd();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div className="scrolll_bar">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Image</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {imges1
              ?.filter((element) => element?.name === "LuxuryTrain")
              .map((ele, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <img
                        src={`https://dhanyatours.me/image/${ele.image}`}
                        alt="images"
                        style={{ width: "250px", height: "150px" }}
                      />
                    </td>
                    <td>
                      <div className="">
                        <AiFillDelete
                          style={{ color: "red", fontSize: "25px" }}
                          onClick={() => {
                            onLuxuryaDelete(ele._id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default LuxuryTrainImage;
