import React, { useState } from "react";
import {
  Button,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import Select from "react-select";
import { FaRegCircleUser } from "react-icons/fa6";
import { BsSearch } from "react-icons/bs";
import { MdOutlineSevereCold } from "react-icons/md";
import { GiSteeringWheel, GiThermometerCold } from "react-icons/gi";
import { PiArmchairFill } from "react-icons/pi";
import "./busbooking.css";
import Pagination from "react-bootstrap/Pagination";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { FaBed } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaBusAlt } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";

export const Bus = () => {
  const { state } = useLocation();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [policies, setpolicies] = useState(false);
  const [photos, setphotos] = useState(false);
  const [bordingpoints, setbordingpoints] = useState(false);
  const [Amenities, setAmenities] = useState(false);
  const [reviews, setreviews] = useState(false);
  const [reststops, setreststops] = useState(false);
  const [busroutes, setbusroutes] = useState(false);
  const onPress1 = () => {
    setpolicies(!policies);
    setphotos(false);
    setbordingpoints(false);
    setAmenities(false);
    setreviews(false);
    setreststops(false);
    setbusroutes(false);
  };
  const onPress2 = () => {
    setpolicies(false);
    setphotos(!photos);
    setbordingpoints(false);
    setAmenities(false);
    setreviews(false);
    setreststops(false);
    setbusroutes(false);
  };
  const onPress3 = () => {
    setpolicies(false);
    setphotos(false);
    setbordingpoints(!bordingpoints);
    setAmenities(false);
    setreviews(false);
    setreststops(false);
    setbusroutes(false);
  };
  const onPress4 = () => {
    setpolicies(false);
    setphotos(false);
    setbordingpoints(false);
    setAmenities(!Amenities);
    setreviews(false);
    setreststops(false);
    setbusroutes(false);
  };
  const onPress5 = () => {
    setpolicies(false);
    setphotos(false);
    setbordingpoints(false);
    setAmenities(false);
    setreviews(!reviews);
    setreststops(false);
    setbusroutes(false);
  };
  const onPress6 = () => {
    setpolicies(false);
    setphotos(false);
    setbordingpoints(false);
    setAmenities(false);
    setreviews(false);
    setreststops(!reststops);
    setbusroutes(false);
  };
  const onPress7 = () => {
    setpolicies(false);
    setphotos(false);
    setbordingpoints(false);
    setAmenities(false);
    setreviews(false);
    setreststops(false);
    setbusroutes(!busroutes);
  };
  const [selectdesti, setselectdesti] = useState(state?.destination);
  const [selectsource, setselectsource] = useState(state?.source);
  const [startdate, setstartdate] = useState(state?.startdate);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  const options1 = [
    { value: "Delhi", label: "Delhi" },
    { value: "Mumbai", label: "Mumbai" },
    { value: "Kolar", label: "Kolar" },
    { value: "Chintamani", label: "Chintamani" },
    { value: "Chennai", label: "Chennai" },
    { value: "vizag", label: "vizag" },
    { value: "Delhi", label: "Delhi" },
    { value: "Mumbai", label: "Mumbai" },
    { value: "Kolar", label: "Kolar" },
    { value: "Chintamani", label: "Chintamani" },
    { value: "Chennai", label: "Chennai" },
    { value: "vizag", label: "vizag" },
  ];
  const bus=[
        {
          "bus_id": "BUS001",
          "route": "New York to Boston",
          "departure_time": "2024-05-15T08:00:00",
          "arrival_time": "2024-05-15T11:00:00",
          "available_seats": 30,
          "ticket_price": 2599
        },
        {
          "bus_id": "BUS002",
          "route": "Los Angeles to San Francisco",
          "departure_time": "2024-05-15T09:30:00",
          "arrival_time": "2024-05-15T12:30:00",
          "available_seats": 25,
          "ticket_price": 3050
        },
        {
          "bus_id": "BUS003",
          "route": "Chicago to Detroit",
          "departure_time": "2024-05-15T10:45:00",
          "arrival_time": "2024-05-15T13:45:00",
          "available_seats": 20,
          "ticket_price": 2075
        },
        {
          "bus_id": "BUS003",
          "route": "Chicago to Detroit",
          "departure_time": "2024-05-15T10:45:00",
          "arrival_time": "2024-05-15T13:45:00",
          "available_seats": 20,
          "ticket_price": 2075
        },
        {
          "bus_id": "BUS003",
          "route": "Chicago to Detroit",
          "departure_time": "2024-05-15T10:45:00",
          "arrival_time": "2024-05-15T13:45:00",
          "available_seats": 20,
          "ticket_price": 2075
        },
        {
          "bus_id": "BUS003",
          "route": "Chicago to Detroit",
          "departure_time": "2024-05-15T10:45:00",
          "arrival_time": "2024-05-15T13:45:00",
          "available_seats": 20,
          "ticket_price": 2075
        },
        {
          "bus_id": "BUS003",
          "route": "Chicago to Detroit",
          "departure_time": "2024-05-15T10:45:00",
          "arrival_time": "2024-05-15T13:45:00",
          "available_seats": 20,
          "ticket_price": 2075
        },
        {
          "bus_id": "BUS003",
          "route": "Chicago to Detroit",
          "departure_time": "2024-05-15T10:45:00",
          "arrival_time": "2024-05-15T13:45:00",
          "available_seats": 20,
          "ticket_price": 2075
        },
        {
          "bus_id": "BUS003",
          "route": "Chicago to Detroit",
          "departure_time": "2024-05-15T10:45:00",
          "arrival_time": "2024-05-15T13:45:00",
          "available_seats": 20,
          "ticket_price": 2075
        },
        {
          "bus_id": "BUS003",
          "route": "Chicago to Detroit",
          "departure_time": "2024-05-15T10:45:00",
          "arrival_time": "2024-05-15T13:45:00",
          "available_seats": 20,
          "ticket_price": 2075
        },
        {
          "bus_id": "BUS003",
          "route": "Chicago to Detroit",
          "departure_time": "2024-05-15T10:45:00",
          "arrival_time": "2024-05-15T13:45:00",
          "available_seats": 20,
          "ticket_price": 2075
        }

  ]
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <div>
      <div style={{ backgroundColor: "#041422" }} className="gradient-box">
        <div className="bus-booking">
          <div className="sadfjsdjfdgfgh">
         
            <div style={{ margin: "10px" }}>
             <div className="arunbega" style={{ width: "250px" }}>
                <span className="arunbega1">Source</span>
                <Select
                  id="selectsource"
                  onChange={(value) => setselectsource(value)}
                  placeholder={selectsource ? selectsource : "Select Source"}
                  options={options1}
                />
              </div>
            </div>

            <div style={{ margin: "10px" }}>
              <div className="arunbega" style={{ width: "250px" }}>
                <span className="arunbega1">Destination</span>
                <Select
                  id="selectdesti"
                  onChange={(value) => setselectdesti(value)}
                  placeholder={selectdesti ? selectdesti : "Select Destination"}
                  options={options1}
                />
              </div>
            </div>

            <div style={{ margin: "10px" }}>
              <div className="arunbega" style={{ width: "250px" }}>
                <span className="arunbega1">Date</span>
                <Form.Control
                  type="date"
                  value={startdate}
                  min={getCurrentDate()}
                  onChange={(e) => setstartdate(e.target.value)}
                />
              </div>
            </div>
           
            <div style={{ margin: "10px" }}>
              <div
                className="arunbega"
                style={{ width: "150px", borderRadius: 10, marginTop: 25 }}
              >
                <p
                  style={{
                    backgroundColor: "#004aad",
                    color: "white",
                    textAlign: "center",
                    padding: 3,
                  }}
                >
                  Search
                </p>
              </div>
            </div>
            <img
                          class="section-intro-img"
                          src="../buses.gif"
                          alt=""
                          style={{
                            width: "100px",
                            height: "65px",
                           
                          }}
                        />
          </div>
        </div>
      </div>
      <div className=" all-fliter-head gradient-box">
        <h2 className="m-auto fw-bold text-light " style={{ color: "black" }}>
          Showing Buses Here
        </h2>
      </div>

      <Container>
        <Row>
          <div className="col-lg-3 gradient-box2">
            <div className="demo-contain">
              <div className="mb-5">
                <h3 className="fw-bold text-dark mb-3">Select Filter</h3>
                <h6 className="text-dark fw-bold">AC</h6>
                <div className="d-flex  mb-2 gap-2">
                  <p className="cold">
                    <MdOutlineSevereCold className="me-2" /> AC
                  </p>
                  <p className="cold">
                    <GiThermometerCold className="me-2" />
                    Non AC
                  </p>
                </div>
              </div>
              <div className="mb-5">
                <h6 className="text-dark fw-bold">Seat Type</h6>
                <div className="d-flex  mb-2 gap-2">
                  <p className="cold">
                    <FaBed className="me-2" />
                    Sleeper
                  </p>
                  <p className="cold">
                    <PiArmchairFill className="me-2" />
                    Seater
                  </p>
                </div>
              </div>

              <div className="mb-5">
                <h6 className="text-dark fw-bold">One Way Price</h6>
                <Box sx={{ width: 260 }}>
                  <Slider
                    defaultValue={50}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  />
                </Box>
                <div className="d-flex justify-content-between">
                  <span>₹5,144</span>
                  <span>₹13,600</span>
                </div>
              </div>

              <div className="mb-5">
                <h6 className="text-dark fw-bold">Travel Operators</h6>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      VRL Travels
                    </label>
                  </div>
                  <label className="form-check-label" for="flexCheckDefault">
                    (10)
                  </label>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      SRS Travels
                    </label>
                  </div>
                  <label className="form-check-label" for="flexCheckDefault">
                    (13)
                  </label>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Sugama Tourist
                    </label>
                  </div>
                  <label className="form-check-label" for="flexCheckDefault">
                    (5)
                  </label>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Sea Bird Travels
                    </label>
                  </div>
                  <label className="form-check-label" for="flexCheckDefault">
                    (12)
                  </label>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Reshma Travels
                    </label>
                  </div>
                  <label className="form-check-label" for="flexCheckDefault">
                    (6)
                  </label>
                </div>
              </div>
              <div className="mb-5">
                <h6 className="text-dark fw-bold">Sort By</h6>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Relevance
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Price-Low to high
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Best Rated First
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Early Departure
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Late Departure
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <h6 className="text-dark fw-bold">Boarding Points</h6>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Aanad Rao Circle
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Attibele
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Aanad Rao Circle
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Attibele
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Reshma Travels
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <h6 className="text-dark fw-bold">Dropping Points</h6>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Aanad Rao Circle
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Attibele
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Aanad Rao Circle
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Attibele
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Reshma Travels
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <h6 className="text-dark fw-bold">Amenities</h6>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Wifi
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Track My Bus
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Toilet
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Water Bottles
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Blankets
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <h6 className="text-dark fw-bold">Bus Features</h6>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Live Tracking
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      High Rated Buses
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input
                      className="form-check-input me-2 filter-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label filter-check-label"
                      for="flexCheckDefault"
                    >
                      Primo
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-9">
            <h3 className="fw-bold text-dark mb-3 text-center">
              25 Buses Found
            </h3>
            <div className="ml-7">
              {bus?.map((item)=>{
                return(
                  <div className=" flight_0 gradient-box1">
                  <div className="gjkh ">
                    <div>
                      <div className="d-flex align-items-center gap-2 ">
                        <div>
                          <p className="fs-5 fw-bold">05:40</p>
                        </div>
  
                        <div className="text-center">--------------------</div>
  
                        <div>
                          <p className="fs-5 fw-bold">10:30</p>
                        </div>
                      </div>
                      <div>
                        {" "}
                        <p style={{ fontSize: 15, textAlign: "center" }}>
                          4hr 50min 9 seats
                        </p>
                      </div>
                    </div>
  
                    <div className="d-flex align-items-center gap-4">
                      <div style={{ color: "#393939" }}>
                        <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                          From ₹ {item?.ticket_price}
                        </p>
                        <p
                          style={{
                            textDecorationLine: "line-through",
                            fontSize: 15,
                            marginLeft: 65,
                          }}
                        >
                          {"      "} ₹ 6236
                        </p>
                      </div>
                      <div>
                        <p
                          className="hvr-shutter-in-vertical flight-book"
                          onClick={handleShow}
                        >
                          Book
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="gjgh"
                    style={{ justifyContent: "space-around" }}
                  >
                    <div>
                      <p
                        style={{
                          fontSize: 15,
                          color: "#004aad",
                          fontWeight: "bold",
                          textAlign: "center",
                          marginLeft: 50,
                        }}
                      >
                        VRL Travels <FaBusAlt style={{ marginLeft: "5px" }} />
                        <MdLocationPin style={{ marginLeft: "5px" }} />
                      </p>
                      <p style={{ textAlign: "center", marginLeft: 50 }}>
                        {" "}
                        A/C Sleeper (2+1)
                      </p>
                    </div>
                    <div className="sdsddasa">
                      <span
                        style={{
                          backgroundColor: "#004AAD",
                          padding: "3px",
                          margin: "15px",
                          borderRadius: "10px",
                          color: "white",
                          marginLeft: "40px",
                        }}
                      >
                        * 4.7{" "}
                      </span>
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "black",
                          margin: "15px",
                        }}
                      >
                        4 Ratings{" "}
                      </span>
                    </div>
                  </div>
                  <div
                    className="gjgh"
                    style={{ justifyContent: "space-around" }}
                  >
                    <div onClick={onPress7}>
                      <p
                        className="fog"
                        style={{ color: busroutes ? "red" : "black" }}
                      >
                        Busroutes
                      </p>
                    </div>
  
                    <div onClick={onPress2}>
                      <p
                        className="fog"
                        style={{ color: photos ? "red" : "black" }}
                      >
                        Photos
                      </p>
                    </div>
  
                    <div onClick={onPress3}>
                      <p
                        className="fog"
                        style={{ color: bordingpoints ? "red" : "black" }}
                      >
                        Pickup/Drop
                      </p>
                    </div>
                    <div onClick={onPress6}>
                      <p
                        className="fog"
                        style={{ color: reststops ? "red" : "black" }}
                      >
                        Reststops
                      </p>
                    </div>
                    <div onClick={onPress4}>
                      <p
                        className="fog"
                        style={{ color: Amenities ? "red" : "black" }}
                      >
                        Amenities
                      </p>
                    </div>
                    <div onClick={onPress5}>
                      <p
                        className="fog"
                        style={{ color: reviews ? "red" : "black" }}
                      >
                        Reviews
                      </p>
                    </div>
                    <div onClick={onPress1}>
                      <p
                        className="fog"
                        style={{ color: policies ? "red" : "black" }}
                      >
                        Policies
                      </p>
                    </div>
                  </div>
                  {busroutes ? (
                    <>
                      <div style={{ margin: 10, padding: 10 }}>
                        <p>● Bangalore</p>
                        <p
                          style={{
                            borderLeft: "2px solid black",
                            height: "20px",
                            marginLeft: 5,
                          }}
                        >
                          {" "}
                        </p>
                        <p>● Chikkaballapur</p>
                        <p
                          style={{
                            borderLeft: "2px solid black",
                            height: "20px",
                            marginLeft: 5,
                          }}
                        >
                          {" "}
                        </p>
                        <p>● Bagepalli</p>
                        <p
                          style={{
                            borderLeft: "2px solid black",
                            height: "20px",
                            marginLeft: 5,
                          }}
                        >
                          {" "}
                        </p>
                        <p>● Penukonda</p>
                        <p
                          style={{
                            borderLeft: "2px solid black",
                            height: "20px",
                            marginLeft: 5,
                          }}
                        >
                          {" "}
                        </p>
                        <p>● Anantapur</p>
                        <p
                          style={{
                            borderLeft: "2px solid black",
                            height: "20px",
                            marginLeft: 5,
                          }}
                        >
                          {" "}
                        </p>
                        <p>● Pamidi</p>
                        <p
                          style={{
                            borderLeft: "2px solid black",
                            height: "20px",
                            marginLeft: 5,
                          }}
                        >
                          {" "}
                        </p>
                        <p>● Gooty</p>
                        <p
                          style={{
                            borderLeft: "2px solid black",
                            height: "20px",
                            marginLeft: 5,
                          }}
                        >
                          {" "}
                        </p>
                        <p>● Dhone</p>
                        <p
                          style={{
                            borderLeft: "2px solid black",
                            height: "20px",
                            marginLeft: 5,
                          }}
                        >
                          {" "}
                        </p>
                        <p>● Kurnool</p>
                        <p
                          style={{
                            borderLeft: "2px solid black",
                            height: "20px",
                            marginLeft: 5,
                          }}
                        >
                          {" "}
                        </p>
                        <p>● Pebbair</p>
                        <p
                          style={{
                            borderLeft: "2px solid black",
                            height: "20px",
                            marginLeft: 5,
                          }}
                        >
                          {" "}
                        </p>
                        <p>● Kothakota</p>
                        <p
                          style={{
                            borderLeft: "2px solid black",
                            height: "20px",
                            marginLeft: 5,
                          }}
                        >
                          {" "}
                        </p>
                        <p>● Bhuthpur</p>
                        <p
                          style={{
                            borderLeft: "2px solid black",
                            height: "20px",
                            marginLeft: 5,
                          }}
                        >
                          {" "}
                        </p>
                        <p>● Shadnagar</p>
                        <p
                          style={{
                            borderLeft: "2px solid black",
                            height: "20px",
                            marginLeft: 5,
                          }}
                        >
                          {" "}
                        </p>
                        <p>● Hyderabad</p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {policies ? (
                    <>
                      <div style={{ margin: 10, padding: 10 }}>
                        <p>
                          ● {"  "}Cancellation charges are computed on a per seat
                          basis.
                        </p>
                        <p>
                          ● {"  "}Above cancellation fare is calculated based on
                          seat fare of ₹ 1499
                        </p>
                        <p>
                          ● {"  "}Cancellation charges are calculated based on
                          service start date + time at :15-05-2024 20:20
                        </p>
                        <p>
                          ● {"  "}Ticket cannot be cancelled after scheduled bus
                          departure time from the first boarding point
                        </p>
                        <p>
                          ● {"  "}Note: Cancellation charges mentioned above are
                          excluding GST
                        </p>
                        <p>
                          ● {"  "}For group bookings cancellation of individual
                          seats is not allowed.
                        </p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {photos ? (
                    <>
                      <div className="d-flex m-20" style={{ flexWrap: "wrap" }}>
                        <img
                          class="section-intro-img"
                          src="../Image/hotel.avif"
                          alt=""
                          style={{
                            width: "130px",
                            height: "130px",
                            margin: 10,
                            borderRadius: 10,
                          }}
                        />
                        <img
                          class="section-intro-img"
                          src="../Image/hotel.avif"
                          alt=""
                          style={{
                            width: "130px",
                            height: "130px",
                            margin: 10,
                            borderRadius: 10,
                          }}
                        />
                        <img
                          class="section-intro-img"
                          src="../Image/hotel.avif"
                          alt=""
                          style={{
                            width: "130px",
                            height: "130px",
                            margin: 10,
                            borderRadius: 10,
                          }}
                        />
                        <img
                          class="section-intro-img"
                          src="../Image/hotel.avif"
                          alt=""
                          style={{
                            width: "130px",
                            height: "130px",
                            margin: 10,
                            borderRadius: 10,
                          }}
                        />
                        <img
                          class="section-intro-img"
                          src="../Image/hotel.avif"
                          alt=""
                          style={{
                            width: "130px",
                            height: "130px",
                            margin: 10,
                            borderRadius: 10,
                          }}
                        />
                        <img
                          class="section-intro-img"
                          src="../Image/hotel.avif"
                          alt=""
                          style={{
                            width: "130px",
                            height: "130px",
                            margin: 10,
                            borderRadius: 10,
                          }}
                        />
                        <img
                          class="section-intro-img"
                          src="../Image/hotel.avif"
                          alt=""
                          style={{
                            width: "130px",
                            height: "130px",
                            margin: 10,
                            borderRadius: 10,
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {Amenities ? (
                    <>
                      <div
                        className="d-flex m-20"
                        style={{ margin: 10, flexWrap: "wrap", padding: 30 }}
                      >
                        <p style={{ padding: 10 }}>● M-ticket Supported</p>
                        <p style={{ padding: 10 }}>● Deep Cleaned Buses</p>
                        <p style={{ padding: 10 }}>● Personal TV</p>
                        <p style={{ padding: 10 }}>● Pillow</p>
                        <p style={{ padding: 10 }}>● Emergency Contact Number</p>
                        <p style={{ padding: 10 }}>● USB port for charger</p>
                        <p style={{ padding: 10 }}>● Blankets</p>
                        <p style={{ padding: 10 }}>● Charging Point</p>
                        <p style={{ padding: 10 }}>● Bed Sheet</p>
                        <p style={{ padding: 10 }}>● Reading Light</p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {bordingpoints ? (
                    <>
                      <div className="d-flex">
                        <div
                          className=" m-20"
                          style={{ margin: 10, flexWrap: "wrap", padding: 30 }}
                        >
                          <p style={{ padding: 10, fontWeight: "bold" }}>
                            BOARDING POINT
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 20:20 LBNAGAR(VANPICK UP)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 20:25 NAGOLE (VAN PICKUP)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 20:35 UPPAL (VAN PICKUP)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 20:40 HABSIGUDA (VAN PICKUP)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 20:45 TARNAKA (VAN PICKUP)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 20:46 mettaguda (VAN PICKUP)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 20:50 Secunderbad Station
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 21:00 Paradise(Van Pick Up)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 21:10 Ameerpet (Pickup van)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 21:15 SR Nagar (pickup van)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 21:25 Moosapet (van pickup)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 21:28 Kukatpally(Bus or Van)
                          </p>
                        </div>
                        <div
                          className=" m-20"
                          style={{ margin: 10, flexWrap: "wrap", padding: 30 }}
                        >
                          <p style={{ padding: 10, fontWeight: "bold" }}>
                            DROPPING POINT
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 20:20 LBNAGAR(VANPICK UP)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 20:25 NAGOLE (VAN PICKUP)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 20:35 UPPAL (VAN PICKUP)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 20:40 HABSIGUDA (VAN PICKUP)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 20:45 TARNAKA (VAN PICKUP)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 20:46 mettaguda (VAN PICKUP)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 20:50 Secunderbad Station
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 21:00 Paradise(Van Pick Up)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 21:10 Ameerpet (Pickup van)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 21:15 SR Nagar (pickup van)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 21:25 Moosapet (van pickup)
                          </p>
                          <p style={{ padding: 10 }}>
                            ● 21:28 Kukatpally(Bus or Van)
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {reviews ? (
                    <>
                      <div
                        className="d-flex"
                        style={{ flexWrap: "wrap", marginTop: 30 }}
                      >
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40, width: 400 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40, width: 400 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40, width: 400 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40, width: 400 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                      </div>
                      <div>
                        <p   onClick={handleShow1} style={{color:"#004aad",fontSize:"18px",fontWeight:"bold",textAlign:"center",margin:10}}>View All Reviews(32)</p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {reststops ? (
                    <>
                      <div
                        className="m-20"
                        style={{ margin: 10, flexWrap: "wrap", padding: 30 }}
                      >
                        <p
                          style={{
                            paddingLeft: 10,
                            fontSize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          SR Nagar
                        </p>
                        <p style={{ paddingLeft: 10 }}>
                          Near Nayata Petrol Bunk,Towards Chandapura
                        </p>
                        <p
                          style={{
                            paddingLeft: 10,
                            fontSize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          10:00 PM
                        </p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                )
              })}
            
              </div>
          </div>
        </Row>
      </Container>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Choose your seat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       
          <div className="row">
            <div className="col-lg-6 bus">
             <div className="row">

             <div className="col-md-6">
             <div className="d-flex mb-2" style={{textAlign:"center",margin:5,fontSize:20,fontWeight:'bold',marginLeft:20}}>
                 LowerDesk
                <span style={{ fontSize: "22px", marginRight: "10px" }}>
                  <GiSteeringWheel  style={{marginLeft:10}}/>
                </span>

              </div>
                <div className="d-flex ">
                  <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                    <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                  <img
                    class="section-intro-img"
                    src="./notavailable.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                </div>
                <div className="d-flex ">
                  <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                    <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                  <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                </div>
                <div className="d-flex ">
                  <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                    <img
                    class="section-intro-img"
                    src="./notavailable.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                  <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                </div>
                <div className="d-flex ">
                  <img
                    class="section-intro-img"
                    src="./notavailable.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                    <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                  <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                </div>
                <div className="d-flex ">
                  <img
                    class="section-intro-img"
                    src="./notavailable.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                    <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                  <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                </div>
                </div>
                <div className="col-md-6">
                <div className="d-flex mb-2" style={{textAlign:"center",margin:5,fontSize:20,fontWeight:'bold',marginLeft:20}}>
                     UpperDesk
              </div>
                   <div className="d-flex ">
                  <img
                    class="section-intro-img"
                    src="./notavailable.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                    <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                  <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                </div>
                <div className="d-flex ">
                  <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                    <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                  <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                </div>
                <div className="d-flex ">
                  <img
                    class="section-intro-img"
                    src="./notavailable.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                    <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                  <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                </div>
                <div className="d-flex ">
                  <img
                    class="section-intro-img"
                    src="./notavailable.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                    <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                  <img
                    class="section-intro-img"
                    src="./notavailable.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                </div>
                <div className="d-flex ">
                  <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                    <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                  <img
                    class="section-intro-img"
                    src="./notavailable.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 5,
                    }}
                  />
                </div>
                
                </div>
             </div>
           </div>

            <div className="col-lg-6">
              <div className="d-flex gap-3 mb-4">
                <span>
                  <img
                    class="section-intro-img"
                    src="./seat.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 10,
                    }}
                  />
                  Available seat
                </span>
                <span>
                  <img
                    class="section-intro-img"
                    src="./notavailable.png"
                    alt=""
                    style={{
                      width: "50px",
                      height: "60px",
                      margin: 10,
                    }}
                  />
                  Booked seat
                </span>
              </div>

              <div>
                <label className="fw-bold">Select Pick Up Point :</label>
                <div class="input-group mb-2">
                  <select
                    class="form-select form-select-lg mb-3"
                    aria-label="Large select example"
                  >
                    <option value="1">Aanad Rao Circle</option>
                    <option value="2">Mejestic</option>
                    <option value="3">Yeshvanthpura</option>
                    <option value="3">Chikkaballapur</option>
                    <option value="3">Bagepalli</option>
                    <option value="3">Gooty</option>
                    <option value="3">Kukatpally</option>
                    <option value="3">Gooty</option> 
                    <option value="3">Kurnool</option>

                  </select>
                </div>
                <label className="fw-bold">Select Drop Point :</label>
                <div class="input-group mb-2">
                  <select
                    class="form-select form-select-lg mb-3"
                    aria-label="Large select example"
                  >
                    <option value="1">Aanad Rao Circle</option>
                    <option value="2">Mejestic</option>
                    <option value="3">Yeshvanthpura</option>
                    <option value="3">Hyderabad</option>
                    <option value="3">LBNAGAR</option>
                    <option value="3">Kolar</option>
                    <option value="3">NAGOLE</option>
                    <option value="3">Kukatpally</option> 
                    <option value="3">Hyderabad</option>
                  </select>
                </div>
              </div>

              <div>
                <Table striped bordered hover>
                  <thead>
                    <tr style={{ background: "#fff" }}>
                      <th>Seat</th>
                      <th>Fare</th>
                      <th>Class</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>A3</td>
                      <td>₹ 2900.00</td>
                      <td>Business</td>
                    </tr>

                    <tr>
                      <td className="fw-bold">Total :</td>
                      <td colSpan={2}>
                        <div className="text-end fs-4 text-primary" style={{color:"#004aad"}}>
                          ₹ 2999.00
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/busbooking">
            <Button
              style={{ background: "#004aad", border: "none" }}
              onClick={handleClose}
            >
              Continue
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
      <Modal size="lg" show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title className="gradient-box1">All Reviews</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       
        <div
                        className="d-flex"
                        style={{ flexWrap: "wrap", marginTop: 30 }}
                      >
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div><div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <p style={{ color: "black" }}>
                              <FaRegCircleUser
                                style={{ fontSize: 40, margin: 15 }}
                              />
                            </p>
                            <p
                              style={{
                                color: "black",
                                marginTop: 20,
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Sam
                            </p>
                          </div>
                          <p
                            style={{ color: "black", marginLeft: 40 }}
                          >
                            Pickup point and drop point are conveniently located
                            near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.Pickup point and drop point are conveniently located near my desired location.
                          </p>
                        </div>
                      </div>
                    
        </Modal.Body>
        <Modal.Footer>
        
            <Button
              style={{ background: "#004aad", border: "none" }}
              onClick={handleClose1}
            >
              Close
            </Button>
        
        </Modal.Footer>
      </Modal>
    </div>
  );
};
