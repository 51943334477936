import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import "./admin.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { PriceCheckOutlined } from "@mui/icons-material";
import { TiDeleteOutline } from "react-icons/ti";
import { CiCircleRemove } from "react-icons/ci";
import ReactPaginate from "react-paginate";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function AddLodges() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // price Modal---
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [deleteId, setDeleteId] = useState("");
  // delete Modal---
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // Includes Modal---
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // Excludes Modal---
  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  // ITINERARY model ---
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  // Excludes Modal---
  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = (data) => {
    setOverview(data?.OVERVIEW);
    setlodgename(data?.lodgeName);
    setLocation(data?.location);
    setlodgetiming(data?.lodgeTiming);
    setLodgeownername(data?.lodgeOwnerName);
    setstartprice(data?.StartingPrice);
    setEmail(data?.email);
    setContact(data?.contact);
    setITINERARY(data?.ITINERARY);
    setTermCondition(data?.termCondition);
    setinclude(data?.Includes);
    setShow4(true);
  };

  const [showVideo, setshowVideo] = useState(false);

  const [UploadVideo, setUploadVideo] = useState(false);

  const [lodgename, setlodgename] = useState("");
  // const [Category, setCategory] = useState("");
  const [Location, setLocation] = useState("");
  const [Lodgeprofile, setLodgeprofile] = useState("");
  const [Lodgetiming, setlodgetiming] = useState("");
  const [Lodgeownername, setLodgeownername] = useState("");
  const [Email, setEmail] = useState("");
  const [Contact, setContact] = useState("");
  const [Overview, setOverview] = useState("");
  const [startprice, setstartprice] = useState("");
  const [Packagename, setPackagename] = useState("");
  const [packImage, setpackImage] = useState([]);
  const [gst, setgst] = useState("");
  const [igst, setigst] = useState(0);
  const [discount, setdiscount] = useState("");
  const [roomType, setroomType] = useState("");
  const [numberOfroom, setnumberOfroom] = useState("");
  const [price, setprice] = useState("");

  const [include, setinclude] = useState("");
  const [inclTest, setinclText] = useState("");
  const [packDisk, setpackDisk] = useState("");

  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setOverview(data);
  };
  const handlePackDisk = (e, editor) => {
    const data = editor.getData();
    setpackDisk(data);
  };
  const [nochGst,setnochGst]=useState(0)
  const getGst = async () => {
    const config = {
      url: "/getGst",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setnochGst(result.data.success?.Sgst+result.data.success?.Cgst);
        setgst(result.data.success?.Sgst+result.data.success?.Cgst);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  // const addInclude = () => {
  //   let inc = {};
  //   if (inclTest) {
  //     inc.text = inclTest;
  //   }
  //   include.push(inc);
  //   alert("Success Added");
  //   setinclText("");
  // };
  const [Excludes, setExcludes] = useState([]);
  const [exText, setexText] = useState("");
  // const addExclude = () => {
  //   let inc = {};
  //   if (exText) {
  //     inc.text1 = exText;
  //   }
  //   Excludes.push(inc);
  //   alert("Success Added");
  //   setexText("");
  // };

  // const addPackDataPrice = () => {
  //   alert("Success Added");
  //   setPackagename("");
  //   setgst("");
  //   setdiscount("");
  //   setroomType("");
  //   setnumberOfroom("");
  // };

  const [ITINERARY, setITINERARY] = useState("");

  const handleITINERARY = (e, editor) => {
    const data = editor.getData();
    setITINERARY(data);
  };
  const [TermAncConDition, setTermCondition] = useState("");

  const handleTermsCond = (e, editor) => {
    const data = editor.getData();
    setTermCondition(data);
  };
  const [timeTxt, settimeTxt] = useState("");
  const [deatails, setDetails] = useState("");
  // const AddIntity = () => {
  //   let obj = {};
  //   if (timeTxt) {
  //     obj["time"] = timeTxt;
  //   }
  //   if (deatails) {
  //     obj["details"] = deatails;
  //   }
  //   ITINERARY.push(obj);
  //   alert("Success");
  //   settimeTxt("");
  //   setDetails("");
  // };
  const [category, setcategory] = useState("");
  const onSubmit = async () => {
    try {
      const config = {
        url: "/AddLodge",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "multipart/form-data" },
        data: {
          lodgeName: lodgename,
          lodgeOwnerName: Lodgeownername,
          lodgeProfile: Lodgeprofile,
          OVERVIEW: Overview,
          lodgeTiming: Lodgetiming,
          location: Location,
          contact: Contact,
          termCondition: TermAncConDition,
          email: Email,
          PRICING: price,
          Includes: include,
          Excludes: Excludes,
          StartingPrice: startprice,
          ITINERARY: ITINERARY,
          category: category,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Added");
        lodges();
        setITINERARY("");
        setTermCondition("");
        setOverview("");
        setinclude("")
        handleClose();
        setlodgename("");
        setLocation("");
        setLodgeprofile("");
        setlodgetiming("");
        setLodgeownername("");
        setstartprice("");
        setEmail("");
        setContact("");
        setcategory("");
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const [lodgess, setLoadgess] = useState([]);

  const lodges = async () => {
    const config = {
      url: "/getAllLodge",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setLoadgess(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    lodges();
    getGst()
  }, []);
  const MakeBlock = async (id, isBloc) => {
    try {
      const config = {
        url: "/makeBlockUnbolckLodge",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { hotelId: id, isBloc: isBloc },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        lodges();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  // Delete
  const onDelete = async () => {
    // e.preventDefault();
    try {
      const config = {
        url: "/deleteLodege/" + deleteId,
        method: "delete",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        // sessionStorage.setItem("user", JSON.stringify(res.data.success));
        // history("/");
        alert("Successfully Deleted");
        lodges();
        handleClose6();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const [Allcategory, setAllcategory] = useState([]);
  const getAllCategory = async () => {
    const config = {
      url: "/getAllCategory",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setAllcategory(result.data.success);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const [edit, setedit] = useState({});
  const onEdit = async () => {
    try {
      const config = {
        url: "/editLodge",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "multipart/form-data" },
        data: {
          lodgeId: edit?._id,
          lodgeName: lodgename,
          lodgeOwnerName: Lodgeownername,
          lodgeProfile: Lodgeprofile,
          OVERVIEW: Overview,
          lodgeTiming: Lodgetiming,
          location: Location,
          contact: Contact,
          termCondition: TermAncConDition,
          email: Email,
          PRICING: price,
          Includes: include,
          Excludes: Excludes,
          StartingPrice: startprice,
          ITINERARY: ITINERARY,
          category: category,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully updated");
        lodges();
        setOverview("");
        handleClose4();
        setlodgename("");
        setLocation("");
        setLodgeprofile("");
        setinclude("")
        setlodgetiming("");
        setLodgeownername("");
        setstartprice("");
        setEmail("");
        setContact("");
        setITINERARY("");
        setTermCondition("");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const getbyLodgeId = async (id) => {
    try {
      const config = {
        url: "/getbyLodgeId/" + id,
        method: "get",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setedit(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addPricing = async () => {
    try {
      if (packImage.length > 11)
        return alert("Images should be less the 10 images");
      if (!Packagename) {
        return alert("Please enter package name!");
      }
      if (!price) {
        return alert("Please enter package price!");
      }
      setUploadVideo(true);
      const config = {
        url: "/AddPakageStuctureLodge",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "multipart/form-data" },
        data: {
          id: imagesData?._id,
          packageImages: packImage,
          packageName: Packagename,
          totalRoom: numberOfroom,
          roomType: roomType,
          gst: gst,
          igst: igst,
          packegePrice: price,
          discount: discount,
          description: packDisk,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfull added");
        setPackagename("");
        getLoadgePackageById(imagesData?._id);
        setpackImage([]);
        setpackDisk("");
        setdiscount("");
        setgst(nochGst);
        setprice("");
        setigst(0);
        setnumberOfroom("");
        setroomType("");
        handleClose5();
        setUploadVideo(false);
      }
    } catch (error) {
      setUploadVideo(false);
      console.log(error);
    }
  };

  const [AllPackage, setAllPackage] = useState([]);
  const getLoadgePackageById = async (id) => {
    try {
      let res = await axios(
        "https://dhanyatours.me/api/user/getAllPackageLodge/" + id
      );
      if (res.status == 200) {
        setAllPackage(res.data.success);

        handleShow1();
        setUploadVideo(false);
      }
    } catch (error) {
      console.log(error);
      setUploadVideo(false);
    }
  };
  const deletePricing = async (id) => {
    try {
      const config = {
        url: "/removeLodgePriceing",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, priceId: id },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully deleted");
        getbyLodgeId(edit._id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [packLodImg, setpackLodImg] = useState("");

  const addPackageLogeImage = async (pack) => {
    try {
      if (!packLodImg) {
        return alert("Please select image!");
      }
      if (pack?.packageImages?.length > 10) return alert("Images maximum 10");
      const config = {
        url: "/addPackageLogeImage",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "multipart/form-data" },
        data: { lodgeId: pack?._id, image: packLodImg },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfull added");
        getLoadgePackageById(imagesData?._id);
        setpackLodImg("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const removePackageLodgeImage = async (packid, id) => {
    try {
      const config = {
        url: "/removePackageLodgeImage",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: packid, imageId: id },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully deleted");
        getLoadgePackageById(imagesData?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addExcludesA = async () => {
    try {
      if (!exText) {
        return alert("Please enter excludes!");
      }
      const config = {
        url: "/AddLodgeExcludes",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, text1: exText },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfull added");
        getbyLodgeId(edit._id);
        setexText("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteExcludes = async (id) => {
    try {
      const config = {
        url: "/removeLodgeExcludes",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, ExcludesId: id },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully deleted");
        getbyLodgeId(edit._id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addItineraryA = async () => {
    try {
      if (!timeTxt) {
        return alert("Please enter time!");
      }
      if (!deatails) {
        return alert("Please enter some details!");
      }
      const config = {
        url: "/AddLodgeITINERARY",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, time: timeTxt, details: deatails },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfull added");
        getbyLodgeId(edit._id);
        settimeTxt("");
        setDetails("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteItinerary = async (id) => {
    try {
      const config = {
        url: "/removeLodgeITINERARY",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { lodgeId: edit._id, ITINERARYId: id },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully deleted");
        getbyLodgeId(edit._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(lodgess?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = lodgess?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setLoadgess([...lodgess]);
    }
  };
  const [imagesData, setImagesData] = useState({});
  const [showImages, setshowImages] = useState(false);
  const hsndleImages = () => setshowImages(true);
  const closeImages = () => setshowImages(false);
  const [image3, setimage3] = useState("");

  const AddSingleImagesHotel = async () => {
    if (!image3) return alert("Please select images");
    try {
      const config = {
        url: "/addLodgeImage",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "multipart/form-data" },
        data: {
          lodgeId: imagesData?._id,
          image: image3,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setimage3("");
        lodges();
        setImagesData(res.data.success);
        return alert("Successfully added");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const deleteHotelImages = async (id) => {
    try {
      const config = {
        url: "/removeLodgeImage",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          lodgeId: imagesData?._id,
          imageId: id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setimage3("");
        lodges();
        setImagesData(res.data.success);
        return alert("Successfully deleted");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const [packEdit, setpackEdit] = useState({});
  const [packEShow, setpackEShow] = useState(false);
  const sshoPackedit = (data) => {
    setpackDisk(data?.description);
    setdiscount(data?.discount);
    setgst(data?.gst);
    setigst(data.igst);
    setprice(data?.packegePrice);
    setPackagename(data?.packageName);
    setnumberOfroom(data?.totalRoom);
    setroomType(data?.roomType);
    setpackEShow(true);
  };

  const editPackage = async () => {
    try {
      setUploadVideo(true);
      const config = {
        url: "/updatePakageStuctureLodge",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          id: packEdit?._id,
          packageImages: packImage,
          packageName: Packagename,
          totalRoom: numberOfroom,
          roomType: roomType,
          gst: gst,
          igst: igst,
          packegePrice: price,
          discount: discount,
          description: packDisk,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfull updated");
        setPackagename("");
        getLoadgePackageById(imagesData?._id);

        setpackDisk("");
        setdiscount("");
        setgst(nochGst);
        setigst(0);
        setprice("");
        setnumberOfroom("");
        setroomType("");
        setUploadVideo(false);
        setpackEShow(false);
      }
    } catch (error) {
      setUploadVideo(false);
      console.log(error);
    }
  };
  const [deltePack, setdeletePack] = useState("");
  const [packDeleteS, setpackDeleteS] = useState(false);
  const deleteApiPackage = async () => {
    try {
      const config = {
        url: "/deletePackageStructureLodge",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: { id: deltePack },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deleted");
        getLoadgePackageById(imagesData?._id);
        setpackDeleteS(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="de-lod">
        {UploadVideo ? (
          <div
            class="loader"
            style={{ position: "fixed", top: "50%", left: "50%" }}
          ></div>
        ) : null}
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h3>Jungle Lodges List</h3>
          </div>

          <div
            className="logde-op-00"
            style={{ cursor: "pointer" }}
            onClick={()=>{
              setOverview("");
              setinclude("");
              setlodgename("");
              setLocation("");
              setLodgeprofile("");
              setlodgetiming("");
              setLodgeownername("");
              setstartprice("");
              setEmail("");
              setContact("");
              setITINERARY("");
              setTermCondition("");
              handleShow()}}
          >
            + Jungle Lodges
          </div>

          <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton>
              <Modal.Title>Add Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form>
                  <label className="pb-2 nd-2">Lodge Name</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="lodgename"
                    value={lodgename}
                    placeholder="Lodge Name"
                    required
                    onChange={(e) => setlodgename(e.target.value)}
                  />
                  <label className="pb-2 nd-2">Category</label>
                  <br />
                  <select
                    className="title-90"
                    onChange={(e) => setcategory(e.target.value)}
                  >
                    <option>Select</option>
                    {Allcategory?.map((item) => {
                      return (
                        <option value={item?.category}>{item?.category}</option>
                      );
                    })}
                  </select>

                  <label className="pb-2 nd-2">Location</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="location"
                    placeholder="Eg:-Bangalore"
                    value={Location}
                    required
                    onChange={(e) => setLocation(e.target.value)}
                  />
                  <label className="pb-2 nd-2" htmlFor="upload1">
                    Lodge Profile
                  </label>
                  <br />
                  <input
                    type="file"
                    className="title-90"
                    name="lodgeprofile"
                    id="upload1"
                    accept="image/*"
                    required
                    onChange={(e) => setLodgeprofile(e.target.files[0])}
                  />
                  <label className="pb-2 nd-2">Lodge Timing</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="lodgetiming"
                    value={Lodgetiming}
                    required
                    onChange={(e) => setlodgetiming(e.target.value)}
                  />
                  <label className="pb-2 nd-2">Lodge Owner Name</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="lodgeownername"
                    value={Lodgeownername}
                    required
                    onChange={(e) => setLodgeownername(e.target.value)}
                  />
                  <label className="pb-2 nd-2">Starting Price</label>
                  <br />
                  <input
                    type="Number"
                    className="title-90"
                    name="lodgeownername"
                    value={startprice}
                    required
                    onChange={(e) => setstartprice(e.target.value)}
                  />
                  <label className="pb-2 nd-2">Includes </label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="lodgeownername"
                    value={include}
                    required
                    placeholder="Ex:- all inclusive including Safari"
                    onChange={(e) => setinclude(e.target.value)}
                  />
                  <label className="pb-2 nd-2">Email</label>
                  <br />
                  <input
                    type="email"
                    className="title-90"
                    name="email"
                    value={Email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label className="pb-2 nd-2">Contact</label>
                  <br />
                  <input
                    type="number"
                    className="title-90"
                    name="contact"
                    value={Contact}
                    required
                    onChange={(e) => setContact(e.target.value)}
                  />
                  <label className="pb-2 nd-2">Overview</label>
                  <br />
                  <CKEditor
                    editor={ClassicEditor}
                    data={Overview}
                    onChange={handleChange1}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                  {/* <Button className="pb-2 nd-2 mt-3" onClick={handleShow1}>
                    Pricing
                  </Button> */}
                  <Button className="pb-2 nd-2 mt-3 mx-2" onClick={handleShow2}>
                    Itinerary
                  </Button>
                  <Button className="pb-2 nd-2 mt-3 mx-2" onClick={handleShow3}>
                    Terms & Condition
                  </Button>
                  {/* <Button className="pb-2 nd-2 mt-3 mx-2" onClick={handleShow5}>
                    Itinerary
                  </Button> */}
                  <br />

                  <Modal show={show2} onHide={handleClose2}>
                    <Modal.Body>
                      <from>
                        <label className="pb-2 nd-2"> Itinerary</label>
                        <br />

                        <CKEditor
                          editor={ClassicEditor}
                          data={ITINERARY}
                          onChange={handleITINERARY}
                          onBlur={(event, editor) => {
                            console.log("Blur.", editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log("Focus.", editor);
                          }}
                        />
                      </from>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose2}>
                        Back
                      </Button>
                      <Button variant="primary" onClick={() => handleClose2()}>
                        Save
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal show={show3} onHide={handleClose3}>
                    <Modal.Body>
                      <from>
                        <label className="pb-2 nd-2">Terms & Condition</label>
                        <br />{" "}
                        <CKEditor
                          editor={ClassicEditor}
                          data={TermAncConDition}
                          onChange={handleTermsCond}
                          onBlur={(event, editor) => {
                            console.log("Blur.", editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log("Focus.", editor);
                          }}
                        />
                      </from>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose3}>
                        Back
                      </Button>
                      <Button variant="primary" onClick={() => handleClose3()}>
                        Add
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Back
              </Button>
              <Button variant="primary" onClick={(e) => onSubmit()}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="searchHeadFlex">
          <div className="searchWrapper" style={{ display: "flex" }}>
            <input
              type="text"
              maxlength="230"
              style={{ outlineWidth: "0px", padding: "4px" }}
              className="textsearch"
              placeholder="Search by lodge"
              onChange={handleFilter}
            />
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 16 16"
              id="BsSearch"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                clip-rule="evenodd"
              ></path>
              <path
                fill-rule="evenodd"
                d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Lodge Name</th>
              <th>Category</th>
              <th>Location</th>
              <th>Lodge Profile</th>
              <th>Lodge Timing</th>
              <th>Lodge Owner Name</th>
              <th>Starting Price</th>
              <th>Email</th>
              <th>Contact</th>
              <th></th>
              <th>Action</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {search?.length > 0 ? (
              tableFilter
                ?.slice(pagesVisited, pagesVisited + usersPerPage)
                ?.map((ele, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>

                      <td>{ele?.lodgeName}</td>
                      <td>{ele?.category}</td>
                      <td>
                        <p
                          style={{
                            overflow: "hidden",
                            height: "100px",
                            width: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          {ele?.location}
                        </p>
                      </td>
                      <td>
                        <div style={{ width: "150px" }}>
                          <img
                            src={`https://dhanyatours.me/lodge/${ele?.lodgeProfile}`}
                            alt="lodge-image"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      </td>
                      <td>{ele?.lodgeTiming}</td>
                      <td>{ele?.lodgeOwnerName}</td>
                      <td>{ele?.StartingPrice}</td>
                      <td>{ele?.email}</td>
                      <td>{ele?.contact}</td>

                      <td style={{ display: "flex", gap: "5px" }}>
                        <div
                          className=""
                          onClick={() => {
                            handleShow4(ele);
                            setedit(ele);
                            getbyLodgeId(ele._id);
                          }}
                        >
                          <FaEdit
                            style={{
                              color: "#5600ff",
                              cursor: "pointer",
                              fontSize: "26px",
                            }}
                          />
                        </div>
                        <div>
                          <button
                            type="button"
                            class="btn btn-success"
                            onClick={() => {
                              setImagesData(ele);
                              hsndleImages();
                            }}
                          >
                            {" "}
                            Gallery{" "}
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            class="btn btn-info"
                            style={{ color: "white" }}
                            onClick={() => {
                              setImagesData(ele);
                              setUploadVideo(true);
                              getLoadgePackageById(ele?._id);
                            }}
                          >
                            {" "}
                            Packages{" "}
                          </button>
                        </div>
                      </td>
                      <td>
                        {" "}
                        {ele?.isBloc === false ? (
                          <button
                            type="button"
                            onClick={() => MakeBlock(ele?._id, true)}
                            class="btn btn-danger"
                          >
                            Block
                          </button>
                        ) : (
                          <button
                            type="button"
                            class="btn btn-warning"
                            onClick={() => MakeBlock(ele?._id, false)}
                          >
                            Unblock
                          </button>
                        )}
                      </td>
                      <td>
                        <div
                          className=""
                          onClick={() => {
                            handleShow6();
                            setDeleteId(ele?._id);
                          }}
                        >
                          <AiFillDelete
                            style={{
                              color: "red",
                              cursor: "pointer",
                              fontSize: "26px",
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
            ) : (
              <>
                {lodgess
                  ?.slice(pagesVisited, pagesVisited + usersPerPage)
                  ?.map((ele, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>

                        <td>{ele?.lodgeName}</td>
                        <td>{ele?.category}</td>
                        <td>
                          <p
                            style={{
                              overflow: "hidden",
                              height: "100px",
                              width: "200px",
                              overflowY: "scroll",
                            }}
                          >
                            {ele?.location}
                          </p>
                        </td>
                        <td>
                          <div style={{ width: "150px" }}>
                            <img
                              src={`https://dhanyatours.me/lodge/${ele?.lodgeProfile}`}
                              alt="lodge-image"
                              style={{ width: "100px", height: "100px" }}
                            />
                          </div>
                        </td>
                        <td>{ele?.lodgeTiming}</td>
                        <td>{ele?.lodgeOwnerName}</td>
                        <td>{ele?.StartingPrice}</td>
                        <td>{ele?.email}</td>
                        <td>{ele?.contact}</td>

                        <td style={{ display: "flex", gap: "5px" }}>
                          <div
                            className=""
                            onClick={() => {
                              handleShow4(ele);
                              setedit(ele);
                              getbyLodgeId(ele._id);
                            }}
                          >
                            <FaEdit
                              style={{
                                color: "#5600ff",
                                cursor: "pointer",
                                fontSize: "26px",
                              }}
                            />
                          </div>
                          <div>
                            <button
                              type="button"
                              class="btn btn-success"
                              onClick={() => {
                                setImagesData(ele);
                                hsndleImages();
                              }}
                            >
                              {" "}
                              Gallery{" "}
                            </button>
                          </div>
                          <div>
                            <button
                              type="button"
                              class="btn btn-info"
                              style={{ color: "white" }}
                              onClick={() => {
                                setImagesData(ele);
                                setUploadVideo(true);
                                getLoadgePackageById(ele?._id);
                              }}
                            >
                              {" "}
                              Packages{" "}
                            </button>
                          </div>
                        </td>
                        <td>
                          {" "}
                          {ele?.isBloc === false ? (
                            <button
                              type="button"
                              onClick={() => MakeBlock(ele?._id, true)}
                              class="btn btn-danger"
                            >
                              Block
                            </button>
                          ) : (
                            <button
                              type="button"
                              class="btn btn-warning"
                              onClick={() => MakeBlock(ele?._id, false)}
                            >
                              Unblock
                            </button>
                          )}
                        </td>
                        <td>
                          <div
                            className=""
                            onClick={() => {
                              handleShow6();
                              setDeleteId(ele?._id);
                            }}
                          >
                            <AiFillDelete
                              style={{
                                color: "red",
                                cursor: "pointer",
                                fontSize: "26px",
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </>
            )}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
      {/* delete model */}
      <Modal show={show6} onHide={handleClose6}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose6}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onDelete}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edite */}
      <Modal show={show4} onHide={handleClose4} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Lodge Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <form>
              <label className="pb-2 nd-2">Lodge Name</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="lodgename"
                value={lodgename}
                placeholder={edit.lodgeName}
                required
                onChange={(e) => setlodgename(e.target.value)}
              />
              <label className="pb-2 nd-2">Category</label>
              <br />
              <select
                className="title-90"
                onChange={(e) => setcategory(e.target.value)}
              >
                <option>{edit?.category ? edit?.category : "Select"}</option>
                {Allcategory?.map((item) => {
                  return (
                    <option value={item?.category}>{item?.category}</option>
                  );
                })}
              </select>
              <label className="pb-2 nd-2">Location</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="location"
                value={Location}
                placeholder={edit.location}
                required
                onChange={(e) => setLocation(e.target.value)}
              />
              <label className="pb-2 nd-2" htmlFor="upload2">
                Lodge Profile
              </label>
              <br />
              <input
                type="file"
                className="title-90"
                name="lodgeprofile"
                id="upload2"
                accept="image/*"
                required
                onChange={(e) => setLodgeprofile(e.target.files[0])}
              />
              <label className="pb-2 nd-2">Lodge Timing</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="lodgetiming"
                value={Lodgetiming}
                placeholder={edit.lodgeTiming}
                required
                onChange={(e) => setlodgetiming(e.target.value)}
              />
              <label className="pb-2 nd-2">Lodge Owner Name</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="lodgeownername"
                value={Lodgeownername}
                placeholder={edit.lodgeOwnerName}
                required
                onChange={(e) => setLodgeownername(e.target.value)}
              />
              <label className="pb-2 nd-2">Starting Price</label>
              <br />
              <input
                type="Number"
                className="title-90"
                name="lodgeownername"
                value={startprice}
                placeholder={edit.StartingPrice}
                required
                onChange={(e) => setstartprice(e.target.value)}
              />
               <label className="pb-2 nd-2">Includes </label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="lodgeownername"
                    value={include}
                    required
                    placeholder="Ex:- all inclusive including Safari"
                    onChange={(e) => setinclude(e.target.value)}
                  />
              <label className="pb-2 nd-2">Email</label>
              <br />
              <input
                type="email"
                className="title-90"
                name="email"
                value={Email}
                placeholder={edit.email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <label className="pb-2 nd-2">Contact</label>
              <br />
              <input
                type="number"
                className="title-90"
                name="contact"
                value={Contact}
                placeholder={edit.contact}
                required
                onChange={(e) => setContact(e.target.value)}
              />
              <br />
              {/* <div className="row">
                <label className="pb-2 nd-2">Pricing</label>

                <div className="col-6">
                  <input
                    type="text"
                    className="title-90 mb-2"
                    name="packagename"
                    value={Packagename}
                    placeholder="Package Name"
                    onChange={(e) => setPackagename(e.target.value)}
                  />
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    style={{
                      float: "right",
                      margin: "2px",
                      padding: "1px 7px 0px",
                    }}
                    onClick={addPricing}
                    class="btn btn-outline-info"
                  >
                    Add
                  </button>
                </div>
                <label className="pb-2 nd-2">Single Price</label>
                <div className="col-6">
                  {" "}
                  <input
                    type="number"
                    className="title-90"
                    name="contact"
                    value={roomType}
                    placeholder="Single week price"
                    onChange={(e) => setroomType(e.target.value)}
                  />
                </div>
                <div className="col-6">
                  {" "}
                  <input
                    type="number"
                    className="title-90"
                    value={numberOfroom}
                    placeholder="Single weekend price"
                    required
                    onChange={(e) => setnumberOfroom(e.target.value)}
                  />
                </div>
                <label className="pb-2 nd-2">Double Price</label>
                <div className="col-6">
                  {" "}
                  <input
                    type="number"
                    className="title-90"
                    placeholder="Double week price"
                    value={gst}
                    required=""
                    onChange={(e) => setgst(e.target.value)}
                  />
                </div>
                <div className="col-6">
                  {" "}
                  <input
                    type="number"
                    className="title-90"
                    value={discount}
                    onChange={(e) => setdiscount(e.target.value)}
                    placeholder="Double weekend price"
                    required
                    // onChange={(e) => setContact(e.target.value)}
                  />
                </div>
             
              </div> */}
              <div className="row">
                <div className="col-6">
                  <label className="pb-2 nd-2">Itinerary</label>
                </div>

                <CKEditor
                          editor={ClassicEditor}
                          data={ITINERARY}
                          onChange={handleITINERARY}
                          onBlur={(event, editor) => {
                            console.log("Blur.", editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log("Focus.", editor);
                          }}
                        />
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="pb-2 nd-2">Terms & Condition</label>
                </div>

                <CKEditor
                  editor={ClassicEditor}
                  data={TermAncConDition}
                  onChange={handleTermsCond}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
              {/* <div className="row">
                <div className="col-6">
                  <label className="pb-2 nd-2">Itinerary</label>
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    style={{
                      float: "right",
                      margin: "2px",
                      padding: "1px 7px 0px",
                    }}
                    class="btn btn-outline-info"
                    onClick={addItineraryA}
                  >
                    Add
                  </button>
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    className="title-90"
                    name="include"
                    placeholder="Eg: 12:00 pm Noon"
                    value={timeTxt}
                    required
                    onChange={(e) => settimeTxt(e.target.value)}
                  />
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    className="title-90"
                    name="include"
                    placeholder="Write some deatails"
                    value={deatails}
                    required=""
                    onChange={(e) => setDetails(e.target.value)}
                  />
                </div>

                <ol style={{ marginLeft: "15px" }}>
                  {edit?.ITINERARY?.map((item) => {
                    return (
                      <li>
                        <span style={{ fontWeight: "bold" }}>{item?.time}</span>
                        {" : "}
                        {item?.details}{" "}
                        <span>
                          {" "}
                          <CiCircleRemove
                            style={{
                              color: "red",
                              cursor: "pointer",
                              fontSize: "20px",
                            }}
                            onClick={() => deleteItinerary(item?._id)}
                          />
                        </span>
                      </li>
                    );
                  })}{" "}
                </ol>
              </div> */}
              <label className="pb-2 nd-2">Overview</label>
              <br />
              <CKEditor
                editor={ClassicEditor}
                data={Overview}
                onChange={handleChange1}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose4()}>
            Back
          </Button>
          <Button variant="primary" onClick={(e) => onEdit()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Images Model */}
      <Modal show={showImages} onHide={closeImages} size="lg">
        <Modal.Header closeButton>
          <h4
            style={{
              color: "red",
              fontWeight: "bold",
              position: "absolute",
              left: "40%",
            }}
          >
            Lodge Gallery{" "}
          </h4>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="row">
            <div className="col-md-6">
              <input
                type="file"
                className="title-90"
                name="lodgename"
                accept="image/*"
                required=""
                onChange={(e) => setimage3(e.target.files[0])}
              />
            </div>
            <div className="col-md-6">
              <button
                type="button"
                class="btn btn-dark"
                onClick={AddSingleImagesHotel}
              >
                Add Now
              </button>
            </div>
          </div>
          <p>Note:- Minimum 4 images and maximum 10 images.</p>
          <hr></hr>
          <div className="row">
            {imagesData?.lodgeImage?.map((item) => {
              return (
                <div
                  className="col-md-4"
                  style={{ display: "flex", gap: "3px", marginBottom: "10px" }}
                >
                  <div>
                    <img
                      src={"https://dhanyatours.me/lodge/" + item?.image}
                      style={{ width: "200px", height: "210px" }}
                    />{" "}
                  </div>

                  <div>
                    <AiFillDelete
                      style={{
                        fontSize: "25px",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() => deleteHotelImages(item?._id)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeImages}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Package deatails Model */}
      <Modal show={show1} onHide={handleClose1} size="lg">
        <Modal.Header closeButton>
          <h4
            style={{
              color: "red",
              fontWeight: "bold",
              position: "absolute",
              left: "40%",
            }}
          >
            Package Details{" "}
          </h4>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="row">
            {/* <div className="col-md-6">
              <input
                type="file"
                className="title-90"
                name="lodgename"
                accept="image/*"
                required=""
                onChange={(e) => setimage3(e.target.files[0])}
              />
            </div> */}
            <div className="col-md-12">
              <button
                type="button"
                class="btn btn-dark"
                style={{ float: "right" }}
                onClick={() => {
                  setpackImage([]);
                  setPackagename("");
                  setpackDisk("");
                  setdiscount("");
                  setgst(nochGst);
                  setprice("");
                  setigst(0);
                  setnumberOfroom("");
                  setroomType("");
                  handleShow5();
                }}
              >
                +Add
              </button>
            </div>
          </div>
          <p style={{ float: "right" }}>Note:- Add Here new packages.</p>
          <br />
          <hr></hr>
          <hr></hr>
          <div
            className="odjs"
            style={{
              // display:"flex",
              width: "700px",
              overflow: "hidden",
              overflowX: "scroll",
            }}
          >
            {AllPackage?.map((item, a) => {
              return (
                <div
                  className="afadf"
                  style={{ gap: "3px", marginBottom: "10px" }}
                >
                  <div style={{ display: "flex", gap: "5px" }}>
                    <span style={{ fontSize: "21px" }}>{a + 1}.</span>{" "}
                    <h3>{item?.packageName}</h3>
                  </div>
                  <div
                    style={{
                      overflow: " hidden",
                      display: "flex",
                      gap: "26px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.packageImages?.map((ele) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <div>
                            <img
                              src={"https://dhanyatours.me/lodge/" + ele?.image}
                              style={{ width: "200px", height: "210px" }}
                            />{" "}
                          </div>
                          <div>
                            <AiFillDelete
                              style={{
                                fontSize: "25px",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                removePackageLodgeImage(item?._id, ele?._id)
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="file"
                        className="title-90"
                        name="lodgename"
                        accept="image/*"
                        required=""
                        onChange={(e) => setpackLodImg(e.target.files[0])}
                      />
                    </div>
                    <div className="col-md-6">
                      <button
                        type="button"
                        class="btn btn-dark"
                        onClick={() => addPackageLogeImage(item)}
                      >
                        Add Now
                      </button>
                    </div>
                  </div>
                  <h4>Description</h4>
                  <div>{item?.description ? parse(item?.description) : ""}</div>
                  <div className="row">
                    <div className="col-md-9">
                      {item?.roomType ? (
                        <div>
                          <strong
                            style={{
                              color: "red",
                              fontWeight: "700",
                              fontSize: "16px",
                            }}
                          >
                            Room Type
                          </strong>
                          : {item?.roomType} ({item?.totalRoom})
                        </div>
                      ) : (
                        <></>
                      )}

                      <div>
                        <strong
                          style={{
                            color: "red",
                            fontWeight: "700",
                            fontSize: "16px",
                          }}
                        >
                          GST
                        </strong>
                        : {item?.gst}%
                      </div>
                      <div>
                        <strong
                          style={{
                            color: "red",
                            fontWeight: "700",
                            fontSize: "16px",
                          }}
                        >
                          Discount
                        </strong>
                        : ₹ {item?.discount?.toFixed(2)}
                      </div>
                      <div>
                        <strong
                          style={{
                            color: "red",
                            fontWeight: "700",
                            fontSize: "16px",
                          }}
                        >
                          IGST
                        </strong>
                        : {item?.igst}%
                      </div>
                      <div>
                        <strong
                          style={{
                            color: "red",
                            fontWeight: "700",
                            fontSize: "16px",
                          }}
                        >
                          Package Price
                        </strong>
                        : ₹ {item?.packegePrice?.toFixed(2)}
                      </div>
                    </div>
                    <div
                      className="col-md-3"
                      style={{ display: "flex", gap: "10px" }}
                    >
                      <div>
                        <FaEdit
                          style={{
                            color: "#5600ff",
                            cursor: "pointer",
                            fontSize: "28px",
                          }}
                          onClick={() => {
                            setpackEdit(item);
                            sshoPackedit(item);
                          }}
                        />
                      </div>
                      <div>
                        <AiFillDelete
                          style={{
                            fontSize: "27px",
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setdeletePack(item?._id);
                            setpackDeleteS(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <hr></hr>
                  <hr></hr>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose1}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Add Package */}
      <Modal show={show5} onHide={handleClose5} size="lg">
        <Modal.Header closeButton>
          <h4
            style={{
              color: "red",
              fontWeight: "bold",
              position: "absolute",
              left: "40%",
            }}
          >
            Add Package{" "}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <from>
            {/* <label className="pb-2 nd-2">Package Images maximum 10 </label>
            <br /> */}
            <input
              type="file"
              className="title-90 mb-2"
              name="file"
              multiple
              accept="image/*"
              required=""
              onChange={(e) => setpackImage(e.target.files)}
            />
            <p>Note:- Package images minimum 4 and maximum 10</p>
            <label className="pb-2 nd-2">Package Name</label>
            <br />
            <input
              type="text"
              className="title-90 mb-2"
              name="packagename"
              placeholder="Please enter package name"
              value={Packagename}
              required=""
              onChange={(e) => setPackagename(e.target.value)}
            />
            <div className="row">
              <div className="col-md-6">
                <label className="pb-2 nd-2">Package Price</label>
                <br />
                <input
                  type="number"
                  className="title-90 mb-2"
                  name="weekprice"
                  placeholder="Enter package Price "
                  value={price}
                  required=""
                  onChange={(e) => setprice(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="pb-2 nd-2"> GST%</label>
                <br />
                <input
                  type="number"
                  className="title-90 mb-2"
                  name="weekendprice"
                  placeholder="Please enter gst"
                  value={gst}
                  required=""
                  onChange={(e) => setgst(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="pb-2 nd-2">IGST%</label>
                <br />
                <input
                  type="number"
                  className="title-90 mb-2"
                  name="packagename"
                  placeholder="Please enter igst"
                  value={igst}
                  required=""
                  onChange={(e) => setigst(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="pb-2 nd-2">Discount ₹</label>
                <br />
                <input
                  type="number"
                  className="title-90 mb-2"
                  name="sigaleweekprice"
                  placeholder="Ex:-200"
                  value={discount}
                  required=""
                  onChange={(e) => setdiscount(e.target.value)}
                />
              </div>

              <div className="col-md-6">
                <label className="pb-2 nd-2"> Room Types</label>
                <br />
                {/* <select>
                          <option>Choose</option>
                          <option value="Room">Room</option>
                          <option value="Cottage">Cottage</option>
                          <option value="Loghut">Loghut</option>
                          <option value="Tented Cottage">Tented Cottage</option>
                          <option value="Mud Hut">Mud Hut</option>
                        
                          <option value="Loghut">Loghut</option>
                        </select> */}
                <input
                  type="text"
                  className="title-90 mb-2"
                  name="singaleweekendprice"
                  value={roomType}
                  placeholder="Ex:-Cottage"
                  required=""
                  onChange={(e) => setroomType(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="pb-2 nd-2"> Number of {roomType}</label>
                <br />
                <input
                  type="number"
                  className="title-90 mb-2"
                  name="singaleweekendprice"
                  value={numberOfroom}
                  placeholder="Ex:-2"
                  required=""
                  onChange={(e) => setnumberOfroom(e.target.value)}
                />
              </div>
            </div>
            <label className="pb-2 nd-2"> Package Overview</label>
            <br />
            <CKEditor
              editor={ClassicEditor}
              data={packDisk}
              onChange={handlePackDisk}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
          </from>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose5}>
            Back
          </Button>
          <Button variant="primary" onClick={() => addPricing()}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Update Package */}
      <Modal show={packEShow} onHide={() => setpackEShow(false)} size="lg">
        <Modal.Header closeButton>
          <h4
            style={{
              color: "red",
              fontWeight: "bold",
              position: "absolute",
              left: "40%",
            }}
          >
            Update Package{" "}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <from>
            {/* <label className="pb-2 nd-2">Package Images maximum 10 </label>
            <br /> */}
            <label className="pb-2 nd-2">Package Name</label>
            <br />
            <input
              type="text"
              className="title-90 mb-2"
              name="packagename"
              placeholder="Please enter package name"
              value={Packagename}
              required=""
              onChange={(e) => setPackagename(e.target.value)}
            />
            <div className="row">
              <div className="col-md-6">
                <label className="pb-2 nd-2">Package Price</label>
                <br />
                <input
                  type="number"
                  className="title-90 mb-2"
                  name="weekprice"
                  placeholder="Enter package Price "
                  value={price}
                  required=""
                  onChange={(e) => setprice(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="pb-2 nd-2"> GST%</label>
                <br />
                <input
                  type="number"
                  className="title-90 mb-2"
                  name="weekendprice"
                  placeholder="Please enter gst"
                  value={gst}
                  required=""
                  onChange={(e) => setgst(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="pb-2 nd-2">IGST%</label>
                <br />
                <input
                  type="number"
                  className="title-90 mb-2"
                  name="packagename"
                  placeholder="Please enter igst"
                  value={igst}
                  required=""
                  onChange={(e) => setigst(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="pb-2 nd-2">Discount ₹</label>
                <br />
                <input
                  type="number"
                  className="title-90 mb-2"
                  name="sigaleweekprice"
                  placeholder="Ex:-200"
                  value={discount}
                  required=""
                  onChange={(e) => setdiscount(e.target.value)}
                />
              </div>

              <div className="col-md-6">
                <label className="pb-2 nd-2"> Room Types</label>
                <br />
                {/* <select>
                          <option>Choose</option>
                          <option value="Room">Room</option>
                          <option value="Cottage">Cottage</option>
                          <option value="Loghut">Loghut</option>
                          <option value="Tented Cottage">Tented Cottage</option>
                          <option value="Mud Hut">Mud Hut</option>
                        
                          <option value="Loghut">Loghut</option>
                        </select> */}
                <input
                  type="text"
                  className="title-90 mb-2"
                  name="singaleweekendprice"
                  value={roomType}
                  placeholder="Ex:-Cottage"
                  required=""
                  onChange={(e) => setroomType(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="pb-2 nd-2"> Number of {roomType}</label>
                <br />
                <input
                  type="number"
                  className="title-90 mb-2"
                  name="singaleweekendprice"
                  value={numberOfroom}
                  placeholder="Ex:-2"
                  required=""
                  onChange={(e) => setnumberOfroom(e.target.value)}
                />
              </div>
            </div>
            <label className="pb-2 nd-2"> Package Overview</label>
            <br />
            <CKEditor
              editor={ClassicEditor}
              data={packDisk}
              onChange={handlePackDisk}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
          </from>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setpackEShow(false)}>
            Back
          </Button>
          <Button variant="primary" onClick={() => editPackage()}>
            Update Now
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete package model  */}
      <Modal show={packDeleteS} onHide={() => setpackDeleteS(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete package?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setpackDeleteS(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deleteApiPackage}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddLodges;
