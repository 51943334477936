import { Filter } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import ReactPaginate from "react-paginate";

const Adminaddcarbrands = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [showDelete, setshowDelete] = useState(false);
  const handleDelete = () => setshowDelete(true);
  const closeDelete = () => setshowDelete(false);
  const [category, setcategory] = useState("");
  const [AllCategory, setAllCategory] = useState([]);
  const getAllCategory = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllLuxuryCarBrand"
      );
      if (res.status == 200) {
        setAllCategory(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    getAllCategory();
  },[])
  const addCarBrand = async () => {
    try {
      if (!category) return alert("Please enter car brand name");

      let am = AllCategory?.filter(
        (ele) => ele?.brandName?.toLowerCase() == category?.toLowerCase()
      );
      if (am.length !== 0) return alert(`${category}  already exits`);
      const config = {
        url: "/addLuxuryCarBrand",
        method: "post",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          brandName: category,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        handleClose();
        getAllCategory();
        setcategory("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [editdata,seteditdata]=useState({});
const updateCarBrand=async()=>{
    try {
        if (!category) return alert("Please enter car brand name");
  
        let am = AllCategory?.filter(
          (ele) => ele?.brandName?.toLowerCase() == category?.toLowerCase()
        );
        if (am.length !== 0) return alert(`${category}  already exits`);
        const config = {
          url: "/updateLuxuryCarBrand",
          method: "put",
          baseURL: "https://dhanyatours.me/api/admin",
          headers: { "content-type": "application/json" },
          data: {
            id:editdata?._id,
            brandName: category,
          },
        };
        let res = await axios(config);
        if (res.status == 200) {
          alert("Successfully updated");
          handleClose1();
          getAllCategory();
          setcategory("");
        }
      } catch (error) {
        console.log(error);
      }
}

const deleteCarBrand=async()=>{
    try {
        let res=await axios.delete("https://dhanyatours.me/api/admin/deleteLuxuryCarBrand/" + editdata?._id);
        if(res.status==200){
            alert("Successfully deleted");
            closeDelete()
            getAllCategory();
        }
    } catch (error) {
        console.log(error);
    }
} 

const [pageNumber, setPageNumber] = useState(0);
  
const usersPerPage = 10;
const pagesVisited = pageNumber * usersPerPage;
const pageCount = Math.ceil(AllCategory?.length / usersPerPage);
const changePage = ({ selected }) => {
  setPageNumber(selected);
};
  return (
    <>
      <div className="ms-5 mt-3">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h3> Car Brand</h3>
          </div>

          <div
            className="logde-op-00"
            style={{ cursor: "pointer" }}
            onClick={() => {
           
              handleShow();
            }}
          >
            + Car Brand
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Car Brand</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form>
                  <label className="pb-2 nd-2">Car Brand</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="driverBata"
                    placeholder="Enter car brand...."
                    value={category}
                    onChange={(e) => setcategory(e.target.value)}
                    required
                  />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Back
              </Button>
              <Button variant="primary" onClick={addCarBrand}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Update Car Brand</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form>
                  <label className="pb-2 nd-2">Car Brand</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="driverBata"
                    placeholder={editdata?.brandName}
                    value={category}
                    onChange={(e) => setcategory(e.target.value)}
                    required
                  />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>Close</Button>
              <Button variant="primary" onClick={updateCarBrand}>Submit</Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* <div className="searchHeadFlex">
                    <div className="searchWrapper" style={{ display: "flex" }}>
                        <input
                            type="text"
                            maxlength="230"
                            style={{ outlineWidth: "0px", padding: "4px" }}
                            className="textsearch"
                            placeholder="Search by vehicle"
                        // onChange={handleFilter}
                        />
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 16 16"
                            id="BsSearch"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                                clip-rule="evenodd"
                            ></path>
                            <path
                                fill-rule="evenodd"
                                d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </div>
                </div> */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Car Brand</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {AllCategory?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item, i) => {
              return (
                <tr>
                  <td>{i + 1}.</td>
                  <td>{item?.brandName}</td>
                  <td style={{ display: "flex", gap: "5px" }}>
                    <div
                      className=""
                      onClick={() => {
                        seteditdata(item)
                        handleShow1();
                      }}
                    >
                      <BiEdit
                        style={{
                          color: "blue",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        seteditdata(item)
                        handleDelete();
                      }}
                    >
                      <AiFillDelete
                        style={{
                          color: "red",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
      <Modal show={showDelete} onHide={closeDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete ??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <div style={{ float: "right" }}>
            <Button variant="danger" onClick={closeDelete}>
              Cancel
            </Button>{" "}
            <Button variant="success" onClick={deleteCarBrand}>Ok</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Adminaddcarbrands;
