import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";

function HotelGraph() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // getAllImage
  const [imge, setimge] = useState([]);

  const getTaxi = async () => {
    const config = {
      url: "getAllImage",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/jason" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setimge(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getTaxi();
  }, []);

  // postImage
  const [image1, setimage1] = useState("");
  const [name, setname] = useState("Hotel");

  const onTaxiadd = async () => {
    try {
      const config = {
        url: "/addImage",
        method: "post",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          image: image1,
          name: name,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
    
        alert("Successfully Added");
        // lodges();
        window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const [deleteId,setDeleteId]=useState("");
  const [showDel,setshowDel]=useState(false);

  const onTaxiDelete = async (id) => {
    // e.preventDefault();
    try {
      const config = {
        url: "/deleteImage/" + id,
        method: "delete",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        // sessionStorage.setItem("user", JSON.stringify(res.data.success));
        // history("/");
        alert("Successfully Deleted");
        // lodges();
        window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const [cat, setcat] = useState("Hotel");
  return (
    <div className="de-lod ms-5">
      <div
        className="mt-2 mb-3"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="logde-op">
          <h3> Graphic</h3>
        </div>

        <div
          className="logde-op-00"
          style={{
            cursor: "pointer",
            backgroundColor: cat == "Hotel" ? "red" : "",
          }}
          onClick={() => setcat("Hotel")}
        >
          Hotel
        </div>
        <div
          className="logde-op-00"
          style={{
            cursor: "pointer",
            backgroundColor: cat == "Resort" ? "red" : "",
          }}
          onClick={() => setcat("Resort")}
        >
          Resort
        </div>
        <div
          className="logde-op-00"
          style={{
            cursor: "pointer",
            backgroundColor: cat == "Home Stay" ? "red" : "",
          }}
          onClick={() => setcat("Home Stay")}
        >
          Home Stay
        </div>
        <div
          className="logde-op-00"
          style={{ cursor: "pointer" }}
          onClick={handleShow}
        >
          + Add
        </div>

        <Modal show={showDel} onHide={()=>setshowDel(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete?</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>setshowDel(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onTaxiDelete}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
 
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <form>
                <label className="pb-2 nd-2">Select Category</label>
                <br />
                <select
                  className="title-90"
                  onChange={(e) => setname(e.target.value)}
                  style={{ height: "40px" }}
                >
                  <option value="Hotel">Hotel</option>
                  <option value="Resort">Resort</option>
                  <option value="Home Stay">Home Stay</option>
                </select>
                <label className="pb-2 nd-2" htmlFor="uploade1">
                  Image
                </label>
                <br />
                <input
                  type="file"
                  className="title-90"
                  name="category"
                  id="uploade1"
                  accept="image/*"
                  required
                  onChange={(e) => setimage1(e.target.files[0])}
                />
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Back
            </Button>
            <Button variant="primary" onClick={() => onTaxiadd()}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Category</th>
            <th>Image</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {imge
            ?.filter((item) => item?.name === cat)
            ?.map((itmimg, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{itmimg?.name}</td>
                  <td>
                    <img
                      src={`https://dhanyatours.me/image/${itmimg.image}`}
                      alt="images"
                      style={{ width: "250px", height: "150px" }}
                    />
                  </td>
                  <td>
                    <div className="" onClick={() =>  {setDeleteId(itmimg?._id)
                       setshowDel(true)}}>
                      <AiFillDelete
                        style={{ color: "red", fontSize: "25px" }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}

export default HotelGraph;
