import { Filter } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import ReactPaginate from "react-paginate";

const AdminAddDrivers = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [showDelete, setshowDelete] = useState(false);
  const handleDelete = () => setshowDelete(true);
  const closeDelete = () => setshowDelete(false);

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [profile, setprofile] = useState("");
  const [dob, setdob] = useState("");
  const [gender, setgender] = useState("");
  const [licenseNo, setlicenseNo] = useState("");
  const [licenseImage, setlicenseImage] = useState("");
  const [adharno, setadharno] = useState("");
  // const [name,setname]=useState("");
  const [AllDriver, setAllDriver] = useState([]);
  const [nochangedata, setnochangedata] = useState("");

  const getAllDrivers = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/driver/getAllTransports"
      );
      if (res.status == 200) {
        setAllDriver(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllDrivers();
  }, []);

  const AddDrivers = async () => {
    try {
      const config = {
        url: "/registerTransports",
        method: "post",
        baseURL: "https://dhanyatours.me/api/driver",
        headers: { "content-type": "multipart/form-data" },
        data: {
          mobile: mobile,
          name: name,
          email: email,
          gender: gender,
          DateOfBirth: dob,
          LicenceNo: licenseNo,
          AadharNo: adharno,
          profile: profile,
          LicenceImage: licenseImage,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Added");
        handleClose();
        getAllDrivers();
        setname("")
        setmobile("")
        setemail("")
         setgender("")
          setdob("")
          setlicenseNo("")
          setlicenseImage("")
          setprofile("")
          setadharno("")
         

      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const [editdata,seteditdata]=useState({});
  const updateDriver=async()=>{
    try {
        const config = {
          url: "/editTransports",
          method: "put",
          baseURL: "https://dhanyatours.me/api/driver",
          headers: { "content-type": "multipart/form-data" },
          data: {
            mobile: mobile,
            name: name,
            email: email,
            gender: gender,
            DateOfBirth: dob,
            LicenceNo: licenseNo,
            AadharNo: adharno,
            profile: profile,
            LicenceImage: licenseImage,
            adminId:editdata?._id
          },
        };
        let res = await axios(config);
        if (res.status == 200) {
          alert("Successfully updated");
          handleClose1();
          getAllDrivers();
          setname("")
          setmobile("")
          setemail("")
           setgender("")
            setdob("")
            setlicenseNo("")
            setlicenseImage("")
            setprofile("")
            setadharno("")
        }
      } catch (error) {
        alert(error.response.data.error);
        console.log(error);
      }
  }

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllDriver.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const handleFilter = (e) => {
    if (e.target.value != "") {

      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllDriver([...filterTable]);
    } else {

        setAllDriver([...nochangedata]);
    }
  };

  const deleteDriver=async()=>{
    try {
        let res=await axios.delete("https://dhanyatours.me/api/driver/deleteTransports/"+editdata?._id);
        if(res.status==200){
            alert("Successfully deleted");
            closeDelete();
            getAllDrivers()
        }
    } catch (error) {
        console.log(error);
    }
  }
  return (
    <>
      <div className="ms-5 mt-3">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h3>Drivers</h3>
          </div>

          <div
            className="logde-op-00"
            style={{ cursor: "pointer" }}
            onClick={() => {
                setname("")
                setmobile("")
                setemail("")
                  setdob("")
                  setlicenseNo("")
                  setadharno("")
              handleShow();
            }}
          >
            + Drivers
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Drivers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form>
                  <label className="pb-2 nd-2" htmlFor="upload1">
                    Drivers Image
                  </label>
                  <br />
                  <input
                    type="file"
                    name="file"
                    id="upload1"
                    accept="image/*"
                    onChange={(e) => setprofile(e.target.files[0])}
                    className="title-90"
                  />
                  <label htmlFor="" className="pb-2 nd-2">
                    Driver Name
                  </label>
                  <br />
                  <input
                    type="text"
                    name=""
                    id=""
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    placeholder="Enter Driver Name..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Email{" "}
                  </label>
                  <br />
                  <input
                    type="email"
                    name=""
                    id=""
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    placeholder="Enter Email..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Mobile Number
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    maxLength={10}
                    value={mobile}
                    onChange={(e) => setmobile(e.target.value)}
                    placeholder="Enter Mobile Number..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Gender
                  </label>
                  <br />
                  <select
                    className="title-90"
                    onChange={(e) => setgender(e.target.value)}
                  >
                    <option value="">Select gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Date Of Birth
                  </label>
                  <br />
                  <input
                    type="date"
                    name=""
                    id=""
                    value={dob}
                    onChange={(e) => setdob(e.target.value)}
                    placeholder="Enter Date Of Birth..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Licence No
                  </label>
                  <br />
                  <input
                    type="text"
                    name=""
                    id=""
                    value={licenseNo}
                    onChange={(e) => setlicenseNo(e.target.value)}
                    placeholder="Enter Licence No..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Aadhar No
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    value={adharno}
                    onChange={(e) => setadharno(e.target.value)}
                    placeholder="Enter Aadhar No..."
                    className="title-90"
                  />

                  <br />
                  <label htmlFor="upload2" className="pb-2 nd-2">
                    Licence Image
                  </label>
                  <br />
                  <input
                    type="file"
                    name="file"
                    accept="image/*"
                    id="upload2"
                    onChange={(e) => setlicenseImage(e.target.files[0])}
                    placeholder="Enter Toll Tax..."
                    className="title-90"
                  />
                  <br />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Back
              </Button>
              <Button variant="primary" onClick={AddDrivers}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Update Drivers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form>
                  <label className="pb-2 nd-2" htmlFor="upload1">
                    Drivers Image
                  </label>
                  <br />
                  <input
                    type="file"
                    name="file"
                    id="upload1"
                    accept="image/*"
                    onChange={(e) => setprofile(e.target.files[0])}
                    className="title-90"
                  />
                  <label htmlFor="" className="pb-2 nd-2">
                    Driver Name
                  </label>
                  <br />
                  <input
                    type="text"
                    name=""
                    id=""
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    placeholder="Enter Driver Name..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Email{" "}
                  </label>
                  <br />
                  <input
                    type="email"
                    name=""
                    id=""
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    placeholder="Enter Email..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Mobile Number
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    maxLength={10}
                    value={mobile}
                    onChange={(e) => setmobile(e.target.value)}
                    placeholder="Enter Mobile Number..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Gender
                  </label>
                  <br />
                  <select
                    className="title-90"
                    onChange={(e) => setgender(e.target.value)}
                  >
                    <option value="">{editdata?.gender}</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Date Of Birth
                  </label>
                  <br />
                  <input
                    type="date"
                    name=""
                    id=""
                    value={dob}
                    onChange={(e) => setdob(e.target.value)}
                    placeholder="Enter Date Of Birth..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Licence No
                  </label>
                  <br />
                  <input
                    type="text"
                    name=""
                    id=""
                    value={licenseNo}
                    onChange={(e) => setlicenseNo(e.target.value)}
                    placeholder="Enter Licence No..."
                    className="title-90"
                  />
                  <br />
                  <label htmlFor="" className="pb-2 nd-2">
                    Aadhar No
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    value={adharno}
                    onChange={(e) => setadharno(e.target.value)}
                    placeholder="Enter Aadhar No..."
                    className="title-90"
                  />

                  <br />
                  <label htmlFor="upload2" className="pb-2 nd-2">
                    Licence Image
                  </label>
                  <br />
                  <input
                    type="file"
                    name="file"
                    accept="image/*"
                    id="upload2"
                    onChange={(e) => setlicenseImage(e.target.files[0])}
                    placeholder="Enter Toll Tax..."
                    className="title-90"
                  />
                  <br />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>Close</Button>
              <Button variant="primary" onClick={updateDriver}>Submit</Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="searchHeadFlex">
                    <div className="searchWrapper" style={{ display: "flex" }}>
                        <input
                            type="text"
                            maxlength="230"
                            style={{ outlineWidth: "0px", padding: "4px" }}
                            className="textsearch"
                            placeholder="Search by ...."
                        onChange={handleFilter}
                        />
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 16 16"
                            id="BsSearch"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                                clip-rule="evenodd"
                            ></path>
                            <path
                                fill-rule="evenodd"
                                d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </div>
                </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Drivers Image</th>
              <th>Driver Name</th>
              <th>E-Mail</th>
              <th>Mobile Number</th>
              <th>Gender</th>
              <th>Date Of Birth</th>
              <th>Licence No</th>
              <th>Aadhar No</th>
              <th>Licence Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {AllDriver?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item, i) => {
              return (
                <tr>
                  <td>{i + 1}.</td>
                  <td>
                    <a
                      href={"https://dhanyatours.me/transport/" + item?.profile}
                      target="_blank"
                    >
                      <img
                        src={
                          "https://dhanyatours.me/transport/" + item?.profile
                        }
                        width={70}
                        height={70}
                        style={{borderRadius:"50%"}}
                      />
                    </a>
                  </td>
                  <td>{item?.name}</td>
                  <td>{item?.email}</td>
                  <td>{item?.mobile}</td>
                  <td>{item?.gender}</td>
                  <td>{item?.DateOfBirth}</td>
                  <td>{item?.LicenceNo}</td>
                  <td>{item?.AadharNo}</td>
                  <td>
                    { item?.LicenceImage ? (  <a
                      href={"https://dhanyatours.me/transport/" + item?.LicenceImage}
                      target="_blank"
                    >
                      <img
                        src={
                          "https://dhanyatours.me/transport/" + item?.LicenceImage
                        }
                        width={70}
                        height={70}
                        // style={{borderRadius:"50%"}}
                      />
                    </a>):(<></>)}
                  
                  </td>
                  <td style={{ display: "flex", gap: "5px" }}>
                    <div
                      className=""
                      onClick={() => {
                        seteditdata(item)
                        setname(item?.name)
                        setmobile(item?.mobile)
                        setemail(item?.email)
                          setdob(item?.DateOfBirth)
                          setlicenseNo(item?.LicenceNo)
                     
                          setadharno(item?.AadharNo)
                        handleShow1();
                      }}
                    >
                      <BiEdit
                        style={{
                          color: "blue",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        seteditdata(item)
                        handleDelete();
                      }}
                    >
                      <AiFillDelete
                        style={{
                          color: "red",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
      <Modal show={showDelete} onHide={closeDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete ??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <div style={{ float: "right" }}>
            <Button variant="danger" onClick={closeDelete}>
              Cancel
            </Button>{" "}
            <Button variant="success" onClick={deleteDriver}>Ok</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminAddDrivers;
