import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
import OwlCarousel from "react-owl-carousel";
import { useLocation } from "react-router-dom";

function LuxuriTrain() {
  const [luxury, setluxury] = useState([]);
  const getluxuryImage = async () => {
    const config = {
      url: "/getAllImage",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/jason" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setluxury(result.data.success);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  useEffect(() => {
    getluxuryImage();
  }, []);

  useEffect(() => {
    getluxuryImage();
  }, []);

  const [gettrain, setgettrain] = useState([]);

  const getLuxuryTrain = async () => {
    const config = {
      url: "/getAlltrain",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setgettrain(result.data.success);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getLuxuryTrain();
  }, []);

  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  };
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [number, setnumber] = useState("");

  const [message, setmessage] = useState("");

  const onCreate = async () => {
    try {
      if (!user) {
        alert("Please enter full name!");
      } else if (!email) {
        alert("Please enter email!");
      } else if (!number) {
        alert("Please enter mobile number!");
      } else {
        const config = {
          url: "/createTrainBook",
          method: "post",
          baseURL: "https://dhanyatours.me/api/user",
          headers: { "conttent-type": "application/json" },
          data: {
            userName: user,
            email: email,
            number: number,
            message: message,
            isLuxury:true,
          },
        };
        const result = await axios(config);
        if (result.status === 200) {
          alert("Our team  will contact you soon");
          window.location.assign("/");
        } else {
          alert("Something went wrong");
        }
      }
    } catch (error) {
      console.log("erore", error);
      alert(error.response.data.error);
    }
  };
  const { state } = useLocation();

  const [distanation, setdistanation] = useState("");
  const [date, setdate] = useState("");

  useEffect(() => {
    if (state?.distanation) {
      setdistanation(state?.distanation);
      setdate(state?.date);
    }
  }, []);

  function areStringsEqual(str1, str2) {
    // Normalize and tokenize the strings
    const words1 = str1
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, " ")
      .trim()
      .split(" ");
    const words2 = str2
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, " ")
      .trim()
      .split(" ");

    // Check if any word from words1 exists in words2
    for (const word of words1) {
      if (words2.includes(word)) {
        return true;
      }
    }

    return false;
  }
  return (
    <div>
      <OwlCarousel
        className="owl-theme"
        loop
        margin={0}
        nav={false}
        dots={false}
        autoplay={true}
        responsive={responsive}
      >
        {luxury
          ?.filter((items) => items.name === "LuxuryTrain")
          ?.map((element) => {
            return (
              <div class="item">
                <div class="box">
                  <img
                    src={`https://dhanyatours.me/image/${element.image}`}
                    alt="Jungle Lodges and Resorts"
                    style={{ width: "100%", height: "300px" }}
                  />
                </div>
              </div>
            );
          })}
      </OwlCarousel>

      <div className="jungal">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="read-jungal mb-2">
                <h2 className="read-op">LUXURY TRAINS</h2>
                <p style={{ textAlign: "justify" }}>
                  Luxury train travel offers a host of benefits that make it a
                  unique and highly desirable way to explore various regions and
                  destinations around the world with by Dhanya Tours and Travels
                </p>
                <p style={{ textAlign: "justify" }}>
                  Traveling by train is a popular choice for both short commutes
                  and longer journeys, and for a good reason. With over a
                  thousand routes connecting cities in India, traveling by train
                  is the most convenient mode of travel. The combination of
                  price, convenience and speed makes train travel an ideal spot
                  for discerning travellers.
                </p>
              </div>
              <div className="read-jungal mb-2">
                <h4>All-Inclusive Luxury</h4>
                <p style={{ textAlign: "justify" }}>
                  We believe that your journey should be worry-free. Our
                  all-inclusive packages cover everything from accommodations
                  and dining to excursions and entertainment. Relax and enjoy
                  the journey while we take care of the details.
                </p>
                <h4>Book Your Luxury Train Adventure:</h4>
                <p>
                  Your journey of a lifetime begins here. Explore our upcoming
                  itineraries and secure your place aboard one of the world's
                  most prestigious luxury trains. Contact our dedicated team for
                  reservations or any inquiries you may have—we are here to make
                  your dream journey a reality.
                </p>
              </div>

              {gettrain
                ?.filter((ele) => ele?.isBloc === false)
                .filter((check) => {
                  if (distanation) {
                    if (areStringsEqual(check?.location, distanation)) {
                      return check;
                    }
                  } else {
                    return check;
                  }
                })
                .map((item) => {
                  return (
                    <div className="listing">
                      <div className="row">
                        <div className="col-md-4 col-xs-4 text-left">
                          <a href="#">
                            <img
                              class="img-responsive"
                              src={`https://dhanyatours.me/train/${item?.trainProfile}`}
                              alt="kabini jungle resort"
                            />
                          </a>
                        </div>
                        <div class="col-md-8 col-xs-8 text-left">
                          <div class="car-text">
                            <h3>
                              <a href={`/luxury-train/${item?._id}`}>
                                {item.category}
                              </a>
                            </h3>
                            <p>
                              <AiOutlineCalendar /> 6 Days/7 Nights
                              {/* {item?.trainTiming}  */}
                            </p>
                            <p>
                              <span class="hidden-xs">
                                <FaLocationDot /> {item?.location}
                              </span>
                            </p>
                            <p>
                              <span class="posted_by">
                                <i class="icon-briefcase"></i> Starts at ₹{" "}
                                {item?.StartingPrice}
                              </span>
                            </p>
                            <p>
                              <a
                                href={`/luxury-train/` + item?._id}
                                class="btn btn-primary btn-sm hidden-xs"
                              >
                                View Details
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="col-md-3">
              <div className="r-lg-0">
                <div
                  style={{
                    boxShadow: " 0px 14px 33px -9px rgba(0, 0, 0, 0.15)",
                    padding: "15px",
                    backgroundColor: "#004aad",
                    marginTop: "20px",
                    border: "1px solid #004aad",
                    borderRadius: "5px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    Want to book a trip or inquiry?
                  </span>
                  <form>
                    <div className="form-group pt-2">
                      <input
                        type="text"
                        class="form-control"
                        name="Last Name"
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                        placeholder="Full Name*"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        class="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email Id*"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        class="form-control"
                        name="mobileno"
                        value={number}
                        onChange={(e) => setnumber(e.target.value)}
                        placeholder="Mobile No*"
                      />
                    </div>
                    {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Select Package <span class="text-danger">*</span>
                          </label>
              <div className="col-md-12">
              <div class="form-group">
              <Form.Select onChange={(e)=>{
                           setpackageName(e.target.value)
                           setData(JSON.parse(e.target.value))
                          }}>
                            <option>Select package</option>
                            {priceing?.map((item) => {
                              return (
                                <option value={JSON.stringify(item)}>
                                  {item?.packageName}
                                </option>
                              );
                            })}
                          </Form.Select>
            </div>
              </div>
           
             
               </div> */}
                    {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Check In Date/Time <span class="text-danger">*</span>
                          </label>
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="date"
                class="form-control"
                name="Description"
                value={checkinDate}
                onChange={(e)=>setcheckinDate(e.target.value)}
             
              
              />
            </div>
              </div>
           
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="time"
                class="form-control"
               value={checkinTime}
               onChange={(e)=>setcheckinTime(e.target.value)}              
           
              />
            </div>
              </div>
               </div> */}
                    {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Check Out Date/Time <span class="text-danger">*</span>
                          </label>
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="date"
                class="form-control"
                          value={checkOutDate}
                          onChange={(e)=>setcheckOutDate(e.target.value)}
              />
            </div>
              </div>
           
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="time"
                class="form-control"
               value={checkOutTime}
               onChange={(e)=>setcheckOutTime(e.target.value)}
              />
            </div>
              </div>
               </div> */}
                    {/* <div className="row">
               <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Number Of Guest <span class="text-danger">*</span>
                          </label>
               <div className="col-md-6">
               <div class="form-group">
              <input
                type="number"
                class="form-control"
              value={numberOfguest}
              onChange={(e)=>setnumberOfguest(e.target.value)}
                placeholder="adults"
              />
            </div>
               </div>
               <div className="col-md-6">
               <div class="form-group">
              <input
                type="number"
                class="form-control"
              value={NumberOfChild}
              onChange={(e)=>setNumberOfchild(e.target.value)}
                placeholder="childs"
              />
            </div>
               </div>
               </div> */}

                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        value={message}
                        onChange={(e) => setmessage(e.target.value)}
                        placeholder="Message"
                      />
                    </div>

                    <div class="row" style={{ marginTop: "10px" }}>
                      <div class="col-sm-6">
                        <botton
                          class="btn btn-primary btn-sm hidden-xs"
                          onClick={onCreate}
                        >
                          Enquiry Now
                        </botton>
                        {/* <input
                  class="btn btn-primary btn-sm hidden-xs"
                  type="submit"
                  value="Book Now"
                /> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LuxuriTrain;
