import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { useParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

function Singallodge() {
  const [accord, setaccording] = useState(false);
  const [Loadeges, setLoadeges] = useState({});
  const [priceing, setpriceing] = useState([]);
  const [includes, setincludes] = useState([]);
  const [excludes, setexcludes] = useState([]);

  const { id } = useParams();

  // console.log(Loadeges);
  const [ITINERARY, setITINERARY] = useState([]);
  const getlodges = async () => {
    const config = {
      url: "/getbyLodgeId/" + id,
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setLoadeges(result.data.success);
        setpriceing(result.data.success?.PRICING);
        setincludes(result.data.success?.Includes);
        setexcludes(result.data.success?.Excludes);
        setITINERARY(result.data.success?.ITINERARY);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  const [gst, setgst] = useState({});
  const getGst = async () => {
    const config = {
      url: "/getGst",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setgst(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  useEffect(() => {
    getlodges();
    lodImage();
    getGst();
  }, []);

  const [lodgeslider, setlodgeslider] = useState([]);
  // console.log(lodgeslider, "success");
  const lodImage = async () => {
    const config = {
      url: "/getAllImage",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setlodgeslider(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  };

  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [number, setnumber] = useState("");
  const [packageName, setpackageName] = useState("");
  const [Occupancy, setOccupancy] = useState("");
  const [checkinDate, setcheckinDate] = useState("");
  const [checkinTime, setcheckinTime] = useState("");
  const [checkOutDate, setcheckOutDate] = useState("");
  const [checkOutTime, setcheckOutTime] = useState("");
  const [message, setmessage] = useState("");
  const [numberOfguest, setnumberOfguest] = useState("");
  let [packagePrice, setpackagePrice] = useState(0);
  const [NumberOfChild, setNumberOfchild] = useState("");

  let [data, setData] = useState({});
  const onCreate = async () => {
    try {
      if (!user) {
        alert("Please enter full name!");
      } else if (!email) {
        alert("Please enter email!");
      } else if (!number) {
        alert("Please enter mobile number!");
      } else if (!packageName) {
        alert("Please select package!");
      } else if (!Occupancy) {
        alert("Please select occupancy!");
      } else if (!checkinDate) {
        alert("Please select check in date!");
      } else if (!checkinTime) {
        alert("Please select check in time!");
      } else if (!checkOutDate) {
        alert("Please select check out date!");
      } else if (!checkOutTime) {
        alert("Please select check out time!");
      } else if (!numberOfguest) {
        alert("Please enter  number of guest!");
      } else {
        if (Occupancy === "Single Occupancy Weekday") {
          packagePrice = data?.singleWeekPrice;
        } else if (Occupancy === "Single Occupancy Weekend") {
          packagePrice = data?.singleWeekendPrice;
        } else if (Occupancy === "Double Occupancy Weekday") {
          packagePrice = data?.doublyWeekPrice;
        } else if (Occupancy === "Double Occupancy Weekend") {
          packagePrice = data?.doublyWeekendPrice;
        }
        console.log(
          "A==>",
          packagePrice,
          Math.round(
            packagePrice * numberOfguest * ((gst?.Sgst + gst?.Cgst) / 100)
          ),
          packagePrice * numberOfguest +
            Math.round(
              packagePrice * numberOfguest * ((gst?.Sgst + gst?.Cgst) / 100)
            )
        );
        const config = {
          url: "/createLodgeBook",
          method: "post",
          baseURL: "https://dhanyatours.me/api/user",
          headers: { "conttent-type": "application/json" },
          data: {
            lodgeId: Loadeges?._id,
            userName: user,
            email: email,
            number: number,
            Location: Loadeges?.location,
            checkInDate: checkinDate,
            checkInTime: checkinTime,
            checkOutDate: checkOutDate,
            checkOutTime: checkOutTime,
            NumberOfGuest: numberOfguest,
            message: message,
            packageName: JSON.parse(packageName)?.packageName,
            Occupancy: Occupancy,
            packagePrice: packagePrice,
            // gst: Math.round(
            //   packagePrice * numberOfguest * ((gst?.Sgst + gst?.Cgst) / 100)
            // ),
            totalAmount: packagePrice * numberOfguest,
            NumberOfChild: NumberOfChild,
          },
        };
        const result = await axios(config);
        if (result.status === 200) {
          alert("Successfully Booked");
          window.location.assign("/");
        } else {
          alert("Something went wrong");
        }
      }
    } catch (error) {
      console.log("erore", error);
      alert(error.response.data.error);
    }
  };

  return (
    <>
      <OwlCarousel
        className="owl-theme"
        loop
        margin={0}
        nav={false}
        dots={false}
        autoplay={true}
        responsive={responsive}
      >
        {Loadeges?.lodgeImage?.map((slider) => {
          return (
            <div class="item">
              <div class="box">
                <img
                  src={`https://dhanyatours.me/lodge/${slider?.image}`}
                  alt="Jungle Lodges and Resorts"
                  style={{ width: "100%", height: "300px" }}
                />
              </div>
            </div>
          );
        })}
      </OwlCarousel>

      <div className="inner-content-listing-desc" style={{ padding: "30px" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <h1 className="wr-0">{Loadeges.lodgeName}</h1>
              <p className="listing-wro">
                <span class="posted_by">
                  <i
                    class="icon-price-tag"
                    style={{ fontSize: "14px", color: "#66CC99" }}
                  ></i>{" "}
                  Starts from ₹ {Loadeges.StartingPrice}
                  /-&nbsp;&nbsp;&nbsp;&nbsp;
                  <i
                    class="icon-calendar"
                    style={{ fontSize: "14px", color: "#66CC99" }}
                  ></i>{" "}
                  {Loadeges.lodgeTiming} &nbsp;&nbsp;&nbsp;&nbsp;
                  <i
                    class="icon-location-pin"
                    style={{ fontSize: "14px", color: "#66CC99" }}
                  ></i>{" "}
                  {Loadeges.location}
                </span>
              </p>
              <h4 className="over-taxi">OVERVIEW</h4>
              <p>
                <span style={{ color: "black", fontWeight: "600" }}>
                  {" "}
                  {Loadeges.lodgeName}{" "}
                </span>
                &nbsp; {Loadeges?.OVERVIEW}
              </p>
              <div className="" style={{ overflowX: "auto" }}>
                <h4 className="over-taxi">PRICING</h4>
                {/* table */}
                <Table
                  class="table table-responsive table-bordered table-hover"
                  striped
                  bordered
                  hover
                  size="sm"
                >
                  <tbody>
                    <tr>
                      <td colspan="5">
                        <strong>
                          Per Person Per Night Rate's with includes
                          {/* {gst.Sgst + gst.Cgst}%.{" "} */}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        &nbsp;<strong>Package Name</strong>
                      </td>
                      <td>
                        <strong>Weekday</strong>
                      </td>
                      <td>
                        <strong>Weekend</strong>
                      </td>
                      <td>
                        <strong>Weekday</strong>
                      </td>
                      <td>
                        <strong>Weekend</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td colspan="2">
                        <strong>Double Occupancy</strong>
                      </td>
                      <td colspan="2">
                        <strong>Single Occupancy</strong>
                      </td>
                    </tr>
                    {priceing?.sort()?.map((ele) => {
                      return (
                        <tr>
                          <td>{ele?.packageName}</td>
                          <td>₹ {ele?.doublyWeekPrice}</td>
                          <td>₹ {ele?.doublyWeekendPrice}</td>
                          <td>₹ {ele?.singleWeekPrice}</td>
                          <td>₹ {ele?.singleWeekendPrice}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div className="">
                <h4 className="over-taxi">ITINERARY</h4>
                <div class="accordion-34">
                  <a href="#collapse2080" style={{ color: "#000000" }}>
                    <div class="accordion-group">
                      <div
                        onClick={() => {
                          setaccording(!accord);
                        }}
                        class="accordion-heading"
                        style={{
                          border: "1px solid #f0f0f0",
                          backgroundColor: "#f6f6f6",
                          padding: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {/* <i class="icon-chevron-with-circle-down"></i>{" "}
                        <strong>Day 1 : Day One</strong> */}
                      </div>
                      {!accord ? (
                        <>
                          <div
                            // id="collapse2080"
                            // class="accordion-body collapse"
                            style={{ marginBottom: "20px" }}
                          >
                            <div class="accordion-inner">
                              <div class="table-responsive">
                                <table
                                  class="table table-striped table-responsive table-bordered table-hover "
                                  striped
                                  bordered
                                  hover
                                  size="sm"
                                >
                                  <tbody>
                                    {ITINERARY?.map((item) => {
                                      return (
                                        <tr>
                                          <td width="150">{item?.time}</td>
                                          <td>{item?.details}</td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </a>
                </div>
              </div>
              {/* encludes */}
              <div className="">
                <h4 className="over-taxi">INCLUDES &amp; EXCLUDES</h4>
                <p>
                  <strong>Includes:</strong>
                </p>
                <ul style={{ listStyle: "unset" }}>
                  {includes?.map((eitem) => {
                    return <li>{eitem?.text}</li>;
                  })}
                </ul>
                <p>
                  <strong>Excludes:</strong>
                </p>

                <ul style={{ listStyle: "unset" }}>
                  {excludes.map((itemw) => {
                    return <li>{itemw.text1}</li>;
                  })}
                </ul>
              </div>
              <div className="according">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {" "}
                      <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                        Suggestion
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {" "}
                      <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                        TERMS &amp; CONDITIONS
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

            <div className="col-md-3">
              <div className="r-lg-0">
                <div
                  style={{
                    boxShadow: " 0px 14px 33px -9px rgba(0, 0, 0, 0.15)",
                    padding: "15px",
                    backgroundColor: "#004aad",
                    marginTop: "20px",
                    border: "1px solid #004aad",
                    borderRadius: "5px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    Want to book fill the form?
                  </span>
                  <form>
                    <div className="form-group pt-2">
                      <input
                        type="text"
                        class="form-control"
                        name="Last Name"
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                        placeholder="Full Name*"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        class="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email Id*"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        class="form-control"
                        name="mobileno"
                        value={number}
                        onChange={(e) => setnumber(e.target.value)}
                        placeholder="Mobile No*"
                      />
                    </div>
                    <div className="row">
                      <label
                        style={{
                          fontWeight: "600",
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        Select Package <span class="text-danger">*</span>
                      </label>
                      <div className="col-md-12">
                        <div class="form-group">
                          <Form.Select
                            onChange={(e) => {
                              setpackageName(e.target.value);
                              setData(JSON.parse(e.target.value));
                            }}
                          >
                            <option>Select package</option>
                            {priceing?.map((item) => {
                              return (
                                <option value={JSON.stringify(item)}>
                                  {item?.packageName}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div class="form-group">
                          <Form.Select
                            onChange={(e) => {
                              setOccupancy(e.target.value);
                            }}
                          >
                            <option>Select Occupancy</option>
                            <option value="Single Occupancy Weekday">
                              Single Occupancy Weekday{" "}
                            </option>
                            <option value="Double Occupancy Weekday">
                              Double Occupancy Weekday
                            </option>
                            <option value="Single Occupancy Weekend">
                              Single Occupancy Weekend{" "}
                            </option>
                            <option value="Double Occupancy Weekend">
                              Double Occupancy Weekend
                            </option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <label
                        style={{
                          fontWeight: "600",
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        Check In Date/Time <span class="text-danger">*</span>
                      </label>
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="date"
                            class="form-control"
                            name="Description"
                            value={checkinDate}
                            onChange={(e) => setcheckinDate(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="time"
                            class="form-control"
                            value={checkinTime}
                            onChange={(e) => setcheckinTime(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <label
                        style={{
                          fontWeight: "600",
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        Check Out Date/Time <span class="text-danger">*</span>
                      </label>
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="date"
                            class="form-control"
                            value={checkOutDate}
                            onChange={(e) => setcheckOutDate(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="time"
                            class="form-control"
                            value={checkOutTime}
                            onChange={(e) => setcheckOutTime(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <label
                        style={{
                          fontWeight: "600",
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        Number Of Guest <span class="text-danger">*</span>
                      </label>
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="number"
                            class="form-control"
                            value={numberOfguest}
                            onChange={(e) => setnumberOfguest(e.target.value)}
                            placeholder="adults"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="number"
                            class="form-control"
                            value={NumberOfChild}
                            onChange={(e) => setNumberOfchild(e.target.value)}
                            placeholder="childs"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        value={message}
                        onChange={(e) => setmessage(e.target.value)}
                        placeholder="Extra matter"
                      />
                    </div>

                    <div class="row" style={{ marginTop: "10px" }}>
                      <div class="col-sm-6">
                        <botton
                          class="btn btn-primary btn-sm hidden-xs"
                          onClick={onCreate}
                        >
                          Book Now
                        </botton>
                        {/* <input
                  class="btn btn-primary btn-sm hidden-xs"
                  type="submit"
                  value="Book Now"
                /> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Singallodge;
