import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  AiFillDelete,
  AiOutlineDelete,
  AiOutlineDownload,
} from "react-icons/ai";
import { FaEdit, FaEye } from "react-icons/fa";
import Table1 from "react-bootstrap/Table";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useLocation } from "react-router-dom";
import moment from "moment";

function BookResortSub() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [deleteId, setDeleteId] = useState("");
  // const location = useLocation;
  const [VechicleId, setVechicleId] = useState("");
  const [Username, setUsername] = useState("");
  const [Number, setNumber] = useState("");
  const [Email, setEmail] = useState("");
  const [PicKUpPlace, setPicKUpPlace] = useState("");
  const [PicKUpDate, setPicKUpDate] = useState("");
  const [DropPlace, setDropPlace] = useState("");
  const [DropDate, setDropDate] = useState("");
  const [DropTime, setDropTime] = useState("");
  const [Bata, setBata] = useState("");
  const [PackageV, setPackageV] = useState("");

  const [booktaxi, setbooktaxi] = useState([]);
  // console.log(graphImage, "orvih");
  const vendor = JSON.parse(sessionStorage.getItem("vendor"));
  const getBooking = async () => {
    const config = {
      url: "/getallHotelBookOrder",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setbooktaxi(result.data.success);
        getGst();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDelete = async () => {
    const config = {
      url: "/deleteHotelOrder/" + deleteId,
      method: "delete",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        getBooking();
        handleClose1();
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  const [gst, setgst] = useState({});
  const getGst = async () => {
    const config = {
      url: "/getGst",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setgst(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getBooking();
    getGst();
  }, []);


  const [bookingtaxi, setbookingtaxi] = useState({});

  let [PaymentMethod, setPaymentMethod] = useState("");
  let [GST, setGST] = useState(0);
  let [Total, setTotal] = useState(0);
  let [NumberOfchild, setNumberOfchild] = useState(0);
  let [Extramater, setExtraKm] = useState(0);
  let [numberOfguest, setnumberOfguest] = useState(0);
  let [extraKmPrice, setkmprice] = useState(0);
  const editBookingTaxi = async () => {
    let subTotal = 0;
    if (numberOfguest) {
      subTotal =
        numberOfguest * bookingtaxi?.packagePrice + parseInt(Extramater);
      GST = Math.round(subTotal * ((gst?.Cgst + gst?.Sgst) / 100));
      Total = subTotal + GST;
    } else {
      subTotal =
        bookingtaxi?.NumberOfGuest * bookingtaxi?.packagePrice +
        parseInt(Extramater);
      GST = Math.round(subTotal * ((gst?.Cgst + gst?.Sgst) / 100));
      Total = subTotal + GST;
    }

   
    try {
      const config = {
        url: "/editbookHotelOrder",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          bookId: bookingtaxi?._id,
          totalAmount: Total,
          paymentMethod: PaymentMethod,
          gst: GST,
          NumberOfChild: NumberOfchild,
          NumberOfGuest: numberOfguest,
          extaraMatter: Extramater,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Success");
        getBooking();
        handleClose();
        setExtraKm(0);
        setnumberOfguest(0);
        setNumberOfchild(0);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert("error");
        alert(error.response.data.error);
      }
    }
  };

  const makeConfirm = async (id) => {
    try {
      const config = {
        url: "/makeOderStatusChangeHotelBook",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          orderId: id,
          status: "Confirm",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getBooking();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const makeCancel = async (id) => {
    try {
      const config = {
        url: "/makeOderStatusChangeHotelBook",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          orderId: id,
          status: "Cancel",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getBooking();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const makeComplete = async (id) => {
    try {
      const config = {
        url: "/makeOderStatusChangeHotelBook",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          orderId: id,
          status: "Complete",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getBooking();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  //getVehicleById
  const createPDF = async () => {
    const input = document.getElementById("pdf");
    const options = { scrollY: -window.scrollY };
    const canvas = await html2canvas(input, options);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    pdf.save("bookingDetails.pdf");
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    booktaxi
      ?.filter((ele) => ele?.lodgeId?.category === "HomeStay")
      ?.filter((a) => a?.lodgeId?.wenderId === vendor?._id)?.length /
      usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = booktaxi?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setbooktaxi([...booktaxi]);
    }
  };
  return (
    <>
      <div className="de-lod">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h3>Resort Hotel Booking Details</h3>
          </div>
        </div>

        <Modal show={show} onHide={handleClose} id="pdf">
          <Modal.Header
            closeButton
            style={{
              backgroundColor: "#2fdbeb",
              color: "white",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            Resort Hotel Booking Details
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <form>
                <label className="pb-2 nd-2">BookId{" :"}</label>{" "}
                <span>{bookingtaxi?._id}</span>
                <br />
                <label className="pb-2 nd-2">Name {" :"}</label>{" "}
                <span>{bookingtaxi?.userName}</span> <br />
                <label className="pb-2 nd-2">Number{" :"}</label>{" "}
                <span> {bookingtaxi?.number}</span>
                <br />
                <label className="pb-2 nd-2">Email{" :"}</label>{" "}
                <span>{bookingtaxi?.email}</span>
                <br />
                <label className="pb-2 nd-2">Location{" :"}</label>{" "}
                {bookingtaxi?.Location}
                <br />
                <label className="pb-2 nd-2">Message{" :"}</label>{" "}
                {bookingtaxi?.message}
                <TableContainer>
                  <Table variant="striped" colorScheme="teal">
                    <TableCaption></TableCaption>

                    <Tbody>
                      {/* <Tr>
                      <Td>Trip Type</Td>
                      <Td>{bookingtaxi?.tripType}</Td>
                    </Tr> */}
                      <Tr>
                        <Td>Hotel Name</Td>
                        <Td>{bookingtaxi?.lodgeId?.hotalName}</Td>
                      </Tr>
                      <Tr>
                        <Td>HotelId</Td>
                        <Td>{bookingtaxi?.lodgeId?._id}</Td>
                      </Tr>
                      <Tr>
                        <Td>Hotel Contact Number</Td>
                        <Td>{bookingtaxi?.lodgeId?.contact}</Td>
                      </Tr>
                      <Tr>
                        <Td>Hotel Email</Td>
                        <Td>{bookingtaxi?.lodgeId?.email}</Td>
                      </Tr>
                      <Tr>
                        <Td>Package Name</Td>
                        <Td>{bookingtaxi?.packageName}</Td>
                      </Tr>

                      <Tr>
                        <Td>Check In Date/Time</Td>
                        <Td>
                          {" "}
                          {moment(bookingtaxi?.checkInDate).format(
                            "DD/MM/YYYY"
                          )}
                          {"  "}
                          {moment(bookingtaxi?.checkInTime, [
                            "HH:mm:ss",
                          ]).format("hh:mm a")}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Check Out Date/time</Td>
                        <Td>
                          {moment(bookingtaxi?.checkOutDate).format(
                            "DD/MM/YYYY"
                          )}
                          {"  "}
                          {moment(bookingtaxi?.checkOutTime, [
                            "HH:mm:ss",
                          ]).format("hh:mm a")}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Package Price/Adults ₹</Td>
                        <Td>{bookingtaxi?.packagePrice}.00</Td>
                      </Tr>
                      <Tr>
                        <Td>Number Of Adults</Td>
                        <Td>{bookingtaxi?.NumberOfGuest}</Td>
                      </Tr>
                      {bookingtaxi?.status === "Confirm" ? (
                        <Tr>
                          <Td>Number Of Adults</Td>
                          <Td>
                            {" "}
                            <input
                              type="number"
                              className="title-90"
                              name="lodgename"
                              value={numberOfguest}
                              placeholder="Ex:- 3"
                              onChange={(e) => setnumberOfguest(e.target.value)}
                            />
                          </Td>
                        </Tr>
                      ) : (
                        <></>
                      )}
                      <Tr>
                        <Td>Number Of Childs</Td>
                        <Td>{bookingtaxi?.NumberOfChild}</Td>
                      </Tr>
                      {bookingtaxi?.status === "Confirm" ? (
                        <Tr>
                          <Td>Number Of Childs</Td>
                          <Td>
                            {" "}
                            <input
                              type="number"
                              className="title-90"
                              name="lodgename"
                              value={NumberOfchild}
                              placeholder="Ex:- 3"
                              onChange={(e) => setNumberOfchild(e.target.value)}
                            />
                          </Td>
                        </Tr>
                      ) : (
                        <></>
                      )}
                      <Tr>
                        <Td>Extra Matter Price ₹</Td>
                        <Td>{bookingtaxi?.extaraMatter}</Td>
                      </Tr>
                      {bookingtaxi?.status === "Confirm" ? (
                        <Tr>
                          <Td>Extra Matter Price ₹</Td>
                          <Td>
                            {" "}
                            <input
                              type="number"
                              className="title-90"
                              name="lodgename"
                              value={Extramater}
                              placeholder="Ex:- 1200"
                              onChange={(e) => setExtraKm(e.target.value)}
                            />
                          </Td>
                        </Tr>
                      ) : (
                        <></>
                      )}
                      <Tr>
                        <Td>GST ₹</Td>
                        <Td>{bookingtaxi?.gst}.00</Td>
                      </Tr>

                      <Tr>
                        <Td>Total Price ₹</Td>
                        <Td>{bookingtaxi?.totalAmount}.00</Td>
                      </Tr>
                      <Tr>
                        <Td>Status</Td>
                        <Td>{bookingtaxi?.status}</Td>
                      </Tr>
                      <Tr>
                        <Td>Payment</Td>
                        <Td>{bookingtaxi?.paymentMethod}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <AiOutlineDownload
              style={{
                color: "red",
                cursor: "pointer",
                fontSize: "36px",
                float: "right",
              }}
              onClick={() => createPDF()}
            />
            {bookingtaxi?.status === "Complete" ? (
              <></>
            ) : (
              <>
                {bookingtaxi?.status === "Confirm" ? (
                  <>
                    <Button variant="primary" onClick={editBookingTaxi}>
                      Submit
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        makeCancel(bookingtaxi?._id);
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => {
                        makeComplete(bookingtaxi?._id);
                        handleClose();
                      }}
                    >
                      Complete
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="warning"
                    onClick={() => {
                      makeConfirm(bookingtaxi?._id);
                      handleClose();
                    }}
                  >
                    Confirm
                  </Button>
                )}
              </>
            )}

            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete?</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <Button variant="primary" onClick={getDelete}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="taxi-table">
          <div className="searchHeadFlex">
            <div className="searchWrapper" style={{ display: "flex" }}>
              <input
                type="text"
                maxlength="230"
                style={{ outlineWidth: "0px", padding: "4px" }}
                className="textsearch"
                placeholder="Search by lodge"
                onChange={handleFilter}
              />
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 16 16"
                id="BsSearch"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                  clip-rule="evenodd"
                ></path>
                <path
                  fill-rule="evenodd"
                  d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
          <Table1 striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Hotel name</th>
                <th>User Name</th>
                <th>Number</th>
                <th>CheckIn Date/Time</th>
                <th>CheckOut Date/Time</th>
                <th>Package Name</th>
                <th>Occupancy</th>

                <th>Package Price ₹</th>

                <th>Number of Adults</th>
                <th>Total Amount</th>
                <th>Location</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {search?.length > 0 ? (
                tableFilter
                  ?.filter((ele) => ele?.lodgeId?.category === "Resort")
                  ?.filter((a) => a?.lodgeId?.wenderId === vendor?._id)
                  ?.slice(pagesVisited, pagesVisited + usersPerPage)
                  ?.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <th>{item?.lodgeId?.hotalName}</th>
                        <td>{item?.userName}</td>
                        <td>{item?.number}</td>
                        <td>
                          {moment(item?.checkInDate).format("DD/MM/YYYY")}
                          {"  "}
                          {moment(item?.checkInTime, ["HH:mm:ss"]).format(
                            "hh:mm a"
                          )}{" "}
                        </td>
                        <td>
                          {moment(item?.checkOutDate).format("DD/MM/YYYY")}
                          {"  "}
                          {moment(item?.checkOutTime, ["HH:mm:ss"]).format(
                            "hh:mm a"
                          )}{" "}
                        </td>
                        <td>{item?.packageName}</td>
                        <td>{item?.Occupancy}</td>
                        <td>{item?.packagePrice}</td>
                        <td>{item?.NumberOfGuest}</td>
                        <td>{item?.totalAmount}.00</td>
                        <th>{item?.Location}</th>
                        <td>{item?.status}</td>
                        {item?.status === "Pending" ? (
                          <td>
                            <div style={{ display: "flex", gap: "10px" }}>
                              <FaEye
                                style={{
                                  fontSize: "26px",
                                  color: " #5600ff",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleShow();
                                  setbookingtaxi(item);
                                }}
                              />
                              <button
                                type="button"
                                class="btn btn-success"
                                onClick={() => makeConfirm(item?._id)}
                              >
                                Confirm
                              </button>{" "}
                              <button
                                type="button"
                                class="btn btn-danger"
                                onClick={() => makeCancel(item?._id)}
                              >
                                Cancel
                              </button>
                            </div>
                          </td>
                        ) : (
                          <td>
                            <div style={{ display: "flex", gap: "10px" }}>
                              <span>
                                <FaEye
                                  style={{
                                    color: "#5600ff",
                                    cursor: "pointer",
                                    fontSize: "26px",
                                  }}
                                  onClick={() => {
                                    handleShow();
                                    setbookingtaxi(item);
                                  }}
                                />
                              </span>
                              <span>
                                <AiOutlineDelete
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                    fontSize: "26px",
                                  }}
                                  onClick={() => {
                                    setDeleteId(item?._id);
                                    handleShow1();
                                  }}
                                />
                              </span>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })
              ) : (
                <>
                  {booktaxi
                    ?.filter((ele) => ele?.lodgeId?.category === "Resort")
                    ?.filter((a) => a?.lodgeId?.wenderId === vendor?._id)
                    ?.slice(pagesVisited, pagesVisited + usersPerPage)
                    ?.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <th>{item?.lodgeId?.hotalName}</th>
                          <td>{item?.userName}</td>
                          <td>{item?.number}</td>
                          <td>
                            {moment(item?.checkInDate).format("DD/MM/YYYY")}
                            {"  "}
                            {moment(item?.checkInTime, ["HH:mm:ss"]).format(
                              "hh:mm a"
                            )}{" "}
                          </td>
                          <td>
                            {moment(item?.checkOutDate).format("DD/MM/YYYY")}
                            {"  "}
                            {moment(item?.checkOutTime, ["HH:mm:ss"]).format(
                              "hh:mm a"
                            )}{" "}
                          </td>
                          <td>{item?.packageName}</td>
                          <td>{item?.Occupancy}</td>
                          <td>{item?.packagePrice}</td>
                          <td>{item?.NumberOfGuest}</td>
                          <td>{item?.totalAmount}.00</td>
                          <th>{item?.Location}</th>
                          <td>{item?.status}</td>
                          {item?.status === "Pending" ? (
                            <td>
                              <div style={{ display: "flex", gap: "10px" }}>
                                <FaEye
                                  style={{
                                    fontSize: "26px",
                                    color: " #5600ff",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleShow();
                                    setbookingtaxi(item);
                                  }}
                                />
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  onClick={() => makeConfirm(item?._id)}
                                >
                                  Confirm
                                </button>{" "}
                                <button
                                  type="button"
                                  class="btn btn-danger"
                                  onClick={() => makeCancel(item?._id)}
                                >
                                  Cancel
                                </button>
                              </div>
                            </td>
                          ) : (
                            <td>
                              <div style={{ display: "flex", gap: "10px" }}>
                                <span>
                                  <FaEye
                                    style={{
                                      color: "#5600ff",
                                      cursor: "pointer",
                                      fontSize: "26px",
                                    }}
                                    onClick={() => {
                                      handleShow();
                                      setbookingtaxi(item);
                                    }}
                                  />
                                </span>
                                <span>
                                  <AiOutlineDelete
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                      fontSize: "26px",
                                    }}
                                    onClick={() => {
                                      setDeleteId(item?._id);
                                      handleShow1();
                                    }}
                                  />
                                </span>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </Table1>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </div>
    </>
  );
}

export default BookResortSub;
