import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import moment from "moment";
import { BiEdit } from "react-icons/bi";
function AdminExpense() {
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShow1 = () => setShow1(true);
    const handleClose1 = () => setShow1(false);
    const [show4, setShow4] = useState(false);
    const handleShow4 = () => setShow4(true);
    const handleClose4 = () => setShow4(false);
    const [ExpanseList, setExpansList] = useState(false);
const [expanseType,setexpanseType]=useState("");
const [remarks,setremarks]=useState("")
const [date,setdate]=useState("")
const [amount,setamount]=useState("")
const [AllExpanseType,setAllExpanseType]=useState([])
const [AllExpanse,setAllExpanse]=useState([])

const getAllExpanseType=async()=>{
    try {
        let res=await axios.get("https://dhanyatours.me/api/admin/getAllexpanseType");
        if(res.status==200){
            setAllExpanseType(res.data.success);
        }
    } catch (error) {
        console.log(error);
    }
}

const getAllExpanse=async()=>{
    try {
        let res=await axios.get("https://dhanyatours.me/api/admin/getAllExpanse");
        if(res.status==200){
            setAllExpanse(res.data.success);
        }
    } catch (error) {
        console.log(error);
    }
}

useEffect(()=>{
    getAllExpanseType()
    getAllExpanse()
},[]);

const AddExpanseType=async()=>{
    try {
        const config={
        url:"/addExpanseType",
        method:"post",
        baseURL:"https://dhanyatours.me/api/admin",
        headers:{"content-type":"application/json"},
        data:{
            expanseType:expanseType
        }
        }
        let res=await axios(config);
        if(res.status==200){
            alert("Successfully Added");
            handleClose3();
            setexpanseType("");
            getAllExpanseType()
            setExpansList(true);
        }
    } catch (error) {
        console.log(error);
        alert(error.response.data.error)
    }
}

const AddExpanse=async()=>{
    if(!expanseType) return alert("Please select Expanse type");
    if(!remarks) return alert("Please enter remarks")
    if(!date) return alert("Please select date")
    if(!amount) return alert("Please enter amount")

    try {
        const config={
        url:"/AddExpanse",
        method:"post",
        baseURL:"https://dhanyatours.me/api/admin",
        headers:{"content-type":"application/json"},
        data:{
            expanseType:expanseType,
            remarks:remarks,
            date:date,
            amount:amount,
        }
        }
        let res=await axios(config);
        if(res.status==200){
            alert("Successfully Added");
            handleClose();
            getAllExpanse()
            setExpansList(false);
            setexpanseType("");
            setamount("");
            setremarks("");
            setdate(moment().format("YYYY-MM-DD"))
        }
    } catch (error) {
        console.log(error);
        alert(error.response.data.error)
    }
}
const [edit,setedit]=useState({})
const updateExpanse=async()=>{
    try {
        const config={
        url:"/UpdateExpanse",
        method:"put",
        baseURL:"https://dhanyatours.me/api/admin",
        headers:{"content-type":"application/json"},
        data:{
            id:edit?._id,
            expanseType:expanseType,
            remarks:remarks,
            date:date,
            amount:amount,
        }
        }
        let res=await axios(config);
        if(res.status==200){
            alert("Successfully updated");
            handleClose1();
            getAllExpanse()
            setExpansList(false);
            setexpanseType("");
            setamount("");
            setremarks("");
            setdate(moment().format("YYYY-MM-DD"))
        }
    } catch (error) {
        console.log(error);
        alert(error.response.data.error)
    }
}
const [deleteId,setDeleteId]=useState("")

const deleteExpanse=async()=>{
    try {
        if(ExpanseList){
            let res=await axios.delete("https://dhanyatours.me/api/admin/deleteExpanseType/"+deleteId);
            if(res.status==200){
                alert("Successfully deleted");
                handleClose4();
               return getAllExpanseType()
            }
        }else{
            let res=await axios.delete("https://dhanyatours.me/api/admin/deleteExpanse/"+deleteId);
            if(res.status==200){
                alert("Successfully deleted");
                handleClose4();
                return  getAllExpanse()
            }

        }
      
    } catch (error) {
        console.log(error);
    }
}

const [pageNumber, setPageNumber] = useState(0);

const usersPerPage = 10;
const pagesVisited = pageNumber * usersPerPage;
const pageCount = Math.ceil(AllExpanse?.length / usersPerPage);
const changePage = ({ selected }) => {
  setPageNumber(selected);
};

const [pageNumber1, setPageNumber1] = useState(0);

const usersPerPage1 = 10;
const pagesVisited1 = pageNumber1 * usersPerPage1;
const pageCount1 = Math.ceil(AllExpanseType?.length / usersPerPage1);
const changePage1 = ({ selected }) => {
  setPageNumber1(selected);
};
    return (
        <>
            <div className="ms-5 mt-4">
                <div className="container mb-3">
                    <div className="row">
                        <div className="col-md-6 ">
                            <div className="logde-op">
                                <h2 style={{ fontSize: "25px" }}>Expense Details</h2>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="logde-op-00" onClick={()=>{
                                             setexpanseType("");
                                             setamount("");
                                             setremarks("");
                                             setdate(moment().format("YYYY-MM-DD"))
                                        handleShow()}} style={{ cursor: "pointer" }}>
                                        +Expense
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="logde-op-00" onClick={handleShow3} style={{ cursor: "pointer" }}>
                                        +Expense Type

                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div >
                                        {ExpanseList ? (
                                            <div className="logde-op-00" onClick={() => {
                                                setExpansList(false);
                                            }} style={{ cursor: "pointer" }}>
                                                Expense List

                                            </div>

                                        ) : (
                                            <div className="logde-op-00" onClick={() => {
                                                setExpansList(true);
                                            }} style={{ cursor: "pointer" }}>
                                                Expense Type List
                                            </div>

                                        )}
                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>
                </div>
                <div className="container">
                    {ExpanseList ? (
                        <div className="scrolll_bar">
                            <Table striped bordered hover style={{ textAlign: "center" }}>
                                <thead>
                                    <tr>
                                        <th>S.No</th>

                                        <th>Expense Type </th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {AllExpanseType?.slice(pagesVisited1, pagesVisited1 + usersPerPage1)?.map((item,i)=>{
                                    return ( <tr>
                                        <td>{i+1}.</td>
                                        <td>{item?.expanseType}</td>
                                        <td>
                                            <Button
                                                type="button"
                                                variant="danger"
                                                onClick={() => {
                                                    setDeleteId(item?._id)
                                                    handleShow4();
                                                }}
                                            >
                                                <span>
                                                    <RiDeleteBin6Line style={{ fontSize: "25" }} />
                                                </span>
                                            </Button>
                                        </td>
                                    </tr>)
                                })}
                                   

                                </tbody>
                            </Table>
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                pageCount={pageCount1}
                                onPageChange={changePage1}
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                            />
                        </div>
                    ) : (
                        <div className="scrolll_bar">
                            <Table striped bordered hover style={{ textAlign: "center" }}>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Expense Type </th>
                                        <th>Remarks</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {AllExpanse?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                                    return ( <tr>
                                        <td>{i+1}.</td>

                                        <td>{item?.expanseType}</td>
                                        <td>{item?.remarks}</td>
                                        <td>{item?.date}</td>
                                        <td>{item?.amount}</td>
                                        <td>
                                            <BiEdit
                                               
                                                style={{color:"blue",cursor:"pointer",fontSize:"45px"}}
                                                onClick={() => {
                                                    setedit(item);
                                                    setremarks(item?.remarks);
                                                    setexpanseType(item?.expanseType);
                                                    setdate(item?.date);
                                                    setamount(item?.amount)
                                                    handleShow1();
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Button
                                                type="button"
                                                variant="danger"
                                                onClick={() => {
                                                    setDeleteId(item?._id)
                                                    handleShow4();
                                                }}
                                            >
                                                <span>
                                                    <RiDeleteBin6Line style={{ fontSize: "25" }} />
                                                </span>
                                            </Button>
                                        </td>
                                    </tr>)
                                })}
                                   

                                </tbody>
                            </Table>
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                pageCount={pageCount}
                                onPageChange={changePage}
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                            />
                        </div>
                    )}
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Expanse</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="do-sear mt-2">
                        <label className="pb-2 nd-2">Expense Type</label>
                        <select
                            className="title-90"
                        onChange={(e) => setexpanseType(e.target.value)}
                        >
                            <option value="">Select</option>
                            {AllExpanseType?.map((ele)=>{
                                return (<option value={ele?.expanseType}>{ele?.expanseType}</option>)
                            })}
                        </select>
                    </div>
                    <div className="do-sear mt-2">
                        <label className="pb-2 nd-2">Remarks</label>
                        <input
                            type="text"
                            value={remarks}
                            onChange={(e) => setremarks(e.target.value)}
                            className="title-90"
                            placeholder="Enter Your remarks"
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label className="pb-2 nd-2">Date</label>
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setdate(e.target.value)}
                            className="title-90"
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label className="pb-2 nd-2">Amount</label>
                        <input
                            type="number"
                            min={1}
                            value={amount}
                            onChange={(e) => setamount(e.target.value)}
                            className="title-90"
                            placeholder="Enter amount"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={AddExpanse} >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={show3}
                onHide={handleClose3}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Expense Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="do-sear mt-2">
                            <label className="pb-2 nd-2">Expense Type</label>
                            <input
                                type="text"
                                value={expanseType}
                                onChange={(e) => setexpanseType(e.target.value)}
                                className="title-90"
                                placeholder="Please enter expanse type"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose3}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={AddExpanseType}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* edit Model */}
            <Modal
                show={show1}
                onHide={handleClose1}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Updated Expense</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="do-sear mt-2">
                        <label className="pb-2 nd-2">Expanse Type</label>
                        <select
                            className="title-90"
                        onChange={(e) => setexpanseType(e.target.value)}
                        >
                            <option value={expanseType}>{expanseType}</option>
                            {AllExpanseType?.map((ele)=>{
                                return (<option value={ele?.expanseType}>{ele?.expanseType}</option>)
                            })}
                        </select>
                        {/* <input type="text" className="title-90" placeholder="Please enter question" /> */}
                    </div>
                    <div className="do-sear mt-2">
                        <label className="pb-2 nd-2" >Remarks</label>
                        <input
                            type="text"
                            value={remarks}
                            onChange={(e) => setremarks(e.target.value)}
                            className="title-90"
                        // placeholder={edit?.remarks}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label className="pb-2 nd-2">Date</label>
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setdate(e.target.value)}
                            className="title-90"
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label className="pb-2 nd-2">Amount</label>
                        <input
                            type="number"
                            min={1}
                            value={amount}
                            onChange={(e) => setamount(e.target.value)}
                            className="title-90"
                        // placeholder={edit?.amount}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={updateExpanse} >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* deleteMode */}
            <Modal
                show={show4}
                onHide={handleClose4}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure delete??</Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose4}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={deleteExpanse}>
                        Ok
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AdminExpense;
