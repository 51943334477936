import { Autocomplete, TextField } from "@mui/material";
import { DatePicker, TimePicker } from "antd";
import axios from "axios";
import moment from "moment";
// import { setDate } from "date-fns";
import React, { useEffect, useState } from "react";
import { Form, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function TaxiBooking() {
  const [city, setcity] = useState("");
  const [airport, setairport] = useState(true);
  const [localdrop, setlocaldrop] = useState(false);
  const [outstation, setoutsation] = useState(false);
  const [Luxury, setLuxury] = useState(false);

  // const [airports, setairports] = useState("");
  const [pickupplace, setpickupplace] = useState("");
  const [dropplace, setdropplace] = useState("");
  const [pickdate, setpickdate] = useState("");
  const [picktime, setpicktime] = useState("");
  // const [Localdrop, setLocaldrop] = useState("");
  const [pickupplace1, setpickupplace1] = useState("");
  const [dropplace1, setdropplace1] = useState("");
  const [pickdate1, setpickdate1] = useState("");
  const [picktime1, setpicktime1] = useState("");
  // const [outstation1, setoutstation1] = useState("");
  const [pickupplace2, setpickupplace2] = useState("");
  const [dropplace2, setdropplace2] = useState("");
  const [pickdate2, setpickdate2] = useState("");
  const [picktime2, setpicktime2] = useState("");
  const [trip, setTrip] = useState({});
  // console.log(pickdate, "success");
  // console.log(picktime, "success");
  const [packageType, setpackageType] = useState("");
  const [packagePrice, setpackagePrice] = useState("");
  const [driverBata, setdriverBata] = useState("");
  // console.log("price,==>",packageType,packagePrice,driverBata,picktime)
  let piTime = `${picktime?.$H}:${picktime?.$m}:${picktime?.$s}`;
  // console.log(piTime, "4frgog");
  let piTime1 = `${picktime1?.$H}:${picktime1?.$m}:${picktime1?.$s}`;
  let piTime2 = `${picktime2?.$H}:${picktime2?.$m}:${picktime2?.$s}`;
  let night = false;

  const airportCheck = () => {
    if (!pickupplace) {
      alert("Please enter your pickup place!");
    } else if (!dropplace) {
      alert("Please enter your drop place!");
    } else if (!pickdate) {
      alert("Please select your date!");
    } else if (!picktime?.$H) {
      alert("Please select your time!");
    } else {
      if (20 <= picktime?.$H || 6 >= picktime?.$H) {
        night = true;
      }
      let airportObj = {
        tripType: "Airport",
        pickupplace: pickupplace,
        dropplace: dropplace,
        pickdate: pickdate,
        picktime: piTime,
        packageType: packageType,
        packagePrice: packagePrice,
        driverBata: driverBata,
        night: night,
        city: city,
      };
      history("/taxi-here", { state: airportObj });
    }
  };
  const localCheck = () => {
    if (!pickupplace1) {
      alert("Please enter your pickup place!");
    } else if (!dropplace1) {
      alert("Please enter your drop place!");
    } else if (!packageType) {
      alert("Please select package type!");
    } else if (!pickdate1) {
      alert("Please select your date!");
    } else if (!picktime1?.$H) {
      alert("Please select your time!");
    } else {
      if (20 <= picktime1?.$H || 6 >= picktime1?.$H) {
        night = true;
      }
      let localObj = {
        tripType: "Local Drop",
        pickupplace: pickupplace1,
        dropplace: dropplace1,
        pickdate: pickdate1,
        picktime: piTime1,
        packageType: packageType,
        packagePrice: packagePrice,
        driverBata: driverBata,
        night: night,
        city: city,
      };
      history("/taxi-here", { state: localObj });
    }
  };

  const checkOustsation = () => {
    if (!pickupplace2) {
      return alert("Please enter your pickup place!");
    }
    if (!dropplace2) {
      return "Please enter your drop place!";
    }
    if (!pickdate2) {
      return alert("Please select your date!");
    }
    if (!picktime2?.$H) {
      return alert("Please select your time!");
    }
    if (20 <= picktime2?.$H || 6 >= picktime2?.$H) {
      night = true;
    }

    let outstationObj = {
      tripType: "Outstation",
      pickupplace: pickupplace2,
      dropplace: dropplace2,
      pickdate: pickdate2,
      picktime: piTime2,
      packageType: packageType,
      packagePrice: packagePrice,
      driverBata: driverBata,
      night: night,
    };
    history("/taxi-here", {
      state: outstationObj,
    });
  };

  const history = useNavigate();

  const [Allarea, setAllarea] = useState([]);
  const getArea = async () => {
    try {
      const config = {
        url: "/getAllarea",
        method: "get",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setAllarea(res.data.success);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllPackage, setAllpackage] = useState([]);
  const getAllPackage = async () => {
    const config = {
      url: "/getAllPackage",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setAllpackage(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  const [AllOutStation, setAllOutStation] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getOutStationData = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllOUTSATIONUser"
      );
      if (res.status == 200) {
        setAllOutStation(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uniqueOptions = Array.from(
    new Set(AllOutStation?.map((ele) => ele?.from))
  );
  const uniqueOptions2 = Array.from(
    new Set(AllOutStation?.map((ele) => ele?.to))
  );

  const [AllAirport, setAllAirport] = useState([]);
  const getAirports = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllAirportsRateByUser"
      );
      if (res.status == 200) {
        setAllAirport(res.data.success);
        // setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AllCarModels,setAllCarModels]=useState([]);
  const getLuxuryCarM=async()=>{
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllvehicleModel"
      );
      if (res.status == 200) {
        setAllCarModels(
          res.data.carList?.filter((ele) => ele?.vehicleType == "LuxuryCar")
        );
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getArea();
    getAllPackage();
    getOutStationData();
    getAirports();
    getLuxuryCarM()
  }, []);

  const AirprtCity = Array.from(new Set(AllAirport?.map((ele) => ele?.city)));
  const AirPort = Array.from(
    new Set(
      AllAirport?.filter((item) => item?.city == city)?.map(
        (ele) => `${ele?.airportName}`
      )
    )
  );

  const LocalCity = Array.from(new Set(AllPackage?.map((ele) => ele?.city)));

  const LuxuryBrand=Array.from(new Set(AllCarModels?.map((ele)=>ele?.category)))

const [Brand,setbrand]=useState("");
const [carModel,setcarModel]=useState("");
const [name,setname]=useState("");
const [email,setemail]=useState("");
const [mobile,setmobile]=useState("");
const [todate,settodate]=useState("");
const ApplyForLuxuryTrain=async()=>{
  try {
     if (!Brand) {
      return alert("Please select car brand!");
    }
     if (!carModel) {
      return alert("Please select car model!");
    }

    if (!pickupplace2) {
      return alert("Please enter your pickup place!");
    }
    if (!dropplace2) {
      return "Please enter your drop place!";
    }
    if(!name) return alert("Please enter your name")
      if(!email) return alert("Please enter your email")
        if(!mobile) return alert("Please enter your mobile")
    if (!pickdate2) {
      return alert("Please select from date!");
    }
    if (!todate) {
       return alert("Please select to date!");
    }
    const config = {
      url: "/addLuxuryCarEnquiry",
      method: "post",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
      data: {
        from: pickupplace2,
        to: dropplace2,
        CarBrand:Brand,CarModel:carModel,
        name:name,email:email,mobile:mobile,
        FromDate:pickdate2,
        ToDate:todate
      },
    };
let res=await axios(config);
if(res.status==200){
  alert("Thanks you team will be connect soon as soon..")
  return window.location.assign("/")
}
 
  } catch (error) {
    window.location.reload()
    console.log(error);
  }
}
  return (
    <>
      <div className="taxi-booking">
        <div className="booking-aap">
          <ul className="flow-wrap">
            {airport ? (
              <li
                className="local-trap"
                style={{
                  color: "white",
                  cursor: "pointer",
                  backgroundColor: "#54c4b7",
                }}
                onClick={() => {
                  setairport(true);
                  setlocaldrop(false);
                  setoutsation(false);
                  setLuxury(false);
                  setpackageType("");
                }}
              >
                Airport Transfer
              </li>
            ) : (
              <li
                className="local-trap"
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  setairport(true);
                  setlocaldrop(false);
                  setoutsation(false);
                  setLuxury(false);
                  setpackageType("");
                }}
              >
                Airport Transfer
              </li>
            )}
            {localdrop ? (
              <li
                className="local-trap"
                style={{
                  color: "white",
                  cursor: "pointer",
                  backgroundColor: "#54c4b7",
                }}
                onClick={() => {
                  setairport(false);
                  setlocaldrop(true);
                  setoutsation(false);
                  setLuxury(false);
                  setpackageType("");
                }}
              >
                Local Drop
              </li>
            ) : (
              <li
                className="local-trap"
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  setairport(false);
                  setlocaldrop(true);
                  setoutsation(false);
                  setLuxury(false);
                  setpackageType("");
                }}
              >
                Local Drop
              </li>
            )}

            {outstation ? (
              <li
                className="local-trap"
                style={{
                  color: "white",
                  cursor: "pointer",
                  backgroundColor: "#54c4b7",
                }}
                onClick={() => {
                  setairport(false);
                  setlocaldrop(false);
                  setoutsation(true);
                  setLuxury(false);
                  setpackageType("");
                }}
              >
                Outstation
              </li>
            ) : (
              <li
                className="local-trap"
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  setairport(false);
                  setlocaldrop(false);
                  setoutsation(true);
                  setLuxury(false);
                  setpackageType("");
                }}
              >
                Outstation
              </li>
            )}
            {Luxury ? (
              <li
                className="local-trap"
                style={{
                  color: "white",
                  cursor: "pointer",
                  backgroundColor: "#54c4b7",
                }}
                onClick={() => {
                  setairport(false);
                  setlocaldrop(false);
                  setoutsation(false);
                  setLuxury(true);
                  setpackageType("");
                }}
              >
                Luxury Car
              </li>
            ) : (
              <li
                className="local-trap"
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  setairport(false);
                  setlocaldrop(false);
                  setoutsation(false);
                  setLuxury(true);
                  setpackageType("");
                }}
              >
                Luxury Car
              </li>
            )}
          </ul>
          {/*  */}
          {airport ? (
            <>
              <div className="tab-content">
                <div className="tab-panes">
                  <div className="trip-type-frm">
                    <div className="row">
                      <div className="col-md-6">
                        <div class="field-tip" id="airport_pickup">
                          <Stack>
                            <Autocomplete
                              freeSolo
                              id="free-solo-2-demo"
                              disableClearable
                              onChange={(event, value) => setcity(value)}
                              options={AirprtCity}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select City"
                                  InputProps={{
                                    ...params.InputProps,
                                    type: "search",
                                  }}
                                  style={{
                                    backgroundColor: "white",
                                    borderRadius: "10px",
                                  }}
                                />
                              )}
                            />
                          </Stack>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="field-tip" id="airport_pickup">
                          <Stack>
                            <Autocomplete
                              freeSolo
                              id="free-solo-2-demo"
                              disableClearable
                              onChange={(event, value) => setpickupplace(value)}
                              options={AirPort}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Airport"
                                  InputProps={{
                                    ...params.InputProps,
                                    type: "search",
                                  }}
                                  style={{
                                    backgroundColor: "white",
                                    borderRadius: "10px",
                                  }}
                                />
                              )}
                            />
                          </Stack>
                        </div>
                      </div>
                    </div>

                    <br />
                    <div class="field-tip" id="airport_pickup">
                      <Stack>
                        <Autocomplete
                          freeSolo
                          id="free-solo-2-demo"
                          disableClearable
                          onChange={(event, value) => setdropplace(value)}
                          options={Allarea.filter(
                            (item) => item?.City == city
                          )?.map(
                            (option) =>
                              `${
                                option?.Area
                              } ${option?.City?.toLowerCase()} ${option?.State?.toLowerCase()} ,${
                                option?.pincode
                              }`
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Drop Place"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                              style={{
                                backgroundColor: "white",
                                borderRadius: "10px",
                              }}
                            />
                          )}
                        />
                      </Stack>

                      <input type="hidden" value="1" name="trip_type" />
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <div class=" field-outer">
                          <label class="activel" style={{ color: " #626969" }}>
                            Pick Date <span class="text-danger">*</span>
                          </label>
                          <br />
                          <DatePicker
                            className="hasDatepicker-09"
                            required
                            // onChange={(date) => console.log(date)}
                            onChange={(date) => {
                              // setDate(date);
                              setpickdate(date?.$d ? date?.$d :"");
                            }}
                              disabledDate={(current) => current && current < moment().startOf('day')}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div class=" field-outer">
                          <label class="activel" style={{ color: " #626969" }}>
                            Pick Time<span class="text-danger">*</span>
                          </label>{" "}
                          <br />
                          <TimePicker
                            className="hasDatepicker-09"
                            required
                            onChange={(piTime) => setpicktime(piTime)}
                          />
                        </div>
                      </div>
                      <div class="col-md-12 field-outer">
                        <button
                          type="submit"
                          id="submit"
                          class="search-btn"
                          onClick={() => {
                            // window.location.assign("/taxi-here/" + trip);
                            airportCheck();
                          }}
                        >
                          Search Cabs{" "}
                          <i
                            class="fa fa-arrow-circle-right"
                            aria-hidden="true"
                          ></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {localdrop ? (
                <>
                  <div className="tab-content">
                    <div className="tab-panes">
                      <div className="trip-type-frm">
                        <div>
                          <div className="row">
                            <div className="col-md-6">
                              <Stack>
                                <Autocomplete
                                  freeSolo
                                  id="free-solo-2-demo"
                                  disableClearable
                                  onChange={(event, value) => setcity(value)}
                                  options={LocalCity}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select City"
                                      InputProps={{
                                        ...params.InputProps,
                                        type: "search",
                                      }}
                                      style={{
                                        backgroundColor: "white",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  )}
                                />
                              </Stack>
                            </div>
                            <div className="col-md-6">
                              <Stack>
                                <Autocomplete
                                  freeSolo
                                  id="free-solo-2-demo"
                                  disableClearable
                                  onChange={(event, value) =>
                                    setpickupplace1(value)
                                  }
                                  options={Allarea.filter(
                                    (item) => item?.City == city
                                  )?.map(
                                    (option) =>
                                      `${
                                        option?.Area
                                      } ${option?.City?.toLowerCase()} ${option?.State?.toLowerCase()} ,${
                                        option?.pincode
                                      }`
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Pickup Place"
                                      InputProps={{
                                        ...params.InputProps,
                                        type: "search",
                                      }}
                                      style={{
                                        backgroundColor: "white",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  )}
                                />
                              </Stack>
                            </div>
                          </div>

                          <br />
                          <div class="field-tip" id="airport_pickup">
                            <Stack>
                              <Autocomplete
                                freeSolo
                                id="free-solo-2-demo"
                                disableClearable
                                onChange={(event, value) =>
                                  setdropplace1(value)
                                }
                                options={Allarea?.map(
                                  (option) =>
                                    `${
                                      option?.Area
                                    } ${option?.City?.toLowerCase()} ${option?.State?.toLowerCase()} ,${
                                      option?.pincode
                                    }`
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Drop Place"
                                    InputProps={{
                                      ...params.InputProps,
                                      type: "search",
                                    }}
                                    style={{
                                      backgroundColor: "white",
                                      borderRadius: "10px",
                                    }}
                                  />
                                )}
                              />
                            </Stack>

                            <input type="hidden" value="1" name="trip_type" />
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div class=" field-outer">
                                <label
                                  class="activel"
                                  style={{ color: " #626969" }}
                                >
                                  Select Package{" "}
                                  <span class="text-danger">*</span>
                                </label>
                                <br />
                                <Form.Select
                                  onChange={(e) => {
                                    const selectedPackageDescription =
                                      e.target.value;
                                    const selectedPackage = AllPackage.find(
                                      (item) => {
                                        const packageKey = `${item?.PackageKm} km with ${item?.packageHour} hours`;
                                        return (
                                          packageKey ===
                                          selectedPackageDescription
                                        );
                                      }
                                    );

                                    if (selectedPackage) {
                                      setpackageType(
                                        selectedPackage.PackageKm || ""
                                      );
                                      setdriverBata(
                                        selectedPackage.packageHour || ""
                                      );
                                    }
                                  }}
                                >
                                  <option>Select package</option>
                                  {AllPackage?.filter(
                                    (item) => item?.city === city
                                  )
                                    .reduce((uniquePackages, item) => {
                                      const packageKey = `${item?.PackageKm} km with ${item?.packageHour} hours`;
                                      if (
                                        !uniquePackages.includes(packageKey)
                                      ) {
                                        uniquePackages.push(packageKey);
                                      }
                                      return uniquePackages;
                                    }, [])
                                    .map((uniquePackage, index) => (
                                      <option key={index} value={uniquePackage}>
                                        {uniquePackage}
                                      </option>
                                    ))}
                                </Form.Select>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div class=" field-outer">
                                <label
                                  class="activel"
                                  style={{ color: " #626969" }}
                                >
                                  Pick Date <span class="text-danger">*</span>
                                </label>
                                <br />
                                <DatePicker
                                  className="hasDatepicker-09"
                                  required
                                  onChange={(date) => setpickdate1(date?.$d ? date?.$d :"")}
                                    disabledDate={(current) => current && current < moment().startOf('day')}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div class=" field-outer">
                                <label
                                  class="activel"
                                  style={{ color: " #626969" }}
                                >
                                  Pick Time<span class="text-danger">*</span>
                                </label>{" "}
                                <br />
                                <TimePicker
                                  className="hasDatepicker-09"
                                  required
                                  onChange={(piTime1) => {
                                    setpicktime1(piTime1);
                                    console.log(piTime1);
                                  }}
                                />
                              </div>
                            </div>
                            <div class="col-md-12 field-outer">
                              <button
                                type="submit"
                                id="submit"
                                class="search-btn"
                                onClick={() => {
                                  localCheck();
                                }}
                              >
                                Search Cabs{" "}
                                <i
                                  class="fa fa-arrow-circle-right"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {outstation ? (
                    <>
                      <div className="tab-content">
                        <div className="tab-panes">
                          <div className="trip-type-frm">
                            <div class="field-tip" id="airport_pickup">
                              <Stack>
                                <Autocomplete
                                  freeSolo
                                  id="free-solo-2-demo"
                                  disableClearable
                                  onChange={(event, value) =>
                                    setpickupplace2(value)
                                  }
                                  options={uniqueOptions}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Pickup Place"
                                      InputProps={{
                                        ...params.InputProps,
                                        type: "search",
                                      }}
                                      style={{
                                        backgroundColor: "white",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  )}
                                />
                              </Stack>

                              <input type="hidden" value="1" name="trip_type" />
                            </div>
                            <br />
                            <div class="field-tip" id="airport_pickup">
                              <Stack>
                                <Autocomplete
                                  freeSolo
                                  id="free-solo-2-demo"
                                  disableClearable
                                  onChange={(event, value) =>
                                    setdropplace2(value)
                                  }
                                  options={uniqueOptions2}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Drop Place"
                                      InputProps={{
                                        ...params.InputProps,
                                        type: "search",
                                      }}
                                      style={{
                                        backgroundColor: "white",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  )}
                                />
                              </Stack>

                              <input type="hidden" value="1" name="trip_type" />
                            </div>

                            <div className="row">
                              {/* <div className="col-md-4">
                                <div class=" field-outer">
                                  <label
                                    class="activel"
                                    style={{ color: " #626969" }}
                                  >
                                    Select Package{" "}
                                    <span class="text-danger">*</span>
                                  </label>
                                  <br />
                                  <Form.Select
                                    onChange={(e) => {
                                      setpackageType(
                                        JSON.parse(e.target.value).PackageName
                                      );
                                      setpackagePrice(
                                        JSON.parse(e.target.value).price
                                      );
                                      setdriverBata(
                                        JSON.parse(e.target.value).dariveBata
                                      );
                                    }}
                                  >
                                    <option>Select package</option>
                                    {AllPackage?.filter(
                                      (ele) => ele.packageType === "Outstation"
                                    ).map((item) => {
                                      return (
                                        <option value={JSON.stringify(item)}>
                                          {item?.PackageName}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </div>
                              </div> */}
                              <div className="col-md-4">
                                <div class=" field-outer">
                                  <label
                                    class="activel"
                                    style={{ color: " #626969" }}
                                  >
                                    Pick Date <span class="text-danger">*</span>
                                  </label>
                                  <br />
                                  <DatePicker
                                    className="hasDatepicker-09"
                                    required
                                    onChange={(date) => setpickdate2(date?.$d ? date?.$d :"")}
                                      disabledDate={(current) => current && current < moment().startOf('day')}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div class=" field-outer">
                                  <label
                                    class="activel"
                                    style={{ color: " #626969" }}
                                  >
                                    Pick Time<span class="text-danger">*</span>
                                  </label>{" "}
                                  <br />
                                  <TimePicker
                                    className="hasDatepicker-09"
                                    required
                                    onChange={(piTime2) =>
                                      setpicktime2(piTime2)
                                    }
                                  />
                                </div>
                              </div>
                              <div class="col-md-12 field-outer">
                                <button
                                  type="submit"
                                  id="submit"
                                  class="search-btn"
                                  onClick={() => {
                                    checkOustsation();
                                  }}
                                >
                                  Search Cabs{" "}
                                  <i
                                    class="fa fa-arrow-circle-right"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {Luxury ? (
                        <>
                          <div className="tab-content">
                            <div className="tab-panes">
                              <div className="trip-type-frm">
                                <div class="field-tip" id="airport_pickup">
                                  <Stack>
                                    <Autocomplete
                                      freeSolo
                                      id="free-solo-2-demo"
                                      disableClearable
                                      onChange={(event, value) =>
                                        setbrand(value)
                                      }
                                      options={LuxuryBrand}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Select Brand"
                                          InputProps={{
                                            ...params.InputProps,
                                            type: "search",
                                          }}
                                          style={{
                                            backgroundColor: "white",
                                            borderRadius: "10px",
                                          }}
                                        />
                                      )}
                                    />
                                  </Stack>

                                  <input
                                    type="hidden"
                                    value="1"
                                    name="trip_type"
                                  />
                                </div>
                                <br />
                                <div class="field-tip" id="airport_pickup">
                                  <Stack>
                                    <Autocomplete
                                      freeSolo
                                      id="free-solo-2-demo"
                                      disableClearable
                                      onChange={(event, value) =>
                                        setcarModel(value)
                                      }
                                      options={AllCarModels?.filter((ele)=>ele?.category==Brand)?.map(
                                        (option) => option?.vehicleModel
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Select Model"
                                          InputProps={{
                                            ...params.InputProps,
                                            type: "search",
                                          }}
                                          style={{
                                            backgroundColor: "white",
                                            borderRadius: "10px",
                                          }}
                                        />
                                      )}
                                    />
                                  </Stack>

                                  <input
                                    type="hidden"
                                    value="1"
                                    name="trip_type"
                                  />
                                </div>
                                <br />
                                <div class="field-tip" id="airport_pickup">
                                  <Stack>
                                    <Autocomplete
                                      freeSolo
                                      id="free-solo-2-demo"
                                      disableClearable
                                      onChange={(event, value) =>
                                        setpickupplace2(value)
                                      }
                                      options={Allarea?.map(
                                        (option) =>
                                          `${
                                            option?.Area
                                          } ${option?.City?.toLowerCase()} ${option?.State?.toLowerCase()} ,${
                                            option?.pincode
                                          }`
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Pickup Place"
                                          InputProps={{
                                            ...params.InputProps,
                                            type: "search",
                                          }}
                                          style={{
                                            backgroundColor: "white",
                                            borderRadius: "10px",
                                          }}
                                        />
                                      )}
                                    />
                                  </Stack>

                                  <input
                                    type="hidden"
                                    value="1"
                                    name="trip_type"
                                  />
                                </div>
                                <br />
                                <div class="field-tip" id="airport_pickup">
                                  <Stack>
                                    <Autocomplete
                                      freeSolo
                                      id="free-solo-2-demo"
                                      disableClearable
                                      onChange={(event, value) =>
                                        setdropplace2(value)
                                      }
                                      options={Allarea?.map(
                                    (option) =>
                                      `${
                                        option?.Area
                                      } ${option?.City?.toLowerCase()} ${option?.State?.toLowerCase()} ,${
                                        option?.pincode
                                      }`
                                  )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Drop Place"
                                          InputProps={{
                                            ...params.InputProps,
                                            type: "search",
                                          }}
                                          style={{
                                            backgroundColor: "white",
                                            borderRadius: "10px",
                                          }}
                                        />
                                      )}
                                    />
                                  </Stack>

                                  <input
                                    type="hidden"
                                    value="1"
                                    name="trip_type"
                                  />
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div class="field-outer">
                                      <label
                                        class="activel"
                                        style={{ color: " #626969" }}
                                      >
                                        Name <span class="text-danger">*</span>
                                      </label>
                                      <br />
                                      <input
                                        type="text"
                                        placeholder="Enter your Name"
                                        value={name}
                                        onChange={(e)=>setname(e.target.value)}
                                        style={{ height: "48px" }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div class="field-outer">
                                      <label
                                        class="activel"
                                        style={{ color: " #626969" }}
                                      >
                                        Email <span class="text-danger">*</span>
                                      </label>
                                      <br />
                                      <input
                                        type="text"
                                        placeholder="Enter your Email"
                                        style={{ height: "48px" }}
                                        value={email}
                                        onChange={(e)=>setemail(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div class="field-outer">
                                      <label
                                        class="activel"
                                        style={{ color: " #626969" }}
                                      >
                                        Phone No{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <br />
                                      <input
                                        type="text"
                                        placeholder="Enter your Phone No"
                                        style={{ height: "48px" }}
                                        value={mobile}
                                        onChange={(e)=>setmobile(e.target.value)}
                                        maxLength={10}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div class=" field-outer">
                                      <label
                                        class="activel"
                                        style={{ color: " #626969" }}
                                      >
                                        From Date{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <br />
                                      <DatePicker
                                        className="hasDatepicker-09"
                                        required
                                        onChange={(date) =>
                                          setpickdate2(date?.$d ? date?.$d :"")
                                        }
                                        disabledDate={(current) => current && current < moment().startOf('day')}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div class=" field-outer">
                                      <label
                                        class="activel"
                                        style={{ color: " #626969" }}
                                      >
                                        To Date{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <br />
                                      <DatePicker
                                        className="hasDatepicker-09"
                                        required
                                        onChange={(date) =>
                                          settodate(date?.$d ? date?.$d :"")
                                        }
                                        disabledDate={(current) => current && current < moment().startOf('day')}
                                      />
                                    </div>
                                  </div>
                                
                                  <div class="col-md-12 field-outer">
                                    <button
                                      type="submit"
                                      id="submit"
                                      class="search-btn"
                                      onClick={() => {
                                        ApplyForLuxuryTrain();
                                      }}
                                    >
                                      Send Enquiry{" "}
                                      <i
                                        class="fa fa-arrow-circle-right"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default TaxiBooking;
