import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
import "./navbar.css";
import { BsStopwatch, BsWhatsapp } from "react-icons/bs";
import { AiOutlinePhone } from "react-icons/ai";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Header() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const [navigation, setnavigation] = useState("relative");
  const [AllCategory, setAllCategory] = useState([]);
  const getAllCategory = async () => {
    const config = {
      url: "/getAllCategory",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setAllCategory(result.data.success);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAllCategory();
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 500
        ? setnavigation("fixed top-0 left-0 z-50")
        : setnavigation("relative");
    }
  };

  const history = useNavigate();
  return (
    <>
      <div
        className=""
        style={{
          position: "sticky",
          top: "0px",
          zIndex: "999",
          backgroundColor: "white",
        }}
      >
        <div className="container-fluid">
          <div className="header-s">
            <div className="left-h">
              <a href="/" className="brand-name" style={{ width: "15%" }}>
                <img
                  src="../logo_eye.jpeg"
                  alt="logo"
                  style={{ width: "220px", height: "120px" }}
                />
              </a>
            </div>
            <div className="right-h">
              <div className="wraperr-f">
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <div className="icon-d">
                    <BsStopwatch />
                  </div>
                  <div className="text-d text-end">
                    <div className="time-d">9 AM &nbsp; to &nbsp; 9 PM</div>
                    <div className="time-text">GMT + 5Hrs 30Mins</div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <div className="icon-d">
                    <AiOutlinePhone />
                  </div>
                  <div className="text-d text-end">
                    <div className="time-d">Mobile</div>
                    <div className="time-text" style={{ color: "#004bab" }}>
                      +919035023309
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <a href="https://wa.aisensy.com/0dBXGb">
                    <div className="icon-d" style={{ color: "#3dd065" }}>
                      <BsWhatsapp />
                    </div>
                  </a>
                  <div className="text-d text-end">
                    <div className="time-d">WhatsApp</div>
                    <div className="time-text" style={{ color: "#004bab" }}>
                      +919108880004
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-0">
          <nav className="navigation">
            <button
              className="hamburger"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              {/* icon from Heroicons.com */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="white"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <div
              className={
                isNavExpanded
                  ? "navigation-menu expanded"
                  : "navigation-menu ms-auto"
              }
              style={{ marginTop: "12px" }}
            >
              <ul>
                <li>
                  <a href="/home">Home</a>
                </li>

                <li className="dropdown">
                  <a href="/jungal-lodges" className="dropbtn">
                    Jungle Lodges
                  </a>
                  <div class="dropdown-content">
                    {AllCategory?.map((item) => {
                      return (
                        <a
                          href="/our-best-packages"
                          onClick={() =>
                            history("/our-best-packages", {
                              state: { cat: item?.category },
                            })
                          }
                        >
                          {item?.category}
                        </a>
                      );
                    })}
                  </div>
                </li>
                <li>
                  <a href="/taxi-details">Hire Taxi</a>
                </li>
                <li>
                  <a href="/luxury-train"> Luxury Train</a>
                </li>
                <li>
                  <a href="/Bus">Bus</a>
                </li>
                <li>
                  <a href="/hotels"> Hotel</a>
                </li>
                <li>
                  <a href="/resorts"> Resort</a>
                </li>
                <li>
                  <a href="/home-stay"> Home Stay</a>
                </li>
                <li>
                  <a href="/vender-login"> Vendor Login</a>
                </li>
                <li>
                  <a href="/pay-online"> Pay Online</a>
                </li>
                <li>
                  <a href="/contact-us">Contact</a>
                </li>
                {/* <li>
                <a href="/booking">Mybooking</a>
              </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Header;
