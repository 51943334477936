import { Filter } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import ReactPaginate from "react-paginate";

const AdminVehicleModel = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [showDelete, setshowDelete] = useState(false);
  const handleDelete = () => setshowDelete(true);
  const closeDelete = () => setshowDelete(false);

  const [category, setcategory] = useState("");
  const [AllCategory, setAllCategory] = useState([]);
  const getAllCategory = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllVehicleCategory"
      );
      if (res.status == 200) {
        setAllCategory(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllVehicleModel, setAllVehicleModel] = useState([]);
  const getAllVehicleM = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllvehicleModel"
      );
      if (res.status == 200) {
        setAllVehicleModel(
          res.data.carList?.filter((ele) => ele?.vehicleType == "Taxi")
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCategory();
    getAllVehicleM();
  }, []);
  const [vehicleModel, setvehicleModel] = useState("");

  const addVehicleCat = async () => {
    try {
      if (!category) return alert("Please select category");
      if (!vehicleModel) return alert("Please enter vehicle model");
      let am = AllVehicleModel?.filter(
        (ele) =>
          ele?.category?.toLowerCase() == category?.toLowerCase() &&
          ele?.vehicleModel?.toLowerCase() == vehicleModel?.toLowerCase()
      );
      if (am.length !== 0)
        return alert(`${category} And ${vehicleModel} already exits`);
      const config = {
        url: "/addvehicleModel",
        method: "post",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          category: category,
          vehicleModel: vehicleModel,
          vehicleType: "Taxi",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        handleClose();
        getAllVehicleM();
        setcategory("");
        setvehicleModel("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [editdata, seteditdata] = useState({});

  const updateVehicleCate = async () => {
    try {
      if (!category) return alert("Please select category");
      if (!vehicleModel) return alert("Please enter vehicle model");
      let am = AllVehicleModel?.filter(
        (ele) =>
          ele?.category?.toLowerCase() == category?.toLowerCase() &&
          ele?.vehicleModel?.toLowerCase() == vehicleModel?.toLowerCase()
      );
      if (am.length !== 0)
        return alert(`${category} And ${vehicleModel} already exits`);
      const config = {
        url: "/editvehicleModel",
        method: "put",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          id: editdata?._id,
          category: category,
          vehicleModel: vehicleModel,
          vehicleType: "Taxi",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully updated");
        handleClose1();
        getAllVehicleM();
        setcategory("");
        setvehicleModel("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteVehCat = async () => {
    try {
      let res = await axios.delete(
        "https://dhanyatours.me/api/admin/deletevehicleModel/" + editdata?._id
      );
      if (res.status == 200) {
        alert("Successfully delete");
        getAllVehicleM();
        closeDelete();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllVehicleModel?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <>
      <div className="ms-5 mt-3">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h3> Vehicle Model</h3>
          </div>

          <div
            className="logde-op-00"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleShow();
            }}
          >
            + Vehicle Model
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Vehicle Model</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form>
                  <label className="pb-2 nd-2">Vehicle Category</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setcategory(e.target.value)}
                  >
                    <option>Select package type</option>
                    {AllCategory?.map((item) => {
                      return (
                        <option value={item?.category}>{item?.category}</option>
                      );
                    })}
                  </Form.Select>
                  <br />
                  <label htmlFor="">Vehicle Model</label>
                  <input
                    type="text"
                    placeholder="Please enter Vehicle Model.... "
                    className="title-90"
                    value={vehicleModel}
                    onChange={(e) => setvehicleModel(e.target.value)}
                  />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Back
              </Button>
              <Button variant="primary" onClick={addVehicleCat}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Update Vehicle Model</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form>
                  <label className="pb-2 nd-2">Vehicle Category</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e)=>setcategory(e.target.value)}
                  >
                    <option value={category}>{category}</option>
                    {AllCategory?.map((item) => {
                      return (
                        <option value={item?.category}>{item?.category}</option>
                      );
                    })}
                  </Form.Select>
                  <br />
                  <label htmlFor="">Vehicle Model</label>
                  <input
                    type="text"
                    placeholder={editdata?.vehicleModel}
                    className="title-90"
                    value={vehicleModel}
                    onChange={(e) => setvehicleModel(e.target.value)}
                  />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>
                Close
              </Button>
              <Button variant="primary" onClick={updateVehicleCate}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* <div className="searchHeadFlex">
                    <div className="searchWrapper" style={{ display: "flex" }}>
                        <input
                            type="text"
                            maxlength="230"
                            style={{ outlineWidth: "0px", padding: "4px" }}
                            className="textsearch"
                            placeholder="Search by vehicle"
                        // onChange={handleFilter}
                        />
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 16 16"
                            id="BsSearch"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                                clip-rule="evenodd"
                            ></path>
                            <path
                                fill-rule="evenodd"
                                d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </div>
                </div> */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Vehicle Category</th>
              <th>Vehicle Model</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {AllVehicleModel?.slice(
              pagesVisited,
              pagesVisited + usersPerPage
            )?.map((item, i) => {
              return (
                <tr>
                  <td>{i + 1}.</td>
                  <td>{item?.category}</td>
                  <td>{item?.vehicleModel}</td>
                  <td style={{ display: "flex", gap: "5px" }}>
                    <div
                      className=""
                      onClick={() => {
                        setcategory(item?.category);
                        seteditdata(item);
                        handleShow1();
                      }}
                    >
                      <BiEdit
                        style={{
                          color: "blue",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        seteditdata(item);
                        handleDelete();
                      }}
                    >
                      <AiFillDelete
                        style={{
                          color: "red",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
      <Modal show={showDelete} onHide={closeDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete ??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <div style={{ float: "right" }}>
            <Button variant="danger" onClick={closeDelete}>
              Cancel
            </Button>{" "}
            <Button variant="success" onClick={deleteVehCat}>
              Ok
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminVehicleModel;
