import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  AiFillDelete,
  AiOutlineDelete,
  AiOutlineDownload,
} from "react-icons/ai";
import { FaEdit, FaEye } from "react-icons/fa";
import Table1 from "react-bootstrap/Table";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useLocation } from "react-router-dom";
import moment from "moment";

const Check_In = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);




  const [bookingtaxi, setbookingtaxi] = useState({});


  const vendor = JSON.parse(sessionStorage.getItem("vendor"));
  const [booktaxi, setbooktaxi] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getBooking = async () => {
    const config = {
      url: "/getHotelBookingBywenderId/" + vendor?._id,
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setbooktaxi(result.data.success?.filter((ele)=>ele.checkStatus=="CheckIn"));
        setnochangedata(result.data.success?.filter((ele)=>ele.checkStatus=="CheckIn"));
      }
    } catch (error) {
      console.log(error);
    }
  };
useEffect(()=>{
  if(vendor){
    getBooking()
  }
},[])
const [gst, setgst] = useState({});
let [PaymentMethod, setPaymentMethod] = useState("");
let [GST, setGST] = useState(0);
let [Total, setTotal] = useState(0);
let [NumberOfchild, setNumberOfchild] = useState(0);
let [Extramater, setExtraKm] = useState(0);
let [numberOfguest, setnumberOfguest] = useState(0);
let [extraDeteails, setextraDeteails] = useState("");
const editBookingTaxi = async () => {

  try {
    const config = {
      url: "/editbookHotelOrder",
      method: "put",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
      data: {
        bookId: bookingtaxi?._id,
        extaraMatter: Extramater,
        message:extraDeteails,
      },
    };
    let res = await axios(config);
    if (res.status === 200) {
      alert("Success");
      getBooking();
      handleClose();
      setExtraKm(0);
 
      setextraDeteails("");
    } else {
      alert("Something Wrong");
    }
  } catch (error) {
    if (error.response) {
      alert("error");
      alert(error.response.data.error);
    }
  }
};
const createPDF = async () => {
  const input = document.getElementById("pdf");
  const options = { scrollY: -window.scrollY };
  const canvas = await html2canvas(input, options);
  const imgData = canvas.toDataURL("image/png");
  const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
  pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
  pdf.save("CheckInDetails.pdf");
};
const makeConfirm = async (id) => {
  try {
    const config = {
      url: "/makeOderStatusChangeHotelBook",
      method: "post",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
      data: {
        orderId: id,
        status: "Confirm",
      },
    };
    let res = await axios(config);
    if (res.status === 200) {
      getBooking();
    } else {
      alert("Something Wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

const makeCancel = async (id) => {
  try {
    const config = {
      url: "/makeOderStatusChangeHotelBook",
      method: "post",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
      data: {
        orderId: id,
        status: "Cancel",
      },
    };
    let res = await axios(config);
    if (res.status === 200) {
      getBooking();
    } else {
      alert("Something Wrong");
    }
  } catch (error) {
    console.log(error);
  }
};
const AddInvoiceGenration = async () => {
  try {
    const config = {
      url: "/addINVOICE",
      method: "post",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
      data: {
        date: moment().format("YYYY-MM-DD"),
        Name: bookingtaxi?.userName,
        PlaceOfSupply: bookingtaxi?.Location,
        email: bookingtaxi?.email,
        mobile: bookingtaxi?.number,
        HotelPerson:`${bookingtaxi?.NumberOfGuest} Adult ${bookingtaxi?.NumberOfChild?.length} Childrens`,
        HotelAmount: bookingtaxi?.totalAmount,
        PickUpPoint: bookingtaxi?.Location,
        DropingPoint: bookingtaxi?.dropPlace,
        hotelbookId: bookingtaxi?._id,
        TotalAmount: parseInt(bookingtaxi?.totalAmount)+bookingtaxi?.extaraMatter,
        PaymentStatus: bookingtaxi?.paymentMethod,
        InvoiceType:vendor?.vendorType ,
        hotelId:bookingtaxi?.lodgeId?._id
      },
    };
    let res = await axios(config);
  } catch (error) {
    console.log(error);
  }
};

const [bookid,setbookid]=useState("")
const makeRoomAlotHotel=async(id)=>{
  try {
    const config = {
      url: "/makeRoomAlotHotel",
      method: "put",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
      data: {
        checkStatus: "CheckOut",
        id:bookid
      },
    };
    let res=await axios(config);
    if(res.status==200){
      AddInvoiceGenration()
      alert("Successfully Check-out");
      window.location.assign("/vendor-check-out")
      getBooking();
    }
  } catch (error) {
    console.log(error);
  }
}

const handleFilter = (e) => {
  if (e.target.value != "") {
 
    const filterTable = nochangedata?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    setbooktaxi([...filterTable]);
  } else {
 
    setbooktaxi([...nochangedata]);
  }
};


const [pageNumber, setPageNumber] = useState(0);

const usersPerPage = 5;
const pagesVisited = pageNumber * usersPerPage;
const pageCount = Math.ceil(
  booktaxi.length /
    usersPerPage
);
const changePage = ({ selected }) => {
  setPageNumber(selected);
};

const [fromd, setfromd] = useState("");
const [tod, settod] = useState("");
const searchDate=()=>{
if(!fromd) return alert("Please select from date");
if(!tod) return alert("Please select to date");

let std=fromd?.split("-");
    let entd=tod?.split("-")
    let startD=parseInt(std?.join(""));
    let endD=parseInt(entd?.join(""))
    let abc=nochangedata?.filter((ele)=>{
      // console.log("amit check it",moment(ele?.createdAt).format("YYYY-MM-DD").split("-"))
      let abd=moment(ele?.createdAt).format("YYYY-MM-DD").split("-")
      let amd=parseInt(abd?.join(""));
      return startD<=amd && endD>=amd
    })
    setbooktaxi(abc);
    // setdata(abc)
    // setfileName(`AcountHistory_${moment(fromd).format("DD/MM/YYYY")} to ${moment(tod).format("DD/MM/YYYY")}`)
}

  return (
    <>
      <div className="ms-4">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op ms-4">
            <h3>Check In Details</h3>
          </div>
        </div>

        <Modal show={show} onHide={handleClose} id="pdf">
          <Modal.Header
            closeButton
            style={{
              backgroundColor: "#2fdbeb",
              color: "white",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            {vendor?.vendorType} Booking Details
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <form>
                <label className="pb-2 nd-2">BookId{" :"}</label>{" "}
                <span>{bookingtaxi?.bookId}</span>
                <br />
                <label className="pb-2 nd-2">Name {" :"}</label>{" "}
                <span>{bookingtaxi?.userName}</span> <br />
                <label className="pb-2 nd-2">Number{" :"}</label>{" "}
                <span> {bookingtaxi?.number}</span>
                <br />
                <label className="pb-2 nd-2">Email{" :"}</label>{" "}
                <span>{bookingtaxi?.email}</span>
                <br />
                <label className="pb-2 nd-2">Location{" :"}</label>{" "}
                {bookingtaxi?.Location}
                <br />
                <label className="pb-2 nd-2">Message{" :"}</label>{" "}
                {bookingtaxi?.message}
                <TableContainer>
                  <Table variant="striped" colorScheme="teal">
                    <TableCaption></TableCaption>

                    <Tbody>
                    
                      <Tr>
                        <Td>{vendor?.vendorType} Name</Td>
                        <Td>{bookingtaxi?.lodgeId?.hotalName}</Td>
                      </Tr>
                      <Tr>
                        <Td>{vendor?.vendorType}Id</Td>
                        <Td>{bookingtaxi?.lodgeId?._id}</Td>
                      </Tr>
                      <Tr>
                        <Td>{vendor?.vendorType} Contact Number</Td>
                        <Td>{bookingtaxi?.lodgeId?.contact}</Td>
                      </Tr>
                      <Tr>
                        <Td>{vendor?.vendorType} Email</Td>
                        <Td>{bookingtaxi?.lodgeId?.email}</Td>
                      </Tr>
                      <Tr>
                        <Td>Package Name</Td>
                        <Td>{bookingtaxi?.packageName}</Td>
                      </Tr>

                      <Tr>
                        <Td>Check In Date/Time</Td>
                        <Td>
                          {" "}
                          {moment(bookingtaxi?.checkInDate).format(
                            "DD/MM/YYYY"
                          )}
                          {"  "}
                          {moment(bookingtaxi?.checkInTime, [
                            "HH:mm:ss",
                          ]).format("hh:mm a")}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Check Out Date/time</Td>
                        <Td>
                          {moment(bookingtaxi?.checkOutDate).format(
                            "DD/MM/YYYY"
                          )}
                          {"  "}
                          {moment(bookingtaxi?.checkOutTime, [
                            "HH:mm:ss",
                          ]).format("hh:mm a")}
                        </Td>
                      </Tr>
                      {bookingtaxi?.packageName ? (<Tr>
                        <Td>Package Price/Adults ₹</Td>
                        <Td>{bookingtaxi?.packagePrice}</Td>
                      </Tr>):(<></>)}
                      
                      <Tr>
                        <Td>Number Of Adults</Td>
                        <Td>{bookingtaxi?.NumberOfGuest}</Td>
                      </Tr>
                      {/* {bookingtaxi?.status === "Confirm" ? (
                        <Tr>
                          <Td>Number Of Adults</Td>
                          <Td>
                            {" "}
                            <input
                              type="number"
                              className="title-90"
                              name="lodgename"
                              value={numberOfguest}
                              placeholder="Ex:- 3"
                              onChange={(e) => setnumberOfguest(e.target.value)}
                            />
                          </Td>
                        </Tr>
                      ) : (
                        <></>
                      )} */}
                      <Tr>
                        <Td>Number Of children</Td>
                        <Td>{bookingtaxi?.NumberOfChild?.length}</Td>
                      </Tr>
                      {/* {bookingtaxi?.status === "Confirm" ? (
                        <Tr>
                          <Td>Number Of children</Td>
                          <Td>
                            {" "}
                            <input
                              type="number"
                              className="title-90"
                              name="lodgename"
                              value={NumberOfchild}
                              placeholder="Ex:- 3"
                              onChange={(e) => setNumberOfchild(e.target.value)}
                            />
                          </Td>
                        </Tr>
                      ) : (
                        <></>
                      )} */}
                      <Tr>
                        <Td>Extra Matter Price ₹</Td>
                        <Td>{(bookingtaxi?.extaraMatter)?.toFixed(2)}</Td>
                      </Tr>
                      {bookingtaxi?.status === "Confirm" ? (<>
                        <Tr>
                          <Td>Extra Matter </Td>
                          <Td>
                            {" "}
                            <input
                              type="text"
                              className="title-90"
                              name="lodgename"
                              value={extraDeteails}
                              placeholder="Write details extra mater"
                              onChange={(e) => setextraDeteails(e.target.value)}
                            />
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Extra Matter Price ₹</Td>
                          <Td>
                            {" "}
                            <input
                              type="number"
                              className="title-90"
                              name="lodgename"
                              value={Extramater}
                              placeholder="Ex:- 1200"
                              onChange={(e) => setExtraKm(e.target.value)}
                            />
                          </Td>
                        </Tr>
                     </> ) : (
                        <></>
                      )}
                      <Tr>
                        <Td>GST ₹</Td>
                        <Td>{(bookingtaxi?.gst)?.toFixed(2)}</Td>
                      </Tr>

                      <Tr>
                        <Td>Total Price ₹</Td>
                        <Td>{(parseInt(bookingtaxi?.totalAmount)+bookingtaxi?.extaraMatter)?.toFixed(2)}</Td>
                      </Tr>
                      <Tr>
                        <Td>Status</Td>
                        <Td>{bookingtaxi?.status}</Td>
                      </Tr>
                      <Tr>
                        <Td>Payment</Td>
                        <Td>{bookingtaxi?.paymentMethod}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <AiOutlineDownload
              style={{
                color: "red",
                cursor: "pointer",
                fontSize: "36px",
                float: "right",
              }}
              onClick={() => createPDF()}
            />
            {bookingtaxi?.status === "Complete" ? (
              <></>
            ) : (
              <>
                {bookingtaxi?.status === "Confirm" ? (
                  <>
                    <Button variant="primary" onClick={editBookingTaxi}>
                      Update Booking
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        makeCancel(bookingtaxi?._id);
                        handleClose();
                      }}
                    >
                       Cancel Booking
                    </Button>
             
                  </>
                ) : (
                  <Button
                    variant="warning"
                    onClick={() => {
                      makeConfirm(bookingtaxi?._id);
                      handleClose();
                    }}
                  >
                    Confirm Booking
                  </Button>
                )}
              </>
            )}

            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure check-out?</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <Button variant="primary" onClick={makeRoomAlotHotel} >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="taxi-table ms-4">
        <div className="row">
              <div className="col-md-4">
                <div className="searchHeadFlex">
                  <div className="searchWrapper" style={{ display: "flex" }}>
                    <input
                      type="text"
                      maxlength="230"
                      style={{ outlineWidth: "0px", padding: "4px" }}
                      className="textsearch"
                      placeholder="Search by ..."
                      onChange={handleFilter}
                    />
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      id="BsSearch"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                        clip-rule="evenodd"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
              <label htmlFor="">From</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => setfromd(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="">To</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => settod(e.target.value)}
              />
            </div>
            <div className="col-md-2">
         
              <button onClick={searchDate}
                style={{
                  borderRadius: "3px",
                  color: "white",
                  fontSize: "18px",
                  border: "1px solid",
                  backgroundColor: "#004aad",
                  padding: "5px",
                  marginTop: "23px",
                  borderRadius: "10px",
                  fontWeight: 500,
                }}
              >
                Search
              </button>
            </div>
              
            </div>
          <div className="scrolll_bar">
            <Table1 striped bordered hover>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Booking Id</th>
                  <th>{vendor?.vendorType} name</th>
                  <th>User Name</th>
                  <th>Number</th>
                  <th>CheckIn Date/Time</th>
                  <th>CheckOut Date/Time</th>
                  <th>Package Name</th>
                  <th>Occupancy</th>
                  <th>Package Price ₹</th>
                  <th>Number of Adults</th>
                  <th>No. of Rooms/Room Number</th>
                  <th>Total Amount</th>

                  <th> Booking Status</th>
                  <th>Check In Status</th>
                  <th>Action</th>
                  <th>Check Out</th>
                </tr>
              </thead>
              <tbody>
                {booktaxi ?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                  return (
                     <tr>
                    <td>{i+1}</td>
                     
                     <td>{item?.bookId}</td>
                     <th>{item?.lodgeId?.hotalName}</th>
                     <td>{item?.userName}</td>
                     <td>{item?.number}</td>
                     <td>
                       {moment(item?.checkInDate).format("DD/MM/YYYY")}
                       {"  "}
                       {moment(item?.checkInTime, ["HH:mm:ss"]).format(
                         "hh:mm a"
                       )}{" "}
                     </td>
                     <td>
                       {moment(item?.checkOutDate).format("DD/MM/YYYY")}
                       {"  "}
                       {moment(item?.checkOutTime, ["HH:mm:ss"]).format(
                         "hh:mm a"
                       )}{" "}
                     </td>
                     <td>{item?.packageName}</td>
                     <td>{item?.Occupancy}</td>
                     <td>{item?.packagePrice}</td>
                     <td>{item?.NumberOfGuest}</td>
                     <td>{item?.AloteRoom ? (item?.AloteRoom?.split(",")?.length):""}{" "}{"Room No:"}{` ${item?.AloteRoom}`}</td>
                     <td>{(parseInt(item?.totalAmount)+item?.extaraMatter)?.toFixed(2)}</td>

                     <td>{item?.status}</td>
                  <td>Checked In/Pending</td>
                  <td>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <span>
                        <FaEye
                          style={{
                            color: "#5600ff",
                            cursor: "pointer",
                            fontSize: "26px",
                          }}
                          onClick={()=>{
                            setextraDeteails(item?.message)
                            setExtraKm(item?.extaraMatter)
                            setbookingtaxi(item)
                            handleShow()}}
                        />
                      </span>
                      {/* <span>
                        <AiOutlineDelete
                          style={{
                            color: "red",
                            cursor: "pointer",
                            fontSize: "26px",
                          }}
                          onClick={handleShow1}

                        />
                      </span> */}
                    </div>
                  </td>
                  <td><Button style={{ color: "white" }}  onClick={()=>{
                    setbookid(item?._id);
                    setbookingtaxi(item)
                    handleShow1();
                  }}> Check Out</Button></td>

                </tr>
                  )
                })}
               
           
              </tbody>
            </Table1>
          </div>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </div>
    </>
  )
}

export default Check_In