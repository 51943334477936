import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table, Form } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import ReactPaginate from "react-paginate";

function AddLocation() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // getAllImage
  const [imge, setimge] = useState([]);
  console.log(imge, "foqkpofe");

  const getTaxi = async () => {
    const config = {
      url: "getAllImage",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/jason" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setimge(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getTaxi();
    getState();
    getCity();
    getArea();
  }, []);

  // postImage
  const [image1, setimage1] = useState("");
  const [name, setname] = useState("");
  const [State, setState] = useState("");
  // console.log(taxi, "roebo");
  const addState = async () => {
    if (!State) {
     return  alert("Please enter state!");
    } 
      try {
        const config = {
          url: "/addState",
          method: "post",
          baseURL: "https://dhanyatours.me/api/admin",
          headers: { "content-type": "application/json" },
          data: {
            State: State,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Successfully Added");
          setState("");
          handleClose();
          getState();
        } else {
          alert("Something Wrong");
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data.error);
        }
      }
  };
  const [allState, setAllstate] = useState([]);
  const getState = async () => {
    try {
      const config = {
        url: "/getAllstates",
        method: "get",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setAllstate(res.data.success);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [City, setCity] = useState("");
  const addCity = async () => {
    if (!State) {
     return alert("Please select state!");
    } else if (!City) {
      return  alert("Please enter City!");
    } else
      try {
        const config = {
          url: "/AddCity",
          method: "post",
          baseURL: "https://dhanyatours.me/api/admin",
          headers: { "content-type": "application/json" },
          data: {
            State: State,
            City: City,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Successfully Added");
          handleClose1();
          setState("");
          setCity("");
          getCity();
        } else {
          alert("Something Wrong");
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data.error);
        }
      }
  };

const [CityShowEd,setCityShowEd]=useState(false);
const [edcity,setedcity]=useState({});

  const updateCityS=async()=>{
    try {
      const config = {
        url: "/UpdateCitys",
        method: "put",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          id:edcity?._id,
          State: State,
          City: City,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully updated");
        setCityShowEd(false);
        setState("");
        setCity("");
        getCity();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  }
  const [AllCity, setAllcity] = useState([]);
  const getCity = async () => {
    try {
      const config = {
        url: "/GetCity",
        method: "get",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setAllcity(res.data.success);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  function validatePIN (pin) {
    return /^(\d{6})$/.test(pin);
}
  const [Area, setArea] = useState("");
  const [pincode, setpincode] = useState("");
  const addArea = async () => {
    if (!State) {
     return alert("Please select state!");
    } else if (!City) {
      return alert("Please select City!");
    } else if (!Area) {
      return  alert("Please enter area!");
    } else if (!pincode) {
      return  alert("Please enter pincode");
    } else
      if(!validatePIN(pincode)) return alert("Invalid pincode")
      try {
        const config = {
          url: "/Addarea",
          method: "post",
          baseURL: "https://dhanyatours.me/api/admin",
          headers: { "content-type": "application/json" },
          data: {
            State: State,
            City: City,
            Area: Area,
            pincode: pincode,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Successfully Added");
          handleClose2();
          setState("");
          setCity("");
          setArea("");
          setpincode("");
          getArea();
        } else {
          alert("Something Wrong");
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data.error);
        }
      }
  };
  const [Allarea, setAllarea] = useState([]);
  const getArea = async () => {
    try {
      const config = {
        url: "/getAllarea",
        method: "get",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setAllarea(res.data.success);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [deleteId, setDeleteId] = useState("");
  const [showDelete, setshowDelete] = useState(false);
  const handleDelete = () => setshowDelete(true);
  const closeDelete = () => setshowDelete(false);

  const onTaxiDelete = async () => {
    try {
      const config = {
        url: "/deleteArea/" + deleteId,
        method: "delete",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Delete");
        closeDelete();
        getArea();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const [editId, seteditId] = useState("");
  const [editShow, seteditShow] = useState(false);
  const editHandShow = (data) => {
    setState(data?.State);
    setCity(data?.City);
    setArea(data?.Area);
    setpincode(data?.pincode);
    seteditShow(true);
  };
  const closeEdit = () => seteditShow(false);
  const updateArea = async () => {
    try {
      if(pincode){
        if(!validatePIN(pincode)) return alert("Invalid pincode")
      }
      const config = {
        url: "/UpdateArea",
        method: "put",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          id: editId,
          State: State,
          City: City,
          Area: Area,
          pincode: pincode,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully updated");
        closeEdit();
        setState("");
        setCity("");
        setArea("");
        setpincode("");
        getArea();
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const [listSt, setlistSt] = useState(false);
  const [listCty, setlistCty] = useState(false);
  const [listAre, setlistAre] = useState(true);

  const deleteState=async()=>{
    try {
      let res=await axios.delete("https://dhanyatours.me/api/admin/deleteStateData/"+deleteId);
      if(res.status==200){
        alert("Successfully deleted");
        getState();
        closeDelete()
      }
    } catch (error) {
      console.log(error);
    }
  }

  const deleteCityList=async ()=>{
    try {
      let res=await axios.delete("https://dhanyatours.me/api/admin/deleteCityData/"+deleteId);
      if(res.status==200){
        alert("Successfully deleted");
        getCity();
        closeDelete()
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(Allarea?.length / usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [pageNumber1, setPageNumber1] = useState(0);

  const usersPerPage1 = 10;
  const pagesVisited1 = pageNumber1 * usersPerPage1;
  const pageCount1 = Math.ceil(AllCity?.length / usersPerPage1
  );
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected);
  };
  return (
    <div className="de-lod">
      <div
        className="mt-2 mb-3"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="logde-op">
          <h3>Location </h3>
        </div>
        <div
          className="logde-op-00"
          style={{ cursor: "pointer",backgroundColor:listAre? "red":"" }}
          onClick={()=>{
            setlistSt(false);
            setlistCty(false)
            setlistAre(true)
          }}
        >
         Area List
        </div>
        <div
          className="logde-op-00"
          style={{ cursor: "pointer",backgroundColor:listSt? "red":"" }}
          onClick={()=>{
            setlistSt(true);
            setlistCty(false)
            setlistAre(false)
          }}
        >
         State List
        </div>
        <div
          className="logde-op-00"
          style={{ cursor: "pointer",backgroundColor:listCty? "red":"" }}
          onClick={()=>{
            setlistSt(false);
            setlistCty(true)
            setlistAre(false)
          }}
        >
          City List
        </div>
     <div style={{display:"flex",gap:"5px"}}>
        <div
          className="logde-op-00"
          style={{ cursor: "pointer" }}
          onClick={handleShow}
        >
          + State
        </div>
        <div
          className="logde-op-00"
          style={{ cursor: "pointer" }}
          onClick={handleShow1}
        >
          + City
        </div>
        <div
          className="logde-op-00"
          style={{ cursor: "pointer" }}
          onClick={handleShow2}
        >
          + Area
        </div></div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add State</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <form>
                <label className="pb-2 nd-2">State</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  name="lodgename"
                  placeholder="Please enter state"
                  value={State}
                  required
                  onChange={(e) => setState(e.target.value)}
                />
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Back
            </Button>
            <Button variant="primary" onClick={() => addState()}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <form>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setState(e.target.value)}
                >
                  <option>Select state</option>
                  {allState?.map((item) => {
                    return <option value={item?.State}>{item?.State}</option>;
                  })}
                </Form.Select>
                <label className="pb-2 nd-2">City</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  name="lodgename"
                  placeholder="Please enter city"
                  value={City}
                  required
                  onChange={(e) => setCity(e.target.value)}
                />
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Back
            </Button>
            <Button variant="primary" onClick={() => addCity()}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <div
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                  gap: "30px",
                }}
              >
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                >
                  <option>Select state</option>
                  {allState?.map((item) => {
                    return <option value={item?.State}>{item?.State}</option>;
                  })}
                </Form.Select>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setCity(e.target.value)}
                >
                  <option>Select city</option>
                  {AllCity?.filter((ele) => ele?.State === State)?.map(
                    (item) => {
                      return <option value={item?.City}>{item?.City}</option>;
                    }
                  )}
                </Form.Select>
              </div>
              <form>
                <label className="pb-2 nd-2">Area</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  name="lodgename"
                  value={Area}
                  placeholder="Please enter area.."
                  required
                  onChange={(e) => setArea(e.target.value)}
                />
                <br />
                <label className="pb-2 nd-2">PinCode</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  name="lodgename"
                  placeholder="Please enter pincode"
                  value={pincode}
                  required
                  onChange={(e) => setpincode(e.target.value)}
                />
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Back
            </Button>
            <Button variant="primary" onClick={() => addArea()}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {listAre ? ( <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>State</th>
              <th>City</th>
              <th>Area</th>
              <th>Pincode</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Allarea?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((itmimg, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{itmimg?.State}</td>
                  <td>{itmimg?.City}</td>
                  <td>{itmimg?.Area}</td>
                  <td>{itmimg?.pincode}</td>
                  <td style={{ display: "flex", gap: "5px" }}>
                    <div
                      className=""
                      onClick={() => {
                        seteditId(itmimg?._id);
                        editHandShow(itmimg);
                      }}
                    >
                      <BiEdit
                        style={{
                          color: "blue",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setDeleteId(itmimg?._id);
                        handleDelete();
                      }}
                    >
                      <AiFillDelete
                        style={{
                          color: "red",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>):(<></>)}
      {listSt ? (<div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>State</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allState?.map((itmimg, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{itmimg?.State}</td>
                
                  <td style={{ display: "flex", gap: "5px" }}>
                    {/* <div
                      className=""
                      onClick={() => {
                        seteditId(itmimg?._id);
                        editHandShow(itmimg);
                      }}
                    >
                      <BiEdit
                        style={{
                          color: "blue",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      />
                    </div> */}
                    <div
                      className=""
                      onClick={() => {
                        setDeleteId(itmimg?._id);
                        handleDelete();
                      }}
                    >
                      <AiFillDelete
                        style={{
                          color: "red",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>):(<></>)}
      {listCty ? (<div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>State</th>
              <th>City</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {AllCity?.slice(pagesVisited1, pagesVisited1 + usersPerPage1)?.map((itmimg, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{itmimg?.State}</td>
                  <td>{itmimg?.City}</td>
                  <td style={{ display: "flex", gap: "5px" }}>
                    <div
                      className=""
                      onClick={() => {
                        setedcity(itmimg);
                        setCityShowEd(true);
                      }}
                    >
                      <BiEdit
                        style={{
                          color: "blue",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setDeleteId(itmimg?._id);
                        handleDelete();
                      }}
                    >
                      <AiFillDelete
                        style={{
                          color: "red",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount1}
          onPageChange={changePage1}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>):(<></>)}
      <Modal show={editShow} onHide={closeEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div
              className="d-flex"
              style={{
                justifyContent: "space-between",
                gap: "30px",
              }}
            >
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setState(e.target.value);
                }}
              >
                <option>{State ? State : "Select state"}</option>
                {allState?.map((item) => {
                  return <option value={item?.State}>{item?.State}</option>;
                })}
              </Form.Select>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setCity(e.target.value)}
              >
                <option>{City ? City : "Select city"}</option>
                {AllCity?.filter((ele) => ele?.State === State)?.map((item) => {
                  return <option value={item?.City}>{item?.City}</option>;
                })}
              </Form.Select>
            </div>
            <form>
              <label className="pb-2 nd-2">Area</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="lodgename"
                value={Area}
                placeholder="Please enter area.."
                required
                onChange={(e) => setArea(e.target.value)}
              />
              <br />
              <label className="pb-2 nd-2">PinCode</label>
              <br />
              <input
                type="text"
                className="title-90"
                name="lodgename"
                placeholder="Please enter pincode"
                value={pincode}
                required
                onChange={(e) => setpincode(e.target.value)}
              />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeEdit}>
            Back
          </Button>
          <Button variant="primary" onClick={() => updateArea()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} onHide={closeDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete ??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <div style={{ float: "right" }}>
            <Button variant="danger" onClick={closeDelete}>
              Cancel
            </Button>{" "}
            {listAre ? ( <Button variant="success" onClick={onTaxiDelete}>
              Ok
            </Button>):(<></>)}
            {listCty ? ( <Button variant="success" onClick={deleteCityList}>
              Ok
            </Button>):(<></>)}
            {listSt ? ( <Button variant="success" onClick={deleteState}>
              Ok
            </Button>):(<></>)}
           
          </div>
        </Modal.Footer>
      </Modal>

      {/* edit city Data model */}
      <Modal show={CityShowEd} onHide={()=>setCityShowEd(false)}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <form>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setState(e.target.value)}
                >
                  <option>{edcity?.State}</option>
                  {allState?.map((item) => {
                    return <option value={item?.State}>{item?.State}</option>;
                  })}
                </Form.Select>
                <label className="pb-2 nd-2">City</label>
                <br />
                <input
                  type="text"
                  className="title-90"
                  name="lodgename"
                  placeholder={edcity?.City}
                  value={City}
                  required
                  onChange={(e) => setCity(e.target.value)}
                />
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>setCityShowEd(false)}>
              Back
            </Button>
            <Button variant="primary" onClick={() => updateCityS()}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
    </div>
  );
}

export default AddLocation;
