
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'



// const mockVal = (str, repeat = 1) => ({
//     value: str.repeat(repeat),
//   });
const AdminCollection = () => {




  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [showDelete, setshowDelete] = useState(false);
  const handleDelete = () => setshowDelete(true);
  const closeDelete = () => setshowDelete(false);
  const [DriverName, setDriverName] = useState("");
  const [remarks, setremarks] = useState("");
  const [date, setdate] = useState("");
  const [amount, setamount] = useState("");
  const [driverId, setdriverId] = useState("");
  const [PaymentStatus, setPaymentStatus] = useState("");
  const [tableFilter, settableFilter] = useState([]);
  const [AllDriverCollection, setAllDriverCollection] = useState([]);
  const getAllDriverCollection = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllDriverCollection"
      );
      if (res.status == 200) {
        setAllDriverCollection(res.data.success);
        settableFilter(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AllDriver, setAllDriver] = useState([]);
//   const [nochangedata, setnochangedata] = useState("");

  const getAllDrivers = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/driver/getAllTransports"
      );
      if (res.status == 200) {
        setAllDriver(res.data.success);
        // setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllDriverCollection();
    getAllDrivers()
  }, []);

//   const top100Films = AllDriverCollection.map((item) => ({
//     label: `${item?.name}-${item?.mobile}`,
//     year: JSON.stringify(item),
//   }));


  

  const AddDriverCollection = async () => {
    if (!DriverName) return alert("Please select Driver");
    if (!remarks) return alert("Please enter remarks");
    if (!date) return alert("Please select date");
    if (!amount) return alert("Please enter amount");

    try {
      const config = {
        url: "/AddDriverCollection",
        method: "post",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          DriverName: DriverName,
          remarks: remarks,
          date: date,
          amount: amount,
          driverId: driverId,
          PaymentStatus:PaymentStatus
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Added");
        handleClose();
        getAllDriverCollection();
        setPaymentStatus("");
        setDriverName("");
        setamount("");
        setremarks("");
        setdate(moment().format("YYYY-MM-DD"));
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const [edit, setedit] = useState({});
  const UpdateDriverCollectione = async () => {

    try {
      const config = {
        url: "/UpdateDriverCollectione",
        method: "put",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          id: edit?._id,
          DriverName: DriverName,
          remarks: remarks,
          date: date,
          amount: amount,
          driverId: driverId,
          PaymentStatus:PaymentStatus,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully updated");
        handleClose1();
        getAllDriverCollection();
        setPaymentStatus("");
        setDriverName("");
        setamount("");
        setremarks("");
        setdate(moment().format("YYYY-MM-DD"));
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const [deleteId, setDeleteId] = useState("");
  const deleteDriverCollection = async () => {
    try {
      let res = await axios.delete(
        "https://dhanyatours.me/api/admin/deleteDriverCollection/" + deleteId
      );
      if (res.status == 200) {
        alert("Successfully deleted");
        closeDelete();
        return getAllDriverCollection();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllDriverCollection?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

 
  const handleFilter = (e) => {
    if (e.target.value != "") {
   
      const filterTable = tableFilter
        ?.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
          )
        );
        setAllDriverCollection([...filterTable]);
    } else {
   
      setAllDriverCollection([...tableFilter]);
    }
  };


  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    setDriverName(item?.name);
      setdriverId(item?._id);
    // console.log("check==>", item)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
        <span style={{ display: 'block', textAlign: 'left' }}>{item?.name}-{item?.mobile}</span>
      </>
    )
  }

  return (
    <>
      <div className="ms-5 mt-3">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h3>Collection</h3>
          </div>

          <div
            className="logde-op-00"
            style={{ cursor: "pointer" }}
            onClick={() => {
            //   setDriverName("");
            //   setamount("");
            //   setremarks("");
            //   setdate(moment().format("YYYY-MM-DD"));
              handleShow();
            }}
          >
            + Collection
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Collection </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
            
                  <label className="pb-2 nd-2">Driver Name</label>
                  <br />
                
                  <ReactSearchAutocomplete
            items={AllDriver}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
            placeholder="Select driver name"
          />

                  <label className="pb-2 nd-2">Amount</label>
                  <br />
                  <input
                    type="number"
                    className="title-90"
                    name="driverBata"
                    placeholder="Enter Amount....."
                    value={amount}
                    min={1}
                    onChange={(e) => setamount(e.target.value)}
                    required
                  />
                    <div className="do-sear mt-2">
                        <label className="pb-2 nd-2">Remarks</label>
                        <input
                            type="text"
                            value={remarks}
                            onChange={(e) => setremarks(e.target.value)}
                            className="title-90"
                            placeholder="Enter Your remarks"
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label className="pb-2 nd-2">Date</label>
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setdate(e.target.value)}
                            className="title-90"
                        />
                    </div>
                  <label className="pb-2 nd-2">Collection Status</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e)=>setPaymentStatus(e.target.value)}
                  >
                    <option>Select Collection Status</option>
                    <option value="Collected">Collected</option>
                    <option value="Pending">Pending</option>
                  </Form.Select> 
               
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>Back</Button>
              <Button variant="primary" onClick={AddDriverCollection}>Submit</Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Update Vehicle Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
            
                  <label className="pb-2 nd-2">Driver Name</label>
                  <br />
                
                  <ReactSearchAutocomplete
            items={AllDriver}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
            placeholder={DriverName}
          />

                  <label className="pb-2 nd-2">Amount</label>
                  <br />
                  <input
                    type="number"
                    className="title-90"
                    name="driverBata"
                    placeholder="Enter Amount....."
                    value={amount}
                    min={1}
                    onChange={(e) => setamount(e.target.value)}
                    required
                  />
                    <div className="do-sear mt-2">
                        <label className="pb-2 nd-2">Remarks</label>
                        <input
                            type="text"
                            value={remarks}
                            onChange={(e) => setremarks(e.target.value)}
                            className="title-90"
                            placeholder="Enter Your remarks"
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label className="pb-2 nd-2">Date</label>
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setdate(e.target.value)}
                            className="title-90"
                        />
                    </div>
                  <label className="pb-2 nd-2">Collection Status</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e)=>setPaymentStatus(e.target.value)}
                  >
                    <option>{PaymentStatus}</option>
                    <option value="Collected">Collected</option>
                    <option value="Pending">Pending</option>
                  </Form.Select> 
               
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>Close</Button>
              <Button variant="primary" onClick={UpdateDriverCollectione} >Submit</Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="searchHeadFlex">
                    <div className="searchWrapper" style={{ display: "flex" }}>
                        <input
                            type="text"
                            maxlength="230"
                            style={{ outlineWidth: "0px", padding: "4px" }}
                            className="textsearch"
                            placeholder="Search by ..."
                        onChange={handleFilter}
                        />
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 16 16"
                            id="BsSearch"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                                clip-rule="evenodd"
                            ></path>
                            <path
                                fill-rule="evenodd"
                                d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </div>
                </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Driver Name</th>
                 <th>Remarks</th>
                 <th>Date</th>
              <th>Amount</th>
              <th>Collection Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {AllDriverCollection?.slice(pagesVisited,pagesVisited+usersPerPage)?.map((item,i)=>{
                return (<tr>
              <td>{i+1}.</td>
              <td>{item?.DriverName}</td>
              <td>{item?.remarks}</td>
              <td>{item?.date}</td>
              <td>{item?.amount}</td>
                <td>{item?.PaymentStatus}</td>
              <td style={{ display: "flex", gap: "5px" }}>
                <div
                  className=""
                  onClick={() => {
                    setedit(item);
                    setPaymentStatus(item?.PaymentStatus);
        setDriverName(item?.DriverName);
        setamount(item?.amount);
        setremarks(item?.remarks);
        setdate(item?.date);
                    handleShow1();
                  }}
                >
                  <BiEdit
                    style={{
                      color: "blue",
                      fontSize: "25px",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div
                  className=""
                  onClick={() => {
                    setDeleteId(item?._id)
                    handleDelete();
                  }}
                >
                  <AiFillDelete
                    style={{
                      color: "red",
                      fontSize: "25px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </td>
            </tr>)
            })}
            
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
      <Modal show={showDelete} onHide={closeDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete ??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <div style={{ float: "right" }}>
            <Button variant="danger" onClick={closeDelete}>
              Cancel
            </Button>{" "}
            <Button variant="success" onClick={deleteDriverCollection}>Ok</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminCollection;
