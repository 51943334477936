import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Header from "./Components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Blog from "./Components/Blog";
import "react-tooltip/dist/react-tooltip.css";
import "react-alice-carousel/lib/alice-carousel.css";
// import Popular from "./Components/Popular";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Jungal from "./Components/Jungal";
import Taxi from "./Components/Taxi";
// import Mybooking from "./Components/Mybooking";
// import Local from "./Components/LocalDrop";

// import Outstation from "./Components/Outstation";

import AddHomeGraphic from "./Components/Admin/AddHomeGraphic";

import Contact from "./Components/Contact";
import SingalTaxi from "./Components/SingalTaxi";
import Singallodge from "./Components/Singallodge";
// import Product from "./Components/Product";
import Sidebar from "./Components/Admin/Sidebar";
import AddLodges from "./Components/Admin/AddLodges";
import AddLodgeGraph from "./Components/Admin/AddLodgeGraph";
import AddTaxiGraphic from "./Components/Admin/AddTaxiGraphic";
import AddTaxi from "./Components/Admin/AddTaxi";
import TaxiStation from "./Components/TaxiStation";
import Information from "./Components/Admin/Information";
import Login from "./Components/Admin/Login";
import AddBookTaxi from "./Components/Admin/BookingDetails";
import LuxuriTrain from "./Components/LuxuriTrain";
import SingalLuxuryTrain from "./Components/SingalLuxuryTrain";
import LuxuryTrainImage from "./Components/Admin/LuxuryTrainImage";
import LuxuryTrain from "./Components/Admin/LuxuryTrain";
import Footer from "./Components/Footer";
import Hotel from "./Components/Hotel";
import AddPackage from "./Components/Admin/AddPackage";
import AddLocation from "./Components/Admin/AddLocation";
import HotelSub from "./Components/Subadmin/HotelSub";
import LuxuryTrainSub from "./Components/Subadmin/LuxuryTrainSub";
import SubAdmin from "./Components/Admin/SubAdmin";
import LoadgeSub from "./Components/Subadmin/LoadgeSub";
import VenderRegister from "./Components/Subadmin/VenderRegister";
import VenderLogin from "./Components/Subadmin/VenderLogin";
import SingalHotel from "./Components/SingalHotel";

import BookLodage from "./Components/Admin/BookLodage";
import InquireTrain from "./Components/Admin/InquireTrain";
import HotelGraph from "./Components/Admin/HotelGraph";
import AddHotel from "./Components/Admin/AddHotel";
import BookHotel from "./Components/Admin/BookHotel";
import GST from "./Components/Admin/GST";
import Vendor from "./Components/Admin/Vendor";
import BookHotelSub from "./Components/Subadmin/BookHotelSub";
import HomeStay from "./Components/Subadmin/HomeStay";
import BookHomeSub from "./Components/Subadmin/BookHomeSub";
import HomeStayH from "./Components/HomeStayH";
import SingalHomeStay from "./Components/SingalHomeStay";
import AddHomeStay from "./Components/Admin/AddHomeStay";
import BookHomeStayA from "./Components/Admin/BookHomeStayA";
import Resort from "./Components/Resort";
import ResortSub from "./Components/Subadmin/ResortSub";
import BookResortSub from "./Components/Subadmin/BookResortSub";
import AddResort from "./Components/Admin/AddResort";
import BookResort from "./Components/Admin/BookResort";
import Loader from "./Components/Loader";
import Payonline from "./Components/Payonline";
import Category from "./Components/Admin/Category";
import { Calendar } from "antd";
import { Calender } from "./Components/Admin/Calender";
import Wildlife from "./Components/Wildlife";
import WildlifeSingal from "./Components/WildlifeSingal";
import AllDestination from "./Components/AllDestination";
import Child from "./Components/Admin/Child";
import "react-multi-carousel/lib/styles.css";
import Check_In from "./Components/Subadmin/Check_In";
import Check_Out from "./Components/Subadmin/Check_Out";
import Rooms from "./Components/Subadmin/Rooms";
import RoomType from "./Components/Subadmin/RoomType";
import Dashboard from "./Components/Subadmin/Dashboard";
import Invoice from "./Components/Invoice";
import AdminGST from "./Components/Admin/AdminGST";
import AdminIGST from "./Components/Admin/AdminIgst";
import AdminVehicleCategory from "./Components/Admin/AdminVehicleCategory";
import AdminVehicleModel from "./Components/Admin/AdminVehicleModel";
import AdminOutStation from "./Components/Admin/AdminOutStation";
import AdminAddDrivers from "./Components/Admin/AdminAddDrivers";
import AdminAirport from "./Components/Admin/AdminAirport";
import AdminEnquiry from "./Components/Admin/AdminEnquiry";
import AdminExpense from "./Components/Admin/AdminExpense";
import AdminCollection from "./Components/Admin/AdminCollection";
import AdminLuxurycarenquiry from "./Components/Admin/AdminLuxurycarenquiry";
import Adminaddcarbrands from "./Components/Admin/Adminaddcarbrands";
import Adminaddcarmodel from "./Components/Admin/Adminaddcarmodel";
import AdminGenerateinvoice from "./Components/Admin/AdminGenerateinvoice";
import AdminTransaction from "./Components/Admin/AdminTransaction";
import { HotelInvoice } from "./Components/HotelInvoice";
import SubAdmin1 from "./Components/Subadmin/SubAdmin1";
import InvoiceLuxuryCarTrain from "./Components/InvoiceLuxuryCarTrain";
import { InvoiceJungle } from "./Components/InvoiceJungle";
import LuxuryTrainInvoice from "./Components/LuxuryTrainInvoice";
import { Bus } from "./Components/Bus";
import Busbooking from "./Components/Busbooking";
import Privacy from "./Components/Privacy";
import Disclaimer from "./Components/Disclaimer";
import TermsConditions from "./Components/Terms&Conditions";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Loader />
            </>
          }
        />
        <Route
          path="/home"
          element={
            <>
              <Header />
              <Home />
              <Footer />
            </>
          }
        />
        <Route
          path="/admin"
          element={
            <>
              <Login />
            </>
          }
        />
        <Route
          path="/luxury-train"
          element={
            <>
              <Header />
              <LuxuriTrain />
              <Footer />
            </>
          }
        />
        <Route
          path="/luxury-train/:id"
          element={
            <>
              <Header />
              <SingalLuxuryTrain />
              <Footer />
            </>
          }
        />
        <Route
          path="/contact-us"
          element={
            <>
              <Header />
              <Contact />
              <Footer />
            </>
          }
        />
        <Route
          path="/invoice/:id"
          element={
            <>
              <Header />
              <Invoice />
              <Footer />
            </>
          }
        />
        <Route
          path="/jungal-lodges"
          element={
            <>
              <Header />
              <Jungal />
              <Footer />
            </>
          }
        />
        <Route
          path="/resorts"
          element={
            <>
              <Header />
              <Resort />
              <Footer />
            </>
          }
        />
        <Route
          path="/taxi-here"
          element={
            <>
              <Header />
              <Taxi />
              <Footer />
            </>
          }
        />
        <Route
          path="/hotels"
          element={
            <>
              <Header />
              <Hotel />
              <Footer />
            </>
          }
        />
        {/*  */}
        <Route
          path="/wildlife"
          element={
            <>
              <Header />
              <Wildlife />
              <Footer />
            </>
          }
        />
        <Route
          path="/wildlife-singal/:id"
          element={
            <>
              <Header />
              <WildlifeSingal />
              <Footer />
            </>
          }
        />
        <Route
          path="/singal-lodge/:id"
          element={
            <>
              <Header />
              <Singallodge />
              <Footer />
            </>
          }
        />
        <Route
          path="/taxi-details"
          element={
            <>
              <Header />
              <SingalTaxi />
              <Footer />
            </>
          }
        />
        <Route
          path="/pay-online"
          element={
            <>
              <Header />
              <Payonline />
              <Footer />
            </>
          }
        />
        <Route
          path="/privacy&policy"
          element={
            <>
              <Header />
              <Privacy />
              <Footer />
            </>
          }
        />
        <Route
          path="/disclaimer"
          element={
            <>
              <Header />
              <Disclaimer />
              <Footer />
            </>
          }
        />
        <Route
          path="/terms&conditions"
          element={
            <>
              <Header />
              <TermsConditions />
              <Footer />
            </>
          }
        />
        <Route
          path="/taxi-station"
          element={
            <>
              <Header />
              <TaxiStation />
              <Footer />
            </>
          }
        />
        <Route
          path="/add-lodges"
          element={
            <>
              <Sidebar childern={<AddLodges />} />
            </>
          }
        />
        <Route
          path="/add-lodges-graphic"
          element={
            <>
              <Sidebar childern={<AddLodgeGraph />} />
            </>
          }
        />
        <Route
          path="/add-taxi-graphic"
          element={
            <>
              <Sidebar childern={<AddTaxiGraphic />} />
            </>
          }
        />
        <Route
          path="/lodge-category"
          element={
            <>
              <Sidebar childern={<Category />} />
            </>
          }
        />
        <Route
          path="/add-taxi"
          element={
            <>
              <Sidebar childern={<AddTaxi />} />
            </>
          }
        />
        <Route
          path="/add-home"
          element={
            <>
              <Sidebar childern={<AddHomeGraphic />} />
            </>
          }
        />
        <Route
          path="/hotel-graph"
          element={
            <>
              <Sidebar childern={<HotelGraph />} />
            </>
          }
        />
        <Route
          path="/information"
          element={
            <>
              <Sidebar childern={<Information />} />
            </>
          }
        />
        <Route
          path="/Admin_Expense"
          element={
            <>
              <Sidebar childern={<AdminExpense />} />
            </>
          }
        />
        <Route
          path="/Admin_invoice"
          element={
            <>
              <Sidebar childern={<AdminGenerateinvoice />} />
            </>
          }
        />
        <Route
          path="/Admin_car_brands"
          element={
            <>
              <Sidebar childern={<Adminaddcarbrands />} />
            </>
          }
        />
        <Route
          path="/Admin_car_model"
          element={
            <>
              <Sidebar childern={<Adminaddcarmodel />} />
            </>
          }
        />
        <Route
          path="/Admin_Statement"
          element={
            <>
              <Sidebar childern={<AdminTransaction />} />
            </>
          }
        />
        <Route
          path="/Admin_Collection"
          element={
            <>
              <Sidebar childern={<AdminCollection />} />
            </>
          }
        />
        <Route
          path="/Admin_Luxury_car_enquiry"
          element={
            <>
              <Sidebar childern={<AdminLuxurycarenquiry />} />
            </>
          }
        />
        <Route
          path="/add-book-taxi"
          element={
            <>
              <Sidebar childern={<AddBookTaxi />} />
            </>
          }
        />
        <Route
          path="/Lodge-book-details"
          element={
            <>
              <Sidebar childern={<BookLodage />} />
            </>
          }
        />
        <Route
          path="/Sub-Admin"
          element={
            <>
              <Sidebar childern={<SubAdmin />} />
            </>
          }
        />
        <Route
          path="/Resort-book-details"
          element={
            <>
              <Sidebar childern={<BookResort />} />
            </>
          }
        />
        <Route
          path="/Add-Hotel"
          element={
            <>
              <Sidebar childern={<AddHotel />} />
            </>
          }
        />
        <Route
          path="/booked-hotel-details"
          element={
            <>
              <Sidebar childern={<BookHotel />} />
            </>
          }
        />
        <Route
          path="/Inquire-train-details"
          element={
            <>
              <Sidebar childern={<InquireTrain />} />
            </>
          }
        />
        <Route
          path="/add-luxury-train"
          element={
            <>
              <Sidebar childern={<LuxuryTrain />} />
            </>
          }
        />
        <Route
          path="/add-vendor"
          element={
            <>
              <Sidebar childern={<Vendor />} />
            </>
          }
        />
        <Route
          path="/add-package"
          element={
            <>
              <Sidebar childern={<AddPackage />} />
            </>
          }
        />
        <Route
          path="/add-Resort"
          element={
            <>
              <Sidebar childern={<AddResort />} />
            </>
          }
        />
        <Route
          path="/Gst"
          element={
            <>
              <Sidebar childern={<GST />} />
            </>
          }
        />
        <Route
          path="/children"
          element={
            <>
              <Sidebar childern={<Child />} />
            </>
          }
        />
        <Route
          path="/Add-Home-Stay-Hotel"
          element={
            <>
              <Sidebar childern={<AddHomeStay />} />
            </>
          }
        />
        <Route
          path="/Book-Home-Stay-Hotel"
          element={
            <>
              <Sidebar childern={<BookHomeStayA />} />
            </>
          }
        />
        <Route
          path="/add-location"
          element={
            <>
              <Sidebar childern={<AddLocation />} />
            </>
          }
        />
        <Route
          path="/luxury-train-img"
          element={
            <>
              <Sidebar childern={<LuxuryTrainImage />} />
            </>
          }
        />
        <Route
          path="/admin_gst"
          element={
            <>
              <Sidebar childern={<AdminGST />} />
            </>
          }
        />
        <Route
          path="/admin_igst"
          element={
            <>
              <Sidebar childern={<AdminIGST />} />
            </>
          }
        />
        <Route
          path="/admin_vehicle_Category"
          element={
            <>
              <Sidebar childern={<AdminVehicleCategory />} />
            </>
          }
        />
        <Route
          path="/admin_airport"
          element={
            <>
              <Sidebar childern={<AdminAirport />} />
            </>
          }
        />
        <Route
          path="/Enquiries"
          element={
            <>
              <Sidebar childern={<AdminEnquiry />} />
            </>
          }
        />
        <Route
          path="/admin_outstation"
          element={
            <>
              <Sidebar childern={<AdminOutStation />} />
            </>
          }
        />
        <Route
          path="/admin_add_drivers"
          element={
            <>
              <Sidebar childern={<AdminAddDrivers />} />
            </>
          }
        />
        <Route
          path="/admin_vehicle_Category_Model"
          element={
            <>
              <Sidebar childern={<AdminVehicleModel />} />
            </>
          }
        />
        <Route
          path="/calender"
          element={
            <>
              <Sidebar childern={<Calender />} />
            </>
          }
        />
        {/* ugiuhj */}
        <Route
          path="/singal-hotel/:id"
          element={
            <>
              <Header />
              <SingalHotel />
              <Footer />
            </>
          }
        />
        <Route
          path="/Hotel-Invoice/:id"
          element={
            <>
              <Header />
              <HotelInvoice />
              <Footer />
            </>
          }
        />
        <Route
          path="/Luxury-Invoice/:id"
          element={
            <>
              <Header />
              <InvoiceLuxuryCarTrain />
              <Footer />
            </>
          }
        />
        <Route
          path="/Luxury-Train-Invoice/:id"
          element={
            <>
              <Header />
              <LuxuryTrainInvoice />
              <Footer />
            </>
          }
        />
        <Route
          path="/Bus"
          element={
            <>
              <Header />
              <Bus />
              <Footer />
            </>
          }
        />
        <Route
          path="/Busbooking"
          element={
            <>
              <Header />
              <Busbooking />
              <Footer />
            </>
          }
        />
        <Route
          path="/Jungle-Lodge-Invoice/:id"
          element={
            <>
              <Header />
              <InvoiceJungle />
              <Footer />
            </>
          }
        />
        <Route
          path="/vendor-register"
          element={
            <>
              <Header />
              <VenderRegister />
              <Footer />
            </>
          }
        />
        <Route
          path="/home-stay"
          element={
            <>
              <Header />
              <HomeStayH />
              <Footer />
            </>
          }
        />
        <Route
          path="/home-stay/:id"
          element={
            <>
              <Header />
              <SingalHomeStay />
              <Footer />
            </>
          }
        />
        <Route
          path="/our-best-packages"
          element={
            <>
              <Header />
              <AllDestination />
              <Footer />
            </>
          }
        />
        <Route
          path="/vender-login"
          element={
            <>
              {/* <Header /> */}
              <VenderLogin />
              {/* <Footer /> */}
            </>
          }
        />
        <Route
          path="/singal-lodge/:id"
          element={
            <>
              <Header />
              <Singallodge />
              <Footer />
            </>
          }
        />
        {/* Vendor */}
        <Route
          path="/add-vender-hotel"
          element={
            <>
              <SubAdmin1 childern={<HotelSub />} />
            </>
          }
        />
        <Route
          path="/vendor-book-hotel"
          element={
            <>
              <SubAdmin1 childern={<BookHotelSub />} />
            </>
          }
        />
        <Route
          path="/add-vender-loadge"
          element={
            <>
              <SubAdmin1 childern={<LoadgeSub />} />
            </>
          }
        />
        <Route
          path="/vendor-home-stay"
          element={
            <>
              <SubAdmin1 childern={<HomeStay />} />
            </>
          }
        />
        <Route
          path="/vendor-resorts"
          element={
            <>
              <SubAdmin1 childern={<ResortSub />} />
            </>
          }
        />
        <Route
          path="/vendor-resorts-recipt"
          element={
            <>
              <SubAdmin1 childern={<BookResortSub />} />
            </>
          }
        />
        <Route
          path="/vendor-book-home-stay"
          element={<SubAdmin1 childern={<BookHomeSub />} />}
        />
        <Route
          path="/vendor-check-in"
          element={<SubAdmin1 childern={<Check_In />} />}
        />
        <Route
          path="/vendor-check-out"
          element={<SubAdmin1 childern={<Check_Out />} />}
        />
        <Route
          path="/vendor-rooms"
          element={<SubAdmin1 childern={<Rooms />} />}
        />
        <Route
          path="/vendor-room-type"
          element={<SubAdmin1 childern={<RoomType />} />}
        />
        <Route
          path="/dashboard"
          element={<SubAdmin1 childern={<Dashboard />} />}
        />
        {/* ihj */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
