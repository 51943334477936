import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { AiFillEdit } from "react-icons/ai";
import { MdDeleteForever, MdDone } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Offcanvas } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { BsEye, BsFillEyeFill } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { RxCross2 } from "react-icons/rx";
// import { Button } from "react-bootstrap";
function SubAdmin() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [password, setpassword] = useState("");
  const [SubAdmin, setsubadmin] = useState(false);
  const [HomeS, setHomeS] = useState(false);
  const [jungleGraph, setjungleGraph] = useState(false);
  const [Vehicle, setVehicle] = useState(false);
  const [hotelGraph, sethotelGraph] = useState(false);
  const [taxiGraph, settaxiGraph] = useState(false);
  const [luxuryTrainGraph, setluxuryTrainGraph] = useState(false);
  const [gst, setgst] = useState(false);
  const [igst, setigst] = useState(false);
  const [location, setlocation] = useState(false);
  const [children, setchildren] = useState(false);

  const [hotel, sethotel] = useState(false);
  const [vendorS, setvendorS] = useState(false);
  const [hotelBooking, sethotelBooking] = useState(false);
  const [account, setaccount] = useState(false);
  const [expense, setexpense] = useState(false);
  const [resort, setresort] = useState(false);
  const [resortBooking,setresortBooking]=useState(false);
  const [lodgeCategory,setlodgeCategory]=useState(false);
  const [lodge,setlodge]=useState(false);
  const [lodgeBooking,setlodgeBooking]=useState(false);
  const [luxuryTrain,setluxuryTrain]=useState(false);
  const [luxuryTrainBooking ,setluxuryTrainBooking ]=useState(false);
  const [taxiManger,settaxiManger]=useState(false);
  const [taxiBooking,settaxiBooking]=useState(false);
  const [HomeStay,setHomeStay]=useState(false);
  const [HomeStayBooking,setHomeStayBooking]=useState(false);
  const [LuxuryCar,setLuxuryCar]=useState(false);
  const [LuxuryCarEnquiry,setLuxuryCarEnquiry]=useState(false);
  const [luxuryCarModel,setluxuryCarModel]=useState(false);
  const [genrelEnquiry,setgenrelEnquiry]=useState(false);
  const [InvoiceGen,setInvoiceGen]=useState(false);
  const [driverCash,setdriverCash]=useState(false);
  const [contactus,setcontactus]=useState(false);

  const token = sessionStorage.getItem("token");
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const [AllsubAdmin, setAllSubAdmin] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);

  const getAllSubadmin = async () => {
    try {
      const config = {
        url: "/admin/getAllSubAdmin",
        method: "get",
        baseURL: "https://dhanyatours.me/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllSubAdmin(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const mobileNumberRegex = /^[0-9]{10}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const AddSubadminDetails = async () => {
    if(!name) return alert("Please enter name");
    if(!mobile) return alert("Please enter mobile number");
    if(!mobileNumberRegex.test(mobile)) return alert("Invalid mobile number")
    if(!email) return alert("Please enter email id");
    if(!emailRegex.test(email)) return alert("Invalid email id")
    if(!password) return alert("Please enter password");
    try {
      const config = {
        url: "/admin/registerSubAdmin",
        method: "post",
        baseURL: "https://dhanyatours.me/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
          name,
          email,
          password,
contactus,
          mobile,
          SubAdmin,
          HomeS,
          jungleGraph,
          Vehicle,
          hotelGraph,
          taxiGraph,
          luxuryTrainGraph,
          gst,
          igst,
          location,
          children,
          resort,
          vendorS,
          hotelBooking,
          accountHistory: account,
          hotel,
          expanse:expense,
          resort,
          resortBooking,
          lodgeCategory,
          lodge,
          lodgeBooking,
          luxuryTrain,
          luxuryTrainBooking,
          taxiManger,
          taxiBooking,
          HomeStay,
          HomeStayBooking,
          LuxuryCar,
          LuxuryCarEnquiry,
          luxuryCarModel,
          genrelEnquiry,
          InvoiceGen,
          driverCash
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Added");
        handleClose();
        getAllSubadmin();
       
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  };

  // console.log("check==>", name, email, password, mobile,SubAdmin,HomeS,jungleGraph, Vehicle,hotelGraph,taxiGraph,luxuryTrainGraph,gst,igst,location,children,resort,vendorS,hotelBooking,account,hotel ,expense);
  useEffect(() => {
 
      getAllSubadmin();
    
  }, []);
  const [edit, setedit] = useState({});
  const handleShow1 = (data) => {
    setname(data?.name);
    setedit(data);
    setsubadmin(data?.SubAdmin);
    setHomeS(data?.HomeS);
    setjungleGraph(data?.jungleGraph);
    setgst(data?.gst);
    setresort(data?.resort);
    settaxiGraph(data?.taxiGraph);
    setluxuryTrainGraph(data?.luxuryTrainGraph);
    sethotelGraph(data?.hotelGraph);
    setlocation(data?.location);
    sethotelBooking(data?.hotelBooking);
    sethotel(data?.hotel);
    setchildren(data?.children);
    setvendorS(data?.vendorS);
    setexpense(data?.expanse);
    setaccount(data?.accountHistory);
    setVehicle(data?.Vehicle);
    setigst(data?.igst);
    setresort(data?.resort);
    setresortBooking(data?.resortBooking);
    setlodgeCategory(data?.lodgeCategory);
    setlodgeBooking(data?.lodgeBooking);
    setluxuryTrain(data?.luxuryTrain);
    setluxuryTrainBooking(data?.luxuryTrainBooking);
    settaxiManger(data?.taxiManger);
    settaxiBooking(data?.taxiBooking);
    setHomeStay(data?.HomeStay);
    setHomeStayBooking(data?.HomeStayBooking);
    setLuxuryCar(data?.LuxuryCar);
    setLuxuryCarEnquiry(data?.LuxuryCarEnquiry);
    setluxuryCarModel(data?.luxuryCarModel);
    setgenrelEnquiry(data?.genrelEnquiry);
    setInvoiceGen(data?.InvoiceGen);
    setdriverCash(data?.driverCash);
    setcontactus(data?.contactus);
    setShow1(true);
  };

  const updateSubAdmin = async () => {
    if(mobile){
      if(!mobileNumberRegex.test(mobile)) return alert("Invalid mobile number")
    }
    if(email){
      if(!emailRegex.test(email)) return alert("Invalid email id")
    }
    try {
      const config = {
        url: "/admin/editSubAdmin",
        method: "put",
        baseURL: "https://dhanyatours.me/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          adminId: edit?._id,
   
          name,
          email,
          password,
          mobile,
          SubAdmin,
          HomeS,
          jungleGraph,
          Vehicle,
          hotelGraph,
          taxiGraph,
          luxuryTrainGraph,
          gst,
          contactus,
          igst,
          location,
          children,
          resort,
          vendorS,
          hotelBooking,
          accountHistory: account,
          hotel,
          expanse:expense,
          resort,
          resortBooking,
          lodgeCategory,
          lodge,
          lodgeBooking,
          luxuryTrain,
          luxuryTrainBooking,
          taxiManger,
          taxiBooking,
          HomeStay,
          HomeStayBooking,
          LuxuryCar,
          LuxuryCarEnquiry,
          luxuryCarModel,
          genrelEnquiry,
          InvoiceGen,
          driverCash

        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully updated");
        handleClose1();
        getAllSubadmin();
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  };

  const [deleteId,setdeleteId]=useState("");
  const [showD,setShowD]=useState(false);

  const deleteSubAdmin=async()=>{
    try {
      const config={
        url: "/admin/deleteSubAdmin/"+deleteId,
        method: "delete",
        baseURL: "https://dhanyatours.me/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      }
      let res=await axios(config);
      if(res.status==200){
        alert("Successfully Deleted");
        setShowD(false);
        getAllSubadmin();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllsubAdmin?.filter((ele) => ele?.role !== "admin" && ele?._id!=admin?._id).length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllSubAdmin([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAllSubAdmin([...nochangedata]);
    }
  };

  const [showdata, setshowdata] = useState({});
const [show4,setshow4]=useState(false)
  return (
    <>
      <div className="add-gr ms-5">
        <div className="container">
          <div className="ad-b mt-4">
            <h3>ALL SUBADMIN LIST </h3>
            <Button onClick={()=>{
                 setname("");
                 setemail("");
                 setpassword("");
                 setmobile("");
                 setsubadmin(false);
                 setHomeS(false);
                 setjungleGraph(false);
                 setgst(false);
                 setresort(false);
                 settaxiGraph(false);
                 setluxuryTrainGraph(false);
                 sethotelGraph(false);
                 setlocation(false);
                 sethotelBooking(false);
                 sethotel(false);
                 setchildren(false);
                 setvendorS(false);
                 setexpense(false);
                 setaccount(false);
                 setVehicle(false);
                 setigst(false);
                 setresort(false);
                 setcontactus(false);
                 setresortBooking(false);
                 setlodgeCategory(false);
                 setlodgeBooking(false);
                 setluxuryTrain(false);
                 setluxuryTrainBooking(false);
                 settaxiManger(false);
                 settaxiBooking(false);
                 setHomeStay(false);
                 setHomeStayBooking(false);
                 setLuxuryCar(false);
                 setLuxuryCarEnquiry(false);
                 setluxuryCarModel(false);
                 setgenrelEnquiry(false);
                 setInvoiceGen(false);
                 setdriverCash(false);
              handleShow()}} style={{ float: "right",borderRadius:"10px"}}>
             + Add 
            </Button>
          </div>
        </div>

        <div className="container">
          <div className="mo-gra mt-5">
            <div className="row">
              <div className="col-3">
              <div className="searchWrapper" style={{ display: "flex" }}>
              <input
                type="text"
                maxlength="230"
                style={{ outlineWidth: "0px", padding: "4px" }}
                className="textsearch"
                placeholder="Search by ....."
                onChange={handleFilter}
              />
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 16 16"
                id="BsSearch"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                  clip-rule="evenodd"
                ></path>
                <path
                  fill-rule="evenodd"
                  d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
              </div>
            </div>

            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                  {/* <th>User id</th> */}
                  <th>Name</th>
                  <th>Contact No</th>
                  <th>Email Id</th>
                  <th>Access Modules</th>
                  <th>Date of Creation</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {AllsubAdmin?.filter((ele) => ele?.role !== "admin" && ele?._id!=admin?._id)  ?.slice(pagesVisited, pagesVisited + usersPerPage)?.map(
                  (item, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{item?.name}</td>
                        <td>{item?.mobile}</td>
                        <td>{item?.email}</td>
                        <td >
                         <div style={{textAlign:"-webkit-center"}}>
                           <BsFillEyeFill
                            style={{
                              color: "blue",
                              fontSize: "28px",
                              cursor: "pointer",

                            }}
                            onClick={()=>{
                              setshowdata(item);
                              setshow4(true);
                            }}
                          />
                         </div>

                        </td>

                        <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                        <td style={{display:"flex",gap:"3px"}}>
                          <AiFillEdit
                            style={{
                              fontSize: "27px",
                              color: "blue",
                          
                              cursor: "pointer",
                            }}
                            onClick={() => handleShow1(item)}
                          />
                          <MdDeleteForever
                            style={{
                              fontSize: "27px",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={()=>{
                              setdeleteId(item?._id);
                              setShowD(true);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </Table>
            <br/>
            <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
          </div>
        </div>
      </div>

      {/* Add Model */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add SubAdmin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="fwei">
            <div className="mb-2">
              <input
                type="text"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #004aad",
                  height: "50px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setname(e.target.value)}
                placeholder="Sub Admin Name"
              />
            </div>
            <div className="mb-2">
              <input
                type="number"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #004aad",
                  height: "50px",
                  borderRadius: "20px",
                }}
                placeholder="Phone Number"
                onChange={(e) => setmobile(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <input
                type="email"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #004aad",
                  height: "50px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setemail(e.target.value)}
                placeholder="Email Id"
              />
            </div>

            <div className="mb-2">
              <input
                type="password"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #004aad",
                  height: "50px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setpassword(e.target.value)}
                placeholder="Password"
              />
            </div>
          </div>
          <div className="dgier mt-4">
            <h4 style={{ color: "#004aad" }}>Access Modules</h4>
            <div className="grse" style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={SubAdmin}
                  onChange={() => setsubadmin(!SubAdmin)}
                />
                <label>SubAdmin</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={HomeS}
                  onChange={() => setHomeS(!HomeS)}
                />
                <label>Home</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={jungleGraph}
                  onChange={() => setjungleGraph(!jungleGraph)}
                />
                <label>Jungle Graph</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={taxiGraph}
                  onChange={() => settaxiGraph(!taxiGraph)}
                />
                <label>Taxi Graph</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={hotelGraph}
                  onChange={() => sethotelGraph(!hotelGraph)}
                />
                <label>Hotel Graph</label>
              </div>
        
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={luxuryTrainGraph}
                  onChange={() => setluxuryTrainGraph(!luxuryTrainGraph)}
                />
                <label>
                    Luxury Train Graph
                </label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={gst}
                  onChange={() => setgst(!gst)}
                />
                <label>GST</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={igst}
                  onChange={() => setigst(!igst)}
                />
                <label>IGST</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={location}
                  onChange={() => setlocation(!location)}
                />
                <label>Location Management</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={children}
                  onChange={() => setchildren(!children)}
                />
                <label>Children Age</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={vendorS}
                  onChange={() => setvendorS(!vendorS)}
                />
                <label>Vendors</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={hotel}
                  onChange={() => sethotel(!hotel)}
                />
                <label>Hotel</label>
              </div>
   
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={hotelBooking}
                  onChange={() => sethotelBooking(!hotelBooking)}
                />
                <label>Hotel Booking</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={HomeStay}
                  onChange={() => setHomeStay(!HomeStay)}
                />
                <label>Home Stay</label>
              </div>
   
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={HomeStayBooking}
                  onChange={() => setHomeStayBooking(!HomeStayBooking)}
                />
                <label>Home Stay Booking</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={resort}
                  onChange={() => setresort(!resort)}
                />
                <label>Resort List</label>
              </div>
   
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={resortBooking}
                  onChange={() => setresortBooking(!resortBooking)}
                />
                <label>Resort Booking</label>
              </div>
              {/* <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={resort}
                  onChange={() => setresort(!resort)}
                />
                <label>resorts</label>
              </div> */}
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={lodgeCategory}
                  onChange={() => setlodgeCategory(!lodgeCategory)}
                />
                <label>Lodge Category</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={lodge}
                  onChange={() => setlodge(!lodge)}
                />
                <label>Lodge</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={lodgeBooking}
                  onChange={() => setlodgeBooking(!lodgeBooking)}
                />
                <label>Lodge Enquiry</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={luxuryTrain}
                  onChange={() => setluxuryTrain(!luxuryTrain)}
                />
                <label>Luxury Train</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={luxuryTrainBooking}
                  onChange={() => setluxuryTrainBooking(!luxuryTrainBooking)}
                />
                <label>Luxury Train Enquiry</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={taxiManger}
                  onChange={() => settaxiManger(!taxiManger)}
                />
                <label>Taxi Management</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={taxiBooking}
                  onChange={() => settaxiBooking(!taxiBooking)}
                />
                <label>Taxi Booking</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={LuxuryCar}
                  onChange={() => setLuxuryCar(!LuxuryCar)}
                />
                <label>Luxury Car Brand</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={luxuryCarModel}
                  onChange={() => setluxuryCarModel(!luxuryCarModel)}
                />
                <label>Luxury Car Model</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={LuxuryCarEnquiry}
                  onChange={() => setLuxuryCarEnquiry(!LuxuryCarEnquiry)}
                />
                <label>Luxury Car Enquiry</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={genrelEnquiry}
                  onChange={() => setgenrelEnquiry(!genrelEnquiry)}
                />
                <label>General  Enquiry</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={contactus}
                  onChange={() => setcontactus(!genrelEnquiry)}
                />
                <label>Contact Us</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={expense}
                  onChange={() => setexpense(!expense)}
                />
                <label>Expanse</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={driverCash}
                  onChange={() => setdriverCash(!driverCash)}
                />
                <label>Cash Collection</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={InvoiceGen}
                  onChange={() => setInvoiceGen(!InvoiceGen)}
                />
                <label>Invoice Generation</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={account}
                  onChange={() => setaccount(!account)}
                />
                <label>Account History</label>
              </div>
         
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={AddSubadminDetails}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Edit  Model */}
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit SubAdmin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="fwei">
            <div className="mb-2">
              <input
                type="text"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #004aad",
                  height: "50px",
                  borderRadius: "20px",
                }}
                value={name}
                onChange={(e) => setname(e.target.value)}
                placeholder="Sub Admin Name"
              />
            </div>
            <div className="mb-2">
              <input
                type="number"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #004aad",
                  height: "50px",
                  borderRadius: "20px",
                }}
                value={mobile}
                placeholder={edit?.mobile}
                onChange={(e) => setmobile(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <input
                type="email"
                value={email}
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #004aad",
                  height: "50px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setemail(e.target.value)}
                placeholder={edit?.email}
              />
            </div>

            <div className="mb-2">
              <input
                type="password"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #004aad",
                  height: "50px",
                  borderRadius: "20px",
                }}
                value={password}
                onChange={(e) => setpassword(e.target.value)}
                placeholder="Password"
              />
            </div>
          </div>
          <div className="dgier mt-4">
            <h4 style={{ color: "#004aad" }}>Access Modules</h4>
            <div className="grse" style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={SubAdmin}
                  onChange={() => setsubadmin(!SubAdmin)}
                />
                <label>SubAdmin</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={HomeS}
                  onChange={() => setHomeS(!HomeS)}
                />
                <label>Home</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={jungleGraph}
                  onChange={() => setjungleGraph(!jungleGraph)}
                />
                <label>Jungle Graph</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={taxiGraph}
                  onChange={() => settaxiGraph(!taxiGraph)}
                />
                <label>Taxi Graph</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={hotelGraph}
                  onChange={() => sethotelGraph(!hotelGraph)}
                />
                <label>Hotel Graph</label>
              </div>
        
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={luxuryTrainGraph}
                  onChange={() => setluxuryTrainGraph(!luxuryTrainGraph)}
                />
                <label>
                    Luxury Train Graph
                </label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={gst}
                  onChange={() => setgst(!gst)}
                />
                <label>GST</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={igst}
                  onChange={() => setigst(!igst)}
                />
                <label>IGST</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={location}
                  onChange={() => setlocation(!location)}
                />
                <label>Location Management</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={children}
                  onChange={() => setchildren(!children)}
                />
                <label>Children Age</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={vendorS}
                  onChange={() => setvendorS(!vendorS)}
                />
                <label>Vendors</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={hotel}
                  onChange={() => sethotel(!hotel)}
                />
                <label>Hotel</label>
              </div>
   
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={hotelBooking}
                  onChange={() => sethotelBooking(!hotelBooking)}
                />
                <label>Hotel Booking</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={HomeStay}
                  onChange={() => setHomeStay(!HomeStay)}
                />
                <label>Home Stay</label>
              </div>
   
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={HomeStayBooking}
                  onChange={() => setHomeStayBooking(!HomeStayBooking)}
                />
                <label>Home Stay Booking</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={resort}
                  onChange={() => setresort(!resort)}
                />
                <label>Resort List</label>
              </div>
   
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={resortBooking}
                  onChange={() => setresortBooking(!resortBooking)}
                />
                <label>Resort Booking</label>
              </div>
              {/* <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={resort}
                  onChange={() => setresort(!resort)}
                />
                <label>resorts</label>
              </div> */}
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={lodgeCategory}
                  onChange={() => setlodgeCategory(!lodgeCategory)}
                />
                <label>Lodge Category</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={lodge}
                  onChange={() => setlodge(!lodge)}
                />
                <label>Lodge</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={lodgeBooking}
                  onChange={() => setlodgeBooking(!lodgeBooking)}
                />
                <label>Lodge Enquiry</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={luxuryTrain}
                  onChange={() => setluxuryTrain(!luxuryTrain)}
                />
                <label>Luxury Train</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={luxuryTrainBooking}
                  onChange={() => setluxuryTrainBooking(!luxuryTrainBooking)}
                />
                <label>Luxury Train Enquiry</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={taxiManger}
                  onChange={() => settaxiManger(!taxiManger)}
                />
                <label>Taxi Management</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={taxiBooking}
                  onChange={() => settaxiBooking(!taxiBooking)}
                />
                <label>Taxi Booking</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={LuxuryCar}
                  onChange={() => setLuxuryCar(!LuxuryCar)}
                />
                <label>Luxury Car Brand</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={luxuryCarModel}
                  onChange={() => setluxuryCarModel(!luxuryCarModel)}
                />
                <label>Luxury Car Model</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={LuxuryCarEnquiry}
                  onChange={() => setLuxuryCarEnquiry(!LuxuryCarEnquiry)}
                />
                <label>Luxury Car Enquiry</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={genrelEnquiry}
                  onChange={() => setgenrelEnquiry(!genrelEnquiry)}
                />
                <label>General  Enquiry</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={contactus}
                  onChange={() => setcontactus(!genrelEnquiry)}
                />
                <label>Contact Us</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={expense}
                  onChange={() => setexpense(!expense)}
                />
                <label>Expanse</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={driverCash}
                  onChange={() => setdriverCash(!driverCash)}
                />
                <label>Cash Collection</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={InvoiceGen}
                  onChange={() => setInvoiceGen(!InvoiceGen)}
                />
                <label>Invoice Generation</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #004aad",
                    borderRadius: "20px",
                  }}
                  checked={account}
                  onChange={() => setaccount(!account)}
                />
                <label>Account History</label>
              </div>
         
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={updateSubAdmin}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

       {/* Delete  Model */}
       <Modal
        show={showD}
        onHide={()=>setShowD(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete ??</Modal.Title>
        </Modal.Header>
     
        <Modal.Footer>
          <Button variant="danger" onClick={()=>setShowD(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={deleteSubAdmin}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Offcanvas show={show4} onHide={()=>setshow4(false)}>
        <Offcanvas.Header style={{backgroundColor:"#004aad",color:"white"}} closeButton>
          <Offcanvas.Title>Sub Admin All Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="efqevs mb-2">
            <div className="mt-2 mb-2">
              <h5 style={{ color: "black" }}>Name: {showdata?.name}</h5>
            </div>
            <div className="mt-2 mb-2">
              <h5 style={{ color: "black" }}>Email: {showdata?.email}</h5>
            </div>
            <div className="mt-2 mb-2">
              <h5 style={{ color: "black" }}>Mobile : {showdata?.mobile}</h5>
            </div>
          </div>
          <div style={{ fontSize: "25px", fontWeight: "700", color: "black" }}>
            Access Modules
          </div>
          <ul style={{ listStyle: "none" }}>
            <li style={{ padding: "2px" }}>
            SubAdmin{" "}
              <span>
                {showdata?.SubAdmin == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Home{" "}
              <span>
                {showdata?.HomeS == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Jungle Graph{" "}
              <span>
                {showdata?.jungleGraph == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Taxi Graph{" "}
              <span>
                {showdata?.taxiGraph == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Hotel Graph{" "}
              <span>
                {showdata?.hotelGraph == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Luxury Train Graph{" "}
              <span>
                {showdata?.luxuryTrainGraph == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            GST{" "}
              <span>
                {showdata?.gst == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            IGST{" "}
              <span>
                {showdata?.igst == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Location Management{" "}
              <span>
                {showdata?.location == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Children Age{" "}
              <span>
                {showdata?.children == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Vendors{" "}
              <span>
                {showdata?.vendorS == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Hotel{" "}
              <span>
                {showdata?.hotel == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Hotel Booking{" "}
              <span>
                {showdata?.hotelBooking == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Home Stay{" "}
              <span>
                {showdata?.HomeStay == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Home Stay Booking{" "}
              <span>
                {showdata?.HomeStayBooking == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Resort List{" "}
              <span>
                {showdata?.resort == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Resort Booking{" "}
              <span>
                {showdata?.resortBooking == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Lodge Category{" "}
              <span>
                {showdata?.lodgeCategory == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Lodge {" "}
              <span>
                {showdata?.lodge == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Lodge Enquiry{" "}
              <span>
                {showdata?.lodgeBooking == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>

            <li style={{ padding: "2px" }}>
            Luxury Train{" "}
              <span>
                {showdata?.luxuryTrain == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Luxury Train Enquiry{" "}
              <span>
                {showdata?.luxuryTrainBooking == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Taxi Management{" "}
              <span>
                {showdata?.taxiManger == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Taxi Booking{" "}
              <span>
                {showdata?.taxiBooking == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Luxury Car Brand{" "}
              <span>
                {showdata?.LuxuryCar == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Luxury Car Model{" "}
              <span>
                {showdata?.luxuryCarModel == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Luxury Car Enquiry{" "}
              <span>
                {showdata?.LuxuryCarEnquiry == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            General  Enquiry{" "}
              <span>
                {showdata?.genrelEnquiry == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Contact Us{" "}
              <span>
                {showdata?.contactus == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Expanse{" "}
              <span>
                {showdata?.expanse == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Cash Collection{" "}
              <span>
                {showdata?.driverCash == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Invoice Generation{" "}
              <span>
                {showdata?.InvoiceGen == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
        
            <li style={{ padding: "2px" }}>
            Account History{" "}
              <span>
                {showdata?.accountHistory == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
       
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default SubAdmin;
