import axios from "axios";
import React, { useState } from "react";
import "./login.scoped.scss";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function Login() {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const adminLogin = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/login",
        method: "post",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          email: email,
          password: password,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        sessionStorage.setItem("admin", JSON.stringify(res.data.success));
        alert("Successfully Login");
        window.location.assign("/add-home");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log("Error==>", error);
    }
  };

  return (
    <>
      <div
        className="town"
        style={{
          backgroundImage: "url('../Image/bag-admin.jpg')",
          height: "100vh",
          backgroundSize: "cover",
        }}
      >
        <div id="login-form-wrap">
          <h2 style={{ fontWeight: "800" }}>Login</h2>
          <form id="login-form" onSubmit={(e) => adminLogin(e)}>
            <p>
              <input
                value={email}
                type="text"
                id="username"
                name="username"
                placeholder="Email"
                required
                onChange={(e) => setemail(e.target.value)}
                style={{ backgroundColor: "white" }}
              />
              {/* <i class="validation">
                <span></span>
                <span></span>
              </i> */}
            </p>
            <div style={{ position: "relative" }}>
              <input
                value={password}
                type={passwordVisible ? "text" : "password"}
                id="email"
                name="email"
                placeholder="Password"
                required
                onChange={(e) => setpassword(e.target.value)}
              />
              {passwordVisible ? (
                <>
                  <AiFillEyeInvisible
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    style={{
                      position: "absolute",
                      color: "#000",
                      bottom: "32%",
                      right: "5%",
                      cursor: "pointer",
                    }}
                  />
                </>
              ) : (
                <>
                  {" "}
                  <AiFillEye
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    style={{
                      position: "absolute",
                      color: "#000",
                      bottom: "32%",
                      right: "5%",
                      cursor: "pointer",
                    }}
                  />
                </>
              )}
              {/* <i class="validation">
                <span></span>
                <span></span>
              </i> */}
            </div>
            <br />
            <p>
              <input type="submit" id="login" value="Login" />
            </p>
          </form>
          <div id="create-account-wrap"></div>
        </div>
      </div>
    </>
  );
}

export default Login;
