import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  AiFillDelete,
  AiOutlineDelete,
  AiOutlineDownload,
} from "react-icons/ai";
import { FaEdit, FaEye } from "react-icons/fa";
import Table1 from "react-bootstrap/Table";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";

function AddBookTaxi() {
  const [odometer, setodometer] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show5, setShow5] = useState(false);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);

  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const [show8, setShow8] = useState(false);

  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show7, setShow7] = useState(false);

  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [deleteId, setDeleteId] = useState("");
  // const location = useLocation;
  const [VechicleId, setVechicleId] = useState("");
  const [Username, setUsername] = useState("");
  const [Number, setNumber] = useState("");
  const [Email, setEmail] = useState("");
  const [PicKUpPlace, setPicKUpPlace] = useState("");
  const [PicKUpDate, setPicKUpDate] = useState("");
  const [DropPlace, setDropPlace] = useState("");
  const [DropDate, setDropDate] = useState(moment().format("YYYY-MM-DD"));
  const [DropTime, setDropTime] = useState(moment().format("LT"));
  const [Bata, setBata] = useState("");
  const [PackageV, setPackageV] = useState("");
  const [tableFilter, settableFilter] = useState([]);
  const [booktaxi, setbooktaxi] = useState([]);
  // console.log(graphImage, "orvih");
  const getBooking = async () => {
    const config = {
      url: "/getallOrder",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setbooktaxi(result.data.success);
        settableFilter(result.data.success);
        getGst();
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDelete = async () => {
    const config = {
      url: "/deleteOrder/" + deleteId,
      method: "delete",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        getBooking();
        handleClose1();
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  const [gst, setgst] = useState({});
  const getGst = async () => {
    const config = {
      url: "/getGst",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setgst(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getBooking();
  }, []);
  // console.log(booktaxi, "Swagat to karo hamara");

  const [bookingtaxi, setbookingtaxi] = useState({});

  let [PaymentMethod, setPaymentMethod] = useState("");
  let [GST, setGST] = useState(0);
  const [ParkingCharge, setParkingCharge] = useState("");
  let [extraHour, setextrahrs] = useState("");
  let [extraKm, setExtraKm] = useState(0);
  let [tollCharge, settoll] = useState("");
  let [extraKmPrice, setkmprice] = useState(0);
  const [PermintN, setPermintN] = useState("");
  const [PermintPrice, setPermintPrice] = useState("");
  const [servicePrice, setservicePrice] = useState("");
  const editBookingTaxi = async () => {
    let tripData = bookingtaxi?.TripData;
    if (tripData) {
      tripData = JSON.parse(tripData);
    }
    if (bookingtaxi?.tripType === "Local Drop" && extraHour) {
      extraHour = tripData?.PerMinutesPrice * extraHour;
    }
    let obj = { bookId: bookingtaxi?._id };

    let total = parseInt(bookingtaxi?.FixedPrice);
    if (PermintN) {
      obj["PermintN"] = PermintN;
    }
    if (PaymentMethod) {
      obj["paymentMethod"] = PaymentMethod;
    }
    if (PermintPrice) {
      total = total + parseInt(PermintPrice);
      obj["PermintPrice"] = PermintPrice;
    }

    if (servicePrice) {
      total = total + parseInt(servicePrice);
      obj["servicePrice"] = servicePrice;
    }
    if (ParkingCharge) {
      total = total + parseInt(ParkingCharge);
      obj["ParkingCharge"] = ParkingCharge;
    }

    if (tollCharge) {
      total = total + parseInt(tollCharge);
      obj["tollCharge"] = tollCharge;
    }
    if (extraHour) {
      total = total + parseInt(extraHour);
      obj["extraHrPrice"] = extraHour;
    }
    obj["totalAmount"] = total;

    try {
      const config = {
        url: "/editOrder",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: obj,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Success");
        getBooking();
        handleClose();
        setextrahrs("");
        setExtraKm(0);
        settoll("");
        setParkingCharge("");
        setservicePrice("");
        setParkingCharge("");
        setPermintN("");
        setPermintPrice("");
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const makeConfirm = async (id) => {
    try {
      const config = {
        url: "/makeOderStatusChange",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          orderId: id,
          status: "Confirm",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getBooking();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const makeCancel = async (id) => {
    try {
      const config = {
        url: "/makeOderStatusChange",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          orderId: id,
          status: "Cancel",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getBooking();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const AddInvoiceGenration = async () => {
    try {
      const config = {
        url: "/addINVOICE",
        method: "post",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          date: moment().format("YYYY-MM-DD"),
          Name: bookingtaxi?.userName,
          PlaceOfSupply: bookingtaxi?.picUpPlace,
          email: bookingtaxi?.email,
          mobile: bookingtaxi?.number,
          taxiPerson: bookingtaxi?.packageName,
          taxiAmount: bookingtaxi?.basePrice,
          PickUpPoint: bookingtaxi?.picUpPlace,
          DropingPoint: bookingtaxi?.dropPlace,
          taxibookId: bookingtaxi?._id,
          TotalAmount: bookingtaxi?.totalAmount,
          PaymentStatus: bookingtaxi?.paymentMethod,
          InvoiceType: "Taxi",
        },
      };
      let res = await axios(config);
    } catch (error) {
      console.log(error);
    }
  };
  const makeComplete = async (id) => {
    try {
      const config = {
        url: "/makeOderStatusChange",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          orderId: id,
          status: "Complete",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        AddInvoiceGenration();
        getBooking();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  //getVehicleById
  const createPDF = async () => {
    const input = document.getElementById("pdf");
    const options = { scrollY: -window.scrollY };
    const canvas = await html2canvas(input, options);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    pdf.save("bookingDetails.pdf");
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(booktaxi.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // const [tableFilter, settableFilter] = useState([]);
  const handleFilter = (e) => {
    if (e.target.value != "") {
      const filterTable = tableFilter.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setbooktaxi([...filterTable]);
    } else {
      setbooktaxi([...tableFilter]);
    }
  };

  const [AllDriver, setAllDriver] = useState([]);
  const [nochangedata, setnochangedata] = useState("");

  const getAllDriverbuUser = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/driver/getAllDriverbuUser"
      );
      if (res.status == 200) {
        setAllDriver(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllDriverbuUser();
  }, []);
  const handleFilter2 = (e) => {
    if (e.target.value != "") {
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllDriver([...filterTable]);
    } else {
      setAllDriver([...nochangedata]);
    }
  };
  const [assignData, setassignData] = useState({});
  const [vehicleNumber, setvehicleNumber] = useState("");

  const makeAssnedDriverforTrip = async (
    driverId,
    driverName,
    driveMobile,
    driverStatus
  ) => {
    if (!vehicleNumber) return alert("Please enter vehicle number");
    try {
      const config = {
        url: "/assignedDriverTrip",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          id: assignData?._id,
          driverId: driverId,
          driverName: driverName,
          driveMobile: driveMobile,
          driverStatus: driverStatus,
          vehicleNumber: vehicleNumber,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(`Successfully ${res.data.success?.driverStatus}`);
        setassignData(res.data.success);
        getBooking();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [StMiter, setStMiter] = useState("");

  const startMiterTrip = async () => {
    if (!StMiter) return alert("Please enter start miter");
    try {
      const config = {
        url: "/startMiterTrip",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          id: assignData?._id,
          startMiter: StMiter,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully updated");
        handleClose5();
        getBooking();
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log("boooki",booktaxi);
  const [EndMiter, setEndMiter] = useState("");
  const [totatRide, settotalRide] = useState("");
  const [totalAm, settotalAm] = useState("");
  useEffect(() => {
    if (EndMiter) {
      if (assignData?.startMiter < EndMiter) {
        settotalRide(EndMiter - assignData?.startMiter);
      }
    }
  }, [EndMiter]);

  const miterUpdate = async () => {
    if (!EndMiter) return alert("Please enter end miter");
    if (assignData?.startMiter > EndMiter) {
      setEndMiter("");
      settotalRide("");
      return alert("End miter should be greater then start miter");
    }
    let tripData = assignData?.TripData;
    if (tripData) {
      tripData = JSON.parse(tripData);
    }
    let extrgst = assignData?.gst;
    let totalAmount = parseInt(assignData?.totalAmount);
    let FixedPrice = totalAmount;
    if (totatRide > assignData?.totalKm) {
      let ab = (totatRide - assignData?.totalKm) * tripData?.PerKmPrice;
      extraKmPrice =
        ab + ab * ((tripData?.CGST + tripData?.SGST + tripData?.IGST) / 100);
      totalAmount =
        totalAmount +
        (ab + ab * ((tripData?.CGST + tripData?.SGST + tripData?.IGST) / 100));
      extrgst =
        assignData?.gst +
        ab * ((tripData?.CGST + tripData?.SGST + tripData?.IGST) / 100);
      FixedPrice = totalAmount;
    }
    try {
      const config = {
        url: "/updateEndmiterTrip",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          id: assignData?._id,
          endMiter: EndMiter,
          totalKm: totatRide,
          dropDate: DropDate,
          dropTime: DropTime,
          extraKmPrice: extraKmPrice,
          gst: extrgst,
          totalAmount: totalAmount,
          FixedPrice: FixedPrice,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully updated");
        handleClose6();
        getBooking();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [paymethod, setpaymethod] = useState("");
  const [payD, setpayD] = useState(moment().format("YYYY-MM-DD"));
  const [PayAm, setPayAm] = useState("");
  const makePayment = async () => {
    if (!PayAm) return alert("Please enter pay amount");
    if (!paymethod) return alert("Please select payment method");
    let status = assignData?.payStatus;
    if (parseInt(assignData?.totalAmount) - assignData?.payAmount <= PayAm) {
      status = "Full Payment";
    }
    const config = {
      url: "/makePaymentofTrip",
      method: "put",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
      data: {
        id: assignData?._id,
        paymentMethod: paymethod,
        payAmount: PayAm,
        paymentDate: payD,
        payStatus: status,
      },
    };
    let res = await axios(config);
    if (res.status == 200) {
      alert("Successfully updated");
      handleClose7();
      getBooking();
    }
  };

  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
const searchDate=()=>{
  if(!fromd) return alert("Please select from date");
  if(!tod) return alert("Please select to date");

 let std=fromd?.split("-");
      let entd=tod?.split("-")
      let startD=parseInt(std?.join(""));
      let endD=parseInt(entd?.join(""))
      let abc=tableFilter?.filter((ele)=>{
        // console.log("amit check it",moment(ele?.createdAt).format("YYYY-MM-DD").split("-"))
        let abd=moment(ele?.createdAt).format("YYYY-MM-DD").split("-")
        let amd=parseInt(abd?.join(""));
        return startD<=amd && endD>=amd
      })
      setbooktaxi(abc);
      // setdata(abc)
      // setfileName(`AcountHistory_${moment(fromd).format("DD/MM/YYYY")} to ${moment(tod).format("DD/MM/YYYY")}`)
}
  return (
    <>
      <div className="ms-5">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h2 style={{ fontSize: "25px" }}>Taxi Booking Details</h2>
          </div>
        </div>

        <Modal show={show} onHide={handleClose} id="pdf">
          <Modal.Header
            closeButton
            style={{
              backgroundColor: "#2fdbeb",
              color: "white",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            Booking Details
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <form>
                <label className="pb-2 nd-2">BookId{" :"}</label>{" "}
                <span>{bookingtaxi?.bookId}</span>
                <br />
                <label className="pb-2 nd-2">Name {" :"}</label>{" "}
                <span>{bookingtaxi?.userName}</span> <br />
                <label className="pb-2 nd-2">Number{" :"}</label>{" "}
                <span> {bookingtaxi?.number}</span>
                <br />
                <label className="pb-2 nd-2">Email{" :"}</label>{" "}
                <span>{bookingtaxi?.email}</span>
                <br />
                <label className="pb-2 nd-2">From{" :"}</label>{" "}
                {bookingtaxi?.picUpPlace}
                <br />
                <label className="pb-2 nd-2">To{" :"}</label>{" "}
                {bookingtaxi?.dropPlace}
                <br />
                <TableContainer>
                  <Table variant="striped" colorScheme="teal">
                    <TableCaption></TableCaption>

                    <Tbody>
                      <Tr>
                        <Td>Trip Type</Td>
                        <Td>{bookingtaxi?.tripType}</Td>
                      </Tr>
                      <Tr>
                        <Td>Pickup Date/Time</Td>
                        <Td>
                          {bookingtaxi?.picUpDate}
                          {" - "}
                          {bookingtaxi?.picUpTime}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Vehicle</Td>
                        <Td>
                          {bookingtaxi?.TripData ? (
                            <>
                              {JSON?.parse(bookingtaxi?.TripData)?.category} (
                              {JSON?.parse(bookingtaxi?.TripData)?.VehicleType})
                            </>
                          ) : (
                            ""
                          )}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Vehicle Model</Td>
                        <Td>
                          {bookingtaxi?.TripData ? (
                            <>
                              {JSON?.parse(bookingtaxi?.TripData)?.VehicleModel}{" "}
                            </>
                          ) : (
                            ""
                          )}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Driver Name</Td>
                        <Td>{bookingtaxi?.driverName}</Td>
                      </Tr>
                      <Tr>
                        <Td>Driver Number</Td>
                        <Td>{bookingtaxi?.driveMobile}</Td>
                      </Tr>
                  
                      {bookingtaxi?.tripType == "Local Drop" ? (
                        <>
                          <Tr>
                            <Td>Package Type</Td>
                            <Td>{bookingtaxi?.packageName}</Td>
                          </Tr>
                          <Tr>
                            <Td>Package Price ₹</Td>
                            <Td>{bookingtaxi?.packagePrice?.toFixed(2)}</Td>
                          </Tr>
                        </>
                      ) : (
                        <></>
                      )}

                      <Tr>
                        <Td>Base Price ₹</Td>
                        <Td>{bookingtaxi?.basePrice?.toFixed(2)}</Td>
                      </Tr>
                      <Tr>
                        <Td>Driver Bata ₹</Td>
                        <Td>{bookingtaxi?.bata?.toFixed(2)}</Td>
                      </Tr>
                      <Tr>
                        <Td>GST ₹</Td>
                        <Td>{bookingtaxi?.gst?.toFixed(2)}</Td>
                      </Tr>
                      {bookingtaxi?.tripType !== "Local Drop" ? (
                        <></>
                      ) : (
                        <>
                          <Tr>
                            <Td>Extra Hours Price ₹</Td>
                            <Td>{bookingtaxi?.extraHrPrice?.toFixed(2)}</Td>
                          </Tr>

                          {bookingtaxi?.status === "Confirm" &&
                          bookingtaxi?.tripType === "Local Drop" ? (
                            <Tr>
                              <Td>Extra Hours</Td>
                              <Td>
                                {" "}
                                <input
                                  type="number"
                                  className="title-90"
                                  value={extraHour}
                                  placeholder="Ex:- 2"
                                  onChange={(e) => setextrahrs(e.target.value)}
                                />
                              </Td>
                            </Tr>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      {/*                       <Tr>
                        <Td>Extra Km Price ₹</Td>
                        <Td>{(bookingtaxi?.extraKmPrice)?.toFixed(2)}</Td>
                      </Tr>

                      {bookingtaxi?.status === "Confirm" ? (
                        <Tr>
                          <Td>Extra Km</Td>
                          <Td>
                            {" "}
                            <input
                              type="number"
                              className="title-90"
                              name="lodgename"
                              value={extraKm}
                              placeholder="Ex:- 3"
                              onChange={(e) => setExtraKm(e.target.value)}
                            />
                          </Td>
                        </Tr>
                      ) : (
                        <></>
                      )} */}

                      <Tr>
                        <Td>Extra Toll Price ₹</Td>
                        <Td>
                          {" "}
                          {bookingtaxi?.TripData ? (
                            <>
                              {JSON?.parse(bookingtaxi?.TripData)?.TollCharge
                                ? (
                                    JSON?.parse(bookingtaxi?.TripData)
                                      ?.TollCharge + bookingtaxi?.tollCharge
                                  )?.toFixed(2)
                                : bookingtaxi?.tollCharge?.toFixed(2)}
                            </>
                          ) : (
                            bookingtaxi?.tollCharge?.toFixed(2)
                          )}
                        </Td>
                      </Tr>
                      {bookingtaxi?.status === "Confirm" ? (
                        <Tr>
                          <Td>Total Toll Charge</Td>
                          <Td>
                            {" "}
                            <input
                              type="number"
                              className="title-90"
                              name="lodgename"
                              value={tollCharge}
                              placeholder="Ex:- 120.00"
                              onChange={(e) => settoll(e.target.value)}
                            />
                          </Td>
                        </Tr>
                      ) : (
                        <></>
                      )}
                      <Tr>
                        <Td>Permit Charge {bookingtaxi?.PermintN} ₹</Td>
                        <Td>{bookingtaxi?.PermintPrice?.toFixed(2)}</Td>
                      </Tr>
                      {bookingtaxi?.status === "Confirm" ? (
                        <>
                          <Tr>
                            <Td>Permit Details</Td>
                            <Td>
                              {" "}
                              <input
                                type="text"
                                className="title-90"
                                name="lodgename"
                                value={PermintN}
                                placeholder="Ex:- Permit short details"
                                onChange={(e) => setPermintN(e.target.value)}
                              />
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>Total Permit Charges</Td>
                            <Td>
                              {" "}
                              <input
                                type="number"
                                className="title-90"
                                name="lodgename"
                                min={0}
                                value={PermintPrice}
                                placeholder="Ex:- 120.00"
                                onChange={(e) =>
                                  setPermintPrice(e.target.value)
                                }
                              />
                            </Td>
                          </Tr>
                        </>
                      ) : (
                        <></>
                      )}

                      <Tr>
                        <Td>Parking Charges ₹</Td>
                        <Td>
                          {bookingtaxi?.TripData ? (
                            <>
                              {JSON?.parse(bookingtaxi?.TripData)?.ParkingCharge
                                ? (
                                    JSON?.parse(bookingtaxi?.TripData)
                                      ?.ParkingCharge +
                                    bookingtaxi?.ParkingCharge
                                  )?.toFixed(2)
                                : bookingtaxi?.ParkingCharge?.toFixed(2)}
                            </>
                          ) : (
                            bookingtaxi?.ParkingCharge?.toFixed(2)
                          )}
                        </Td>
                      </Tr>
                      {bookingtaxi?.status === "Confirm" ? (
                        <Tr>
                          <Td>Parking Charges</Td>
                          <Td>
                            {" "}
                            <input
                              type="number"
                              className="title-90"
                              name="lodgename"
                              value={ParkingCharge}
                              placeholder="Ex:- 120.00"
                              onChange={(e) => setParkingCharge(e.target.value)}
                            />
                          </Td>
                        </Tr>
                      ) : (
                        <></>
                      )}
                      <Tr>
                        <Td>Service Charges ₹</Td>
                        <Td>{bookingtaxi?.servicePrice?.toFixed(2)}</Td>
                      </Tr>
                      {bookingtaxi?.status === "Confirm" ? (
                        <Tr>
                          <Td>Service Charges</Td>
                          <Td>
                            {" "}
                            <input
                              type="number"
                              className="title-90"
                              name="lodgename"
                              value={servicePrice}
                              placeholder="Ex:- 120.00"
                              onChange={(e) => setservicePrice(e.target.value)}
                            />
                          </Td>
                        </Tr>
                      ) : (
                        <></>
                      )}
                      <Tr>
                        <Td>Total Price ₹</Td>
                        <Td>
                          {bookingtaxi?.totalAmount
                            ? parseInt(bookingtaxi?.totalAmount)?.toFixed(2)
                            : 0}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Status</Td>
                        <Td>{bookingtaxi?.status}</Td>
                      </Tr>
                      <Tr>
                        <Td>Payment</Td>
                        <Td>{bookingtaxi?.paymentMethod}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <AiOutlineDownload
              style={{
                color: "red",
                cursor: "pointer",
                fontSize: "36px",
                float: "right",
              }}
              onClick={() => createPDF()}
            />
            {bookingtaxi?.status === "Complete" ? (
              <></>
            ) : (
              <>
                {bookingtaxi?.status === "Confirm" ? (
                  <>
                    <Button
                      variant="danger"
                      onClick={() => {
                        makeCancel(bookingtaxi?._id);
                        handleClose();
                      }}
                    >
                      Cancel Trip
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => {
                        makeComplete(bookingtaxi?._id);
                        handleClose();
                      }}
                    >
                      Complete Trip
                    </Button>
                    <Button variant="primary" onClick={editBookingTaxi}>
                      Updated Trip
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="warning"
                    onClick={() => {
                      makeConfirm(bookingtaxi?._id);
                      handleClose();
                    }}
                  >
                    Confirm Trip
                  </Button>
                )}
              </>
            )}

            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete?</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <Button variant="primary" onClick={getDelete}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="taxi-table">
        <div className="row">
              <div className="col-md-4">
                <div className="searchHeadFlex">
              <div className="searchWrapper" style={{ display: "flex" }}>
                <input
                  type="text"
                  maxlength="230"
                  style={{ outlineWidth: "0px", padding: "4px" }}
                  className="textsearch"
                  placeholder="Search by ..."
                  onChange={handleFilter}
                />
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  id="BsSearch"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                    clip-rule="evenodd"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
           
              </div>
              <div className="col-md-2">
              <label htmlFor="">From</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => setfromd(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="">To</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => settod(e.target.value)}
              />
            </div>
            <div className="col-md-2">
         
              <button onClick={searchDate}
                style={{
                  borderRadius: "3px",
                  color: "white",
                  fontSize: "18px",
                  border: "1px solid",
                  backgroundColor: "#004aad",
                  padding: "5px",
                  marginTop: "26px",
                  borderRadius: "10px",
                  fontWeight: 500,
                }}
              >
                Search
              </button>
            </div>
            </div>

          <div className="scrolll_bar">
            <Table1 striped bordered hover>
              <thead>
                <tr>
                  <th rowSpan={2}>S.No</th>
                  <th rowSpan={2}>BookId</th>
                  <th rowSpan={2}>Trip Type</th>
                  <th rowSpan={2}>User Name</th>
                  <th rowSpan={2}>Number</th>
                  <th rowSpan={2}>Pickup Place</th>
                  <th rowSpan={2}>DropPlace</th>
                  <th rowSpan={2}>Pickup Date/Time</th>
                  <th rowSpan={2}>Package Name</th>
                  <th rowSpan={2}>Status</th>
                  <th rowSpan={2}>Driver Status</th>

                  <th colSpan={2}>Odometer Readings</th>
                  <th rowSpan={2}>Total Km</th>
                  <th rowSpan={2}>Total Price</th>
                  <th rowSpan={2}>Payment Date</th>
                  <th rowSpan={2}>Payment Status</th>

                  <th rowSpan={2}>Action</th>
                  <th rowSpan={2}>Asign Driver</th>
                  <th rowSpan={2}>Odometer</th>
                  <th rowSpan={2}>Invoice</th>
                </tr>
                <tr>
                  <th>Starting Reading</th>
                  <th>Ending Reading</th>
                </tr>
              </thead>
              <tbody>
                {booktaxi
                  ?.slice(pagesVisited, pagesVisited + usersPerPage)
                  ?.map((item, index) => {
                    return (
                      <tr>
                        <th>{index + 1}</th>
                        <th>{item?.bookId}</th>
                        <th>{item?.tripType}</th>
                        <td>{item?.userName}</td>
                        <td>{item?.number}</td>
                        <td>{item?.picUpPlace} </td>
                        <td>{item?.dropPlace}</td>
                        <td>
                          {item?.picUpDate} {item?.picUpTime}
                        </td>
                        <td>{item?.packageName}</td>
                        <td>{item?.status}</td>
                        <td>{item?.driverStatus}</td>

                        <td>{item?.startMiter}</td>
                        <td>{item?.endMiter}</td>
                        <td>{item?.totalKm} Km</td>
                        <td>{parseInt(item?.totalAmount)?.toFixed(2)}</td>
                        <td>{item?.paymentDate}</td>
                        <td>{item?.payStatus}</td>

                        {item?.status === "Pending" ? (
                          <td>
                            <div style={{ display: "flex", gap: "10px" }}>
                              <FaEye
                                style={{
                                  fontSize: "26px",
                                  color: " #5600ff",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleShow();

                                  setbookingtaxi(item);
                                }}
                              />
                              <button
                                type="button"
                                class="btn btn-success"
                                onClick={() => makeConfirm(item?._id)}
                              >
                                Confirm
                              </button>{" "}
                              <button
                                type="button"
                                class="btn btn-danger"
                                onClick={() => makeCancel(item?._id)}
                              >
                                Cancel
                              </button>
                            </div>
                          </td>
                        ) : (
                          <td>
                            <div style={{ display: "flex", gap: "10px" }}>
                              <span>
                                <FaEye
                                  style={{
                                    color: "#5600ff",
                                    cursor: "pointer",
                                    fontSize: "26px",
                                  }}
                                  onClick={() => {
                                    handleShow();
                                    setPermintN(item?.PermintN);
                                    setPermintPrice(item?.PermintPrice);
                                    setservicePrice(item?.servicePrice);
                                    setParkingCharge(item?.ParkingCharge);
                                    settoll(item?.tollCharge);
                                    setbookingtaxi(item);
                                  }}
                                />
                              </span>
                              <span>
                                <AiOutlineDelete
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                    fontSize: "26px",
                                  }}
                                  onClick={() => {
                                    setDeleteId(item?._id);
                                    handleShow1();
                                  }}
                                />
                              </span>
                            </div>
                          </td>
                        )}
                        <td>
                          {item?.status == "Complete" ||
                          item?.status == "Cancel" ? (
                            <></>
                          ) : (
                            <div
                              className="logde-op-00"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setassignData(item);
                                setvehicleNumber(item?.vehicleNumber);
                                handleShow8();
                              }}
                            >
                              Assign Driver
                            </div>
                          )}
                        </td>

                        <td>
                          {item.startMiter ? (
                            <>
                              {item?.endMiter ? (
                                <></>
                              ) : (
                                <div
                                  className="logde-op-00"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setassignData(item);
                                    handleShow6();
                                  }}
                                >
                                  Ending Reading
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {" "}
                              <div
                                className="logde-op-00"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setassignData(item);
                                  handleShow5();
                                }}
                              >
                                Starting Reading
                              </div>
                            </>
                          )}
                        </td>
                        <td>
                          {item?.status == "Complete" ? (
                            <Link to="/Admin_invoice">
                              <div
                                className="logde-op-00"
                                style={{ cursor: "pointer" }}
                              >
                                View
                              </div>
                            </Link>
                          ) : (
                            <></>
                          )}{" "}
                          <br />
                          {parseInt(item?.totalAmount) - item?.payAmount ? (
                            <div
                              className="logde-op-00"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setassignData(item);
                                setPayAm(
                                  parseInt(item?.totalAmount) - item?.payAmount
                                );
                                handleShow7();
                              }}
                            >
                              Add Payment
                            </div>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table1>
          </div>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </div>
      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#2fdbeb",
            color: "white",
            fontSize: "20px",
            fontWeight: "500",
          }}
        ></Modal.Header>
        <Modal.Body>
          <div className="">
            <form>
              <label className="pb-2 nd-2">Starting Reading</label>
              <br />
              <input
                type="number"
                name=""
                id=""
                value={StMiter}
                onChange={(e) => setStMiter(e.target.value)}
                className="title-90"
                placeholder="Enter Start Reading...."
              />
              <br />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose5}>
            Cancel
          </Button>
          <Button variant="primary" onClick={startMiterTrip}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Miter */}
      <Modal show={show6} onHide={handleClose6}>
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#2fdbeb",
            color: "white",
            fontSize: "20px",
            fontWeight: "500",
          }}
        ></Modal.Header>
        <Modal.Body>
          <div className="">
            <p>Start miter:{assignData?.startMiter}</p>
            <form>
              <label htmlFor="" className="pb-2 nd-2">
                Ending Reading
              </label>
              <br />
              <input
                type="number"
                name=""
                id=""
                value={EndMiter}
                onChange={(e) => setEndMiter(e.target.value)}
                placeholder="Enter Ending Reading..."
                className="title-90"
              />
              <br />
              <label htmlFor="" className="pb-2 nd-2">
                Total Ride(in Km)
              </label>
              <br />
              <input
                type="number"
                name=""
                id=""
                value={totatRide}
                onChange={(e) => settotalRide(e.target.value)}
                placeholder="Enter Total Km"
                className="title-90"
              />
              <br />
              <label htmlFor="" className="pb-2 nd-2">
                Drop Date
              </label>
              <br />
              <input
                type="date"
                name=""
                id=""
                value={DropDate}
                onChange={(e) => setDropDate(e.target.value)}
                placeholder="Enter Total Payment....."
                className="title-90"
              />
              <br />
              <label htmlFor="" className="pb-2 nd-2">
                Drop Time
              </label>
              <br />
              <input
                type="time"
                name=""
                id=""
                value={DropTime}
                onChange={(e) => setDropTime(e.target.value)}
                placeholder="Enter Total Payment....."
                className="title-90"
              />
              <br />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose6}>
            Cancel
          </Button>
          <Button variant="primary" onClick={miterUpdate}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Make Payment */}
      <Modal show={show7} onHide={handleClose7}>
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#2fdbeb",
            color: "white",
            fontSize: "20px",
            fontWeight: "500",
          }}
        ></Modal.Header>
        <Modal.Body>
          <div className="">
            <form>
              <label htmlFor="" className="pb-2 nd-2">
                Payment Date
              </label>
              <br />
              <input
                type="date"
                name=""
                id=""
                value={payD}
                onChange={(e) => setpayD(e.target.value)}
                placeholder="Enter Ending Reading..."
                className="title-90"
              />
              <br />
              <label htmlFor="" className="pb-2 nd-2">
                Paid Amount
              </label>
              <br />
              <input
                type="number"
                name=""
                id=""
                value={PayAm}
                onChange={(e) => setPayAm(e.target.value)}
                placeholder="Enter Amount"
                className="title-90"
              />
              <br />
              <label htmlFor="" className="pb-2 nd-2">
                Payment Status
              </label>
              <br />
              <select
                className="title-90"
                onChange={(e) => setpaymethod(e.target.value)}
              >
                <option>Select</option>
                <option value="Cash">Cash</option>
                <option value="Online">Online</option>
              </select>
              <br />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose7}>
            Cancel
          </Button>
          <Button variant="primary" onClick={makePayment}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Assigned Driver */}
      <Modal show={show8} onHide={handleClose8}>
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#2fdbeb",
            color: "white",
            fontSize: "20px",
            fontWeight: "500",
          }}
        >
          Assign Driver
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="searchHeadFlex">
                <div
                  className="searchWrapper"
                  style={{ display: "flex", width: "230px" }}
                >
                  <input
                    type="text"
                    maxlength="230"
                    style={{ outlineWidth: "0px", padding: "4px" }}
                    className="textsearch"
                    placeholder="Assign Driver"
                    onChange={handleFilter2}
                  />
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 16 16"
                    id="BsSearch"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                      clip-rule="evenodd"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className="title-90"
                placeholder="Enter vehicle number..."
                value={vehicleNumber}
                onChange={(e) => setvehicleNumber(e.target.value)}
                style={{
                  width: "230px",
                  boxShadow:
                    "0 1px 1px rgba(0, 0, 0, 0.12) 0 2px 2px rgba(0, 0, 0, 0.12)",
                  border: "2px solid #00000047",
                  borderRadius: "50px",
                  height: " 51px",
                }}
              />
            </div>
          </div>

          {AllDriver?.map((item) => {
            return (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div>
                  <img
                    src={"https://dhanyatours.me/transport/" + item?.profile}
                    width={40}
                    height={40}
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div>
                  {" "}
                  <span>{item?.name}</span>
                </div>
                <div>
                  {" "}
                  <span>{item?.mobile}</span>
                </div>
                {assignData?.driverId == item?._id ? (
                  <div>
                    <button
                      type="button"
                      class="btn btn-danger"
                      onClick={() =>
                        makeAssnedDriverforTrip("", "", "", "Cancel")
                      }
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      type="button"
                      class="btn btn-success"
                      onClick={() =>
                        makeAssnedDriverforTrip(
                          item?._id,
                          item?.name,
                          item?.mobile,
                          "Assigned"
                        )
                      }
                    >
                      Assign
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose8}>
            Cancel
          </Button>
          {/* <Button variant="primary">Ok</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddBookTaxi;
