import { Filter } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";

function AddLodgeGraph() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [lodgeimage, setloadgeimages] = useState([]);
  const [image1, setImages1] = useState();
  const [imname, setimname] = useState();
  console.log(lodgeimage, "success");

  // Get
  const lodImage = async () => {
    const config = {
      url: "/getAllImage",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setloadgeimages(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  useEffect(() => {
    lodImage();
  }, []);

  // Post
  const lodgeImages = async () => {
    // e.preventDefault();
    try {
      const config = {
        url: "/addImage",
        method: "post",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          image: image1,
          name: "Lodge",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        // sessionStorage.setItem("user", JSON.stringify(res.data.success));
        // history("/");
        alert("Successfully Added");
        // lodges();
        window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  // Delete
  const [deleteId, setDeleteId] = useState("");
  const [showDelete, setshowDelete] = useState(false);
  const handleDelete = () => setshowDelete(true);
  const closeDelete = () => setshowDelete(false);
  const lodgeImagesDelete = async () => {
    // e.preventDefault();
    try {
      const config = {
        url: "/deleteImage/" + deleteId,
        method: "delete",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        // sessionStorage.setItem("user", JSON.stringify(res.data.success));
        // history("/");
        alert("Successfully Deleted");
        // lodges();
        window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  return (
    <>
      <div className="ms-5">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h3> Jungle Lodges Graphics</h3>
          </div>

          <div
            className="logde-op-00"
            style={{ cursor: "pointer" }}
            onClick={handleShow}
          >
            + Jungle Lodges Graphics
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Modal heading</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form>
                  <label className="pb-2 nd-2" htmlFor="upload1">
                    Image
                  </label>
                  <br />
                  <input
                    type="file"
                    className="title-90"
                    name="lodgename"
                    id="upload1"
                    accept="image/*"
                    required=""
                    onChange={(e) => setImages1(e.target.files[0])}
                  />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Back
              </Button>
              <Button variant="primary" onClick={() => lodgeImages()}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="scrolll_bar">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No.</th>
                {/* <th>Name</th> */}
                <th>Image</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {lodgeimage
                ?.filter((item) => item?.name === "Lodge")
                ?.map((ele, index) => {
                  return (
                    <tr>
                      {/* <th>{ele.name}</th> */}
                      <th>{index + 1}</th>
                      <th>
                        <img
                          src={`https://dhanyatours.me/image/${ele?.image}`}
                          alt="loadge-image"
                          style={{ width: "250px", height: "150px" }}
                        />
                      </th>
                      <th>
                        <div
                          className=""
                          onClick={() => {
                            setDeleteId(ele._id);
                            handleDelete();
                          }}
                        >
                          <AiFillDelete
                            style={{
                              color: "red",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </th>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={showDelete} onHide={closeDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete ??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <div style={{ float: "right" }}>
            <Button variant="danger" onClick={closeDelete}>
              Cancel
            </Button>{" "}
            <Button variant="success" onClick={lodgeImagesDelete}>
              Ok
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddLodgeGraph;
