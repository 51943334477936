import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Form } from "react-bootstrap";
import { Carousel } from "react-carousel-minimal";
import { useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import parse from "html-react-parser";
import moment from "moment";

function WildlifeSingal() {
  const [selectedValue, setSelectedValue] = useState(1);
  const data = [];
  const [data1, setdata1] = useState([]);
  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };
  const [previewImg, setPreviewImg] = useState("../jungal/bheemeshwari1.jpg");

  const [loadge, setLoadeges] = useState({});
  const [priceing, setpriceing] = useState([]);
  const [includes, setincludes] = useState([]);
  const [excludes, setexcludes] = useState([]);
  const [ITINERARY, setITINERARY] = useState([]);
  const [package1, setpackage] = useState({});

  const { id } = useParams();

  const getlodges = async () => {
    const config = {
      url: "/getbyLodgeId/" + id,
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setLoadeges(result.data.success);
        setdata1(result.data.success?.lodgeImage);

        // setPreviewImg(result.data.success?.lodgeImage[0]?.image);
        setpriceing(result.data.success?.PRICING);
        setincludes(result.data.success?.Includes);
        setexcludes(result.data.success?.Excludes);
        setITINERARY(result.data.success?.ITINERARY);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllPackage, setAllPackage] = useState([]);
  const getLoadgePackageById = async (id) => {
    try {
      let res = await axios(
        "https://dhanyatours.me/api/user/getAllPackageLodge/" + id
      );
      if (res.status == 200) {
        setAllPackage(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [ChildData, setChildData] = useState([]);
  const getChildData = async () => {
    const config = {
      url: "/getChildSdata",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setChildData(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (id) {
      getlodges();
      getLoadgePackageById(id);
      getChildData();
    }
  }, []);
  const [count, setcount] = useState(0);
  if (data1.length !== 0) {
    data.push({
      image: `https://dhanyatours.me/lodge/${loadge?.lodgeProfile}`,
      caption: loadge?.lodgeName,
    });
    for (let i = 0; i < data1.length; i++) {
      data.push({
        image: `https://dhanyatours.me/lodge/${data1[i]?.image}`,
        caption: "",
      });
    }
  }
  useEffect(() => {
    if (Object.keys(loadge).length !== 0) {
    }
  }, []);

  const [itemObj, setItemObj] = useState({});
  const handleUpdate = (id, index) => {
    setItemObj({ id: id, index: index });
  };
  useEffect(() => {
    const currentDate = new Date(Date.now());
    currentDate.setDate(currentDate.getDate() + 1);
    const formattedNewDate = currentDate.toISOString().split("T")[0];
    setcheckinDate(formattedNewDate);

    const currentDate1 = new Date(Date.now());
    currentDate1.setDate(currentDate1.getDate() + 2);
    const formattedNewDate1 = currentDate1.toISOString().split("T")[0];
    setcheckOutDate(formattedNewDate1);
  }, []);
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [number, setnumber] = useState("");
  const [packageName, setpackageName] = useState("");
  const [Occupancy, setOccupancy] = useState("");
  const [checkinDate, setcheckinDate] = useState("");
  const [checkinTime, setcheckinTime] = useState("");
  const [checkOutDate, setcheckOutDate] = useState("");
  const [checkOutTime, setcheckOutTime] = useState("");
  const [message, setmessage] = useState("");
  const [numberOfguest, setnumberOfguest] = useState(1);
  // let [packagePrice, setpackagePrice] = useState(0);
  const [childAges, setChildAges] = useState([]);

  var [packagePrice, setpackagePrice] = useState(0);
  var [actualPrice, setactualPrice] = useState(0);
  const [gst, setgst] = useState(0);
  const [discount, setdiscount] = useState(0);
  let [otherPrice, setotherPric] = useState(0);
  const [data2, setdata2] = useState(0);
  const [NumberOfChild, setNumberOfchild] = useState([]);
  const handleChange = (e) => {
    if (e.target.value !== "") {
      const value = parseInt(e.target.value);
      setSelectedValue(value);
      setChildAges(Array(value).fill(1));
      if (data2 > parseInt(e.target.value)) {
        setdata2(data2 - 1);
        const newTaskList = [...NumberOfChild];
        newTaskList.splice(e.target.value, 1);
        setNumberOfchild(newTaskList);
      } else {
        setdata2(data2 + value);
      }
    } else {
      setChildAges([]);
      setNumberOfchild([]);
      setotherPric(0);
    }
  };

  useEffect(() => {
    if (loadge?.StartingPrice) {
      setpackagePrice(Number(loadge?.StartingPrice));
      setactualPrice(Number(loadge?.StartingPrice));
    }
  }, [loadge?.StartingPrice]);

  const handlePack = (ab, count) => {
    if (ab == "") {
      setpackagePrice(Number(loadge?.StartingPrice) * count);
      setactualPrice(Number(loadge?.StartingPrice) * count);
    } else {
      let item = JSON.parse(ab);
      let am =
        item?.packegePrice * count +
        item?.packegePrice * count * ((item?.gst + item?.igst) / 100) -
        item?.discount * count;
      setdiscount(item?.discount * count);
      setgst(item?.packegePrice * count * ((item?.gst + item?.igst) / 100));
      setpackagePrice(am);
      setactualPrice(item?.am * count);
    }
  };

  console.log("NumberOfChild", NumberOfChild);
  console.log("packegePrice", packagePrice);
  console.log("actualPrice", actualPrice);
  console.log("otherPrice", otherPrice);
  console.log("gst", gst);

  const handleAgeChange = (index, e) => {
    let obj = {};
    if (e.target.value !== "") {
      obj = JSON.parse(e.target.value);
      let am = NumberOfChild.filter((ele) => ele.no == index);
      if (am.length == 0) {
        NumberOfChild.push({
          no: index,
          age: obj?.ageType,
          discount: obj?.discount,
        });
        let ab = [...NumberOfChild];

        let childPrice = 0;
        for (let i = 0; i < ab.length; i++) {
          childPrice =
            childPrice +
            Number(packagePrice - (packagePrice * ab[i]?.discount) / 100);
        }
        setotherPric(Number(childPrice));
      } else {
        let an = NumberOfChild.map((ele) => {
          if (ele.no == index) {
            let ob = {
              ...ele,
              age: obj?.ageType,
              discount: obj?.discount,
            };
            return ob;
          }
          return ele;
        });
        setNumberOfchild(an);
        let childPrice = 0;
        for (let i = 0; i < an.length; i++) {
          childPrice =
            childPrice +
            Number(packagePrice - (packagePrice * an[i]?.discount) / 100);
        }
        setotherPric(Number(childPrice));
      }
    }
  };

  useEffect(() => {
    if (NumberOfChild.length !== 0) {
      let childPrice = 0;
      for (let i = 0; i < NumberOfChild.length; i++) {
        childPrice =
          childPrice +
          Number(
            packagePrice - (packagePrice * NumberOfChild[i]?.discount) / 100
          );
      }
      setotherPric(Number(childPrice));
    }
  }, [NumberOfChild]);

  // const onCreate = async () => {
  //   try {
  //     if (!user) {
  //       return alert("Please enter full name!");
  //     } else if (!email) {
  //       return alert("Please enter email!");
  //     } else if (!number) {
  //       return alert("Please enter mobile number!");
  //     }

  //     if (!checkinDate) {
  //       return alert("Please select check in date!");
  //     }

  //     if (!checkOutDate) {
  //       return alert("Please select check out date!");
  //     }

  //     if (!numberOfguest) {
  //       return alert("Please enter  number of guest!");
  //     }

  //     const config = {
  //       url: "/createLodgeBook",
  //       method: "post",
  //       baseURL: "https://dhanyatours.me/api/user",
  //       headers: { "conttent-type": "application/json" },
  //       data: {
  //         lodgeId: loadge?._id,
  //         userName: user,
  //         email: email,
  //         number: number,
  //         Location: loadge?.location,
  //         checkInDate: checkinDate,
  //         checkInTime: checkinTime,
  //         checkOutDate: checkOutDate,
  //         checkOutTime: checkOutTime,
  //         NumberOfGuest: numberOfguest,
  //         message: message,
  //         packageName: packageName ? JSON.parse(packageName)?.packageName : "",
  //         Occupancy: Occupancy,
  //         packagePrice: packageName ? actualPrice : 0,
  //         gst: gst,
  //         totalAmount: Number(packagePrice + otherPrice),
  //         NumberOfChild: NumberOfChild,
  //         discount: discount,
  //       },
  //     };
  //     const result = await axios(config);
  //     if (result.status === 200) {
  //       alert("Successfully Booked Configration will be send on your email");
  //       // window.location.assign("/");
  //       posttransaction();
  //     } else {
  //       alert("Something went wrong");
  //     }
  //   } catch (error) {
  //     console.log("erore", error);
  //     alert(error.response.data.error);
  //   }
  // };
  const onCreate = async () => {
    try {
      if (!user) {
        return alert("Please enter full name!");
      } else if (!email) {
        return alert("Please enter email!");
      } else if (!number) {
        return alert("Please enter mobile number!");
      }

      if (!checkinDate) {
        return alert("Please select check-in date!");
      }

      if (!checkOutDate) {
        return alert("Please select check-out date!");
      }

      if (!numberOfguest) {
        return alert("Please enter number of guests!");
      }

      // Initiate the payment process
      posttransaction();
    } catch (error) {
      console.log("Error:", error);
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  // Transaction++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ phonepay
  const generateUniqueTransactionId = () => {
    const timestamp = Date.now().toString();
    const randomComponent = Math.floor(Math.random() * 1000000).toString();
    // console.log('timestamp', timestamp - randomComponent);
    return `${timestamp}-${randomComponent}`;
  };

  const [paymentUrl, setPaymentUrl] = useState();
  const posttransaction = async () => {
    // if (!fullName || !phno || !email || !donatetype || !amt) {
    //     return alert("Please fill all the necessary fields");
    // }
    try {
      const newMerchantTransactionId = generateUniqueTransactionId();
      const config = {
        url: "/makepayment",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-Type": "application/json" },
        data: {
          merchantTransactionId: newMerchantTransactionId,
          merchantUserId: "2342343",
          amount: Number(packagePrice + otherPrice) * 100,
          redirectUrl: "https://dhanyatoursandtravels.com/home",
          callbackUrl: "https://dhanyatoursandtravels.com/home",
          mobileNumber: number,
        },
      };
      console.log("Request Config:", config);
      const res = await axios(config);
      if (res.status === 200) {
        window.location.assign(res.data?.url?.url);
      }
    } catch (error) {
      console.log("Error while fetching payment URL:", error);
      if (error.response) {
        console.log("Response data:", error.response.data);
        console.log("Response status:", error.response.status);
        console.log("Response headers:", error.response.headers);
      } else if (error.request) {
        console.log("Request made but no response received:", error.request);
      } else {
        console.log("Error message:", error.message);
      }
      alert("An error occurred while fetching the payment URL");
    }
  };

  // Call this function after payment is successful
  const storeBookingData = async () => {
    try {
      const config = {
        url: "/createLodgeBook",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          lodgeId: loadge?._id,
          userName: user,
          email: email,
          number: number,
          Location: loadge?.location,
          checkInDate: checkinDate,
          checkInTime: checkinTime,
          checkOutDate: checkOutDate,
          checkOutTime: checkOutTime,
          NumberOfGuest: numberOfguest,
          message: message,
          packageName: packageName ? JSON.parse(packageName)?.packageName : "",
          Occupancy: Occupancy,
          packagePrice: packageName ? actualPrice : 0,
          gst: gst,
          totalAmount: Number(packagePrice + otherPrice),
          NumberOfChild: NumberOfChild,
          discount: discount,
        },
      };
      const result = await axios(config);
      if (result.status === 200) {
        alert("Successfully Booked. Configuration will be sent to your email.");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log("Error:", error);
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  // Simulate the callback URL being hit after a successful payment
  // In practice, you would configure this on your server and the server would call `storeBookingData`
  window.onload = function () {
    const urlParams = new URLSearchParams(window.location.search);
    const paymentStatus = urlParams.get("paymentStatus");

    if (paymentStatus === "success") {
      storeBookingData();
    }
  };

  return (
    <>
      <div className="beach">
        <div className="container">
          <div className="wild-b">
            <div className="row">
              <div className="col-md-8">
                {" "}
                <div className="kabini-wrap">
                  <p> {loadge?.lodgeName}</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="price-wild text-end">
                  <p>Price Starts from</p>
                  <div className="price-0">
                    ₹ {loadge?.StartingPrice?.toFixed(2)}({loadge?.Includes}).
                  </div>
                  <small>Tariff is per person on twin sharing basis.</small>
                </div>
              </div>
            </div>
            <div className="lodge-wrapper"></div>
            <div className="zip-o">
              <div className="row">
                <div className="col-md-8">
                  <div className="product-o">
                    <div style={{ textAlign: "center" }}>
                      {data.length != 0 ? (
                        <div>
                          <Carousel
                            data={data}
                            time={2000}
                            width="850px"
                            height="500px"
                            captionStyle={captionStyle}
                            radius="10px"
                            slideNumber={true}
                            slideNumberStyle={slideNumberStyle}
                            captionPosition="bottom"
                            automatic={true}
                            dots={false}
                            pauseIconColor="white"
                            pauseIconSize="40px"
                            //   slideBackgroundColor="darkgrey"
                            slideImageFit="cover"
                            thumbnails={true}
                            thumbnailWidth="100px"
                            style={{
                              textAlign: "center",
                              maxWidth: "850px",
                              maxHeight: "500px",
                              margin: "40px auto",
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="over-view">
                      <div className="river">
                        <p className="cautious">Overview</p>
                      </div>
                      {loadge?.OVERVIEW ? parse(loadge?.OVERVIEW) : ""}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="contact-wrap mb-2" id="enquiry">
                    <h2>Enquiry Form</h2>
                    <div className="rspqc">
                      <div className="wpcf7 js">
                        <input
                          type="text"
                          className="wpcf7-text "
                          placeholder="Name"
                          value={user}
                          onChange={(e) => setUser(e.target.value)}
                        />
                      </div>
                      <div className="wpcf7 js">
                        <input
                          type="text"
                          className="wpcf7-text "
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="wpcf7 js">
                        <input
                          type="text"
                          className="wpcf7-text "
                          placeholder="Phone"
                          value={number}
                          onChange={(e) => setnumber(e.target.value)}
                        />
                      </div>
                      <div className="wpcf7 js">
                        <Form.Select
                          className="wpcf7-text "
                          style={{
                            height: "50px",
                            width: "100%",

                            float: "left",
                            marginBottom: "20px",
                            padding: "0",
                            border: "solid #ccc",
                            borderWidth: "0 0 1px 0",
                            fontFamily: "Lato,sans-serif",
                            fontSize: "15px",
                            fontWeight: "400",
                            color: "#333",
                            outlineWidth: "0px",
                          }}
                          onChange={(e) => {
                            setpackageName(e.target.value);
                            handlePack(e.target.value, numberOfguest);
                          }}
                        >
                          <option value="">Select package</option>
                          {AllPackage?.map((item) => {
                            return (
                              <option value={JSON.stringify(item)}>
                                {item?.packageName}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>
                      <div className="row">
                        <label
                          style={{
                            fontWeight: "600",
                            color: "white",
                            fontSize: "14px",
                          }}
                        >
                          Check In Date/Time
                        </label>
                        <div className="col-md-12">
                          <div className="wpcf7 js">
                            <input
                              type="date"
                              className="wpcf7-text "
                              name="Description"
                              value={checkinDate}
                              onChange={(e) => setcheckinDate(e.target.value)}
                              min={moment().format("YYYY-MM-DD")}
                            />
                          </div>
                        </div>

                        {/* <div className="col-md-6">
                        <div  className="wpcf7 js">
                          <input
                            type="time"
                            className="wpcf7-text "
                            value={checkinTime}
                            onChange={(e) => setcheckinTime(e.target.value)}
                          />
                        </div>
                      </div> */}
                      </div>
                      <div className="row">
                        <label
                          style={{
                            fontWeight: "600",
                            color: "white",
                            fontSize: "14px",
                          }}
                        >
                          Check Out Date/Time
                        </label>
                        <div className="col-md-12">
                          <div className="wpcf7 js">
                            <input
                              type="date"
                              className="wpcf7-text "
                              value={checkOutDate}
                              onChange={(e) => setcheckOutDate(e.target.value)}
                              min={checkinDate}
                            />
                          </div>
                        </div>
                        {/* 
                      <div className="col-md-6">
                        <div className="wpcf7 js">
                          <input
                            type="time"
                            className="wpcf7-text "
                            value={checkOutTime}
                            onChange={(e) => setcheckOutTime(e.target.value)}
                          />
                        </div>
                      </div> */}
                      </div>
                      <div className="row">
                        <label
                          style={{
                            fontWeight: "600",
                            color: "white",
                            fontSize: "14px",
                          }}
                        >
                          Number Of Guest
                        </label>

                        <div className="col-md-12">
                          <div className="wpcf7 js">
                            <input
                              type="number"
                              className="wpcf7-text "
                              value={numberOfguest}
                              onChange={(e) => {
                                setnumberOfguest(e.target.value);
                                handlePack(packageName, e.target.value);
                              }}
                              placeholder="Adults"
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="wpcf7 js">
                            <input
                              type="number"
                              className="wpcf7-text "
                              min="0"
                              // value={NumberOfChild}
                              onChange={handleChange}
                              placeholder="Childs"
                            />
                          </div>
                        </div>
                        {childAges.map((age, index) => {
                          return (
                            <div>
                              <div class="form-group">
                                <Form.Select
                                  aria-label="Default select example"
                                  onChange={(e) =>
                                    handleAgeChange(index + 1, e)
                                  }
                                >
                                  <option>Age needed</option>
                                  {ChildData?.map((ele) => {
                                    return (
                                      <option value={JSON.stringify(ele)}>
                                        {ele?.ageType} old
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="wpcf7 js">
                        <textarea
                          type="text"
                          className="wpcf7-text "
                          placeholder="Message"
                          value={message}
                          onChange={(e) => setmessage(e.target.value)}
                          style={{ height: "70px" }}
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="wpcf7 js">
                            <Button onClick={onCreate}> Send Enquiry</Button>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="wpcf7 js">
                            <Button>
                              {" "}
                              Total:-{" "}
                              {Number(packagePrice + otherPrice)?.toFixed(2)}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="contact-wrap mb-2">
                    <h5>Resort Contact Info</h5>
                    <div className="rspqc">
                      <div>
                        Kabini River Lodge, Nissana Beltur Post, HD Kote Taluk,
                        Karapura, Around Mysore - 571 114 Karnataka, India
                      </div>
                      <div>Manager:Arun Baghat</div>
                      <div>For Booking:9858674858</div>
                      <div>Contact:9858674858</div>
                      <div>Email Id: arun@gmail.com</div>
                    </div>
                  </div>
                  <div
                    className="contact-wrap mb-2"
                    style={{
                      border: "1px solid red",
                      padding: "10px",
                    }}
                  >
                    <h5>Other Resorts</h5>
                    <div className="rspqc">
                      <div className="other-resort">
                        <img
                          src="../jungal/bheemeshwari1.jpg"
                          alt="other-resorts"
                          style={{
                            width: "100%",
                            height: "130px",
                          }}
                        />
                        <div className="Lodge-wraper">
                          <a href="#"> Bandipur Safari Lodge</a>
                        </div>
                      </div>
                      <div className="other-resort">
                        <img
                          src="../jungal/bheemeshwari1.jpg"
                          alt="other-resorts"
                          style={{
                            width: "100%",
                            height: "130px",
                          }}
                        />
                        <div className="Lodge-wraper">
                          <a href="#"> K Gudi Wilderness Camp</a>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div
                    className="contact-wrap mb-2"
                    style={{
                      border: "1px solid red",
                      padding: "10px",
                    }}
                  >
                    <h5>Useful Links</h5>
                    <div className="rspqc">
                      <div className="other-resort">
                        <div className="Lodge-wraper">
                          <a href="#"> JLR Walk through (Video)</a>
                        </div>
                        <div className="Lodge-wraper">
                          <a href="#" target="_blank">
                            {" "}
                            JLR Safari Experience (VR Video)
                          </a>
                        </div>
                        <div className="Lodge-wraper">
                          <a href="#" target="_blank">
                            {" "}
                            A RIOT OF COLOURS AT KABINI
                          </a>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* Packages */}
          <div className="packages-wrap">
            <div className="lion" id="Packages">
              <p className="cautious" style={{ color: "red" }}>
                Packages
              </p>
              <div
                style={{
                  float: "right",
                  width: "100%",
                }}
              >
                <hr
                  style={{
                    color: "red",
                    opacity: "3",
                    margin: "6px 0",
                  }}
                />
              </div>
            </div>
            {AllPackage?.map((item) => {
              var changeImge = 0;

              return (
                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="">
                      <div className="product-o">
                        <div style={{ textAlign: "center" }}>
                          <div className="product__main-img">
                            {item?.packageImages?.length != 0 ? (
                              <img
                                src={`https://dhanyatours.me/lodge/${
                                  item?.packageImages[
                                    itemObj.id == item?._id ? itemObj.index : 0
                                  ]?.image
                                }`}
                                alt=""
                                className="w-100"
                                style={{ height: "330px" }}
                              />
                            ) : (
                              <img
                                src={previewImg}
                                alt=""
                                className="w-100"
                                style={{ height: "330px" }}
                              />
                            )}
                          </div>
                          <div className="product__images ">
                            {item?.packageImages?.map((ele, i) => {
                              return (
                                <div
                                  className="img__item mb-3"
                                  onClick={() => handleUpdate(item?._id, i)}
                                >
                                  <img
                                    src={`https://dhanyatours.me/lodge/${ele?.image}`}
                                    alt=""
                                    style={{
                                      width: "100%",
                                      height: "70px",
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="packagess-maha">
                      <div className="items-pro">
                        <div className="items-pro-wrap">
                          {" "}
                          <p className="kjhg">{item?.packageName}</p>
                        </div>
                        <div className="money-lodge">
                          <p>Price Starts from</p>
                          <div className="price-0">
                            <strong>
                              ₹
                              {(
                                item?.packegePrice +
                                item?.packegePrice *
                                  ((item?.gst + item?.igst) / 100)
                              )?.toFixed(2)}
                            </strong>
                            ₹{" "}
                            {(
                              item?.packegePrice +
                              item?.packegePrice *
                                ((item?.gst + item?.igst) / 100) -
                              item?.discount
                            )?.toFixed(2)}
                          </div>
                        </div>
                      </div>

                      {item?.description ? parse(item?.description) : ""}
                      {item?.roomType ? (
                        <div>
                          <strong
                            style={{
                              color: "red",
                              fontWeight: "700",
                              fontSize: "16px",
                            }}
                          >
                            Room Type
                          </strong>
                          : {item?.roomType} ({item?.totalRoom})
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="wpcf7 js" style={{ float: "right" }}>
                        <Button>
                          {" "}
                          <a href="#enquiry" style={{ color: "white" }}>
                            Enquiry Now
                          </a>{" "}
                        </Button>
                      </div>

                      {/* <div>
                          <strong
                            style={{
                              color: "red",
                              fontWeight: "700",
                              fontSize: "16px",
                            }}
                          >
                            Amenities:
                          </strong>
                        </div> */}
                      {/* <div className="amee">
                          <ul>
                            <li className="inac">
                              <Tooltip id="my-tooltip" />
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Air Condition"
                                data-tooltip-place="top"
                              >
                                <img
                                  src="../Ame/inac.png"
                                  alt="inac"
                                  className="tooltip-img"
                                />
                              </a>
                            </li>
                            <li className="inac">
                              <Tooltip id="my-tooltip" />
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Coffee-maker"
                                data-tooltip-place="top"
                              >
                                <img
                                  src="../Ame/coffee-maker.png"
                                  alt="inac"
                                  className="tooltip-img"
                                />
                              </a>
                            </li>
      
                            <li className="inac">
                              <Tooltip id="my-tooltip" />
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="BathRoom"
                                data-tooltip-place="top"
                              >
                                <img
                                  src="../Ame/bathroom.png"
                                  alt="inac"
                                  className="tooltip-img"
                                />
                              </a>
                            </li>
                            <li className="inac">
                              <Tooltip id="my-tooltip" />
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="House-keeping"
                                data-tooltip-place="top"
                              >
                                <img
                                  src="../Ame/house-keeping.png"
                                  alt="inac"
                                  className="tooltip-img"
                                />
                              </a>
                            </li>
                            <li className="inac">
                              <Tooltip id="my-tooltip" />
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Luggag"
                                data-tooltip-place="top"
                              >
                                <img
                                  src="../Ame/luggage-assistance.png"
                                  alt="inac"
                                  className="tooltip-img"
                                />
                              </a>
                            </li>
                            <li className="inac">
                              <Tooltip id="my-tooltip" />
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Parking"
                                data-tooltip-place="top"
                              >
                                <img
                                  src="../Ame/parking.png"
                                  alt="inac"
                                  className="tooltip-img"
                                />
                              </a>
                            </li>
                            <li className="inac">
                              <Tooltip id="my-tooltip" />
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Seating"
                                data-tooltip-place="top"
                              >
                                <img
                                  src="../Ame/seating-area.png"
                                  alt="inac"
                                  className="tooltip-img"
                                />
                              </a>
                            </li>
                            <li className="inac">
                              <Tooltip id="my-tooltip" />
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Umbrellas"
                                data-tooltip-place="top"
                              >
                                <img src="../Ame/umbrellas.png" alt="inac" />
                              </a>
                            </li>
                            <li className="inac">
                              <Tooltip id="my-tooltip" />
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="wake-up-call"
                                data-tooltip-place="top"
                              >
                                <img
                                  src="../Ame/wake-up-call.png"
                                  alt="inac"
                                  className="tooltip-img"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>  */}
                      {/* <div className="text-end">
                          <Button>Book Now</Button>
                        </div> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Packages */}
          <div className="packages-wrap">
            <div className="lion" id="Packages">
              <p className="cautious" style={{ color: "red" }}>
                Itinerary
              </p>
              <div
                style={{
                  float: "right",
                  width: "100%",
                }}
              >
                <hr
                  style={{
                    color: "red",
                    opacity: "3",
                    margin: "6px 0",
                  }}
                />
              </div>
            </div>
            <div className="according">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {" "}
                    <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                      Day 1
                    </h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    {loadge?.ITINERARY ? parse(loadge?.ITINERARY) : ""}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    {" "}
                    <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                      TERMS &amp; CONDITIONS
                    </h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    {loadge?.termCondition ? parse(loadge?.termCondition) : ""}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WildlifeSingal;
