import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

export const HotelInvoice = () => {
  const { id } = useParams();
  const [bookData, setBookData] = useState({});
  const getInvoiceById = async () => {
    try {
      let res = await axios(
        "https://dhanyatours.me/api/admin/getInvoiceByHotelBookId/" + id
      );
      if (res.status == 200) {
        setBookData(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getInvoiceById();
    }
  }, []);
  const generatePDF = async () => {
    const input = document.getElementById("pdf-content");
    const options = { scrollY: -window.scrollY };
    const canvas = await html2canvas(input, options);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", [1000, canvas.height]);
    pdf.addImage(imgData, "PNG", 0, 0, 1000, canvas.height);
    pdf.save("Invoice.pdf");
  };
  const History=useNavigate()
  return (
    <div>
      <div
        className="container py-5"
        style={{ display: " flex", justifyContent: "center" }}
        id="pdf-content"
      >
        <div>
          <div>
            <div
              className="container border p-3 px-4 bg-light"
            
            >
              <div>
                <div className="d-flex justify-content-between ">
                  <h3>TAX INVOICE</h3>
                  <div>
                    <p>
                      <b>INVOICE NO : </b> {bookData?.InvoiceNo}
                    </p>
                    <p>
                      <b>INVOICE DATE : </b>  {moment(bookData?.date).format("ll")}
                    </p>
                  </div>
                </div>
                <div className="Invoice_0">
                  <img src="../logo_eye.jpeg" alt="logo" style={{}}/>
                  <div>
                    <h5> Dhanya Travels</h5>
                    <p>Dhanya Travel Co.Pvt.Ltd</p>
                    <p>
                      {" "}
                      II Floor, Papanna Lane, No-8, St.Mark's Road <br />{" "}
                      Bangalore-560001, India.
                    </p>
                    <p>
                      <span>Tel: </span> +91 9876543210 <span>Cell:</span> +91
                      9876543321
                    </p>
                    <p>
                      <span>Email :</span> Ganesh@gmail.com{" "}
                      <span> Website :</span> DhanyaTravel.com
                    </p>
                  </div>
                  <div className="text-end">
                    <h5>{bookData?.hotelId?.hotalName}</h5>
                    <p style={
                      {width:"300px"}
                    }>
                    {bookData?.hotelId?.location}
                    </p>
                    <p>
                      <span> Cell:</span> +91
                      {bookData?.hotelId?.contact}
                    </p>
                    <p>
                      <span>Email :</span> {bookData?.hotelId?.email}
                    </p>
                  </div>
                </div>

                <hr />
                {/* <div className="iahsdijah">
                            <div>
                                <h6>BILL TO</h6>
                                <p><span>Booking ID : </span> #10245as154650</p>
                                <p><span>Room : </span> 54-A</p>
                                <p><span>Arrival Date : </span> 10/20/2023</p>
                                <p><span>Departure Date : </span> 10/20/2023</p>
                            </div>
                            <div>
                                <h6>INVOICE DETAILS</h6>
                                <p><span>Invoice ID : </span> #10245as154650</p>
                                <p><span>Invoice Date : </span> 54-A</p>
                                <p><span>Invoice Number : </span> 10/20/2023</p>
                            </div>
                        </div> */}
                <h6 className="fw-bold">Booking Details :</h6>
                <div className="mb-4">
                  <Table bordered striped responsive>
                    <tbody>
                      <tr>
                        <td>Booking ID : {bookData?.BookingId}</td>
                        <td>Booking Date :  {moment(bookData?.taxibookId?.createdAt).format(
                          "DD/MM/YYYY"
                        )}</td>
                        <td>Package Name : {bookData?.hotelbookId?.packageName}</td>
                        <td>Occupancy : {bookData?.hotelbookId?.Occupancy}</td>
                      </tr>
                      <tr>
                        <td>Name :{bookData?.Name}</td>
                        <td>Email : {bookData?.email}</td>
                        <td>Mobile : +91 {bookData?.mobile}</td>
                        <td>Number Of Guest : {bookData?.HotelPerson}</td>
                      </tr>
                      <tr>
                        <td>Check In date : {moment(bookData?.hotelbookId?.checkInDate).format(
                            "DD/MM/YYYY"
                          )} </td>
                        <td>Check Out Date :  {moment(bookData?.hotelbookId?.checkOutDate).format(
                            "DD/MM/YYYY"
                          )}</td>
                        <td>Check In Time :   {moment(bookData?.hotelbookId?.checkInTime, [
                            "HH:mm:ss",
                          ]).format("hh:mm a")}</td>
                        <td>Check Out Time : {moment(bookData?.hotelbookId?.checkOutTime, [
                            "HH:mm:ss",
                          ]).format("hh:mm a")}</td>
                      </tr>
                      <tr>
                        <td>Children : {bookData?.hotelbookId?.NumberOfChild?.length}</td>
                        <td>Adult : {bookData?.hotelbookId?.NumberOfGuest}</td>
                        <td>Status :  {bookData?.hotelbookId?.status}</td>
                        <td>Extra Matter :  {bookData?.hotelbookId?.message}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                <h6 className="fw-bold">Fare Break Up :</h6>
                <div className="mb-3">
                  <Table
                    bordered
                    striped
                    responsive
                    className="invoice-table-head"
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Room</th>
                        <th>Package</th>
                        <th>Occupancy</th>
                        <th>Adult</th>
                        <th>Children</th>
                        <th>Days</th>
                        <th>Nights</th>
                        {/* <th>Rate per night</th> */}
                        <th>Discout%</th>
                        <th>Total Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookData?.hotelbookId?.AloteRoom?.split(",")?.map((item,i)=>{
                      return ( <tr>
                        <td>{i+1}.</td>
                        <td>{item}</td>
                        <td>{bookData?.hotelbookId?.packageName}</td>
                        <td>{bookData?.hotelbookId?.Occupancy}</td>
                        <td>{bookData?.hotelbookId?.NumberOfGuest}</td>
                        <td>{bookData?.hotelbookId?.NumberOfChild?.length}</td>
                        <td>{bookData?.hotelbookId?.days}</td>
                        <td>{bookData?.hotelbookId?.days}</td>
                        {/* <td>{bookData?.hotelbookId?.Occupancy}</td> */}
                        <td>{0}</td>
                        <td>{(bookData?.hotelbookId?.packagePrice)?.toFixed(2)}</td>
                      </tr>)
                      })}
                     
                  
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-end">
                  <Table style={{ width: "400px" }}>
                    <tbody>
                      <tr>
                        <td className="fw-bold">SUBTOTAL : </td>
                        <td>₹{(bookData?.hotelbookId?.packagePrice)?.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">STATE TAX : </td>
                        <td>₹{bookData?.hotelId?.igst ? (bookData?.hotelbookId?.gst)?.toFixed(2):0.00}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">GST : </td>
                        <td>₹{(bookData?.hotelbookId?.gst)?.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">GRAND TOTAL : </td>
                        <td>₹{(parseInt(bookData?.hotelbookId?.totalAmount)+bookData?.hotelbookId?.extaraMatter)?.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
        <div>
          <button
            type="button"
            class="btn btn-warning"
            onClick={generatePDF}
            style={{ color: "white" }}
          >
            Download Pdf
          </button>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-danger"
            onClick={() => History(-1)}
          >
            Back
          </button>
        </div>
      </div>
      <br />
    </div>
  );
};
