import { Filter } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import ReactPaginate from "react-paginate";

function AddPackage() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [lodgeimage, setloadgeimages] = useState([]);
  const [packageName, setpackageName] = useState("");
  const [Price, setPrice] = useState("");

  // Get
  const [nochangedata,setnochangedata]=useState([])
  const getAllPackage = async () => {
    const config = {
      url: "/getAllPackage",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setloadgeimages(result.data.success);
        setnochangedata(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  const [AllCity, setAllcity] = useState([]);
  const getCity = async () => {
    try {
      const config = {
        url: "/GetCity",
        method: "get",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setAllcity(res.data.success);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AllVehicleModel, setAllVehicleModel] = useState([]);
  const getAllVehicleM = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllvehicleModel"
      );
      if (res.status == 200) {
        setAllVehicleModel(
          res.data.carList?.filter((ele) => ele?.vehicleType == "Taxi")
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllCategory, setAllCategory] = useState([]);
  const getAllCategory = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllVehicleCategory"
      );
      if (res.status == 200) {
        setAllCategory(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AllGst,setAllGst]=useState([]);

  const getAllGst=async()=>{
      try {
          let res=await axios.get("https://dhanyatours.me/api/admin/getGst");
          if(res.status==200){
              setAllGst(res.data.success)
          }
      } catch (error) {
          console.log(error);
      }
  }
  useEffect(() => {
    getAllPackage();
    getAllCategory();
    getAllVehicleM();
    getCity();
    getAllGst();
  }, []);

  const [city, setcity] = useState("");
  const [category, setcategory] = useState("");
  const [TripType, setTripType] = useState("");
  const [VehicleModel, setVehicleModel] = useState("");
  const [packagePrice, setpackagePrice] = useState(10);
  const [PerKmPrice, setPerKmPrice] = useState(0);
  const [VehicleType, setVehicleType] = useState("");
  const [FuelType, setFuelType] = useState("");
  const [CGST, setCGST] = useState(0);
  const [SGST, setSGST] = useState(0);
  const [IGST, setIGST] = useState(0);
  const [NightDriverAllowance, setNightDriverAllowance] = useState(0);
  const [PackageKm, setPackageKm] = useState("");
  const [packageHour, setpackageHour] = useState("");
  const [driverAllowances, setdriverAllowances] = useState(0);
  const [WaitingTimeLimit, setWaitingTimeLimit] = useState(0);
  const [WaitingCharge, setWaitingCharge] = useState(0);
  const [PerMinutesPrice, setPerMinutesPrice] = useState(0);

  const [packageType, setpackageType] = useState("");
  const [driverBata, setdriverBata] = useState("");
  const AddPackageData = async () => {
    if (
      !PackageKm |
      !packageHour |
      !packagePrice |
      !category |
      !city |
      !VehicleType |
      !VehicleModel |
      !FuelType |
      !driverAllowances
    )
      return alert("Please fill the form");
    try {
      const config = {
        url: "/addpackage",
        method: "post",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          city: city,
          category: category,
          packagePrice: packagePrice,
          packageHour: packageHour,
          PackageKm: PackageKm,
          PerKmPrice: PerKmPrice,
          PerMinutesPrice: PerMinutesPrice,
          driverAllowances: driverAllowances,
          VehicleType: VehicleType,
          VehicleModel: VehicleModel,
          FuelType: FuelType,
          CGST: CGST,
          IGST: IGST,
          SGST: SGST,
          NightDriverAllowance: NightDriverAllowance,
          WaitingTimeLimit: WaitingTimeLimit,
          WaitingCharge: WaitingCharge,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Add");
        handleClose();
        getAllPackage();
        setcity("");
        setPackageKm("");
        setpackageHour("");
        setpackagePrice("");
        setVehicleModel("");
        setcategory("");
        setFuelType("");
        setdriverAllowances(0);
        setNightDriverAllowance(0);
        setSGST(0);
        setCGST(0);
        setIGST(0);
        setPerKmPrice(0);
        setPerMinutesPrice(0);
        setWaitingCharge(0);
        setWaitingTimeLimit(0);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  // Delete
  const [deleteId, setDeleteId] = useState("");
  const [showDelete, setshowDelete] = useState(false);
  const handleDelete = () => setshowDelete(true);
  const closeDelete = () => setshowDelete(false);

  const lodgeImagesDelete = async () => {
    // e.preventDefault();
    try {
      const config = {
        url: "/deletePackage/" + deleteId,
        method: "delete",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Delete");
        closeDelete();
        getAllPackage();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(lodgeimage.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };



  const handleFilter = (e) => {
    if (e.target.value != "") {
    
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setloadgeimages([...filterTable]);
    } else {
     
      setloadgeimages([...nochangedata]);
    }
  };
  const [editId, seteditId] = useState("");
  const [editShow, seteditShow] = useState(false);
  const editHandShow = (data) => {
    setcity(data?.city);
    setPackageKm(data?.PackageKm);
    setpackageHour(data?.packageHour);
    setpackagePrice(data?.packagePrice);
    setVehicleModel(data?.VehicleModel);
    setVehicleType(data?.VehicleType);
    setFuelType(data?.FuelType);
    setcategory(data?.category);
 
    setdriverAllowances(data?.driverAllowances);
    setNightDriverAllowance(data?.NightDriverAllowance);
    setSGST(data?.SGST);
    setCGST(data?.CGST);
    setIGST(data?.IGST);
    setPerKmPrice(data?.PerKmPrice);
    setPerMinutesPrice(data?.PerMinutesPrice);
    setWaitingCharge(data?.WaitingCharge);
    setWaitingTimeLimit(data?.WaitingTimeLimit);
    seteditShow(true);
  };
  const closeEdit = () => seteditShow(false);

  const updatePackage = async () => {
    try {
      const config = {
        url: "/UpdatePackage",
        method: "put",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          id: editId?._id,
          city: city,
          category: category,
          packagePrice: packagePrice,
          packageHour: packageHour,
          PackageKm: PackageKm,
          PerKmPrice: PerKmPrice,
          PerMinutesPrice: PerMinutesPrice,
          driverAllowances: driverAllowances,
          VehicleType: VehicleType,
          VehicleModel: VehicleModel,
          FuelType: FuelType,
          CGST: CGST,
          IGST: IGST,
          SGST: SGST,
          NightDriverAllowance: NightDriverAllowance,
          WaitingTimeLimit: WaitingTimeLimit,
          WaitingCharge: WaitingCharge,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully updated");
        closeEdit();
        getAllPackage();
        setcity("");
        setPackageKm("");
        setpackageHour("");
        setpackagePrice("");
        setVehicleModel("");
        setcategory("");
        setFuelType("");
        setdriverAllowances(0);
        setNightDriverAllowance(0);
        setSGST(0);
        setCGST(0);
        setIGST(0);
        setPerKmPrice(0);
        setPerMinutesPrice(0);
        setWaitingCharge(0);
        setWaitingTimeLimit(0);
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  return (
    <>
      <div className="ms-5">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h3> Package List</h3>
          </div>

          <div
            className="logde-op-00"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setcity("");
              setPackageKm("");
              setpackageHour("");
              setpackagePrice("");
              setVehicleModel("");
              setcategory("");
              setFuelType("");
              setdriverAllowances(0);
              setNightDriverAllowance(0);
              setSGST(0);
              setCGST(0);
              setIGST(0);
              setPerKmPrice(0);
              setPerMinutesPrice(0);
              setWaitingCharge(0);
              setWaitingTimeLimit(0);
              handleShow();
            }}
          >
            + Package
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Package Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Package Km
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={PackageKm}
                    onChange={(e) => setPackageKm(e.target.value)}
                    placeholder="Enter package Km..."
                    className="title-90"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Package Hours
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={packageHour}
                    onChange={(e) => setpackageHour(e.target.value)}
                    placeholder="Enter package Hours..."
                    className="title-90"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Package Price
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={packagePrice}
                    onChange={(e) => setpackagePrice(e.target.value)}
                    placeholder="Enter package price..."
                    className="title-90"
                  />
                </div>
                <div className="col-md-6">
                  <label className="pb-2 nd-2">City</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setcity(e.target.value)}
                  >
                    <option>Select City </option>
                    {AllCity?.map((item) => {
                      return <option value={item?.City}>{item?.City}</option>;
                    })}
                  </Form.Select>
                </div>

                <div className="col-md-6">
                  <label className="pb-2 nd-2">Vehicle Category</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setcategory(e.target.value)}
                  >
                    <option>Select Vehicle Category </option>
                    {AllCategory?.map((item) => {
                      return (
                        <option value={item?.category}>{item?.category}</option>
                      );
                    })}
                  </Form.Select>
                </div>
                <div className="col-md-6">
                  <label className="pb-2 nd-2">Vehicle Model</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setVehicleModel(e.target.value)}
                  >
                    <option>Select Brand / Model</option>
                    {AllVehicleModel?.filter(
                      (ele) => ele?.category == category
                    )?.map((item) => {
                      return (
                        <option value={item?.vehicleModel}>
                          {item?.vehicleModel}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
                <div className="col-md-6">
                  <label className="pb-2 nd-2">Vehicle Type</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setVehicleType(e.target.value)}
                  >
                    <option value="">Select Vehicle Type</option>
                    <option value="Ac">Ac</option>
                    <option value="Non-Ac">Non-Ac</option>
                  </Form.Select>
                </div>
                <div className="col-md-6">
                  <label className="pb-2 nd-2">Vehicle Fuel Type</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setFuelType(e.target.value)}
                  >
                    <option value="">Select Fuel Type</option>
                    <option value="Petrol">Petrol</option>
                    <option value="Diesel">Diesel</option>
                    <option value="CNG">CNG</option>
                    <option value="Electric">Electric</option>
                  </Form.Select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Per Km Price
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={PerKmPrice}
                    onChange={(e) => setPerKmPrice(e.target.value)}
                    placeholder="Enter Per Km..."
                    className="title-90"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Per Minutes Price
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={PerMinutesPrice}
                    onChange={(e) => setPerMinutesPrice(e.target.value)}
                    placeholder="Enter Per minutes..."
                    className="title-90"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Driver Allowances
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={driverAllowances}
                    onChange={(e) => setdriverAllowances(e.target.value)}
                    placeholder="Enter Driver Allowances..."
                    className="title-90"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Night (10:00 pm - 06:00 am)
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    min={0}
                    id=""
                    value={NightDriverAllowance}
                    onChange={(e) => setNightDriverAllowance(e.target.value)}
                    placeholder="Enter Driver Allowances..."
                    className="title-90"
                  />
                </div>
                <div className="row">
                   
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                   CGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setCGST(e.target.value)}>
                     <option value={0}>Select</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="gst")?.map((item)=>{
                       return <option value={item?.Cgst}>{item?.Cgst}%</option>
                     })}
                   </select>
                  
                   </div>
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                   SGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setSGST(e.target.value)}>
                     <option value={0}>Select</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="gst")?.map((item)=>{
                       return <option value={item?.Sgst}>{item?.Sgst}%</option>
                     })}
                   </select>
                  
                   </div>
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                    IGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setIGST(e.target.value)}>
                     <option value={0}>Select</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="Igst")?.map((item)=>{
                       return <option value={item?.Igst}>{item?.Igst}%</option>
                     })}
                   </select>
                    
                   </div>
                 </div>
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Waiting Time limit (Minutes)
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={WaitingTimeLimit}
                    onChange={(e) => setWaitingTimeLimit(e.target.value)}
                    placeholder="Enter Waiting time ..."
                    className="title-90"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Waiting Charges / Minutes
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={WaitingCharge}
                    onChange={(e) => setWaitingCharge(e.target.value)}
                    placeholder="Enter Waiting Charges/minutes..."
                    className="title-90"
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Back
              </Button>
              <Button variant="primary" onClick={AddPackageData}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={editShow} onHide={closeEdit}>
            <Modal.Header closeButton>
              <Modal.Title>Update Package</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Package Km
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={PackageKm}
                    onChange={(e) => setPackageKm(e.target.value)}
                    placeholder="Enter package Km..."
                    className="title-90"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Package Hours
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={packageHour}
                    onChange={(e) => setpackageHour(e.target.value)}
                    placeholder="Enter package Hours..."
                    className="title-90"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Package Price
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={packagePrice}
                    onChange={(e) => setpackagePrice(e.target.value)}
                    placeholder="Enter package price..."
                    className="title-90"
                  />
                </div>
                <div className="col-md-6">
                  <label className="pb-2 nd-2">City</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setcity(e.target.value)}
                  >
                    <option>{city} </option>
                    {AllCity?.map((item) => {
                      return <option value={item?.City}>{item?.City}</option>;
                    })}
                  </Form.Select>
                </div>

                <div className="col-md-6">
                  <label className="pb-2 nd-2">Vehicle Category</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setcategory(e.target.value)}
                  >
                    <option>{category}</option>
                    {AllCategory?.map((item) => {
                      return (
                        <option value={item?.category}>{item?.category}</option>
                      );
                    })}
                  </Form.Select>
                </div>
                <div className="col-md-6">
                  <label className="pb-2 nd-2">Vehicle Model</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setVehicleModel(e.target.value)}
                  >
                    <option>{editId?.VehicleModel}</option>
                    {AllVehicleModel?.filter(
                      (ele) => ele?.category == category
                    )?.map((item) => {
                      return (
                        <option value={item?.vehicleModel}>
                          {item?.vehicleModel}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
                <div className="col-md-6">
                  <label className="pb-2 nd-2">Vehicle Type</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setVehicleType(e.target.value)}
                  >
                    <option value="">{editId?.VehicleType}</option>
                    <option value="Ac">Ac</option>
                    <option value="Non-Ac">Non-Ac</option>
                  </Form.Select>
                </div>
                <div className="col-md-6">
                  <label className="pb-2 nd-2">Vehicle Fuel Type</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setFuelType(e.target.value)}
                  >
                    <option value="">{editId?.FuelType}</option>
                    <option value="Petrol">Petrol</option>
                    <option value="Diesel">Diesel</option>
                    <option value="CNG">CNG</option>
                    <option value="Electric">Electric</option>
                  </Form.Select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Per Km Price
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={PerKmPrice}
                    onChange={(e) => setPerKmPrice(e.target.value)}
                    placeholder="Enter Per Km..."
                    className="title-90"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Per Minutes Price
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={PerMinutesPrice}
                    onChange={(e) => setPerMinutesPrice(e.target.value)}
                    placeholder="Enter Per minutes..."
                    className="title-90"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Driver Allowances
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={driverAllowances}
                    onChange={(e) => setdriverAllowances(e.target.value)}
                    placeholder="Enter Driver Allowances..."
                    className="title-90"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Night (10:00 pm - 06:00 am)
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    min={0}
                    id=""
                    value={NightDriverAllowance}
                    onChange={(e) => setNightDriverAllowance(e.target.value)}
                    placeholder="Enter Driver Allowances..."
                    className="title-90"
                  />
                </div>
                <div className="row">
                   
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                   CGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setCGST(e.target.value)}>
                   <option value={CGST}>{CGST}%</option>
                   <option value={0}>0%</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="gst")?.map((item)=>{
                       return <option value={item?.Cgst}>{item?.Cgst}%</option>
                     })}
                   </select>
                  
                   </div>
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                   SGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setSGST(e.target.value)}>
                   <option value={SGST}>{SGST}%</option>
                   <option value={0}>0%</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="gst")?.map((item)=>{
                       return <option value={item?.Sgst}>{item?.Sgst}%</option>
                     })}
                   </select>
                  
                   </div>
                   <div className="col-md-4">
                   <label className="pb-2 nd-2">
                    IGST%
                   </label><br/>
                   <select     className="title-90"  onChange={(e) => setIGST(e.target.value)}>
                   <option value={IGST}>{IGST}%</option>
                     <option value={0}>0%</option>
                     {AllGst?.filter((ele)=>ele?.gstType=="Igst")?.map((item)=>{
                       return <option value={item?.Igst}>{item?.Igst}%</option>
                     })}
                   </select>
                    
                   </div>
                 </div>
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Waiting Time limit (Minutes)
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={WaitingTimeLimit}
                    onChange={(e) => setWaitingTimeLimit(e.target.value)}
                    placeholder="Enter Waiting time ..."
                    className="title-90"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="pb-2 nd-2">
                    Waiting Charges / Minutes
                  </label>
                  <br />
                  <input
                    type="number"
                    name=""
                    id=""
                    min={0}
                    value={WaitingCharge}
                    onChange={(e) => setWaitingCharge(e.target.value)}
                    placeholder="Enter Waiting Charges/minutes..."
                    className="title-90"
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeEdit}>
                Back
              </Button>
              <Button variant="primary" onClick={updatePackage}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="searchHeadFlex">
          <div className="searchWrapper" style={{ display: "flex" }}>
            <input
              type="text"
              maxlength="230"
              style={{ outlineWidth: "0px", padding: "4px" }}
              className="textsearch"
              placeholder="Search by vehicle"
              onChange={handleFilter}
            />
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 16 16"
              id="BsSearch"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                clip-rule="evenodd"
              ></path>
              <path
                fill-rule="evenodd"
                d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>City</th>
              <th>Package Name</th>
              <th>Vehicle Category</th>
              <th>Vehicle Model</th>
              <th>Vehicle Type</th>
              <th>Vehicle Fuel</th>
              <th>Per km Price</th>
              <th>Per Minutes Price</th>
              <th>Package Price</th>
              <th>Driver Allowances</th>
              <th>Driver Allowances Night (10:00 pm - 06:00 am)</th>
              <th>CGST</th>
              <th>SGST</th>
              <th>IGST</th>
              <th>Waiting Charges</th>

              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {lodgeimage
              ?.slice(pagesVisited, pagesVisited + usersPerPage)
              ?.map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.city}</td>
                    <td>
                      <p  style={{ width: "150px" }}>
                        {" "}
                        {item?.PackageKm} Km with {item?.packageHour} Hours
                      </p>
                    </td>
                    <td>{item?.category}</td>
                    <td>{item?.VehicleModel}</td>
                    <td>{item?.VehicleType}</td>
                    <td>{item?.FuelType}</td>
                    <td>{item?.PerKmPrice}</td>
                    <td>{item?.PerMinutesPrice}</td>
                    <td>{item?.packagePrice}</td>

                    <td>{item?.driverAllowances}</td>
                    <td>{item?.NightDriverAllowance}</td>
                    <td>{item?.CGST}</td>
                    <td>{item?.SGST}</td>
                    <td>{item?.IGST}</td>
                    <td>
                      {item?.WaitingTimeLimit} /{item?.WaitingCharge}
                    </td>
                    <td style={{ display: "flex", gap: "5px" }}>
                      <div
                        className=""
                        onClick={() => {
                          seteditId(item);
                          editHandShow(item);
                        }}
                      >
                        <BiEdit style={{ color: "blue", fontSize: "25px" }} />
                      </div>
                      <div
                        className=""
                        onClick={() => {
                          setDeleteId(item._id);
                          handleDelete();
                        }}
                      >
                        <AiFillDelete
                          style={{ color: "red", fontSize: "25px" }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
      <Modal show={showDelete} onHide={closeDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete ??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <div style={{ float: "right" }}>
            <Button variant="danger" onClick={closeDelete}>
              Cancel
            </Button>{" "}
            <Button variant="success" onClick={lodgeImagesDelete}>
              Ok
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddPackage;
