import axios from "axios";
import React from "react";
import { useState } from "react";
import "./register.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function VenderLogin() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible1, setPasswordVisible1] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const Login = async () => {
    try {
      if (!email) {
        alert("Please enter email Id!");
      } else if (!password) {
        alert("Please enter password!");
      } else {
        const config = {
          url: "/loginVendor",
          method: "post",
          baseURL: "https://dhanyatours.me/api/vendor",
          headers: { "content-type": "application/json" },
          data: { email: email, password: password },
        };
        let res = await axios(config);
        if (res.status === 200) {
          sessionStorage.setItem("vendor", JSON.stringify(res.data.success));
          window.location.assign("/dashboard");
        }
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  return (
    <>
      <section
        class="bg-image"
        style={{
          backgroundImage: "url('../Image/bag-admin.jpg')",
          height: "100vh",
          backgroundSize: "cover",
        }}
      >
        <div class="mask d-flex align-items-center  gradient-custom-3 ">
          <div class="container">
            <div class="row d-flex justify-content-center align-items-center ">
              <div class="col-12 col-md-9 col-lg-7 col-xl-6">
                <div
                  class=""
                  style={{
                    borderRadius: "15px",
                    boxShadow:
                      " rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                  }}
                >
                  <div class="card-body " style={{ padding: "25px" }}>
                    <p
                      style={{
                        fontSize: "25px",
                        fontWeight: "700",
                        color: "#004aad",
                      }}
                    >
                      {" "}
                      Welcome to Dhanya Tours and Travels...{" "}
                    </p>
                    <p>
                      &nbsp; &nbsp; &nbsp; Thank you for your interest in
                      working with Dhanya Tours and Travels. Without you, we
                      would not be able to service our clients.
                    </p>
                    <div
                      class="text-uppercase text-center mb-2"
                      style={{
                        fontWeight: "bold",
                        fontSize: "25px",
                      }}
                    >
                      Login
                    </div>
                    <form>
                      <div class="form-outline mb-2">
                        <label class="form-label" for="form3Example3cg">
                          Your Email
                        </label>
                        <input
                          type="email"
                          class="form-control form-control-lg"
                          style={{ width: "100%" }}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div
                        style={{ position: "relative" }}
                        className="form-outline mb-2"
                      >
                        <label htmlFor="form3Example4cg" className="form-label">
                          Password
                        </label>
                        <input
                          type={passwordVisible ? "text" : "password"}
                          id="Rpassword"
                          // placeholder="Enter Password"
                          class="form-control form-control-lg"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          style={{ width: "100%" }}
                          // required
                        />{" "}
                        {passwordVisible ? (
                          <AiFillEyeInvisible
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            style={{
                              position: "absolute",
                              color: "#000",
                              bottom: "23%",
                              right: "5%",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <AiFillEye
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            style={{
                              position: "absolute",
                              color: "#000",
                              bottom: "23%",
                              right: "5%",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </div>

                      <div class="d-flex justify-content-center">
                        <button
                          type="button"
                          class="btn btn-success btn-block btn-lg gradient-custom-4 text-body"
                          style={{
                            background: "#004aad",
                            color: "white",
                            border: "#004aad",
                            width: "100%",
                          }}
                          onClick={Login}
                        >
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default VenderLogin;
