import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useLocation, useNavigate } from "react-router-dom";

function Taxi() {
 
  // console.log(Taxi);
  const location = useLocation();
  const history = useNavigate();

  // console.log("swagat karo hamara ", location.state);
  const [Taxi, setTaxi] = useState([]);
  const taxi = async () => {
    const config = {
      url: "/alltexi",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setTaxi(result.data.success);
        // getGst();
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  // getGraphicImage

  const [graphImage, setgraphImage] = useState([]);
  // console.log(graphImage, "orvih");
  const getGraphicImage = async () => {
    const config = {
      url: "/getAllImage",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setgraphImage(result.data.success);
     
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  const [gst, setgst] = useState({});
  const getGst = async () => {
    const config = {
      url: "/getGst",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setgst(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  const [AllOutStation,setAllOutStation]=useState([]);
const [nochangedata,setnochangedata]=useState([]);
const getOutStationData=async()=>{
    try {
        let res = await axios.get(
            "https://dhanyatours.me/api/admin/getDataByFromOrToOutsattion/"+location.state.pickupplace+"/"+location.state.dropplace
          );
          if (res.status == 200) {
            setAllOutStation(res.data.success);
            setnochangedata(res.data.success);
          }
    } catch (error) {
        console.log(error);
    }
}

const [AllPackage, setAllpackage] = useState([]);
const getAllPackage = async () => {
  const config = {
    url: "/getAllPackageByKmWithHours/"+location?.state?.packageType+"/"+location?.state?.driverBata+"/"+location?.state?.city,
    method: "get",
    baseURL: "https://dhanyatours.me/api/admin",
    headers: { "content-type": "application/json" },
  };
  try {
    const result = await axios(config);
    if (result.status === 200) {
      setAllpackage(result.data.success);
      // console.log("suceesss");
    } else {
      alert("Something went wrong");
    }
  } catch (error) {}
};
const [AllAirport,setAllAirport]=useState([]);
const getAirports=async()=>{
  try {
    let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllAirportsRateByAirportName/"+location.state.pickupplace
      );
      if (res.status == 200) {
        setAllAirport(res.data.success);
        // setnochangedata(res.data.success);
      }
} catch (error) {
    console.log(error);
}
}
  useEffect(() => {
    taxi();
    if(location?.state.tripType=="Outstation"){
       getOutStationData()
    }
    if(location?.state.tripType=="Airport"){
      getAirports()
   }
   
    getGraphicImage();
    // getGst();
    if(location?.state.tripType=="Local Drop"){
     getAllPackage()
   }
  
  }, []);
  useEffect(()=>{
    getGraphicImage();
  },[])

  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  };

  return (
    <>
      <OwlCarousel
        className="owl-theme"
        loop
        margin={2}
        nav={false}
        responsive={responsive}
        dots={false}
      >
        {graphImage
          ?.filter((e) => e?.name === "Vehicle")
          .map((ele) => {
            return (
              <div class="item">
                <div class="box">
                  <img
                    src={`https://dhanyatours.me/image/${ele?.image}`}
                    alt="Jungle Lodges and Resorts"
                    style={{ width: "100%", height: "300px" }}
                  />
                </div>
              </div>
            );
          })}
      </OwlCarousel>
      <div className="jungal">
        <div className="container">
          <div className="container">
            <div className="row">
              <h2
                style={{
                  fontWeight: "780",
                  color: "#318e91",
                  fontSize: "35px",
                  marginBottom: "20px",
                  lineHeight: "1",

                  textTransform: "uppercase",
                  letterSpacing: "0",
                  fontFamily: "Poppins,sans-serif",
                }}
              >
                {location?.state.tripType}
              </h2>
              <h5>
                From {location.state.pickupplace} to {location.state.dropplace}{" "}
                on {moment(location.state.pickdate).format("DD/MM/YYYY")}{" "}
                {moment(location.state.picktime, ["HH:mm:ss"]).format(
                  "hh:mm a"
                )}
              </h5>
              {/*..... Outstation .... */}
              {location?.state.tripType!=="Outstation" ? (
               <></>):(   <div className="col-md-9">
                {AllOutStation?.map((item) => {
                return(
                    <div className="listing">
                      <div className="row">
                        <div className="col-md-4 col-xs-4 text-left">
                          <img
                            class="img-responsive"
                            src={`https://dhanyatours.me/vehicle/${Taxi?.filter((ele)=>ele?.category==item?.category)[0]?.vehicleImage}`}
                            alt="kabini jungle resort"
                          />
                        </div>
                        <div class="col-md-8 col-xs-8 text-left">
                          <div class="car-text">
                            <h3>
                              <a> {item?.VehicleModel}</a>
                            </h3>
                            <div>
                            <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                {item?.category}  ({item?.VehicleType})
                              </span> <br/>
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                {item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm) } Km / ₹ {item?.PerKmPrice}
                              </span>
                              {/* <i class="icon-calendar">{location?.state?.divackageType}</i> */}
                            </div>
                            {/* <div>
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                Package Price ₹{" "}
                              </span>
                              <i class="icon-calendar">
                                {location?.state?.packagePrice}
                              </i>
                            </div> */}
                            <div>
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                Base Price ₹{" "}
                              </span>
                              <i class="icon-calendar">{(item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm))* item?.PerKmPrice}</i>
                            </div>
                            <div>
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                Driver Bata ₹{" "}
                              </span>
                              <i class="icon-calendar">
                                {location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances}
                              </i>
                            </div>
                            <span style={{ fontWeight: "600", color: "black" }}>
                              GST ₹{" "}
                            </span>{" "}
                            <i class="icon-calendar">
                              {(
                                (((item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm))* item?.PerKmPrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)+
                                  item?.TollCharge +
                                  item?.ParkingCharge) *
                                  ((item?.CGST + item?.SGST+item?.IGST) / 100)
                              )?.toFixed(2)}
                            </i>
                            <div>
                              <span
                                class="posted_by"
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                <i class="icon-briefcase"></i>Starts at ₹{" "}
                                {(((item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm))* item?.PerKmPrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)+
                                  item?.TollCharge +
                                  item?.ParkingCharge) +
                                  (
                                    (((item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm))* item?.PerKmPrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)+
                                    item?.TollCharge +
                                    item?.ParkingCharge) *
                                      ((item?.CGST + item?.SGST+item?.IGST) / 100)
                                  )}
                                /-
                              </span>
                            </div>
                            <div
                              class="btn btn-primary btn-sm hidden-xs"
                              onClick={() => {
                                history("/taxi-station", {
                                  state: {
                                    pickupplace: location?.state?.pickupplace,
                                    id: item,
                                    dropplace: location?.state?.dropplace,
                                    pickdate: location?.state?.pickdate,
                                    packageType: location?.state.packageType,
                                    packagePrice: (((item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm))* item?.PerKmPrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)+
                                    item?.TollCharge +
                                    item?.ParkingCharge) +
                                    (
                                      (((item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm))* item?.PerKmPrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)+
                                      item?.TollCharge +
                                      item?.ParkingCharge) *
                                        ((item?.CGST + item?.SGST+item?.IGST) / 100)
                                    ),
                                    driverBata: location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances,
                                    picktime: location?.state?.picktime,
                                    tripType: location?.state.tripType,
                                    gst:(
                                      (((item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm))* item?.PerKmPrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)+
                                        item?.TollCharge +
                                        item?.ParkingCharge) *
                                        ((item?.CGST + item?.SGST+item?.IGST) / 100)
                                    ),
                                    basePrice:(item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm))* item?.PerKmPrice,
                                    tripType:location?.state.tripType,
                                    totalKm:item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm),
                                    TollCharge: item?.TollCharge,
                                    ParkingCharge: item?.ParkingCharge,
                                  },
                                });
                              }}
                              // location.state.pickupplace
                            >
                              Book now
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ); 
                })}
                {/* === */}
              </div>)}
                {/*..... Air Port .... */}
              {location?.state.tripType=="Airport"? (   <div className="col-md-9">
                {AllAirport?.map((item) => {
                  return (
                    <div className="listing">
                      <div className="row">
                        <div className="col-md-4 col-xs-4 text-left">
                          <img
                            class="img-responsive"
                            src={`https://dhanyatours.me/vehicle/${Taxi?.filter((ele)=>ele?.category==item?.category)[0]?.vehicleImage}`}
                            alt="kabini jungle resort"
                          />
                        </div>
                        <div class="col-md-8 col-xs-8 text-left">
                          <div class="car-text">
                            <h3>
                            <a> {item?.VehicleModel}</a>
                            </h3>
                            <div>
                            <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                {item?.category}  ({item?.VehicleType})
                              </span> <br/>
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                {item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm) } Km / ₹ {item?.PerKmPrice}
                              </span>
                              {/* <i class="icon-calendar">{location?.state?.divackageType}</i> */}
                            </div>
                            {/* <div>
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                Package Price ₹{" "}
                              </span>
                              <i class="icon-calendar">
                                {location?.state?.packagePrice}
                              </i>
                            </div> */}
                            <div>
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                Base Price ₹{" "}
                              </span>
                              <i class="icon-calendar">{(item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm))* item?.PerKmPrice}</i>
                            </div>
                            <div>
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                Driver Bata ₹{" "}
                              </span>
                              <i class="icon-calendar">
                              {location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances}
                              </i>
                            </div>
                            <span style={{ fontWeight: "600", color: "black" }}>
                              GST ₹{" "}
                            </span>{" "}
                            <i class="icon-calendar">
                            {(
                                (((item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm))* item?.PerKmPrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)+
                                  item?.TollCharge +
                                  item?.ParkingCharge) *
                                  ((item?.CGST + item?.SGST+item?.IGST) / 100)
                              )?.toFixed(2)}
                            </i>
                            <div>
                              <span
                                class="posted_by"
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                <i class="icon-briefcase"></i>Starts at ₹{" "}
                                {(((item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm))* item?.PerKmPrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)+
                                  item?.TollCharge +
                                  item?.ParkingCharge) +
                                  (
                                    (((item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm))* item?.PerKmPrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)+
                                    item?.TollCharge +
                                    item?.ParkingCharge) *
                                      ((item?.CGST + item?.SGST+item?.IGST) / 100)
                                  )}
                                /-
                              </span>
                            </div>
                            <div
                              class="btn btn-primary btn-sm hidden-xs"
                              onClick={() => {
                                history("/taxi-station", {
                                  state: {
                                    pickupplace: location?.state?.pickupplace,
                                    id: item,
                                    dropplace: location?.state?.dropplace,
                                    pickdate: location?.state?.pickdate,
                                    packageType: location?.state.packageType,
                                    packagePrice: (((item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm))* item?.PerKmPrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)+
                                    item?.TollCharge +
                                    item?.ParkingCharge) +
                                    (
                                      (((item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm))* item?.PerKmPrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)+
                                      item?.TollCharge +
                                      item?.ParkingCharge) *
                                        ((item?.CGST + item?.SGST+item?.IGST) / 100)
                                    ),
                                    driverBata: location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances,
                                    picktime: location?.state?.picktime,
                                    tripType: location?.state.tripType,
                                    gst:(
                                      (((item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm))* item?.PerKmPrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)+
                                        item?.TollCharge +
                                        item?.ParkingCharge) *
                                        ((item?.CGST + item?.SGST+item?.IGST) / 100)
                                    ),
                                    basePrice:(item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm))* item?.PerKmPrice,
                                    tripType:location?.state.tripType,
                                    totalKm:item?.TotalKm > item?.baseKm ? (item?.TotalKm) :(item?.baseKm),
                                    TollCharge: item?.TollCharge,
                                    ParkingCharge: item?.ParkingCharge,
                                  },
                                });
                              }}
                              // location.state.pickupplace
                            >
                              Book now
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* === */}
              </div>):(<></>)}
              {/*..... Local .... */}
              {location?.state.tripType=="Local Drop"? (   <div className="col-md-9">
                {AllPackage?.map((item) => {
                  return (
                    <div className="listing">
                      <div className="row">
                        <div className="col-md-4 col-xs-4 text-left">
                          <img
                            class="img-responsive"
                            src={`https://dhanyatours.me/vehicle/${Taxi?.filter((ele)=>ele?.category==item?.category)[0]?.vehicleImage}`}
                            alt="kabini jungle resort"
                          />
                        </div>
                        <div class="col-md-8 col-xs-8 text-left">
                          <div class="car-text">
                            <h3>
                            <a> {item?.VehicleModel}</a>
                            </h3>
                            <div>
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                               {item?.category} ({item?.VehicleType})
                              </span>
                              {/* <i class="icon-calendar">{location?.state?.divackageType}</i> */}
                            </div>
                            <div>
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                Package Name {" "}
                              </span>
                              <i class="icon-calendar">
                                {item?.PackageKm} km with {item?.packageHour} hours
                              </i>
                            </div>
                            <div>
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                Base Price ₹{" "}
                              </span>
                              <i class="icon-calendar">{item?.packagePrice}</i>
                            </div>
                            <div>
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                Driver Bata ₹{" "}
                              </span>
                              <i class="icon-calendar">
                              {location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances}
                              </i>
                            </div>
                            <span style={{ fontWeight: "600", color: "black" }}>
                              GST ₹{" "}
                            </span>{" "}
                            <i class="icon-calendar">
                            {(
                                ((item?.packagePrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)) *
                                  ((item?.CGST + item?.SGST+item?.IGST) / 100)
                              )?.toFixed(2)}
                            </i>
                            <div>
                              <span
                                class="posted_by"
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                <i class="icon-briefcase"></i>Starts at ₹{" "}
                                {(item?.packagePrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)+
                                  (
                                    ((item?.packagePrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)) *
                                      ((item?.CGST + item?.SGST+item?.IGST) / 100)
                                  )}
                                /-
                              </span>
                            </div>
                            <div
                              class="btn btn-primary btn-sm hidden-xs"
                              onClick={() => {
                                history("/taxi-station", {
                                  state: {
                                    pickupplace: location?.state?.pickupplace,
                                    id: item,
                                    dropplace: location?.state?.dropplace,
                                    pickdate: location?.state?.pickdate,
                                    packageType: location?.state.packageType,
                                    packagePrice: (item?.packagePrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)+
                                    (
                                      ((item?.packagePrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)) *
                                        ((item?.CGST + item?.SGST+item?.IGST) / 100)
                                    ),
                                    driverBata:location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances,
                                    picktime: location?.state?.picktime,
                                    tripType: location?.state.tripType,
                                    gst:(
                                      ((item?.packagePrice)+(location?.state?.night==true ? item?.NightDriverAllowance: item?.driverAllowances)) *
                                        ((item?.CGST + item?.SGST+item?.IGST) / 100)
                                    ),
                                    tripType:location?.state.tripType,
                                    basePrice:item?.packagePrice,
                                    packageName:`${item?.PackageKm} km with ${item?.packageHour} hours`,
                                    totalKm:item?.PackageKm ,
                                       TollCharge: item?.TollCharge,
                                    ParkingCharge: item?.ParkingCharge,
                                  },
                                });
                              }}
                              // location.state.pickupplace
                            >
                              Book now
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* === */}
              </div>):(<></>)}
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Taxi;
