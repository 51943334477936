import React, { useEffect, useState } from 'react'
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import { AiOutlineArrowUp } from 'react-icons/ai';
import axios from 'axios';


const Dashboard = () => {
    const [counterOn, setCounterOn] = useState(false);

    const [booktaxi, setbooktaxi] = useState([]);

    const vendor = JSON.parse(sessionStorage.getItem("vendor"))
    const getBooking = async () => {
      const config = {
        url: "/getHotelBookingBywenderId/"+vendor?._id,
        method: "get",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "conttent-type": "application/json" },
      };
      try {
        const result = await axios(config);
        if (result.status === 200) {
          setbooktaxi(result.data.success);
      
        }
      } catch (error) {
        console.log(error);
      }
    };
    const [AllRoom, setAllRoom] = useState([]);
    const [nochangedata, setnochangedata] = useState([]);
    const getAllrooms = async () => {
      try {
        let res = await axios.get(
          "https://dhanyatours.me/api/user/getAllRoomsByVendorId/" + vendor?._id
        );
        if (res.status == 200) {
          setAllRoom(res.data.success);
          setnochangedata(res.data.success);
        }
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(()=>{
        if(vendor){
             getBooking();
             getAllrooms()
        }
       
    },[]);

    return (
        <>
            <div className="ms-5 mt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <a href="/vendor-rooms"> <div className='title-90' style={{ backgroundColor: "#004AAD", color: "white", height: "150px" }}>
                                <h4 className='text-center pt-5'>No. of Rooms</h4>
                                <ScrollTrigger
                                    onEnter={() => setCounterOn(true)}
                                    onExit={() => setCounterOn(false)}
                                >
                                    <h5 style={{ textAlign: "center" }}>
                                        {counterOn && (
                                            <CountUp
                                                start={0}
                                                end={AllRoom?.length}
                                                duration={2}
                                                delay={0}
                                                style={{ color: "white" }}
                                            />
                                        )}

                                    </h5>

                                </ScrollTrigger>
                            </div></a>

                        </div>
                        <div className="col-md-4">
                            <a href="/vendor-book-hotel">
                                <div className='title-90' style={{ backgroundColor: "#FF0000", color: "white", height: "150px" }}>
                                    <h4 className='text-center pt-5'>Bookings</h4>
                                    <ScrollTrigger
                                        onEnter={() => setCounterOn(true)}
                                        onExit={() => setCounterOn(false)}
                                    >
                                        <h5 style={{ textAlign: "center" }}>
                                            {counterOn && (
                                                <CountUp
                                                    start={0}
                                                    end={booktaxi?.length}
                                                    duration={2}
                                                    delay={0}
                                                    style={{ color: "white" }}
                                                />
                                            )}

                                        </h5>

                                    </ScrollTrigger>
                                </div>
                            </a>

                        </div>
                        <div className="col-md-4">
                            <a href="/vendor-check-in">
                                <div className='title-90' style={{ backgroundColor: "#5362B3", color: "white", height: "150px" }}>
                                    <h4 className='text-center pt-5'>Check In</h4>
                                    <ScrollTrigger
                                        onEnter={() => setCounterOn(true)}
                                        onExit={() => setCounterOn(false)}
                                    >
                                        <h5 style={{ textAlign: "center" }}>
                                            {counterOn && (
                                                <CountUp
                                                    start={0}
                                                    end={booktaxi?.filter((ele)=>ele.checkStatus=="CheckIn")?.length}
                                                    duration={2}
                                                    delay={0}
                                                    style={{ color: "white" }}
                                                />
                                            )}

                                        </h5>

                                    </ScrollTrigger>
                                </div>
                            </a>

                        </div>
                        <div className="col-md-4 mt-4">
                            <a href="/vendor-check-out">
                                <div className='title-90' style={{ backgroundColor: "#C12E2E", color: "white", height: "150px" }}>
                                    <h4 className='text-center pt-5'>Check Out</h4>
                                    <ScrollTrigger
                                        onEnter={() => setCounterOn(true)}
                                        onExit={() => setCounterOn(false)}
                                    >
                                        <h4 style={{ textAlign: "center" }}>
                                            {counterOn && (
                                                <CountUp
                                                    start={0}
                                                    end={booktaxi?.filter((ele)=>ele.checkStatus=="CheckOut")?.length}
                                                    duration={2}
                                                    delay={0}
                                                    style={{ color: "white" }}
                                                />
                                            )}

                                        </h4>

                                    </ScrollTrigger>
                                </div>
                            </a>

                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Dashboard