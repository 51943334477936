import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import ReactPaginate from "react-paginate";
function AddTaxi() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // Get Vehicle
  const [vehicle, setvehicle] = useState([]);

  const getVehicle = async () => {
    const config = {
      url: "/getAllVehicle",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setvehicle(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  const [AllCategory, setAllCategory] = useState([]);
  const getAllCategory = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllVehicleCategory"
      );
      if (res.status == 200) {
        setAllCategory(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllVehicleModel, setAllVehicleModel] = useState([]);
  const getAllVehicleM = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/admin/getAllvehicleModel"
      );
      if (res.status == 200) {
        setAllVehicleModel(
          res.data.carList?.filter((ele) => ele?.vehicleType == "Taxi")
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVehicle();
    getAllCategory();
    getAllVehicleM();
  }, []);

  // add Vehicle
  // const [vehicleModel, setvehicleModel] = useState("");

  const [category, setcategory] = useState("");
  const [vehicleImage, setvehicleImage] = useState("");
  const [vehicleNumber, setvehicleNumber] = useState("");
  const [brand, setbrand] = useState("");
  const [model, setmodel] = useState("");
  const [capacity, setcapacity] = useState("");
  const [VehicleType, setVehicleType] = useState("");
  const [FuelType, setFuelType] = useState("");
  const [RCDOC, setRCDOC] = useState("");
  const [Insurance, setInsurance] = useState("");
  const [number, setnumber] = useState("");
  const [email, setemail] = useState("");
  const [Emmission, setEmmission] = useState("");
  const [driverImage, setdriverImage] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [VehicleOtherDoc, setVehicleOtherDoc] = useState("");
  const [oneHrPrice, setoneHrPrice] = useState("");
  const addVehilce = async () => {
    // e.preventDefault();
    try {
      const config = {
        url: "/registerVehicle",
        method: "post",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          vehicleImage: vehicleImage,
          vehicleNumber: vehicleNumber,
          brand: brand,
          capacity: capacity,
          FuelType: FuelType,
          RCDOC: RCDOC,
          Insurance: Insurance,    
          Emmission: Emmission,
          VehicleOtherDoc: VehicleOtherDoc,
          VehicleType: VehicleType,
          category:category
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Added");
        handleClose();
        getVehicle();
        setvehicleImage("")
        setcategory("")
        setbrand("")
        setvehicleNumber("");
        setcapacity("")
        setVehicleType("")
        setFuelType("")
        setRCDOC("")
        setInsurance("")
        setEmmission("")
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  // Edit
  const [vehicleid, setvehicleid] = useState({});

  const editVehilce = async () => {
    try {
      const config = {
        url: "/editVehicle",
        method: "put",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          vehicleId: vehicleid?._id,
          vehicleImage: vehicleImage,
          vehicleNumber: vehicleNumber,
          brand: brand,
          capacity: capacity,
          FuelType: FuelType,
          RCDOC: RCDOC,
          Insurance: Insurance,    
          Emmission: Emmission,
          VehicleOtherDoc: VehicleOtherDoc,
          VehicleType: VehicleType,
          category:category
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully update");
        handleClose1();
        getVehicle();
       
        setvehicleImage("")
        setcategory("")
        setbrand("")
        setvehicleNumber("");
        setcapacity("")
        setVehicleType("")
        setFuelType("")
        setRCDOC("")
        setInsurance("")
        setEmmission("")
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  // Delete
  const taxiDelete = async (id) => {
    try {
      const config = {
        url: "/deleteVehicle/" + id,
        method: "delete",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Delete");

        getVehicle();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const MakeBlock = async (id, isAvailable) => {
    try {
      const config = {
        url: "/makeBlockTaxi",
        method: "post",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "application/json" },
        data: { vehicleId: id, isAvailable: isAvailable },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Success");
        getVehicle();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(vehicle.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = vehicle.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setvehicle([...vehicle]);
    }
  };
  return (
    <>
      <div className="de-lod">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h3>Taxi List</h3>
          </div>

          <div
            className="logde-op-00"
            style={{ cursor: "pointer" }}
            onClick={()=>{
          
              setcategory("")
              setbrand("")
              setvehicleNumber("");
              setcapacity("")
              setVehicleType("")
              setFuelType("")
            
              handleShow()}}
          >
            + Taxi
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Modal heading</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form>
                  <label className="pb-2 nd-2" htmlFor="upload1">
                    Vehicle Image
                  </label>
                  <br />
                  <input
                    type="file"
                    className="title-90"
                    name="VehicleImage"
                    id="upload1"
                    accept="image/*"
                    onChange={(e) => {
                      setvehicleImage(e.target.files[0]);
                    }}
                    required=""
                  />
                   <label className="pb-2 nd-2">Vehicle Category</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setcategory(e.target.value)}
                  >
                    <option>Select Category type</option>
                    {AllCategory?.map((item) => {
                      return (
                        <option value={item?.category}>{item?.category}</option>
                      );
                    })}
                  </Form.Select>
                  <label className="pb-2 nd-2">Brand/Model</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setbrand(e.target.value)}
                  >
                    <option>Select Brand / Model</option>
                    {AllVehicleModel?.filter((ele)=>ele?.category==category)?.map((item) => {
                      return (
                        <option value={item?.vehicleModel}>{item?.vehicleModel}</option>
                      );
                    })}
                  </Form.Select>
                  <label className="pb-2 nd-2">Vehicle Number</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="VehicleNumber"
                    value={vehicleNumber}
                    placeholder="Ex:-KA 03 6787"
                    onChange={(e) => {
                      setvehicleNumber(e.target.value);
                    }}
                  />
              
                  <label className="pb-2 nd-2">Capacity</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="Capacity"
                    value={capacity}
                    placeholder="Ex:-4"
                    onChange={(e) => {
                      setcapacity(e.target.value);
                    }}
                  />
               <label className="pb-2 nd-2">Vehicle Type</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setVehicleType(e.target.value)}
                  >
                    <option value="">Select Vehicle Type</option>
                    <option value="Ac">Ac</option>
                    <option value="Non-Ac">Non-Ac</option>
                  </Form.Select>
         
                  <label className="pb-2 nd-2">Vehicle Fuel Type</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setFuelType(e.target.value)}
                  >
                    <option value="">Select Fuel Type</option>
                    <option value="Petrol">Petrol</option>
                    <option value="Diesel">Diesel</option>
                    <option value="CNG">CNG</option>
                    <option value="Electric">Electric</option>
                  </Form.Select>
                  <label className="pb-2 nd-2" htmlFor="upload2">
                  Vehicle RC
                  </label>
                  <br />
                  <input
                    type="file"
                    className="title-90"
                    name="DriverImage"
                    id="upload2"
                    accept="image/*"
                    required=""
                    onChange={(e) => {
                      setRCDOC(e.target.files[0]);
                    }}
                  />
                  <label className="pb-2 nd-2" htmlFor="upload3">
                  Vehicle Insurance
                  </label>
                  <br />
                  <input
                    type="file"
                    className="title-90"
                    name="DriverImage"
                    id="upload3"
                    accept="image/*"
                    required=""
                    onChange={(e) => {
                      setInsurance(e.target.files[0]);
                    }}
                  />
                  <label className="pb-2 nd-2" htmlFor="upload4">
                  Vehicle Emissions
                  </label>
                  <br />
                  <input
                    type="file"
                    className="title-90"
                    name="DriverImage"
                    id="upload4"
                    accept="image/*"
                    required=""
                    onChange={(e) => {
                      setEmmission(e.target.files[0]);
                    }}
                  />
                  <label className="pb-2 nd-2" htmlFor="upload5">
                  Vehicle Other Document
                  </label>
                  <br />
                  <input
                    type="file"
                    className="title-90"
                    name="DriverImage"
                    id="upload5"
                    accept="image/*"
                    required=""
                    onChange={(e) => {
                      setVehicleOtherDoc(e.target.files[0]);
                    }}
                  />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Back
              </Button>
              <Button variant="primary" onClick={addVehilce}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/*====== Edit===== */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Modal heading</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form>
                  <label className="pb-2 nd-2" htmlFor="upload1">
                    Vehicle Image
                  </label>
                  <br />
                  <input
                    type="file"
                    className="title-90"
                    name="VehicleImage"
                    id="upload1"
                    accept="image/*"
                    onChange={(e) => {
                      setvehicleImage(e.target.files[0]);
                    }}
                    required=""
                  />
                   <label className="pb-2 nd-2">Vehicle Category</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setcategory(e.target.value)}
                  >
                    <option>{category? (category):("Select Category type")}</option>
                    {AllCategory?.map((item) => {
                      return (
                        <option value={item?.category}>{item?.category}</option>
                      );
                    })}
                  </Form.Select>
                  <label className="pb-2 nd-2">Brand/Model</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setbrand(e.target.value)}
                  >
                    <option>{vehicleid?.brand ?(vehicleid?.brand):("Select Brand / Model")}</option>
                    {AllVehicleModel?.filter((ele)=>ele?.category==category)?.map((item) => {
                      return (
                        <option value={item?.vehicleModel}>{item?.vehicleModel}</option>
                      );
                    })}
                  </Form.Select>
                  <label className="pb-2 nd-2">Vehicle Number</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="VehicleNumber"
                    value={vehicleNumber}
                    placeholder="Ex:-KA 03 6787"
                    onChange={(e) => {
                      setvehicleNumber(e.target.value);
                    }}
                  />
              
                  <label className="pb-2 nd-2">Capacity</label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="Capacity"
                    value={capacity}
                    placeholder="Ex:-4"
                    onChange={(e) => {
                      setcapacity(e.target.value);
                    }}
                  />
               <label className="pb-2 nd-2">Vehicle Type</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setVehicleType(e.target.value)}
                  >
                    <option value="">{vehicleid?.VehicleType ? (vehicleid?.VehicleType ):("Select Vehicle Type")}</option>
                    <option value="Ac">Ac</option>
                    <option value="Non-Ac">Non-Ac</option>
                  </Form.Select>
         
                  <label className="pb-2 nd-2">Vehicle Fuel Type</label>
                  <br />
                  <Form.Select
                    aria-label="Default select example"
                    className="title-90"
                    onChange={(e) => setFuelType(e.target.value)}
                  >
                    <option value="">{vehicleid?.FuelType ?(vehicleid?.FuelType):("Select Fuel Type")}</option>
                    <option value="Petrol">Petrol</option>
                    <option value="Diesel">Diesel</option>
                    <option value="CNG">CNG</option>
                    <option value="Electric">Electric</option>
                  </Form.Select>
                  <label className="pb-2 nd-2" htmlFor="upload2">
                  Vehicle RC
                  </label>
                  <br />
                  <input
                    type="file"
                    className="title-90"
                    name="DriverImage"
                    id="upload2"
                    accept="image/*"
                    required=""
                    onChange={(e) => {
                      setRCDOC(e.target.files[0]);
                    }}
                  />
                  <label className="pb-2 nd-2" htmlFor="upload3">
                  Vehicle Insurance
                  </label>
                  <br />
                  <input
                    type="file"
                    className="title-90"
                    name="DriverImage"
                    id="upload3"
                    accept="image/*"
                    required=""
                    onChange={(e) => {
                      setInsurance(e.target.files[0]);
                    }}
                  />
                  <label className="pb-2 nd-2" htmlFor="upload4">
                  Vehicle Emissions
                  </label>
                  <br />
                  <input
                    type="file"
                    className="title-90"
                    name="DriverImage"
                    id="upload4"
                    accept="image/*"
                    required=""
                    onChange={(e) => {
                      setEmmission(e.target.files[0]);
                    }}
                  />
                  <label className="pb-2 nd-2" htmlFor="upload5">
                  Vehicle Other Document
                  </label>
                  <br />
                  <input
                    type="file"
                    className="title-90"
                    name="DriverImage"
                    id="upload5"
                    accept="image/*"
                    required=""
                    onChange={(e) => {
                      setVehicleOtherDoc(e.target.files[0]);
                    }}
                  />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>
                Back
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  editVehilce();
                }}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
          {/* ==== */}
        </div>

        <div className="searchHeadFlex">
          <div className="searchWrapper" style={{ display: "flex" }}>
            <input
              type="text"
              maxlength="230"
              style={{ outlineWidth: "0px", padding: "4px" }}
              className="textsearch"
              placeholder="Search by vehicle"
              onChange={handleFilter}
            />
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 16 16"
              id="BsSearch"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                clip-rule="evenodd"
              ></path>
              <path
                fill-rule="evenodd"
                d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>VehicleImage</th>
              <th>Vehicle Category</th>
              <th>Brand/Model</th>
              <th>Vehicle Number</th>
              <th>Capacity</th>

              <th>Vehicle Type</th>
              <th>Vehicle Fuel Type</th>
              <th>Vehicle RC</th>
              <th>Vehicle Insurance</th>
              <th>Vehicle Emissions</th>
              <th>Vehicle Other Document</th>
            
              <th>Edit</th>
              <th>Delete</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {search.length > 0 ? (
              tableFilter
                .slice(pagesVisited, pagesVisited + usersPerPage)
                .map((ele, index) => {
                  return (
                    <tr>
                    <td>{index + 1}</td>
                    <td>
                      <a href={`https://dhanyatours.me/vehicle/${ele?.vehicleImage}`} target="_blank">
                      <img
                        src={`https://dhanyatours.me/vehicle/${ele?.vehicleImage}`}
                        alt="vehicle"
                        style={{ width: "100%" }}
                      /></a>
                    </td>
                    <td>{ele?.category}</td>
                    <td>{ele?.brand}</td>
                    <td>{ele?.vehicleNumber}</td>
                    <td>{ele?.capacity}</td>
                

                    <td>{ele?.VehicleType}</td>
                    <td>{ele.FuelType}</td>
                    <td>
                      {ele?.RCDOC ? ( <a href={`https://dhanyatours.me/vehicle/${ele?.RCDOC}`} target="_blank">
                      <img
                        src={`https://dhanyatours.me/vehicle/${ele?.RCDOC}`}
                        alt="vehicle"
                        style={{ width: "100%" }}
                      /></a>):(<></>)}
                     
                    </td>
                    <td>
                      {ele?.Insurance ? ( <a href={`https://dhanyatours.me/vehicle/${ele?.Insurance}`} target="_blank">
                      <img
                        src={`https://dhanyatours.me/vehicle/${ele?.Insurance}`}
                        alt="vehicle"
                        style={{ width: "100%" }}
                      /></a>):(<></>)}
                     
                    </td>
                    <td>
                      {ele?.Emmission ? (  <a href={`https://dhanyatours.me/vehicle/${ele?.Emmission}`} target="_blank">
                      <img
                        src={`https://dhanyatours.me/vehicle/${ele?.Emmission}`}
                        alt="vehicle"
                        style={{ width: "100%" }}
                      /></a>):(<></>)}
                    
                    </td>
                    <td>
                      {ele?.VehicleOtherDoc ? (  <a href={`https://dhanyatours.me/vehicle/${ele?.VehicleOtherDoc}`} target="_blank">
                      <img
                        src={`https://dhanyatours.me/vehicle/${ele?.VehicleOtherDoc}`}
                        alt="vehicle"
                        style={{ width: "100%" }}
                      /></a>):(<></>)}
                    
                    </td>
                    

                    <td>
                      <div
                        className=""
                        onClick={() => {
                          setvehicleid(ele);
                          setcategory(ele?.category)
                          setvehicleNumber(ele?.vehicleNumber);
                          setcapacity(ele?.capacity)
          
                          handleShow1();
                        }}
                      >
                        <FaEdit
                          style={{
                            fontSize: "26px",
                            color: " #5600ff",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        className=""
                        onClick={() => {
                          taxiDelete(ele?._id);
                        }}
                      >
                        <AiFillDelete
                          style={{
                            color: "red",
                            cursor: "pointer",
                            fontSize: "26px",
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      {ele?.isAvailable === false ? (
                        <button
                          type="button"
                          onClick={() => MakeBlock(ele?._id, true)}
                          class="btn btn-danger"
                        >
                          Block
                        </button>
                      ) : (
                        <button
                          type="button"
                          class="btn btn-warning"
                          onClick={() => MakeBlock(ele?._id, false)}
                        >
                          Unblock
                        </button>
                      )}
                    </td>
                  </tr>
                  );
                })
            ) : (
              <>
                {vehicle
                  ?.slice(pagesVisited, pagesVisited + usersPerPage)
                  ?.map((ele, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <a href={`https://dhanyatours.me/vehicle/${ele?.vehicleImage}`} target="_blank">
                          <img
                            src={`https://dhanyatours.me/vehicle/${ele?.vehicleImage}`}
                            alt="vehicle"
                            style={{ width: "100%" }}
                          /></a>
                        </td>
                        <td>{ele?.category}</td>
                        <td>{ele?.brand}</td>
                        <td>{ele?.vehicleNumber}</td>
                        <td>{ele?.capacity}</td>
                    

                        <td>{ele?.VehicleType}</td>
                        <td>{ele.FuelType}</td>
                        <td>
                          {ele?.RCDOC ? ( <a href={`https://dhanyatours.me/vehicle/${ele?.RCDOC}`} target="_blank">
                          <img
                            src={`https://dhanyatours.me/vehicle/${ele?.RCDOC}`}
                            alt="vehicle"
                            style={{ width: "100%" }}
                          /></a>):(<></>)}
                         
                        </td>
                        <td>
                          {ele?.Insurance ? ( <a href={`https://dhanyatours.me/vehicle/${ele?.Insurance}`} target="_blank">
                          <img
                            src={`https://dhanyatours.me/vehicle/${ele?.Insurance}`}
                            alt="vehicle"
                            style={{ width: "100%" }}
                          /></a>):(<></>)}
                         
                        </td>
                        <td>
                          {ele?.Emmission ? (  <a href={`https://dhanyatours.me/vehicle/${ele?.Emmission}`} target="_blank">
                          <img
                            src={`https://dhanyatours.me/vehicle/${ele?.Emmission}`}
                            alt="vehicle"
                            style={{ width: "100%" }}
                          /></a>):(<></>)}
                        
                        </td>
                        <td>
                          {ele?.VehicleOtherDoc ? (  <a href={`https://dhanyatours.me/vehicle/${ele?.VehicleOtherDoc}`} target="_blank">
                          <img
                            src={`https://dhanyatours.me/vehicle/${ele?.VehicleOtherDoc}`}
                            alt="vehicle"
                            style={{ width: "100%" }}
                          /></a>):(<></>)}
                        
                        </td>
                        

                        <td>
                          <div
                            className=""
                            onClick={() => {
                              setvehicleid(ele);
                              setcategory(ele?.category)
                              setvehicleNumber(ele?.vehicleNumber);
                              setcapacity(ele?.capacity)
              
                              handleShow1();
                            }}
                          >
                            <FaEdit
                              style={{
                                fontSize: "26px",
                                color: " #5600ff",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div
                            className=""
                            onClick={() => {
                              taxiDelete(ele?._id);
                            }}
                          >
                            <AiFillDelete
                              style={{
                                color: "red",
                                cursor: "pointer",
                                fontSize: "26px",
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          {ele?.isAvailable === false ? (
                            <button
                              type="button"
                              onClick={() => MakeBlock(ele?._id, true)}
                              class="btn btn-danger"
                            >
                              Block
                            </button>
                          ) : (
                            <button
                              type="button"
                              class="btn btn-warning"
                              onClick={() => MakeBlock(ele?._id, false)}
                            >
                              Unblock
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </>
            )}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
    </>
  );
}

export default AddTaxi;
