import { Filter } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";

function Category() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [lodgeimage, setloadgeimages] = useState([]);
  const [image1, setImages1] = useState();
  const [category, setcategory] = useState();

  // Get
  const getAllCategory = async () => {
    const config = {
      url: "/getAllCategory",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setloadgeimages(result.data.success);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const addCategory = async () => {
    try {
      const config = {
        url: "/addCategory",
        method: "post",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          image: image1,
          category: category,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Add");

        window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  // Delete
  const [deleteId, setDeleteId] = useState("");
  const [showDelete, setshowDelete] = useState(false);
  const handleDelete = () => setshowDelete(true);
  const closeDelete = () => setshowDelete(false);
  const lodgeImagesDelete = async () => {
    try {
      const config = {
        url: "/deleteCategory/" + deleteId,
        method: "delete",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        // sessionStorage.setItem("user", JSON.stringify(res.data.success));
        // history("/");
        alert("Successfully Delete");
        // lodges();
        window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const [editdata, seteditdata] = useState({});
  const [editshow, seteditshow] = useState(false);
  const edithandel = () => seteditshow(true);
  const closeEdit = () => seteditshow(false);
  const updateCategory = async () => {
    try {
      const config = {
        url: "/updateCategory",
        method: "put",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          id: editdata?._id,
          image: image1,
          category: category,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Updated");

        window.location.reload(true);
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  return (
    <>
      <div className="ms-5">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h3>Lodges Category</h3>
          </div>

          <div
            className="logde-op-00"
            style={{ cursor: "pointer" }}
            onClick={handleShow}
          >
            + Category
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form>
                  <label className="pb-2 nd-2" htmlFor="upload1">
                    Image
                  </label>
                  <br />
                  <input
                    type="file"
                    className="title-90"
                    name="lodgename"
                    id="upload1"
                    accept="image/*"
                    required=""
                    onChange={(e) => setImages1(e.target.files[0])}
                  />
                  <br />
                  <label className="pb-2 nd-2" htmlFor="upload1">
                    Category
                  </label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="lodgename"
                    placeholder="Enter category name"
                    onChange={(e) => setcategory(e.target.value)}
                  />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Back
              </Button>
              <Button variant="primary" onClick={() => addCategory()}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={editshow} onHide={closeEdit}>
            <Modal.Header closeButton>
              <Modal.Title>Update Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form>
                  <label className="pb-2 nd-2" htmlFor="upload1">
                    Image
                  </label>
                  <br />
                  <input
                    type="file"
                    className="title-90"
                    name="lodgename"
                    id="upload1"
                    accept="image/*"
                    required=""
                    onChange={(e) => setImages1(e.target.files[0])}
                  />
                  <br />
                  <label className="pb-2 nd-2" htmlFor="upload1">
                    Category
                  </label>
                  <br />
                  <input
                    type="text"
                    className="title-90"
                    name="lodgename"
                    placeholder={editdata?.category}
                    onChange={(e) => setcategory(e.target.value)}
                  />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeEdit}>
                Back
              </Button>
              <Button variant="primary" onClick={() => updateCategory()}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.No.</th>
              {/* <th>Name</th> */}
              <th>Image</th>
              <th>Category</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {lodgeimage?.map((ele, index) => {
              return (
                <tr>
                  <th>{index + 1}</th>
                  <th>
                    <img
                      src={`https://dhanyatours.me/category/${ele?.image}`}
                      alt="loadge-image"
                      style={{ width: "250px", height: "150px" }}
                    />
                  </th>
                  <th>{ele?.category}</th>
                  <th>
                    {" "}
                    <div
                      className=""
                      onClick={() => {
                        seteditdata(ele);
                        edithandel();
                      }}
                    >
                      <FaEdit
                        style={{
                          fontSize: "30px",
                          color: " #5600ff",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className=""
                      onClick={() => {
                        setDeleteId(ele._id);
                        handleDelete();
                      }}
                    >
                      <AiFillDelete
                        style={{
                          color: "red",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </th>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <Modal show={showDelete} onHide={closeDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete ??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <div style={{ float: "right" }}>
            <Button variant="danger" onClick={closeDelete}>
              Cancel
            </Button>{" "}
            <Button variant="success" onClick={lodgeImagesDelete}>
              Ok
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Category;
