import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";

function Child() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // getAllImage

  const [ChildData, setChildData] = useState([]);
  const getChildData = async () => {
    const config = {
      url: "/getChildSdata",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setChildData(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getChildData();
  }, []);

  // postImage
  const [ageType, setageType] = useState(3);
  const [discount, setdiscount] = useState("");
const [stAge,setstAge]=useState(0);
  const AddChild = async () => {
    
    const config = {
      url: "/AddChild",
      method: "post",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
      data: { ageType: `${stAge}-${ageType} Years`, discount: discount },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        alert(result.data.success);
        getChildData();
        setageType("");
        setdiscount("");
        setstAge("");
        handleClose()
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [deleteId,setDeleteId]=useState("");
  const [showDelete,setshowDelete]=useState(false);
  
  const deleteAge=async()=>{
    try {
      let res=await axios.delete("https://dhanyatours.me/api/admin/deleteChildSdata/"+deleteId);
      if(res.status==200){
        alert("Successfully deleted");
      
        setshowDelete(false);
        getChildData();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [showedit,setshowedit]=useState(false);

  const editAge=async()=>{
    let ab=""
    if(stAge || ageType){
      if(!stAge) return alert("Please enter minmum age");
      if(!ageType) return alert("Please enter maximum age")
      ab=`${stAge}-${ageType} Years`
    }
    const config = {
      url: "/updateChild",
      method: "put",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
      data: {id:deleteId, ageType: ab, discount: discount },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        alert(result.data.success);
        getChildData();
        setageType("");
        setdiscount("");
        setstAge("");
        setshowedit(false)
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [age,setage]=useState("")
  return (
    <div className="de-lod">
      <div
        className="mt-2 mb-3"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="logde-op">
          <h3>Children  Age Condition</h3>
        </div>
        <div
            className="logde-op-00"
            style={{ cursor: "pointer" }}
            onClick={()=>{
                setageType("");
                setdiscount("");
                setstAge("");
              handleShow()}}
          >
            + Add
          </div>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Age Limit</th>
            <th>Discont %</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
            {ChildData?.map((item,i)=>{
            return (
                <tr>
                    <td>{i+1}</td>
            <td>{item?.ageType}</td>
            <td>{item?.discount}%</td>
            <td style={{ display: "flex", gap: "5px" }}>
                          <div
                            className=""
                            onClick={() => {
                             setDeleteId(item?._id)
                             setage(item?.ageType)
                             setstAge("");
                             setageType("")
                              setdiscount(item?.discount);
                             setshowedit(true)

                            }}
                          >
                            <FaEdit
                              style={{
                                color: "#5600ff",
                                cursor: "pointer",
                                fontSize: "26px",
                              }}
                            />
                          </div>
                          <div
                            className=""
                            onClick={() => {
                              
                              setDeleteId(item?._id);
                              setshowDelete(true)
                            }}
                          >
                            <AiFillDelete
                              style={{
                                color: "red",
                                cursor: "pointer",
                                fontSize: "26px",
                              }}
                            />
                          </div>
                       
                        </td>
          </tr>
            )
            })}
          
       
        </tbody>
      </Table>

      <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton>
              <Modal.Title>Add Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form>
                    <div className="row">
                        <div className="col-md-6">
                        <label className="pb-2 nd-2">Minimum Age</label>
                  <br />
                  <input
                    type="number"
                    className="title-90"
                    name="lodgename"
                    min={1}
                    value={stAge}
                    placeholder="Please enter minimum age"
                    required
                    onChange={(e) => setstAge(e.target.value)}
                  />
                 
                        </div>
                        <div className="col-md-6">
                        <label className="pb-2 nd-2">Maximum Age</label>
                  <br />
                  <input
                    type="number"
                    className="title-90"
                    name="location"
                    min={1}
                    placeholder="Eg:-Bangalore"
                    value={ageType}
                    required
                    onChange={(e) => setageType(e.target.value)}
                  />
                        </div>
                    </div>
                
                
                  <label className="pb-2 nd-2" htmlFor="upload1">
                    Discount%
                  </label>
                  <br />
                  <input
                    type="number"
                    className="title-90"
                    name="lodgeprofile"
                    min={0}
                    placeholder="Eg:-50"
                    required
                    value={discount}
                    onChange={(e) => setdiscount(e.target.value)}
                  />
                
               
          
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Back
              </Button>
              <Button variant="primary" onClick={(e) => AddChild()}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

            {/* delete model */}
      <Modal show={showDelete} onHide={()=>setshowDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setshowDelete(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deleteAge}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      {/* edit model */}
      <Modal show={showedit} onHide={()=>setshowedit(false)} >
            <Modal.Header closeButton>
              <Modal.Title>Edit Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form>
                    <div className="row">
                      <p>{age}</p>
                        <div className="col-md-6">
                        <label className="pb-2 nd-2">Minimum Age</label>
                  <br />
                  <input
                    type="number"
                    className="title-90"
                    name="lodgename"
                    min={1}
                    value={stAge}
                    placeholder="Please enter minimum age"
                    required
                    onChange={(e) => setstAge(e.target.value)}
                  />
                 
                        </div>
                        <div className="col-md-6">
                        <label className="pb-2 nd-2">Maximum Age</label>
                  <br />
                  <input
                    type="number"
                    className="title-90"
                    name="location"
                    min={1}
                    placeholder="Eg:-Bangalore"
                    value={ageType}
                    required
                    onChange={(e) => setageType(e.target.value)}
                  />
                        </div>
                    </div>
                
                
                  <label className="pb-2 nd-2" htmlFor="upload1">
                    Discount%
                  </label>
                  <br />
                  <input
                    type="number"
                    className="title-90"
                    name="lodgeprofile"
                    min={0}
                    placeholder="Eg:-50"
                    required
                    value={discount}
                    onChange={(e) => setdiscount(e.target.value)}
                  />
                
               
          
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setshowedit(false)}>
                Back
              </Button>
              <Button variant="primary" onClick={(e) => editAge()}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
    </div>
  );
}

export default Child;
