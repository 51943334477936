import { TableCaption, TableContainer, Tbody, Td, Tr ,Table} from "@chakra-ui/react";
 
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Table1 from "react-bootstrap/Table"
import {
    AiFillDelete,
    AiOutlineDelete,
    AiOutlineDownload,
  } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { FaEye } from "react-icons/fa";
import moment from "moment";

const AdminEnquiry = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [showDelete, setshowDelete] = useState(false);
    const handleDelete = () => setshowDelete(true);
    const closeDelete = () => setshowDelete(false);
    const [deleteId, setDeleteId] = useState("");
  // const location = useLocation;
  const [tableFilter, settableFilter] = useState([]);
  const [booktaxi, setbooktaxi] = useState([]);
  // console.log(graphImage, "orvih");
  const getBooking = async () => {
    const config = {
      url: "/getallTrainBookOrder",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setbooktaxi(result.data.success?.filter((ele)=> ele.isLuxury==false));
        settableFilter(result.data.success?.filter((ele)=> ele.isLuxury==false));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDelete = async () => {
    const config = {
      url: "/deleteTrainOrder/" + deleteId,
      method: "delete",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        getBooking();
        closeDelete();
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getBooking();
  }, []);

  const [bookingtaxi, setbookingtaxi] = useState({});

  
  const makeConfirm = async (id) => {
    try {
      const config = {
        url: "/makeOderStatusChangeTrainBook",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          orderId: id,
          status: "Confirm",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getBooking();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const makeCancel = async (id) => {
    try {
      const config = {
        url: "/makeOderStatusChangeTrainBook",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          orderId: id,
          status: "Cancel",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getBooking();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const makeComplete = async (id) => {
    try {
      const config = {
        url: "/makeOderStatusChangeTrainBook",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          orderId: id,
          status: "Complete",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getBooking();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  //getVehicleById
  const createPDF = async () => {
    const input = document.getElementById("pdf");
    const options = { scrollY: -window.scrollY };
    const canvas = await html2canvas(input, options);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    pdf.save("Inquire.pdf");
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(booktaxi?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };



  const handleFilter = (e) => {
    if (e.target.value != "") {
  
      const filterTable = tableFilter?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setbooktaxi([...filterTable]);
    } else {
   
      setbooktaxi([...tableFilter]);
    }
  };

  const [AddShow,setAddShow]=useState(false);

  const [name,setname]=useState("");
  const [email,setemail]=useState("");
const [number,setnumber]=useState("");
  const [message,setmessage]=useState("");
  const onCreate = async () => {
    try {
      if (!name) {
        alert("Please enter full name!");
      } else if (!email) {
        alert("Please enter email!");
      } else if (!number) {
        alert("Please enter mobile number!");
      } else {
        const config = {
          url: "/createTrainBook",
          method: "post",
          baseURL: "https://dhanyatours.me/api/user",
          headers: { "conttent-type": "application/json" },
          data: {
            userName: name,
            email: email,
            number: number,
            message: message,
            isLuxury:false,
          },
        };
        const result = await axios(config);
        if (result.status === 200) {
          alert("Successfully added");
         setAddShow(false);
         getBooking()
         setname("");
         setemail("");
         setnumber("");
         setmessage("");
        } else {
          alert("Something went wrong");
        }
      }
    } catch (error) {
      console.log("erore", error);
      alert(error.response.data.error);
    }
  };
  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
const searchDate=()=>{
  if(!fromd) return alert("Please select from date");
  if(!tod) return alert("Please select to date");

 let std=fromd?.split("-");
      let entd=tod?.split("-")
      let startD=parseInt(std?.join(""));
      let endD=parseInt(entd?.join(""))
      let abc=tableFilter?.filter((ele)=>{
        // console.log("amit check it",moment(ele?.createdAt).format("YYYY-MM-DD").split("-"))
        let abd=moment(ele?.createdAt).format("YYYY-MM-DD").split("-")
        let amd=parseInt(abd?.join(""));
        return startD<=amd && endD>=amd
      })
      setbooktaxi(abc);
      // setdata(abc)
      // setfileName(`AcountHistory_${moment(fromd).format("DD/MM/YYYY")} to ${moment(tod).format("DD/MM/YYYY")}`)
}
    return (
        <>
            <div className="ms-5 mt-3">
                <div
                    className="mt-2 mb-3"
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <div className="logde-op">
                        <h3> Enquiries</h3>
                    </div>

                    <div
                        className="logde-op-00"
                        style={{ cursor: "pointer" }}
                        onClick={() => {

                            setAddShow(true);
                        }}
                    >
                        + Add Enquiry
                    </div>
                </div>
            
                <Modal show={AddShow} onHide={()=>setAddShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Enquiry</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="">
                            <form>

                                <label className="pb-2 nd-2">Name</label>
                                <br />
                                <input type="text" placeholder="Please Enter Name...." className="title-90"  value={name} onChange={(e)=>setname(e.target.value)}/>
                                <br />
                                <label className="pb-2 nd-2">Email</label>
                                <br />
                                <input type="email" placeholder="Please Enter Name...." className="title-90"  value={email} onChange={(e)=>setemail(e.target.value)} />
                                <br />
                                <label htmlFor="" className="pb-2 nd-2">Mobile Number</label>
                                <br />
                                <input type="text" name="" id="" maxLength="10" placeholder="Enter Mobile Number..." value={number} onChange={(e)=>setnumber(e.target.value)}
                                    className="title-90"
                                />

                                <br />
                                <label htmlFor="" className="pb-2 nd-2">Message</label>
                                <br />
                                <textarea name="" id="" cols="30" rows="10" className="title-90" placeholder="Message...."  value={message} onChange={(e)=>setmessage(e.target.value)}></textarea>
                                <br />
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>setAddShow(false)}>Back</Button>
                        <Button variant="primary" onClick={onCreate} >
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={show} onHide={handleClose} id="pdf">
          <Modal.Header
            closeButton
            style={{
              backgroundColor: "#2fdbeb",
              color: "white",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            Inquiry luxury Train Details
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <form>
                <label className="pb-2 nd-2">EnquiryId{" :"}</label>{" "}
                <span>{bookingtaxi?._id}</span>
                <br />
                <label className="pb-2 nd-2">Name {" :"}</label>{" "}
                <span>{bookingtaxi?.userName}</span> <br />
                <label className="pb-2 nd-2">Number{" :"}</label>{" "}
                <span> {bookingtaxi?.number}</span>
                <br />
                <label className="pb-2 nd-2">Email{" :"}</label>{" "}
                <span>{bookingtaxi?.email}</span>
                <br />
                <label className="pb-2 nd-2">Message{" :"}</label>{" "}
                {bookingtaxi?.message}
                <br />
                <br />
                <TableContainer>
                  <Table variant="striped" colorScheme="teal">
                    <TableCaption></TableCaption>

                    <Tbody>
                      <Tr>
                        <Td>Status</Td>
                        <Td>{bookingtaxi?.status}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <AiOutlineDownload
              style={{
                color: "red",
                cursor: "pointer",
                fontSize: "36px",
                float: "right",
              }}
              onClick={() => createPDF()}
            />
            {bookingtaxi?.status === "Complete" ? (
              <></>
            ) : (
              <>
                {bookingtaxi?.status === "Confirm" ? (
                  <>
                    <Button
                      variant="danger"
                      onClick={() => {
                        makeCancel(bookingtaxi?._id);
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => {
                        makeComplete(bookingtaxi?._id);
                        handleClose();
                      }}
                    >
                      Complete
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="warning"
                    onClick={() => {
                      makeConfirm(bookingtaxi?._id);
                      handleClose();
                    }}
                  >
                    Confirm
                  </Button>
                )}
              </>
            )}

            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>


        <div className="row">
              <div className="col-md-4">
                <div className="searchHeadFlex">
              <div className="searchWrapper" style={{ display: "flex" }}>
                <input
                  type="text"
                  maxlength="230"
                  style={{ outlineWidth: "0px", padding: "4px" }}
                  className="textsearch"
                  placeholder="Search by ..."
                  onChange={handleFilter}
                />
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  id="BsSearch"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                    clip-rule="evenodd"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
           
              </div>
              <div className="col-md-2">
              <label htmlFor="">From</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => setfromd(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="">To</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => settod(e.target.value)}
              />
            </div>
            <div className="col-md-2">
         
              <button onClick={searchDate}
                style={{
                  borderRadius: "3px",
                  color: "white",
                  fontSize: "18px",
                  border: "1px solid",
                  backgroundColor: "#004aad",
                  padding: "5px",
                  marginTop: "26px",
                  borderRadius: "10px",
                  fontWeight: 500,
                }}
              >
                Search
              </button>
            </div>
            </div>
                <Table1 striped bordered hover>
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile No.</th>
                            <th>Message</th>
                            <th>Status</th>

                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {booktaxi
                    ?.slice(pagesVisited, pagesVisited + usersPerPage)
                    ?.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.userName}</td>
                          <td>{item?.number}</td>
                          <td>{item?.email}</td>
                          <th>{item?.message}</th>
                          <td>{item?.status}</td>
                          {item?.status === "Pending" ? (
                            <td>
                              <div style={{ display: "flex", gap: "10px" }}>
                                <FaEye
                                  style={{
                                    fontSize: "26px",
                                    color: " #5600ff",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleShow();
                                    setbookingtaxi(item);
                                  }}
                                />
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  onClick={() => makeConfirm(item?._id)}
                                >
                                  Confirm
                                </button>{" "}
                                <button
                                  type="button"
                                  class="btn btn-danger"
                                  onClick={() => makeCancel(item?._id)}
                                >
                                  Cancel
                                </button>
                              </div>
                            </td>
                          ) : (
                            <td>
                              <div style={{ display: "flex", gap: "10px" }}>
                                <span>
                                  <FaEye
                                    style={{
                                      color: "#5600ff",
                                      cursor: "pointer",
                                      fontSize: "26px",
                                    }}
                                    onClick={() => {
                                      handleShow();
                                      setbookingtaxi(item);
                                    }}
                                  />
                                </span>
                                <span>
                                  <AiOutlineDelete
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                      fontSize: "26px",
                                    }}
                                    onClick={() => {
                                      setDeleteId(item?._id);
                                      setshowDelete(true);
                                    }}
                                  />
                                </span>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}

                    </tbody>
                </Table1>
                <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                />
            </div>
            <Modal show={showDelete} onHide={closeDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure delete ??</Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <div style={{ float: "right" }}>
                        <Button variant="danger" onClick={closeDelete}>
                            Cancel
                        </Button>{" "}
                        <Button variant="success" onClick={getDelete} >
                            Ok
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AdminEnquiry