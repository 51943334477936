import axios from "axios";
import React, { useState } from "react";

function Contact() {
  const [fullName, setfullName] = useState();
  const [number, setnumber] = useState();
  const [email, setemail] = useState();
  const [subject, setsubject] = useState();
  const [comments, setcomments] = useState();

  const onContact = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/createContact",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          fullName: fullName,
          number: number,
          email: email,
          subject: subject,
          comments: comments,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
      
        alert("Thank You our team will contact as soon");
       
        window.location.assign("/");
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  return (
    <>
      <div
        class="hero-wrap js-fullheight"
        style={{
          backgroundImage: "url('../Image/contact_banner.jpg')",
          height: "300px",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div class="overlay"></div>
        <div class="container ">
          <div
            class="row no-gutters slider-text js-fullheight align-items-center justify-content-center"
            data-scrollax-parent="true"
            style={{ height: "393px" }}
          >
            <div
              class="col-md-9 text-center ftco-animate fadeInUp ftco-animated "
              data-scrollax=" properties: { translateY: '70%' }"
              style={{ transform: "translateZ(0px) translateY(0%)" }}
            >
              {/* <h1
                class="mb-3 bread"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                style={{
                  opacity: "1",
                  transform: "translateZ(0px) translateY(0%)",
                }}
              >
                Contact
              </h1> */}
            </div>
          </div>
        </div>
      </div>
      <div className="ftco-section contact-section ftco-degree-bg mt-4">
        <div className="container">
          <div className="row d-flex mb-5 contact-info">
            <div className="col-md-12 mb-4">
              <h2 class="h4">Contact Information</h2>
            </div>
            <div class="col-md-3">
              <p>
                <span style={{ color: "white" }}>Address:</span>
                {/* 198 West 21th Street, Suite 721 New York
                NY 10016 */}
              </p>
            </div>
            <div class="col-md-3">
              <p>
                <span style={{ color: "white" }}>Phone:</span>{" "}
                <a href="#" style={{ color: "white" }}>
                  +919035023309
                </a>
              </p>
            </div>
            <div class="col-md-3">
              <p>
                <span style={{ color: "white" }}>Email:</span>{" "}
                <a href="#" style={{ color: "white" }}>
                  {/* info@yoursite.com */}
                </a>
              </p>
            </div>
            <div class="col-md-3">
              <p>
                {/* <span>Website:</span>{" "} */}
                <a href="#" style={{ color: "white" }}>
                  dhanyatoursandtravels.com
                </a>
              </p>
            </div>

            <div class="row block-9">
              <div class="col-md-6 order-md-last pr-md-5">
                <form onSubmit={(e) => onContact(e)}>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Your Name"
                      required
                      value={fullName}
                      onChange={(e) => setfullName(e.target.value)}
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Your Email"
                      required
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Mobile No"
                      required
                      value={number}
                      onChange={(e) => setnumber(e.target.value)}
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Subject"
                      required
                      value={subject}
                      onChange={(e) => setsubject(e.target.value)}
                    />
                  </div>
                  <div class="form-group">
                    <textarea
                      name=""
                      rows="7"
                      cols="55"
                      class="form-control-2"
                      placeholder="Message"
                      required
                      value={comments}
                      onChange={(e) => setcomments(e.target.value)}
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <input
                      type="submit"
                      value="Send Message"
                      class="btn btn-primary py-3 px-5"
                    />
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <div className="map">
                  <div className="">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d497699.9973874144!2d77.35074421903857!3d12.95384772557775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1669372973308!5m2!1sen!2sin"
                      width="100%"
                      height="400"
                      style={{ border: "0" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
