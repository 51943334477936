import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  AiFillDelete,
  AiOutlineDelete,
  AiOutlineDownload,
} from "react-icons/ai";
import { FaEdit, FaEye } from "react-icons/fa";
import Table1 from "react-bootstrap/Table";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useLocation } from "react-router-dom";
import moment from "moment";

const Check_Out = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);


  const vendor = JSON.parse(sessionStorage.getItem("vendor"));
  const [booktaxi, setbooktaxi] = useState([]);

  const [nochangedata, setnochangedata] = useState([]);
  const getBooking = async () => {
    const config = {
      url: "/getHotelBookingBywenderId/" + vendor?._id,
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setbooktaxi(
          result.data.success?.filter((ele) => ele.checkStatus == "CheckOut")
        );
        setnochangedata(
          result.data.success?.filter((ele) => ele.checkStatus == "CheckOut")
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (vendor) {
      getBooking();
    }
  }, []);

  const handleFilter = (e) => {
    if (e.target.value != "") {
   
      const filterTable = nochangedata?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setbooktaxi([...filterTable]);
    } else {
   
      setbooktaxi([...nochangedata]);
    }
  };

  const [bookingtaxi, setbookingtaxi] = useState({});

  
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    booktaxi.length /
      usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const createPDF = async () => {
    const input = document.getElementById("pdf");
    const options = { scrollY: -window.scrollY };
    const canvas = await html2canvas(input, options);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    pdf.save("CheckOut.pdf");
  };
  const [date1,setdate1]=useState("");
  const [date2,setdate2]=useState("");
// console.log("bbbbb",bookingtaxi);

const [fromd, setfromd] = useState("");
const [tod, settod] = useState("");
const searchDate=()=>{
if(!fromd) return alert("Please select from date");
if(!tod) return alert("Please select to date");

let std=fromd?.split("-");
    let entd=tod?.split("-")
    let startD=parseInt(std?.join(""));
    let endD=parseInt(entd?.join(""))
    let abc=nochangedata?.filter((ele)=>{
      // console.log("amit check it",moment(ele?.createdAt).format("YYYY-MM-DD").split("-"))
      let abd=moment(ele?.createdAt).format("YYYY-MM-DD").split("-")
      let amd=parseInt(abd?.join(""));
      return startD<=amd && endD>=amd
    })
    setbooktaxi(abc);
    // setdata(abc)
    // setfileName(`AcountHistory_${moment(fromd).format("DD/MM/YYYY")} to ${moment(tod).format("DD/MM/YYYY")}`)
}

  return (
    <>
      <div className="ms-4">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op ms-4">
            <h3>Check Out Details</h3>
          </div>
        </div>

        <Modal show={show} onHide={handleClose} id="pdf">
          <Modal.Header
            closeButton
            style={{
              backgroundColor: "#2fdbeb",
              color: "white",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            Check Out Details
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <form>
                <label className="pb-2 nd-2">Room No.{" :"}</label>{" "}
                <span>{bookingtaxi?.AloteRoom}</span>
                <br />
                <label className="pb-2 nd-2">BookId{" :"}</label>                <span>{bookingtaxi?.bookId}</span>
                <br />
                <label className="pb-2 nd-2">{vendor?.vendorType} Name {" :"}</label>{" "}
                <span>{bookingtaxi?.lodgeId?.hotalName}</span> <br />
                <label className="pb-2 nd-2">
                  Name {" :"}
                </label>  <span>{bookingtaxi?.userName}</span> <br />
                <label className="pb-2 nd-2">Number{" :"}</label> <span>{bookingtaxi?.number} </span>
                <br />
                <label className="pb-2 nd-2">Email{" :"}</label> <span>{bookingtaxi?.email}</span>
                <br />
                <label className="pb-2 nd-2">
                  Check-In Date/Time{" :"}
                </label>{" "}
                <span>{moment(bookingtaxi?.checkInDate).format("DD/MM/YYYY")}{"  "}
                       {moment(bookingtaxi?.checkInTime, ["HH:mm:ss"]).format(
                         "hh:mm a"
                       )}</span>
                <br />
                <label className="pb-2 nd-2">
                  Check-Out Date/Time{" :"}
                </label>{" "}
                <span> {moment(bookingtaxi?.checkOutDate).format("DD/MM/YYYY")}
                       {"  "}
                       {moment(bookingtaxi?.checkOutTime, ["HH:mm:ss"]).format(
                         "hh:mm a"
                       )}{" "}</span>
                <br />
                <label className="pb-2 nd-2">Number of Days{" :"}</label>{" "}
                <span>{bookingtaxi?.days} </span>
                <br />
                <label className="pb-2 nd-2">Amount{" :"}</label> <span>{(parseInt(bookingtaxi?.totalAmount)+bookingtaxi?.extaraMatter)?.toFixed(2)}</span>
                <br />
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <AiOutlineDownload
              style={{
                color: "red",
                cursor: "pointer",
                fontSize: "36px",
                float: "right",
              }}
              onClick={createPDF}
            />

            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete?</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <Button variant="primary">Ok</Button>
          </Modal.Footer>
        </Modal>
        <div className="taxi-table ms-4">
        <div className="row">
              <div className="col-md-4">
                <div className="searchHeadFlex">
                  <div className="searchWrapper" style={{ display: "flex" }}>
                    <input
                      type="text"
                      maxlength="230"
                      style={{ outlineWidth: "0px", padding: "4px" }}
                      className="textsearch"
                      placeholder="Search by ..."
                      onChange={handleFilter}
                    />
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      id="BsSearch"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                        clip-rule="evenodd"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
              <label htmlFor="">From</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => setfromd(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="">To</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => settod(e.target.value)}
              />
            </div>
            <div className="col-md-2">
         
              <button onClick={searchDate}
                style={{
                  borderRadius: "3px",
                  color: "white",
                  fontSize: "18px",
                  border: "1px solid",
                  backgroundColor: "#004aad",
                  padding: "5px",
                  marginTop: "23px",
                  borderRadius: "10px",
                  fontWeight: 500,
                }}
              >
                Search
              </button>
            </div>
              
            </div>
          <div className="scrolll_bar">
            <Table1 striped bordered hover>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Booking Id</th>
                  <th>{vendor?.vendorType} name</th>
                  <th>User Name</th>
                  <th>Number</th>
                  <th>CheckIn Date/Time</th>
                  <th>CheckOut Date/Time</th>
                  <th>Package Name</th>
                  <th>Occupancy</th>
                  <th>Package Price ₹</th>
                  <th>Number of Adults</th>
                  <th>No. of Rooms/Room Number</th>
                  <th>Total Amount</th>

                  <th> Payment Status</th>
                  <th>Action</th>
                  <th>Invoice</th>
                </tr>
              </thead>
              <tbody>
                {booktaxi ?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>

                      <td>{item?.bookId}</td>
                      <th>{item?.lodgeId?.hotalName}</th>
                      <td>{item?.userName}</td>
                      <td>{item?.number}</td>
                      <td>
                        {moment(item?.checkInDate).format("DD/MM/YYYY")}
                        {"  "}
                        {moment(item?.checkInTime, ["HH:mm:ss"]).format(
                          "hh:mm a"
                        )}{" "}
                      </td>
                      <td>
                        {moment(item?.checkOutDate).format("DD/MM/YYYY")}
                        {"  "}
                        {moment(item?.checkOutTime, ["HH:mm:ss"]).format(
                          "hh:mm a"
                        )}{" "}
                      </td>
                      <td>{item?.packageName}</td>
                      <td>{item?.Occupancy}</td>
                      <td>{item?.packagePrice}</td>
                      <td>{item?.NumberOfGuest}</td>
                      <td>
                        {item?.AloteRoom
                          ? item?.AloteRoom?.split(",")?.length
                          : ""}{" "}
                        {"Room No:"}
                        {` ${item?.AloteRoom}`}
                      </td>
                      <td>{(parseInt(item?.totalAmount)+item?.extaraMatter)?.toFixed(2)}</td>

                      <td>{item?.status}</td>
                      <td>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <span>
                            <FaEye
                              style={{
                                color: "#5600ff",
                                cursor: "pointer",
                                fontSize: "26px",
                              }}
                              onClick={()=>{
                                setdate1(moment(item?.checkInDate));
                                setdate2(moment(item?.checkOutDate))
                                setbookingtaxi(item)
                                handleShow()}}
                            />
                          </span>
                    
                        </div>
                      </td>
                      <td><a href={"/Hotel-Invoice/"+item?._id} >
                       <button type="button" class="btn btn-success">View</button>
                        </a></td>
                    </tr>
                  );
                })}

  
              </tbody>
            </Table1>
          </div>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </div>
    </>
  );
};

export default Check_Out;
