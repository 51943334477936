import { Select } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table1 from "react-bootstrap/Table";
import { AiOutlineDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import ReactPaginate from "react-paginate";

const Rooms = () => {
  const [show1, setShow1] = useState(false);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
  const [show, setShow] = useState(false);
  const [edit, setedit] = useState({});
  const handleShow = (data) => {
    setedit(data);
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const vendor = JSON.parse(sessionStorage.getItem("vendor"));
  const [category, setCategory] = useState("");
  const [RoomType, setRoomType] = useState("");
  const [roomNo, setroomNo] = useState("");

  const [AllCategory, setAllCategory] = useState([]);
  const getAllCategory = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/user/getAllRoomcategoryByVendorId/" +
          vendor?._id
      );
      if (res.status == 200) {
        setAllCategory(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AllRoom, setAllRoom] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getAllrooms = async () => {
    try {
      let res = await axios.get(
        "https://dhanyatours.me/api/user/getAllRoomsByVendorId/" + vendor?._id
      );
      if (res.status == 200) {
        setAllRoom(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (vendor) {
      getAllCategory();
      getAllrooms();
    }
  }, []);
  console.log();

  const createRegister = async () => {
    if (!category) return alert("Please select category");
    if (!RoomType) return alert("Please select room type");
    if (!roomNo) return alert("Please enter room number");
    let am = AllRoom.filter((item) => item.roomNumber == roomNo);
    if (am.length !== 0) return alert(`Room No. ${roomNo} already exits`);

    try {
      const config = {
        url: "/addRooms",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          roomCategory: category,
          vendorId: vendor?._id,
          roomNumber: roomNo,
          roomType: RoomType,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      // alert(error.response.data.error)
    }
  };

  const updateRooms = async () => {
    if (roomNo) {
      let am = AllRoom.filter((item) => item.roomNumber == roomNo);
      if (am.length !== 0) return alert(`Room No. ${roomNo} already exits`);
    }

    try {
      const config = {
        url: "/UpdateRooms",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          roomCategory: category,
          vendorId: vendor?._id,
          roomNumber: roomNo,
          roomType: RoomType,
          id: edit?._id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully updated");
        handleClose();
        getAllrooms();
        setCategory("");
        setroomNo("");
        setRoomType("");
      }
    } catch (error) {
      console.log(error);
      // alert(error.response.data.error)
    }
  };

  const [deleteId, setDeleteId] = useState("");
  const deleteRoom = async () => {
    try {
      let res = await axios.delete(
        "https://dhanyatours.me/api/user/deleteRooms/" + deleteId
      );
      if (res.status == 200) {
        alert("Successfully deleted");
        handleClose1();
        getAllrooms();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilter = (e) => {
    if (e.target.value != "") {
      const filterTable = nochangedata?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllRoom([...filterTable]);
    } else {
      setnochangedata([...nochangedata]);
    }
  };
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllRoom.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const [blockid, setblockid] = useState("");
  const [stDate, setstDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [stTitme, setstTime] = useState("");
  const [endTime, setendTime] = useState("");

  const [show4, setShow4] = useState(false);
  const handleShow4 = () => setShow4(true);
  const handleClose4 = () => setShow4(false);

  const makeBlockUnbolckRoom = async (isBlock, id) => {
    try {
      if (isBlock == true) {
        if (!stDate | !stTitme)
          return alert("Please Select Start Date And Time");
        if (!endDate | !endTime)
          return alert("Please Select End Date And Time");
      }

      const config = {
        url: "/makeBlockAndUnblockRooms",
        method: "put",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          stDate: stDate,
          endDate: endDate,
          stTime: stTitme,
          isBlock: isBlock,
          endTime: endTime,
          id: id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(res.data.success);
        handleClose4();
        getAllrooms();
        setstDate("");
        setstTime("");
        setendDate("");
        setendTime("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="ms-5 mt-4">
        <div className="conatiner">
          <div className="row">
            <div className="col-md-4">
              <form action="" className="title-90 pb-4">
                <h4
                  className="text-center"
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid darkgray",
                  }}
                >
                  Room Form
                </h4>
                <label htmlFor="" className="pb-2 nd-2">
                  Room Category
                </label>
                <select
                  className="title-90"
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="">----Select Room Category----</option>
                  {AllCategory?.map((item) => {
                    return (
                      <option value={item?.category}>{item?.category}</option>
                    );
                  })}
                </select>
                <label htmlFor="" className="pb-2 nd-2">
                  Room Type
                </label>
                <select
                  className="title-90"
                  onChange={(e) => setRoomType(e.target.value)}
                >
                  <option value="">----Select Room Type----</option>
                  <option value="Ac">Ac</option>
                  <option value="Non-Ac">Non-Ac</option>
                </select>
                <label htmlFor="" className="pb-2 nd-2">
                  Room No.
                </label>
                <input
                  type="number"
                  className="title-90"
                  placeholder="Please enter room number"
                  onChange={(e) => setroomNo(e.target.value)}
                />

                <div
                  className="mt-5 text-center pt-3"
                  style={{ borderTop: "1px solid darkgrey" }}
                >
                  <Button onClick={createRegister}>Save</Button> &nbsp;
                  <Button
                    variant="light"
                    onClick={() => window.location.reload()}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
            <div className="col-md-8">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  textAlign: "center",
                }}
              >
                <div>
                  <div className="searchHeadFlex">
                    <div className="searchWrapper" style={{ display: "flex" }}>
                      <input
                        type="text"
                        maxlength="230"
                        style={{ outlineWidth: "0px", padding: "4px" }}
                        className="textsearch"
                        placeholder="Search by room"
                        onChange={handleFilter}
                      />
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        id="BsSearch"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                          clip-rule="evenodd"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div>
                  <h3>Total Room: {AllRoom?.length}</h3>
                </div>
              </div>

              <Table1 striped bordered hover>
                <thead>
                  <tr>
                    <th>S No.</th>
                    <th>Room Category</th>
                    <th>Room Type</th>
                    <th>Room No.</th>
                    <th>Block Start Date/Time</th>
                    <th>Block Start Date/Time</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {AllRoom?.slice(
                    pagesVisited,
                    pagesVisited + usersPerPage
                  )?.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{item?.roomCategory}</td>
                        <td>{item?.roomType}</td>
                        <td>{item?.roomNumber}</td>
                        <td>{item?.stDate}{" "} {item?.stTime? (moment(item?.stTime, ["HH:mm"]).format("hh:mm a")):""}</td>
                        <td>{item?.endDate}{" "} {item?.endTime ? (moment(item?.endTime, ["HH:mm"]).format("hh:mm a")):""}</td>
                        <td>
                          {item?.isBlock == true ? (
                            <span style={{ color: "red" }}>Blocked</span>
                          ) : (
                            <span style={{ color: "green" }}>Unblocked</span>
                          )}
                        </td>
                        <td>
                          <div style={{ display: "flex", gap: "10px" }}>
                            {item?.isBlock == true ? (
                              <button
                                type="button"
                                class="btn btn-success"
                                onClick={() => {
                                  setstDate("");
                                  setstTime("");
                                  setendDate("");
                                  setendTime("");
                                  makeBlockUnbolckRoom(false, item?._id);
                                }}
                              >
                                UnBlock
                              </button>
                            ) : (
                              <button
                                type="button"
                                class="btn btn-danger"
                                onClick={() => {
                                  setblockid(item);
                                  handleShow4();
                                }}
                              >
                                Block
                              </button>
                            )}
                            <span>
                              <BiSolidEdit
                                style={{
                                  color: "#5600ff",
                                  cursor: "pointer",
                                  fontSize: "26px",
                                }}
                                onClick={() => handleShow(item)}
                              />
                            </span>
                            <span>
                              <AiOutlineDelete
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  fontSize: "26px",
                                }}
                                onClick={() => {
                                  setDeleteId(item?._id);
                                  handleShow1();
                                }}
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table1>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deleteRoom}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Rooms</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="" className="pb-2 nd-2">
            Room Category
          </label>
          <select
            className="title-90"
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value={edit?.roomCategory}>{edit?.roomCategory}</option>
            {AllCategory?.map((item) => {
              return <option value={item?.category}>{item?.category}</option>;
            })}
          </select>
          <label htmlFor="" className="pb-2 nd-2">
            Room Type
          </label>
          <select
            className="title-90"
            onChange={(e) => setRoomType(e.target.value)}
          >
            <option value={edit.roomType}>{edit.roomType}</option>

            <option value="Ac">Ac</option>
            <option value="Non-Ac">Non-Ac</option>
          </select>
          <label htmlFor="" className="pb-2 nd-2">
            Room No.
          </label>
          <input
            type="number"
            className="title-90"
            placeholder={edit.roomNumber}
            onChange={(e) => setroomNo(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={updateRooms}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Block Room */}
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Block Rooms</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="pb-2 nd-2">Room Category</label>
          <input
            type="text"
            className="title-90"
            value={blockid?.roomCategory}
          />
          <div className="row">
            <div className="col-md-6">
              <label className="pb-2 nd-2">Room No.</label>
              <input
                type="text"
                className="title-90"
                value={blockid?.roomNumber}
              />
            </div>
            <div className="col-md-6">
              <label className="pb-2 nd-2">Room Type.</label>
              <input
                type="text"
                className="title-90"
                value={blockid?.roomType}
              />
            </div>
          </div>
          <div className="row">
            <label className="pb-2 nd-2">Start Date And Time</label>
            <div className="col-md-6">
              <input
                type="Date"
                className="title-90"
                name="lodgetiming"
                onChange={(e) => setstDate(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <input
                type="Time"
                className="title-90"
                name="lodgetiming"
                onChange={(e) => setstTime(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <label className="pb-2 nd-2">End Date And Time</label>
            <div className="col-md-6">
              <input
                type="Date"
                className="title-90"
                name="lodgetiming"
                onChange={(e) => setendDate(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <input
                type="Time"
                className="title-90"
                name="lodgetiming"
                onChange={(e) => setendTime(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => makeBlockUnbolckRoom(true, blockid?._id)}
          >
            Block
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Rooms;
