import { green } from "@mui/material/colors";
import axios from "axios";
import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import Enquiry from "./Enquiry";
import Mybooking from "./Mybooking";
import TaxiBooking from "./TaxiBooking";

function SingalTaxi() {
  // const { id } = useParams();

  const [graphImage, setgraphImage] = useState([]);
  console.log(graphImage, "orvih");
  const getGraphicImage = async () => {
    const config = {
      url: "/getAllImage",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setgraphImage(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  useEffect(() => {
    getGraphicImage();
  }, []);
  useEffect(() => {
    getGraphicImage();
  }, []);

  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  };
  const [gettrain, setgettrain] = useState([]);
  console.log(gettrain, "successdfg");
  const getLuxuryTrain = async () => {
    const config = {
      url: "/getAlltrain",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setgettrain(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getLuxuryTrain();
  }, []);

  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [number, setnumber] = useState("");

  const [message, setmessage] = useState("");

  const onCreate = async () => {
    try {
      if (!user) {
        alert("Please enter full name!");
      } else if (!email) {
        alert("Please enter email!");
      } else if (!number) {
        alert("Please enter mobile number!");
      } else {
        const config = {
          url: "/createTrainBook",
          method: "post",
          baseURL: "https://dhanyatours.me/api/user",
          headers: { "conttent-type": "application/json" },
          data: {
            userName: user,
            email: email,
            number: number,
            message: message,
          },
        };
        const result = await axios(config);
        if (result.status === 200) {
          alert("Our team  will contact you soon");
          window.location.assign("/");
        } else {
          alert("Something went wrong");
        }
      }
    } catch (error) {
      console.log("erore", error);
      alert(error.response.data.error);
    }
  };

  return (
    <>
      <OwlCarousel
        className="owl-theme"
        loop
        margin={2}
        nav={false}
        dots={false}
        autoplay={true}
        responsive={responsive}
      >
        {graphImage
          ?.filter((e) => e?.name === "Vehicle")
          ?.map((ele) => {
            return (
              <div class="item">
                <div class="box">
                  <img
                    src={`https://dhanyatours.me/image/${ele?.image}`}
                    alt="Jungle Lodges and Resorts"
                    style={{ width: "100%", height: "300px" }}
                  />
                </div>
              </div>
            );
          })}
      </OwlCarousel>

      <div className="inner-content-listing-desc" style={{ padding: "30px" }}>
        <div className="container">
          {/* <h1 className="wr-0">TAXI HIRE</h1> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="drop-d  ">
                <h5>Airport Transfer</h5>
                <p>
                  <span
                    style={{
                      color: "black",
                      fontWeight: "600",
                      textAlign: "justify",
                    }}
                  >
                    {" "}
                  </span>
                 Whether you travel frequently, for work or
                  pleasure. I understand how important it is to have a reliable
                  transfer from home to the airport, and back again. I will
                  always arrive on-time, get you there safely, and be there
                  waiting for you when you return.
                </p>
                <p>
                  <span
                    style={{
                      color: "black",
                      fontWeight: "600",
                      textAlign: "justify",
                    }}
                  >
                    {" "}
                  </span>
               Book Airport Taxi All our drivers ensure all the
                  passengers are safe when we drop them off. Our drivers don’t
                  leave from the drop off point or destination until passengers
                  enter their respective house or chosen destination.
                </p>
                <p>
                  <span
                    style={{
                      color: "black",
                      fontWeight: "600",
                      textAlign: "justify",
                    }}
                  >
                    {" "}
                  </span>
                   Customers being our priority, we are always
                  happy to help with your luggage too. If you are about to
                  arrive at, you can book our cab service and we guarantee that
                  our driver will be there for you.
                </p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className=" drop-d">
                <h5> Local Drop</h5>

                <p>
                  <span
                    style={{
                      color: "black",
                      fontWeight: "600",
                      textAlign: "justify",
                    }}
                  >
                    {" "}
                  </span>
                   We are Best Cabs services in Bangalore. Dhanya Tours
                  and Travels provides the most reliable, affordable and
                  cheapest taxi services in and around Bangalore.
                </p>
                <p>
                  <span
                    style={{
                      color: "black",
                      fontWeight: "600",
                      textAlign: "justify",
                    }}
                  >
                    {" "}
                  </span>
                 Living in Garden City ? Don’t know how do you get
                  around the city? The best way is travelling by a cab. Looking
                  for a cab for your everyday travel needs? If the answer is
                  yes, Dhanya Tours and Travels is your best choice around
                  Bangalore city more comfortable, safer, and faster. local cab
                  service to Bangalore.
                </p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className=" drop-d">
                <h5> Outstation</h5>

                <p>
                  <span
                    style={{
                      color: "black",
                      fontWeight: "600",
                      textAlign: "justify",
                    }}
                  >
                    {" "}
                  </span>
                  When we plan to book outstation cabs online we consider
                  a lot of thing, which outstation taxi service offers the best
                  in class experience at lowest fares, on-time pickup, no last
                  minute cancellation, professional drivers' behaviours..
                </p>
                <p>
                  <span
                    style={{
                      color: "black",
                      fontWeight: "600",
                      textAlign: "justify",
                    }}
                  >
                    {" "}
                  </span>
                 Quick Dhanya Tours & Travels fares are transparent, and
                  5-10% lower for outstation travels. No Hidden Charges. No
                  Extra Fares. No Additional Charges for Pre-booking. Book your
                  Lowest Fare Outstation Taxi now.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9">
              {/* <div className="">
                <h4 className="over-taxi">
                  For immediate booking please call +91-8884467467
                </h4>
              </div> */}
              <TaxiBooking />
            </div>
            <div className="col-md-3">
              <div>
                <div className="r-lg-0">
                  <div
                    style={{
                      boxShadow: " 0px 14px 33px -9px rgba(0, 0, 0, 0.15)",
                      padding: "15px",
                      backgroundColor: "#004aad",
                      marginTop: "20px",
                      border: "1px solid #004aad",
                      borderRadius: "5px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "600",
                        color: "white",
                        fontSize: "14px",
                      }}
                    >
                      Want to book a trip or inquiry?
                    </span>
                    <form>
                      <div className="form-group pt-2">
                        <input
                          type="text"
                          class="form-control"
                          name="Last Name"
                          value={user}
                          onChange={(e) => setUser(e.target.value)}
                          placeholder="Full Name*"
                          required=""
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          class="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email Id*"
                          required=""
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          class="form-control"
                          name="mobileno"
                          value={number}
                          onChange={(e) => setnumber(e.target.value)}
                          placeholder="Mobile No*"
                        />
                      </div>
                      {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Select Package <span class="text-danger">*</span>
                          </label>
              <div className="col-md-12">
              <div class="form-group">
              <Form.Select onChange={(e)=>{
                           setpackageName(e.target.value)
                           setData(JSON.parse(e.target.value))
                          }}>
                            <option>Select package</option>
                            {priceing?.map((item) => {
                              return (
                                <option value={JSON.stringify(item)}>
                                  {item?.packageName}
                                </option>
                              );
                            })}
                          </Form.Select>
            </div>
              </div>
           
             
               </div> */}
                      {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Check In Date/Time <span class="text-danger">*</span>
                          </label>
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="date"
                class="form-control"
                name="Description"
                value={checkinDate}
                onChange={(e)=>setcheckinDate(e.target.value)}
             
              
              />
            </div>
              </div>
           
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="time"
                class="form-control"
               value={checkinTime}
               onChange={(e)=>setcheckinTime(e.target.value)}              
           
              />
            </div>
              </div>
               </div> */}
                      {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Check Out Date/Time <span class="text-danger">*</span>
                          </label>
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="date"
                class="form-control"
                          value={checkOutDate}
                          onChange={(e)=>setcheckOutDate(e.target.value)}
              />
            </div>
              </div>
           
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="time"
                class="form-control"
               value={checkOutTime}
               onChange={(e)=>setcheckOutTime(e.target.value)}
              />
            </div>
              </div>
               </div> */}
                      {/* <div className="row">
               <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Number Of Guest <span class="text-danger">*</span>
                          </label>
               <div className="col-md-6">
               <div class="form-group">
              <input
                type="number"
                class="form-control"
              value={numberOfguest}
              onChange={(e)=>setnumberOfguest(e.target.value)}
                placeholder="adults"
              />
            </div>
               </div>
               <div className="col-md-6">
               <div class="form-group">
              <input
                type="number"
                class="form-control"
              value={NumberOfChild}
              onChange={(e)=>setNumberOfchild(e.target.value)}
                placeholder="childs"
              />
            </div>
               </div>
               </div> */}

                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          value={message}
                          onChange={(e) => setmessage(e.target.value)}
                          placeholder="Message"
                        />
                      </div>

                      <div class="row" style={{ marginTop: "10px" }}>
                        <div class="col-sm-6">
                          <botton
                            class="btn btn-primary btn-sm hidden-xs"
                            onClick={onCreate}
                          >
                            Inquiry Now
                          </botton>
                          {/* <input
                  class="btn btn-primary btn-sm hidden-xs"
                  type="submit"
                  value="Book Now"
                /> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingalTaxi;
