import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  AiFillDelete,
  AiOutlineDelete,
  AiOutlineDownload,
} from "react-icons/ai";
import { FaEdit, FaEye } from "react-icons/fa";
import Table1 from "react-bootstrap/Table";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useLocation } from "react-router-dom";
import moment from "moment";

function InquireTrain() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [deleteId, setDeleteId] = useState("");
  // const location = useLocation;

  const [booktaxi, setbooktaxi] = useState([]);
  const [tableFilter, settableFilter] = useState([]);
  const getBooking = async () => {
    const config = {
      url: "/getallTrainBookOrder",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        settableFilter(result.data.success?.filter((ele)=> ele.isLuxury==true));
        setbooktaxi(result.data.success?.filter((ele)=> ele.isLuxury==true));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDelete = async () => {
    const config = {
      url: "/deleteTrainOrder/" + deleteId,
      method: "delete",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        getBooking();
        handleClose1();
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

 
  useEffect(() => {
    getBooking();
  }, []);

  const [bookingtaxi, setbookingtaxi] = useState({});

  const makeConfirm = async (id) => {
    try {
      const config = {
        url: "/makeOderStatusChangeTrainBook",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          orderId: id,
          status: "Confirm",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getBooking();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const makeCancel = async (id) => {
    try {
      const config = {
        url: "/makeOderStatusChangeTrainBook",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          orderId: id,
          status: "Cancel",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getBooking();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const makeComplete = async (id) => {
    try {
      const config = {
        url: "/makeOderStatusChangeTrainBook",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-type": "application/json" },
        data: {
          orderId: id,
          status: "Complete",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getBooking();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  //getVehicleById
  const createPDF = async () => {
    const input = document.getElementById("pdf");
    const options = { scrollY: -window.scrollY };
    const canvas = await html2canvas(input, options);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    pdf.save("Inquire.pdf");
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(booktaxi?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [search, setSearch] = useState("");

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = tableFilter?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setbooktaxi([...filterTable]);
    } else {
      setSearch(e.target.value);
      setbooktaxi([...tableFilter]);
    }
  };

  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
const searchDate=()=>{
  if(!fromd) return alert("Please select from date");
  if(!tod) return alert("Please select to date");

 let std=fromd?.split("-");
      let entd=tod?.split("-")
      let startD=parseInt(std?.join(""));
      let endD=parseInt(entd?.join(""))
      let abc=tableFilter?.filter((ele)=>{
        // console.log("amit check it",moment(ele?.createdAt).format("YYYY-MM-DD").split("-"))
        let abd=moment(ele?.createdAt).format("YYYY-MM-DD").split("-")
        let amd=parseInt(abd?.join(""));
        return startD<=amd && endD>=amd
      })
      setbooktaxi(abc);
      // setdata(abc)
      // setfileName(`AcountHistory_${moment(fromd).format("DD/MM/YYYY")} to ${moment(tod).format("DD/MM/YYYY")}`)
}
  return (
    <>
      <div className="de-lod">
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h2 style={{ fontSize: "25px" }}>Inquiry Luxury Train Details</h2>
          </div>
        </div>

        <Modal show={show} onHide={handleClose} id="pdf">
          <Modal.Header
            closeButton
            style={{
              backgroundColor: "#2fdbeb",
              color: "white",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            Inquiry luxury Train Details
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <form>
                <label className="pb-2 nd-2">BookId{" :"}</label>{" "}
                <span>{bookingtaxi?._id}</span>
                <br />
                <label className="pb-2 nd-2">Name {" :"}</label>{" "}
                <span>{bookingtaxi?.userName}</span> <br />
                <label className="pb-2 nd-2">Number{" :"}</label>{" "}
                <span> {bookingtaxi?.number}</span>
                <br />
                <label className="pb-2 nd-2">Email{" :"}</label>{" "}
                <span>{bookingtaxi?.email}</span>
                <br />
                <label className="pb-2 nd-2">Message{" :"}</label>{" "}
                {bookingtaxi?.message}
                <br />
                <br />
                <TableContainer>
                  <Table variant="striped" colorScheme="teal">
                    <TableCaption></TableCaption>

                    <Tbody>
                      <Tr>
                        <Td>Status</Td>
                        <Td>{bookingtaxi?.status}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <AiOutlineDownload
              style={{
                color: "red",
                cursor: "pointer",
                fontSize: "36px",
                float: "right",
              }}
              onClick={() => createPDF()}
            />
            {bookingtaxi?.status === "Complete" ? (
              <></>
            ) : (
              <>
                {bookingtaxi?.status === "Confirm" ? (
                  <>
                    <Button
                      variant="danger"
                      onClick={() => {
                        makeCancel(bookingtaxi?._id);
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => {
                        makeComplete(bookingtaxi?._id);
                        handleClose();
                      }}
                    >
                      Complete
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="warning"
                    onClick={() => {
                      makeConfirm(bookingtaxi?._id);
                      handleClose();
                    }}
                  >
                    Confirm
                  </Button>
                )}
              </>
            )}

            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete?</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <Button variant="primary" onClick={getDelete}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="taxi-table">
           <div className="row">
              <div className="col-md-4">
                <div className="searchHeadFlex">
              <div className="searchWrapper" style={{ display: "flex" }}>
                <input
                  type="text"
                  maxlength="230"
                  style={{ outlineWidth: "0px", padding: "4px" }}
                  className="textsearch"
                  placeholder="Search by ..."
                  onChange={handleFilter}
                />
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  id="BsSearch"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                    clip-rule="evenodd"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
           
              </div>
              <div className="col-md-2">
              <label htmlFor="">From</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => setfromd(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="">To</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => settod(e.target.value)}
              />
            </div>
            <div className="col-md-2">
         
              <button onClick={searchDate}
                style={{
                  borderRadius: "3px",
                  color: "white",
                  fontSize: "18px",
                  border: "1px solid",
                  backgroundColor: "#004aad",
                  padding: "5px",
                  marginTop: "26px",
                  borderRadius: "10px",
                  fontWeight: 500,
                }}
              >
                Search
              </button>
            </div>
            </div>
          <Table1 striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>User Name</th>
                <th>Number</th>
                <th>Email</th>
                <th>Message</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
         
                  {booktaxi
                    ?.slice(pagesVisited, pagesVisited + usersPerPage)
                    ?.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.userName}</td>
                          <td>{item?.number}</td>
                          <td>{item?.email}</td>
                          <th>{item?.message}</th>
                          <td>{item?.status}</td>
                          {item?.status === "Pending" ? (
                            <td>
                              <div style={{ display: "flex", gap: "10px" }}>
                                <FaEye
                                  style={{
                                    fontSize: "26px",
                                    color: " #5600ff",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleShow();
                                    setbookingtaxi(item);
                                  }}
                                />
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  onClick={() => makeConfirm(item?._id)}
                                >
                                  Confirm
                                </button>{" "}
                                <button
                                  type="button"
                                  class="btn btn-danger"
                                  onClick={() => makeCancel(item?._id)}
                                >
                                  Cancel
                                </button>
                              </div>
                            </td>
                          ) : (
                            <td>
                              <div style={{ display: "flex", gap: "10px" }}>
                                <span>
                                  <FaEye
                                    style={{
                                      color: "#5600ff",
                                      cursor: "pointer",
                                      fontSize: "26px",
                                    }}
                                    onClick={() => {
                                      handleShow();
                                      setbookingtaxi(item);
                                    }}
                                  />
                                </span>
                                <span>
                                  <AiOutlineDelete
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                      fontSize: "26px",
                                    }}
                                    onClick={() => {
                                      setDeleteId(item?._id);
                                      handleShow1();
                                    }}
                                  />
                                </span>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
             
            </tbody>
          </Table1>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </div>
    </>
  );
}

export default InquireTrain;
