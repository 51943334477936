import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Wildlife() {
  return (
    <>
      <div className="wild-li" style={{ backgroundColor: "#d3d3d37a" }}>
        <div
          className=""
          style={{
            backgroundImage: "url('../Image/hs2bg.jpg')",
            height: "310px",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="wild-0">
          <div className="container">
            <div className="wild-life-0">
              <div className="lion">
                <p style={{ fontSize: "40px", fontWeight: "400" }}>Wildlife</p>
                <div
                  style={{
                    float: "right",
                    width: "100%",
                  }}
                >
                  <hr style={{ color: "red", opacity: "3" }} />
                </div>
              </div>
              <div className="rhlep">
                <Link to="/wildlife-singal">
                  <div className="rhlepd">
                    <div className="wild-img">
                      <img
                        src="../Image/hotel2.jpg"
                        alt=""
                        style={{ width: "275px", height: "259px" }}
                      />
                    </div>
                    <div className="wild-img-content">
                      <p style={{ fontSize: "25px" }}>Kabini River Lodge</p>
                      <p>
                        Named after the river Kabini, the lodge beckons with the
                        promise of elephants, gaurs, deer and flaunts the
                        mesmerizing tiger.
                      </p>
                      {/* <div className="Activities-o">
                        <p>Activities</p>
                        <ul className="face-act">
                          <li className="rehped-0">
                            <span>
                              <img
                                src="../act1.png"
                                alt=""
                                style={{ width: "30px", height: "20px" }}
                              />
                            </span>
                            Safari
                          </li>
                          <li className="rehped-0">
                            <span>
                              <img
                                src="../act1.png"
                                alt=""
                                style={{ width: "30px", height: "20px" }}
                              />
                            </span>
                            Camp fire
                          </li>
                          <li className="rehped-0">
                            <span>
                              <img
                                src="../act1.png"
                                alt=""
                                style={{ width: "30px", height: "20px" }}
                              />
                            </span>
                            Nature walk
                          </li>
                          <li className="rehped-0">
                            <span>
                              <img
                                src="../act1.png"
                                alt=""
                                style={{ width: "30px", height: "20px" }}
                              />
                            </span>
                            Coracle boat dirve
                          </li>
                          <li className="rehped-0">
                            <span>
                              <img
                                src="../act1.png"
                                alt=""
                                style={{ width: "30px", height: "20px" }}
                              />
                            </span>
                            Low rope courses
                          </li>
                          <li className="rehped-0">
                            <span>
                              <img
                                src="../act1.png"
                                alt=""
                                style={{ width: "30px", height: "20px" }}
                              />
                            </span>
                            All meals{" "}
                          </li>
                          <li className="rehped-0">
                            <span>
                              <img
                                src="../act1.png"
                                alt=""
                                style={{ width: "30px", height: "20px" }}
                              />
                            </span>
                            Hi Tea
                          </li>
                          <li className="rehped-0">
                            <span>
                              <img
                                src="../act1.png"
                                alt=""
                                style={{ width: "30px", height: "20px" }}
                              />
                            </span>
                            Out door games
                          </li>
                          <li className="rehped-0">
                            <span>
                              <img
                                src="../act1.png"
                                alt=""
                                style={{ width: "30px", height: "20px" }}
                              />
                            </span>
                            Indoor Games
                          </li>
                          <li className="rehped-0">
                            <span>
                              <img
                                src="../act1.png"
                                alt=""
                                style={{ width: "30px", height: "20px" }}
                              />
                            </span>
                            Ayurveda treatment
                          </li>
                        </ul>
                        <div className="active-b">
                          <Button>View details</Button>
                        </div>
                      </div> */}
                      <div
                        className="active-b"
                        style={{ position: "absolute", bottom: "15px" }}
                      >
                        <Button>View details</Button>
                      </div>
                    </div>
                    <div className="price-wild text-end">
                      <p>Price Starts from</p>
                      <div className="price-0">
                        ₹ 8,776 (all inclusive including Safari).
                      </div>
                      <span>Per Person on twin sharing</span>
                      <div className="active-b">
                        <Button>Book Now</Button>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Wildlife;
