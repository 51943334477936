import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Form, Table } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
function SingalLuxuryTrain() {
  const [luxury, setluxury] = useState([]);
  const [includes, setincludes] = useState([]);
  const [excludes, setexcludes] = useState([]);
  const [priceing, setpriceing] = useState([]);

  const getluxuryImage = async () => {
    const config = {
      url: "/getAllImage",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setluxury(result.data.success);

        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  const { id } = useParams();

  const [singlluxury, setSingle] = useState({});
  const [gst, setgst] = useState({});
  const getGst = async () => {
    const config = {
      url: "/getGst",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setgst(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  const getSingalLuxuries = async () => {
    try {
      const result = await axios.get(
        `https://dhanyatours.me/api/user/getbytrainId/${id}`
      );
      if (result.status === 200) {
        setSingle(result.data.success);
        setincludes(result.data.success?.Includes);
        setexcludes(result.data.success?.Excludes);
        setpriceing(result.data.success?.PRICING);
        getGst();
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getluxuryImage();
    getSingalLuxuries();
    getGst();
  }, []);
  // console.log(singlluxury, "aoe");

  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  };

  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [number, setnumber] = useState("");

  const [message, setmessage] = useState("");

  const onCreate = async () => {
    try {
      if (!user) {
        alert("Please enter full name!");
      } else if (!email) {
        alert("Please enter email!");
      } else if (!number) {
        alert("Please enter mobile number!");
      } else {
        const config = {
          url: "/createTrainBook",
          method: "post",
          baseURL: "https://dhanyatours.me/api/user",
          headers: { "conttent-type": "application/json" },
          data: {
            userName: user,
            email: email,
            number: number,
            message: message,
            isLuxury:true,
          },
        };
        const result = await axios(config);
        if (result.status === 200) {
          alert("Our team  will contact you soon");
          window.location.assign("/");
        } else {
          alert("Something went wrong");
        }
      }
    } catch (error) {
      console.log("erore", error);
      alert(error.response.data.error);
    }
  };

  return (
    <>
      <OwlCarousel
        className="owl-theme"
        loop
        margin={0}
        nav={false}
        dots={false}
        autoplay={true}
        responsive={responsive}
      >
        {singlluxury?.trainImage?.map((element) => {
          return (
            <div class="item">
              <div class="box">
                <img
                  src={`https://dhanyatours.me/train/${element?.image}`}
                  alt="Jungle Lodges and Resorts"
                  style={{ width: "100%", height: "300px" }}
                />
              </div>
            </div>
          );
        })}
      </OwlCarousel>

      <div className="inner-content-listing-desc" style={{ padding: "30px" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <h1 className="wr-0">{singlluxury.category}</h1>
              <p className="listing-wro">
                <span class="posted_by">
                  <i
                    class="icon-price-tag"
                    style={{ fontSize: "14px", color: "#66CC99" }}
                  ></i>{" "}
                  Starts from ₹ {singlluxury.StartingPrice}
                  /-&nbsp;&nbsp;&nbsp;&nbsp;
                  <i
                    class="icon-calendar"
                    style={{ fontSize: "14px", color: "#66CC99" }}
                  ></i>{" "}
                  {singlluxury.trainTiming} &nbsp;&nbsp;&nbsp;&nbsp;
                  <i
                    class="icon-location-pin"
                    style={{ fontSize: "14px", color: "#66CC99" }}
                  ></i>{" "}
                  {singlluxury.location}
                </span>
              </p>
              <h4 className="over-taxi">OVERVIEW</h4>
              <p>
                <span style={{ color: "black", fontWeight: "600" }}>
                  {singlluxury.OVERVIEW ? (parse(singlluxury.OVERVIEW )):""}
                </span>
                {/* &nbsp; {Loadeges?.OVERVIEW} */}
              </p>
              <div className="" style={{ overflowX: "auto" }}>
                <h4 className="over-taxi">PRICING</h4>
                {/* <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                  Jewels of South - Package Tariff for 2022-23
                </h4> */}
         
              </div>
              <div className="" style={{ overflowX: "auto" }}>
                <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                  Pride of {singlluxury?.location} - {singlluxury?.to}
                </h4>
                {/* table */}
                <Table
                  class="table table-responsive table-bordered table-hover"
                  striped
                  bordered
                  hover
                  size="sm"
                >
                  <tbody>
                    <tr>
                      <td width="135" valign="top">
                        <strong>&nbsp;</strong>
                      </td>
                      <td>
                        &nbsp;
                        <strong> In INR/person on twin sharing basis</strong>
                      </td>

                      <td>
                        <strong>In USD/person on twin sharing basis</strong>
                      </td>
                    </tr>
                   
                    {priceing?.map((element) => {
                      return (
                        <tr>
                          <td valign="top">{element.packageName}</td>
                          <td valign="top">₹ {element.singleWeekPrice}</td>
                          {/* <td valign="top">₹ {element.doublyWeekendPrice}/-</td> */}
                          <td valign="top">$ {element.doublyWeekPrice}</td>
                          {/* <td valign="top">$ {element.singleWeekendPrice}</td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              {/* DEPARTURE CALENDAR */}

              <div className="mt-3 mb-3">
                <h4 className="over-taxi">DEPARTURE CALENDAR</h4>
           
                  <div className="">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th >Months</th>
                          <th >Date</th>
                          <th>Day</th>
                          <th >Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {singlluxury?.CalenderData?.filter((ele)=>ele?.ClStatus==false)?.map((item)=>{
                          return<tr>
                          <td>{item?.monthD}</td>
                          <td>{item?.date}</td>
                          <td>{item?.day}</td>
                          <td>{item?.time}</td>
                        </tr>
                        })}
                        
                      
                      </tbody>
                    </Table>
                  </div>
                 
               
               
              </div>

              {/* ======================= */}
              {/* Itenery */}
              <h4 className="over-taxi">ITINERARY</h4>
              <div className="according">
                <Accordion defaultActiveKey="1">
                  {singlluxury?.ITINERARY?.map((item,i)=>{
                    return (
                         <Accordion.Item eventKey={i+1}>
                    <Accordion.Header>
                      {" "}
                      <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                        Day {i+1}: {item?.dayName}
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                    {item?.details ? (parse(item?.details)):""}
                    </Accordion.Body>
                  </Accordion.Item>
                    )
                  })}
               
              
                </Accordion>
              </div>
              {/* ==== */}
              {/* encludes */}
              <div className="">
                <h4 className="over-taxi">INCLUDES &amp; EXCLUDES</h4>
                <p>
                  <strong>Includes:</strong>
                </p>
                <ul style={{ listStyle: "unset" }}>
                  {includes?.map((eitem) => {
                    return <li>{eitem?.text}</li>;
                  })}
                </ul>
                <p>
                  <strong>Excludes:</strong>
                </p>

                <ul style={{ listStyle: "unset" }}>
                  {excludes.map((itemw) => {
                    return <li>{itemw.text1}</li>;
                  })}
                </ul>
              </div>
              <div className="according">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {" "}
                      <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                        Suggestion
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                    {singlluxury.suggetion ? (parse(singlluxury.suggetion )):""}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {" "}
                      <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                        TERMS &amp; CONDITIONS
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                    {singlluxury.termAndCondition ? (parse(singlluxury.termAndCondition )):""}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

            <div className="col-md-3">
              <div className="r-lg-0">
                <div
                  style={{
                    boxShadow: " 0px 14px 33px -9px rgba(0, 0, 0, 0.15)",
                    padding: "15px",
                    backgroundColor: "#004aad",
                    marginTop: "20px",
                    border: "1px solid #004aad",
                    borderRadius: "5px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    Want to book a trip or inquiry?
                  </span>
                  <form>
                    <div className="form-group pt-2">
                      <input
                        type="text"
                        class="form-control"
                        name="Last Name"
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                        placeholder="Full Name*"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        class="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email Id*"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        class="form-control"
                        name="mobileno"
                        value={number}
                        onChange={(e) => setnumber(e.target.value)}
                        placeholder="Mobile No*"
                      />
                    </div>
                    {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Select Package <span class="text-danger">*</span>
                          </label>
              <div className="col-md-12">
              <div class="form-group">
              <Form.Select onChange={(e)=>{
                           setpackageName(e.target.value)
                           setData(JSON.parse(e.target.value))
                          }}>
                            <option>Select package</option>
                            {priceing?.map((item) => {
                              return (
                                <option value={JSON.stringify(item)}>
                                  {item?.packageName}
                                </option>
                              );
                            })}
                          </Form.Select>
            </div>
              </div>
           
             
               </div> */}
                    {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Check In Date/Time <span class="text-danger">*</span>
                          </label>
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="date"
                class="form-control"
                name="Description"
                value={checkinDate}
                onChange={(e)=>setcheckinDate(e.target.value)}
             
              
              />
            </div>
              </div>
           
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="time"
                class="form-control"
               value={checkinTime}
               onChange={(e)=>setcheckinTime(e.target.value)}              
           
              />
            </div>
              </div>
               </div> */}
                    {/* <div className="row">
            <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Check Out Date/Time <span class="text-danger">*</span>
                          </label>
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="date"
                class="form-control"
                          value={checkOutDate}
                          onChange={(e)=>setcheckOutDate(e.target.value)}
              />
            </div>
              </div>
           
              <div className="col-md-6">
              <div class="form-group">
              <input
                type="time"
                class="form-control"
               value={checkOutTime}
               onChange={(e)=>setcheckOutTime(e.target.value)}
              />
            </div>
              </div>
               </div> */}
                    {/* <div className="row">
               <label style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>
                            Number Of Guest <span class="text-danger">*</span>
                          </label>
               <div className="col-md-6">
               <div class="form-group">
              <input
                type="number"
                class="form-control"
              value={numberOfguest}
              onChange={(e)=>setnumberOfguest(e.target.value)}
                placeholder="adults"
              />
            </div>
               </div>
               <div className="col-md-6">
               <div class="form-group">
              <input
                type="number"
                class="form-control"
              value={NumberOfChild}
              onChange={(e)=>setNumberOfchild(e.target.value)}
                placeholder="childs"
              />
            </div>
               </div>
               </div> */}

                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        value={message}
                        onChange={(e) => setmessage(e.target.value)}
                        placeholder="Message"
                      />
                    </div>

                    <div class="row" style={{ marginTop: "10px" }}>
                      <div class="col-sm-6">
                        <botton
                          class="btn btn-primary btn-sm hidden-xs"
                          onClick={onCreate}
                        >
                          Inquiry Now
                        </botton>
                        {/* <input
                  class="btn btn-primary btn-sm hidden-xs"
                  type="submit"
                  value="Book Now"
                /> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingalLuxuryTrain;
