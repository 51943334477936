import React, { useEffect, useState } from "react";
import AOS from "aos";
import { RiCustomerServiceLine } from "react-icons/ri";
import { AiOutlineHome } from "react-icons/ai";
import { TbBrandBooking } from "react-icons/tb";
import axios from "axios";
import { IoIosArrowUp } from "react-icons/io";
import OwlCarousel from "react-owl-carousel";
import { Button } from "react-bootstrap";
import { IoLocationOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
function Home() {
  useEffect(() => {
    AOS.init();
  }, []);
  const history = useNavigate();

  const [loadeimg, setloadeimg] = useState([]);

  const lodgesImag = async () => {
    const config = {
      url: "/getAllLodge",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setloadeimg(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  const [AllCity, setAllcity] = useState([]);
  const getCity = async () => {
    try {
      const config = {
        url: "/GetCity",
        method: "get",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setAllcity(res.data.success);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [Hotels, setLoadgess] = useState([]);
  const getHotel = async () => {
    const config = {
      url: "/getAllHotelForUser",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setLoadgess(result.data.success);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  useEffect(() => {
    lodgesImag();
    getHotel();
    getCity();
  }, []);

  const [item, setitem] = useState([]);

  const getHome = async () => {
    const config = {
      url: "/getAllImages",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setitem(result.data.success);
        lodgesImag();
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  const [category, setcategory] = useState([]);
  const getAllCategory = async () => {
    const config = {
      url: "/getAllCategory",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setcategory(result.data.success);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  const [gettrain, setgettrain] = useState([]);

  const getLuxuryTrain = async () => {
    const config = {
      url: "/getAlltrain",
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setgettrain(result.data.success);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getHome();
    getLuxuryTrain();
  }, []);

  const [distanation, setdistanstion] = useState("");
  const [id, setid] = useState("");
  const [date, setdate] = useState("");
  const searchLodge = () => {
    if (!id) return alert("Please  select your category");
    if (id == "Jungle Lodge") {
      return history(`/jungal-lodges`, {
        state: { distanation: distanation, date: date },
      });
    }
    if (id == "Hotel") {
      return history(`/hotels`, {
        state: { distanation: distanation, date: date },
      });
    }
    if (id == "Resort") {
      return history(`/resorts`, {
        state: { distanation: distanation, date: date },
      });
    }
    if (id == "Home Stay") {
      return history(`/home-stay`, {
        state: { distanation: distanation, date: date },
      });
    }
    if (id == "Luxury Trains") {
      return history(`/luxury-train`, {
        state: { distanation: distanation, date: date },
      });
    }
  };

  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <>
      <div className="video" style={{ marginTop: "-10px" }}>
        <div className="video-travel">
          <video
            src={`https://dhanyatours.me/home/${item[0]?.video}`}
            muted
            autoPlay
            loop
            type="video/mp4"
            className="vohrg"
          ></video>
        </div>
        <div className=" container">
          <div className="pacakege">
            <div className=" dhyana" style={{ color: "white" }}>
              <div className="travel-pacakeg">OUR PACKAGES</div>
              <div className="search-dec">
                <div className="destination">
                  <div className="diff-s">
                    <label>Select Category</label> <br />
                    <select
                      onChange={(e) => setid(e.target.value)}
                      style={{
                        height: "44px",
                        border: "1px solid #dee2e6",
                        outlineWidth: " 0px",
                        padding: "11px",
                        width: "290px",
                        borderRadius: "5px",
                      }}
                    >
                      <option>Select</option>

                      <option value={"Jungle Lodge"}>Jungle Lodge</option>
                      <option value={"Hotel"}>Hotel</option>
                      <option value={"Luxury Trains"}>Luxury Trains</option>
                      <option value={"Resort"}>Resort</option>
                      <option value={"Home Stay"}>Home Stay</option>
                    </select>
                  </div>
                  <div className="diff-s">
                    <label>Search Destination</label>
                    <br />
                    <select
                      onChange={(e) => setdistanstion(e.target.value)}
                      style={{
                        height: "44px",
                        border: "1px solid #dee2e6",
                        outlineWidth: " 0px",
                        padding: "11px",
                        width: "290px",
                        borderRadius: "5px",
                      }}
                    >
                      <option>Select</option>
                      {AllCity?.map((ele) => {
                        return (
                          <option value={`${ele?.City} ${ele?.State}`}>
                            {ele?.City},{ele?.State}
                          </option>
                        );
                      })}
                    </select>
                    {/* <input type="text" onChange={(e)=>setdistanstion(e.target.value)} placeholder="Search Destination....." /> */}
                  </div>

                  <div className="diff-ss" style={{ marginTop: "23px" }}>
                    <Button onClick={searchLodge}>Search</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="footer-wrap"
        style={{
          position: "relative",
        }}
      >
        <span
          style={{
            position: "fixed",
            right: "2%",
            bottom: "90px",
            background: "rgb(250 50 48)",
            padding: "9px",
            cursor: "pointer",
            zIndex: "999",
          }}
          onClick={scrollToTop}
        >
          <IoIosArrowUp />
        </span>
      </div>
      <div className="home-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="travel">
                <h1>Travel More To Discover Yourself</h1>
                <div className="travel-p">
                  <p style={{ textAlign: "justify" }}>
                    &nbsp; &nbsp; Everybody's got a story to tell in this world;
                    not everyone takes the time to listen. At first, the more we
                    see and do, the more we want to share our adventures with
                    others and impress. Then, we realize that every individual
                    has experiences that are not ours— none of them any less
                    important than our own. This is the right time to explore on
                    your own.
                  </p>
                  <div className="b-travel">
                    <a class="button button-hero mt-4" href="/taxi-details">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 ">
              <div
                className="travel-img"
                data-aos="zoom-in"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <img
                  class="img-fluid-0"
                  src="../public/carbaba.png"
                  alt="car-image"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ======= */}
      <div className="pick mt-30">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div
                className="book mt-2 mb-2"
                data-aos="zoom-in"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <div className="icon-0">
                  <RiCustomerServiceLine style={{ fontSize: "50px" }} />
                </div>

                <div className="book-co mt-2">
                  <h5>24/7 Customer Care</h5>
                  <p>
                    Our dedicated customer support team will always help you to
                    solve any of your queries.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="book mt-2 mb-2"
                data-aos="zoom-in"
                data-aos-easing="linear"
                data-aos-duration="1000"
              >
                <div className="icon-0">
                  <AiOutlineHome style={{ fontSize: "50px" }} />
                </div>
                <div className="book-co mt-2">
                  <h5>Home Pickups</h5>
                  <p>
                    We can pickup you directly at your doorstep to save your
                    time and convenient way to reach your destination
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="book mt-2 mb-2"
                data-aos="zoom-in"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <div className="icon-0">
                  <TbBrandBooking style={{ fontSize: "50px" }} />
                </div>
                <div className="book-co mt-2">
                  <h5>Easy Bookings</h5>
                  <p>
                    Booking a local taxi with us is very easy, from anywhere, at
                    any time by web or phone & we are also available Online Chat
                    24/7.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ============ */}

      {item?.map((data) => {
        return (
          <div
            className="out-00"
            style={{
              backgroundImage: `url("https://dhanyatours.me/home/${data?.image1}")`,
              // "url(" + "https://dhanyatours.me/home/" + data?.image1 + ")",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundAttachment: "fixed",
              height: "600px",
            }}
          >
            {/* <img src={"https://dhanyatours.me/home/" + item[0]?.image1}></img> */}
            <div className="container">
              <div className="row">
                <div className="col-md-4 hinfobxout">
                  <div
                    class="hinfobx"
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                  >
                    <h2 style={{ fontSize: "30px", color: "white" }}>
                      {" "}
                      Jungle Lodge{" "}
                    </h2>
                    <p style={{ textAlign: "justify",wordSpacing:"-1px", fontSize:"17.2px",fontFamily:"fangsong" }}>
                    Jungle lodges are situated in remote or secluded locations, often in the midst of pristine natural environments. They provide an opportunity for guests to explore and appreciate the local flora and fauna.
                    </p>
                    <div className="text-center">
                      <Button onClick={() => history("/our-best-packages")}>
                        {" "}
                        Explore more
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {/* Jungal Loadge */}
      <div className="popular" style={{ backgroundColor: "#f8f8ff" }}>
        <div className="" style={{ paddingTop: "15px" }}>
          <div class="section-intro ">
            <h2 style={{ fontSize: "30px", fontWeight: "900" }}>
              Jungle lodges
            </h2>
            <p style={{ fontSize: "15px", fontWeight: "500" }}>
              <a href="/jungal-lodges">View All</a>
            </p>
          </div>
          <div className="ftco-section">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              nav={false}
              dots={false}
              autoplay={true}
              responsive={responsive}
            >
              {loadeimg
                ?.filter((data) => data?.isBloc === false)
                ?.map((items) => {
                  return (
                    <div class="item">
                      <div
                        class="destination desti"
                        style={{
                          display: "block",
                          boxShadow:
                            " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                          margin: " 10px",
                          height: " 415px",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={`https://dhanyatours.me/lodge/${items?.lodgeProfile}`}
                          alt="lkigft"
                          style={{ height: "200px" }}
                        />
                        <div class="text p-3">
                          <div class="d-flex">
                            <div class="one">
                              <h5>
                                <a>{items?.category}</a>
                              </h5>
                            </div>
                            <div class="two">
                              <span class="price">₹{items?.StartingPrice}</span>
                            </div>
                          </div>
                          <p>
                            {/* {item?.lodgeName?.slice(0, 30) <
                            item?.lodgeName?.slice(0, 31) ? (
                              <>{item?.lodgeName?.slice(0, 30)}...</>
                            ) : (
                              <>{item?.lodgeName?.slice(0, 30)}</>
                            )}{" "} */}
                            {items?.lodgeName
                              ? items?.lodgeName?.slice(0, 30)
                              : ""}
                          </p>
                          <p
                            class="days"
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <img
                                src="../clock.png"
                                alt=""
                                style={{ width: "16px" }}
                              />
                            </span>
                            <span>{items?.lodgeTiming}</span>
                          </p>
                          <hr />
                          <p class="bottom-area d-flex">
                            <span>
                              <IoLocationOutline style={{ fontSize: "15px" }} />{" "}
                              {items?.location?.slice(0, 70)}
                            </span>
                            <span class="ml-auto">
                              <a href={`/wildlife-singal/${items._id}`}>View</a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </OwlCarousel>
          </div>
        </div>
      </div>
      {/* ============== */}

      {/*  Hotel */}
      <div className="popular" style={{ backgroundColor: "#f8f8ff" }}>
        <div className="" style={{ paddingTop: "15px" }}>
          <div class="section-intro ">
            <h2 style={{ fontSize: "30px", fontWeight: "900" }}>Hotel</h2>
            <p style={{ fontSize: "15px", fontWeight: "500" }}>
              <a href="/hotels">View All</a>
            </p>
          </div>
          <div className="ftco-section">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              nav={false}
              dots={false}
              autoplay={true}
              responsive={responsive}
            >
              {Hotels?.filter((data) => data?.category === "Hotel")?.map(
                (items) => {
                  return (
                    <div class="item">
                      <div
                        class="destination desti"
                        style={{
                          display: "block",
                          boxShadow:
                            " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                          margin: " 10px",
                          height: " 400px",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={`https://dhanyatours.me/Hotel/${items?.HotelProfile}`}
                          alt="lkigft"
                          style={{ height: "200px" }}
                        />
                        <div class="text p-3">
                          <div class="d-flex">
                            <div class="one">
                              <h5>
                                <a>{items?.hotalName.slice(0, 15)}</a>
                              </h5>
                            </div>
                            <div class="two">
                              <span class="price">
                                ₹{" "}
                                {(
                                  items?.StartingPrice +
                                  items?.StartingPrice *
                                    ((items?.gst + items?.igst) / 100)
                                )?.toFixed(2)}
                              </span>
                            </div>
                          </div>
                          {/* <p>
                            {items?.OVERVIEW
                              ? parse(items?.OVERVIEW.slice(0, 50))
                              : ""}
                            ...
                          </p> */}
                          <p
                            class="days"
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <img
                                src="../clock.png"
                                alt=""
                                style={{ width: "16px" }}
                              />
                            </span>
                            <span>
                              {items?.CheckInTime}-{items?.CheckOutTime}
                            </span>
                          </p>
                          <hr />
                          <p class="bottom-area d-flex">
                            <span>
                              <IoLocationOutline style={{ fontSize: "15px" }} />{" "}
                              {items?.location}
                            </span>
                            <span class="ml-auto">
                              <a href={`/singal-hotel/${items._id}`}>View</a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </OwlCarousel>
          </div>
        </div>
      </div>
      {/* ============== */}
      {/*  Resort */}
      <div className="popular" style={{ backgroundColor: "#f8f8ff" }}>
        <div className="" style={{ paddingTop: "15px" }}>
          <div class="section-intro ">
            <h2 style={{ fontSize: "30px", fontWeight: "900" }}>Resort</h2>
            <p style={{ fontSize: "15px", fontWeight: "500" }}>
              <a href="/resorts">View All</a>
            </p>
          </div>
          <div className="ftco-section">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              nav={false}
              dots={false}
              autoplay={true}
              responsive={responsive}
            >
              {Hotels?.filter((data) => data?.category === "Resort")?.map(
                (items) => {
                  return (
                    <div class="item">
                      <div
                        class="destination desti"
                        style={{
                          display: "block",
                          boxShadow:
                            " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                          margin: " 10px",
                          height: " 400px",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={`https://dhanyatours.me/Hotel/${items?.HotelProfile}`}
                          alt="lkigft"
                          style={{ height: "200px" }}
                        />
                        <div class="text p-3">
                          <div class="d-flex">
                            <div class="one">
                              <h6>
                                <a>{items?.hotalName?.slice(0, 15)}</a>
                              </h6>
                            </div>
                            <div class="two">
                              <span class="price">
                                ₹{" "}
                                {(
                                  items?.StartingPrice +
                                  items?.StartingPrice *
                                    ((items?.gst + items?.igst) / 100)
                                )?.toFixed(2)}
                              </span>
                            </div>
                          </div>
                          {/* <p>
                            {items?.OVERVIEW
                              ? parse(items?.OVERVIEW.slice(0, 50))
                              : ""}
                            ...
                          </p> */}
                          <p
                            class="days"
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <img
                                src="../clock.png"
                                alt=""
                                style={{ width: "16px" }}
                              />
                            </span>
                            <span>
                              {items?.CheckInTime}-{items?.CheckOutTime}
                            </span>
                          </p>
                          <hr />
                          <p class="bottom-area d-flex">
                            <span>
                              <IoLocationOutline style={{ fontSize: "15px" }} />{" "}
                              {items?.location?.slice(0, 70)}
                            </span>
                            <span class="ml-auto">
                              <a href={`/singal-hotel/${items._id}`}>View</a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </OwlCarousel>
          </div>
        </div>
      </div>
      {/* ============== */}
      {/*  Home Stay */}
      <div className="popular" style={{ backgroundColor: "#f8f8ff" }}>
        <div className="" style={{ paddingTop: "15px" }}>
          <div class="section-intro ">
            <h2 style={{ fontSize: "30px", fontWeight: "900" }}>Home Stay</h2>
            <p style={{ fontSize: "15px", fontWeight: "500" }}>
              <a href="/home-stay">View All</a>
            </p>
          </div>
          <div className="ftco-section">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              nav={false}
              dots={false}
              autoplay={true}
              responsive={responsive}
            >
              {Hotels?.filter((data) => data?.category === "HomeStay")?.map(
                (items) => {
                  return (
                    <div class="item">
                      <div
                        class="destination desti"
                        style={{
                          display: "block",
                          boxShadow:
                            " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                          margin: " 10px",
                          height: " 400px",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={`https://dhanyatours.me/Hotel/${items?.HotelProfile}`}
                          alt="lkigft"
                          style={{ height: "200px" }}
                        />
                        <div class="text p-3">
                          <div class="d-flex">
                            <div class="one">
                              <h5>
                                <a>{items?.hotalName?.slice(0, 15)}</a>
                              </h5>
                            </div>
                            <div class="two">
                              <span class="price">
                                ₹
                                {(
                                  items?.StartingPrice +
                                  items?.StartingPrice *
                                    ((items?.gst + items?.igst) / 100)
                                )?.toFixed(2)}
                              </span>
                            </div>
                          </div>
                          {/* <p>
                            {items?.OVERVIEW
                              ? parse(items?.OVERVIEW.slice(0, 50))
                              : ""}
                            ...
                          </p> */}
                          <p
                            class="days"
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <img
                                src="../clock.png"
                                alt=""
                                style={{ width: "16px" }}
                              />
                            </span>
                            <span>
                              {items?.CheckInTime}-{items?.CheckOutTime}
                            </span>
                          </p>
                          <hr />
                          <p class="bottom-area d-flex">
                            <span>
                              <IoLocationOutline style={{ fontSize: "15px" }} />{" "}
                              {items?.location?.slice(0, 70)}
                            </span>
                            <span class="ml-auto">
                              <a href={`/singal-hotel/${items._id}`}>View</a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </OwlCarousel>
          </div>
        </div>
      </div>
      {/* ============== */}

      {/* <div className="popular" style={{ backgroundColor: "#f8f8ff" }}>
        <div className="" style={{ paddingTop: "15px" }}>
          <div class="section-intro text-center ">
            <img
              class="section-intro-img"
              src="/Taxi/taxi2.png"
              alt=""
              style={{ width: "12%", height: "75px" }}
            />
            <h2 style={{ fontSize: "30px", fontWeight: "900" }}>
              Our Best Packages
            </h2>
            <p style={{ fontSize: "15px", fontWeight: "500" }}>
              <a href="/our-best-packages">View All</a>
            </p>
          </div>
          <div className="ftco-section">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              nav={false}
              dots={false}
              autoplay={true}
              responsive={responsive}
            >
              {loadeimg
                ?.filter((data) => data?.isBloc === false)
                ?.map((items) => {
                  return (
                    <div class="item">
                      <div
                        class="destination desti"
                        style={{
                          display: "block",
                          boxShadow:
                            " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                          margin: " 10px",
                          height: " 430px",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={`https://dhanyatours.me/lodge/${items?.lodgeProfile}`}
                          alt="lkigft"
                          style={{ height: "200px" }}
                        />
                        <div class="text p-3">
                          <div class="d-flex">
                            <div class="one">
                              <h3>
                                <a>{items?.category}</a>
                              </h3>
                            </div>
                            <div class="two">
                              <span class="price">
                                ₹{items?.StartingPrice?.toFixed(2)}
                              </span>
                            </div>
                          </div>
                          <p> {items?.lodgeName}</p>
                          <p
                            class="days"
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <img
                                src="../clock.png"
                                alt=""
                                style={{ width: "16px" }}
                              />
                            </span>
                            <span>{items?.lodgeTiming}</span>
                          </p>
                          <hr />
                          <p class="bottom-area d-flex">
                            <span>
                              <IoLocationOutline style={{ fontSize: "15px" }} />{" "}
                              {items?.location?.slice(0, 70)}
                            </span>
                            <span class="ml-auto">
                              <a href={`/wildlife-singal/${items._id}`}>View</a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </OwlCarousel>
          </div>
        </div>
      </div> */}

      {/* ==== */}
      {item.map((data1) => {
        return (
          <div
            className="out-02"
            style={{
              backgroundImage: `url("https://dhanyatours.me/home/${data1?.image2}")`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundAttachment: "fixed",
              height: "500px",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-4 hinfobxout-9">
                  <div
                    class="hinfobx-0p"
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                  >
                    <h2>Luxury Train</h2>
                    <p style={{ textAlign: "justify",fontSize:"14.9px",wordSpacing:"-1.93px" }}>
                      Luxury trains are a form of upscale travel that the combines
                      the nostalgia of classic train journeys with high-end
                      amenities, service, and accommodations. These trains offer
                      passengers a unique and opulent way to explore scenic
                      routes and destinations.
                    </p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        onClick={() =>
                          history("/luxury-train", {
                            state: { cat: "Adventure" },
                          })
                        }
                      >
                        Explore more
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {/* Luxury Train */}
      <div className="popular" style={{ backgroundColor: "#f8f8ff" }}>
        <div className="" style={{ paddingTop: "15px" }}>
          <div class="section-intro ">
            <h2 style={{ fontSize: "30px", fontWeight: "900" }}>
              Luxury Train
            </h2>
            <p style={{ fontSize: "15px", fontWeight: "500" }}>
              <a href="/luxury-train">View All</a>
            </p>
          </div>
          <div className="ftco-section">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              nav={false}
              dots={false}
              autoplay={true}
              responsive={responsive}
            >
              {gettrain
                ?.filter((ele) => ele?.isBloc === false)
                ?.map((items) => {
                  return (
                    <div class="item">
                      <div
                        class="destination desti"
                        style={{
                          display: "block",
                          boxShadow:
                            " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                          margin: " 10px",
                          height: " 350px",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={`https://dhanyatours.me/train/${items?.trainProfile}`}
                          alt="lkigft"
                          style={{ height: "200px" }}
                        />
                        <div class="text p-3">
                          <div class="d-flex">
                            <div class="one">
                              <h5>
                                <a>{items?.category}</a>
                              </h5>
                            </div>
                            <div class="two">
                              <span class="price">
                                ₹{items?.StartingPrice?.toFixed(2)}
                              </span>
                            </div>
                          </div>
                          {/* <p>
                            {items?.OVERVIEW
                              ? parse(items?.OVERVIEW.slice(0, 70))
                              : ""}
                            ...
                          </p> */}
                          <p
                            class="days"
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <img
                                src="../clock.png"
                                alt=""
                                style={{ width: "16px" }}
                              />
                            </span>
                            <span>{items?.trainTiming}</span>
                          </p>
                          <hr />
                          <p class="bottom-area d-flex">
                            <span>
                              <IoLocationOutline style={{ fontSize: "15px" }} />{" "}
                              {items?.location}
                            </span>
                            <span class="ml-auto">
                              <a href={`/luxury-train/${items._id}`}>View</a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </OwlCarousel>
          </div>
        </div>
      </div>
      {/* ============== */}
    </>
  );
}

export default Home;
