import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  AiFillCar,
  AiFillHome,
  AiFillMoneyCollect,
  AiFillPhone,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import "./sidebar.css";
import {
  BiCategoryAlt,
  BiCurrentLocation,
  BiSolidHomeHeart,
  BiSolidHotel,
  BiSolidTrain,
  BiTrain,
  BiUserCircle,
} from "react-icons/bi";
import { GoGraph } from "react-icons/go";
import {
  MdHotelClass,
  MdLocalHotel,
  MdLocalTaxi,
  MdOutlineHotel,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowLeft,
  MdTaxiAlert,
} from "react-icons/md";
import { LuHotel } from "react-icons/lu";
import {
  BsFillCarFrontFill,
  BsFillTaxiFrontFill,
  BsPatchQuestion,
  BsTaxiFront,
} from "react-icons/bs";
import { FaBusinessTime, FaHotel, FaRegMoneyBillAlt } from "react-icons/fa";
import { TbReceiptTax } from "react-icons/tb";
import { FcMoneyTransfer } from "react-icons/fc";
import { FaChildren } from "react-icons/fa6";
import { RiHotelFill, RiTaxiWifiLine } from "react-icons/ri";
import { GiKnockedOutStars } from "react-icons/gi";
import { SiExpensify } from "react-icons/si";
import axios from "axios";
function Sidebar(props) {
  const [acc, setacc] = useState(true);
  const [Graphics, setGraphics] = useState(false);
  const [acc1, setacc1] = useState(false);
  const [acc2, setacc2] = useState(false);
  const [acc3, setacc3] = useState(false);
  const [acc4, setacc4] = useState(false);
  const [tax, setTax] = useState(false);
  const [acc5, setacc5] = useState(false);
  const [acc6, setacc6] = useState(false);
  const [acc7, setacc7] = useState(false);
  const [acc8, setacc8] = useState(false);
  const [acc9, setacc9] = useState(false);
  const [acc10, setacc10] = useState(false);
  const [acc11, setacc11] = useState(false);
  const [acc12, setacc12] = useState(false);
  const [acc13, setacc13] = useState(false);
  const [acc14, setacc14] = useState(false);
  const [acc15, setacc15] = useState(false);
  const [acc16, setacc16] = useState(false);
  const [acc17, setacc17] = useState(false);
  const [acc18, setacc18] = useState(false);
  const [acc19, setacc19] = useState(false);
  const [acc20, setacc20] = useState(false);
  const [taxi, settaxi] = useState(false);
  const [acc21, setacc21] = useState(false);
  const [acc22, setacc22] = useState(false);
  const [acc23, setacc23] = useState(false);
  const [acc24, setacc24] = useState(false);
  const [acc25, setacc25] = useState(false);
  const [acc26, setacc26] = useState(false);
  const [acc27, setacc27] = useState(false);
  const [acc28, setacc28] = useState(false);
  const [acc29, setacc29] = useState(false);
  const [acc30, setacc30] = useState(false);
  const [amountmanagement, setamountmanagement] = useState(false);
  const [acc31, setacc31] = useState(false);
  const [acc32, setacc32] = useState(false);
  const [acc33, setacc33] = useState(false);
  const [acc34, setacc34] = useState(false);
  const [acc35, setacc35] = useState(false);
  const [luxurycar, setluxurycar] = useState(false);
  const [acc36, setacc36] = useState(false);
  const [acc37, setacc37] = useState(false);
  const [acc38, setacc38] = useState(false);
  const [acc39, setacc39] = useState(false);
  const [acc40, setacc40] = useState(false);
  const [acc41, setacc41] = useState(false);
  const [SubAdmin, setSubAdmin] = useState(false);

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const [navigation, setnavigation] = useState("relative");
  const [booktaxi, setbooktaxi] = useState([]);
  // console.log(graphImage, "orvih");
  const getBooking = async () => {
    const config = {
      url: "/getContofBooking",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setbooktaxi(result.data);

      }
    } catch (error) {
      console.log(error);
    }
  };
 
  useEffect(() => {
    getBooking();
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 500
        ? setnavigation("fixed top-0 left-0 z-50")
        : setnavigation("relative");
    }
  };
  const Logout = () => {
    sessionStorage.removeItem("admin");
    window.location.assign("/admin");
  };
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  console.log("Amit Slider",admin);
  if (!admin) {
    alert("Please login");
    window.location.assign("/admin");
  } else
    return (
      <>
        <div className="header-0">
          <nav className="navigation" style={{ height: "120px" }}>
            <a href="/add-taxi" className="" style={{ width: "15%" }}>
              <img
                src="./logo_eye.jpeg"
                alt="logo"
                style={{ width: "221px", height: "120px" }}
              />
            </a>
            <button
              className="hamburger"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              {/* icon from Heroicons.com */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="white"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <div
              className={
                isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
              }
            >
              <ul>
                <li
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  {" "}
                  <BiUserCircle style={{ color: "white", fontSize: "45px" }} />
                  {"  "} <a style={{ color: "white" }}>{admin?.name}</a>
                </li>

                <li>
                  <button
                    type="button"
                    class="btn btn-outline-info"
                    onClick={Logout}
                  >
                    Logout
                  </button>
                </li>
                {/* <li>
                <a href="/booking">Mybooking</a>
              </li> */}
              </ul>
            </div>
          </nav>
        </div>
        <div>
          <Row className="me-0">
            <Col md={2}>
              <div class="sidebar">
                <ul>
                  <div className="mt-3">
                    {admin?.HomeS==true ?(<Link to="/add-home">
                      <li
                        className={`a-ele ${acc ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(true);
                          setacc1(false);
                          setacc2(false);
                          setSubAdmin(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc18(false);
                          setacc17(false);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                        }}
                      >
                        <span>
                          <AiFillHome
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Home{" "}
                        </span>
                      </li>
                    </Link>):(<></>)}
                    {admin?.SubAdmin==true ? (    <Link to="/Sub-Admin">
                      <li
                        className={`a-ele ${SubAdmin ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setSubAdmin(true);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc18(false);
                          setacc17(false);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                        }}
                      >
                        <span>
                          <BiUserCircle
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Sub-Admin{" "}
                        </span>
                      </li>
                    </Link>):(<></>)}
                      {admin?.jungleGraph==true ||admin?.taxiGraph==true||admin?.hotelGraph==true||admin?.luxuryTrainGraph==true ? (   <Link to="#">
                      <li
                        className={`a-ele ${acc ? "active-0" : "null"}`}
                        onClick={() => {
                          setGraphics(!Graphics);
                          setTax(false);
                          settaxi(false);
                          setSubAdmin(false);
                          setamountmanagement(false);
                          setluxurycar(false);
                        }}
                      >
                        {" "}
                        <span>
                          {" "}
                          <GoGraph
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Graphics
                        </span>
                        {Graphics ? (
                          <>
                            {" "}
                            <span
                              style={{ float: "right", marginTop: "-20px" }}
                            >
                              <MdOutlineKeyboardArrowLeft />
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              style={{ float: "right", marginTop: "-20px" }}
                            >
                              <MdOutlineKeyboardArrowDown />
                            </span>
                          </>
                        )}
                      </li>
                    </Link>):(<></>)  }
                 
                    <Link>
                      {Graphics ? (
                        <>
                          <div className="graphics">
                            {admin?.jungleGraph == true ? (<Link to="/add-lodges-graphic">
                              <li
                                className={`a-ele ${acc1 ? "active2" : "null"}`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(true);
                                  setacc2(false);
                                  setacc3(false);
                                  setacc4(false);
                                  setacc5(false);
                                  setacc6(false);
                                  setacc7(false);
                                  setSubAdmin(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(false);
                                  setacc23(false);
                                  setacc24(false);
                                  setacc25(false);
                                  setacc26(false);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(false);
                                  setacc37(false);
                                }}
                              >
                                <span>
                                  <LuHotel
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  Jungle Lodges{" "}
                                </span>
                              </li>{" "}
                            </Link>):(<></>)}
                            {admin?.taxiGraph == true ? (   <Link to="/add-taxi-graphic">
                              {" "}
                              <li
                                className={`a-ele ${acc2 ? "active2" : "null"}`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setacc2(true);
                                  setacc3(false);
                                  setacc4(false);
                                  setSubAdmin(false);
                                  setacc5(false);
                                  setacc6(false);
                                  setacc7(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(false);
                                  setacc23(false);
                                  setacc24(false);
                                  setacc25(false);
                                  setacc26(false);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(false);
                                  setacc37(false);
                                }}
                              >
                                <span>
                                  <BsFillTaxiFrontFill
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  Taxi{" "}
                                </span>
                              </li>
                            </Link>):(<></>)}
                         {admin?.hotelGraph == true ? ( <Link to="/Hotel-graph">
                              <li
                                className={`a-ele ${acc3 ? "active2" : "null"}`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setSubAdmin(false);
                                  setacc2(false);
                                  setacc3(true);
                                  setacc4(false);
                                  setacc5(false);
                                  setacc6(false);
                                  setacc7(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(false);
                                  setacc23(false);
                                  setacc24(false);
                                  setacc25(false);
                                  setacc26(false);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(false);
                                  setacc37(false);
                                }}
                              >
                                <span>
                                  <BiSolidHotel
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  Hotel{" "}
                                </span>
                              </li>
                            </Link>):(<></>)}
                           {admin?.luxuryTrainGraph == true ? (   <Link to="/luxury-train-img">
                              {" "}
                              <li
                                className={`a-ele ${acc4 ? "active2" : "null"}`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setSubAdmin(false);
                                  setacc2(false);
                                  setacc3(false);
                                  setacc4(true);
                                  setacc5(false);
                                  setacc6(false);
                                  setacc7(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(false);
                                  setacc23(false);
                                  setacc24(false);
                                  setacc25(false);
                                  setacc26(false);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(false);
                                  setacc37(false);
                                }}
                              >
                                <span>
                                  <BiSolidTrain
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  Luxury Train{" "}
                                </span>
                              </li>
                            </Link>):(<></>)}
                         
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </Link>
                    {admin?.gst == true||admin?.igst == true ? ( <Link to="#">
                      <li
                        className={`a-ele ${acc ? "active-0" : "null"}`}
                        onClick={() => {
                          setTax(!tax);
                          setGraphics(false);
                          settaxi(false);
                          setamountmanagement(false);
                          setluxurycar(false);
                          setSubAdmin(false);
                        }}
                      >
                        {" "}
                        <span>
                          {" "}
                          <TbReceiptTax
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Tax
                        </span>
                        {tax ? (
                          <>
                            {" "}
                            <span
                              style={{ float: "right", marginTop: "-20px" }}
                            >
                              <MdOutlineKeyboardArrowLeft />
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              style={{ float: "right", marginTop: "-20px" }}
                            >
                              <MdOutlineKeyboardArrowDown />
                            </span>
                          </>
                        )}
                      </li>
                    </Link>):(<></>)}
                   
                    <Link>
                      {tax ? (
                        <>
                          <div className="graphics">
                            {admin?.gst == true ? (  <Link to="/admin_gst">
                              <li
                                className={`a-ele ${acc5 ? "active2" : "null"}`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setacc2(false);
                                  setSubAdmin(false);
                                  setacc3(false);
                                  setacc4(false);
                                  setacc5(true);
                                  setacc6(false);
                                  setacc7(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(false);
                                  setacc23(false);
                                  setacc24(false);
                                  setacc25(false);
                                  setacc26(false);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(false);
                                  setacc37(false);
                                }}
                              >
                                <span>
                                  <FcMoneyTransfer
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  GST
                                </span>
                              </li>{" "}
                            </Link>):(<></>)}
                            {admin?.igst == true ? (<Link to="/admin_igst">
                              <li
                                className={`a-ele ${acc6 ? "active2" : "null"}`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setacc2(false);
                                  setacc3(false);
                                  setSubAdmin(false);
                                  setacc4(false);
                                  setacc5(false);
                                  setacc6(true);
                                  setacc7(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(false);
                                  setacc23(false);
                                  setacc24(false);
                                  setacc25(false);
                                  setacc26(false);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(false);
                                  setacc37(false);
                                }}
                              >
                                <span>
                                  <FaRegMoneyBillAlt
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  IGST
                                </span>
                              </li>{" "}
                            </Link>):(<></>)}
                            
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </Link>
                        {admin?.location == true? ( <Link to="/add-location">
                      <li
                        className={`a-ele ${acc7 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(true);
                          setacc8(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc18(false);
                          setacc17(false);
                          setacc19(false);
                          setSubAdmin(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                        }}
                      >
                        <span>
                          <BiCurrentLocation
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Add Location List
                        </span>
                      </li>{" "}
                    </Link>):(<></>) }
                   {admin?.children == true ? (    <Link to="/children">
                      <li
                        className={`a-ele ${acc8 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(true);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc18(false);
                          setacc17(false);
                          setSubAdmin(false);
                          setacc19(false);
                          setGraphics(false)
                          setacc20(false);
                          setTax(false)
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                        }}
                      >
                        <span>
                          <FaChildren
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Children age
                        </span>
                      </li>{" "}
                    </Link>):(<></>)}
                {admin?.vendorS == true ? ( <Link to="/add-vendor">
                      {" "}
                      <li
                        className={`a-ele ${acc9 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setSubAdmin(false);
                          setacc8(false);
                          setacc9(true);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc18(false);
                          setacc17(false);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                        }}
                      >
                        <span>
                          <FaBusinessTime
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Vendor
                        </span>
                      </li>
                    </Link>):(<></>)}
                   {admin?.hotel == true ? ( <Link to="/Add-Hotel">
                      <li
                        className={`a-ele ${acc10 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setTax(false);
                          setGraphics(false)
                          setSubAdmin(false);
                          setacc7(false);
                          setacc8(false);
                          setacc9(false);
                          setacc10(true);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc18(false);
                          setacc17(false);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                        }}
                      >
                        <span>
                          <FaHotel
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Hotel
                        </span>
                      </li>
                    </Link>):(<></>)}
                   {admin?.hotelBooking == true ?  (<Link to="/booked-hotel-details">
                      <li
                        className={`a-ele ${acc11 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setSubAdmin(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(true);
                          setacc12(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc18(false);
                          setacc17(false);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                        }}
                      >
                        <span>
                          <MdOutlineHotel
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Hotel Bookings
                          <span
                            style={{ marginLeft: "40px", marginTop: "10px" }}
                          >
                            ({booktaxi?.hotelBook?.filter((ele) => ele?.lodgeId?.category === "Hotel")?.length})
                          </span>
                        </span>
                      </li>{" "}
                    </Link>):(<></>)}
                    {admin?.HomeStay == true ? (      <Link to="/Add-Home-Stay-Hotel">
                      <li
                        className={`a-ele ${acc12 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setGraphics(false);
                          setTax(false)
                          setacc7(false);
                          setacc8(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(true);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc18(false);
                          setacc17(false);
                          setacc19(false);
                          setSubAdmin(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                        }}
                      >
                        <span>
                          <BiSolidHomeHeart
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Home Stay List
                        </span>
                      </li>{" "}
                    </Link>):(<></>)}
              {admin?.HomeStayBooking == true ? (<Link to="/Book-Home-Stay-Hotel">
                      {" "}
                      <li
                        className={`a-ele ${acc13 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(false);
                          setSubAdmin(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(true);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc18(false);
                          setacc17(false);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                        }}
                      >
                        <span>
                          <BiSolidHomeHeart
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Home Stay Bookings
                          <span
                            style={{ marginLeft: "20px", marginTop: "10px" }}
                          >
                             ({booktaxi?.hotelBook?.filter((ele) => ele?.lodgeId?.category === "HomeStay")?.length})
                          </span>
                        </span>
                      </li>{" "}
                    </Link>):(<></>)}
                    
                    {admin?.resort == true ? (<Link to="/Add-Resort">
                      {" "}
                      <li
                        className={`a-ele ${acc14 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(false);
                          setSubAdmin(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setacc14(true);
                          setacc15(false);
                          setacc16(false);
                          setacc18(false);
                          setacc17(false);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                          setGraphics(false);
                          setTax(false);
                        }}
                      >
                        <span>
                          <RiHotelFill
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Resort List
                        </span>
                      </li>{" "}
                    </Link>):(<></>)}
                    {admin?.resortBooking == true ? (<Link to="/Resort-book-details">
                      {" "}
                      <li
                        className={`a-ele ${acc15 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setSubAdmin(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(true);
                          setacc16(false);
                          setacc17(false);
                          setacc18(false);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                          setGraphics(false);
                          setTax(false);
                        }}
                      >
                        <span>
                          <MdLocalHotel
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Resort Bookings
                          <span
                            style={{ marginLeft: "32px", marginTop: "10px" }}
                          >
                            {" "}
                            ({booktaxi?.hotelBook?.filter((ele) => ele?.lodgeId?.category === "Resort")?.length})
                          </span>
                        </span>
                      </li>{" "}
                    </Link>):(<></>)}
                    {admin?.lodgeCategory == true ? (<Link to="/lodge-category">
                      <li
                        className={`a-ele ${acc16 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(false);
                          setacc9(false);
                          setacc10(false);
                          setSubAdmin(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(true);
                          setacc17(false);
                          setacc18(false);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                          setGraphics(false);
                          setTax(false);
                        }}
                      >
                        <span>
                          <BiCategoryAlt
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Jungle Lodges Category
                        </span>
                      </li>{" "}
                    </Link>):(<></>)}
                    {admin?.lodge == true ? (<Link to="/add-lodges">
                      <li
                        className={`a-ele ${acc17 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setSubAdmin(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc17(true);
                          setacc18(false);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                          setGraphics(false);
                          setTax(false);
                        }}
                      >
                        <span>
                          <MdHotelClass
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Jungle Lodges List
                        </span>
                      </li>{" "}
                    </Link>):(<></>)}

                    {admin?.lodgeBooking == true ? (<Link to="/Lodge-book-details">
                      {" "}
                      <li
                        className={`a-ele ${acc18 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(false);
                          setacc9(false);
                          setacc10(false);
                          setSubAdmin(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc16(false);
                          setacc17(false);
                          setacc18(true);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                          setGraphics(false);
                          setTax(false);
                        }}
                      >
                        <span>
                          <MdLocalHotel
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                           Lodge Enquiry
                          <span
                            style={{ marginLeft: "27px", marginTop: "10px" }}
                          >
                            {" "}
                            ({booktaxi?.lodgeBook})
                          </span>
                        </span>
                      </li>{" "}
                    </Link>):(<></>)}
                    {admin?.luxuryTrain == true ? (<Link to="/add-luxury-train">
                      {" "}
                      <li
                        className={`a-ele ${acc19 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setSubAdmin(false);
                          setacc16(false);
                          setacc16(false);
                          setacc17(false);
                          setacc18(false);
                          setacc19(true);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                          setGraphics(false);
                          setTax(false);
                        }}
                      >
                        <span>
                          <BiTrain
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Luxury Train
                        </span>
                      </li>{" "}
                    </Link>):(<></>)}
                    {admin?.luxuryTrainBooking == true ? (  <Link to="/Inquire-train-details">
                      {" "}
                      <li
                        className={`a-ele ${acc20 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setSubAdmin(false);
                          setacc7(false);
                          setacc8(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc16(false);
                          setacc17(false);
                          setacc18(false);
                          setacc19(false);
                          setacc20(true);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                          setGraphics(false);
                          setTax(false);
                        }}
                      >
                        <span>
                          <BiTrain
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Luxury Train Enquiry
                          <span
                            style={{ marginLeft: "30px", marginTop: "10px" }}
                          >
                              ({booktaxi?.inquiryTrain})
                          </span>
                        </span>
                      </li>{" "}
                    </Link>):(<></>)}
                      {admin?.taxiManger == true ? (<Link to="#">
                      <li
                        className={`a-ele ${acc ? "active-0" : "null"}`}
                        onClick={() => {
                          settaxi(!taxi);
                          setGraphics(false);
                          setTax(false);
                          setamountmanagement(false);
                          setluxurycar(false);
                        }}
                      >
                        {" "}
                        <span>
                          {" "}
                          <RiTaxiWifiLine
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Taxi Management
                        </span>
                        {taxi ? (
                          <>
                            {" "}
                            <span
                              style={{ float: "right", marginTop: "-20px" }}
                            >
                              <MdOutlineKeyboardArrowLeft />
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              style={{ float: "right", marginTop: "-20px" }}
                            >
                              <MdOutlineKeyboardArrowDown />
                            </span>
                          </>
                        )}
                      </li>
                    </Link>):(<></>)}
                    {admin?.taxiManger == true ? ( <Link>
                      {taxi ? (
                        <>
                          <div className="graphics">
                            <Link to="/admin_vehicle_Category">
                              <li
                                className={`a-ele ${
                                  acc21 ? "active2" : "null"
                                }`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setacc2(false);
                                  setacc3(false);
                                  setacc4(false);
                                  setacc5(false);
                                  setacc6(false);
                                  setacc7(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setSubAdmin(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(true);
                                  setacc22(false);
                                  setacc23(false);
                                  setacc24(false);
                                  setacc25(false);
                                  setacc26(false);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(false);
                                  setacc37(false);
                                }}
                              >
                                <span>
                                  <AiFillCar
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  Vehicle Category List
                                </span>
                              </li>{" "}
                            </Link>
                            <Link to="/admin_vehicle_Category_Model">
                              <li
                                className={`a-ele ${
                                  acc22 ? "active2" : "null"
                                }`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setacc2(false);
                                  setacc3(false);
                                  setacc4(false);
                                  setacc5(false);
                                  setacc6(false);
                                  setacc7(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setSubAdmin(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(true);
                                  setacc23(false);
                                  setacc24(false);
                                  setacc25(false);
                                  setacc26(false);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(false);
                                  setacc37(false);
                                }}
                              >
                                <span>
                                  <MdTaxiAlert
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  Vehicle Model List
                                </span>
                              </li>{" "}
                            </Link>
                            <Link to="/add-taxi">
                              {" "}
                              <li
                                className={`a-ele ${
                                  acc23 ? "active2" : "null"
                                }`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setacc2(false);
                                  setacc3(false);
                                  setacc4(false);
                                  setacc5(false);
                                  setacc6(false);
                                  setacc7(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setacc11(false);
                                  setSubAdmin(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(false);
                                  setacc23(true);
                                  setacc24(false);
                                  setacc25(false);
                                  setacc26(false);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(false);
                                  setacc37(false);
                                }}
                              >
                                <span>
                                  <MdLocalTaxi
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  Vehicle List
                                </span>
                              </li>{" "}
                            </Link>
                            <Link to="/admin_outstation">
                              {" "}
                              <li
                                className={`a-ele ${
                                  acc24 ? "active2" : "null"
                                }`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setacc2(false);
                                  setacc3(false);
                                  setacc4(false);
                                  setacc5(false);
                                  setacc6(false);
                                  setacc7(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setSubAdmin(false);
                                  setacc10(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(false);
                                  setacc23(false);
                                  setacc24(true);
                                  setacc25(false);
                                  setacc26(false);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(false);
                                  setacc37(false);
                                }}
                              >
                                <span>
                                  <GiKnockedOutStars
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  Outstation Package List
                                </span>
                              </li>{" "}
                            </Link>
                            <Link to="/admin_airport">
                              {" "}
                              <li
                                className={`a-ele ${
                                  acc25 ? "active2" : "null"
                                }`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setacc2(false);
                                  setacc3(false);
                                  setacc4(false);
                                  setacc5(false);
                                  setSubAdmin(false);
                                  setacc6(false);
                                  setacc7(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(false);
                                  setacc23(false);
                                  setacc24(false);
                                  setacc25(true);
                                  setacc26(false);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(false);
                                  setacc37(false);
                                }}
                              >
                                <span>
                                  <GiKnockedOutStars
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  Airport Package List
                                </span>
                              </li>{" "}
                            </Link>
                            <Link to="/add-package">
                              {" "}
                              <li
                                className={`a-ele ${
                                  acc26 ? "active2" : "null"
                                }`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setacc2(false);
                                  setacc3(false);
                                  setacc4(false);
                                  setacc5(false);
                                  setacc6(false);
                                  setSubAdmin(false);
                                  setacc7(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(false);
                                  setacc23(false);
                                  setacc24(false);
                                  setacc25(false);
                                  setacc26(true);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(false);
                                  setacc37(false);
                                }}
                              >
                                <span>
                                  <GiKnockedOutStars
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  local/Rental Package List
                                </span>
                              </li>{" "}
                            </Link>
                            <Link to="/admin_add_drivers">
                              {" "}
                              <li
                                className={`a-ele ${
                                  acc27 ? "active2" : "null"
                                }`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setacc2(false);
                                  setacc3(false);
                                  setacc4(false);
                                  setacc5(false);
                                  setacc6(false);
                                  setacc7(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setSubAdmin(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(false);
                                  setacc23(false);
                                  setacc24(false);
                                  setacc25(false);
                                  setacc26(false);
                                  setacc27(true);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(false);
                                  setacc37(false);
                                }}
                              >
                                <span>
                                  <GiKnockedOutStars
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  Drivers List
                                </span>
                              </li>{" "}
                            </Link>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </Link>):(<></>)}
                   {admin?.taxiBooking == true ? ( <Link to="/add-book-taxi">
                      {" "}
                      <li
                        className={`a-ele ${acc28 ? "active2" : "null"}`}
                        onClick={() => {
                          settaxi(false);
                          setGraphics(false);
                          setTax(false);
                          setamountmanagement(false);
                          setluxurycar(false);
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(false);
                          setSubAdmin(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc18(false);
                          setacc17(false);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(true);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                        }}
                      >
                        <span>
                          <BsTaxiFront
                            style={{
                              fontSize: "20px",
                              marginRight: "10px",
                            }}
                          />
                          Taxi Bookings{" "}
                          <span style={{ marginLeft: "50px" }}> ({booktaxi?.taxiBook})</span>
                        </span>
                      </li>{" "}
                    </Link>):(<></>)}
                   {admin?.LuxuryCar == true||admin?.luxuryCarModel == true||admin?.LuxuryCarEnquiry == true  ?(<Link to="#">
                      <li
                        className={`a-ele ${acc ? "active-0" : "null"}`}
                        onClick={() => {
                          setGraphics(false);
                          setTax(false);
                          settaxi(false);
                          setamountmanagement(false);
                          setluxurycar(!luxurycar);
                          setSubAdmin(false);
                        }}
                      >
                        {" "}
                        <span>
                          {" "}
                          <GoGraph
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Luxury Car
                        </span>
                        {luxurycar ? (
                          <>
                            {" "}
                            <span
                              style={{ float: "right", marginTop: "-20px" }}
                            >
                              <MdOutlineKeyboardArrowLeft />
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              style={{ float: "right", marginTop: "-20px" }}
                            >
                              <MdOutlineKeyboardArrowDown />
                            </span>
                          </>
                        )}
                      </li>
                    </Link>):(<></>)}
                    
                    <Link>
                      {luxurycar ? (
                        <>
                          <div className="graphics">
                            {admin?.LuxuryCar == true ? (<Link to="/Admin_car_brands">
                              {" "}
                              <li
                                className={`a-ele ${
                                  acc37 ? "active2" : "null"
                                }`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setacc2(true);
                                  setacc3(false);
                                  setacc4(false);
                                  setacc5(false);
                                  setacc6(false);
                                  setacc7(false);
                                  setacc8(false);
                                  setSubAdmin(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(false);
                                  setacc23(false);
                                  setacc24(false);
                                  setacc25(false);
                                  setacc26(false);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(false);
                                  setacc37(true);
                                }}
                              >
                                <span>
                                  <BsFillTaxiFrontFill
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  Car Brand{" "}
                                </span>
                              </li>
                            </Link>):(<></>)}
                            {admin?.luxuryCarModel == true ? (<Link to="/Admin_car_model">
                              <li
                                className={`a-ele ${
                                  acc36 ? "active2" : "null"
                                }`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setacc2(false);
                                  setacc3(true);
                                  setacc4(false);
                                  setacc5(false);
                                  setacc6(false);
                                  setacc7(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setSubAdmin(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(false);
                                  setacc23(false);
                                  setacc24(false);
                                  setacc25(false);
                                  setacc26(false);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(true);
                                }}
                              >
                                <span>
                                  <AiFillCar
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  Car Model{" "}
                                </span>
                              </li>
                            </Link>):(<></>)}
                            {admin?.LuxuryCarEnquiry == true ? (   <Link to="/Admin_Luxury_car_enquiry">
                              {" "}
                              <li
                                className={`a-ele ${
                                  acc35 ? "active2" : "null"
                                }`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setacc2(false);
                                  setacc3(false);
                                  setacc4(false);
                                  setacc5(false);
                                  setacc6(false);
                                  setacc7(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setSubAdmin(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(false);
                                  setacc23(false);
                                  setacc24(false);
                                  setacc25(false);
                                  setacc26(false);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(true);
                                  setacc36(false);
                                  setacc37(false);
                                }}
                              >
                                <span>
                                  <AiFillCar
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  Luxury Car Enquiry{" "}
                                  <span style={{ marginLeft: "50px" }}>
                                  ({booktaxi?.LuxuryCar})
                                  </span>
                                </span>
                              </li>{" "}
                            </Link>):(<></>)}
                         
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </Link>
                        {admin?.genrelEnquiry == true ? (<Link to="/Enquiries">
                      {" "}
                      <li
                        className={`a-ele ${acc29 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(false);
                          setSubAdmin(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc18(false);
                          setacc17(false);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(true);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                          setGraphics(false);
                          setTax(false);
                          settaxi(false);
                      
                          setamountmanagement(false);
                        }}
                      >
                        <span>
                          <BsPatchQuestion
                            style={{
                              fontSize: "20px",
                              marginRight: "10px",
                            }}
                          />
                          General Enquiries{" "}
                          <span style={{ marginLeft: "50px" }}>
                                  ({booktaxi?.genralEn})
                                  </span>
                        </span>
                      </li>{" "}
                    </Link>):(<></>)}
                    {admin?.contactus==true ? (<Link to="/information">
                      {" "}
                      <li
                        className={`a-ele ${acc30 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc18(false);
                          setacc17(false);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(true);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                             setSubAdmin(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                        }}
                      >
                        <span>
                          <AiFillPhone
                            style={{
                              fontSize: "20px",
                              marginRight: "10px",
                            }}
                          />
                          Contact Us
                        </span>
                      </li>{" "}
                    </Link>):(<></>) }
                    
                    {admin?.expanse == true||admin?.driverCash == true ? (<Link to="#">
                      <li
                        className={`a-ele ${acc ? "active-0" : "null"}`}
                        onClick={() => {
                          setGraphics(false);
                          setTax(false);
                          settaxi(false);
                          setSubAdmin(false);
                          setamountmanagement(!amountmanagement);
                        }}
                      >
                        {" "}
                        <span>
                          {" "}
                          <GoGraph
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          Account Management
                        </span>
                        {amountmanagement ? (
                          <>
                            {" "}
                            <span
                              style={{ float: "right", marginTop: "-20px" }}
                            >
                              <MdOutlineKeyboardArrowLeft />
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              style={{ float: "right", marginTop: "-20px" }}
                            >
                              <MdOutlineKeyboardArrowDown />
                            </span>
                          </>
                        )}
                      </li>
                    </Link>):(<></>)}
                    
                    <Link>
                      {amountmanagement ? (
                        <>
                          <div className="graphics">
                            {admin?.expanse == true ? (<Link to="/Admin_Expense">
                              <li
                                className={`a-ele ${
                                  acc31 ? "active2" : "null"
                                }`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setacc2(false);
                                  setacc3(false);
                                  setacc4(false);
                                  setacc5(false);
                                  setSubAdmin(false);
                                  setacc6(false);
                                  setacc7(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(false);
                                  setacc23(false);
                                  setacc24(false);
                                  setacc25(false);
                                  setacc26(false);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(true);
                                  setacc32(false);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(false);
                                  setacc37(false);
                                }}
                              >
                                <span>
                                  <SiExpensify
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  Expense{" "}
                                </span>
                              </li>{" "}
                            </Link>):(<></>)}
                            {admin?.driverCash == true ? ( <Link to="/Admin_Collection">
                              {" "}
                              <li
                                className={`a-ele ${
                                  acc32 ? "active2" : "null"
                                }`}
                                onClick={() => {
                                  setacc(false);
                                  setacc1(false);
                                  setacc2(true);
                                  setacc3(false);
                                  setacc4(false);
                                  setacc5(false);
                                  setSubAdmin(false);
                                  setacc6(false);
                                  setacc7(false);
                                  setacc8(false);
                                  setacc9(false);
                                  setacc10(false);
                                  setacc11(false);
                                  setacc12(false);
                                  setacc13(false);
                                  setacc14(false);
                                  setacc15(false);
                                  setacc16(false);
                                  setacc18(false);
                                  setacc17(false);
                                  setacc19(false);
                                  setacc20(false);
                                  setacc21(false);
                                  setacc22(false);
                                  setacc23(false);
                                  setacc24(false);
                                  setacc25(false);
                                  setacc26(false);
                                  setacc27(false);
                                  setacc28(false);
                                  setacc29(false);
                                  setacc30(false);
                                  setacc31(false);
                                  setacc32(true);
                                  setacc33(false);
                                  setacc34(false);
                                  setacc35(false);
                                  setacc36(false);
                                  setacc37(false);
                                }}
                              >
                                <span>
                                  <AiFillMoneyCollect
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                Cash  Collection{" "}
                                </span>
                              </li>
                            </Link>):(<></>)}
                           
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </Link>
                        {admin?.InvoiceGen == true ? (<Link to="/Admin_invoice">
                      <li
                        className={`a-ele ${acc33 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(true);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setSubAdmin(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc18(false);
                          setacc17(false);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(true);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                          setGraphics(false);
                          setTax(false);
                          settaxi(false);
                       
                          setamountmanagement(false);
                        }}
                      >
                        <span>
                          <BiSolidHotel
                            style={{
                              fontSize: "20px",
                              marginRight: "10px",
                            }}
                          />
                          Invoice Generation{" "}
                        </span>
                      </li>
                    </Link>):(<></>)}
                    {admin?.accountHistory == true ? (<Link to="/Admin_Statement">
                      {" "}
                      <li
                        className={`a-ele ${acc34 ? "active2" : "null"}`}
                        onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(true);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setSubAdmin(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc18(false);
                          setacc17(false);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(false);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(true);
                          setGraphics(false);
                          setTax(false);
                          settaxi(false);
                       
                          setamountmanagement(false);
                        }}
                      >
                        <span>
                          <BiSolidTrain
                            style={{
                              fontSize: "20px",
                              marginRight: "10px",
                            }}
                          />
                          Account Statement{" "}
                        </span>
                      </li>
                    </Link>):(<></>)}
                    
                    {/* <Link to="/add-package">
                      {" "}
                      <img
                        src="../package.png"
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                      Package
                    </Link> */}
                  </div>
                </ul>
              </div>
            </Col>
            <Col md={10}>
              {" "}
              <ChakraProvider>{props.childern}</ChakraProvider>
            </Col>
          </Row>
        </div>
      </>
    );
}

export default Sidebar;
