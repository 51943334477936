import React, { useEffect } from "react";

function Loader() {
  useEffect(() => {
    setTimeout(() => {
      window.location.assign("/home");
    }, 3000);
  }, []);

  return (
    <>
      <div className="">
        <div
          className="loader"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <img
            src="../dhanaya travellls.gif"
            alt="loader"
            style={{ width: "100%", borderRadius: "50%" }}
          />
        </div>
      </div>
    </>
  );
}

export default Loader;
