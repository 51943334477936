import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import "./busbooking.css";
import { IoLocation } from "react-icons/io5";
import { MdOutlineAirlineSeatReclineExtra } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";

function BusBooking() {
  const [selectedOption, setSelectedOption] = useState("option1");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
       <div className="gradient-box">
          <h2 style={{ textAlign: "center",padding:'20px',color:"white",fontWeight:"bold" }}>Bus Booking</h2>
        </div>
       <br/>

      <div className="container">
        <div className="bus_booking">
          <div className="bus_journey_details">
            <div
              className="mb-4 flight_0">
              <h4 className="fw-bold">VRL Travels</h4>
              <div className="d-flex justify-content-between">
                <p>A/C Seater Push Back (2+1)</p>
                <p className="fw-bold">Seat No: 5D</p>
              </div>
              <div className="bus_userdetails mb-4">
                <div className="bus_from_info">
                  <p>
                    <strong>10:30 AM</strong> 7 Sep' 23, Thu
                  </p>
                  <b>Delhi</b>
                  <p>
                    Kashmiri gate Toward civil line road Bharat petrol pump near
                    kashmiri gate metro gate 4
                  </p>
                </div>
                <div>
                  <p>-------9 hr 30 min---------</p>
                </div>
                <div className="bus_to_info">
                  <p>
                    <strong>18:30 PM</strong> 7 Sep' 23, Thu
                  </p>
                  <b>Kanpur</b>
                  <p>Kanpur bypass kannauj cut lucknow kanpur expressway</p>
                </div>
              </div>
            </div>

            <div className="travel_details flight_0">
              <h4 className="fw-bold">Traveller Details</h4>
              <div>
                <div className="row align-items-center">
                  <div className="form-group col-md-4">
                    <label for="inputCity">Full Name<span style={{ color: "red" }}>*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputCity"
                    />
                  </div>
                  <div className="form-group col-md-2">
                    <label for="inputState">
                      Age<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputCity"
                    />
                  </div>
                  <div className="form-group col-md-2 ">
                    <label for="inputZip">Gender<span style={{ color: "red" }}>*</span></label>
                    <select id="inputState" class="form-control">
                      <option selected style={{marginBottom:"5px"}}>Choose...</option>
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
         </div>
   <div className="bus_details01 flight_0" >
            <h4 className="fw-bold"> Price details</h4>
            <div className="bus_fare_det">
              <div className="mb-3">
                <p className="d-flex justify-content-between p-2">
                  <span> Base Fare</span> <b> ₹ 1598.00</b>
                </p>
                <p className="d-flex justify-content-between p-2">
                  <span> Tax</span> <b> ₹ 80.00</b>
                </p>
                <p className="d-flex justify-content-between p-2">
                  <span> My Deal</span> <b> - ₹ 80.00</b>
                </p>
                <hr />
                <p className="d-flex justify-content-between p-2">
                  <span> Total Amount</span> <b> ₹ 1598</b>
                </p>
              </div>
              <div>
              <p className="hvr-shutter-in-vertical flight-book" onClick={handleShow} style={{backgroundColor:"#004aad"}}> CONTINUE </p>
              </div>
              <p style={{ fontSize: "15px", padding: "5px 11px 3px 18px",margin:"10px" }}>
                By proceeding, I agree to Dhanyatravels User Agreement, Terms of
                Service and Privacy Policy
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Payment modal */}

      <Modal show={show} onHide={handleClose} className="flight_0">
        <Modal.Header closeButton style={{color:"#004aad"}}>
          <Modal.Title className="gradient-box1">Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex hgdsf_0" style={{justifyContent:'space-between'}}>

            <div>
              <div className="mb-2">
                <div className="d-flex gap-2 align-items-center">
                  <span className="fs-5"><IoLocation style={{color:"#004aad"}}/></span>
                  <span className="fw-bold">Pick Up</span>
                </div>
                <span className="text-secondary">Near Anand Rao Circle</span>
              </div>
              <div>
                <div className="d-flex gap-2 align-items-center">
                  <span className="fs-5"><IoLocation style={{color:"#004aad"}}/></span>
                  <span className="fw-bold">Drop Off</span>
                </div>
                <span className="text-secondary">Chennama Circle</span>
              </div>
            </div>

            <div>
              <div className="d-flex gap-2 align-items-center">
                <span className="fs-5"><MdOutlineAirlineSeatReclineExtra style={{color:"#004aad"}}/></span>
                <span className="fw-bold">Seat</span>
              </div>
              <span className="text-secondary">seat 1-B</span>
            </div>

            <div className="text-end">
              <div className="">
                <span className="fs-5"><FaUser style={{color:"#004aad"}}/></span>
                <span className="fw-bold">Traveler(s)</span>
              </div>
              <span className="text-secondary">1.Abc(M, 24)</span> <br />
              <span className="text-secondary">abc@gmail.com</span> <br /> 
              <span className="text-secondary">+91 9000000000</span>
            </div>
          </div>

          <div className="payment_method mb-4">
            <h4>Payment Method</h4>
            <div className="userdetails">
              <div>
                <label>
                  <input
                    type="radio"
                    value="option1"
                    checked={selectedOption === "option1"}
                    onChange={handleOptionChange}
                  />{" "}
                  &nbsp; Pay Online
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    value="option2"
                    checked={selectedOption === "option2"}
                    onChange={handleOptionChange}
                  />
                  &nbsp; Pay Later
                </label>
                <br />
              </div>
            </div>
          </div>
          <div>
            <Link>
            <p className="hvr-shutter-in-vertical flight-book" style={{backgroundColor:"#004aad"}}>Submit</p>
            </Link>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
}

export default BusBooking;
