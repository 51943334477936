import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
const LuxuryTrainInvoice = () => {
  const { id } = useParams();
  const [bookData, setBookData] = useState({});
  const getInvoiceById = async () => {
    try {
      let res = await axios(
        "https://dhanyatours.me/api/admin/getInvoiceById/" + id
      );
      if (res.status == 200) {
        setBookData(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getInvoiceById();
    }
  }, []);

  const History = useNavigate();

  console.log("Invoice==ssdsdsds>", bookData);

  const generatePDF = async () => {
    const input = document.getElementById("pdf-content");
    const options = { scrollY: -window.scrollY };
    const canvas = await html2canvas(input, options);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    pdf.save("Invoice.pdf");
  };
  return (
    <>
      <div
        className="container py-5"
        id="pdf-content"
        style={{
          display: " flex",
          justifyContent: " center",
        }}
      >
        <div>
          <div>
            <div
              className="container border p-3 px-4 bg-light"
              style={{ width: "1000px" }}
            >
              <div className="d-flex justify-content-between ">
                <h3>TAXI INVOICE</h3>
                <div>
                  <p>
                    <b>INVOICE NO : </b> {bookData?.InvoiceNo}
                  </p>
                  <p>
                    <b>INVOICE DATE : </b> {moment(bookData?.date).format("ll")}
                  </p>
                </div>
              </div>

              <div
                className="Invoice_0"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <img src="../logo_eye.jpeg" alt="logo" />
              
                </div>
                <div className="text-end">
                  <h5> Dhanya Travels</h5>
                  <p className="mb-0">Dhanya Travel Co.Pvt.Ltd</p>
                  <p className="mb-0">
                    {" "}
                    II Floor, Papanna Lane, No-8, St.Mark's Road <br />{" "}
                    Bangalore-560001, India.
                  </p>
                  <p className="mb-0">
                    <span>Tel: </span> +91 9876543210 <span>Cell:</span> +91
                    9876543321
                  </p>
                  <p className="mb-0">
                    <span>Email :</span> Ganesh@gmail.com{" "}
                    <span> Website :</span> DhanyaTravel.com
                  </p>
                </div>
              </div>

              <hr />

              <h6 className="fw-bold">Booking Details :</h6>
              <div className="mb-4">
                <Table bordered striped responsive>
                  <tbody>
                    <tr>
                      <td>Booking ID : {bookData?.BookingId}</td>
                      <td>
                        Booking Date :{" "}
                        {moment(bookData?.taxibookId?.createdAt).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                      <td>Frpm : {bookData?.PickUpPoint}</td>
                      <td>To: {bookData?.DropingPoint}</td>
                    </tr>
                    <tr>
                      <td> Name : {bookData?.Name}</td>
                      <td>Number : {bookData?.mobile}</td>
                      <td>Start Date : {bookData?.pickDate}</td>
                      <td>Drop Date : {bookData?.dropDate}</td>
                    </tr>
                    <tr>
                      <td>Email : {bookData?.email}</td>
                      <td>package : {bookData?.taxiPerson}</td>
                      <td>Bording Time : {bookData?.pickUpTime}</td>
                      <td>Drop Time : {bookData?.dropTime}</td>
                    </tr>
                    <tr>
                      <td>
                        PNR :{" "}
                        {bookData?.LodgeName}
                      </td>
                      <td>
                        Train Name :{" "}
                        {bookData?.LodgeEmail}
                      </td>
                      <td>Seat No : {bookData?.tripType}</td>
                      <td>Status : {bookData?.status}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>

            

              <div>
                <Table
                  bordered
                  hover
                  striped
                  responsive
                  className="invoice-table-head"
                >
                  <thead>
                    <tr>
                      <th>FARE BREAKUP</th>
                      <th>PRICE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Ticket Fare</td>
                      <td className="text-end">
                        INR {bookData?.carHireCharge?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Convenience fee</td>
                      <td className="text-end">
                        INR {Number(bookData?.ExtraMater)?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Travel Insurance Premium fee</td>
                      <td className="text-end">
                        INR {bookData?.driverAllowances?.toFixed(2)}
                      </td>
                    </tr>
                  
                    {/* <tr>
                      <td>Toll Charges</td>
                      <td className="text-end">
                        INR{" "}
                        {bookData?.TollCharge?.toFixed(2)}
                      </td>
                    </tr> */}
                    {/* <tr>
                      <td>Parking Charges</td>
                      <td className="text-end">
                        INR{" "}
                        {bookData?.ParkingCharge?.toFixed(2)}
                      </td>
                    </tr> */}
                    <tr>
                      <td>Permit Charges</td>
                      <td className="text-end">
                        INR   {bookData?.PermitCharge?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>GST% (CGST + SGST)</td>
                      <td className="text-end">
                        INR {bookData?.gst?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        IGST(%)
                      </td>
                      <td className="text-end">
                        INR{" "}
                        {bookData?.stateTax?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Other Service Charges</td>
                      <td className="text-end">
                        INR   {bookData?.serviceCharge?.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-end">
                <Table style={{ width: "400px" }}>
                  <tbody>
                    <tr>
                      <td>Sub Total</td>
                      <td className="text-end">
                        INR{" "}
                        {(
                          bookData?.TotalAmount -(bookData?.gst?bookData?.gst:0)-(bookData?.stateTax?bookData?.stateTax:0)
                        )?.toFixed(2)}
                      </td>
                    </tr>
                 
                   
                    <tr>
                      <td>Total Amount</td>
                      <td className="text-end">
                        INR {bookData?.TotalAmount?.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div>
                <p className="text-start fw-bold mb-0">Invoice Terms:</p>
                <p className="text-start">
                  E.g Payment Instructions(Account Number,Bank and Bank Account
                  Holder)
                </p>
              </div>

              <div className="">
                <h5 className="fw-bold text-start mb-4">
                  Invoice issued by Dhanya Tours & Travels
                </h5>
                <div className="row">
                  <div className="col-md-4">
                    <div className="text-start mb-3">
                      <p className="fw-bold mb-0">PAN :</p>
                      <span>AADCM5146R</span>
                    </div>
                    <div className="text-start">
                      <p className="fw-bold mb-0">GSTIN :</p>
                      <span>06AADCM533533</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="text-start mb-3">
                      <p className="fw-bold mb-0">HSN/SAC :</p>
                      <span>998551</span>
                    </div>
                    <div className="text-start">
                      <p className="fw-bold mb-0">CIN :</p>
                      <span>U63040HR2000PTC090846</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="text-start mb-3">
                      <p className="fw-bold mb-0">SERVICE DESCRIPTION :</p>
                      <span>Dhanya Tours & Travels</span>
                    </div>
                    <div className="text-start">
                      <p className="fw-bold mb-0">REGISTERED OFFICE :</p>
                      <span>Singapoor Layout</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
        <div>
          <button
            type="button"
            class="btn btn-warning"
            onClick={generatePDF}
            style={{ color: "white" }}
          >
            Download Pdf
          </button>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-danger"
            onClick={() => History(-1)}
          >
            Back
          </button>
        </div>
      </div>
      <br />
    </>
  );
};

export default LuxuryTrainInvoice;
