import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Form, Table } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { useParams } from "react-router-dom";
import { ImLocation2 } from "react-icons/im";
import { BiTimeFive } from "react-icons/bi";
import parse from "html-react-parser";
import moment from "moment";

function SingalHotel() {
  const [NumberOfChild, setNumberOfchild] = useState([]);
  let [otherPrice, setotherPric] = useState(0);
  const [selectedValue, setSelectedValue] = useState(1);
  const [childAges, setChildAges] = useState([]);
  let [data1, setdata1] = useState(0);
  const [day, setday] = useState(1);
  console.log("NumberOfChild", NumberOfChild);
  const handleChange = (e) => {
    if (e.target.value !== "") {
      const value = parseInt(e.target.value);

      console.log("data1", data1, value);

      setSelectedValue(value);
      setChildAges(Array(value).fill(1));
      if (data1 > parseInt(e.target.value)) {
        setdata1(data1 - 1);
        const newTaskList = [...NumberOfChild];
        newTaskList.splice(e.target.value, 1);
        setNumberOfchild(newTaskList);
      } else {
        setdata1(data1 + value);
      }
    } else {
      setChildAges([]);
      setNumberOfchild([]);
      setotherPric(0);
    }
  };

  const [accord, setaccording] = useState(false);
  const [Loadeges, setLoadeges] = useState({});
  const [priceing, setpriceing] = useState([]);
  const [includes, setincludes] = useState([]);
  const [excludes, setexcludes] = useState([]);

  const { id } = useParams();

  // console.log(Loadeges);
  const [ITINERARY, setITINERARY] = useState([]);
  const getlodges = async () => {
    const config = {
      url: "/getbyHotelId/" + id,
      method: "get",
      baseURL: "https://dhanyatours.me/api/user",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setLoadeges(result.data.success);
        setpriceing(result.data.success?.PRICING);
        setincludes(result.data.success?.Includes);
        setexcludes(result.data.success?.Excludes);
        setITINERARY(result.data.success?.ITINERARY);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  const [ChildData, setChildData] = useState([]);
  const getChildData = async () => {
    const config = {
      url: "/getChildSdata",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setChildData(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  useEffect(() => {
    getlodges();
    getChildData();
  }, []);

  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  };

  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [number, setnumber] = useState("");
  const [packageName, setpackageName] = useState("");
  const [Occupancy, setOccupancy] = useState("");
  const [checkinDate, setcheckinDate] = useState("");
  const [checkinTime, setcheckinTime] = useState("");
  const [checkOutDate, setcheckOutDate] = useState("");
  const [checkOutTime, setcheckOutTime] = useState("");
  const [message, setmessage] = useState("");
  const [numberOfguest, setnumberOfguest] = useState(1);
  let [packagePrice, setpackagePrice] = useState(0);
  let [actualPrice, setactualPrice] = useState(0);
  useEffect(() => {
    const currentDate = new Date(Date.now());
    currentDate.setDate(currentDate.getDate() + 1);
    const formattedNewDate = currentDate.toISOString().split("T")[0];
    setcheckinDate(formattedNewDate);

    const currentDate1 = new Date(Date.now());
    currentDate1.setDate(currentDate1.getDate() + 2);
    const formattedNewDate1 = currentDate1.toISOString().split("T")[0];
    setcheckOutDate(formattedNewDate1);
  }, []);

  const handleendDays = (data) => {
    let date1 = moment(checkinDate);
    let date2 = moment(data);
    let am = date2.diff(date1, "day");
    if (am <= 0) {
      setday(1);
    } else {
      setday(am);
    }
  };

  const HandleStDate = (dat) => {
    let date1 = moment(dat);
    let date2 = moment(checkOutDate);
    let am = date2.diff(date1, "day");
    if (am <= 0) {
      setday(1);
    } else {
      setday(am);
    }
  };

  let [data, setData] = useState({});

  packagePrice = Number(Loadeges?.StartingPrice);
  actualPrice = Number(Loadeges?.StartingPrice);
  if (Occupancy) {
    if (Occupancy === "Single Occupancy Weekday") {
      packagePrice = data?.singleWeekPrice;
      actualPrice = data?.singleWeekPrice;
    } else if (Occupancy === "Single Occupancy Weekend") {
      packagePrice = data?.singleWeekendPrice;
      actualPrice = data?.singleWeekendPrice;
    } else if (Occupancy === "Double Occupancy Weekday") {
      packagePrice = data?.doublyWeekPrice;
      actualPrice = data?.doublyWeekPrice;
    } else if (Occupancy === "Double Occupancy Weekend") {
      packagePrice = data?.doublyWeekendPrice;
      actualPrice = data?.doublyWeekendPrice;
    }
  }

  packagePrice =
    (packagePrice * day +
      Number(
        Number(packagePrice * day) *
          Number(Number(Loadeges?.gst + Loadeges?.igst) / 100)
      )) *
    numberOfguest;
  actualPrice = actualPrice * numberOfguest * day;

  const handleAgeChange = (index, e) => {
    let obj = {};
    if (e.target.value !== "") {
      obj = JSON.parse(e.target.value);
      let am = NumberOfChild.filter((ele) => ele.no == index);
      if (am.length == 0) {
        NumberOfChild.push({
          no: index,
          age: obj?.ageType,
          discount: obj?.discount,
        });
        let ab = [...NumberOfChild];

        let childPrice = 0;
        for (let i = 0; i < ab.length; i++) {
          childPrice =
            childPrice +
            Number(packagePrice - (packagePrice * ab[i]?.discount) / 100);
        }
        setotherPric(Number(childPrice));
      } else {
        let an = NumberOfChild.map((ele) => {
          if (ele.no == index) {
            let ob = {
              ...ele,
              age: obj?.ageType,
              discount: obj?.discount,
            };
            return ob;
          }
          return ele;
        });
        setNumberOfchild(an);
        let childPrice = 0;
        for (let i = 0; i < an.length; i++) {
          childPrice =
            childPrice +
            Number(packagePrice - (packagePrice * an[i]?.discount) / 100);
        }
        setotherPric(Number(childPrice));
      }
    }
  };

  console.log("otherPrice", otherPrice);

  useEffect(() => {
    if (NumberOfChild.length !== 0) {
      let childPrice = 0;
      for (let i = 0; i < NumberOfChild.length; i++) {
        childPrice =
          childPrice +
          Number(
            packagePrice - (packagePrice * NumberOfChild[i]?.discount) / 100
          );
      }
      setotherPric(Number(childPrice));
    }
  }, [NumberOfChild]);

  // const onCreate = async () => {
  //   try {
  //     if (!user) {
  //       return alert("Please enter full name!");
  //     } else if (!email) {
  //       return alert("Please enter email!");
  //     } else if (!number) {
  //       return alert("Please enter mobile number!");
  //     }

  //     if (packageName) {
  //       if (!Occupancy) {
  //         return alert("Please select occupancy!");
  //       }
  //     }
  //     if (!checkinDate) {
  //       return alert("Please select check in date!");
  //     }
  //     if (!checkinTime) {
  //       return alert("Please select check in time!");
  //     }
  //     if (!checkOutDate) {
  //       return alert("Please select check out date!");
  //     }
  //     if (!checkOutTime) {
  //       return alert("Please select check out time!");
  //     }
  //     if (!numberOfguest) {
  //       return alert("Please enter  number of guest!");
  //     }

  //     const config = {
  //       url: "/createHotelBook",
  //       method: "post",
  //       baseURL: "https://dhanyatours.me/api/user",
  //       headers: { "conttent-type": "application/json" },
  //       data: {
  //         lodgeId: Loadeges?._id,
  //         userName: user,
  //         email: email,
  //         number: number,
  //         Location: Loadeges?.location,
  //         checkInDate: checkinDate,
  //         checkInTime: checkinTime,
  //         checkOutDate: checkOutDate,
  //         checkOutTime: checkOutTime,
  //         NumberOfGuest: numberOfguest,
  //         message: message,
  //         packageName: packageName ? JSON.parse(packageName)?.packageName : "",
  //         roomType: packageName ? JSON.parse(packageName)?.packageType : "",
  //         Occupancy: Occupancy,
  //         packagePrice: actualPrice,
  //         gst: (actualPrice * (Loadeges?.gst + Loadeges?.igst)) / 100,
  //         totalAmount: Number(packagePrice + otherPrice),
  //         NumberOfChild: NumberOfChild,
  //         wenderId: Loadeges?.wenderId,
  //         days: day,
  //       },
  //     };
  //     const result = await axios(config);
  //     if (result.status === 200) {
  //       alert("Successfully booking configration will send your email");
  //       window.location.assign("/");
  //     } else {
  //       alert("Something went wrong");
  //     }
  //   } catch (error) {
  //     console.log("erore", error);
  //     alert(error.response.data.error);
  //   }
  // };

  const onCreate = async () => {
    try {
      if (!user) {
        return alert("Please enter full name!");
      } else if (!email) {
        return alert("Please enter email!");
      } else if (!number) {
        return alert("Please enter mobile number!");
      }

      if (packageName) {
        if (!Occupancy) {
          return alert("Please select occupancy!");
        }
      }
      if (!checkinDate) {
        return alert("Please select check in date!");
      }
      if (!checkinTime) {
        return alert("Please select check in time!");
      }
      if (!checkOutDate) {
        return alert("Please select check out date!");
      }
      if (!checkOutTime) {
        return alert("Please select check out time!");
      }
      if (!numberOfguest) {
        return alert("Please enter  number of guest!");
      }

      // Initiate the payment process
      posttransaction();
    } catch (error) {
      console.log("Error:", error);
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  // Transaction++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ phonepay
  const generateUniqueTransactionId = () => {
    const timestamp = Date.now().toString();
    const randomComponent = Math.floor(Math.random() * 1000000).toString();
    // console.log('timestamp', timestamp - randomComponent);
    return `${timestamp}-${randomComponent}`;
  };

  const [paymentUrl, setPaymentUrl] = useState();
  const posttransaction = async () => {
    // if (!fullName || !phno || !email || !donatetype || !amt) {
    //     return alert("Please fill all the necessary fields");
    // }
    try {
      const newMerchantTransactionId = generateUniqueTransactionId();
      const config = {
        url: "/makepayment",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "content-Type": "application/json" },
        data: {
          merchantTransactionId: newMerchantTransactionId,
          merchantUserId: "2342343",
          amount: Number(packagePrice + otherPrice) * 100,
          redirectUrl: "https://dhanyatoursandtravels.com/home",
          callbackUrl: "https://dhanyatoursandtravels.com/home",
          mobileNumber: number,
        },
      };
      console.log("Request Config:", config);
      const res = await axios(config);
      if (res.status === 200) {
        window.location.assign(res.data?.url?.url);
      }
    } catch (error) {
      console.log("Error while fetching payment URL:", error);
      if (error.response) {
        console.log("Response data:", error.response.data);
        console.log("Response status:", error.response.status);
        console.log("Response headers:", error.response.headers);
      } else if (error.request) {
        console.log("Request made but no response received:", error.request);
      } else {
        console.log("Error message:", error.message);
      }
      alert("An error occurred while fetching the payment URL");
    }
  };

  // Call this function after payment is successful
  const storeBookingData = async () => {
    try {
      const config = {
        url: "/createHotelBook",
        method: "post",
        baseURL: "https://dhanyatours.me/api/user",
        headers: { "conttent-type": "application/json" },
        data: {
          lodgeId: Loadeges?._id,
          userName: user,
          email: email,
          number: number,
          Location: Loadeges?.location,
          checkInDate: checkinDate,
          checkInTime: checkinTime,
          checkOutDate: checkOutDate,
          checkOutTime: checkOutTime,
          NumberOfGuest: numberOfguest,
          message: message,
          packageName: packageName ? JSON.parse(packageName)?.packageName : "",
          roomType: packageName ? JSON.parse(packageName)?.packageType : "",
          Occupancy: Occupancy,
          packagePrice: actualPrice,
          gst: (actualPrice * (Loadeges?.gst + Loadeges?.igst)) / 100,
          totalAmount: Number(packagePrice + otherPrice),
          NumberOfChild: NumberOfChild,
          wenderId: Loadeges?.wenderId,
          days: day,
        },
      };
      const result = await axios(config);
      if (result.status === 200) {
        alert("Successfully Booked. Configuration will be sent to your email.");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log("Error:", error);
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  // Simulate the callback URL being hit after a successful payment
  // In practice, you would configure this on your server and the server would call `storeBookingData`
  window.onload = function () {
    const urlParams = new URLSearchParams(window.location.search);
    const paymentStatus = urlParams.get("paymentStatus");

    if (paymentStatus === "success") {
      storeBookingData();
    }
  };

  return (
    <>
      <OwlCarousel
        className="owl-theme"
        loop
        margin={0}
        nav={false}
        dots={false}
        autoplay={true}
        responsive={responsive}
      >
        {Loadeges?.HotelImages?.map((slider) => {
          return (
            <div class="item">
              <div class="box">
                <img
                  src={`https://dhanyatours.me/Hotel/${slider?.image}`}
                  alt="Jungle Lodges and Resorts"
                  style={{ width: "100%", height: "300px" }}
                />
              </div>
            </div>
          );
        })}
      </OwlCarousel>

      <div className="inner-content-listing-desc" style={{ padding: "30px" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1 className="wr-0">{Loadeges?.hotalName}</h1>
              <p className="listing-wro">
                <span class="posted_by">
                  <i
                    class="icon-price-tag"
                    style={{ fontSize: "14px", color: "#66CC99" }}
                  ></i>{" "}
                  Starts from ₹{" "}
                  {(
                    Number(Loadeges?.StartingPrice) +
                    Number(
                      Number(Loadeges?.StartingPrice) *
                        Number(Number(Loadeges?.gst + Loadeges?.igst) / 100)
                    )
                  )?.toFixed(2)}
                  /-&nbsp;&nbsp;&nbsp;&nbsp;
                  <i
                    class="icon-calendar"
                    style={{ fontSize: "14px", color: "#66CC99" }}
                  ></i>{" "}
                  <BiTimeFive /> {Loadeges?.CheckInTime}/
                  {Loadeges?.CheckOutTime}&nbsp;&nbsp;&nbsp;&nbsp;
                  <i
                    class="icon-location-pin"
                    style={{ fontSize: "14px", color: "#66CC99" }}
                  ></i>
                  <ImLocation2 /> {Loadeges?.location?.slice(0, 50)}
                </span>
              </p>
              <h4 className="over-taxi">OVERVIEW</h4>
              <p style={{ textAlign: "justify" }}>
                <span style={{ color: "black", fontWeight: "600" }}>
                  {" "}
                  {Loadeges?.lodgeName}{" "}
                </span>
                &nbsp; {Loadeges?.OVERVIEW ? parse(Loadeges?.OVERVIEW) : ""}
              </p>
              <div className="" style={{ overflowX: "auto" }}>
                <h4 className="over-taxi">PRICING</h4>
                {/* table */}
                <Table
                  class="table table-responsive table-bordered table-hover"
                  striped
                  bordered
                  hover
                  size="sm"
                >
                  <tbody>
                    <tr>
                      <td colspan="5">
                        <strong>
                          Per Person Per Night Rate's with includes gst{" "}
                          {Loadeges?.gst + Loadeges?.igst}%.{" "}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        &nbsp;<strong>Package Name</strong>
                      </td>
                      <td>
                        <strong>Weekday</strong>
                      </td>
                      <td>
                        <strong>Weekend</strong>
                      </td>
                      <td>
                        <strong>Weekday</strong>
                      </td>
                      <td>
                        <strong>Weekend</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td colspan="2">
                        <strong>Double Occupancy</strong>
                      </td>
                      <td colspan="2">
                        <strong>Single Occupancy</strong>
                      </td>
                    </tr>
                    {priceing?.sort()?.map((ele) => {
                      return (
                        <tr>
                          <td>
                            {ele?.packageName} {ele?.packageType}
                          </td>
                          <td>
                            ₹{" "}
                            {(
                              ele?.doublyWeekPrice +
                              ele?.doublyWeekPrice *
                                ((Loadeges?.gst + Loadeges?.igst) / 100)
                            )?.toFixed(2)}
                          </td>
                          <td>
                            ₹{" "}
                            {(
                              ele?.doublyWeekendPrice +
                              ele?.doublyWeekendPrice *
                                ((Loadeges?.gst + Loadeges?.igst) / 100)
                            )?.toFixed(2)}
                          </td>
                          <td>
                            ₹{" "}
                            {(
                              ele?.singleWeekPrice +
                              ele?.singleWeekPrice *
                                ((Loadeges?.gst + Loadeges?.igst) / 100)
                            )?.toFixed(2)}
                          </td>
                          <td>
                            ₹{" "}
                            {(
                              ele?.singleWeekendPrice +
                              ele?.singleWeekendPrice *
                                ((Loadeges?.gst + Loadeges?.igst) / 100)
                            )?.toFixed(2)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div className="">
                <h4 className="over-taxi">ITINERARY</h4>
                <div class="accordion-34">
                  <a href="#collapse2080" style={{ color: "#000000" }}>
                    <div class="accordion-group">
                      <div
                        onClick={() => {
                          setaccording(!accord);
                        }}
                        class="accordion-heading"
                        style={{
                          border: "1px solid #f0f0f0",
                          backgroundColor: "#f6f6f6",
                          padding: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <i class="icon-chevron-with-circle-down"></i>{" "}
                        <strong>Day 1 : Day One</strong>
                      </div>
                      {!accord ? (
                        <>
                          <div
                            // id="collapse2080"
                            // class="accordion-body collapse"
                            style={{ marginBottom: "20px" }}
                          >
                            <div class="accordion-inner">
                              <div class="table-responsive">
                                <table
                                  class="table table-striped table-responsive table-bordered table-hover "
                                  striped
                                  bordered
                                  hover
                                  size="sm"
                                >
                                  <tbody>
                                    {ITINERARY?.map((item) => {
                                      return (
                                        <tr>
                                          <td width="150">{item?.time}</td>
                                          <td>{item?.details}</td>
                                        </tr>
                                      );
                                    })}

                                    {/* <tr>
                                      <td>1.30 p.m. to 2.30 p.m</td>
                                      <td>
                                        Buffet lunch at the 'Gol Ghar'
                                        overlooking the Kabini River OR at the
                                        Viceroy's Bungalow - depending on
                                        weather.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">REST</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        3.30 p.m. OR 4.00 p.m.
                                        <br />
                                        (Depending on Season)
                                      </td>
                                      <td>
                                        Tea/Coffee served in the Verandah of the
                                        Lodges, outside the rooms.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        4.00 p.m. OR 4.30 p.m to 7.00 p.m.
                                      </td>
                                      <td>
                                        Wild life Tour into the Nagarahole
                                        National Park, in the Resorts
                                        Jeeps/Vans, accompanied by our
                                        experienced Drivers and Naturalists.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>6.30 p.m to 7.00 p.m</td>
                                      <td>
                                        Return to the Lodge (Depending on
                                        season).
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>8.00 p.m.</td>
                                      <td>
                                        Video films on Wild-life at the
                                        Viceroy's Bungalow.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>9.00 p.m to 10.30 p.m</td>
                                      <td>
                                        Buffet Dinner around camp-fire at the
                                        'Gol Ghar' or Viceroy's Bungalow. Bar
                                        Services are billed separately.
                                      </td>
                                    </tr> */}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </a>
                </div>
              </div>
              {/* encludes */}
              <div className="">
                <h4 className="over-taxi">INCLUDES &amp; EXCLUDES</h4>
                <p>
                  <strong>Includes:</strong>
                </p>
                <ul style={{ listStyle: "unset" }}>
                  {includes?.map((eitem) => {
                    return <li>{eitem?.text}</li>;
                  })}
                </ul>
                <p>
                  <strong>Excludes:</strong>
                </p>

                <ul style={{ listStyle: "unset" }}>
                  {excludes.map((itemw) => {
                    return <li>{itemw.text1}</li>;
                  })}
                </ul>
              </div>
              {/* <a style={{ float: "right", fontSize: "13px" }}>
                Posted By-
                {Loadeges?.adminId ? (
                  <span style={{ fontSize: "13px", color: "red" }}>
                    (Admin)
                  </span>
                ) : (
                  <span style={{ fontSize: "13px", color: "red" }}>
                    ({Loadeges?.wenderId?.name})
                  </span>
                )}
              </a> */}
              <div className="according">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {" "}
                      <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                        Suggestion
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      {Loadeges?.suggestion ? parse(Loadeges?.suggestion) : ""}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {" "}
                      <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                        TERMS &amp; CONDITIONS
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      {Loadeges?.termsCondition
                        ? parse(Loadeges?.termsCondition)
                        : ""}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

            <div className="col-md-4">
              <div className="r-lg-0">
                <div
                  style={{
                    boxShadow: " 0px 14px 33px -9px rgba(0, 0, 0, 0.15)",
                    padding: "15px",
                    backgroundColor: "#004aad",
                    marginTop: "20px",
                    border: "1px solid #004aad",
                    borderRadius: "5px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    Want to book fill the form?
                  </span>
                  <form>
                    <div className="form-group pt-2">
                      <input
                        type="text"
                        class="form-control"
                        name="Last Name"
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                        placeholder="Full Name*"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        class="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email Id*"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        class="form-control"
                        name="mobileno"
                        value={number}
                        onChange={(e) => setnumber(e.target.value)}
                        placeholder="Mobile No*"
                      />
                    </div>
                    <div className="row">
                      <label
                        style={{
                          fontWeight: "600",
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        Select Package <span class="text-danger">*</span>
                      </label>
                      <div className="col-md-12">
                        <div class="form-group">
                          <Form.Select
                            onChange={(e) => {
                              setpackageName(e.target.value);
                              setData(JSON.parse(e.target.value));
                            }}
                          >
                            <option>Select package</option>
                            {priceing?.map((item) => {
                              return (
                                <option value={JSON.stringify(item)}>
                                  {item?.packageName} {item?.packageType}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div class="form-group">
                          <Form.Select
                            onChange={(e) => {
                              setOccupancy(e.target.value);
                            }}
                          >
                            <option>Select Occupancy</option>
                            <option value="Single Occupancy Weekday">
                              Single Occupancy Weekday{" "}
                            </option>
                            <option value="Double Occupancy Weekday">
                              Double Occupancy Weekday
                            </option>
                            <option value="Single Occupancy Weekend">
                              Single Occupancy Weekend{" "}
                            </option>
                            <option value="Double Occupancy Weekend">
                              Double Occupancy Weekend
                            </option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <label
                        style={{
                          fontWeight: "600",
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        Check In Date/Time <span class="text-danger">*</span>
                      </label>
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="date"
                            class="form-control"
                            name="Description"
                            value={checkinDate}
                            onChange={(e) => {
                              HandleStDate(e.target.value);
                              setcheckinDate(e.target.value);
                            }}
                            min={moment().format("YYYY-MM-DD")}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="time"
                            class="form-control"
                            value={checkinTime}
                            onChange={(e) => setcheckinTime(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <label
                        style={{
                          fontWeight: "600",
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        Check Out Date/Time <span class="text-danger">*</span>
                      </label>
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="date"
                            class="form-control"
                            value={checkOutDate}
                            onChange={(e) => {
                              handleendDays(e.target.value);
                              setcheckOutDate(e.target.value);
                            }}
                            min={checkinDate}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="time"
                            class="form-control"
                            value={checkOutTime}
                            onChange={(e) => setcheckOutTime(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <label
                        style={{
                          fontWeight: "600",
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        Number Of Guest <span class="text-danger">*</span>
                      </label>

                      <div className="col-md-12">
                        <div class="form-group">
                          <input
                            type="number"
                            class="form-control"
                            value={numberOfguest}
                            onChange={(e) => setnumberOfguest(e.target.value)}
                            placeholder="Adults"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div class="form-group">
                          <input
                            type="number"
                            class="form-control"
                            min="0"
                            // value={NumberOfChild}
                            onChange={handleChange}
                            placeholder="Childs"
                          />
                        </div>
                      </div>
                      {childAges.map((age, index) => {
                        return (
                          <div>
                            <div class="form-group">
                              <Form.Select
                                aria-label="Default select example"
                                onChange={(e) => handleAgeChange(index + 1, e)}
                              >
                                <option>Age needed</option>
                                {ChildData?.map((ele) => {
                                  return (
                                    <option value={JSON.stringify(ele)}>
                                      {ele?.ageType} old
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </div>
                          </div>
                        );
                      })}

                      {/* <div class="col-sm-6 mb-2">
                        <botton
                          class="btn btn-primary btn-sm hidden-xs"
                          style={{ width: "100%" }}
                        >
                          Add
                        </botton>
                        <input
                  class="btn btn-primary btn-sm hidden-xs"
                  type="submit"
                  value="Book Now"
                />
                      </div> */}
                    </div>

                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        value={message}
                        onChange={(e) => setmessage(e.target.value)}
                        placeholder="Extra matter"
                      />
                    </div>

                    <div class="row" style={{ marginTop: "10px" }}>
                      <div class="col-sm-6">
                        <botton
                          class="btn btn-primary btn-sm hidden-xs"
                          onClick={onCreate}
                          style={{ width: "100%" }}
                        >
                          Book Now
                        </botton>
                        {/* <input
                  class="btn btn-primary btn-sm hidden-xs"
                  type="submit"
                  value="Book Now"
                /> */}
                      </div>
                      <div class="col-sm-6">
                        <botton
                          class="btn btn-primary btn-sm hidden-xs"
                          style={{ width: "100%" }}
                        >
                          Total:- {(packagePrice + otherPrice)?.toFixed(2)}
                        </botton>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingalHotel;
