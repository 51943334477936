import React, { useState, useRef, useEffect } from "react";
import {
  DayPilot,
  DayPilotCalendar,
  DayPilotNavigator,
} from "@daypilot/daypilot-lite-react";
import { Button, Form, Modal } from "react-bootstrap";

const styles = {
  wrap: {
    display: "flex",
  },
  left: {
    marginRight: "10px",
  },
  main: {
    flexGrow: "1",
  },
};

export const Calender = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const calendarRef = useRef();
  const [calendarConfig, setCalendarConfig] = useState({
    viewType: "Week",
    durationBarVisible: false,
    timeRangeSelectedHandling: "Enabled",
    onTimeRangeSelected: async (args) => {
      handleShow();
      // console.log("Dekho bhai kya hal chal",args);
      //   const dp = calendarRef.current.control;
      //   const modal = await DayPilot.Modal.prompt("Create a new event:", "Event 1");
      //   dp.clearSelection();
      //   if (!modal.result) { return; }
      //   dp.events.add({
      //     start: args.start,
      //     end: args.end,
      //     id: DayPilot.guid(),
      //     text: modal.result
      //   });
    },
    eventDeleteHandling: "Update",
    // onClick={ handleShow }

    onEventClick: async (args) => {
      const dp = calendarRef.current.control;
      const modal = await DayPilot.Modal.prompt(
        "Update event text:",
        args.e.text()
      );
      if (!modal.result) {
        return;
      }
      const e = args.e;
      e.data.text = modal.result;
      dp.events.update(e);
    },
  });

  useEffect(() => {
    const events = [
      {
        id: 1,
        text: "Event 1",
        start: "2023-10-02T10:30:00",
        end: "2023-10-02T13:00:00",
      },
      {
        id: 2,
        text: "Event 2",
        start: "2023-10-03T09:30:00",
        end: "2023-10-03T11:30:00",
        backColor: "#6aa84f",
      },
      {
        id: 3,
        text: "Event 3",
        start: "2023-10-03T12:00:00",
        end: "2023-10-03T15:00:00",
        backColor: "#f1c232",
      },
      {
        id: 4,
        text: "Event 4",
        start: "2023-10-01T11:30:00",
        end: "2023-10-01T14:30:00",
        backColor: "#cc4125",
      },
    ];

    const startDate = "2023-10-02";

    calendarRef.current.control.update({ startDate, events });
  }, []);

  return (
    <div style={styles.wrap}>
      <div style={styles.left}>
        <DayPilotNavigator
          selectMode={"Week"}
          showMonths={3}
          skipMonths={3}
          onClick={handleShow}
          startDate={"2023-10-02"}
          selectionDay={"2023-10-02"}
          onTimeRangeSelected={(args) => {
            calendarRef.current.control.update({
              startDate: args.day,
            });
          }}
        />
      </div>
      <div style={styles.main}>
        <DayPilotCalendar
          onClick={handleShow}
          {...calendarConfig}
          ref={calendarRef}
        />
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Hotel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="calender-0">
            <div className=" row">
              <div className="col-md-6">
                <label className="mb-0">Room Type</label>
                <Form.Select
                  aria-label="Default select example"
                  style={{
                    border: "2px solid #dee2e6",
                    outlineWidth: "0px",
                    padding: " 7px",
                    borderRadius: " 5px",
                    width: " 98%",
                  }}
                >
                  <option>Select Room Type</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
              <div className="col-md-6">
                <label className="mb-0">Room No</label>
                <input
                  type="text"
                  placeholder="Enter Room No"
                  className="vi_0"
                  style={{
                    border: "2px solid #dee2e6",
                    outlineWidth: "0px",
                    padding: " 7px",
                    borderRadius: " 5px",
                    width: " 98%",
                  }}
                />
              </div>
              <div className="col-md-4">
                <label className="mb-0">Check In</label>
                <input
                  type="date"
                  placeholder="Enter Room No"
                  className="vi_0"
                  style={{
                    border: "2px solid #dee2e6",
                    outlineWidth: "0px",
                    padding: " 7px",
                    borderRadius: " 5px",
                    width: " 98%",
                  }}
                />
              </div>
              <div className="col-md-4">
                <label className="mb-0">Check Out</label>
                <input
                  type="date"
                  placeholder="Enter Room No"
                  className="vi_0"
                  style={{
                    border: "2px solid #dee2e6",
                    outlineWidth: "0px",
                    padding: " 7px",
                    borderRadius: " 5px",
                    width: " 98%",
                  }}
                />
              </div>
              <div className="col-md-4">
                <label className="mb-0">Booking Type</label>
                <Form.Select
                  aria-label="Default select example"
                  style={{
                    border: "2px solid #dee2e6",
                    outlineWidth: "0px",
                    padding: " 7px",
                    borderRadius: " 5px",
                    width: " 98%",
                  }}
                >
                  <option>Select Booking Type</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">Save</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};