import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";

function GST() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // getAllImage

  const [gst, setgst] = useState({});
  const getGst = async () => {
    const config = {
      url: "/getGst",
      method: "get",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setgst(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getGst();
  }, []);

  // postImage
  const [SGST, setSGST] = useState("");
  const [CGST, setCGST] = useState("");

  const AddGST = async () => {
    const config = {
      url: "/addGst",
      method: "post",
      baseURL: "https://dhanyatours.me/api/admin",
      headers: { "conttent-type": "application/json" },
      data: { Sgst: SGST, Cgst: CGST },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        alert(result.data.success);
        getGst();
        setSGST("");
        setCGST("");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };

  return (
    <div className="ms-5">
      <div
        className="mt-2 mb-3"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="logde-op">
          <h3>GST</h3>
        </div>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>GST Type</th>
            <th>%</th>

            <th>Input</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>SGST</td>
            <td>{gst.Sgst}%</td>
            <td>
              <input
                type="number"
                className="title-90"
                style={{ width: "174px" }}
                value={SGST}
                placeholder="Ex:- 9"
                onChange={(e) => setSGST(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>CGST</td>
            <td>{gst.Cgst}%</td>
            <td>
              <input
                type="number"
                className="title-90"
                style={{ width: "174px" }}
                value={CGST}
                placeholder="Ex:- 9"
                onChange={(e) => setCGST(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Total Gst</td>
            <td>{gst?.Sgst + gst.Cgst}%</td>
            <td>
              <button type="button" class="btn btn-success" onClick={AddGST}>
                Submit
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default GST;
