import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";

function AddHomeGraphic() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [home, sethome] = useState([]);

  const homeImage = async () => {
    const config = {
      url: "/admin/getAllImages",
      method: "get",
      baseURL: "https://dhanyatours.me/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        sethome(result.data.success);
        // console.log("suceesss");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {}
  };
  useEffect(() => {
    homeImage();
  }, []);

  // Add Image
  const [Image1, setImage1] = useState("");
  const [Image2, setImage2] = useState("");
  const addImages = async () => {
    // e.preventDefault();
    try {
      const config = {
        url: "/addImages",
        method: "post",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          image1: Image1,
          image2: Image2,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        // lodges();
        window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  //   Delete

  const deleteImages = async (id) => {
    // e.preventDefault();
    try {
      const config = {
        url: "/deleteImages/" + id,
        method: "delete",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Deleted");
        // lodges();
        window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const [edit, setedit] = useState(false);
  const [showVideo, setshowVideo] = useState(false);
  const [video, setvideo] = useState("");
  const [UploadVideo, setUploadVideo] = useState(false);

  const UpdateVideos = async () => {
    if (video) {
      const fileSizeInMB = video.size / (1024 * 1024);
      if (fileSizeInMB > 25) {
        return alert("File size exceeds the limit of 25MB.");
      }
    }

    setshowVideo(false);
    setUploadVideo(true);
    try {
      const config = {
        url: "/addImages",
        method: "post",
        baseURL: "https://dhanyatours.me/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          video: video,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setUploadVideo(false);
        alert("success");
        window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        setUploadVideo(false);
        alert(error.response.data.error);
      }
    }
  };
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  return (
    <>
    {admin?.HomeS==true ? ( <div className="ms-5">
        {UploadVideo ? (
          <div
            class="loader"
            style={{ position: "fixed", top: "50%", left: "50%" }}
          ></div>
        ) : null}
        <div
          className="mt-2 mb-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="logde-op">
            <h3> Home Graphic</h3>
          </div>

          <div
            className="logde-op-00"
            style={{ cursor: "pointer" }}
            onClick={handleShow}
          >
            {edit ? "Update Home Graphic" : " + Home Graphic"}
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Modal heading</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form>
                  <la className="pb-2 nd-2" htmlFor="upload1">
                    Wild Life Background
                  </la>
                  <p>Note :- Image size should be 1920x1080</p>
                  
                  <input
                    type="file"
                    className="title-90"
                    name="lodgename"
                    id="upload1"
                    accept="image/*"
                    required=""
                    onChange={(e) => setImage1(e.target.files[0])}
                  />
                   <br />
                  <label className="pb-2 nd-2" htmlFor="upload2">
                    Adventure Background
                  </label>
                  <p>Note :- Image size should be 1920x1080</p>
                 
                  <input
                    type="file"
                    className="title-90"
                    name="lodgename"
                    id="upload2"
                    accept="image/*"
                    required=""
                    onChange={(e) => setImage2(e.target.files[0])}
                  />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Back
              </Button>
              <Button variant="primary" onClick={() => addImages()}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showVideo} onHide={() => setshowVideo(false)}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Modal heading</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form>
                  <la className="pb-2 nd-2" htmlFor="upload3">
                    Home Video
                  </la>
                  <br />
                  <input
                    type="file"
                    className="title-90"
                    name="lodgename"
                    id="upload3"
                    accept="video/*"
                    required=""
                    onChange={(e) => setvideo(e.target.files[0])}
                  />
                  <p>Note:- Video size should be less then 25 mb</p>
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setshowVideo(false)}>
                Back
              </Button>
              <Button variant="primary" onClick={() => UpdateVideos()}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="scrolll_bar">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th> Wild Life Background</th>

                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {home?.map((element) => {
                return (
                  <tr>
                    <td>
                      <img
                        src={`https://dhanyatours.me/home/${element?.image1}`}
                        alt=""
                        style={{ width: "250px", height: "151px" }}
                      />
                    </td>

                    <td>
                      <div
                        className=""
                        onClick={() => {
                          setedit(true);
                          handleShow();
                        }}
                      >
                        <BiEdit
                          style={{
                            color: "blue",
                            fontSize: "25px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="scrolll_bar">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th> Adventure Background</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {home?.map((element) => {
                return (
                  <tr>
                    <td>
                      <img
                        src={`https://dhanyatours.me/home/${element?.image2}`}
                        alt=""
                        style={{ width: "250px", height: "151px" }}
                      />
                    </td>

                    <td>
                      <div
                        className=""
                        onClick={() => {
                          setedit(true);
                          handleShow();
                        }}
                      >
                        <BiEdit
                          style={{
                            color: "blue",
                            fontSize: "25px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        <div className="row">
          <div
            className="col-md-6"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            Home Videos
          </div>
          <div className="col-md-6">
            <div
              className="logde-op-00 mb-3"
              style={{ cursor: "pointer", width: "128px", float: "right" }}
              onClick={() => setshowVideo(true)}
            >
              + Videos
            </div>
          </div>
        </div>
        <div className="scrolll_bar">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th> Videos</th>

                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {home?.map((element) => {
                return (
                  <tr>
                    <td>
                      <a
                        href={`https://dhanyatours.me/home/${element?.video}`}
                        target="_blank"
                      >
                        <video
                          src={`https://dhanyatours.me/home/${element?.video}`}
                          muted
                          autoPlay
                          loop
                          type="video/mp4"
                          className="vohrg"
                          style={{ width: "250px", height: "151px" }}
                        />
                      </a>
                    </td>

                    <td>
                      <div
                        className=""
                        onClick={() => {
                          setshowVideo(true);
                        }}
                      >
                        <BiEdit
                          style={{
                            color: "blue",
                            fontSize: "25px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>): (<div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}>
      <h1 style={{color:"red",fontSize:"2rem"}}>Welcome {admin?.name}</h1>
    </div>)}
     
    </>
  );
}

export default AddHomeGraphic;
